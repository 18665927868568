import { Paper, styled } from '@mui/material';

const StyledBottomNavigationPaper = styled(Paper)(({ theme }) => ({
  bottom: 0,
  left: 0,
  position: 'fixed',
  right: 0,
  zIndex: theme.zIndex.drawer
}));

export default StyledBottomNavigationPaper;
