import styledComponent from 'styled-components';

const StyledPerformanceHistoryChartSubtitle = styledComponent.p`
  color: #BAC5D8;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0 0 1.5rem 0;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 1rem;
    margin: 0 0 2rem 0;
  }
`;

export default StyledPerformanceHistoryChartSubtitle;
