import { gql } from '@apollo/client';
import client from 'apollo/client';
import { getNotificationFilters } from 'common/filters/requestFilters';

/**
 * Count unread notifications method
 * @param {string} userId
 * @returns {Promise}
 */
const countUnread = (userId) =>
  client.query({
    query: gql`
      query countUnreadNotifications($user_id: ID!) {
        countUnreadNotifications(user_id: $user_id)
      }
    `,
    fetchPolicy: 'network-only',
    variables: { user_id: userId }
  });

/**
 * Delete all notifications method
 * @param  {string} userId
 * @return {Promise}
 */
const deleteAll = (userId) =>
  client.mutate({
    mutation: gql`
      mutation deleteAllNotifications($user_id: ID!) {
        deleteAllNotifications(user_id: $user_id)
      }
    `,
    variables: { user_id: userId }
  });

/**
 * List notifications method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { filters, pagination } = payload;
  const notificationFilters = getNotificationFilters(filters);

  return client.query({
    query: gql`
      query notifications {
        notifications(
          first: ${pagination.first}
          page: ${pagination.page}
          ${notificationFilters.user_id}
          ${notificationFilters.status}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            id
            created_at
            data
            read
            user_id
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Mark notification as read method
 * @param  {string} id
 * @return {Promise}
 */
const markAsRead = (id) =>
  client.mutate({
    mutation: gql`
      mutation markNotificationAsRead($id: ID!) {
        markNotificationAsRead(id: $id) {
          id
          success
          user_id
        }
      }
    `,
    variables: { id }
  });

/**
 * Mark all notifications as read method
 * @param  {string} userId
 * @return {Promise}
 */
const markAllAsRead = (userId) =>
  client.mutate({
    mutation: gql`
      mutation markAllNotificationsAsRead($user_id: ID!) {
        markAllNotificationsAsRead(user_id: $user_id)
      }
    `,
    variables: { user_id: userId }
  });

export default {
  countUnread,
  deleteAll,
  list,
  markAsRead,
  markAllAsRead
};
