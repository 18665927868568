import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isBefore, isWithinInterval } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat } from 'common/constants/date';
import { sessionListAllFields } from 'common/constants/query-fields/session';
import {
  constructAvailabilityCalendarEvent,
  deleteMirrorEvents,
  filterEventsByDateRange,
  generateAvailabilityIdentifier,
  getStartEndDatesFromEvent,
  getStartEndDatesFromView
} from 'common/utils/availability';
import { convertLocalToUtcTime, convertUtcToLocalTime } from 'common/utils/date';
import availabilitySlice from 'store/modules/availability';
import sessionSlice from 'store/modules/session';

const useAvailabilityCalendar = () => {
  const { i18n } = useTranslation();
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const dispatch = useDispatch();
  const calendarRef = useRef();

  const [calendarApi, setCalendarApi] = useState(null);

  const availabilities = useSelector((state) => state.availability.data.availabilities);
  const refresh = useSelector((state) => state.availability.refresh);
  const toUpsert = useSelector((state) => state.availability.data.toUpsert);
  const trainerId = useSelector((state) => state.auth.user.id);

  /**
   * Adds an availability event to the calendar.
   *
   * @callback addAvailabilityToCalendar
   * @param  {string}  id    The identifier for the availability.
   * @param  {Object}  info  The information about the availability.
   */
  const addAvailabilityToCalendar = useCallback(
    (id, info) => {
      if (!calendarApi) return;

      const availabilityEvent = constructAvailabilityCalendarEvent({
        ...info,
        id,
        start_date: info.start,
        end_date: info.end
      });

      calendarApi.addEvent(availabilityEvent);
    },
    [calendarApi, constructAvailabilityCalendarEvent]
  );

  /**
   * Adds an availability to be updated or inserted.
   *
   * @callback addAvailabilityToUpsert
   * @param  {string}  id    The identifier for the availability.
   * @param  {Object}  info  The information about the availability.
   */
  const addAvailabilityToUpsert = useCallback(
    (id, info) => {
      const { startDate, endDate } = getStartEndDatesFromEvent(info);

      const availability = {
        id,
        user_id: Number(trainerId),
        start_date: startDate,
        end_date: endDate,
        stored: false
      };

      dispatch(availabilitySlice.actions.addToUpsert(availability));
    },
    [dispatch, trainerId]
  );

  /**
   * Checks if a given availability mirror event, identified by its ID, can be restored to the calendar view
   * based on its local start date and the current view range. This
   *
   * @param  {Number}       availabilityId  The ID of the availability event.
   * @param  {String|Date}  localStartDate  The local start date of the availability event.
   *
   * @returns  {Boolean}  Returns true if the event is within the range and does not already exist in it,
   * otherwise returns false.
   */
  const canMirrorEventBeRestored = useCallback(
    (availabilityId, localStartDate) => {
      if (!calendarApi) return false;

      const { startDate, endDate } = getStartEndDatesFromView(calendarApi);
      const eventsInRange = filterEventsByDateRange({ startDate, endDate }, calendarApi);

      const inRange = isWithinInterval(new Date(localStartDate), {
        start: startDate,
        end: endDate
      });

      const availabilityExists = eventsInRange.some(
        (event) => Number(event.id) === Number(availabilityId)
      );

      return inRange && !availabilityExists;
    },
    [calendarApi, filterEventsByDateRange, getStartEndDatesFromView]
  );

  /**
   * Get the start and end dates of that event, converted to local time.
   *
   * @callback getMirrorEventLocalDates
   * @param  {Object}  mirrorEvent  The event object. This object should include start_date and end_date properties which are in UTC format.
   *
   * @returns  {Object}  An object containing the start and end dates of the event converted to local time.
   */
  const getMirrorEventLocalDates = useCallback(
    (mirrorEvent) => {
      const localStartDate = convertUtcToLocalTime(
        mirrorEvent.start_date,
        dateTimeFormat,
        dateLocale
      );
      const localEndDate = convertUtcToLocalTime(mirrorEvent.end_date, dateTimeFormat, dateLocale);

      return { localStartDate, localEndDate };
    },
    [convertUtcToLocalTime, dateLocale]
  );

  /**
   * Handles changes in availabilities. When an availability is changed, it is added to the upsert queue.
   *
   * @callback handleChange
   * @param  {Object}  info The information about the availability change.
   */
  const handleChange = useCallback(
    (info) => {
      const { event } = info;
      const { startDate, endDate } = getStartEndDatesFromEvent(event);

      const availability = {
        id: Number(event.id),
        user_id: Number(trainerId),
        start_date: startDate,
        end_date: endDate,
        stored: event.extendedProps.stored
      };

      dispatch(availabilitySlice.actions.addToUpsert(availability));
    },
    [dispatch, trainerId]
  );

  /**
   * Handles date selection on the calendar. When a date is selected, an availability for that date is both
   * added to the calendar and the upsert queue.
   *
   * @callback handleDateSelect
   * @param {Object} info - The information about the date selection.
   */
  const handleDateSelect = useCallback(
    (info) => {
      if (!calendarApi) return;

      const id = generateAvailabilityIdentifier(info);
      addAvailabilityToUpsert(id, info);
      addAvailabilityToCalendar(id, info);
      calendarApi.unselect();
    },
    [addAvailabilityToCalendar, addAvailabilityToUpsert, calendarApi]
  );

  /**
   * Dispatches actions to list availabilities and all sessions within the selected date range.
   *
   * @callback handleDateSet
   * @param  {Object}  info  The information about the date set.
   */
  const handleDateSet = useCallback(
    (info) => {
      const { startDate, endDate } = getStartEndDatesFromEvent(info);

      dispatch(availabilitySlice.actions.list({ trainerId, startDate, endDate }));
      dispatch(
        sessionSlice.actions.listAll({
          fields: sessionListAllFields,
          filters: {
            trainer_id: trainerId,
            happen_between: { start_date: startDate, end_date: endDate }
          }
        })
      );
    },
    [dispatch, getStartEndDatesFromEvent, sessionListAllFields, trainerId]
  );

  /**
   * Handles deletion of availability events from both the calendar and upsert queue.
   *
   * @callback handleDelete
   * @param  {Object}  event  The event object that represents the availability to delete.
   */
  const handleDelete = useCallback(
    (event) => {
      const {
        id,
        extendedProps: { stored }
      } = event;

      dispatch(availabilitySlice.actions.removeToUpsert(Number(id)));

      if (stored) {
        dispatch(availabilitySlice.actions.addToDelete(Number(id)));
      }

      event.remove();
    },
    [dispatch]
  );

  /**
   * Restores mirror events. It checks for any availabilities in the `toUpsert` queue that should exist on the
   * calendar within the current view but do not, and adds those availabilities to the calendar.
   *
   * @callback handleRestoreMirrorEvents
   */
  const handleRestoreMirrorEvents = useCallback(() => {
    if (!calendarApi || toUpsert.length === 0) return;

    toUpsert.forEach((availability) => {
      const { localStartDate, localEndDate } = getMirrorEventLocalDates(availability);

      if (canMirrorEventBeRestored(availability.id, localStartDate)) {
        const availabilityEvent = constructAvailabilityCalendarEvent({
          ...availability,
          start_date: localStartDate,
          end_date: localEndDate
        });
        calendarApi.addEvent(availabilityEvent);
      }
    });
  }, [
    calendarApi,
    constructAvailabilityCalendarEvent,
    filterEventsByDateRange,
    getStartEndDatesFromView,
    toUpsert
  ]);

  /**
   * Dispatches an action to list availabilities within the current view of the calendar and deletes any mirror events
   * on the calendar.
   *
   * @callback refreshCalendar
   */
  const refreshCalendar = useCallback(() => {
    if (!calendarApi) return;

    const { startDate, endDate } = getStartEndDatesFromView(calendarApi);
    const utcStartDate = convertLocalToUtcTime(startDate);
    const utcEndDate = convertLocalToUtcTime(endDate);

    dispatch(
      availabilitySlice.actions.list({
        trainerId,
        startDate: utcStartDate,
        endDate: utcEndDate
      })
    );
    deleteMirrorEvents(calendarApi);
  }, [calendarApi, deleteMirrorEvents, dispatch, getStartEndDatesFromView, trainerId]);

  /**
   * Determines whether a date selection on the calendar is allowed.
   *
   * @callback selectAllow
   * @param  {Object}  selectInfo  The information about the date selection.
   *
   * @return  {Boolean}  Returns `true` if the selection is allowed, `false` otherwise.
   */
  const selectAllow = useCallback((selectInfo) => {
    const { start: startDate } = selectInfo;
    const pastSelected = isBefore(startDate, new Date());

    return !pastSelected;
  }, []);

  /**
   * This useEffect hook is utilized to set the calendar API only once when the component is mounted.
   */
  useEffect(() => {
    if (calendarRef?.current && !calendarApi) {
      setCalendarApi(calendarRef.current.getApi());
    }
  }, []);

  /**
   * This useEffect hook is used to refresh the calendar when the `refresh` prop changes.
   */
  useEffect(() => {
    if (refresh && calendarApi) {
      refreshCalendar();
    }
  }, [refresh]);

  /**
   * This `useEffect` hook is used to restore mirror events every time the `availabilities` change.
   */
  useEffect(() => {
    handleRestoreMirrorEvents();
  }, [availabilities]);

  return {
    calendarApi,
    calendarRef,
    handleChange,
    handleDateSelect,
    handleDateSet,
    handleDelete,
    selectAllow
  };
};

export default useAvailabilityCalendar;
