import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import requestStatus from 'common/constants/status';
import { roles, trashed } from 'common/constants/options';

const initialFiltersState = {
  membership_status: [],
  user_id: [],
  gender: '',
  plan_id: [],
  soft_delete: trashed.without,
  role: roles.client
};

const initialState = {
  counters: {
    active: 0,
    inactive: 0,
    inArrears: 0
  },
  data: {
    arrearageClients: [],
    clients: [],
    findClient: null,
    search: [],
    viewClient: null
  },
  filters: initialFiltersState,
  pagination: {
    first: 20,
    page: 1
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0
  },
  refresh: false,
  status: {
    find: requestStatus.idle,
    general: requestStatus.idle,
    search: requestStatus.idle,
    view: requestStatus.idle
  }
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    clearSearchResults(state) {
      state.data.search = initialState.data.search;
    },
    create(state) {
      state.status.general = requestStatus.loading;
    },
    createSuccess(state) {
      state.status.general = requestStatus.success;
    },
    createError(state) {
      state.status.general = requestStatus.error;
    },
    find(state) {
      state.status.find = requestStatus.loading;
    },
    findSuccess(state, action) {
      state.status.find = requestStatus.success;
      state.data.findClient = action.payload;
    },
    findError(state) {
      state.status.find = requestStatus.error;
    },
    list(state) {
      state.status.general = requestStatus.loading;
      if (state.refresh) state.refresh = false;
    },
    listSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.clients = action.payload;
    },
    listError(state) {
      state.status.general = requestStatus.error;
    },
    listArrearage(state) {
      state.status.general = requestStatus.loading;
    },
    listArrearageSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.arrearageClients = action.payload;
    },
    listArrearageError(state) {
      state.status.general = requestStatus.error;
    },
    membershipStatusCounters(state) {
      state.status.general = requestStatus.loading;
    },
    membershipStatusCountersSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.counters = action.payload;
    },
    membershipStatusCountersError(state) {
      state.status.general = requestStatus.error;
    },
    remove(state) {
      state.status.general = requestStatus.loading;
    },
    removeSuccess(state) {
      state.status.general = requestStatus.success;
      state.refresh = true;
    },
    removeError(state) {
      state.status.general = requestStatus.error;
    },
    restore(state) {
      state.status.general = requestStatus.loading;
    },
    restoreSuccess(state) {
      state.status.general = requestStatus.success;
      state.refresh = true;
    },
    restoreError(state) {
      state.status.general = requestStatus.error;
    },
    search(state) {
      state.status.search = requestStatus.loading;
    },
    searchSuccess(state, action) {
      state.status.search = requestStatus.success;
      state.data.search = action.payload;
    },
    searchError(state) {
      state.status.search = requestStatus.error;
    },
    resetFilters(state) {
      state.filters = initialFiltersState;
    },
    update(state) {
      state.status.general = requestStatus.loading;
    },
    updateSuccess(state) {
      state.status.general = requestStatus.success;
    },
    updateError(state) {
      state.status.general = requestStatus.error;
    },
    updateFilters(state, action) {
      state.filters = action.payload;
    },
    updatePagination(state, action) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    updatePaginatorInfo(state, action) {
      state.paginatorInfo = action.payload;
    },
    view(state) {
      state.status.view = requestStatus.loading;
    },
    viewSuccess(state, action) {
      state.status.view = requestStatus.success;
      state.data.viewClient = action.payload;
    },
    viewError(state) {
      state.status.view = requestStatus.error;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default clientSlice;
