import styledComponent from 'styled-components';

const StyledWeeklySessionEventAvatarContainer = styledComponent.div`
  align-items: center;
  column-gap: 0.5rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  whiteSpace: nowrap;
  @media (min-width: 900px) {
    justify-content: flex-start;
  }
`;

export default StyledWeeklySessionEventAvatarContainer;
