import React from 'react';
import { ListItemButton, styled } from '@mui/material';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { listItemTextClasses } from '@mui/material/ListItemText';

const StyledCollapsibleMenuItemButton = styled(({ noLink, ...otherProps }) => (
  <ListItemButton {...otherProps} />
))(({ noLink, theme }) => ({
  borderRadius: '10px',
  marginBottom: '10px',
  padding: noLink ? '0' : `${theme.spacing(1)} ${theme.spacing(2)}`,
  '&:hover, &.Mui-selected': {
    background:
      'linear-gradient(90deg, rgba(27, 35, 191, 0.9) 0%, #273BD7 85.18%, #273BD7 99.99%, rgba(255, 255, 255, 0) 100%)',
    [`& .${listItemTextClasses.root}`]: {
      color: theme.palette.common.white
    },
    [`& .${listItemIconClasses.root}`]: {
      color: theme.palette.common.white
    }
  }
}));

export default StyledCollapsibleMenuItemButton;
