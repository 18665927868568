import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Unstable_Grid2 as Grid } from '@mui/material';
import OverviewProfileDetail from 'components/shared/overview-profile/OverviewProfileDetail';
import StyledOverviewProfileDivider from 'common/theme/overview-profile/StyledOverviewProfileDivider';
import StyledOverviewProfileSubtitle from 'common/theme/overview-profile/StyledOverviewProfileSubtitle';

function TrainerMedicalInfo({ trainer, loading }) {
  const { t } = useTranslation(['labels']);

  return (
    <div>
      <StyledOverviewProfileSubtitle component="h4">
        {t('medicalInformation', { ns: 'labels' })}
      </StyledOverviewProfileSubtitle>
      <StyledOverviewProfileDivider />
      <Grid container spacing={{ xs: 2, md: 4 }}>
        <Grid xs={12} md={4}>
          <OverviewProfileDetail
            label={t('medicalInsuranceCompany', { ns: 'labels' })}
            value={trainer?.eps}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <OverviewProfileDetail
            label={t('emergencyContactName', { ns: 'labels' })}
            value={trainer?.contact_name}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <OverviewProfileDetail
            label={t('emergencyContactPhone', { ns: 'labels' })}
            value={trainer?.contact_phone}
            loading={loading}
          />
        </Grid>
      </Grid>
    </div>
  );
}

TrainerMedicalInfo.defaultProps = {
  trainer: null
};

TrainerMedicalInfo.propTypes = {
  trainer: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default TrainerMedicalInfo;
