import { Chip, styled } from '@mui/material';
import defaultChipStyles from 'common/theme/chips/defaultChipStyles';

const StyledIncompleteTrainerProfileChip = styled(Chip)(({ theme }) => ({
  ...defaultChipStyles,
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(2)
}));

export default StyledIncompleteTrainerProfileChip;
