import { take, takeLatest, put, call } from 'redux-saga/effects';
import authSlice from 'store/modules/auth';
import availabilitySlice from 'store/modules/availability';
import settingsSlice from 'store/modules/settings';
import uiSlice from 'store/modules/ui';
import auth from 'api/graphql/auth';
import user from 'api/graphql/user';
import { persistor } from 'store';
import { roles } from 'common/constants/options';
import {
  createSnack,
  saveAuthInfo,
  removeAuthInfo,
  getUserFriendlyError
} from 'common/utils/general';
import i18n from 'i18n';

/**
 * Generate reset token saga
 *
 * @param {*} action
 */
function* generateResetToken(action) {
  try {
    const { payload: email } = action;
    const {
      data: {
        generatePasswordResetToken: { created, message }
      }
    } = yield auth.generateResetToken(email);

    const errorSnack = createSnack({
      key: 'generate-reset-token-error',
      variant: 'error',
      message
    });

    if (created) {
      yield put(authSlice.actions.generateResetTokenSuccess());
      yield put(authSlice.actions.sendResetToken(email));
    } else {
      yield put(authSlice.actions.generateResetTokenError());
      yield put(uiSlice.actions.enqueueSnackbar(errorSnack));
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authSlice.actions.generateResetTokenSuccess(message));
  }
}

/**
 * Login saga
 *
 * @param {*} action
 */
function* login(action) {
  try {
    const response = yield auth.login(action.payload);
    const {
      data: { login: loginResponse }
    } = response;

    const { user: loggedUser } = loginResponse;
    const isTrainer = loggedUser.role === roles.trainer;

    yield call(saveAuthInfo, loginResponse);
    yield put(settingsSlice.actions.find(loggedUser.settings.id));

    yield take(settingsSlice.actions.findSuccess);
    yield put(authSlice.actions.loginSuccess(loginResponse));

    if (isTrainer) {
      const userId = loggedUser.id;
      yield put(availabilitySlice.actions.getLatest(userId));
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authSlice.actions.loginError(message));
  }
}

/**
 * Logout saga
 *
 * @param {*} action
 */
function* logout() {
  try {
    const response = yield auth.logout();
    const {
      data: { logout: logoutResponse }
    } = response;

    yield put(authSlice.actions.logoutSuccess(logoutResponse));
    yield call(removeAuthInfo);
    yield call(persistor.purge);
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authSlice.actions.logoutError(message));
  }
}

/**
 * Me saga
 *
 * @param {*} action
 */
function* me(action) {
  try {
    const response = yield user.me(action.payload);
    const {
      data: { me: currentUser }
    } = response;

    yield put(authSlice.actions.meSuccess(currentUser));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authSlice.actions.meError(message));
  }
}

/**
 * Reset password saga
 *
 * @param {*} action
 */
function* resetPassword(action) {
  try {
    const { payload } = action;
    const {
      data: {
        updatePasswordWithToken: { updated, message }
      }
    } = yield auth.resetPassword(payload);

    const snack = createSnack({
      key: updated ? 'update-password-success' : 'update-password-error',
      variant: updated ? 'success' : 'error',
      message
    });

    if (updated) {
      yield put(authSlice.actions.resetPasswordSuccess());
    } else {
      yield put(authSlice.actions.resetPasswordError());
    }

    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authSlice.actions.resetPasswordError(message));
  }
}

/**
 * Send reset token saga
 *
 * @param {*} action
 */
function* sendResetToken(action) {
  try {
    const { payload: email } = action;
    const {
      data: {
        sendPasswordResetToken: { sent, message }
      }
    } = yield auth.sendResetToken(email);

    const snack = createSnack({
      key: sent ? 'send-reset-token-success' : 'send-reset-token-error',
      variant: sent ? 'success' : 'error',
      message
    });

    if (sent) {
      yield put(authSlice.actions.sendResetTokenSuccess());
    } else {
      yield put(authSlice.actions.sendResetTokenError());
    }

    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authSlice.actions.sendResetTokenError(message));
  }
}

/**
 * Update me saga
 *
 * @param {*} action
 */
function* updateMe(action) {
  try {
    const snack = createSnack({
      key: 'auth-update-me-notification-success',
      variant: 'success',
      message: i18n.t('me.update', { ns: 'notifications' })
    });

    const response = yield user.update(action.payload);
    const {
      data: { updateUser: updateUserResponse }
    } = response;

    yield put(authSlice.actions.updateMeSuccess(updateUserResponse));
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(authSlice.actions.updateMeError(message));
  }
}

/**
 * Watch auth
 *
 * @export
 */
export default function* watchAuth() {
  yield takeLatest(authSlice.actions.generateResetToken, generateResetToken);
  yield takeLatest(authSlice.actions.login, login);
  yield takeLatest(authSlice.actions.logout, logout);
  yield takeLatest(authSlice.actions.me, me);
  yield takeLatest(authSlice.actions.resetPassword, resetPassword);
  yield takeLatest(authSlice.actions.sendResetToken, sendResetToken);
  yield takeLatest(authSlice.actions.updateMe, updateMe);
}
