import React from 'react';
import { Box, styled } from '@mui/material';

const StyledDefaultLayoutSidebar = styled(({ drawerWidth, ...otherProps }) => (
  <Box {...otherProps} />
))(({ drawerWidth, theme }) => ({
  transition: `width ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms`,
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: drawerWidth
  }
}));

export default StyledDefaultLayoutSidebar;
