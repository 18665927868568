import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Close, OpenInNew } from '@mui/icons-material';
import SessionCard from 'components/shared/cards/session/SessionCard';
import StyledSessionDetailsDialog from 'common/theme/dialog/session-details/StyledSessionDetailsDialog';
import StyledSessionDetailsDialogActions from 'common/theme/dialog/session-details/StyledSessionDetailsDialogActions';
import StyledSessionDetailsDialogButton from 'common/theme/dialog/session-details/StyledSessionDetailsDialogButton';
import StyledSessionDetailsDialogCloseButton from 'common/theme/dialog/session-details/StyledSessionDetailsDialogCloseButton';
import StyledSessionDetailsDialogTitle from 'common/theme/dialog/session-details/StyledSessionDetailsDialogTitle';
import { roles } from 'common/constants/options';
import { canClientGoToSession, canSessionBeDeleted } from 'common/utils/session';
import sessionSlice from 'store/modules/session';

function SessionDetailsDialog({ event, onClose, open, viewType }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons']);
  const [sessionData, setSessionData] = useState(null);

  useEffect(() => {
    if (!event) {
      setSessionData(null);
      return;
    }

    const { extendedProps } = event;
    setSessionData({
      id: event.id,
      client_id: extendedProps.client,
      end_date: event.end,
      language: extendedProps.language,
      session_type_id: { name: extendedProps.session_type },
      start_date: event.start,
      status: extendedProps.status,
      trainer_id: extendedProps.trainer
    });
  }, [event]);

  const handleExternalLink = () => {
    const url = sessionData?.trainer_id?.online_meeting_url;

    if (url) {
      window.open(url, '_blank');
      onClose();
    }
  };

  const handleOnDelete = () => {
    dispatch(sessionSlice.actions.forceDelete([sessionData.id]));
    onClose();
  };

  if (!sessionData) {
    return null;
  }

  return (
    <StyledSessionDetailsDialog onClose={onClose} open={open}>
      <StyledSessionDetailsDialogTitle>
        {canClientGoToSession(sessionData.end_date, sessionData.status) ? (
          <StyledSessionDetailsDialogButton
            color="secondary"
            variant="contained"
            size="small"
            endIcon={<OpenInNew />}
            disableElevation
            onClick={handleExternalLink}>
            {t('goToSession', { ns: 'buttons' })}
          </StyledSessionDetailsDialogButton>
        ) : (
          <span />
        )}
        <StyledSessionDetailsDialogCloseButton aria-label="close" onClick={onClose}>
          <Close />
        </StyledSessionDetailsDialogCloseButton>
      </StyledSessionDetailsDialogTitle>
      <SessionCard data={sessionData} options={{ viewType }} />
      {canSessionBeDeleted(sessionData.start_date, sessionData.status) &&
        viewType === roles.client && (
          <StyledSessionDetailsDialogActions>
            <StyledSessionDetailsDialogButton
              onClick={handleOnDelete}
              variant="contained"
              color="error">
              {t('delete', { ns: 'buttons' })}
            </StyledSessionDetailsDialogButton>
          </StyledSessionDetailsDialogActions>
        )}
    </StyledSessionDetailsDialog>
  );
}

SessionDetailsDialog.defaultProps = {
  event: null
};

SessionDetailsDialog.propTypes = {
  event: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  viewType: PropTypes.string.isRequired
};

export default SessionDetailsDialog;
