import { Toolbar, styled } from '@mui/material';

const StyledAppbarToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    padding: 0
  },
  [theme.breakpoints.up('lg')]: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

export default StyledAppbarToolbar;
