import { Typography, styled } from '@mui/material';

const StyledDashboardBlockTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(20)
  }
}));

export default StyledDashboardBlockTitle;
