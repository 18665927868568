import { ListItemIcon, styled } from '@mui/material';

const StyledCollapsibleMenuItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.menuItem.main,
  marginRight: '20px',
  minWidth: 'auto',
  transition: 'color .3s ease-in-out'
}));

export default StyledCollapsibleMenuItemIcon;
