import { call, takeLatest, put } from 'redux-saga/effects';
import settingsSlice from 'store/modules/settings';
import settings from 'api/graphql/settings';
import { getUserFriendlyError, saveLocalStorage } from 'common/utils/general';
import i18n from 'i18n';

/**
 * Create settings saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    yield settings.create(action.payload);
    yield put(settingsSlice.actions.createSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(settingsSlice.actions.createError(message));
  }
}

/**
 * Find settings saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield settings.find(action.payload);
    const {
      data: { settings: currentSettings }
    } = response;

    yield call(saveLocalStorage, 'otx_lang', currentSettings.language);

    // set i18n language
    i18n.changeLanguage(currentSettings.language);

    yield put(settingsSlice.actions.findSuccess(currentSettings));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(settingsSlice.actions.findError(message));
  }
}

/**
 * Update settings saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const response = yield settings.update(action.payload);
    const {
      data: { updateSettings: updatedSettings }
    } = response;

    // update language if it's different than the current one
    if (updatedSettings.language !== i18n.language) {
      // save new language to local storage
      yield call(saveLocalStorage, 'otx_lang', updatedSettings.language);
      // set i18n language
      i18n.changeLanguage(updatedSettings.language);
    }

    yield put(settingsSlice.actions.updateSuccess(updatedSettings));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(settingsSlice.actions.updateError(message));
  }
}

/**
 * Watch Settings
 *
 * @export
 */
export default function* watchSettings() {
  yield takeLatest(settingsSlice.actions.create, create);
  yield takeLatest(settingsSlice.actions.find, find);
  yield takeLatest(settingsSlice.actions.update, update);
}
