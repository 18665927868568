import { Box, styled } from '@mui/material';

const StyledAppbarLogoContainer = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '113px',
  [theme.breakpoints.up('lg')]: {
    display: 'none'
  }
}));

export default StyledAppbarLogoContainer;
