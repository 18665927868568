import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Rhombus } from 'mdi-material-ui';
import StyledMainMenuNestedButton from 'common/theme/menu/StyledMainMenuNestedButton';
import StyledMainMenuNestedLink from 'common/theme/menu/StyledMainMenuNestedLink';
import StyledMainMenuNestedListItemIcon from 'common/theme/menu/StyledMainMenuNestedListItemIcon';
import StyledMainMenuNestedListItemText from 'common/theme/menu/StyledMainMenuNestedListItemText';

function NestedMenuItem({ label, path }) {
  return (
    <StyledMainMenuNestedLink component={NavLink} to={path}>
      <StyledMainMenuNestedButton>
        <StyledMainMenuNestedListItemIcon>
          <Rhombus />
        </StyledMainMenuNestedListItemIcon>
        <StyledMainMenuNestedListItemText primary={label} />
      </StyledMainMenuNestedButton>
    </StyledMainMenuNestedLink>
  );
}

NestedMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

export default NestedMenuItem;
