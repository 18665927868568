import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { getClientColumns } from 'common/table/columns';
import { getClientData } from 'common/table/data';
import { defaultTableOptions, tableLocalization } from 'common/table/utils';

function ClientsTable({ clients, role, rowsPerPage }) {
  const { t } = useTranslation(['labels', 'routes']);
  const tableData = getClientData(clients, t);
  const columns = getClientColumns({
    data: tableData,
    includeActions: false,
    role,
    translateFunc: t
  });

  const options = {
    ...defaultTableOptions,
    jumpToPage: false,
    rowsPerPage,
    selectableRows: 'none',
    serverSide: false,
    textLabels: tableLocalization(t('client.list', { ns: 'routes' }).toLowerCase(), t)
  };

  return <MUIDataTable className="clients" columns={columns} data={tableData} options={options} />;
}

ClientsTable.defaultProps = {
  rowsPerPage: 5
};

ClientsTable.propTypes = {
  clients: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number
};

export default ClientsTable;
