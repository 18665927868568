import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import SessionCard from 'components/shared/cards/session/SessionCard';
import { roles } from 'common/constants/options';
import StyledSessionSideList from 'common/theme/session/side-list/StyledSessionSideList';
import StyledSessionSideListContainer from 'common/theme/session/side-list/StyledSessionSideListContainer';
import StyledSessionSideListHeader from 'common/theme/session/side-list/StyledSessionSideListHeader';
import StyledSessionSideListHeaderTitle from 'common/theme/session/side-list/StyledSessionSideListHeaderTitle';

function SessionsSideList({ disableReset, onReset, sessions, status, viewType }) {
  const { t } = useTranslation(['buttons', 'routes', 'sessions']);
  const hasSessions = sessions.length > 0;

  return (
    <StyledSessionSideListContainer hasSessions={hasSessions}>
      <StyledSessionSideListHeader>
        <StyledSessionSideListHeaderTitle>
          {t('session.list', { ns: 'routes' })}
        </StyledSessionSideListHeaderTitle>
        <Button
          variant="text"
          color="white"
          disabled={disableReset}
          disableElevation
          onClick={onReset}>
          {t('reset', { ns: 'buttons' })}
        </Button>
      </StyledSessionSideListHeader>
      <LoadingBlock
        emptyBlockProps={{
          imageType: 'calendar',
          imageWidth: '150px',
          message: t('noSessions', { ns: 'sessions' })
        }}
        isValid={hasSessions}
        skeletonProps={{ height: 150, rows: 3 }}
        status={status}
        type="table">
        <StyledSessionSideList spacing={2}>
          {sessions.map((session) => (
            <SessionCard data={session} options={{ viewType }} key={session.id} />
          ))}
        </StyledSessionSideList>
      </LoadingBlock>
    </StyledSessionSideListContainer>
  );
}

SessionsSideList.defaultProps = {
  disableReset: true,
  onReset: null,
  viewType: roles.administrator
};

SessionsSideList.propTypes = {
  disableReset: PropTypes.bool,
  onReset: PropTypes.func,
  sessions: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  viewType: PropTypes.string
};

export default SessionsSideList;
