import styledComponent from 'styled-components';

const StyledPerformanceHistoryChartContainer = styledComponent.div`
  height: 170px;
  @media (min-width: 1200px) {
    height: 300px;
  }
`;

export default StyledPerformanceHistoryChartContainer;
