import { Box, styled } from '@mui/material';

const StyledImageUploadInnerContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
  width: '100%'
}));

export default StyledImageUploadInnerContainer;
