import { put, takeLatest } from 'redux-saga/effects';
import { getUserFriendlyError } from 'common/utils/general';
import notificationSlice from 'store/modules/notification';
import createNotificationList from 'store/sagas/hof/notification';
import notification from 'api/graphql/notification';
import i18n from 'i18n';

/**
 * Count unread notifications saga
 *
 * @param {*} action
 */
function* countUnread(action) {
  try {
    const {
      data: { countUnreadNotifications: unreadMenuNotificationsCount }
    } = yield notification.countUnread(action.payload);

    yield put(
      notificationSlice.actions.countUnreadNotificationsSuccess(unreadMenuNotificationsCount)
    );
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(notificationSlice.actions.countUnreadNotificationsError(message));
  }
}

/**
 * Delete all notification saga
 *
 * @param {*} action
 */
function* deleteAll(action) {
  try {
    const {
      data: { deleteAllNotifications }
    } = yield notification.deleteAll(action.payload);

    if (deleteAllNotifications) {
      yield put(notificationSlice.actions.deleteAllSuccess());
    } else {
      notificationSlice.actions.deleteAllError(i18n.t('notification.deleteAll', { ns: 'errors' }));
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(notificationSlice.actions.deleteAllError(message));
  }
}

/**
 * Mark as read notification saga
 *
 * @param {*} action
 */
function* markAsRead(action) {
  try {
    const {
      data: {
        markNotificationAsRead: { id, success, user_id: userId }
      }
    } = yield notification.markAsRead(action.payload);

    if (success) {
      yield put(notificationSlice.actions.markAsReadSuccess(id));
      yield put(notificationSlice.actions.countUnreadNotifications(userId));
    } else {
      notificationSlice.actions.markAsReadError(i18n.t('notification.update', { ns: 'errors' }));
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(notificationSlice.actions.markAsReadError(message));
  }
}

/**
 * Mark all as read notification saga
 *
 * @param {*} action
 */
function* markAllAsRead(action) {
  try {
    const {
      data: { markAllNotificationsAsRead }
    } = yield notification.markAllAsRead(action.payload);

    if (markAllNotificationsAsRead) {
      yield put(notificationSlice.actions.markAllAsReadSuccess());
    } else {
      notificationSlice.actions.markAllAsReadError(
        i18n.t('notification.markAllAsRead', { ns: 'errors' })
      );
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(notificationSlice.actions.markAllAsReadError(message));
  }
}

/**
 * Watch notification
 *
 * @export
 */
export default function* watchNotification() {
  yield takeLatest(notificationSlice.actions.countUnreadNotifications, countUnread);
  yield takeLatest(notificationSlice.actions.deleteAll, deleteAll);
  yield takeLatest(
    notificationSlice.actions.listMenuNotifications,
    createNotificationList(
      notificationSlice.actions.listMenuNotificationsSuccess,
      notificationSlice.actions.listMenuNotificationsError,
      notificationSlice.actions.updateMenuPaginatorInfo
    )
  );
  yield takeLatest(notificationSlice.actions.markAsRead, markAsRead);
  yield takeLatest(notificationSlice.actions.markAllAsRead, markAllAsRead);
}
