import React from 'react';
import { Box, styled } from '@mui/material';

const StyledDashboardBlockContainer = styled(({ showLogo, sxStyles, ...otherProps }) => (
  <Box {...otherProps} />
))(({ showLogo, sxStyles, theme }) => ({
  backgroundColor: theme.palette.gray2.main,
  borderRadius: '4px',
  padding: theme.spacing(2),
  overflow: showLogo ? 'hidden' : 'auto',
  position: 'relative',
  ...sxStyles,
  [theme.breakpoints.up('md')]: {
    borderRadius: '5px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(3)
  }
}));

export default StyledDashboardBlockContainer;
