import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import SessionFieldsClientSelection from 'components/session/fields/SessionFieldsClientSelection';
import SessionFieldsObservations from 'components/session/fields/SessionFieldsObservations';
import SessionFieldsSessionDetails from 'components/session/fields/SessionFieldsSessionDetails';
import SessionFieldsTrainerSelection from 'components/session/fields/SessionFieldsTrainerSelection';
import StyledSessionFieldsSectionTitle from 'common/theme/session/fields/StyledSessionFieldsSectionTitle';
import StyledSessionFieldsTitle from 'common/theme/session/fields/StyledSessionFieldsTitle';
import { isAdmin } from 'common/utils/user';

function SessionFields({ disableClientSelection, isEditing, values }) {
  const { t } = useTranslation(['labels']);
  const role = useSelector((state) => state.auth.user.role);
  const isAdminUser = isAdmin(role);

  return (
    <>
      {isAdminUser && (
        <>
          <StyledSessionFieldsTitle component="h4">
            {t('client', { count: 1, ns: 'labels' })}
          </StyledSessionFieldsTitle>
          <Divider sx={{ marginBottom: '22px' }} />
        </>
      )}
      <SessionFieldsClientSelection values={values} isDisabled={disableClientSelection} />
      <StyledSessionFieldsSectionTitle component="h4">
        {t('sessionDetails', { ns: 'labels' })}
      </StyledSessionFieldsSectionTitle>
      <Divider sx={{ marginBottom: '22px' }} />
      <SessionFieldsSessionDetails isEditing={isEditing} values={values} />
      <StyledSessionFieldsSectionTitle component="h4">
        {t('trainer', { count: 1, ns: 'labels' })}
      </StyledSessionFieldsSectionTitle>
      <Divider sx={{ marginBottom: '22px' }} />
      <SessionFieldsTrainerSelection values={values} />
      {isAdminUser && (
        <>
          <StyledSessionFieldsSectionTitle component="h4">
            {t('observations', { ns: 'labels' })}
          </StyledSessionFieldsSectionTitle>
          <Divider sx={{ marginBottom: '22px' }} />
          <SessionFieldsObservations values={values} />
        </>
      )}
    </>
  );
}

SessionFields.defaultProps = {
  disableClientSelection: false,
  isEditing: false
};

SessionFields.propTypes = {
  disableClientSelection: PropTypes.bool,
  isEditing: PropTypes.bool,
  values: PropTypes.object.isRequired
};

export default SessionFields;
