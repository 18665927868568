import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function PriceWithCurrencyColumn({ data }) {
  const { price, currency } = data;

  return (
    <Typography variant="body2">
      {price} {currency}
    </Typography>
  );
}

PriceWithCurrencyColumn.propTypes = {
  data: PropTypes.object.isRequired
};

export default PriceWithCurrencyColumn;
