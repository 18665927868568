export const newsListFields = `
  id
  created_at
  language
  title
`;

export const newsListBasicFields = `
  id
  body
  created_at
  summary
  title
  thumbnail
`;
