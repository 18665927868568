import { Stack, styled } from '@mui/material';

const StyledClientProfileHeader = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    marginBottom: theme.spacing(4)
  }
}));

export default StyledClientProfileHeader;
