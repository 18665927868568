import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addWeeks, isBefore, isSameDay } from 'date-fns';
import {
  constructAvailabilityCalendarEvent,
  filterEventsByDateRange,
  generateAvailabilityIdentifier,
  getStartEndDatesFromEvent
} from 'common/utils/availability';
import availabilitySlice from 'store/modules/availability';

const useAvailabilityRepeat = () => {
  const dispatch = useDispatch();

  const latestAvailability = useSelector((state) => state.availability.data.latest);
  const trainerId = useSelector((state) => state.auth.user.id);

  /**
   * Checks whether an group of availabilities can be repeated based on the given date range and the latest availability.
   *
   * @callback canRepeat
   * @param  {Object}  dateRange  The date range to check for repeat.
   *
   * @return  {Boolean}  Returns `true` if the availability can be repeated, `false` otherwise.
   */
  const canRepeat = useCallback(
    (dateRange) => {
      if (!latestAvailability) return true;

      const { start_date: startDate } = latestAvailability;
      const { endDate: limitDate } = dateRange;
      const latestAvailabilityStartDate = new Date(startDate);

      return (
        isBefore(latestAvailabilityStartDate, limitDate) ||
        isSameDay(latestAvailabilityStartDate, limitDate, { weekStartsOn: 1 })
      );
    },
    [latestAvailability]
  );

  /**
   * Repeats events within a given date range for a specified number of weeks and adds them to the calendar.
   *
   * @callback handleRepeat
   * @param  {Object}  dateRange  The date range within which to repeat events.
   * @param  {Object}  calendar   The calendar to which to add the repeated events.
   */
  const handleRepeat = useCallback(
    (dateRange, calendar) => {
      const eventsInRange = filterEventsByDateRange(dateRange, calendar);
      const eventsToUpsert = [];
      const calendarEvents = [];
      const weeksToRepeat = 8;

      for (let i = 1; i <= weeksToRepeat; i += 1) {
        eventsInRange.forEach((event) => {
          const newStart = addWeeks(event.start, i);
          const newEnd = addWeeks(event.end, i);
          const { startDate: formattedStartDate, endDate: formattedEndDate } =
            getStartEndDatesFromEvent({ start: newStart, end: newEnd });
          const id = generateAvailabilityIdentifier({ start: newStart, end: newEnd });

          const availabilityCalendarEvent = constructAvailabilityCalendarEvent({
            ...event,
            id,
            start_date: newStart,
            end_date: newEnd
          });

          const availabilityToUpsert = {
            id,
            user_id: Number(trainerId),
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            stored: false
          };

          eventsToUpsert.push(availabilityToUpsert);
          calendarEvents.push(availabilityCalendarEvent);
        });
      }

      calendar.addEventSource(calendarEvents);
      dispatch(availabilitySlice.actions.repeat(eventsToUpsert));
    },
    [
      constructAvailabilityCalendarEvent,
      dispatch,
      filterEventsByDateRange,
      generateAvailabilityIdentifier,
      getStartEndDatesFromEvent,
      trainerId
    ]
  );

  return {
    canRepeat,
    handleRepeat
  };
};

export default useAvailabilityRepeat;
