import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Unstable_Grid2 as Grid } from '@mui/material';
import ClientPaymentCounter from 'components/dashboard/admin/counters/ClientPaymentCounter';
import MembershipStatusCounters from 'components/dashboard/admin/counters/MembershipStatusCounter';
import SessionStatusCounter from 'components/dashboard/admin/counters/SessionStatusCounter';
import LatestClientsTable from 'components/dashboard/admin/tables/LatestClientsTable';
import LatestSessionsTable from 'components/dashboard/admin/tables/LatestSessionsTable';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import { roles } from 'common/constants/options';
import { clientListBasicFields } from 'common/constants/query-fields/client';
import { sessionAdminCountersFields } from 'common/constants/query-fields/session';
import { convertLocalToUtcTime } from 'common/utils/date';
import AppBarContext from 'context/AppBarContext';
import clientSlice from 'store/modules/client';
import clientPaymentSlice from 'store/modules/clientPayment';
import sessionSlice from 'store/modules/session';

const clientListOrderBy = `{ column: CREATED_AT, order: DESC }`;
const queryPagination = { first: 10, page: 1 };
const itemsPerPage = 5;

function AdminDashboard() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['notFound', 'routes', 'tables']);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const clientLabel = t('client.list', { ns: 'routes' }).toLowerCase();
  const sessionLabel = t('session.list', { ns: 'routes' }).toLowerCase();
  const emptyClientsMessage = t('emptyNoFilters', { type: clientLabel, ns: 'tables' });
  const emptySessionsMessage = t('emptyNoFilters', { type: sessionLabel, ns: 'tables' });

  const firstName = useSelector((state) => state.auth.user.first_name);
  const userId = useSelector((state) => state.auth.user.id);

  const pageTitle = t('dashboard', { name: firstName, ns: 'routes' });
  const startOfMonthDate = convertLocalToUtcTime(startOfMonth(new Date()));
  const endOfMonthDate = convertLocalToUtcTime(endOfMonth(new Date()));

  useEffect(() => {
    dispatch(sessionSlice.actions.list({ pagination: queryPagination }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      sessionSlice.actions.counters({
        fields: sessionAdminCountersFields,
        filters: {
          start_between: { start_date: startOfMonthDate, end_date: endOfMonthDate },
          user_id: userId
        }
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(clientSlice.actions.membershipStatusCounters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clientPaymentSlice.actions.counters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      clientSlice.actions.list({
        fields: clientListBasicFields,
        filters: { role: roles.client },
        orderBy: clientListOrderBy,
        pagination: queryPagination
      })
    );
  }, [dispatch, clientListBasicFields]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  return (
    <>
      <SEOPageTitle title={t('home', { ns: 'routes' })} />
      <Grid container spacing={{ xs: 2, lg: 4 }}>
        <Grid xs={12} container spacing={{ xs: 1, sm: 2, md: 4 }}>
          <Grid xs={4}>
            <SessionStatusCounter />
          </Grid>
          <Grid xs={4}>
            <MembershipStatusCounters />
          </Grid>
          <Grid xs={4}>
            <ClientPaymentCounter />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <LatestSessionsTable emptyMessage={emptySessionsMessage} itemsPerPage={itemsPerPage} />
        </Grid>
        <Grid xs={12}>
          <LatestClientsTable emptyMessage={emptyClientsMessage} itemsPerPage={itemsPerPage} />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminDashboard;
