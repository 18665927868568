import { styled, Typography } from '@mui/material';

const StyledSessionFieldsSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(18)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(20),
    marginTop: '35px'
  }
}));

export default StyledSessionFieldsSectionTitle;
