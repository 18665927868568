import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import Select from 'components/shared/inputs/Select';
import { getPerformanceOptions, getTimeRangeOptions } from 'common/constants/options';
import requestStatus from 'common/constants/status';

function TopRankedControls({
  onPerformanceTestChange,
  onTimeRangeChange,
  selectedTimeRange,
  selectedPerformanceTest,
  status
}) {
  const { t } = useTranslation(['labels', 'performances']);
  const performanceTestsOptions = useMemo(() => getPerformanceOptions(t), [t]);
  const timeRangeOptions = useMemo(() => getTimeRangeOptions(t), [t]);
  const isDisabled = status === requestStatus.loading;

  const handlePerformanceTestChange = useCallback((event) => {
    onPerformanceTestChange(event.target.value);
  }, []);

  const handTimeRangeChange = useCallback((event) => {
    onTimeRangeChange(event.target.value);
  }, []);

  return (
    <Stack direction="row" spacing={2}>
      <Select
        id="performance-time-range-select"
        isDisabled={isDisabled}
        label={t('timeRange', { ns: 'labels' })}
        onChange={handTimeRangeChange}
        options={timeRangeOptions}
        value={selectedTimeRange}
      />
      <Select
        id="performance-test-select"
        isDisabled={isDisabled}
        label={t('test', { ns: 'performances' })}
        onChange={handlePerformanceTestChange}
        options={performanceTestsOptions}
        value={selectedPerformanceTest}
      />
    </Stack>
  );
}

TopRankedControls.propTypes = {
  onPerformanceTestChange: PropTypes.func.isRequired,
  onTimeRangeChange: PropTypes.func.isRequired,
  selectedPerformanceTest: PropTypes.string.isRequired,
  selectedTimeRange: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default TopRankedControls;
