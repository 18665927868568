import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Box } from '@mui/material';
import StyledProfilePictureContainer from 'common/theme/fields/profile-picture/StyledProfilePictureContainer';
import StyledProfilePictureDropzone from 'common/theme/fields/profile-picture/StyledProfilePictureDropzone';
import StyledProfilePictureIcon from 'common/theme/fields/profile-picture/StyledProfilePictureIcon';
import StyledProfilePictureIconButton from 'common/theme/fields/profile-picture/StyledProfilePictureIconButton';
import StyledProfilePictureInitials from 'common/theme/fields/profile-picture/StyledProfilePictureInitials';
import StyledProfilePictureImage from 'common/theme/fields/profile-picture/StyledProfilePictureImage';
import StyledProfilePicturePreview from 'common/theme/fields/profile-picture/StyledProfilePicturePreview';
import { createSnack } from 'common/utils/general';
import uiSlice from 'store/modules/ui';

function PreviewBox({ url }) {
  return <StyledProfilePicturePreview $url={url} />;
}

PreviewBox.propTypes = {
  url: PropTypes.string.isRequired
};

const renderPreview = ({ firstName, input, lastName, preview }) => {
  const { value } = input;
  const valueType = typeof value;

  const onLoad = () => {
    URL.revokeObjectURL(preview);
  };

  if (!value) {
    return (
      <Box>
        <StyledProfilePictureInitials variant="h1">
          {firstName.charAt(0)}
          {lastName.charAt(0)}
        </StyledProfilePictureInitials>
      </Box>
    );
  }

  return valueType === 'string' ? (
    <PreviewBox url={value} />
  ) : (
    <StyledProfilePictureImage src={preview} alt={`${firstName} ${lastName}`} onLoad={onLoad} />
  );
};

function ProfilePictureFieldAdapter({ firstName, input, lastName, ...rest }) {
  const { t } = useTranslation(['errors']);
  const dropzoneRef = createRef();
  const dispatch = useDispatch();
  const [preview, setPreview] = useState([]);

  useEffect(() => () => URL.revokeObjectURL(preview), []);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const previewUrl = URL.createObjectURL(acceptedFiles[0]);

      setPreview(previewUrl);
      input.onChange(acceptedFiles[0]);
    }
  };

  const onDropRejected = () => {
    const snack = createSnack({
      key: `upload-profile-picture-notification-error`,
      variant: 'error',
      message: t('upload.size.image', { ns: 'errors' })
    });

    dispatch(uiSlice.actions.enqueueSnackbar(snack));
  };

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <StyledProfilePictureContainer>
      <Dropzone
        accept={{
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg']
        }}
        ref={dropzoneRef}
        noClick
        noKeyboard
        maxFiles={1}
        maxSize={10000000}
        multiple={false}
        onDrop={onDrop}
        onDropRejected={onDropRejected}
        {...rest}>
        {({ getRootProps, getInputProps }) => (
          <StyledProfilePictureDropzone {...getRootProps()}>
            {renderPreview({ firstName, input, lastName, preview })}
            <input {...getInputProps()} />
            <StyledProfilePictureIconButton
              aria-label="edit"
              color="secondary"
              onClick={openDialog}>
              <StyledProfilePictureIcon />
            </StyledProfilePictureIconButton>
          </StyledProfilePictureDropzone>
        )}
      </Dropzone>
    </StyledProfilePictureContainer>
  );
}

ProfilePictureFieldAdapter.propTypes = {
  firstName: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  lastName: PropTypes.string.isRequired
};

export default ProfilePictureFieldAdapter;
