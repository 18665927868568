import { Typography, styled } from '@mui/material';

const StyledNewsCardText = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  fontSize: theme.typography.pxToRem(12),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  wordBreak: 'break-word',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(14)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(16)
  }
}));

export default StyledNewsCardText;
