import AdminCreate from 'components/admin/AdminCreate';
import AdminDashboard from 'components/dashboard/admin/AdminDashboard';
import AdminEdit from 'components/admin/AdminEdit';
import AdminList from 'components/admin/AdminList';
import AdminMore from 'components/admin/more/AdminMore';
import DefaultLayout from 'components/layout/DefaultLayout';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const adminRoutes = [
  {
    key: 'admin-dashboard',
    exact: true,
    path: routes.adminDashboard.path,
    component: AdminDashboard,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'admin-create',
    exact: true,
    path: routes.createAdmin.path,
    component: AdminCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'admin-edit',
    exact: true,
    path: routes.editAdmin.path,
    component: AdminEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'admin-list',
    exact: true,
    path: routes.listAdmin.path,
    component: AdminList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'admin-more',
    exact: true,
    path: routes.adminMore.path,
    component: AdminMore,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  }
];

export default adminRoutes;
