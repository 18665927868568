import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import { beamsRegistrationStates } from 'common/constants/general';
import { pushNotificationStatus } from 'common/constants/options';
import {
  arePushNotificationsSupported,
  failNotificationPermissionSnack,
  normalizeBasicNotification,
  normalizeSessionNotification
} from 'common/utils/notification';
import { refreshSessionsAction, updateAvailableSessionsCounterAction } from 'common/utils/session';
import BeamContext from 'context/BeamContext';
import notificationSlice from 'store/modules/notification';
import settingsSlice from 'store/modules/settings';
import uiSlice from 'store/modules/ui';

const useNotifications = () => {
  const dispatch = useDispatch();
  const { beamsClient, beamsTokenProvider } = useContext(BeamContext);
  const [beamsStarted, setBeamsStarted] = useState(false);

  const pushNotifications = useSelector((state) => state.settings.config.pushNotifications);
  const role = useSelector((state) => state.auth.user.role);
  const settingsId = useSelector((state) => state.auth.user.settings.id);
  const unreadNotificationsCount = useSelector(
    (state) => state.notification.data.unreadMenuNotificationsCount
  );
  const userId = useSelector((state) => state.auth.user.id);

  const notificationFormatters = {
    'session.created': normalizeSessionNotification,
    'session.deleted.client': normalizeSessionNotification,
    'session.deleted.trainer': normalizeSessionNotification
  };

  const startPusherBeams = useCallback(async () => {
    if (!beamsStarted && beamsClient) {
      try {
        await beamsClient.start();
        setBeamsStarted(true);

        await beamsClient.setUserId(userId, beamsTokenProvider);
        await beamsClient.setDeviceInterests(['global', role, `user_${userId}`]);
      } catch (error) {
        console.error('Error in Pusher Beams initialization: ', error);
      }
    }
  }, [beamsClient, beamsTokenProvider, role, userId, beamsStarted]);

  const debouncedStartPusherBeams = _debounce(startPusherBeams, 1000);

  const askForNotificationPermission = useCallback(() => {
    if (arePushNotificationsSupported) {
      Notification.requestPermission()
        .then((permission) => {
          dispatch(
            settingsSlice.actions.update({
              id: settingsId,
              input: { push_notifications: pushNotificationStatus[permission] }
            })
          );

          if (permission === pushNotificationStatus.granted) {
            startPusherBeams();
          }
        })
        .catch(() => {
          uiSlice.actions.enqueueSnackbar(failNotificationPermissionSnack);
        });
    }
  }, [dispatch, settingsId]);

  const countUnreadNotifications = useCallback(
    (id) => {
      dispatch(notificationSlice.actions.countUnreadNotifications(id));
    },
    [dispatch]
  );

  const deleteAllNotifications = useCallback(() => {
    dispatch(notificationSlice.actions.deleteAll(userId));
  }, [dispatch]);

  const getFormattedNotification = useCallback((notification) => {
    const formatter = notificationFormatters[notification.type] || normalizeBasicNotification;
    return formatter(notification);
  }, []);

  const sideEffectActions = (userRole, dispatchFunc, availableSessions = 0) => ({
    'session.created': () => refreshSessionsAction(userRole, dispatchFunc),
    'session.deleted.client': () => refreshSessionsAction(userRole, dispatchFunc),
    'session.deleted.trainer': () =>
      updateAvailableSessionsCounterAction(availableSessions, dispatchFunc)
  });

  const handleNotificationSideEffects = useCallback(
    (notification, normalizedNotification) => {
      const actions = sideEffectActions(role, dispatch, notification?.available_sessions);
      actions[notification.type]?.();

      dispatch(notificationSlice.actions.updateMenuNotifications(normalizedNotification));
      countUnreadNotifications(userId);
    },
    [countUnreadNotifications, dispatch, userId]
  );

  const markNotificationAsRead = useCallback(
    (notificationId) => {
      dispatch(notificationSlice.actions.markAsRead(notificationId));
    },
    [dispatch]
  );

  const markAllNotificationAsRead = useCallback(() => {
    dispatch(notificationSlice.actions.markAllAsRead(userId));
  }, [dispatch]);

  const updatePushNotificationsStatus = useCallback(
    (status) => {
      dispatch(
        settingsSlice.actions.update({
          id: settingsId,
          input: { push_notifications: status }
        })
      );
    },
    [dispatch]
  );

  const updatePushNotificationsStatusIfChanged = useCallback(
    (status, currentStatus) => {
      if (currentStatus !== status) {
        updatePushNotificationsStatus(status);
      }
    },
    [updatePushNotificationsStatus]
  );

  useEffect(() => {
    if (beamsClient) {
      beamsClient.getRegistrationState().then((state) => {
        if (state === beamsRegistrationStates.permissionGranted.notRegistered) {
          debouncedStartPusherBeams();
        }

        if (state === beamsRegistrationStates.permissionDenied) {
          updatePushNotificationsStatusIfChanged(pushNotificationStatus.denied, pushNotifications);
        }

        if (
          state === beamsRegistrationStates.permissionGranted.registered ||
          state === beamsRegistrationStates.permissionGranted.notRegistered
        ) {
          updatePushNotificationsStatusIfChanged(pushNotificationStatus.granted, pushNotifications);
        }

        if (state === beamsRegistrationStates.permissionPromptRequired) {
          updatePushNotificationsStatusIfChanged(pushNotificationStatus.default, pushNotifications);
        }
      });
    }
  }, [beamsClient]);

  return {
    askForNotificationPermission,
    countUnreadNotifications,
    deleteAllNotifications,
    getFormattedNotification,
    handleNotificationSideEffects,
    markNotificationAsRead,
    markAllNotificationAsRead,
    pushNotifications,
    startPusherBeams,
    unreadNotificationsCount,
    updatePushNotificationsStatus,
    updatePushNotificationsStatusIfChanged
  };
};

export default useNotifications;
