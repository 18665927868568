import { Button, styled } from '@mui/material';

const StyledImageUploadResetButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  position: 'absolute',
  left: theme.spacing(2),
  top: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px'
  }
}));

export default StyledImageUploadResetButton;
