import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PerformanceHistoryBarLabel from 'components/shared/charts/performance/history/PerformanceHistoryBarLabel';

function PerformanceHistoryBar({ bars, borderWidth }) {
  const calculatedBars = useMemo(
    () =>
      bars.map((bar) => {
        const { color, data: barData, height, key, width, x, y } = bar;
        const { fill } = barData;

        return (
          <React.Fragment key={key}>
            <PerformanceHistoryBarLabel bar={bar} />
            <g transform={`translate(${x}, ${y})`} key={`${key}-g`}>
              <rect
                width={width}
                height={height}
                rx="0"
                ry="0"
                fill={fill}
                strokeWidth={borderWidth}
                stroke={color}
              />
            </g>
          </React.Fragment>
        );
      }),
    [bars, borderWidth]
  );

  return calculatedBars;
}

PerformanceHistoryBar.propTypes = {
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      data: PropTypes.shape({
        fill: PropTypes.string.isRequired
      }).isRequired,
      height: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  borderWidth: PropTypes.number.isRequired
};

export default PerformanceHistoryBar;
