export const eventTimeFormat = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true
};

export const headerToolbar = {
  start: 'prev,next,today',
  center: 'title',
  end: 'timeGridWeek,dayGridMonth'
};

export const slotLabelFormat = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
};

export const titleFormat = {
  month: 'short',
  day: '2-digit',
  year: 'numeric'
};
