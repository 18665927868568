import { Box, styled } from '@mui/material';
import { typographyClasses } from '@mui/material/Typography';

const StyledMonthlySessionEventContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  [`& .${typographyClasses.root}`]: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

export default StyledMonthlySessionEventContainer;
