import { Tab, styled } from '@mui/material';
import { tabClasses } from '@mui/material/Tab';

const StyledProfileTab = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  [`& .${tabClasses.iconWrapper}`]: {
    fontSize: theme.typography.pxToRem(24),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(35)
    }
  }
}));

export default StyledProfileTab;
