import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Unstable_Grid2 as Grid } from '@mui/material';
import AvailabilityAppBarButtons from 'components/availability/AvailabilityAppBarButtons';
import AvailabilityEvent from 'components/availability/AvailabilityEvent';
import AvailabilityRepeatButton from 'components/availability/AvailabilityRepeatButton';
import AvailabilitySidebar from 'components/availability/AvailabilitySidebar';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import {
  availabilityEventTimeFormat,
  availabilityHeaderToolbar,
  availabilitySlotDuration,
  availabilitySlotLabelFormat
} from 'common/constants/availability';
import AppBarContext from 'context/AppBarContext';
import LayoutContext from 'context/LayoutContext';
import useAvailabilityCalendar from 'hooks/availability/useAvailabilityCalendar';
import useAvailabilitySidebar from 'hooks/availability/useAvailabilitySidebar';

import 'styles/full-calendar.css';

function AvailabilityCalendar() {
  const { t, i18n } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('availability.trainer', { ns: 'routes' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const { setCompactLayout, setNoBgColorLayout } = useContext(LayoutContext);

  const availabilities = useSelector((state) => state.availability.data.availabilities);

  const {
    calendarApi,
    calendarRef,
    handleChange,
    handleDateSelect,
    handleDateSet,
    handleDelete,
    selectAllow
  } = useAvailabilityCalendar();

  const { handleSessionsFilter, disableReset, handleResetSessionList, sessionsToShow } =
    useAvailabilitySidebar();

  useEffect(() => {
    setCompactLayout(true);
    setNoBgColorLayout(true);

    return () => {
      setCompactLayout(false);
      setNoBgColorLayout(false);
    };
  }, []);

  useEffect(() => {
    setAppBarButton(<AvailabilityAppBarButtons />);
    setAppBarTitle(pageTitle);
  }, [setAppBarButton, setAppBarTitle]);

  const renderEventContent = useCallback(
    (info) => (
      <AvailabilityEvent
        event={info.event}
        onDelete={handleDelete}
        onFilter={handleSessionsFilter}
      />
    ),
    [handleDelete, handleSessionsFilter]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Grid container spacing={4} disableEqualOverflow>
        <Grid xs={12} lg={3}>
          <AvailabilitySidebar
            disableReset={disableReset}
            resetList={handleResetSessionList}
            sessions={sessionsToShow}
          />
        </Grid>
        <Grid xs={12} lg={9} sx={{ position: 'relative' }}>
          <AvailabilityRepeatButton calendar={calendarApi} />
          <div className="fc-availability-calendar">
            <FullCalendar
              ref={calendarRef}
              plugins={[timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              datesSet={handleDateSet}
              firstDay={1}
              events={availabilities}
              eventTimeFormat={availabilityEventTimeFormat}
              eventContent={renderEventContent}
              eventOverlap={false}
              selectable
              selectOverlap={false}
              selectMirror
              selectAllow={selectAllow}
              height="auto"
              allDaySlot={false}
              slotDuration={availabilitySlotDuration}
              slotLabelFormat={availabilitySlotLabelFormat}
              slotMinTime="04:00:00"
              slotMaxTime="21:00:00"
              expandRows
              headerToolbar={availabilityHeaderToolbar}
              buttonText={{
                today: t('today', { ns: 'common' }),
                month: t('month', { ns: 'common' }),
                week: t('week', { ns: 'common' }),
                day: t('day', { count: 1, ns: 'common' }),
                list: t('list', { ns: 'common' })
              }}
              locale={i18n.language}
              timeZone="local"
              eventChange={handleChange}
              select={handleDateSelect}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default AvailabilityCalendar;
