import { Box, styled } from '@mui/material';

const StyledProgressGalleryThumbnail = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

export default StyledProgressGalleryThumbnail;
