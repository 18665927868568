import routes from 'common/routes';

// Layouts
import LoginLayout from 'components/layout/LoginLayout';

// Public components
import Login from 'components/auth/Login';
import ForgotPassword from 'components/auth/ForgotPassword';
import ResetPassword from 'components/auth/reset-password/ResetPassword';

import adminRoutes from 'router/routes/adminRoutes';
import availabilityRoutes from 'router/routes/availabilityRoutes';
import clientRoutes from 'router/routes/clientRoutes';
import legalRoutes from 'router/routes/legalRoutes';
import mealPlanRoutes from 'router/routes/mealPlanRoutes';
import newsRoutes from 'router/routes/newsRoutes';
import paymentRoutes from 'router/routes/paymentRoutes';
import performanceRoutes from 'router/routes/performanceRoutes';
import planRoutes from 'router/routes/planRoutes';
import progressRoutes from 'router/routes/progressRoutes';
import sessionRoutes from 'router/routes/sessionRoutes';
import sessionTypeRoutes from 'router/routes/sessionTypeRoutes';
import trainerRoutes from 'router/routes/trainerRoutes';

export const publicRoutes = [
  {
    key: 'home',
    exact: true,
    path: routes.home.path,
    component: Login,
    layout: LoginLayout
  },
  {
    key: 'login',
    exact: true,
    path: routes.login.path,
    component: Login,
    layout: LoginLayout
  },
  {
    key: 'forgotPassword',
    exact: true,
    path: routes.forgotPassword.path,
    component: ForgotPassword,
    layout: LoginLayout
  },
  {
    key: 'resetPassword',
    exact: true,
    path: routes.resetPassword.path,
    component: ResetPassword,
    layout: LoginLayout
  }
];

export const privateRoutes = [
  ...adminRoutes,
  ...availabilityRoutes,
  ...clientRoutes,
  ...legalRoutes,
  ...mealPlanRoutes,
  ...newsRoutes,
  ...paymentRoutes,
  ...performanceRoutes,
  ...planRoutes,
  ...progressRoutes,
  ...sessionRoutes,
  ...sessionTypeRoutes,
  ...trainerRoutes
];
