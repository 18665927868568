import { Divider, styled } from '@mui/material';

const StyledClientProfileHeaderDivider = styled(Divider)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block'
  }
}));

export default StyledClientProfileHeaderDivider;
