import DefaultLayout from 'components/layout/DefaultLayout';
import SessionTypeCreate from 'components/session-type/SessionTypeCreate';
import SessionTypeEdit from 'components/session-type/SessionTypeEdit';
import SessionTypeList from 'components/session-type/SessionTypeList';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const sessionTypeRoutes = [
  {
    key: 'session-type-create',
    exact: true,
    path: routes.createSessionType.path,
    component: SessionTypeCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'session-type-edit',
    exact: true,
    path: routes.editSessionType.path,
    component: SessionTypeEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'session-type-list',
    exact: true,
    path: routes.listSessionType.path,
    component: SessionTypeList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  }
];

export default sessionTypeRoutes;
