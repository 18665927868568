import { LoadingButton } from '@mui/lab';
import { buttonClasses } from '@mui/material/Button';
import { styled } from '@mui/material';

const StyledAppbarSecondaryLoadingButton = styled(LoadingButton)(({ theme }) => ({
  background: 'transparent',
  borderColor: theme.palette.common.white,
  color: theme.palette.common.white,
  fontSize: theme.typography.pxToRem(14),
  padding: '6px 16px',
  textTransform: 'none',
  '&:hover': {
    background: 'transparent',
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main
  },
  [`&.${buttonClasses.disabled}`]: {
    borderColor: theme.palette.grey[600]
  }
}));

export default StyledAppbarSecondaryLoadingButton;
