import Radio, { radioClasses } from '@mui/material/Radio';
import { styled } from '@mui/material';

const StyledRadio = styled(Radio)(({ theme }) => ({
  [`&.${radioClasses.root}`]: {
    color: theme.palette.secondary.main
  }
}));

export default StyledRadio;
