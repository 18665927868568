import _camelCase from 'lodash/camelCase';
import { format, parseISO } from 'date-fns';
import { dateFormat } from 'common/constants/date';
import { formatCurrency } from 'common/utils/format';

/**
 * Formats a date string into a specified format.
 *
 * @param  {string}  dateString  The date string to format.
 * @param  {Object}  dateLocale  Locale object for date formatting.
 *
 * @returns  {string}  The formatted date string.
 */
const formatDate = (dateString, dateLocale) =>
  format(parseISO(dateString), dateFormat, { locale: dateLocale });

/**
 * Transforms a list of payment data into a detailed structure for client display.
 *
 * @param  {Array}     data           Array of payment data objects.
 * @param  {Function}  translateFunc  Function to translate text.
 * @param  {Object}    dateLocale     Locale object for date formatting.
 *
 * @returns  {Array}  Array of transformed payment data objects.
 */
export const getClientPaymentData = (data, translateFunc, dateLocale) =>
  data.map((payment) => ({
    id: payment.id,
    created_at: formatDate(payment.created_at, dateLocale),
    currency: payment.currency,
    end_date: formatDate(payment.end_date, dateLocale),
    invoice_url: payment.invoice_url,
    observations: payment.observations,
    payment_method: translateFunc(`paymentMethod.${_camelCase(payment.payment_method)}`, {
      ns: 'options'
    }),
    plan: payment.plan_id.name,
    price: formatCurrency(payment.price),
    sessions_paid: payment.sessions_paid,
    start_date: formatDate(payment.start_date, dateLocale),
    user: payment.user_id
  }));

/**
 * Transforms a list of payment data into a basic structure for quick overview.
 *
 * @param  {Array}   data        Array of payment data objects.
 * @param  {Object}  dateLocale  Locale object for date formatting.
 *
 * @returns  {Array}  Array of simplified payment data objects.
 */
export const getClientPaymentBasicData = (data, dateLocale) =>
  data.map((payment) => ({
    created_at: formatDate(payment.created_at, dateLocale),
    currency: payment.currency,
    end_date: formatDate(payment.end_date, dateLocale),
    price: formatCurrency(payment.price),
    sessions_paid: payment.sessions_paid,
    start_date: formatDate(payment.start_date, dateLocale),
    user: payment.user_id.full_name
  }));

/**
 * Transforms a list of my client's payment data into a detailed structure.
 *
 * @param  {Array}     data           Array of payment data objects.
 * @param  {Function}  translateFunc  Function to translate text.
 * @param  {Object}    dateLocale     Locale object for date formatting.
 *
 * @returns  {Array}  Array of transformed payment data objects.
 */
export const getMyClientPaymentData = (data, translateFunc, dateLocale) =>
  data.map((payment) => ({
    id: payment.id,
    created_at: formatDate(payment.created_at, dateLocale),
    currency: payment.currency,
    end_date: formatDate(payment.end_date, dateLocale),
    invoice_url: payment.invoice_url,
    payment_method: translateFunc(`paymentMethod.${_camelCase(payment.payment_method)}`, {
      ns: 'options'
    }),
    plan: payment.plan_id.name,
    price: formatCurrency(payment.price),
    sessions_paid: payment.sessions_paid,
    start_date: formatDate(payment.start_date, dateLocale)
  }));
