import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Stack } from '@mui/material';
import EmptyResultBlock from 'components/shared/blocks/EmptyResultsBlock';
import LegalSkeleton from 'components/shared/skeletons/LegalSkeleton';
import NewsSkeleton from 'components/shared/skeletons/NewsSkeleton';
import ProgressGallerySkeleton from 'components/shared/skeletons/ProgressGallerySkeleton';
import TrainerSelectSkeleton from 'components/shared/skeletons/TrainerSelectSkeleton';
import requestStatus from 'common/constants/status';

const getSkeletonRows = (rows, height = 50) =>
  Array.from({ length: rows }, (_, i) => (
    <Skeleton key={i} variant="rounded" width="100%" height={height} />
  ));

const getSkeletonType = (type, props) => {
  const skeletonToDisplay = {
    legal: () => <LegalSkeleton />,
    news: () => <NewsSkeleton />,
    progressGallery: () => <ProgressGallerySkeleton />,
    table: () => <Stack spacing={2}>{getSkeletonRows(props.rows, props.height)}</Stack>,
    trainerSelect: () => <TrainerSelectSkeleton />,
    default: () => <Skeleton variant="rounded" width="100%" height={props.height} />
  };

  return (skeletonToDisplay[type] || skeletonToDisplay.default)();
};

function LoadingBlock({ children, emptyBlockProps, isValid, skeletonProps, status, type }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'relative' }}>
      {(status === requestStatus.idle || status === requestStatus.loading) &&
        getSkeletonType(type, skeletonProps)}
      {(status === requestStatus.error || (status === requestStatus.success && !isValid)) && (
        <EmptyResultBlock
          imageType="woman"
          imageWidth="215px"
          message={t('emptyResult', { ns: 'common' })}
          {...emptyBlockProps}
        />
      )}
      {status === requestStatus.success && isValid && children}
    </Box>
  );
}

LoadingBlock.defaultProps = {
  children: null,
  emptyBlockProps: {},
  skeletonProps: {
    height: 200,
    rows: 3
  },
  type: 'default'
};

LoadingBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([null])]),
  emptyBlockProps: PropTypes.shape({
    imageType: PropTypes.string,
    imageWidth: PropTypes.string,
    message: PropTypes.string
  }),
  isValid: PropTypes.bool.isRequired,
  skeletonProps: PropTypes.shape({
    height: PropTypes.number,
    rows: PropTypes.number
  }),
  status: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['default', 'legal', 'news', 'progressGallery', 'table', 'trainerSelect'])
};

export default LoadingBlock;
