import React from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import { OnChange } from 'react-final-form-listeners';
import { debounceWait } from 'common/constants/general';
import useCreateSession from 'hooks/session/useCreateSession';

function SessionFieldsListeners({ form }) {
  const { handleSessionFormChange } = useCreateSession();
  const debouncedFields = ['client_id', 'date', 'from'];
  const fields = ['client_id', 'date', 'from', 'session_type_id', 'language'];

  /**
   * Handles the change event for a field in the session form.
   *
   * @param  {string}  field     The name of the field being changed.
   * @param  {any}     value     The new value of the field.
   * @param  {any}     previous  The previous value of the field.
   */
  const handleChange = (field, value, previous) => {
    const debounceIfNeeded = debouncedFields.includes(field)
      ? _debounce(handleSessionFormChange, debounceWait)
      : handleSessionFormChange;

    debounceIfNeeded(field, form, value, previous);
  };

  return (
    <>
      {fields.map((field) => (
        <OnChange name={field} key={field}>
          {(value, previous) => handleChange(field, value, previous)}
        </OnChange>
      ))}
    </>
  );
}

SessionFieldsListeners.propTypes = {
  form: PropTypes.object.isRequired
};

export default SessionFieldsListeners;
