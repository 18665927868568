import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import StyledTextField from 'common/theme/fields/StyledTextfield';
import datePickerCalendarStyles from 'common/theme/fields/date-picker/datePickerCalendarStyles';
import { convertToDate, resetTimeInDate } from 'common/utils/date';

function DatePickerFieldAdapter({ input, renderInputProps, ...rest }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onChange = (value) => {
    const date = resetTimeInDate(value);
    input.onChange(date);
  };

  return (
    <DatePicker
      {...input}
      {...rest}
      closeOnSelect
      value={convertToDate(input.value)}
      onChange={onChange}
      slots={{
        textField: StyledTextField
      }}
      slotProps={{
        actionBar: {
          actions: []
        },
        mobilePaper: {
          sx: datePickerCalendarStyles(theme)
        },
        popper: {
          sx: datePickerCalendarStyles(theme)
        },
        textField: { ...renderInputProps, size },
        toolbar: {
          hidden: true
        }
      }}
    />
  );
}

DatePickerFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  renderInputProps: PropTypes.object.isRequired
};

export default DatePickerFieldAdapter;
