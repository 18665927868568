import { Typography, styled } from '@mui/material';

const StyledProfileTabPanelTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(3),
  '&:first-of-type': {
    marginTop: 0
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(20)
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(5)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(26)
  }
}));

export default StyledProfileTabPanelTitle;
