import { roles } from 'common/constants/options';
import routes from 'common/routes/index';

export const beamsRegistrationStates = {
  permissionDenied: 'PERMISSION_DENIED',
  permissionGranted: {
    registered: 'PERMISSION_GRANTED_REGISTERED_WITH_BEAMS',
    notRegistered: 'PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS'
  },
  permissionPromptRequired: 'PERMISSION_PROMPT_REQUIRED'
};

export const debounceWait = 500;

export const fullDrawerWidth = 280;

export const homePathByRole = {
  [roles.trainer]: routes.trainerDashboard.path,
  [roles.client]: routes.clientDashboard.path,
  default: routes.adminDashboard.path
};

export const defaultLottieOptions = {
  autoplay: true,
  loop: false,
  style: {
    height: '100%'
  }
};

export const miniDrawerWidth = 100;

export const placeToEmoji = {
  1: { label: 'firstPlace', symbol: '🥇' },
  2: { label: 'secondPlace', symbol: '🥈' },
  3: { label: 'thirdPlace', symbol: '🥉' },
  default: { label: 'podium', symbol: '' }
};
