import styledComponent from 'styled-components';

const StyledProgressGalleryHighlightImage = styledComponent.img`
  aspect-ratio: 2 / 3 auto;
  border-radius: 4px;
  max-width: 100%;
  width: 100%;
  @media (min-width: 900px) {
    border-radius: 5px;
  }
`;

export default StyledProgressGalleryHighlightImage;
