import DefaultLayout from 'components/layout/DefaultLayout';
import LegalCreate from 'components/legal/LegalCreate';
import LegalEdit from 'components/legal/LegalEdit';
import LegalList from 'components/legal/LegalList';
import LegalView from 'components/legal/LegalView';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const legalRoutes = [
  {
    key: 'legal-create',
    exact: true,
    path: routes.createLegal.path,
    component: LegalCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'legal-edit',
    exact: true,
    path: routes.editLegal.path,
    component: LegalEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'legal-list',
    exact: true,
    path: routes.listLegal.path,
    component: LegalList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'legal-view',
    exact: true,
    path: routes.viewLegal.path,
    component: LegalView,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'terms-and-conditions',
    exact: true,
    path: routes.viewTC.path,
    component: LegalView,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.client, roles.trainer]
  }
];

export default legalRoutes;
