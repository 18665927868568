import { hasValue } from 'common/validations/field';
import defaultTrainerFormValidations from 'common/validations/form/defaultTrainer';
import i18n from 'i18n';

const validateCreateTrainerForm = (values) => {
  const errors = defaultTrainerFormValidations(values);

  if (!hasValue(values.password)) {
    errors.password = i18n.t('emptyField', { ns: 'validations' });
  }

  return errors;
};

export default validateCreateTrainerForm;
