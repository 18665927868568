import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, Unstable_Grid2 as Grid } from '@mui/material';
import OverviewProfileDetail from 'components/shared/overview-profile/OverviewProfileDetail';
import StyledOverviewProfileDivider from 'common/theme/overview-profile/StyledOverviewProfileDivider';
import StyledOverviewProfileSubtitle from 'common/theme/overview-profile/StyledOverviewProfileSubtitle';

function TrainerWorkExperience({ trainer, loading }) {
  const { t } = useTranslation(['labels']);

  return (
    <div>
      <StyledOverviewProfileSubtitle component="h4">
        {t('workExperience', { ns: 'labels' })}
      </StyledOverviewProfileSubtitle>
      <StyledOverviewProfileDivider />
      <Grid container spacing={{ xs: 2, md: 4 }}>
        <Grid xs={12}>
          <OverviewProfileDetail
            label={t('workExperience', { ns: 'labels' })}
            value={trainer?.experience}
            loading={loading}
          />
        </Grid>
        <Grid xs={12}>
          <OverviewProfileDetail
            label={t('onlineMeetingUrl', { ns: 'labels' })}
            value={
              <Link href={trainer?.online_meeting_url} color="secondary">
                {trainer?.online_meeting_url}
              </Link>
            }
            loading={loading}
          />
        </Grid>
      </Grid>
    </div>
  );
}

TrainerWorkExperience.defaultProps = {
  trainer: null
};

TrainerWorkExperience.propTypes = {
  trainer: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default TrainerWorkExperience;
