import { Box, styled } from '@mui/material';

const StyledSessionDrawerSessionsContainer = styled(Box)(({ theme }) => ({
  maxHeight: '100%',
  overflowY: 'auto',
  '& > *': {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0
    }
  }
}));

export default StyledSessionDrawerSessionsContainer;
