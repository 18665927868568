import { Link, styled } from '@mui/material';
import { listItemIconClasses } from '@mui/material/ListItemIcon';

const StyledMainMenuNestedLink = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  textDecoration: 'none',
  width: '100%',
  '&:last-child': {
    marginBottom: '10px'
  },
  '&.active': {
    [`& .${listItemIconClasses.root}`]: {
      color: theme.palette.primary.light
    }
  }
}));

export default StyledMainMenuNestedLink;
