import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TrainerFields from 'components/trainer/TrainerFields';
import validateEditTrainerForm from 'common/validations/form/editTrainer';
import { updateTrainerInitialValues } from 'common/form/initial-values/update';
import { generatePassword, getFieldsToUpdate } from 'common/form/utils';
import getDataToSave from 'common/form/dataToSave';
import { trainerFindFields } from 'common/constants/query-fields/trainer';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import AppBarContext from 'context/AppBarContext';
import geoSlice from 'store/modules/geo';
import trainerSlice from 'store/modules/trainer';

const requiredFields = [
  'first_name',
  'last_name',
  'email',
  'birthdate',
  'phone',
  'country',
  'ui_language',
  'specialties',
  'languages'
];

function TrainerEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('trainer.edit', { ns: 'routes' });
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({ languages: [], specialties: [] });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const countries = useSelector((state) => state.geo.countries);
  const geoStatus = useSelector((state) => state.geo.status);
  const sessionTypeOptions = useSelector((state) => state.sessionType.sessionTypeOptions);
  const sessionTypeStatus = useSelector((state) => state.sessionType.status);
  const status = useSelector((state) => state.trainer.status.find);
  const trainer = useSelector((state) => state.trainer.data.findTrainer);

  useEffect(() => {
    dispatch(trainerSlice.actions.find({ fields: trainerFindFields, id }));
  }, []);

  useEffect(() => {
    if (trainer && Object.keys(trainer).length > 0 && trainer.id === id) {
      const userData = updateTrainerInitialValues(trainer);
      setInitialValues(userData);
    }
  }, [trainer]);

  useEffect(() => {
    if (geoStatus !== requestStatus.loading && countries.length <= 0) {
      dispatch(geoSlice.actions.getCountries());
    }
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onEdit = useCallback(
    (values) => {
      const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
      const dataToSave = getDataToSave(fieldsToUpdate, 'user');
      dispatch(trainerSlice.actions.update({ id, input: dataToSave }));
    },
    [dispatch, id, initialValues]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={initialValues}
        mutators={{
          generatePassword
        }}
        onSubmit={onEdit}
        validate={validateEditTrainerForm}
        render={({ form, handleSubmit, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <TrainerFields
              countries={countries}
              form={form}
              geoStatus={geoStatus}
              requestStatus={requestStatus}
              requiredFields={requiredFields}
              sessionTypeOptions={sessionTypeOptions}
              sessionTypeStatus={sessionTypeStatus}
            />
            <StyledButtonsContainer>
              <SecondaryButtonLoading
                component={RouterLink}
                disabled={status === requestStatus.loading}
                style={{ marginRight: '32px' }}
                label={t('cancel', { ns: 'buttons' })}
                to={routes.listTrainer.path}
                type="button"
              />
              <PrimaryLoadingButton
                disabled={invalid || pristine || status === requestStatus.loading}
                loading={status === requestStatus.loading}
                label={t('trainer.edit', { ns: 'buttons' })}
                type="submit"
              />
            </StyledButtonsContainer>
          </form>
        )}
      />
    </>
  );
}

export default TrainerEdit;
