import _camelCase from 'lodash/camelCase';

const createOptionsArray = (
  optionsObj,
  section,
  translator,
  translationProps = {},
  useCamelCaseKeys = false
) =>
  Object.keys(optionsObj).map((key) => ({
    value: optionsObj[key],
    label: translator(`${section}.${useCamelCaseKeys ? _camelCase(key) : key}`, {
      ...translationProps,
      ns: 'options'
    })
  }));

export const bank = {
  bancamia: 'BANCAMIA S.A.',
  agrario: 'BANCO AGRARIO',
  avVillas: 'BANCO AV VILLAS',
  bbva: 'BANCO BBVA COLOMBIA S.A.',
  cajaSocial: 'BANCO CAJA SOCIAL',
  cooperativoCoopcentral: 'BANCO COOPERATIVO COOPCENTRAL',
  credifinanciera: 'BANCO CREDIFINANCIERA',
  davivienda: 'BANCO DAVIVIENDA',
  bancoDeBogota: 'BANCO DE BOGOTA',
  bancoDeOccidente: 'BANCO DE OCCIDENTE',
  falabella: 'BANCO FALABELLA',
  finandina: 'BANCO FINANDINA S.A. BIC',
  gnbSudameris: 'BANCO GNB SUDAMERIS',
  itau: 'BANCO ITAU',
  pichincha: 'BANCO PICHINCHA S.A.',
  popular: 'BANCO POPULAR',
  santander: 'BANCO SANTANDER COLOMBIA',
  serfinanza: 'BANCO SERFINANZA',
  union: 'BANCO UNION antes GIROS',
  bancolombia: 'BANCOLOMBIA',
  bancoomeva: 'BANCOOMEVA S.A.',
  cfa: 'CFA COOPERATIVA FINANCIERA',
  citibank: 'CITIBANK',
  coltefinanciera: 'COLTEFINANCIERA',
  confiar: 'CONFIAR COOPERATIVA FINANCIERA',
  coofinep: 'COOFINEP COOPERATIVA FINANCIERA',
  cotrafa: 'COTRAFA',
  dale: 'DALE',
  daviplata: 'DAVIPLATA',
  iris: 'IRIS',
  luloBank: 'LULO BANK',
  movii: 'MOVII S.A.',
  nequi: 'NEQUI',
  rappipayDaviplata: 'RAPPIPAY DAVIPLATA',
  scotiabank: 'SCOTIABANK COLPATRIA'
};

export const bankOptions = Object.keys(bank).map((key) => ({ value: key, label: bank[key] }));

export const bankAccountType = {
  checking: 'CHECKING',
  savings: 'SAVINGS'
};

export const getBankAccountTypeOptions = (t) =>
  createOptionsArray(bankAccountType, 'bankAccountType', t);

export const currency = {
  cop: 'COP',
  usd: 'USD'
};

export const currencyOptions = Object.keys(currency).map((key) => ({
  value: currency[key],
  label: currency[key]
}));

export const gender = {
  female: 'FEMALE',
  male: 'MALE',
  other: 'OTHER'
};

export const getGenderOptions = (t) => createOptionsArray(gender, 'gender', t);

export const language = {
  spanish: 'SPANISH',
  english: 'ENGLISH',
  chinese: 'CHINESE',
  portuguese: 'PORTUGUESE',
  french: 'FRENCH',
  italian: 'ITALIAN',
  german: 'GERMAN'
};

export const getLanguageOptions = (t) => createOptionsArray(language, 'language', t);

export const membershipStatus = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  inArrears: 'IN_ARREARS'
};

export const getMembershipStatusOptions = (t) =>
  createOptionsArray(membershipStatus, 'membershipStatus', t, {
    context: gender.female.toLowerCase()
  });

export const notificationStatus = {
  all: 'ALL',
  read: 'READ',
  unread: 'UNREAD'
};

export const getNotificationStatusOptions = (t) =>
  createOptionsArray(notificationStatus, 'notificationStatus', t);

export const optionsLimit = 10;

export const paymentMethod = {
  wire: 'WIRE',
  deposit: 'DEPOSIT',
  debitCard: 'DEBIT_CARD',
  creditCard: 'CREDIT_CARD',
  cash: 'CASH'
};

export const getPaymentMethodOptions = (t) => createOptionsArray(paymentMethod, 'paymentMethod', t);

export const performanceTest = {
  burpees: 'burpees_repetitions',
  plank: 'plank_time',
  pullUp: 'pull_ups_repetitions',
  pushUp: 'push_up_repetitions',
  running: 'running_distance',
  squat: 'squats_repetitions'
};

export const getPerformanceOptions = (t) =>
  Object.keys(performanceTest).map((key) => ({
    value: performanceTest[key],
    label: t(`${key}.label`, { ns: 'labels' })
  }));

export const performanceStatus = {
  all: 'all',
  complete: false,
  incomplete: true
};

export const getCompletePerformanceOptions = (t) =>
  createOptionsArray(performanceStatus, 'performanceStatus', t);

export const pushNotificationStatus = {
  default: 'DEFAULT',
  denied: 'DENIED',
  granted: 'GRANTED'
};

export const roles = {
  administrator: 'ADMINISTRATOR',
  trainer: 'TRAINER',
  client: 'CLIENT'
};

export const getLegalRoleOptions = (t) => [
  { value: roles.client, label: t('role.client', { ns: 'options' }) },
  { value: roles.trainer, label: t('role.trainer', { ns: 'options' }) }
];

export const sessionStatus = {
  scheduled: 'SCHEDULED',
  finished: 'FINISHED',
  clientAbsent: 'CLIENT_ABSENT',
  trainerAbsent: 'TRAINER_ABSENT'
};

export const getSessionStatusOptions = (t) => createOptionsArray(sessionStatus, 'sessionStatus', t);

export const trashed = {
  only: 'ONLY',
  without: 'WITHOUT'
};

export const timeRange = {
  last3Months: 'last3Months',
  last6Months: 'last6Months',
  last9Months: 'last9Months'
};

export const getTimeRangeOptions = (t) => createOptionsArray(timeRange, 'timeRange', t);

export const getTrashedOptions = (t) => [
  { value: trashed.only, label: t('yes', { ns: 'common' }) },
  { value: trashed.without, label: t('no', { ns: 'common' }) }
];

export const uiLanguage = {
  es: {
    key: 'spanish',
    value: 'es',
    label: 'Español'
  },
  en: {
    key: 'english',
    value: 'en',
    label: 'Ingles'
  }
};

export const getUiLanguageOptions = (t) =>
  Object.entries(uiLanguage).map((lang) => ({
    value: lang[1].value,
    label: t(`language.${lang[1].key}`, { ns: 'options' })
  }));
