import React from 'react';
import PropTypes from 'prop-types';
import StyledCounterLine from 'common/theme/counter/StyledCounterLine';

function CounterLine({ text, secondary }) {
  const color = secondary ? 'secondary' : 'white';
  return <StyledCounterLine color={color}>{text}</StyledCounterLine>;
}

CounterLine.defaultProps = {
  secondary: false
};

CounterLine.propTypes = {
  text: PropTypes.string.isRequired,
  secondary: PropTypes.bool
};

export default CounterLine;
