import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import { Assignment, ChevronRight, Group, Payment, Restaurant } from '@mui/icons-material';
import {
  AccountBoxMultiple,
  ClipboardList,
  NewspaperVariantOutline,
  WeightLifter
} from 'mdi-material-ui';
import StyledMoreProfileSettingsIcon from 'common/theme/more-profile/StyledMoreProfileSettingsIcon';
import routes from 'common/routes';

function AdminMoreHub() {
  const { t } = useTranslation(['routes']);

  const listItems = [
    {
      icon: <NewspaperVariantOutline />,
      label: t('news.list', { ns: 'routes' }),
      to: routes.listNews.path
    },
    {
      icon: <WeightLifter />,
      label: t('sessionType.list', { ns: 'routes' }),
      to: routes.listSessionType.path
    },
    {
      icon: <ClipboardList />,
      label: t('plan.list', { ns: 'routes' }),
      to: routes.listPlan.path
    },
    {
      icon: <Restaurant />,
      label: t('mealPlan.list', { ns: 'routes' }),
      to: routes.listMealPlan.path
    },
    {
      icon: <AccountBoxMultiple />,
      label: t('progress.list', { ns: 'routes' }),
      to: routes.listProgress.path
    },
    {
      icon: <Payment />,
      label: t('payment.payments', { ns: 'routes' }),
      to: routes.payments.path
    },
    {
      icon: <Group />,
      label: t('users', { ns: 'routes' }),
      to: routes.users.path
    },
    {
      icon: <Assignment />,
      label: t('legal.list', { ns: 'routes' }),
      to: routes.listLegal.path
    }
  ];

  return (
    <List>
      {listItems.map((item) => (
        <ListItem
          button
          component={Link}
          divider
          key={item.label}
          secondaryAction={<ChevronRight />}
          to={item.to}
          sx={{ pl: 0 }}>
          <StyledMoreProfileSettingsIcon>{item.icon}</StyledMoreProfileSettingsIcon>
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
    </List>
  );
}

export default AdminMoreHub;
