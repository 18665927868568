import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import AvailableSessionsChip from 'components/shared/chips/AvailableSessionsChip';
import MembershipStatusChip from 'components/shared/chips/MembershipStatusChip';
import { dateFormat } from 'common/constants/date';
import { roles } from 'common/constants/options';
import StyledDrawerMenuLabel from 'common/theme/drawer-menu/StyledDrawerMenuLabel';
import StyledDrawerMenuMembershipDate from 'common/theme/drawer-menu/StyledDrawerMenuMembershipDate';
import StyledDrawerMenuUserInfoContainer from 'common/theme/drawer-menu/StyledDrawerMenuUserInfoContainer';
import StyledDrawerMenuUsername from 'common/theme/drawer-menu/StyledDrawerMenuUsername';
import StyledDrawerMenuUserStatus from 'common/theme/drawer-menu/StyledDrawerMenuUserStatus';

function DrawerUserInfo() {
  const { t, i18n } = useTranslation(['labels', 'options']);

  const availableSessions = useSelector((state) => state.auth.user.available_sessions);
  const fullName = useSelector((state) => state.auth.user.full_name);
  const gender = useSelector((state) => state.auth.user.gender);
  const joined = useSelector((state) => state.auth.user.created_at);
  const membershipStatus = useSelector((state) => state.auth.user.membership_status);
  const role = useSelector((state) => state.auth.user.role);

  const dateLocale = i18n.language === 'es' ? es : enUS;

  return (
    <>
      <StyledDrawerMenuUserInfoContainer>
        <StyledDrawerMenuUsername>{fullName}</StyledDrawerMenuUsername>
        {role !== roles.client ? (
          <>
            <StyledDrawerMenuLabel>
              {t(`role.${role.toLowerCase()}`, { ns: 'options' })}
            </StyledDrawerMenuLabel>
            <StyledDrawerMenuLabel>Otrix Team</StyledDrawerMenuLabel>
          </>
        ) : (
          <StyledDrawerMenuMembershipDate>
            {t('titanSince', { ns: 'labels' })}:{' '}
            {format(parseISO(joined), dateFormat, { locale: dateLocale })}
          </StyledDrawerMenuMembershipDate>
        )}
      </StyledDrawerMenuUserInfoContainer>
      {role === roles.client && (
        <StyledDrawerMenuUserStatus>
          <MembershipStatusChip context={gender.toLowerCase()} status={membershipStatus} />
          <AvailableSessionsChip availableSessions={availableSessions} />
        </StyledDrawerMenuUserStatus>
      )}
    </>
  );
}

export default DrawerUserInfo;
