import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import MUIDataTable from 'mui-datatables';
import { getPerformanceColumns } from 'common/table/columns/performance';
import { getPerformanceData } from 'common/table/data/performance';
import { defaultTableOptions, tableLocalization } from 'common/table/utils';

function IncompletePerformanceTable({
  onChangePage,
  pagination,
  paginatorInfo,
  performances,
  rowsPerPage
}) {
  const { t, i18n } = useTranslation(['routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const tableData = getPerformanceData(performances, dateLocale);
  const columns = getPerformanceColumns({ data: tableData, translateFunc: t, showActions: false });

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    jumpToPage: false,
    onChangePage,
    page: pagination.page - 1,
    rowsPerPage,
    selectableRows: 'none',
    textLabels: tableLocalization(t('performance.list', { ns: 'routes' }).toLowerCase(), t)
  };

  return (
    <MUIDataTable
      className="incompletePerformance"
      columns={columns}
      data={tableData}
      options={options}
    />
  );
}

IncompletePerformanceTable.propTypes = {
  onChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  paginatorInfo: PropTypes.object.isRequired,
  performances: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default IncompletePerformanceTable;
