import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import requestStatus from 'common/constants/status';

const initialState = {
  data: {
    currentNotification: null,
    menuNotifications: [],
    unreadMenuNotificationsCount: 0
  },
  pagination: {
    first: 20,
    page: 1
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0
  },
  menuPaginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0
  },
  refresh: {
    general: false,
    menu: false
  },
  status: {
    count: requestStatus.idle,
    general: requestStatus.idle,
    menu: requestStatus.idle
  }
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    countUnreadNotifications(state) {
      state.status.count = requestStatus.loading;
    },
    countUnreadNotificationsSuccess(state, action) {
      state.status.count = requestStatus.success;
      state.data.unreadMenuNotificationsCount = action.payload;
    },
    countUnreadNotificationsError(state) {
      state.status.count = requestStatus.error;
    },
    deleteAll() {},
    deleteAllSuccess(state) {
      state.data.menuNotifications = [];
      state.data.unreadMenuNotificationsCount = 0;
    },
    deleteAllError(state) {
      state.status.general = requestStatus.error;
    },
    find(state) {
      state.status.general = requestStatus.loading;
    },
    findSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.currentNotification = action.payload;
    },
    findError(state) {
      state.status.general = requestStatus.error;
    },
    list(state) {
      state.status.general = requestStatus.loading;
      if (state.refresh.general) state.refresh.general = false;
    },
    listSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.notifications = action.payload;
    },
    listError(state) {
      state.status.general = requestStatus.error;
    },
    listMenuNotifications(state) {
      state.status.menu = requestStatus.loading;
      if (state.refresh.menu) state.refresh = false;
    },
    listMenuNotificationsSuccess(state, action) {
      state.status.menu = requestStatus.success;
      state.data.menuNotifications = action.payload;
    },
    listMenuNotificationsError(state) {
      state.status.menu = requestStatus.error;
    },
    markAsRead() {},
    markAsReadSuccess(state, action) {
      const menuNotificationIndex = state.data.menuNotifications.findIndex(
        (notification) => notification.id === action.payload
      );

      if (menuNotificationIndex >= 0) {
        state.data.menuNotifications[menuNotificationIndex].read = true;
      }
    },
    markAsReadError(state) {
      state.status.general = requestStatus.error;
    },
    markAllAsRead() {},
    markAllAsReadSuccess(state) {
      state.data.menuNotifications.forEach((notification) => {
        notification.read = true;
      });
      state.data.unreadMenuNotificationsCount = 0;
    },
    markAllAsReadError(state) {
      state.status.general = requestStatus.error;
    },
    updateMenuNotifications(state, action) {
      state.data.menuNotifications.unshift(action.payload);
    },
    updatePagination(state, action) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    updatePaginatorInfo(state, action) {
      state.paginatorInfo = action.payload;
    },
    updateMenuPaginatorInfo(state, action) {
      state.menuPaginatorInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default notificationSlice;
