import React from 'react';
import { styled } from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { sessionStatus } from 'common/constants/options';

const StyledAvailabilityStatusSquare = styled(({ status, ...otherProps }) => (
  <SquareRoundedIcon {...otherProps} />
))(({ status, theme }) => ({
  color:
    status === sessionStatus.scheduled ? theme.palette.scheduled.main : theme.palette.absent.main
}));

export default StyledAvailabilityStatusSquare;
