import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import StyledAppbarSecondaryLoadingButton from 'common/theme/appbar/StyledAppbarSecondaryLoadingButton';

function AppBarPrimaryButton({ loading, onClick, label, ...rest }) {
  return (
    <StyledAppbarSecondaryLoadingButton
      {...rest}
      size="small"
      onClick={onClick}
      loading={loading}
      loadingIndicator={<CircularProgress color="secondary" size={16} />}
      variant="outlined">
      <span>{label}</span>
    </StyledAppbarSecondaryLoadingButton>
  );
}

AppBarPrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default AppBarPrimaryButton;
