import React from 'react';
import ToolbarSelect from 'components/shared/ToolbarSelect';
import { sessionStatus } from 'common/constants/options';
import { canSessionBeDeleted } from 'common/utils/session';
import { isAdmin, isClient } from 'common/utils/user';

export const defaultTableOptions = {
  download: false,
  elevation: 0,
  filter: false,
  jumpToPage: true,
  print: false,
  responsive: 'standard',
  rowsPerPageOptions: [],
  search: false,
  serverSide: true,
  sort: false,
  viewColumns: false
};

/**
 * Renders a toolbar with actions for selected rows in a table.
 *
 * @param  {Object}    params            The parameters for the toolbar.
 * @param  {Array}     params.filters    The filters applied to the table data.
 * @param  {Array}     params.rows       The currently selected rows.
 * @param  {Array}     params.data       The data being displayed in the table.
 * @param  {Function}  params.onDelete   The callback function to call when delete action is triggered.
 * @param  {Function}  params.onRestore  The callback function to call when restore action is triggered.
 *
 * @returns  {React.Component}  A toolbar component configured with the provided parameters.
 */
export const getSelectToolBar = ({ filters, rows, data, onDelete, onRestore }) => (
  <ToolbarSelect
    filters={filters}
    displayData={data}
    selectedRows={rows}
    onDelete={onDelete}
    onRestore={onRestore}
  />
);

/**
 * Gets the IDs of the selected rows from the table.
 *
 * @param  {Object}  selectedRows  Information about the selected rows.
 * @param  {Array}   displayData   The full set of data being displayed in the table.
 *
 * @returns  {Array}  An array of IDs corresponding to the selected rows.
 */
export const getSelectedIds = (selectedRows, displayData) => {
  const dataIndexes = selectedRows.data.map((selectedRow) => selectedRow.dataIndex);
  const ids = displayData.reduce((acc, curr) => {
    if (dataIndexes.includes(curr.dataIndex)) {
      acc.push(curr.data[0]);
    }

    return acc;
  }, []);

  return ids;
};

/**
 * Determines if a session is selectable based on user role and session status.
 * For admins, a session is selectable if it's scheduled.
 * For clients, a session is selectable if it's scheduled and starts at least 8 hours in the future.
 *
 * @param  {Object[]}  tableData  The array of session objects.
 * @param  {number}    rowIndex   The index of the session in the table data array.
 * @param  {string}    role       The role of the user (e.g., 'admin', 'client').
 *
 * @returns  {boolean}  True if the session is selectable, false otherwise.
 */
export const isSessionSelectable = (tableData, rowIndex, role) => {
  const session = tableData[rowIndex];

  if (isAdmin(role)) {
    return session.status === sessionStatus.scheduled;
  }

  if (isClient(role)) {
    return canSessionBeDeleted(session.start_date_raw, session.status);
  }

  return false;
};

/**
 * Creates a multiline header label for table columns.
 *
 * @param  {Object}  columnMeta  Metadata for the column, including the label.
 *
 * @returns  {React.Component}  A component with the multiline header label.
 */
export const multiLineHeaderLabel = (columnMeta) => {
  const { label } = columnMeta;
  const labelArray = label.split(',');

  const customHeaderLabel = labelArray.map((item, index) => {
    const classes =
      index === labelArray.length - 1
        ? 'multiline-header-label multiline-header-label--last'
        : 'multiline-header-label';

    return (
      <div key={item} className={classes}>
        {item}
      </div>
    );
  });

  return customHeaderLabel;
};

/**
 * Provides localization for table elements.
 *
 * @param  {string}    type           The type of table element to localize.
 * @param  {Function}  translateFunc  A function to handle the translation.
 *
 * @returns  {Object}  An object containing localized strings for different table components.
 */
export const tableLocalization = (type, translateFunc) => ({
  body: {
    noMatch: translateFunc('empty', { ns: 'tables', type })
  },
  pagination: {
    displayRows: translateFunc('of', { ns: 'common' }),
    rowsPerPage: translateFunc('rowsPerPage', { ns: 'tables' }),
    previous: translateFunc('previousPage', { ns: 'tables' }),
    next: translateFunc('nextPage', { ns: 'tables' }),
    jumpToPage: translateFunc('page', { ns: 'tables' })
  },
  toolbar: {
    viewColumns: translateFunc('viewColumns', { ns: 'tables' })
  },
  viewColumns: {
    title: translateFunc('viewColumns', { ns: 'tables' })
  },
  selectedRows: {
    text: translateFunc('selectedRows', { ns: 'tables' }),
    delete: translateFunc('delete', { ns: 'common' }),
    deleteAria: translateFunc('deleteRows', { ns: 'tables' })
  }
});
