import styledComponent from 'styled-components';

const StyledNewsImage = styledComponent.img`
  border-radius: 4px;
  max-height: 100%;
  max-width: 100%;
  @media (min-width: 900px) {
    border-radius: 5px;
  }
`;

export default StyledNewsImage;
