import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import MultipleSelectFieldAdapter from 'components/shared/field-adapters/MultipleSelectFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import FilterButtons from 'components/shared/buttons/FilterButtons';
import { getGenderOptions, getLanguageOptions, getTrashedOptions } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import StyledFiltersContainer from 'common/theme/filters/StyledFiltersContainer';
import StyledFiltersForm from 'common/theme/filters/StyledFiltersForm';
import { handleTrainerIdChange } from 'common/utils/user';
import useForms from 'hooks/useForms';
import sessionTypeSlice from 'store/modules/sessionType';
import trainerSlice from 'store/modules/trainer';

function TrainerFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels']);
  const { clearTrainerSearchResults } = useForms();

  const initialValues = useSelector((state) => state.trainer.filters);
  const searchStatus = useSelector((state) => state.trainer.status.search);
  const sessionTypeOptions = useSelector((state) => state.sessionType.sessionTypeOptions);
  const sessionTypeStatus = useSelector((state) => state.sessionType.status);
  const status = useSelector((state) => state.trainer.status.general);
  const trainerOptions = useSelector((state) => state.trainer.data.search);

  useEffect(() => {
    dispatch(sessionTypeSlice.actions.listAll());
  }, [dispatch]);

  const onFilter = useCallback(
    (values) => {
      dispatch(trainerSlice.actions.updateFilters(values));
    },
    [dispatch]
  );

  const onTrainerIdChange = handleTrainerIdChange(dispatch);

  const resetForm = useCallback(() => {
    dispatch(trainerSlice.actions.resetFilters());
  }, [dispatch]);

  return (
    <StyledFiltersContainer>
      <Form
        initialValues={initialValues}
        onSubmit={onFilter}
        render={({ handleSubmit, pristine }) => (
          <StyledFiltersForm onSubmit={handleSubmit}>
            <Grid container spacing={{ xs: 2, lg: 4 }}>
              <Grid xs={12} sm={8}>
                <Field
                  id="user_id"
                  name="user_id"
                  component={TypeaheadFieldAdapter}
                  fullWidth
                  loading={searchStatus === requestStatus.loading}
                  isMultiple
                  onClose={clearTrainerSearchResults}
                  onInputChange={onTrainerIdChange}
                  options={trainerOptions}
                  renderInputProps={{
                    label: t('trainer', { count: 2, ns: 'labels' })
                  }}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Field
                  id="specialties"
                  name="specialties"
                  component={MultipleSelectFieldAdapter}
                  options={sessionTypeOptions}
                  label={t('specialties', { ns: 'labels' })}
                  fullWidth
                  disabled={sessionTypeStatus === requestStatus.loading}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Field
                  id="languages"
                  name="languages"
                  component={MultipleSelectFieldAdapter}
                  options={getLanguageOptions(t)}
                  label={t('language', { count: 2, ns: 'labels' })}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Field
                  id="gender"
                  name="gender"
                  component={SelectFieldAdapter}
                  options={getGenderOptions(t)}
                  label={t('gender', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Field
                  id="soft_delete"
                  name="soft_delete"
                  component={SelectFieldAdapter}
                  options={getTrashedOptions(t)}
                  label={t('deleted', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
            <FilterButtons pristine={pristine} resetForm={resetForm} status={status} />
          </StyledFiltersForm>
        )}
      />
    </StyledFiltersContainer>
  );
}

export default TrainerFilters;
