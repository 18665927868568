import { Typography, styled } from '@mui/material';

const StyledNewsCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(16)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(20)
  }
}));

export default StyledNewsCardTitle;
