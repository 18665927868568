import { Box, styled } from '@mui/material';

const StyledNotificationDot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '50%',
  display: 'inline-block',
  height: '10px',
  width: '10px'
}));

export default StyledNotificationDot;
