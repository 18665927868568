import React from 'react';
import { Skeleton, Unstable_Grid2 as Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledTrainerSelectGridContainer from 'common/theme/fields/trainer-select/StyledTrainerSelectGridContainer';
import StyledTrainerSelectSkeletonItem from 'common/theme/fields/trainer-select/StyledTrainerSelectSkeletonItem';
import StyledTrainerSelectSkeletonText from 'common/theme/fields/trainer-select/StyledTrainerSelectSkeletonText';

const getBodySkeleton = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  return Array.from({ length: 3 }, (_, i) => (
    <Grid xs={12} md={3} lg={4} key={i}>
      <StyledTrainerSelectSkeletonItem>
        <Skeleton variant="circular" height={isMedium ? 30 : 24} width={isMedium ? 30 : 24} />
        <StyledTrainerSelectSkeletonText variant="text" />
      </StyledTrainerSelectSkeletonItem>
    </Grid>
  ));
};

function TrainerSelectSkeleton() {
  return (
    <StyledTrainerSelectGridContainer container spacing={2}>
      {getBodySkeleton()}
    </StyledTrainerSelectGridContainer>
  );
}

export default TrainerSelectSkeleton;
