import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import adminSlice from 'store/modules/admin';
import authSlice from 'store/modules/auth';
import availabilitySlice from 'store/modules/availability';
import clientSlice from 'store/modules/client';
import clientPaymentSlice from 'store/modules/clientPayment';
import geoSlice from 'store/modules/geo';
import legalSlice from 'store/modules/legal';
import mealPlanSlice from 'store/modules/mealPlan';
import newsSlice from 'store/modules/news';
import notificationSlice from 'store/modules/notification';
import performanceSlice from 'store/modules/performance';
import planSlice from 'store/modules/plan';
import progressSlice from 'store/modules/progress';
import routerSlice from 'store/modules/router';
import sessionSlice from 'store/modules/session';
import sessionTypeSlice from 'store/modules/sessionType';
import settingsSlice from 'store/modules/settings';
import trainerSlice from 'store/modules/trainer';
import trainerPaymentSlice from 'store/modules/trainerPayment';
import uiSlice from 'store/modules/ui';

const defaultBlackListItems = ['refresh', 'status'];

const adminPersistConfig = {
  key: 'admin',
  storage: localforage,
  blacklist: [...defaultBlackListItems, 'searchResults']
};

const authPersistConfig = {
  key: 'auth',
  storage: localforage,
  blacklist: ['passwordReset', 'status', 'tokenSent']
};

const availabilityPersistConfig = {
  key: 'availability',
  storage: localforage,
  blacklist: [...defaultBlackListItems, 'toDelete', 'toUpsert']
};

const clientPersistConfig = {
  key: 'client',
  storage: localforage,
  blacklist: [...defaultBlackListItems, 'data.search']
};

const clientPaymentPersistConfig = {
  key: 'clientPayment',
  storage: localforage,
  blacklist: defaultBlackListItems
};

const geoPersistConfig = {
  key: 'geo',
  storage: localforage,
  blacklist: ['status']
};

const legalPersistConfig = {
  key: 'legal',
  storage: localforage,
  blacklist: defaultBlackListItems
};

const mealPlanPersistConfig = {
  key: 'mealPlan',
  storage: localforage,
  blacklist: defaultBlackListItems
};

const newsPersistConfig = {
  key: 'news',
  storage: localforage,
  blacklist: [...defaultBlackListItems, 'data.search']
};

const notificationPersistConfig = {
  key: 'notification',
  storage: localforage,
  blacklist: defaultBlackListItems
};

const performancePersistConfig = {
  key: 'performance',
  storage: localforage,
  blacklist: defaultBlackListItems
};

const planPersistConfig = {
  key: 'plan',
  storage: localforage,
  blacklist: defaultBlackListItems
};

const progressPersistConfig = {
  key: 'progress',
  storage: localforage,
  blacklist: defaultBlackListItems
};

const routerPersistConfig = {
  key: 'router',
  storage: localforage
};

const sessionPersistConfig = {
  key: 'session',
  storage: localforage,
  blacklist: [...defaultBlackListItems, 'sessionsInRange']
};

const sessionTypePersistConfig = {
  key: 'sessionType',
  storage: localforage,
  blacklist: defaultBlackListItems
};

const settingsPersistConfig = {
  key: 'settings',
  storage: localforage,
  blacklist: ['status']
};

const trainerPersistConfig = {
  key: 'trainer',
  storage: localforage,
  blacklist: [...defaultBlackListItems, 'searchResults']
};

const trainerPaymentPersistConfig = {
  key: 'trainerPayment',
  storage: localforage,
  blacklist: defaultBlackListItems
};

const uiPersistConfig = {
  key: 'ui',
  storage: localforage,
  blacklist: ['notifications']
};

const createRootReducer = () =>
  combineReducers({
    admin: persistReducer(adminPersistConfig, adminSlice.reducer),
    auth: persistReducer(authPersistConfig, authSlice.reducer),
    client: persistReducer(clientPersistConfig, clientSlice.reducer),
    clientPayment: persistReducer(clientPaymentPersistConfig, clientPaymentSlice.reducer),
    geo: persistReducer(geoPersistConfig, geoSlice.reducer),
    legal: persistReducer(legalPersistConfig, legalSlice.reducer),
    mealPlan: persistReducer(mealPlanPersistConfig, mealPlanSlice.reducer),
    news: persistReducer(newsPersistConfig, newsSlice.reducer),
    notification: persistReducer(notificationPersistConfig, notificationSlice.reducer),
    performance: persistReducer(performancePersistConfig, performanceSlice.reducer),
    plan: persistReducer(planPersistConfig, planSlice.reducer),
    progress: persistReducer(progressPersistConfig, progressSlice.reducer),
    router: persistReducer(routerPersistConfig, routerSlice.reducer),
    session: persistReducer(sessionPersistConfig, sessionSlice.reducer),
    sessionType: persistReducer(sessionTypePersistConfig, sessionTypeSlice.reducer),
    settings: persistReducer(settingsPersistConfig, settingsSlice.reducer),
    trainer: persistReducer(trainerPersistConfig, trainerSlice.reducer),
    trainerPayment: persistReducer(trainerPaymentPersistConfig, trainerPaymentSlice.reducer),
    availability: persistReducer(availabilityPersistConfig, availabilitySlice.reducer),
    ui: persistReducer(uiPersistConfig, uiSlice.reducer)
  });

export default createRootReducer;
