import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Divider, Unstable_Grid2 as Grid } from '@mui/material';
import PasswordFieldAdapter from 'components/shared/field-adapters/PasswordFieldAdapter';
import StyledProfileTabPanelTitle from 'common/theme/profile/StyledProfileTabPanelTitle';

function SecurityTabPanel() {
  const { t } = useTranslation(['labels']);

  return (
    <>
      <StyledProfileTabPanelTitle component="h4">
        {t('securityInformation', { ns: 'labels' })}
      </StyledProfileTabPanelTitle>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={6}>
          <Field
            id="password"
            name="password"
            component={PasswordFieldAdapter}
            label={t('password', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="confirm_password"
            name="confirm_password"
            component={PasswordFieldAdapter}
            label={t('confirmPassword', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SecurityTabPanel;
