import { Link, styled } from '@mui/material';

const StyledMainMenuLink = styled(Link)(() => ({
  alignItems: 'center',
  display: 'flex',
  textDecoration: 'none',
  width: '100%'
}));

export default StyledMainMenuLink;
