import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import UserAvatar from 'components/shared/user/UserAvatar';
import { roles } from 'common/constants/options';
import StyledWeeklySessionEventAvatarContainer from 'common/theme/calendar/event/week/StyledWeeklySessionEventAvatarContainer';
import StyledWeeklySessionEventContainer from 'common/theme/calendar/event/week/StyledWeeklySessionEventContainer';
import StyledWeeklySessionEventContent from 'common/theme/calendar/event/week/StyledWeeklySessionEventContent';
import StyledWeeklySessionEventIndicator from 'common/theme/calendar/event/week/StyledWeeklySessionEventIndicator';
import StyledWeeklySessionEventName from 'common/theme/calendar/event/week/StyledWeeklySessionEventName';
import { isClient } from 'common/utils/user';

function WeeklySessionEvent({ event, viewType }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { extendedProps: eventProps } = event;
  const { status } = eventProps;
  const user = isClient(viewType) ? eventProps.trainer : eventProps.client;

  return (
    <StyledWeeklySessionEventContainer>
      <StyledWeeklySessionEventIndicator status={status} />
      <StyledWeeklySessionEventContent>
        <StyledWeeklySessionEventAvatarContainer>
          <UserAvatar size={isExtraSmall ? 20 : 30} textSize={isExtraSmall ? 10 : 15} user={user} />
          {isMedium && (
            <StyledWeeklySessionEventName>{user.full_name}</StyledWeeklySessionEventName>
          )}
        </StyledWeeklySessionEventAvatarContainer>
      </StyledWeeklySessionEventContent>
    </StyledWeeklySessionEventContainer>
  );
}

WeeklySessionEvent.defaultProps = {
  viewType: roles.administrator
};

WeeklySessionEvent.propTypes = {
  event: PropTypes.object.isRequired,
  viewType: PropTypes.string
};

export default WeeklySessionEvent;
