import { Box, styled } from '@mui/material';

const StyledDrawerMenuLogotypeContainer = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  width: '162px',
  [theme.breakpoints.up('lg')]: {
    margin: 0,
    width: 'auto'
  }
}));

export default StyledDrawerMenuLogotypeContainer;
