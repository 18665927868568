import { put, takeLatest } from 'redux-saga/effects';
import authSlice from 'store/modules/auth';
import sessionSlice from 'store/modules/session';
import uiSlice from 'store/modules/ui';
import session from 'api/graphql/session';
import { sessionListIdOnlyFields } from 'common/constants/query-fields/session';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import { buildSessionEvent } from 'common/utils/session';
import i18n from 'i18n';

/**
 * Counters sessions saga
 *
 * @param {*} action
 */
function* counters(action) {
  try {
    const response = yield session.counters(action.payload);
    const {
      data: { sessionCounters }
    } = response;

    yield put(sessionSlice.actions.countersSuccess(sessionCounters));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.countersError(message));
  }
}

/**
 * Create sessions saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const response = yield session.create(action.payload);
    const {
      data: {
        createSession: {
          client_id: { available_sessions: availableSessions }
        }
      }
    } = response;

    yield put(sessionSlice.actions.createSuccess());
    yield put(authSlice.actions.updateAvailableSessionsCounter(availableSessions));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.createError(message));
  }
}

/**
 * Dashboard sessions saga
 *
 * @param {*} action
 */
function* dashboard(action) {
  try {
    const response = yield session.getDashboardData(action.payload);

    const {
      data: { sessionDashboard }
    } = response;

    yield put(sessionSlice.actions.dashboardSuccess(sessionDashboard));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.dashboardError(message));
  }
}

/**
 * Find sessions saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield session.find(action.payload);
    const {
      data: { session: currentSession }
    } = response;

    yield put(sessionSlice.actions.findSuccess(currentSession));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.findError(message));
  }
}

/**
 * Force delete sessions saga
 *
 * @param {*} action
 */
function* forceDelete(action) {
  try {
    const response = yield session.forceDelete(action.payload);
    const {
      data: { deleteSessions }
    } = response;

    yield put(sessionSlice.actions.forceDeleteSuccess());
    yield put(authSlice.actions.incrementAvailableSessionsCounter(deleteSessions.length));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.forceDeleteError(message));
  }
}

/**
 * Get sessions in range saga
 *
 * @param {*} action
 */
function* getSessionsInRange(action) {
  try {
    const { dateRange, userId } = action.payload;
    const response = yield session.listAll({
      fields: sessionListIdOnlyFields,
      filters: { client_id: userId, happen_between: dateRange }
    });

    const {
      data: { sessionsAll }
    } = response;

    const sessionsInRangeObject = {
      exists: sessionsAll.length > 0,
      startDate: dateRange.start_date,
      endDate: dateRange.end_date
    };

    yield put(sessionSlice.actions.getSessionsInRangeSuccess(sessionsInRangeObject));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.getSessionsInRangeError(message));
  }
}

/**
 * List sessions saga
 *
 * @param {*} action
 */
function* list(action) {
  try {
    const {
      payload: { filters = {}, pagination, orderBy = null }
    } = action;
    const response = yield session.list({ filters, pagination, orderBy });
    const {
      data: {
        sessions: { data, paginatorInfo }
      }
    } = response;

    yield put(sessionSlice.actions.listSuccess(data));
    yield put(
      sessionSlice.actions.updatePaginatorInfo({
        hasMorePages: paginatorInfo.hasMorePages,
        lastPage: paginatorInfo.lastPage,
        total: paginatorInfo.total
      })
    );
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.listError(message));
  }
}

/**
 * List all sessions saga
 *
 * @param {*} action
 */
function* listAll(action) {
  try {
    const { fields, filters } = action.payload;
    const response = yield session.listAll({ fields, filters });

    const {
      data: { sessionsAll }
    } = response;

    yield put(sessionSlice.actions.listAllSuccess(sessionsAll));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.listAllError(message));
  }
}

/**
 * List event sessions saga
 *
 * @param {*} action
 */
function* listEvent(action) {
  try {
    const { fields, filters } = action.payload;
    const response = yield session.listAll({ fields, filters });

    const {
      data: { sessionsAll }
    } = response;

    const calendarEvents = sessionsAll.map((event) => buildSessionEvent(event, true));
    yield put(sessionSlice.actions.listEventSuccess(calendarEvents));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.listEventError(message));
  }
}

/**
 * Update session saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-session-notification-success',
      variant: 'success',
      message: i18n.t('session.update', { ns: 'notifications' })
    });

    yield session.update(action.payload);

    yield put(sessionSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionSlice.actions.updateError(message));
  }
}

/**
 * Watch session
 *
 * @export
 */
export default function* watchSession() {
  yield takeLatest(sessionSlice.actions.counters, counters);
  yield takeLatest(sessionSlice.actions.create, create);
  yield takeLatest(sessionSlice.actions.dashboard, dashboard);
  yield takeLatest(sessionSlice.actions.find, find);
  yield takeLatest(sessionSlice.actions.forceDelete, forceDelete);
  yield takeLatest(sessionSlice.actions.getSessionsInRange, getSessionsInRange);
  yield takeLatest(sessionSlice.actions.list, list);
  yield takeLatest(sessionSlice.actions.listAll, listAll);
  yield takeLatest(sessionSlice.actions.listEvent, listEvent);
  yield takeLatest(sessionSlice.actions.update, update);
}
