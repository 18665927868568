import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import Point from 'components/shared/charts/shared/Point';
import RadioLegends from 'components/shared/charts/shared/RadioLegends';
import palette from 'common/theme/palette';
import { getTotalIncomeByMonthData } from 'common/utils/charts';

const areaColors = {
  COP: palette.primary.main,
  USD: palette.finished.main
};

const legends = [
  { label: 'COP', color: palette.primary.main },
  { label: 'USD', color: palette.finished.main }
];

const customChartTheme = {
  textColor: palette.white.main,
  axis: {
    ticks: {
      text: {
        fontSize: 13,
        fontWeight: 600
      }
    }
  }
};

function TotalIncomeByMonthChart({ data }) {
  const formattedData = getTotalIncomeByMonthData(data);
  const [selectedCurrency, setSelectedCurrency] = useState(formattedData[0].id);
  const [visibleData, setVisibleData] = useState([formattedData[0]]);

  const onLegendClick = (id) => {
    setSelectedCurrency(id);
    setVisibleData([formattedData.find((item) => item.id === id)]);
  };

  return (
    <Stack spacing={2}>
      <Box sx={{ height: '220px' }}>
        <ResponsiveLine
          animate
          axisBottom={{
            orient: 'bottom',
            tickSize: 0,
            tickPadding: 50,
            tickRotation: 0,
            legend: ''
          }}
          axisLeft={null}
          axisRight={null}
          axisTop={null}
          colors={(d) => areaColors[d.id]}
          curve="natural"
          data={visibleData}
          enableArea
          enableCrosshair={false}
          enableGridX={false}
          enableGridY={false}
          enablePointLabel
          isInteractive={false}
          margin={{ top: 25, right: 25, bottom: 70, left: 25 }}
          motionConfig="molasses"
          layers={['grid', 'axes', 'areas', 'lines', Point]}
          lineWidth={4}
          legends={[]}
          pointBorderColor={{ from: 'serieColor', modifiers: [] }}
          pointBorderWidth={0}
          pointColor={{ from: 'color', modifiers: [] }}
          pointLabelYOffset={-12}
          pointSize={14}
          theme={customChartTheme}
          useMesh
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
          }}
        />
      </Box>
      <RadioLegends legends={legends} onClick={onLegendClick} selected={selectedCurrency} />
    </Stack>
  );
}

TotalIncomeByMonthChart.propTypes = {
  data: PropTypes.object.isRequired
};

export default TotalIncomeByMonthChart;
