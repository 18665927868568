import styledComponent from 'styled-components';

const StyledProfilePictureContainer = styledComponent.div`
  height: 120px;
  margin: 0 auto;
  width: 120px;
  @media (min-width: 600px) {
    margin 0;
  }
  @media (min-width: 900px) {
    height: 150px;
    width: 150px;
  }
  @media (min-width: 1200px) {
    height: 200px;
    width: 200px;
  }
`;

export default StyledProfilePictureContainer;
