import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { membershipStatus } from 'common/constants/options';
import StyledChip from 'common/theme/chips/StyledChip';

function MembershipStatusChip({ status, context }) {
  const { t } = useTranslation();

  const statusChip = {
    [membershipStatus.inactive]: () => (
      <StyledChip
        label={t('membershipStatus.inactive', { context, ns: 'options' })}
        color="error"
      />
    ),
    [membershipStatus.inArrears]: () => (
      <StyledChip
        label={t('membershipStatus.inArrears', { context, ns: 'options' })}
        color="secondary"
      />
    ),
    default: () => (
      <StyledChip
        label={t('membershipStatus.active', { context, ns: 'options' })}
        color="success"
      />
    )
  };

  return (statusChip[status] || statusChip.default)();
}

MembershipStatusChip.defaultProps = {
  context: 'female'
};

MembershipStatusChip.propTypes = {
  context: PropTypes.string,
  status: PropTypes.string.isRequired
};

export default MembershipStatusChip;
