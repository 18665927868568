import { takeLatest, put } from 'redux-saga/effects';
import trainerSlice from 'store/modules/trainer';
import uiSlice from 'store/modules/ui';
import user from 'api/graphql/user';
import createTrainerFind from 'store/sagas/hof/trainer';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import i18n from 'i18n';

/**
 * Create trainer saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'trainer-create-notification-success',
      variant: 'success',
      message: i18n.t('trainer.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield user.create(payload);
    yield put(trainerSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerSlice.actions.createError(message));
  }
}

/**
 * List trainers saga
 *
 * @param {*} action
 */
function* list(action) {
  try {
    const {
      payload: { filters, pagination }
    } = action;
    const response = yield user.trainers({ filters, pagination });
    const {
      data: {
        users: { data, paginatorInfo }
      }
    } = response;

    yield put(trainerSlice.actions.listSuccess(data));
    yield put(
      trainerSlice.actions.updatePaginatorInfo({
        hasMorePages: paginatorInfo.hasMorePages,
        lastPage: paginatorInfo.lastPage,
        total: paginatorInfo.total
      })
    );
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerSlice.actions.listError(message));
  }
}

/**
 * List available trainers saga
 *
 * @param {*} action
 */
function* listAvailable(action) {
  try {
    const { fields, filters } = action.payload;
    const response = yield user.availableTrainers({ fields, filters });

    const {
      data: { availableTrainers }
    } = response;

    yield put(trainerSlice.actions.listAvailableSuccess(availableTrainers));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerSlice.actions.listAvailableError(message));
  }
}

/**
 * Remove trainers saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deleteUsers: removedTrainers }
    } = yield user.remove(action.payload);

    const snack = createSnack({
      key: 'remove-trainers-notification-success',
      variant: 'success',
      message: i18n.t('trainer.delete', { count: removedTrainers.length, ns: 'notifications' })
    });

    yield put(trainerSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerSlice.actions.removeError(message));
  }
}

/**
 * Restore trainers saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restoreUsers: restoredTrainers }
    } = yield user.restore(action.payload);

    const snack = createSnack({
      key: 'restore-trainers-notification-success',
      variant: 'success',
      message: i18n.t('trainer.restore', { count: restoredTrainers.length, ns: 'notifications' })
    });

    yield put(trainerSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerSlice.actions.restoreError(message));
  }
}

/**
 * Search trainers saga
 *
 * @param {*} action
 */
function* search(action) {
  try {
    const { input, fields } = action.payload;
    const response = yield user.search({ input, fields });

    const {
      data: { searchUser: trainers }
    } = response;

    const options = trainers.map((option) => ({ value: option.id, label: option.full_name }));

    yield put(trainerSlice.actions.searchSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerSlice.actions.searchError(message));
  }
}

/**
 * Update trainer saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-trainer-notification-success',
      variant: 'success',
      message: i18n.t('trainer.update', { ns: 'notifications' })
    });

    yield user.update(action.payload);

    yield put(trainerSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerSlice.actions.updateError(message));
  }
}

/**
 * Watch trainer
 *
 * @export
 */
export default function* watchTrainer() {
  yield takeLatest(trainerSlice.actions.create, create);
  yield takeLatest(
    trainerSlice.actions.find,
    createTrainerFind(trainerSlice.actions.findSuccess, trainerSlice.actions.findError)
  );
  yield takeLatest(trainerSlice.actions.list, list);
  yield takeLatest(trainerSlice.actions.listAvailable, listAvailable);
  yield takeLatest(trainerSlice.actions.remove, remove);
  yield takeLatest(trainerSlice.actions.restore, restore);
  yield takeLatest(trainerSlice.actions.search, search);
  yield takeLatest(trainerSlice.actions.update, update);
  yield takeLatest(
    trainerSlice.actions.view,
    createTrainerFind(trainerSlice.actions.viewSuccess, trainerSlice.actions.viewError)
  );
}
