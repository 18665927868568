import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardBlock from 'components/shared/blocks/DashboardBlock';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import NewsCard from 'components/shared/cards/NewsCard';
import SwiperCarousel from 'components/shared/carousels/SwiperCarousel';

const customStyles = {
  backgroundColor: 'transparent',
  padding: 0
};

function LatestNewsCarousel({ slidesPerView }) {
  const { t } = useTranslation();

  const news = useSelector((state) => state.news.data.news);
  const newsStatus = useSelector((state) => state.news.status.general);

  const isValid = news.length > 0;

  return (
    <DashboardBlock title={t('latestNews', { ns: 'common' })} sxStyles={customStyles}>
      <LoadingBlock
        isValid={isValid}
        skeletonProps={{
          height: 150
        }}
        status={newsStatus}>
        <SwiperCarousel
          items={news}
          slidesPerView={slidesPerView}
          renderItem={(item) => <NewsCard data={item} key={item.id} />}
        />
      </LoadingBlock>
    </DashboardBlock>
  );
}

LatestNewsCarousel.defaultProps = {
  slidesPerView: 1
};

LatestNewsCarousel.propTypes = {
  slidesPerView: PropTypes.number
};

export default LatestNewsCarousel;
