const performanceRoutes = {
  clientPerformances: {
    path: '/performance-history'
  },
  createPerformance: {
    path: '/performances/create'
  },
  editPerformance: {
    path: '/performances/edit/:id'
  },
  listPerformance: {
    path: '/performances/list'
  },
  performanceDashboard: {
    path: '/performances'
  }
};

export default performanceRoutes;
