import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet';
import Authenticated from 'components/auth';
import BeamContextProvider from 'context/BeamContextProvider';
import LayoutContextProvider from 'context/LayoutContextProvider';
import { publicRoutes, privateRoutes } from 'router/routes';
import RouteSync from 'router/RouteSync';

function Router() {
  return (
    <BrowserRouter>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <Helmet>
          <title>Otrix Wellness Club</title>
        </Helmet>
        <RouteSync />
        <BeamContextProvider>
          <LayoutContextProvider>
            <Routes>
              {privateRoutes.map(
                ({ key, path, component: Component, layout: Layout, allowedRoles }) => (
                  <Route
                    key={key}
                    path={path}
                    element={
                      <Authenticated allowedRoles={allowedRoles}>
                        <Layout>
                          <Component />
                        </Layout>
                      </Authenticated>
                    }
                  />
                )
              )}

              {publicRoutes.map(({ key, path, component: Component, layout: Layout }) => (
                <Route
                  key={key}
                  path={path}
                  element={
                    <Layout>
                      <Component />
                    </Layout>
                  }
                />
              ))}
            </Routes>
          </LayoutContextProvider>
        </BeamContextProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default Router;
