import { Button, styled } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';
import { svgIconClasses } from '@mui/material/SvgIcon';

const StyledMainMenuCollapseButton = styled(Button)(({ theme }) => ({
  color: theme.palette.menuItem.main,
  fontWeight: 700,
  justifyContent: 'flex-start',
  padding: `10px 20px 10px ${theme.spacing(3)}`,
  textTransform: 'capitalize',
  width: '100%',
  '&:hover': {
    color: theme.palette.white.main
  },
  [`& .${buttonClasses.startIcon}`]: {
    marginRight: '20px'
  },
  [`& .${buttonClasses.endIcon}`]: {
    color: theme.palette.white.main,
    marginRight: 0,
    marginLeft: 'auto',
    [`& .${svgIconClasses.root}`]: {
      width: '16px'
    }
  }
}));

export default StyledMainMenuCollapseButton;
