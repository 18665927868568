import { hasValue, email, password } from 'common/validations/field';
import i18n from 'i18n';

const validateLoginForm = (values) => {
  const errors = {};

  if (!email(values.username)) {
    errors.username = i18n.t('email', { ns: 'validations' });
  }

  if (!hasValue(values.password)) {
    errors.password = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.password) && !password(values.password)) {
    errors.password = i18n.t('password', { ns: 'validations' });
  }

  return errors;
};

export default validateLoginForm;
