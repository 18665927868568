import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import StyledCounterContainer from 'common/theme/counter/StyledCounterContainer';

import { ReactComponent as Logo } from 'images/logo.svg';

const logoStyles = {
  height: '100%',
  opacity: 0.04,
  position: 'absolute',
  right: 0,
  top: 0,
  transform: 'translateX(50%)'
};

function SingleCounterContainer({ children }) {
  return (
    <StyledCounterContainer textAlign="center">
      <Logo style={logoStyles} />
      <Stack spacing={1.5}>{children}</Stack>
    </StyledCounterContainer>
  );
}

SingleCounterContainer.propTypes = {
  children: PropTypes.array.isRequired
};

export default SingleCounterContainer;
