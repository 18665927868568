import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Box, Button } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import StyledTextField from 'common/theme/fields/StyledTextfield';
import { createSnack } from 'common/utils/general';
import uiSlice from 'store/modules/ui';

function FileUploadAdapter({ allowedFileTypes, input, meta, ...rest }) {
  const { t } = useTranslation(['buttons', 'errors', 'labels']);
  const dropzoneRef = useRef();
  const dispatch = useDispatch();
  const [filename, setFilename] = useState('');

  useEffect(() => {
    if ('value' in input && input.value && input.value.length > 0) {
      const valueArray = input.value.split('/');
      setFilename(valueArray.at(-1));
    }
  }, [input]);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFilename(acceptedFiles[0].name);
      input.onChange(acceptedFiles[0]);
    }
  };

  const onDropRejected = () => {
    const snack = createSnack({
      key: `upload-file-notification-error`,
      variant: 'error',
      message: t('upload.size.file', { ns: 'errors' })
    });

    dispatch(uiSlice.actions.enqueueSnackbar(snack));
  };

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <Dropzone
      accept={allowedFileTypes}
      ref={dropzoneRef}
      noClick
      noKeyboard
      maxFiles={1}
      maxSize={10000000}
      multiple={false}
      onDrop={onDrop}
      onDropRejected={onDropRejected}
      {...rest}>
      {({ getRootProps, getInputProps }) => (
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex'
          }}
          {...getRootProps()}>
          <input {...getInputProps()} />
          <StyledTextField
            label="Outlined"
            variant="outlined"
            placeholder={t('selectAFile', { ns: 'labels' })}
            value={filename}
            error={meta.invalid}
            helperText={meta.invalid ? meta.error : ''}
            sx={{
              flexGrow: 1,
              marginRight: (theme) => theme.spacing(2)
            }}
            {...rest}
          />
          <Button
            variant="contained"
            color="secondary"
            startIcon={<UploadFile />}
            onClick={openDialog}
            sx={{
              height: '47px'
            }}>
            {t('upload', { ns: 'buttons' })}
          </Button>
        </Box>
      )}
    </Dropzone>
  );
}

FileUploadAdapter.propTypes = {
  allowedFileTypes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default FileUploadAdapter;
