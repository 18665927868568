import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'date-fns';
import StyledHourPickerOption from 'common/theme/fields/hour-picker/StyledHourPickerOption';

function HourPickerOption({ disablePast, hour, onClick, selectedHour }) {
  const isSelected = selectedHour && isEqual(hour.value, selectedHour);
  const isDisabled = disablePast && hour.value < new Date();

  const handleClick = () => {
    onClick(hour.value);
  };

  return (
    <StyledHourPickerOption
      disabled={isDisabled}
      label={hour.label}
      onClick={handleClick}
      color={isSelected ? 'secondary' : 'default'}
      variant={isSelected ? 'filled' : 'outlined'}
    />
  );
}

HourPickerOption.defaultProps = {
  selectedHour: null
};

HourPickerOption.propTypes = {
  disablePast: PropTypes.bool.isRequired,
  hour: PropTypes.shape({
    value: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedHour: PropTypes.object
};

export default HourPickerOption;
