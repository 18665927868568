import { Box, styled } from '@mui/material';

const StyledTabPanel = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(0)}`,
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)}`
  },
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(3)}`
  }
}));

export default StyledTabPanel;
