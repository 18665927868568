import React from 'react';
import { formatRelative } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import SessionCreatedNotification from 'components/notifications/SessionCreatedNotification';
import SessionDeletedNotification from 'components/notifications/SessionDeletedNotification';
import { roles } from 'common/constants/options';
import { createSnack } from 'common/utils/general';
import i18n from 'i18n';

export const arePushNotificationsSupported = 'Notification' in window;

export const failNotificationPermissionSnack = createSnack({
  key: 'push-notification-permission-fail',
  variant: 'error',
  message: i18n.t('notification.pushNotificationFail', { ns: 'errors' })
});

const formatCreationDate = (date, locale) => {
  const targetDate = date ? new Date(`${date}Z`) : new Date();

  return formatRelative(targetDate, new Date(), {
    locale: locale === 'es' ? es : enUS,
    weekStartsOn: 1
  });
};

export const getNotificationType = (notification) => {
  const notificationToRender = {
    'session.created': () => <SessionCreatedNotification notification={notification} />,
    'session.deleted.client': () => (
      <SessionDeletedNotification deletedBy={roles.client} notification={notification} />
    ),
    'session.deleted.trainer': () => (
      <SessionDeletedNotification deletedBy={roles.trainer} notification={notification} />
    )
  };

  return notificationToRender[notification.type]();
};

const normalizeNotification = ({ id, created_at: createdAt, read, type, ...otherProps }) => ({
  id,
  created_at: formatCreationDate(createdAt, i18n.language),
  read: read || false,
  type,
  ...otherProps
});

export const normalizeDatabaseNotification = (notification) => {
  const data = JSON.parse(notification.data);
  const { type, ...cleanData } = data;

  return normalizeNotification({
    ...notification,
    data: cleanData,
    type
  });
};

export const normalizeBasicNotification = (notification) => normalizeNotification(notification);

export const normalizeSessionNotification = (notification) =>
  normalizeNotification({
    ...notification,
    data: {
      session: notification.session
    }
  });
