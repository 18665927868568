export const userAvailableSessionsFields = `
  available_sessions
`;

export const userMembershipFields = `
  available_sessions
  membership_status
  membership_days_left
`;

export const userAvatarFields = `
  id
  first_name
  full_name
  last_name
`;

export const userLoginFields = `
  id
  academic_formation
  age
  available_sessions
  bank
  bank_account_certificate
  bank_account_number
  bank_account_type
  birthdate
  city
  contact_name
  contact_phone
  country
  created_at
  email
  eps
  experience
  first_name
  full_name
  gender
  identification_document
  identification_number
  languages
  last_name
  membership_days_left
  membership_status
  observations
  online_meeting_url
  phone
  photo
  plan_id {
    id
    description
    name
    price
    sessions
  }
  profile_complete
  role
  settings {
    id
  }
  specialties {
    id
    name
  }
  state
`;

export const userSearchFields = `
  id
  full_name
`;

export const userUpdateFields = `
  academic_formation
  age
  bank
  bank_account_certificate
  bank_account_number
  bank_account_type
  birthdate
  city
  contact_name
  contact_phone
  country
  email
  eps
  experience
  first_name
  full_name
  gender
  identification_document
  identification_number
  languages
  last_name
  observations
  online_meeting_url
  phone
  photo
  plan_id {
    id
    description
    name
    price
    sessions
  }
  profile_complete
  specialties {
    id
    name
  }
  state
`;
