import { FormHelperText, InputLabel, OutlinedInput, styled } from '@mui/material';

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    color: '#E3E7EB',
    '&.Mui-focused': {
      color: theme.palette.common.white
    },
    '&.Mui-error': {
      color: '#F6B1B1'
    },
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
      '.MuiInputLabel-asterisk': {
        color: theme.palette.action.disabled
      }
    }
  },
  '& .MuiInputLabel-asterisk': {
    color: '#E3E7EB',
    '&.Mui-error': {
      color: '#F6B1B1'
    }
  }
}));

export const StyledOutlineInput = styled(OutlinedInput)(({ theme }) => ({
  '&.MuiOutlinedInput-root': {
    [theme.breakpoints.up('lg')]: {
      height: '47px'
    },
    '& fieldset': {
      border: '1px solid #E3E7EB'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.main
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: theme.palette.secondary.main
      },
      '& .MuiIconButton-root': {
        color: theme.palette.common.white
      }
    },
    '&.Mui-error': {
      '& fieldset': {
        borderColor: '#F6B1B1'
      },
      '& .MuiIconButton-root': {
        color: '#F6B1B1'
      }
    },
    '&.Mui-disabled': {
      '& fieldset': {
        borderColor: theme.palette.action.disabled
      },
      '& .MuiIconButton-root': {
        color: theme.palette.action.disabled
      }
    }
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.common.white,
    '&.Mui-disabled': {
      color: theme.palette.action.disabled
    }
  },
  '& .MuiIconButton-root': {
    color: '#E3E7EB'
  }
}));

export const StyledFormHelperText = styled(FormHelperText)({
  '&.MuiFormHelperText-root': {
    marginLeft: 0,
    '&.Mui-error': {
      color: '#F6B1B1'
    }
  }
});
