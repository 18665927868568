import React from 'react';
import PropTypes from 'prop-types';
import { Unstable_Grid2 as Grid } from '@mui/material';
import StyledCounterContainer from 'common/theme/counter/StyledCounterContainer';
import StyledDoubleCounterTitle from 'common/theme/counter/StyledDoubleCounterTitle';

import { ReactComponent as Logo } from 'images/logo.svg';

const logoStyles = {
  height: '100%',
  opacity: 0.04,
  position: 'absolute',
  right: 0,
  top: 0,
  transform: 'translateX(50%)'
};

function DoubleCounterContainer({ title, emoji, children }) {
  return (
    <StyledCounterContainer double>
      <Logo style={logoStyles} />
      <StyledDoubleCounterTitle>
        {title} {emoji}
      </StyledDoubleCounterTitle>
      <Grid container spacing={2} sx={{ position: 'relative', zIndex: 1 }}>
        {children}
      </Grid>
    </StyledCounterContainer>
  );
}

DoubleCounterContainer.defaultProps = {
  emoji: null
};

DoubleCounterContainer.propTypes = {
  title: PropTypes.string.isRequired,
  emoji: PropTypes.object,
  children: PropTypes.array.isRequired
};

export default DoubleCounterContainer;
