import { gql } from '@apollo/client';
import client from 'apollo/client';
import { getPlanFilters } from 'common/filters/requestFilters';

/**
 * Create plan method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createPlan($input: CreatePlanInput!) {
        createPlan(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Find plan method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query plan($id: ID!) {
        plan(id: $id) {
          id
          currency
          name
          price
          sessions
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * List plans method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { pagination, filters } = payload;
  const planFilters = getPlanFilters(filters);

  return client.query({
    query: gql`
      query plans {
        plans(
          first: ${pagination.first}
          page: ${pagination.page}
          ${planFilters.currency}
          ${planFilters.soft_delete}
          orderBy: {
            column: NAME,
            order: ASC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            id
            currency
            name
            price
            sessions
            clients
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * List all plans method
 * @returns {Promise}
 */
const listAll = () =>
  client.query({
    query: gql`
      query allPlans {
        allPlans {
          id
          name
        }
      }
    `,
    fetchPolicy: 'network-only'
  });

/**
 * Remove plans method
 * @param  {Array} ids
 * @return {Promise}
 */
const remove = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deletePlans($id: [ID!]!) {
        deletePlans(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Restore plans method
 * @param  {Array} ids
 * @return {Promise}
 */
const restore = (ids) =>
  client.mutate({
    mutation: gql`
      mutation restorePlans($id: [ID!]!) {
        restorePlans(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Update plan method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updatePlan($id: ID!, $input: UpdatePlanInput!) {
        updatePlan(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  create,
  find,
  list,
  listAll,
  remove,
  restore,
  update
};
