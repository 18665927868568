import { Box, styled } from '@mui/material';

const StyledDrawerMenuLogoContainer = styled(Box)(() => ({
  '& svg': {
    height: 'auto',
    width: '100%'
  }
}));

export default StyledDrawerMenuLogoContainer;
