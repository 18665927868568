import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DoubleCounter from 'components/shared/counters/double/DoubleCounter';
import Emoji from 'components/shared/Emoji';

function SessionStatusCounters() {
  const { t } = useTranslation(['sessions']);

  const finishedThisMonthSessionsCounter = useSelector(
    (state) => state.session.counters.finishedThisMonth
  );
  const absentThisMonthSessionsCounter = useSelector(
    (state) => state.session.counters.absentThisMonth
  );

  return (
    <DoubleCounter
      emoji={<Emoji label={t('sessionStatus', { ns: 'sessions' })} symbol="📅" />}
      title={t('monthlySessionStatus', { ns: 'sessions' })}
      counters={[
        {
          title: t('finished', { ns: 'sessions' }),
          counter: finishedThisMonthSessionsCounter
        },
        {
          title: t('absent', { ns: 'sessions' }),
          counter: absentThisMonthSessionsCounter
        }
      ]}
    />
  );
}

export default SessionStatusCounters;
