import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import AppBarButtonLink from 'components/shared/buttons/AppBarButtonLink';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import NewsFilters from 'components/news/NewsFilters';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import { newsListFields } from 'common/constants/query-fields/news';
import { getNewsColumns } from 'common/table/columns';
import { getNewsData } from 'common/table/data';
import { defaultTableOptions, getSelectToolBar, tableLocalization } from 'common/table/utils';
import routes from 'common/routes';
import AppBarContext from 'context/AppBarContext';
import newsSlice from 'store/modules/news';

function NewsList() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['buttons', 'labels', 'routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const pageTitle = t('news.list', { ns: 'routes' });
  const emptyMessage = t('empty', { type: pageTitle.toLowerCase(), ns: 'tables' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const filters = useSelector((state) => state.news.filters);
  const news = useSelector((state) => state.news.data.news);
  const pagination = useSelector((state) => state.news.pagination);
  const paginatorInfo = useSelector((state) => state.news.paginatorInfo);
  const refresh = useSelector((state) => state.news.refresh);
  const status = useSelector((state) => state.news.status.general);

  const listNews = useCallback(() => {
    dispatch(newsSlice.actions.list({ fields: newsListFields, filters, pagination }));
  }, [dispatch, filters, newsListFields, pagination]);

  useEffect(() => {
    listNews();
  }, [listNews, refresh]);

  useEffect(() => {
    setAppBarButton(
      <AppBarButtonLink label={t('add', { ns: 'buttons' })} path={routes.createNews.path} />
    );
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const tableData = getNewsData(news, t, dateLocale);
  const columns = getNewsColumns({ data: tableData, translateFunc: t });

  const onChangePage = (page) => {
    dispatch(newsSlice.actions.updatePagination({ page: page + 1 }));
  };

  const onDelete = (ids) => {
    dispatch(newsSlice.actions.remove(ids));
  };

  const onRestore = (ids) => {
    dispatch(newsSlice.actions.restore(ids));
  };

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    customToolbarSelect: (selectedRows, displayData) =>
      getSelectToolBar({
        filters,
        rows: selectedRows,
        data: displayData,
        onDelete,
        onRestore
      }),
    onChangePage,
    page: pagination.page - 1,
    rowsPerPage: pagination.first,
    textLabels: tableLocalization(pageTitle.toLowerCase(), t)
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <NewsFilters />
      <Box
        sx={{
          position: 'relative'
        }}>
        <LoadingBlock
          emptyBlockProps={{ message: emptyMessage }}
          isValid={news.length > 0}
          skeletonProps={{ rows: 5 }}
          status={status}
          type="table">
          <MUIDataTable className="news" columns={columns} data={tableData} options={options} />
        </LoadingBlock>
      </Box>
    </>
  );
}

export default NewsList;
