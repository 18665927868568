import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import Emoji from 'components/shared/Emoji';
import routes from 'common/routes';
import StyledNavigationLink from 'common/theme/links/StyledNavigationLink';

function ResetPasswordSuccessMessage() {
  const { t } = useTranslation(['auth', 'buttons', 'labels', 'routes']);

  const emojiStyles = {
    display: 'block',
    fontSize: '4rem',
    textAlign: 'center'
  };

  return (
    <Stack spacing={2} useFlexGap sx={{ width: '100%' }}>
      <Stack spacing={1}>
        <Emoji symbol="🎉" label="party-popper" customStyles={emojiStyles} />
        <Typography variant="h5" align="center" component="h1" sx={{ fontWeight: 500 }}>
          {t('resetPassword.successTitle', { ns: 'auth' })}
        </Typography>
      </Stack>
      <StyledNavigationLink
        component={RouterLink}
        to={routes.login.path}
        variant="subtitle2"
        underline="always">
        {t('auth.loginNewPassword', { ns: 'buttons' })}
      </StyledNavigationLink>
    </Stack>
  );
}

export default ResetPasswordSuccessMessage;
