import React from 'react';
import { LoadingButton } from '@mui/lab';
import { buttonClasses } from '@mui/material/Button';
import { styled } from '@mui/material';
import {
  primaryButtonBaseStyles,
  primaryButtonHoverStyles,
  primaryButtonPseudoElementStyles
} from 'common/theme/button/sharedButtonStyles';

const StyledPrimaryLoadingButton = styled(({ minWidth, ...otherProps }) => (
  <LoadingButton {...otherProps} />
))(({ minWidth, theme }) => ({
  ...primaryButtonBaseStyles(theme),
  height: '40px',
  minWidth: minWidth ? '170px' : 'auto',
  padding: '0 20px',
  '&::before': {
    ...primaryButtonHoverStyles,
    ...primaryButtonPseudoElementStyles(theme)
  },
  '&:hover::before': {
    opacity: 1
  },
  [theme.breakpoints.up('lg')]: {
    height: '47px',
    minWidth: minWidth ? '191px' : 'auto',
    padding: '0 25px'
  },
  [`&.${buttonClasses.disabled}`]: {
    background: theme.palette.grey[800]
  }
}));

export default StyledPrimaryLoadingButton;
