import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ClientObservations from 'components/client/overview-profile/ClientObservations';
import ClientPlan from 'components/client/overview-profile/ClientPlan';
import ClientTabs from 'components/client/overview-profile/ClientTabs';
import GoBackButton from 'components/shared/buttons/GoBackButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import OverviewProfileBasicDetails from 'components/shared/overview-profile/OverviewProfileBasicDetails';
import { clientViewFields } from 'common/constants/query-fields/client';
import requestStatus from 'common/constants/status';
import { isAdmin } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import clientSlice from 'store/modules/client';

function ClientOverviewProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels', 'routes']);
  const pageTitle = t('client.view', { ns: 'routes' });
  const { id } = useParams();
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const client = useSelector((state) => state.client.data.viewClient);
  const role = useSelector((state) => state.auth.user.role);
  const status = useSelector((state) => state.client.status.view);

  const isAdminUser = isAdmin(role);
  const isLoadingClient =
    status === requestStatus.loading || status === requestStatus.idle || !client;

  useEffect(() => {
    dispatch(clientSlice.actions.view({ fields: clientViewFields, id }));
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(null);
  }, [setAppBarButton, setAppBarTitle]);

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <GoBackButton />
      <OverviewProfileBasicDetails user={client} loading={isLoadingClient} />
      {isAdminUser && <ClientPlan client={client} loading={isLoadingClient} />}
      <ClientTabs client={client} loading={isLoadingClient} />
      <ClientObservations client={client} loading={isLoadingClient} />
    </>
  );
}

export default ClientOverviewProfile;
