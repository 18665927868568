import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatWithNumberSuffix } from 'common/utils/format';

const Circle = styled.circle`
  fill: ${(props) => props.$styles.fill};
  pointer-events: none;
  stroke: ${(props) => props.$styles.stroke};
  stroke-width: ${(props) => props.$styles.strokeWidth};
`;

const G = styled.g`
  pointer-events: none;
`;

const Text = styled.text`
  fill: #ffffff;
  font-size: 13px;
  font-weight: 600;
`;

function Point({ points, pointBorderWidth, pointLabelXOffset, pointLabelYOffset, pointSize }) {
  return points.map((point) => (
    <G key={point.id} transform={`translate(${point.x}, ${point.y})`}>
      <Circle
        r={pointSize / 2}
        $styles={{
          fill: point.color,
          pointerEvents: 'none',
          stroke: point.borderColor,
          strokeWidth: pointBorderWidth
        }}
      />
      <Text x={pointLabelXOffset} y={pointLabelYOffset} textAnchor="middle">
        {formatWithNumberSuffix(point.data.y, '$')}
      </Text>
    </G>
  ));
}

Point.defaultProps = {
  pointLabelXOffset: 0,
  pointLabelYOffset: 0
};

Point.propTypes = {
  points: PropTypes.arrayOf(PropTypes.object).isRequired,
  pointBorderWidth: PropTypes.number.isRequired,
  pointLabelXOffset: PropTypes.number,
  pointLabelYOffset: PropTypes.number,
  pointSize: PropTypes.number.isRequired
};

export default Point;
