import { Box, styled } from '@mui/material';

const StyledAvailabilityStatusContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray0.main,
  borderRadius: '8px',
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px'
  },
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0
  }
}));

export default StyledAvailabilityStatusContainer;
