import { IconButton, styled } from '@mui/material';

const StyledDrawerMenuCollapseButton = styled(IconButton)(({ theme }) => ({
  alignSelf: 'center',
  display: 'none',
  margin: `${theme.spacing(3)} 0`,
  [theme.breakpoints.up('lg')]: {
    display: 'inline-flex'
  }
}));

export default StyledDrawerMenuCollapseButton;
