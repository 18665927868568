import { Typography, styled } from '@mui/material';

const StyledSessionDetailsDialogTitle = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1.5)} 0`
}));

export default StyledSessionDetailsDialogTitle;
