import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import FilterButtons from 'components/shared/buttons/FilterButtons';
import { getTrashedOptions } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import StyledFiltersContainer from 'common/theme/filters/StyledFiltersContainer';
import StyledFiltersForm from 'common/theme/filters/StyledFiltersForm';
import { handleClientIdChange } from 'common/utils/user';
import useForms from 'hooks/useForms';
import progressSlice from 'store/modules/progress';

function ProgressFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels']);
  const { clearClientSearchResults } = useForms();

  const clientOptions = useSelector((state) => state.client.data.search);
  const initialValues = useSelector((state) => state.progress.filters);
  const searchStatus = useSelector((state) => state.client.status.search);
  const status = useSelector((state) => state.progress.status);

  const onClientIdChange = handleClientIdChange(dispatch);

  const onFilter = useCallback(
    (values) => {
      dispatch(progressSlice.actions.updateFilters(values));
    },
    [dispatch]
  );

  const resetForm = useCallback(() => {
    dispatch(progressSlice.actions.resetFilters());
  }, [dispatch]);

  return (
    <StyledFiltersContainer>
      <Form
        initialValues={initialValues}
        onSubmit={onFilter}
        render={({ handleSubmit, pristine }) => (
          <StyledFiltersForm onSubmit={handleSubmit}>
            <Grid container spacing={{ xs: 2, lg: 4 }}>
              <Grid xs={12} sm={8}>
                <Field
                  id="user_id"
                  name="user_id"
                  component={TypeaheadFieldAdapter}
                  fullWidth
                  loading={searchStatus === requestStatus.loading}
                  isMultiple
                  onClose={clearClientSearchResults}
                  onInputChange={onClientIdChange}
                  options={clientOptions}
                  renderInputProps={{
                    label: t('client', { count: 2, ns: 'labels' })
                  }}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Field
                  id="soft_delete"
                  name="soft_delete"
                  component={SelectFieldAdapter}
                  options={getTrashedOptions(t)}
                  label={t('deleted', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
            <FilterButtons pristine={pristine} resetForm={resetForm} status={status} />
          </StyledFiltersForm>
        )}
      />
    </StyledFiltersContainer>
  );
}

export default ProgressFilters;
