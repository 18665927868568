import React from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import { OnChange } from 'react-final-form-listeners';
import { debounceWait } from 'common/constants/general';

function TrainerPaymentListeners({ onFormChange }) {
  return (
    <>
      <OnChange name="start_date">
        {_debounce((value, previous) => onFormChange('start_date', value, previous), debounceWait)}
      </OnChange>
      <OnChange name="end_date">
        {_debounce((value, previous) => onFormChange('end_date', value, previous), debounceWait)}
      </OnChange>
    </>
  );
}

TrainerPaymentListeners.propTypes = {
  onFormChange: PropTypes.func.isRequired
};

export default TrainerPaymentListeners;
