import React from 'react';
import PropTypes from 'prop-types';

function Emoji({ className, customStyles, label, symbol }) {
  return (
    <span
      className={className}
      role="img"
      aria-label={label}
      aria-hidden="false"
      style={customStyles}>
      {symbol}
    </span>
  );
}

Emoji.defaultProps = {
  className: '',
  customStyles: {}
};

Emoji.propTypes = {
  className: PropTypes.string,
  customStyles: PropTypes.object,
  label: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired
};

export default Emoji;
