import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@mui/material';

function LanguageSelector({ selectedLanguage, onSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    const { language } = event.target.dataset;
    setAnchorEl(null);
    onSelect(language);
  };

  return (
    <div>
      <Button
        id="language-button"
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        color="white"
        size="small"
        onClick={handleClick}
        sx={{ minWidth: 'auto' }}>
        {selectedLanguage}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-button'
        }}>
        <MenuItem onClick={handleClose} data-language="es">
          es
        </MenuItem>
        <MenuItem onClick={handleClose} data-language="en">
          en
        </MenuItem>
      </Menu>
    </div>
  );
}

LanguageSelector.propTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default LanguageSelector;
