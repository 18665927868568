import { useCallback } from 'react';
import _debounce from 'lodash/debounce';
import { debounceWait } from 'common/constants/general';
import { optionsLimit, roles } from 'common/constants/options';
import { userSearchFields } from 'common/constants/query-fields/user';
import adminSlice from 'store/modules/admin';
import clientSlice from 'store/modules/client';
import trainerSlice from 'store/modules/trainer';

export const getLanguages = (languages, t) =>
  languages
    .map((language) => t(`language.${language.toLowerCase()}`, { ns: 'options' }))
    .join(', ');

export const getSpecialties = (specialties) =>
  specialties.map((specialty) => specialty.name).join(', ');

export const handleAdminIdChange = (dispatch, fields = null) =>
  useCallback(
    _debounce((event, value, reason) => {
      if (reason === 'input' && value.length >= 2) {
        dispatch(
          adminSlice.actions.search({
            input: { search: value, role: roles.administrator, limit: optionsLimit },
            fields: fields || userSearchFields
          })
        );
      }
    }, debounceWait),
    [dispatch]
  );

export const handleClientIdChange = (dispatch, fields = null) =>
  useCallback(
    _debounce((event, value, reason) => {
      if (reason === 'input' && value.length >= 2) {
        dispatch(
          clientSlice.actions.search({
            input: { search: value, role: roles.client, limit: optionsLimit },
            fields: fields || userSearchFields
          })
        );
      }
    }, debounceWait),
    [dispatch]
  );

export const handleTrainerIdChange = (dispatch, fields = null) =>
  useCallback(
    _debounce((event, value, reason) => {
      if (reason === 'input' && value.length >= 2) {
        dispatch(
          trainerSlice.actions.search({
            input: { search: value, role: roles.trainer, limit: optionsLimit },
            fields: fields || userSearchFields
          })
        );
      }
    }, debounceWait),
    [dispatch]
  );

export const isAdmin = (role) => role === roles.administrator;

export const isClient = (role) => role === roles.client;

export const isTrainer = (role) => role === roles.trainer;
