import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledBottomNavigation from 'common/theme/bottom-navigation/StyledBottomNavigation';
import StyledBottomNavigationAction from 'common/theme/bottom-navigation/StyledBottomNavigationAction';
import StyledBottomNavigationPaper from 'common/theme/bottom-navigation/StyledBottomNavigationPaper';
import {
  adminMobileMenuItems,
  clientMobileMenuItems,
  trainerMobileMenuItems
} from 'common/constants/mobileMenu';
import { roles } from 'common/constants/options';

const getMobileMenuByRole = (role) => {
  const roleType = {
    [roles.client]: () => clientMobileMenuItems,
    [roles.trainer]: () => trainerMobileMenuItems,
    default: () => adminMobileMenuItems
  };

  return (roleType[role] || roleType.default)();
};

function MobileNavigation() {
  const { t } = useTranslation(['routes']);
  const theme = useTheme();
  const [value, setValue] = useState(2);
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));

  const role = useSelector((state) => state.auth.user.role);
  const menuItems = getMobileMenuByRole(role);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    isMedium && (
      <StyledBottomNavigationPaper elevation={3}>
        <StyledBottomNavigation showLabels value={value} onChange={handleChange}>
          {menuItems.map((menuItem) => (
            <StyledBottomNavigationAction
              component={RouterLink}
              key={menuItem.label}
              label={t(menuItem.label, { ns: 'routes' })}
              icon={menuItem.icon}
              to={menuItem.to}
            />
          ))}
        </StyledBottomNavigation>
      </StyledBottomNavigationPaper>
    )
  );
}

export default MobileNavigation;
