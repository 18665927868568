import { autocompleteClasses } from '@mui/material/Autocomplete';
import { checkboxClasses } from '@mui/material/Checkbox';
import { menuItemClasses } from '@mui/material/MenuItem';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableFooterClasses } from '@mui/material/TableFooter';
import { tablePaginationClasses } from '@mui/material/TablePagination';
import { tableRowClasses } from '@mui/material/TableRow';
import { toolbarClasses } from '@mui/material/Toolbar';

const components = {
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
      },
      listbox: ({ theme }) => ({
        [`& .${autocompleteClasses.option}`]: {
          '&[aria-selected="true"]': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.gray2.main,
            fontWeight: 600
          },
          [`&.${autocompleteClasses.focused}[aria-selected="true"]`]: {
            backgroundColor: theme.palette.secondary.main
          }
        }
      }),
      option: ({ theme }) => ({
        '&[aria-selected="true"]': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.gray2.main,
          fontWeight: 600,
          '&.Mui-focused': {
            backgroundColor: theme.palette.secondary.main
          }
        }
      })
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`&.${checkboxClasses.indeterminate}, &.${checkboxClasses.checked}`]: {
          color: theme.palette.secondary.main
        }
      })
    }
  },
  MUIDataTable: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: 'initial',
        [`&.clientPayment tbody .${tableCellClasses.root}:nth-of-type(1)`]: {
          paddingLeft: 0,
          '& > div': {
            paddingLeft: theme.spacing(0.5),
            [theme.breakpoints.down('lg')]: {
              backgroundColor: theme.palette.gray0.main
            }
          }
        },
        '&.performance': {
          [`& tbody .${tableCellClasses.root}:nth-of-type(1)`]: {
            backgroundColor: theme.palette.gray0.main,
            paddingLeft: 0,
            '& > div': {
              paddingLeft: theme.spacing(0.5)
            }
          },
          [`& .${tableCellClasses.body}:nth-of-type(2)`]: {
            whiteSpace: 'nowrap'
          }
        },
        '&.user': {
          [`& .${tableCellClasses.head}:nth-of-type(2)`]: {
            paddingLeft: '32px'
          },
          [`& .${tableCellClasses.head}:nth-of-type(2), .${tableCellClasses.body}:nth-of-type(2)`]:
            {
              textAlign: 'left'
            },
          '&.user--client': {
            [`& .${tableCellClasses.body}:nth-of-type(1)`]: {
              whiteSpace: 'nowrap'
            }
          }
        },
        '&.session': {
          [`
            &:not(.session--administrator) .${tableCellClasses.body}:nth-of-type(1),
            &:not(.session--administrator) .${tableCellClasses.body}:nth-of-type(3)
          `]: {
            whiteSpace: 'nowrap'
          },
          [`.${tableCellClasses.body}:nth-of-type(1)`]: {
            backgroundColor: theme.palette.gray0.main,
            paddingLeft: 0,
            '& > div': {
              paddingLeft: theme.spacing(0.5)
            }
          },
          [`.${tableCellClasses.body}:nth-of-type(3) .MuiBox-root`]: {
            justifyContent: 'flex-start'
          },
          [`&.session--administrator .${tableCellClasses.body}:nth-of-type(2)`]: {
            whiteSpace: 'nowrap'
          },
          [`&.session--administrator .${tableCellClasses.body}:nth-of-type(4) .MuiBox-root`]: {
            justifyContent: 'flex-start'
          },
          [`&.session--administrator .${tableCellClasses.body}:nth-of-type(5) .MuiBox-root`]: {
            justifyContent: 'flex-start'
          }
        },
        [`&.latestPerformanceClient .${tableRowClasses.root}.${tableRowClasses.hover}`]: {
          backgroundColor: 'transparent'
        },
        [`& .${tableFooterClasses.root}`]: {
          [`& .${tableCellClasses.root}`]: {
            [theme.breakpoints.down('lg')]: {
              padding: 0
            }
          },
          [`& .${tablePaginationClasses.spacer}`]: {
            [theme.breakpoints.down('sm')]: {
              display: 'none'
            }
          },
          [`& .${toolbarClasses.root}`]: {
            [theme.breakpoints.down('sm')]: {
              justifyContent: 'center',
              padding: 0
            }
          }
        }
      })
    }
  },
  MUIDataTableToolbarSelect: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.gray2.main,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        boxShadow: 'none',
        '& + *': {
          marginTop: '-9px'
        }
      }),
      title: ({ theme }) => ({
        fontSize: theme.typography.pxToRem(14),
        paddingLeft: '16px',
        [theme.breakpoints.up('lg')]: {
          fontSize: theme.typography.pxToRem(16)
        }
      })
    }
  },
  MUIDataTableSelectCell: {
    styleOverrides: {
      headerCell: ({ theme }) => ({
        backgroundColor: theme.palette.gray2.main
      })
    }
  },
  MUIDataTableHeadCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.gray2.main,
        fontSize: theme.typography.pxToRem(12),
        padding: '6px',
        textAlign: 'center',
        textTransform: 'uppercase',
        [theme.breakpoints.up('lg')]: {
          fontSize: theme.typography.pxToRem(14),
          padding: theme.spacing(2)
        },
        '& .multiline-header-label': {
          lineHeight: '18px',
          '&--last': {
            fontSize: '12px',
            lineHeight: '24px',
            textTransform: 'lowercase'
          }
        }
      })
    }
  },
  MUIDataTableBodyCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.typography.pxToRem(12),
        padding: theme.spacing(0.5),
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
          fontSize: theme.typography.pxToRem(14),
          padding: theme.spacing(2)
        }
      })
    }
  },
  MUIDataTableFooter: {
    styleOverrides: {
      root: () => ({
        [`& .${tableCellClasses.root}`]: {
          padding: 0
        }
      })
    }
  },
  MUIDataTablePagination: {
    styleOverrides: {
      root: ({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
          width: 'auto'
        },
        [`.${tablePaginationClasses.actions}`]: {
          [theme.breakpoints.down('sm')]: {
            columnGap: theme.spacing(3),
            display: 'flex'
          },
          button: {
            [theme.breakpoints.down('sm')]: {
              padding: 0
            }
          }
        }
      }),
      navContainer: ({ theme }) => ({
        columnGap: theme.spacing(2),
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
          columnGap: theme.spacing(0),
          justifyContent: 'flex-end'
        }
      })
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        [`&.${menuItemClasses.selected}`]: {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.gray2.main,
          fontWeight: 600,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.gray2.main,
            fontWeight: 600
          }
        }
      })
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottomColor: '#33393F',
        [`&.${tableCellClasses.head}`]: {
          '&:first-of-type': {
            borderTopLeftRadius: '10px'
          },
          '&:last-child': {
            borderTopRightRadius: '10px'
          }
        },
        [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
          borderBottom: 0
        }
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&:last-child': {
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none'
          }
        },
        [`&.${tableRowClasses.hover}:hover`]: {
          backgroundColor: 'transparent',
          [theme.breakpoints.up('lg')]: {
            backgroundColor: 'rgba(248, 184, 69, 0.1)'
          }
        },
        [`&.${tableRowClasses.selected}`]: {
          [`& .${tableCellClasses.root}:first-of-type::before`]: {
            right: '-3px',
            width: 'calc(100% - 3px)'
          },
          [`& .${tableCellClasses.root}:last-child::before`]: {
            left: '-3px',
            width: 'calc(100% - 3px)'
          }
        }
      })
    }
  }
};

export default components;
