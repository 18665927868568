import { Stack, styled } from '@mui/material';

const StyledDefaultLayoutContent = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(3)} 0 0`
  }
}));

export default StyledDefaultLayoutContent;
