import { Box, styled } from '@mui/material';

const StyledDrawerMenuContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '27px 18px 0'
}));

export default StyledDrawerMenuContainer;
