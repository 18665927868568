import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

function OverviewProfileBasicDetailsSkeleton() {
  const skeletonCount = 4;

  return (
    <>
      <Skeleton variant="circular" width={233} height={233} />
      <Box sx={{ marginLeft: '36px' }}>
        <Skeleton width={203} height={56} sx={{ marginBottom: '10px' }} />
        <Stack sx={{ marginBottom: '15px' }}>
          {Array.from({ length: skeletonCount }, (_, index) => (
            <Skeleton key={index} width={203} height={30} />
          ))}
        </Stack>
        <Skeleton width={92} height={32} variant="circular" />
      </Box>
    </>
  );
}

export default OverviewProfileBasicDetailsSkeleton;
