import React from 'react';
import { EventAvailable, Home, MoreHoriz } from '@mui/icons-material';
import { CalendarCursor, ChartBoxOutline, TableSearch } from 'mdi-material-ui';
import routes from 'common/routes';

export const adminMobileMenuItems = [
  {
    label: 'session.calendar',
    icon: <CalendarCursor />,
    to: routes.calendar.path
  },
  {
    label: 'session.list',
    icon: <TableSearch />,
    to: routes.listSession.path
  },
  {
    label: 'home',
    icon: <Home />,
    to: routes.adminDashboard.path
  },
  {
    label: 'performance.list',
    icon: <ChartBoxOutline />,
    to: routes.listPerformance.path
  },
  {
    label: 'more',
    icon: <MoreHoriz />,
    to: routes.adminMore.path
  }
];

export const clientMobileMenuItems = [
  {
    label: 'session.calendar',
    icon: <CalendarCursor />,
    to: routes.calendar.path
  },
  {
    label: 'session.list',
    icon: <TableSearch />,
    to: routes.clientSessions.path
  },
  {
    label: 'home',
    icon: <Home />,
    to: routes.clientDashboard.path
  },
  {
    label: 'performance.list',
    icon: <ChartBoxOutline />,
    to: routes.clientPerformances.path
  },
  {
    label: 'more',
    icon: <MoreHoriz />,
    to: routes.clientMore.path
  }
];

export const trainerMobileMenuItems = [
  {
    label: 'session.calendar',
    icon: <CalendarCursor />,
    to: routes.calendar.path
  },
  {
    label: 'availability.trainer',
    icon: <EventAvailable />,
    to: routes.availability.path
  },
  {
    label: 'home',
    icon: <Home />,
    to: routes.clientDashboard.path
  },
  {
    label: 'session.list',
    icon: <TableSearch />,
    to: routes.listSession.path
  },
  {
    label: 'more',
    icon: <MoreHoriz />,
    to: routes.trainerMore.path
  }
];
