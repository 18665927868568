import DefaultLayout from 'components/layout/DefaultLayout';
import PlanCreate from 'components/plan/PlanCreate';
import PlanEdit from 'components/plan/PlanEdit';
import PlanList from 'components/plan/PlanList';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const planRoutes = [
  {
    key: 'plan-create',
    exact: true,
    path: routes.createPlan.path,
    component: PlanCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'plan-edit',
    exact: true,
    path: routes.editPlan.path,
    component: PlanEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'plan-list',
    exact: true,
    path: routes.listPlan.path,
    component: PlanList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  }
];

export default planRoutes;
