import { Chip, styled } from '@mui/material';
import { chipClasses } from '@mui/material/Chip';
import defaultChipStyles from 'common/theme/chips/defaultChipStyles';

const StyledSessionCardChip = styled(Chip)(({ theme }) => ({
  ...defaultChipStyles,
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    height: '26px'
  },
  [`& .${chipClasses.label}`]: {
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(12)
    }
  }
}));

export default StyledSessionCardChip;
