import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'date-fns';
import { Popover, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import HourSelectFieldOption from 'components/shared/field-adapters/hour-picker-field-adapter/HourPickerOption';
import StyledHourPickerGrid from 'common/theme/fields/hour-picker/StyledHourPickerGrid';
import StyledTextField from 'common/theme/fields/StyledTextfield';
import { convertToDate, resetTimeInDate } from 'common/utils/date';

function HourSelectFieldAdapter({
  dateFormat,
  disablePast,
  input,
  options,
  renderInputProps,
  ...rest
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'hour-select-popover' : undefined;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  useEffect(() => {
    if (input.value && input.value instanceof Date) {
      const date = resetTimeInDate(input.value);
      input.onChange(date);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (value) => {
    if (!isEqual(input.value, value)) {
      input.onChange(value);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <TimeField
        {...input}
        {...rest}
        format={dateFormat}
        value={convertToDate(input.value)}
        onClick={handleClick}
        onChange={handleChange}
        slots={{
          textField: StyledTextField
        }}
        slotProps={{
          textField: {
            ...renderInputProps,
            readOnly: true,
            size
          }
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <StyledHourPickerGrid>
          {options.map((hour) => (
            <HourSelectFieldOption
              disablePast={disablePast}
              hour={hour}
              key={hour.label}
              onClick={handleChange}
              selectedHour={input.value}
            />
          ))}
        </StyledHourPickerGrid>
      </Popover>
    </div>
  );
}

HourSelectFieldAdapter.defaultProps = {
  dateFormat: 'hh:mm aaa',
  disablePast: false
};

HourSelectFieldAdapter.propTypes = {
  dateFormat: PropTypes.string,
  disablePast: PropTypes.bool,
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  referenceDate: PropTypes.object.isRequired,
  renderInputProps: PropTypes.object.isRequired
};

export default HourSelectFieldAdapter;
