import { Box, styled } from '@mui/material';

const StyledDrawerMenuUserStatus = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  columnGap: theme.spacing(1.5),
  display: 'flex',
  flex: '1 0 100%',
  marginTop: theme.spacing(1),
  minWidth: 0,
  [theme.breakpoints.up('lg')]: {
    flex: '0 0 auto'
  },
  '& > *': {
    minWidth: 0
  },
  '& > *:first-of-type': {
    flexShrink: 0
  },
  '& > *:last-child': {
    flexGrow: 1
  }
}));

export default StyledDrawerMenuUserStatus;
