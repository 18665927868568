import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DoubleCounter from 'components/shared/counters/double/DoubleCounter';
import Emoji from 'components/shared/Emoji';

function ClientPaymentCounter() {
  const { t } = useTranslation(['labels']);

  const currentMonthIncomeInCop = useSelector(
    (state) => state.clientPayment.counters.currentMonthIncomeInCop
  );
  const currentMonthIncomeInUsd = useSelector(
    (state) => state.clientPayment.counters.currentMonthIncomeInUsd
  );

  return (
    <DoubleCounter
      emoji={<Emoji label={t('monthlyIncome', { ns: 'labels' })} symbol="💳" />}
      title={t('monthlyIncomes', { ns: 'labels' })}
      type="currency"
      counters={[
        {
          title: t('cop', { ns: 'common' }),
          counter: currentMonthIncomeInCop
        },
        {
          title: t('usd', { ns: 'common' }),
          counter: currentMonthIncomeInUsd
        }
      ]}
    />
  );
}

export default ClientPaymentCounter;
