import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Podium from 'components/shared/charts/shared/Podium';

const minHeight = 120;

function TopRankedBar({ bars, borderWidth, onClick }) {
  const onBarClick = useCallback(
    (bar) => {
      onClick({
        label: bar.data.data.label,
        place: bar.data.data.place,
        user: bar.data.data.user
      });
    },
    [onClick]
  );

  const calculatedBars = useMemo(
    () =>
      bars.map((bar) => {
        const finalHeight = bar.height < minHeight ? minHeight : bar.height;
        const finalY = bar.y - (finalHeight - bar.height);

        return (
          <React.Fragment key={bar.key}>
            <g
              transform={`translate(${bar.x}, ${finalY})`}
              key={`${bar.key}-g`}
              onClick={() => onBarClick(bar)}
              style={{ cursor: 'pointer' }}>
              <rect
                width={bar.width}
                height={finalHeight}
                rx="0"
                ry="0"
                fill={bar.data.fill}
                strokeWidth={borderWidth}
                stroke={bar.color}
              />
            </g>
            <Podium
              label={bar.data.data.label}
              place={bar.data.data.place}
              user={bar.data.data.user}
              width={bar.width}
              x={bar.x}
              y={finalY}
            />
          </React.Fragment>
        );
      }),
    [bars, borderWidth]
  );

  return calculatedBars;
}

TopRankedBar.propTypes = {
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      data: PropTypes.shape({
        fill: PropTypes.string.isRequired,
        data: PropTypes.shape({
          label: PropTypes.string.isRequired,
          place: PropTypes.number.isRequired,
          user: PropTypes.shape({
            first_name: PropTypes.string.isRequired,
            last_name: PropTypes.string.isRequired,
            photo: PropTypes.string
          }).isRequired
        }).isRequired
      }).isRequired,
      height: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  borderWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default TopRankedBar;
