import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SessionCreateDialogAppBar from 'components/session/dialog/create/SessionCreateDialogAppBar';
import SessionCreateDialogContent from 'components/session/dialog/create/SessionCreateDialogContent';
import SlideUpTransition from 'components/shared/transitions/SlideUpTransition';
import StyledSessionDialogCreate from 'common/theme/dialog/create-session/StyledSessionDialogCreate';
import { isAdmin } from 'common/utils/user';
import clientSlice from 'store/modules/client';
import sessionTypeSlice from 'store/modules/sessionType';
import trainerSlice from 'store/modules/trainer';

function SessionDialogCreate({ onClose, open }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const role = useSelector((state) => state.auth.user.role);
  const isAdminUser = isAdmin(role);

  useEffect(() => {
    dispatch(sessionTypeSlice.actions.listAll());
  }, [dispatch]);

  const onDialogClose = useCallback(() => {
    if (isAdminUser) {
      dispatch(clientSlice.actions.clearSearchResults());
    }

    dispatch(trainerSlice.actions.clearList());
    onClose();
  }, [dispatch, onClose]);

  return (
    <StyledSessionDialogCreate
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      open={open}
      onClose={onDialogClose}
      TransitionComponent={SlideUpTransition}>
      <SessionCreateDialogAppBar onDialogClose={onDialogClose} />
      <SessionCreateDialogContent onDialogClose={onDialogClose} />
    </StyledSessionDialogCreate>
  );
}

SessionDialogCreate.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default SessionDialogCreate;
