import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Stack, Typography } from '@mui/material';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import PasswordFieldAdapter from 'components/shared/field-adapters/PasswordFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import requestStatus from 'common/constants/status';
import validateResetPasswordForm from 'common/validations/form/resetPassword';
import authSlice from 'store/modules/auth';

const resetPasswordInitialValues = {
  token: '',
  password: '',
  password_confirmation: ''
};

function ResetPasswordForm() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['auth', 'buttons', 'labels']);
  const [searchParams] = useSearchParams();

  const status = useSelector((state) => state.auth.status);

  const onPasswordReset = (values) => {
    const dataToSend = {
      ...values,
      email: searchParams.get('email')
    };

    dispatch(authSlice.actions.resetPassword(dataToSend));
  };

  return (
    <Form
      initialValues={resetPasswordInitialValues}
      validate={validateResetPasswordForm}
      onSubmit={onPasswordReset}
      render={({ handleSubmit, invalid, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Stack spacing={4} sx={{ width: '100%' }}>
            <Stack spacing={2}>
              <Typography variant="h5" align="center" component="h1" sx={{ fontWeight: 500 }}>
                {t('resetPassword.title', { ns: 'auth' })}
              </Typography>
              <Typography variant="body2" align="center" component="p">
                {t('resetPassword.text', { ns: 'auth' })}
              </Typography>
            </Stack>
            <Field
              id="token"
              name="token"
              component={TextFieldAdapter}
              label={t('code', { ns: 'labels' })}
              type="text"
              fullWidth
              required
            />
            <Field
              id="password"
              name="password"
              component={PasswordFieldAdapter}
              label={t('password', { ns: 'labels' })}
              autoComplete="current-password"
              fullWidth
              required
            />
            <Field
              id="password_confirmation"
              name="password_confirmation"
              component={PasswordFieldAdapter}
              label={t('confirmPassword', { ns: 'labels' })}
              autoComplete="current-password"
              fullWidth
              required
            />
            <PrimaryLoadingButton
              disabled={invalid || pristine || status === requestStatus.loading}
              loading={status === requestStatus.loading}
              label={t('auth.resetPassword', { ns: 'buttons' })}
              type="submit"
            />
          </Stack>
        </form>
      )}
    />
  );
}

export default ResetPasswordForm;
