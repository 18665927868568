import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import AlertBlock from 'components/shared/blocks/AlertBlock';
import AvailabilityStatus from 'components/availability/AvailabilityStatus';
import SessionsSideList from 'components/shared/lists/SessionsSideList';
import { roles } from 'common/constants/options';

function AvailabilitySidebar({ disableReset, resetList, sessions }) {
  const sessionsStatus = useSelector((state) => state.session.status.general);

  return (
    <Stack spacing={{ xs: 2, md: 4 }}>
      <AlertBlock type="availabilityDelete" />
      <AlertBlock type="availabilityRepeat" />
      <AvailabilityStatus />
      <SessionsSideList
        sessions={sessions}
        disableReset={disableReset}
        status={sessionsStatus}
        onReset={resetList}
        viewType={roles.trainer}
      />
    </Stack>
  );
}

AvailabilitySidebar.propTypes = {
  disableReset: PropTypes.bool.isRequired,
  resetList: PropTypes.func.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AvailabilitySidebar;
