import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledAppbarTitle from 'common/theme/appbar/StyledAppbarTitle';
import StyledAppbarTitleContainer from 'common/theme/appbar/StyledAppbarTitleContainer';
import StyledDefaultLayoutSectionContainer from 'common/theme/layouts/default/StyledDefaultLayoutSectionContainer';
import AppBarContext from 'context/AppBarContext';
import LayoutContext from 'context/LayoutContext';

function DefaultLayoutBody({ children }) {
  const { appBarButton, appBarTitle } = useContext(AppBarContext);
  const { compactLayout, noBgColorLayout } = useContext(LayoutContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      {!isDesktop && (
        <StyledAppbarTitleContainer>
          {appBarTitle && (
            <StyledAppbarTitle noWrap component="h1">
              {appBarTitle}
            </StyledAppbarTitle>
          )}
          {appBarButton}
        </StyledAppbarTitleContainer>
      )}
      <StyledDefaultLayoutSectionContainer compact={compactLayout} noBgColor={noBgColorLayout}>
        {children}
      </StyledDefaultLayoutSectionContainer>
    </>
  );
}

DefaultLayoutBody.propTypes = {
  children: PropTypes.element.isRequired
};

export default DefaultLayoutBody;
