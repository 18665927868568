import { Box, styled } from '@mui/material';

const StyledAppbarUserContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  flexShrink: 0,
  textAlign: 'right',
  [theme.breakpoints.up('lg')]: {
    display: 'block'
  }
}));

export default StyledAppbarUserContainer;
