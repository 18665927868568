import { TextField, styled } from '@mui/material';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { iconButtonClasses } from '@mui/material/IconButton';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    '& fieldset': {
      border: '1px solid #E3E7EB'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.main
    },
    [`&.${outlinedInputClasses.focused}`]: {
      '& fieldset': {
        borderColor: theme.palette.secondary.main
      },
      [`& .${inputAdornmentClasses.root}`]: {
        color: theme.palette.common.white
      }
    },
    [`&.${outlinedInputClasses.error}`]: {
      '& fieldset': {
        borderColor: '#F6B1B1'
      },
      [`& .${inputAdornmentClasses.root}`]: {
        color: '#F6B1B1'
      },
      [`& .${iconButtonClasses.root}`]: {
        color: '#F6B1B1'
      }
    },
    [`&.${outlinedInputClasses.disabled}`]: {
      '& fieldset': {
        borderColor: theme.palette.action.disabled
      },
      [`& .${inputAdornmentClasses.root}`]: {
        color: theme.palette.action.disabled
      }
    }
  },
  [`& .${inputLabelClasses.root}`]: {
    color: '#E3E7EB',
    [`&.${inputLabelClasses.focused}`]: {
      color: theme.palette.common.white
    },
    [`&.${inputLabelClasses.error}`]: {
      color: '#F6B1B1'
    },
    [`&.${inputLabelClasses.disabled}`]: {
      color: theme.palette.action.disabled,
      [`& .${inputLabelClasses.asterisk}`]: {
        color: theme.palette.action.disabled
      }
    }
  },
  [`& .${inputLabelClasses.asterisk}`]: {
    color: '#E3E7EB',
    [`&.${inputLabelClasses.error}`]: {
      color: '#F6B1B1'
    }
  },
  [`& .${outlinedInputClasses.input}`]: {
    color: theme.palette.common.white,
    [theme.breakpoints.up('lg')]: {
      height: '14px'
    },
    [`&.${outlinedInputClasses.disabled}`]: {
      color: theme.palette.action.disabled
    }
  },
  [`& .${formHelperTextClasses.root}`]: {
    marginLeft: 0,
    [`&.${formHelperTextClasses.error}`]: {
      color: '#F6B1B1'
    }
  },
  [`& .${inputAdornmentClasses.root}`]: {
    color: '#E3E7EB',
    [`&.${outlinedInputClasses.disabled}`]: {
      color: theme.palette.action.disabled
    }
  }
}));

export default StyledTextField;
