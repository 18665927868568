import { Box, styled } from '@mui/material';

const StyledWeeklySessionEventContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray2.main,
  borderRadius: '4px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    borderRadius: '5px'
  }
}));

export default StyledWeeklySessionEventContainer;
