import { hasValue, isValidDate } from 'common/validations/field';
import i18n from 'i18n';

const defaultMealPlanFormValidations = (values) => {
  const errors = {};

  if (!isValidDate(values.created_at)) {
    errors.created_at = i18n.t('date', { ns: 'validations' });
  }

  if (!hasValue(values.pdf_document)) {
    errors.pdf_document = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!hasValue(values.user_id)) {
    errors.user_id = i18n.t('emptyField', { ns: 'validations' });
  }

  return errors;
};

export default defaultMealPlanFormValidations;
