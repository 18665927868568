import { Box, styled } from '@mui/material';

const StyledFilterButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridColumnGap: theme.spacing(4),
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  [theme.breakpoints.up('lg')]: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3)
  }
}));

export default StyledFilterButtonsContainer;
