import TrainerCreate from 'components/trainer/TrainerCreate';
import TrainerDashboard from 'components/dashboard/trainer/TrainerDashboard';
import TrainerEdit from 'components/trainer/TrainerEdit';
import TrainerList from 'components/trainer/TrainerList';
import TrainerMore from 'components/trainer/more/TrainerMore';
import TrainerOverviewProfile from 'components/trainer/overview-profile/TrainerOverviewProfile';
import TrainerProfile from 'components/trainer/profile/TrainerProfile';
import DefaultLayout from 'components/layout/DefaultLayout';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const trainerRoutes = [
  // Admin routes

  {
    key: 'trainer-create',
    exact: true,
    path: routes.createTrainer.path,
    component: TrainerCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'trainer-edit',
    exact: true,
    path: routes.editTrainer.path,
    component: TrainerEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'trainer-list',
    exact: true,
    path: routes.listTrainer.path,
    component: TrainerList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'trainer-overview-profile',
    exact: true,
    path: routes.viewTrainer.path,
    component: TrainerOverviewProfile,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.client]
  },

  // Trainer routes

  {
    key: 'trainer-dashboard',
    exact: true,
    path: routes.trainerDashboard.path,
    component: TrainerDashboard,
    layout: DefaultLayout,
    allowedRoles: [roles.trainer]
  },
  {
    key: 'trainer-more',
    exact: true,
    path: routes.trainerMore.path,
    component: TrainerMore,
    layout: DefaultLayout,
    allowedRoles: [roles.trainer]
  },
  {
    key: 'trainer-profile',
    exact: true,
    path: routes.trainerProfile.path,
    component: TrainerProfile,
    layout: DefaultLayout,
    allowedRoles: [roles.trainer]
  }
];

export default trainerRoutes;
