import { gql } from '@apollo/client';
import client from 'apollo/client';

/**
 * Get latest availability method
 * @param {Integer} userId
 * @returns {Promise}
 */
const getLatest = (userId) =>
  client.query({
    query: gql`
      query latestAvailability($user_id: Int!) {
        latestAvailability(user_id: $user_id) {
          id
          end_date
          start_date
        }
      }
    `,
    variables: { user_id: userId },
    fetchPolicy: 'network-only'
  });

/**
 * Force delete availabilities method
 * @param  {Array} ids
 * @return {Promise}
 */
const forceDelete = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deleteAvailabilities($id: [ID!]!) {
        deleteAvailabilities(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * List availabilities method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { trainerId, startDate, endDate } = payload;

  return client.query({
    query: gql`
      query availabilities {
        availabilities(
          user_id: ${trainerId}
          dateRange: {
            start_date: "${startDate}",
            end_date: "${endDate}"
          }
          orderBy: {
            column: START_DATE,
            order: ASC
          }
        ) {
          id
          start_date
          end_date
          sessions {
            id
            client_id {
              first_name
              last_name
              full_name
              photo
            }
            end_date
            session_type_id {
              name
            }
            start_date
            status
          }
          stored
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Upsert availabilities method
 * @param {Array} availabilities
 * @returns {Promise}
 */
const upsert = (availabilities) =>
  client.mutate({
    mutation: gql`
      mutation upsertAvailabilities($input: [UpsertAvailabilityInput!]!) {
        upsertAvailabilities(input: $input) {
          affectedModels
        }
      }
    `,
    variables: { input: availabilities }
  });

export default {
  forceDelete,
  getLatest,
  list,
  upsert
};
