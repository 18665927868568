/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import StyledRangeColumn from 'common/theme/columns/StyledRangeColumn';

function RangeColumn({ end, multiline, start }) {
  return multiline ? (
    <>
      <StyledRangeColumn variant="caption" paragraph>
        {start}
      </StyledRangeColumn>
      <StyledRangeColumn variant="body2" paragraph>
        {end}
      </StyledRangeColumn>
    </>
  ) : (
    <StyledRangeColumn>
      {start} - {end}
    </StyledRangeColumn>
  );
}

RangeColumn.defaultProps = {
  multiline: true
};

RangeColumn.propTypes = {
  end: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  start: PropTypes.string.isRequired
};

export default RangeColumn;
