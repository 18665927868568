import { IconButton, styled } from '@mui/material';
import { svgIconClasses } from '@mui/material/SvgIcon';

const StyledCollapsibleMenuIcon = styled(IconButton)(({ theme }) => ({
  height: theme.spacing(3),
  padding: 0,
  width: theme.spacing(3),
  [`& .${svgIconClasses.root}`]: {
    width: theme.spacing(2)
  }
}));

export default StyledCollapsibleMenuIcon;
