import { Typography, styled } from '@mui/material';

const StyledH2Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 500,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(26)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(44)
  }
}));

export default StyledH2Title;
