import { put, take, takeLatest } from 'redux-saga/effects';
import trainerPaymentSlice from 'store/modules/trainerPayment';
import uiSlice from 'store/modules/ui';
import trainerPayment from 'api/graphql/trainerPayment';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import { createTrainerPaymentList } from 'store/sagas/hof/payment';
import i18n from 'i18n';

/**
 * Create trainer payment saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'trainer-payment-create-notification-success',
      variant: 'success',
      message: i18n.t('payment.create', { ns: 'notifications' })
    });

    const { payload } = action;
    const {
      data: {
        createTrainerPayment: { id }
      }
    } = yield trainerPayment.create(payload);

    yield put(trainerPaymentSlice.actions.createInvoice(id));

    yield take(trainerPaymentSlice.actions.createInvoiceSuccess);
    yield put(trainerPaymentSlice.actions.sendInvoice(id));

    yield take(trainerPaymentSlice.actions.sendInvoiceSuccess);
    yield put(trainerPaymentSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerPaymentSlice.actions.createError(message));
  }
}

/**
 * Create trainer payment invoice saga
 *
 * @param {*} action
 */
function* createInvoice(action) {
  try {
    const { payload: id } = action;
    yield trainerPayment.createInvoice(id);
    yield put(trainerPaymentSlice.actions.createInvoiceSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerPaymentSlice.actions.createInvoiceError(message));
  }
}

/**
 * Find trainer payment saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield trainerPayment.find(action.payload);
    const {
      data: { trainerPayment: currentPayment }
    } = response;

    yield put(trainerPaymentSlice.actions.findSuccess(currentPayment));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerPaymentSlice.actions.findError(message));
  }
}

/**
 * Remove trainer payments saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deleteTrainerPayments: removedPayments }
    } = yield trainerPayment.remove(action.payload);

    const snack = createSnack({
      key: 'remove-trainer-payments-notification-success',
      variant: 'success',
      message: i18n.t('payment.delete', { count: removedPayments.length, ns: 'notifications' })
    });

    yield put(trainerPaymentSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerPaymentSlice.actions.removeError(message));
  }
}

/**
 * Restore trainer payments saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restoreTrainerPayments: restoredPayments }
    } = yield trainerPayment.restore(action.payload);

    const snack = createSnack({
      key: 'restore-trainer-payments-notification-success',
      variant: 'success',
      message: i18n.t('payment.restore', { count: restoredPayments.length, ns: 'notifications' })
    });

    yield put(trainerPaymentSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerPaymentSlice.actions.restoreError(message));
  }
}

/**
 * Send trainer payment invoice saga
 *
 * @param {*} action
 */
function* sendInvoice(action) {
  try {
    const { payload: id } = action;
    const {
      data: {
        sendTrainerInvoice: { sent, error }
      }
    } = yield trainerPayment.sendInvoice(id);

    if (!sent) {
      yield put(trainerPaymentSlice.actions.sendInvoiceError(error));
    } else {
      yield put(trainerPaymentSlice.actions.sendInvoiceSuccess());
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerPaymentSlice.actions.sendInvoiceError(message));
  }
}

/**
 * Get total outcome by month saga
 *
 * @param {*} action
 */
function* totalOutcomeByMonth(action) {
  try {
    const { createdAt } = action.payload;
    const response = yield trainerPayment.totalOutcomeByMonth(createdAt);

    const {
      data: { totalOutcomeByMonth: totalOutcomeByMonthData }
    } = response;

    yield put(trainerPaymentSlice.actions.totalOutcomeByMonthSuccess(totalOutcomeByMonthData));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerPaymentSlice.actions.totalOutcomeByMonthError(message));
  }
}

/**
 * Update trainer payment saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-trainer-payment-notification-success',
      variant: 'success',
      message: i18n.t('payment.update', { ns: 'notifications' })
    });

    yield trainerPayment.update(action.payload);

    yield put(trainerPaymentSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(trainerPaymentSlice.actions.updateError(message));
  }
}

/**
 * Watch trainer payment
 *
 * @export
 */
export default function* watchTrainerPayment() {
  yield takeLatest(trainerPaymentSlice.actions.create, create);
  yield takeLatest(trainerPaymentSlice.actions.createInvoice, createInvoice);
  yield takeLatest(trainerPaymentSlice.actions.find, find);
  yield takeLatest(
    trainerPaymentSlice.actions.list,
    createTrainerPaymentList(
      trainerPaymentSlice.actions.listSuccess,
      trainerPaymentSlice.actions.listError
    )
  );
  yield takeLatest(
    trainerPaymentSlice.actions.listLatest,
    createTrainerPaymentList(
      trainerPaymentSlice.actions.listLatestSuccess,
      trainerPaymentSlice.actions.listLatestError
    )
  );
  yield takeLatest(trainerPaymentSlice.actions.remove, remove);
  yield takeLatest(trainerPaymentSlice.actions.restore, restore);
  yield takeLatest(trainerPaymentSlice.actions.sendInvoice, sendInvoice);
  yield takeLatest(trainerPaymentSlice.actions.totalOutcomeByMonth, totalOutcomeByMonth);
  yield takeLatest(trainerPaymentSlice.actions.update, update);
}
