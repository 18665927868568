import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Unstable_Grid2 as Grid } from '@mui/material';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import LatestNews from 'components/shared/lists/LatestNews';
import NextSessionsList from 'components/shared/lists/NextSessionsList';
import SessionsCompletedTable from 'components/dashboard/trainer/SessionsCompletedTable';
import SessionReminderCounter from 'components/dashboard/trainer/counters/SessionReminderCounter';
import SessionStatusCounter from 'components/dashboard/trainer/counters/SessionStatusCounter';
import TodaySessionsCounter from 'components/dashboard/trainer/counters/TodaySessionsCounter';
import { newsListBasicFields } from 'common/constants/query-fields/news';
import { sessionDashboardTrainerFields } from 'common/constants/query-fields/session';
import AppBarContext from 'context/AppBarContext';
import newsSlice from 'store/modules/news';
import sessionSlice from 'store/modules/session';

function TrainerDashboard() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'labels', 'routes', 'sessions', 'tables']);
  const sessionLabel = t('session.list', { ns: 'routes' }).toLowerCase();
  const emptySessionsMessage = t('emptyNoFilters', { type: sessionLabel, ns: 'tables' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const itemsPerPage = 5;

  const firstName = useSelector((state) => state.auth.user.first_name);
  const role = useSelector((state) => state.auth.user.role);
  const uiLanguage = useSelector((state) => state.settings.config.language);
  const userId = useSelector((state) => state.auth.user.id);

  const pageTitle = t('dashboard', { name: firstName, ns: 'routes' });

  useEffect(() => {
    dispatch(
      sessionSlice.actions.dashboard({
        fields: sessionDashboardTrainerFields,
        userId: Number(userId)
      })
    );
  }, [role, userId]);

  useEffect(() => {
    dispatch(
      newsSlice.actions.list({
        fields: newsListBasicFields,
        filters: { language: uiLanguage },
        pagination: { first: 10, page: 1 }
      })
    );
  }, [uiLanguage]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  return (
    <>
      <SEOPageTitle title={t('home', { ns: 'routes' })} />
      <Grid container spacing={4}>
        <Grid xs={12} container spacing={{ xs: 2, md: 4 }}>
          <Grid xs={12} md={4}>
            <TodaySessionsCounter />
          </Grid>
          <Grid xs={12} md={4}>
            <SessionReminderCounter />
          </Grid>
          <Grid xs={12} md={4}>
            <SessionStatusCounter />
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <Stack spacing={4}>
            <NextSessionsList emptyMessage={emptySessionsMessage} itemsPerPage={itemsPerPage} />
            <SessionsCompletedTable
              emptyMessage={emptySessionsMessage}
              itemsPerPage={itemsPerPage}
            />
          </Stack>
        </Grid>
        <Grid xs={12} md={6}>
          <LatestNews itemsPerPage={itemsPerPage} />
        </Grid>
      </Grid>
    </>
  );
}

export default TrainerDashboard;
