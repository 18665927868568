import routes from 'common/routes';
import { actions, dateRange, userProfile } from 'common/table/columns/customColumns';

const getProgressColumns = ({ data, translateFunc }) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  userProfile({ data, label: translateFunc('client', { count: 1, ns: 'labels' }) }),
  dateRange({ data, label: translateFunc('period', { ns: 'labels' }) }),
  { label: translateFunc('createdAt', { ns: 'labels' }), name: 'created_at' },
  actions({
    items: ['view', 'edit'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editProgress.path, view: routes.viewProgress.path }
  })
];

export default getProgressColumns;
