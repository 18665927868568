import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { lightFormat, parseISO } from 'date-fns';
import { Unstable_Grid2 as Grid } from '@mui/material';
import ProgressGalleryImage from 'components/shared/galleries/progress/ProgressGalleryImage';
import { monthYearFormat } from 'common/constants/date';

function ProgressGalleryHighlight({ progress }) {
  const { t } = useTranslation(['labels']);
  const firstItem = progress[0];
  const date = lightFormat(parseISO(firstItem.created_at), monthYearFormat);

  return (
    <Grid container spacing={4} sx={{ marginBottom: (theme) => theme.spacing(5) }}>
      <Grid xs={12} sm={4}>
        <ProgressGalleryImage
          alt={t('profile', { ns: 'labels' })}
          src={firstItem.profile_picture}
          date={date}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <ProgressGalleryImage
          alt={t('front', { ns: 'labels' })}
          src={firstItem.front_picture}
          date={date}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <ProgressGalleryImage
          alt={t('back', { ns: 'labels' })}
          src={firstItem.back_picture}
          date={date}
        />
      </Grid>
    </Grid>
  );
}

ProgressGalleryHighlight.propTypes = {
  progress: PropTypes.arrayOf(
    PropTypes.shape({
      back_picture: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      front_picture: PropTypes.string.isRequired,
      profile_picture: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default ProgressGalleryHighlight;
