import uiSlice from 'store/modules/ui';
import { createSnack } from 'common/utils/general';

const error =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const result = next(action);
    const { type, payload } = action;

    // If request fail, show error alert
    if (type.indexOf('Error') >= 0) {
      const key = type.toLowerCase().replace(/\//gi, '');
      const message = typeof payload === 'object' && payload !== null ? payload.message : payload;
      const snack = createSnack({ key, variant: 'error', message });

      dispatch(uiSlice.actions.enqueueSnackbar(snack));

      return result;
    }

    return result;
  };

export default error;
