import { takeLatest, put } from 'redux-saga/effects';
import geoSlice from 'store/modules/geo';
import geo from 'api/rest/geo';
import { getCityOptions, getCountryOptions, getStateOptions } from 'common/utils/geo';

/**
 * Get cities saga
 *
 * @param {*} action
 */
function* getCities(action) {
  try {
    const response = yield geo.getCities(action.payload);
    const cityOptions = getCityOptions(response.data);
    yield put(geoSlice.actions.getCitiesSuccess(cityOptions));
  } catch (ex) {
    yield put(geoSlice.actions.getCitiesError(ex));
  }
}

/**
 * Get countries saga
 *
 */
function* getCountries() {
  try {
    const response = yield geo.getCountries();
    const countryOptions = getCountryOptions(response.data);
    yield put(geoSlice.actions.getCountriesSuccess(countryOptions));
  } catch (ex) {
    yield put(geoSlice.actions.getCountriesError(ex));
  }
}

/**
 * Get states saga
 *
 * @param {*} action
 */
function* getStates(action) {
  try {
    const response = yield geo.getStates(action.payload);
    const stateOptions = getStateOptions(response.data.states);
    yield put(geoSlice.actions.getStatesSuccess(stateOptions));
  } catch (ex) {
    yield put(geoSlice.actions.getStatesError(ex));
  }
}

/**
 * Watch geo
 *
 * @export
 */
export default function* watchGeo() {
  yield takeLatest(geoSlice.actions.getCities, getCities);
  yield takeLatest(geoSlice.actions.getCountries, getCountries);
  yield takeLatest(geoSlice.actions.getStates, getStates);
}
