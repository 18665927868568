import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Divider, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import AutocompleteFieldAdapter from 'components/shared/field-adapters/AutocompleteFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import PasswordFieldAdapter from 'components/shared/field-adapters/PasswordFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TextAreaFieldAdapter from 'components/shared/field-adapters/TextAreaFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import { getGenderOptions, getUiLanguageOptions } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import { isRequiredField } from 'common/utils/general';

function ClientFields({
  cities,
  countries,
  form,
  geoStatus,
  planOptions,
  planStatus,
  requiredFields,
  states,
  values
}) {
  const { t } = useTranslation(['labels']);

  return (
    <>
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="first_name"
            name="first_name"
            component={TextFieldAdapter}
            label={t('firstname', { count: 2, ns: 'labels' })}
            type="text"
            fullWidth
            required={isRequiredField('first_name', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="last_name"
            name="last_name"
            component={TextFieldAdapter}
            label={t('lastname', { count: 2, ns: 'labels' })}
            type="text"
            fullWidth
            required={isRequiredField('last_name', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="gender"
            name="gender"
            component={SelectFieldAdapter}
            options={getGenderOptions(t)}
            label={t('gender', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="email"
            name="email"
            component={TextFieldAdapter}
            label={t('email', { ns: 'labels' })}
            type="text"
            autoComplete="email"
            fullWidth
            required={isRequiredField('email', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="birthdate"
            name="birthdate"
            component={DatePickerFieldAdapter}
            label={t('birthdate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('birthdate', requiredFields)
            }}
            disableFuture
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="phone"
            name="phone"
            component={TextFieldAdapter}
            label={t('phone', { ns: 'labels' })}
            type="text"
            fullWidth
            required={isRequiredField('phone', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="country"
            name="country"
            component={AutocompleteFieldAdapter}
            options={countries}
            renderInputProps={{
              label: t('country', { ns: 'labels' }),
              required: isRequiredField('country', requiredFields)
            }}
            loading={geoStatus === requestStatus.loading}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="state"
            name="state"
            component={AutocompleteFieldAdapter}
            disabled={!values.country}
            options={states}
            renderInputProps={{
              label: t('state', { ns: 'labels' }),
              required: isRequiredField('state', requiredFields)
            }}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="city"
            name="city"
            component={AutocompleteFieldAdapter}
            disabled={!values.country || !values.state}
            options={cities}
            renderInputProps={{
              label: t('city', { ns: 'labels' }),
              required: isRequiredField('city', requiredFields)
            }}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="ui_language"
            name="ui_language"
            component={SelectFieldAdapter}
            options={getUiLanguageOptions(t)}
            label={t('uiLanguage', { ns: 'labels' })}
            fullWidth
            required={isRequiredField('ui_language', requiredFields)}
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('planDetails', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="plan_id"
            name="plan_id"
            component={SelectFieldAdapter}
            options={planOptions}
            disabled={planStatus === requestStatus.loading}
            label={t('plan', { ns: 'labels' })}
            fullWidth
            required={isRequiredField('plan_id', requiredFields)}
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('password', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="password"
            name="password"
            component={PasswordFieldAdapter}
            label={t('password', { ns: 'labels' })}
            autoComplete="current-password"
            onShuffle={form.mutators.generatePassword}
            fullWidth
            required={isRequiredField('password', requiredFields)}
            shuffle
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('observations', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container>
        <Grid xs={12}>
          <Field
            id="observations"
            name="observations"
            component={TextAreaFieldAdapter}
            label={t('observations', { ns: 'labels' })}
            helperText={t('text', { ns: 'validations' })}
            multiline
            minRows={5}
            type="text"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

ClientFields.propTypes = {
  cities: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  geoStatus: PropTypes.string.isRequired,
  planOptions: PropTypes.array.isRequired,
  planStatus: PropTypes.string.isRequired,
  requiredFields: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired
};

export default ClientFields;
