import _camelCase from 'lodash/camelCase';
import { format, parseISO } from 'date-fns';
import { dateFormat } from 'common/constants/date';
import { formatCurrency } from 'common/utils/format';

/**
 * Transforms the given admin data into a new format.
 *
 * @param {Array} data - The admin data to transform.
 * @param {Function} translateFunc - The translation function.
 * @param {string} dateLocale - The locale for date formatting.
 * @returns {Array} - The transformed admin data.
 */
export const getAdminData = (data, translateFunc, dateLocale) =>
  data.map((admin) => ({
    id: admin.id,
    created_at: format(parseISO(admin.created_at), dateFormat, { locale: dateLocale }),
    email: admin.email,
    first_name: admin.first_name,
    full_name: admin.full_name,
    gender: translateFunc(`gender.${_camelCase(admin.gender)}`, { ns: 'options' }),
    last_name: admin.last_name,
    photo: admin.photo
  }));

/**
 * Transforms the given arrearage client data into a new format.
 *
 * @param {Array} data - The array of client data.
 * @param {string} dateLocale - The locale for date formatting.
 * @returns {Array} - The formatted arrearage client data.
 */
export const getArrearageClientsData = (data, dateLocale) =>
  data.map((client) => ({
    user: client.full_name,
    due_date: format(parseISO(client.last_client_payment.end_date), dateFormat, {
      locale: dateLocale
    }),
    membership_days_in_arrears: client.membership_days_in_arrears,
    membership_status: client.membership_status
  }));

/**
 * Transforms the given client data into a new format.
 *
 * @param {Array} data - The array of client data.
 * @param {Function} translateFunc - The translation function.
 * @returns {Array} - The transformed client data.
 */
export const getClientData = (data, translateFunc) =>
  data.map((client) => ({
    id: client.id,
    country: JSON.parse(client.country).label,
    first_name: client.first_name,
    full_name: client.full_name,
    gender: translateFunc(`gender.${_camelCase(client.gender)}`, { ns: 'options' }),
    last_name: client.last_name,
    membership_days_left: client.membership_days_left,
    membership_status: client.membership_status,
    photo: client.photo,
    plan: client.plan_id?.name
  }));

/**
 * Transforms the given legal data into a new format.
 *
 * @param {Array} data - The original data to be transformed.
 * @param {Function} translateFunc - The translation function used to translate language and role values.
 * @param {string} dateLocale - The locale used for formatting dates.
 * @returns {Array} - The transformed data with localized dates and translated values.
 */
export const getLegalData = (data, translateFunc, dateLocale) =>
  data.map((legal) => ({
    id: legal.id,
    created_at: format(parseISO(legal.created_at), dateFormat, { locale: dateLocale }),
    language: translateFunc(legal.language, { ns: 'labels' }),
    role: translateFunc(`role.${legal.role.toLowerCase()}`, { ns: 'options' }),
    title: legal.title
  }));

/**
 * Transforms the given trainer data into a new format.
 *
 * @param {Array} data - The array of payment data.
 * @param {string} dateLocale - The locale to use for formatting dates.
 * @returns {Array} - The formatted trainer payment data.
 */
export const getMyTrainerPaymentData = (data, dateLocale) =>
  data.map((payment) => ({
    id: payment.id,
    created_at: format(parseISO(payment.created_at), dateFormat, { locale: dateLocale }),
    end_date: format(parseISO(payment.end_date), dateFormat, { locale: dateLocale }),
    invoice_url: payment.invoice_url,
    price: formatCurrency(payment.price),
    sessions_completed: payment.sessions_completed,
    start_date: format(parseISO(payment.start_date), dateFormat, { locale: dateLocale })
  }));

/**
 * Transforms the given payment data into a new format.
 *
 * @param {Array} data - The meal plan data to be processed.
 * @param {string} dateLocale - The locale to be used for date formatting.
 * @returns {Array} - The formatted meal plan data.
 */
export const getMealPlanData = (data, dateLocale) =>
  data.map((mealPlan) => ({
    id: mealPlan.id,
    created_at: format(parseISO(mealPlan.created_at), dateFormat, { locale: dateLocale }),
    url: mealPlan.pdf_document,
    user: mealPlan.user_id
  }));

/**
 * Transforms the given news data into a new format.
 *
 * @param {Array} data - The news data to transform.
 * @param {Function} translateFunc - The translation function to use.
 * @param {string} dateLocale - The locale to use for date formatting.
 * @returns {Array} - The transformed news data.
 */
export const getNewsData = (data, translateFunc, dateLocale) =>
  data.map((news) => ({
    id: news.id,
    created_at: format(parseISO(news.created_at), dateFormat, { locale: dateLocale }),
    language: translateFunc(news.language, { ns: 'labels' }),
    title: news.title
  }));

/**
 * Transforms the given plan data into a new format.
 *
 * @param {Array} data - The array of plan data.
 * @returns {Array} - The formatted plan data.
 */
export const getPlanData = (data) =>
  data.map((plan) => ({
    id: plan.id,
    clients: plan.clients,
    currency: plan.currency,
    name: plan.name,
    price: formatCurrency(plan.price)
  }));

/**
 * Transforms the given session type data into a new format.
 *
 * @param {Array} data - The array of session type data.
 * @returns {Array} - The array of session type data with formatted currency.
 */
export const getSessionTypeData = (data) =>
  data.map((sessionType) => ({
    id: sessionType.id,
    name: sessionType.name,
    price: formatCurrency(sessionType.price),
    currency: sessionType.currency
  }));

/**
 * Transforms the given trainer data into a new format.
 *
 * @param {Array} data - The array of trainer data.
 * @param {Function} translateFunc - The translation function.
 * @returns {Array} - The transformed trainer data.
 */
export const getTrainerData = (data, translateFunc) =>
  data.map((trainer) => ({
    id: trainer.id,
    age: `${trainer.age} ${translateFunc('year', { count: 2, ns: 'common' })}`,
    country: JSON.parse(trainer.country).label,
    first_name: trainer.first_name,
    full_name: trainer.full_name,
    gender: translateFunc(`gender.${_camelCase(trainer.gender)}`, { ns: 'options' }),
    last_name: trainer.last_name,
    languages: trainer.languages.map((language) =>
      translateFunc(`language.${_camelCase(language)}`, { ns: 'options' })
    ),
    photo: trainer.photo
  }));

/**
 * Transforms the given trainer payment data into a new format.
 *
 * @param {Array} data - The payment data to be processed.
 * @param {string} dateLocale - The locale to be used for date formatting.
 * @returns {Array} - The formatted trainer payment data.
 */
export const getTrainerPaymentData = (data, dateLocale) =>
  data.map((payment) => ({
    id: payment.id,
    created_at: format(parseISO(payment.created_at), dateFormat, { locale: dateLocale }),
    currency: payment.currency,
    end_date: format(parseISO(payment.end_date), dateFormat, { locale: dateLocale }),
    invoice_url: payment.invoice_url,
    observations: payment.observations,
    price: formatCurrency(payment.price),
    sessions_completed: payment.sessions_completed,
    start_date: format(parseISO(payment.start_date), dateFormat, { locale: dateLocale }),
    user: payment.user_id
  }));

/**
 * Transforms the given trainer payment basic data into a new format.
 *
 * @param {Array} data - The input data array.
 * @param {string} dateLocale - The locale for date formatting.
 * @returns {Array} - An array of formatted trainer payment basic data.
 */
export const getTrainerPaymentBasicData = (data, dateLocale) =>
  data.map((payment) => ({
    created_at: format(parseISO(payment.created_at), dateFormat, { locale: dateLocale }),
    end_date: format(parseISO(payment.end_date), dateFormat, { locale: dateLocale }),
    price: formatCurrency(payment.price),
    sessions_completed: payment.sessions_completed,
    start_date: format(parseISO(payment.start_date), dateFormat, { locale: dateLocale }),
    user: payment.user_id.full_name
  }));
