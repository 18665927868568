import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Unstable_Grid2 as Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LastPaymentCounter from 'components/dashboard/client/counters/LastPaymentCounter';
import SessionFinishedTodayCounter from 'components/dashboard/client/counters/SessionFinishedTodayCounter';
import SessionToScheduleCounter from 'components/dashboard/client/counters/SessionToScheduleCounter';
import LatestNews from 'components/shared/lists/LatestNews';
import LatestNewsCarousel from 'components/shared/carousels/LatestNewsCarousel';
import SessionsCompletedTable from 'components/dashboard/trainer/SessionsCompletedTable';
import NextSessionsList from 'components/shared/lists/NextSessionsList';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import { newsListBasicFields } from 'common/constants/query-fields/news';
import { sessionDashboardClientFields } from 'common/constants/query-fields/session';
import AppBarContext from 'context/AppBarContext';
import clientPaymentSlice from 'store/modules/clientPayment';
import newsSlice from 'store/modules/news';
import sessionSlice from 'store/modules/session';

function ClientDashboard() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['notFound', 'routes', 'tables']);
  const theme = useTheme();
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const sessionLabel = t('session.list', { ns: 'routes' }).toLowerCase();
  const emptySessionsMessage = t('emptyNoFilters', { type: sessionLabel, ns: 'tables' });
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  const newsPerPage = 10;
  const listItemsPerPage = 5;

  const firstName = useSelector((state) => state.auth.user.first_name);
  const role = useSelector((state) => state.auth.user.role);
  const uiLanguage = useSelector((state) => state.settings.config.language);
  const userId = Number(useSelector((state) => state.auth.user.id));

  const pageTitle = t('dashboard', { name: firstName, ns: 'routes' });

  useEffect(() => {
    dispatch(
      sessionSlice.actions.dashboard({
        fields: sessionDashboardClientFields,
        userId
      })
    );
  }, [role, userId]);

  useEffect(() => {
    dispatch(clientPaymentSlice.actions.getLatest(userId));
  }, [userId]);

  useEffect(() => {
    dispatch(
      newsSlice.actions.list({
        fields: newsListBasicFields,
        filters: { language: uiLanguage },
        pagination: { first: 20, page: 1 }
      })
    );
  }, [uiLanguage]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  return (
    <>
      <SEOPageTitle title={t('home', { ns: 'routes' })} />
      <Grid container spacing={{ xs: 2, lg: 4 }}>
        <Grid xs={12} container order={1} spacing={{ xs: 1, sm: 2, md: 4 }}>
          <Grid xs={4}>
            <SessionFinishedTodayCounter />
          </Grid>
          <Grid xs={4}>
            <LastPaymentCounter />
          </Grid>
          <Grid xs={4}>
            <SessionToScheduleCounter />
          </Grid>
        </Grid>
        <Grid xs={12} md={6} order={{ xs: 3, md: 2 }}>
          <Stack spacing={{ xs: 2, lg: 4 }}>
            <NextSessionsList emptyMessage={emptySessionsMessage} itemsPerPage={listItemsPerPage} />
            <SessionsCompletedTable
              emptyMessage={emptySessionsMessage}
              itemsPerPage={listItemsPerPage}
            />
          </Stack>
        </Grid>
        <Grid xs={12} md={6} order={{ xs: 2, md: 3 }}>
          {isMedium ? <LatestNews itemsPerPage={newsPerPage} /> : <LatestNewsCarousel />}
        </Grid>
      </Grid>
    </>
  );
}

export default ClientDashboard;
