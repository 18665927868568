import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import _debounce from 'lodash/debounce';
import { Unstable_Grid2 as Grid } from '@mui/material';
import CheckboxFielddAdapter from 'components/shared/field-adapters/CheckboxFieldAdapter';
import DateRangePickerFieldAdapter from 'components/shared/field-adapters/DateRangePickerFieldAdapter';
import MultipleSelectFieldAdapter from 'components/shared/field-adapters/MultipleSelectFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import FilterButtons from 'components/shared/buttons/FilterButtons';
import {
  getSessionStatusOptions,
  optionsLimit,
  roles,
  sessionStatus
} from 'common/constants/options';
import { debounceWait } from 'common/constants/general';
import { userSearchFields } from 'common/constants/query-fields/user';
import requestStatus from 'common/constants/status';
import StyledFiltersContainer from 'common/theme/filters/StyledFiltersContainer';
import StyledFiltersForm from 'common/theme/filters/StyledFiltersForm';
import { formatDateRangeField } from 'common/utils/date';
import { isTrainer } from 'common/utils/user';
import useForms from 'hooks/useForms';
import sessionSlice from 'store/modules/session';
import clientSlice from 'store/modules/client';
import sessionTypeSlice from 'store/modules/sessionType';
import trainerSlice from 'store/modules/trainer';

function SessionFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels']);
  const { clearClientSearchResults, clearTrainerSearchResults } = useForms();

  const clientOptions = useSelector((state) => state.client.data.search);
  const clientSearchStatus = useSelector((state) => state.client.status.search);
  const initialValues = useSelector((state) => state.session.filters);
  const role = useSelector((state) => state.auth.user.role);
  const sessionTypeOptions = useSelector((state) => state.sessionType.sessionTypeOptions);
  const sessionTypeStatus = useSelector((state) => state.sessionType.status);
  const status = useSelector((state) => state.session.status.general);
  const trainerOptions = useSelector((state) => state.trainer.data.search);
  const trainerSearchStatus = useSelector((state) => state.trainer.status.search);

  const isTrainerUser = isTrainer(role);

  useEffect(() => {
    dispatch(sessionTypeSlice.actions.listAll());
  }, [dispatch]);

  const updatedInitialValues = isTrainerUser
    ? { ...initialValues, status: sessionStatus.scheduled }
    : initialValues;

  const onFilter = useCallback(
    (values) => {
      const filters = formatDateRangeField(values, 'start_between', true);
      dispatch(sessionSlice.actions.updateFilters(filters));
    },
    [dispatch]
  );

  const onUserIdChange = (event, value, reason) => {
    if (reason === 'input' && value.length >= 2) {
      if (isTrainer(role)) {
        dispatch(
          clientSlice.actions.search({
            input: { search: value, role: roles.client, limit: optionsLimit },
            fields: userSearchFields
          })
        );
      } else {
        dispatch(
          trainerSlice.actions.search({
            input: { search: value, role: roles.trainer, limit: optionsLimit },
            fields: userSearchFields
          })
        );
      }
    }
  };

  const resetForm = useCallback(() => {
    dispatch(sessionSlice.actions.resetFilters());
  }, [dispatch]);

  return (
    <StyledFiltersContainer>
      <Form
        initialValues={updatedInitialValues}
        onSubmit={onFilter}
        render={({ handleSubmit, pristine }) => (
          <StyledFiltersForm onSubmit={handleSubmit}>
            <Grid container spacing={{ xs: 2, lg: 4 }}>
              <Grid xs={12} md={6}>
                <Field
                  id={isTrainerUser ? 'client_id' : 'trainer_id'}
                  name={isTrainerUser ? 'client_id' : 'trainer_id'}
                  component={TypeaheadFieldAdapter}
                  fullWidth
                  loading={(clientSearchStatus || trainerSearchStatus) === requestStatus.loading}
                  isMultiple
                  onClose={isTrainerUser ? clearClientSearchResults : clearTrainerSearchResults}
                  onInputChange={_debounce(onUserIdChange, debounceWait)}
                  options={isTrainerUser ? clientOptions : trainerOptions}
                  renderInputProps={{
                    label: t(isTrainerUser ? 'client' : 'trainer', {
                      count: 2,
                      ns: 'labels'
                    })
                  }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Field
                  id="start_between"
                  name="start_between"
                  label={t('date', { ns: 'labels' })}
                  component={DateRangePickerFieldAdapter}
                />
              </Grid>
              <Grid xs={12} md={isTrainerUser ? 5 : 6}>
                <Field
                  id="session_type_id"
                  name="session_type_id"
                  component={MultipleSelectFieldAdapter}
                  options={sessionTypeOptions}
                  label={t('category', { ns: 'labels' })}
                  fullWidth
                  disabled={sessionTypeStatus === requestStatus.loading}
                />
              </Grid>
              <Grid xs={12} md={isTrainerUser ? 4 : 6}>
                <Field
                  id="status"
                  name="status"
                  component={SelectFieldAdapter}
                  options={getSessionStatusOptions(t)}
                  label={t('status', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
              {isTrainerUser && (
                <Grid xs={12} md={3}>
                  <Field
                    id="pending_update"
                    name="pending_update"
                    component={CheckboxFielddAdapter}
                    label={t('toFinish', { ns: 'labels' })}
                  />
                </Grid>
              )}
            </Grid>
            <FilterButtons pristine={pristine} resetForm={resetForm} status={status} />
          </StyledFiltersForm>
        )}
      />
    </StyledFiltersContainer>
  );
}

export default SessionFilters;
