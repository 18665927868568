import { Box, styled } from '@mui/material';

const StyledDrawerMenuUserInfoContainer = styled(Box)(({ theme }) => ({
  margin: 0,
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(2)
  }
}));

export default StyledDrawerMenuUserInfoContainer;
