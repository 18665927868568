export const dateFormat = 'P';

export const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

export const hourFormat = 'h';

export const hourFormatAmPm = 'h aaa';

export const hourMinuteFormat = 'hh:mm';

export const iso8601Format = 'yyyy-MM-dd';

export const isoFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";

export const longFormatEN = 'iiii, MMM d, yyyy';

export const longFormatES = "iiii d 'de' MMM, yyyy";

export const monthYearFormat = 'MM/yy';

export const sessionEndMaximumHour = 20;

export const sessionStartMinimumHour = 4;

export const timeFormat = 'hh:mm aaa';
