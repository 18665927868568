import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import NewsFields from 'components/news/NewsFields';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import defaultNewsFormValidations from 'common/validations/form/defaultNews';
import routes from 'common/routes';
import requestStatus from 'common/constants/status';
import getDataToSave from 'common/form/dataToSave';
import { createNewsInitialValues } from 'common/form/initial-values/create';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import AppBarContext from 'context/AppBarContext';
import newsSlice from 'store/modules/news';

const requiredFields = ['created_at', 'body', 'title'];

function NewsCreate() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('news.create', { ns: 'routes' });
  const formRef = useRef(null);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const status = useSelector((state) => state.news.status.general);
  const uiLanguage = useSelector((state) => state.settings.config.language);

  useEffect(() => {
    if (status === requestStatus.success && formRef.current) {
      formRef.current.restart();
    }
  }, [status]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onCreate = useCallback(
    (values) => {
      const dataToSave = getDataToSave(values, 'news');
      dispatch(newsSlice.actions.create(dataToSave));
    },
    [dispatch]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={createNewsInitialValues}
        onSubmit={onCreate}
        validate={defaultNewsFormValidations}
        render={({ handleSubmit, form, invalid, pristine }) => {
          formRef.current = form;

          return (
            <form onSubmit={handleSubmit}>
              <NewsFields requiredFields={requiredFields} uiLanguage={uiLanguage} />
              <StyledButtonsContainer>
                <SecondaryButtonLoading
                  component={RouterLink}
                  disabled={status === requestStatus.loading}
                  style={{ marginRight: '32px' }}
                  label={t('cancel', { ns: 'buttons' })}
                  to={routes.listNews.path}
                  type="button"
                />
                <PrimaryLoadingButton
                  disabled={invalid || pristine || status === requestStatus.loading}
                  loading={status === requestStatus.loading}
                  label={t('news.create', { ns: 'buttons' })}
                  type="submit"
                />
              </StyledButtonsContainer>
            </form>
          );
        }}
      />
    </>
  );
}

export default NewsCreate;
