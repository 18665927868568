import { Box, styled } from '@mui/material';

const StyledProfilePictureContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.secondary.main,
  borderStyle: 'solid',
  borderWidth: '4px',
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  position: 'relative'
}));

export default StyledProfilePictureContainer;
