import { BottomNavigationAction, styled } from '@mui/material';
import { bottomNavigationActionClasses } from '@mui/material/BottomNavigationAction';

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  [`&.${bottomNavigationActionClasses.selected}`]: {
    color: theme.palette.secondary.main
  }
}));

export default StyledBottomNavigationAction;
