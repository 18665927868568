import React from 'react';
import { ListItemButton, styled } from '@mui/material';
import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { listItemTextClasses } from '@mui/material/ListItemText';
import { primaryButtonPseudoElementStyles } from 'common/theme/button/sharedButtonStyles';

const StyledMainMenuButton = styled(({ iconOnly, ...otherProps }) => (
  <ListItemButton {...otherProps} />
))(({ iconOnly, theme }) => ({
  borderRadius: '10px',
  justifyContent: iconOnly ? 'center' : 'flex-start',
  position: 'relative',
  '&::before': {
    background:
      'linear-gradient(90deg, rgba(27, 35, 191, 0.9) 0%, #273BD7 85.18%, #273BD7 99.99%, rgba(255, 255, 255, 0) 100%)',
    borderRadius: '10px',
    ...primaryButtonPseudoElementStyles(theme)
  },
  [`& .${listItemTextClasses.root}, & .${listItemIconClasses.root}`]: {
    zIndex: 1
  },
  [`&:hover, &.${listItemButtonClasses.selected}`]: {
    backgroundColor: 'transparent',
    '&::before': {
      opacity: 1
    },
    [`& .${listItemTextClasses.root}, & .${listItemIconClasses.root}`]: {
      color: theme.palette.common.white
    }
  },
  [`&.${listItemButtonClasses.selected}:hover::before`]: {
    background:
      'linear-gradient(90deg, rgba(20, 25, 170, 0.9) 0%, #202fC7 85.18%, #202fC7 99.99%, rgba(255, 255, 255, 0) 100%)'
  }
}));

export default StyledMainMenuButton;
