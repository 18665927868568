import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import StyledAlert from 'common/theme/alerts/StyledAlert';

const bottomSpacingStyles = {
  marginBottom: (theme) => theme.spacing(4)
};

const fixedVariantStyles = {
  justifyContent: 'center'
};

const getAlertObject = ({ action = null, message, severity = 'warning', styles = {} }) => ({
  action,
  message,
  severity,
  styles
});

function AlertBlock({ actions, onClose, type }) {
  const { t } = useTranslation(['availabilities', 'buttons', 'sessions']);

  const options = useMemo(
    () => ({
      allowPushNotifications: getAlertObject({
        action: (
          <Button color="inherit" onClick={actions.allow} size="small">
            {t('allow', { ns: 'buttons' })}
          </Button>
        ),
        message: t('allowPushNotifications', { ns: 'notifications' }),
        severity: 'info'
      }),
      availabilityDelete: getAlertObject({
        message: t('delete', { ns: 'availabilities' })
      }),
      availabilityRepeat: getAlertObject({
        message: t('repeat', { ns: 'availabilities' })
      }),
      blockedPushNotifications: getAlertObject({
        message: t('pushNotificationBlocked', { ns: 'notifications' })
      }),
      clientTimeRangeFilled: getAlertObject({
        message: t('client.timeRangeFilled', { ns: 'errors' })
      }),
      sessionDelete: getAlertObject({
        message: t('warning.delete', { ns: 'sessions' }),
        styles: bottomSpacingStyles
      }),
      sessionUpdate: getAlertObject({
        message: t('warning.update', { ns: 'sessions' }),
        styles: bottomSpacingStyles
      }),
      trainerAvailabilityOutdated: getAlertObject({
        message: t('availability.outdated', { ns: 'errors' }),
        severity: 'error',
        styles: fixedVariantStyles
      }),
      trainerProfileIncomplete: getAlertObject({
        message: t('profile.incompleteAlert', { ns: 'errors' }),
        severity: 'error',
        styles: fixedVariantStyles
      })
    }),
    [actions, t]
  );

  return (
    <StyledAlert
      action={options[type].action}
      onClose={onClose}
      severity={options[type].severity}
      sx={{ ...options[type].styles }}
      variant="filled">
      {options[type].message}
    </StyledAlert>
  );
}

AlertBlock.defaultProps = {
  actions: {},
  onClose: null
};

AlertBlock.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  onClose: PropTypes.func,
  type: PropTypes.oneOf([
    'allowPushNotifications',
    'availabilityDelete',
    'availabilityRepeat',
    'blockedPushNotifications',
    'clientTimeRangeFilled',
    'sessionDelete',
    'sessionUpdate',
    'trainerAvailabilityOutdated',
    'trainerProfileIncomplete'
  ]).isRequired
};

export default AlertBlock;
