import { Button, styled } from '@mui/material';

const StyledAvailabilityButton = styled(Button)(() => ({
  border: 'none',
  borderRadius: '0 10px 10px 0',
  height: '100%',
  minWidth: 'auto !important',
  padding: 0,
  width: '30px',
  '&:hover': {
    border: 'none'
  }
}));

export default StyledAvailabilityButton;
