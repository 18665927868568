import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Tooltip } from '@mui/material';
import { Edit, Search } from '@mui/icons-material';
import StyledActionColumnButton from 'common/theme/button/StyledActionColumnButton';

function ActionsColumn({ actions, data, routes }) {
  const { t } = useTranslation();
  const { id } = data;

  return (
    <Box>
      {actions.includes('edit') && (
        <Link component={RouterLink} to={routes.edit.replace(':id', id)}>
          <Tooltip title={t('edit', { ns: 'common' })}>
            <StyledActionColumnButton aria-label="edit">
              <Edit />
            </StyledActionColumnButton>
          </Tooltip>
        </Link>
      )}
      {actions.includes('view') && (
        <Link component={RouterLink} to={routes.view.replace(':id', id)}>
          <Tooltip title={t('view', { ns: 'common' })}>
            <StyledActionColumnButton aria-label="view">
              <Search />
            </StyledActionColumnButton>
          </Tooltip>
        </Link>
      )}
    </Box>
  );
}

ActionsColumn.propTypes = {
  actions: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  routes: PropTypes.object.isRequired
};

export default ActionsColumn;
