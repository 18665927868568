import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import UserAvatar from 'components/shared/user/UserAvatar';
import { roles } from 'common/constants/options';
import StyledMonthlySessionEventContainer from 'common/theme/calendar/event/month/StyledMonthlySessionEventContainer';
import StyledMonthlySessionEventIndicator from 'common/theme/calendar/event/month/StyledMonthlySessionEventIndicator';
import StyledMonthlySessionEventText from 'common/theme/calendar/event/month/StyledMonthlySessionEventText';
import { isClient } from 'common/utils/user';

function MonthlySessionEvent({ event, viewType }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const { extendedProps: eventProps } = event;
  const { status } = eventProps;
  const user = isClient(viewType) ? eventProps.trainer : eventProps.client;

  return (
    <StyledMonthlySessionEventContainer>
      <StyledMonthlySessionEventIndicator status={status} />
      <UserAvatar size={isMedium ? 30 : 24} textSize={isMedium ? 15 : 12} user={user} />
      <StyledMonthlySessionEventText variant="body2">
        {user.full_name}
      </StyledMonthlySessionEventText>
    </StyledMonthlySessionEventContainer>
  );
}

MonthlySessionEvent.defaultProps = {
  viewType: roles.administrator
};

MonthlySessionEvent.propTypes = {
  event: PropTypes.object.isRequired,
  viewType: PropTypes.string
};

export default MonthlySessionEvent;
