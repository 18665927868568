import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { roles } from 'common/constants/options';
import requestStatus from 'common/constants/status';

const initialState = {
  status: requestStatus.idle,
  user: null,
  accessToken: null,
  expiresIn: null,
  passwordReset: null,
  refreshToken: null,
  tokenSent: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    generateResetToken(state) {
      state.status = requestStatus.loading;
    },
    generateResetTokenSuccess(state) {
      state.status = requestStatus.success;
    },
    generateResetTokenError(state) {
      state.status = requestStatus.error;
    },
    incrementAvailableSessionsCounter(state, action) {
      if (state.user.role === roles.client) {
        state.user.available_sessions += action.payload;
      }
    },
    login(state) {
      state.status = requestStatus.loading;
    },
    loginSuccess(state, action) {
      const {
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_token: refreshToken,
        user
      } = action.payload;

      state.status = requestStatus.success;
      state.accessToken = accessToken;
      state.expiresIn = expiresIn;
      state.refreshToken = refreshToken;
      state.user = user;
    },
    loginError(state) {
      state.status = requestStatus.error;
    },
    logout(state) {
      state.status = requestStatus.loading;
    },
    logoutSuccess(state) {
      state.status = requestStatus.success;
    },
    logoutError(state) {
      state.status = requestStatus.error;
    },
    me(state) {
      state.status = requestStatus.loading;
    },
    meSuccess(state, action) {
      state.status = requestStatus.success;
      state.user = { ...state.user, ...action.payload };
    },
    meError(state) {
      state.status = requestStatus.error;
    },
    resetPassword(state) {
      state.status = requestStatus.loading;
    },
    resetPasswordSuccess(state) {
      state.status = requestStatus.success;
      state.passwordReset = true;
    },
    resetPasswordError(state) {
      state.status = requestStatus.error;
    },
    resetPasswordReset(state) {
      state.passwordReset = false;
    },
    resetTokenSent(state) {
      state.tokenSent = false;
    },
    sendResetToken(state) {
      state.status = requestStatus.loading;
    },
    sendResetTokenSuccess(state) {
      state.status = requestStatus.success;
      state.tokenSent = true;
    },
    sendResetTokenError(state) {
      state.status = requestStatus.error;
    },
    updateAvailableSessionsCounter(state, action) {
      if (state.user.role === roles.client) {
        state.user.available_sessions = action.payload;
      }
    },
    updateMe(state) {
      state.status = requestStatus.loading;
    },
    updateMeSuccess(state, action) {
      state.status = requestStatus.success;
      state.user = { ...state.user, ...action.payload };
    },
    updateMeError(state) {
      state.status = requestStatus.error;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default authSlice;
