import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import ResetPasswordForm from 'components/auth/reset-password/ResetPasswordForm';
import ResetPasswordSuccessMessage from 'components/auth/reset-password/ResetPasswordSuccessMessage';
import StyledLoginContainer from 'common/theme/login/StyledLoginContainer';

function ResetPassword() {
  const { t } = useTranslation(['auth', 'buttons', 'labels', 'routes']);
  const pageTitle = t('auth.resetPassword', { ns: 'routes' });
  const [showLoginLink, setShowLoginLink] = useState(false);

  const passwordReset = useSelector((state) => state.auth.passwordReset);

  useEffect(() => {
    if (passwordReset) {
      setShowLoginLink(true);
    }
  }, [passwordReset]);

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <StyledLoginContainer elevation={0}>
        {!showLoginLink ? <ResetPasswordForm /> : <ResetPasswordSuccessMessage />}
      </StyledLoginContainer>
    </>
  );
}

export default ResetPassword;
