import React from 'react';
import _camelCase from 'lodash/camelCase';
import { Box, styled } from '@mui/material';

const StyledWeeklySessionEventIndicator = styled(({ status, ...otherProps }) => (
  <Box {...otherProps} />
))(({ status, theme }) => ({
  backgroundColor: theme.palette[_camelCase(status)].main,
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  height: '8px',
  [theme.breakpoints.up('md')]: {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  }
}));

export default StyledWeeklySessionEventIndicator;
