import { put, takeLatest } from 'redux-saga/effects';
import availabilitySlice from 'store/modules/availability';
import uiSlice from 'store/modules/ui';
import availability from 'api/graphql/availability';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import {
  constructAvailabilityCalendarEvent,
  sanitizeAvailabilities
} from 'common/utils/availability';
import i18n from 'i18n';

/**
 * Force delete availabilities saga
 *
 * @param {*} action
 */
function* forceDelete(action) {
  try {
    const snack = createSnack({
      key: 'availability-delete-notification-success',
      variant: 'success',
      message: i18n.t('availability.forceDelete', { ns: 'notifications' })
    });

    const { toDelete, userId } = action.payload;

    yield availability.forceDelete(toDelete);
    yield put(availabilitySlice.actions.forceDeleteSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
    yield put(availabilitySlice.actions.getLatest(userId));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(availabilitySlice.actions.forceDeleteError(message));
  }
}

/**
 * Get latest availability saga
 *
 * @param {*} action
 */
function* getLatest(action) {
  try {
    const response = yield availability.getLatest(Number(action.payload));

    const {
      data: { latestAvailability }
    } = response;

    yield put(availabilitySlice.actions.getLatestSuccess(latestAvailability));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(availabilitySlice.actions.getLatestError(message));
  }
}

/**
 * List availabilities saga
 *
 * @param {*} action
 */
function* list(action) {
  try {
    const response = yield availability.list(action.payload);
    const {
      data: { availabilities }
    } = response;

    const calendarEvents = availabilities.map((event) =>
      constructAvailabilityCalendarEvent(event, true)
    );

    yield put(availabilitySlice.actions.listSuccess(calendarEvents));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(availabilitySlice.actions.listError(message));
  }
}

/**
 * Repeat availabilities saga
 *
 * @param {*} action
 */
function* repeat(action) {
  yield put(availabilitySlice.actions.repeatSuccess(action.payload));
}

/**
 * Upsert availabilities saga
 *
 * @param {*} action
 */
function* upsert(action) {
  try {
    const snack = createSnack({
      key: 'availability-update-notification-success',
      variant: 'success',
      message: i18n.t('availability.upsert', { ns: 'notifications' })
    });

    const { toUpsert, userId } = action.payload;
    const sanitizedAvailabilities = sanitizeAvailabilities(toUpsert);

    yield availability.upsert(sanitizedAvailabilities);
    yield put(availabilitySlice.actions.upsertSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
    yield put(availabilitySlice.actions.reset());
    yield put(availabilitySlice.actions.getLatest(userId));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(availabilitySlice.actions.upsertError(message));
  }
}

/**
 * Watch availability
 *
 * @export
 */
export default function* watchAvailability() {
  yield takeLatest(availabilitySlice.actions.forceDelete, forceDelete);
  yield takeLatest(availabilitySlice.actions.getLatest, getLatest);
  yield takeLatest(availabilitySlice.actions.list, list);
  yield takeLatest(availabilitySlice.actions.repeat, repeat);
  yield takeLatest(availabilitySlice.actions.upsert, upsert);
}
