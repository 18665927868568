import { Box, styled } from '@mui/material';

const StyledLargeSessionCardInnerContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.gray0.main,
  borderBottomRightRadius: '4px',
  borderTopRightRadius: '4px',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2)
  },
  [theme.breakpoints.up('lg')]: {
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px'
  }
}));

export default StyledLargeSessionCardInnerContainer;
