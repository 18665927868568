import { Box, styled } from '@mui/material';

const StyledButtonsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(5),
    textAlign: 'right'
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(7)
  }
}));

export default StyledButtonsContainer;
