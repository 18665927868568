import { ListItemIcon, styled } from '@mui/material';
import { svgIconClasses } from '@mui/material/SvgIcon';

const StyledMoreProfileSettingsIcon = styled(ListItemIcon)(({ theme }) => ({
  [`& .${svgIconClasses.root}`]: {
    fontSize: theme.typography.pxToRem(20)
  }
}));

export default StyledMoreProfileSettingsIcon;
