import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import TrainerPaymentFields from 'components/trainer-payment/TrainerPaymentFields';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import defaultPaymentFormValidations from 'common/validations/form/defaultPayment';
import { updateTrainerPaymentInitialValues } from 'common/form/initial-values/update';
import { trainerCreatePaymentSearchFields } from 'common/constants/query-fields/trainer';
import getDataToSave from 'common/form/dataToSave';
import { getFieldsToUpdate } from 'common/form/utils';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import { handleTrainerIdChange } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import trainerPaymentSlice from 'store/modules/trainerPayment';

const requiredFields = [
  'created_at',
  'end_date',
  'plan_name',
  'price',
  'sessions_paid',
  'start_date',
  'user_id'
];

function TrainerPaymentEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('payment.trainer.edit', { ns: 'routes' });
  const { id } = useParams();
  const formRef = useRef(null);
  const [initialValues, setInitialValues] = useState({});
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const searchStatus = useSelector((state) => state.trainer.status.search);
  const status = useSelector((state) => state.trainerPayment.status.general);
  const trainerOptions = useSelector((state) => state.trainer.data.search);
  const trainerPayment = useSelector((state) => state.trainerPayment.data.currentPayment);

  useEffect(() => {
    dispatch(trainerPaymentSlice.actions.find(id));
  }, []);

  useEffect(() => {
    if (trainerPayment && Object.keys(trainerPayment).length > 0 && trainerPayment.id === id) {
      const trainerPaymentData = updateTrainerPaymentInitialValues(trainerPayment);
      setInitialValues(trainerPaymentData);
    }
  }, [trainerPayment]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onEdit = useCallback(
    (values) => {
      const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
      const dataToSave = getDataToSave(fieldsToUpdate, 'payment');
      dispatch(trainerPaymentSlice.actions.update({ id, input: dataToSave }));
    },
    [dispatch, id, initialValues]
  );

  const onTrainerIdChange = handleTrainerIdChange(dispatch, trainerCreatePaymentSearchFields);

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={initialValues}
        onSubmit={onEdit}
        validate={defaultPaymentFormValidations}
        render={({ form, handleSubmit, invalid, pristine }) => {
          formRef.current = form;

          return (
            <form onSubmit={handleSubmit}>
              <TrainerPaymentFields
                onTrainerIdChange={onTrainerIdChange}
                requiredFields={requiredFields}
                searchStatus={searchStatus}
                trainerOptions={trainerOptions}
              />
              <StyledButtonsContainer>
                <SecondaryButtonLoading
                  component={RouterLink}
                  disabled={status === requestStatus.loading}
                  style={{ marginRight: '32px' }}
                  label={t('cancel', { ns: 'buttons' })}
                  to={routes.listTrainerPayments.administrator.path}
                  type="button"
                />
                <PrimaryLoadingButton
                  disabled={invalid || pristine || status === requestStatus.loading}
                  loading={status === requestStatus.loading}
                  label={t('payment.trainer.edit', { ns: 'buttons' })}
                  type="submit"
                />
              </StyledButtonsContainer>
            </form>
          );
        }}
      />
    </>
  );
}

export default TrainerPaymentEdit;
