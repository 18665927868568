import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { AccountCircle, Paid, School } from '@mui/icons-material';
import { Tabs, Unstable_Grid2 as Grid } from '@mui/material';
import PaymentTabPanel from 'components/trainer/profile/PaymentTabPanel';
import PersonalTabPanel from 'components/trainer/profile/PersonalTabPanel';
import ProfessionalTabPanel from 'components/trainer/profile/ProfessionalTabPanel';
import ProfilePictureFieldAdapter from 'components/shared/field-adapters/ProfilePictureFieldAdapter';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TabPanel from 'components/shared/tabs/TabPanel';
import requestStatus from 'common/constants/status';
import getDataToSave from 'common/form/dataToSave';
import { updateTrainerProfileInitialValues } from 'common/form/initial-values/update';
import { getFieldsToUpdate } from 'common/form/utils';
import StyledIncompleteTrainerProfileChip from 'common/theme/chips/StyledIncompleteTrainerProfileChip';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import StyledProfileButtonContainer from 'common/theme/profile/StyledProfileButtonContainer';
import StyledProfileHeaderText from 'common/theme/profile/StyledProfileHeaderText';
import StyledProfilePictureContainer from 'common/theme/profile/StyledProfilePictureContainer';
import StyledProfileTab from 'common/theme/profile/StyledProfileTab';
import StyledProfileUsername from 'common/theme/profile/StyledProfileUsername';
import validateEditTrainerProfileForm from 'common/validations/form/editTrainerProfile';
import { tabsA11yProps } from 'common/utils/general';
import { getCountryFromJson } from 'common/utils/geo';
import { getSpecialties } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import authSlice from 'store/modules/auth';
import geoSlice from 'store/modules/geo';
import sessionTypeSlice from 'store/modules/sessionType';

function TrainerProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'labels', 'routes']);
  const pageTitle = t('profile', { ns: 'routes' });
  const [initialValues, setInitialValues] = useState({ languages: [], specialties: [] });
  const [currentTab, setCurrentTab] = useState(0);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const countries = useSelector((state) => state.geo.countries);
  const geoStatus = useSelector((state) => state.geo.status);
  const sessionTypeOptions = useSelector((state) => state.sessionType.sessionTypeOptions);
  const sessionTypeStatus = useSelector((state) => state.sessionType.status);
  const status = useSelector((state) => state.auth.status);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(sessionTypeSlice.actions.listAll());
  }, [dispatch]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      const userData = updateTrainerProfileInitialValues(user);
      setInitialValues(userData);
    }
  }, [user]);

  useEffect(() => {
    if (geoStatus !== requestStatus.loading && countries.length <= 0) {
      dispatch(geoSlice.actions.getCountries());
    }
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(null);
  }, [setAppBarButton, setAppBarTitle]);

  const onTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const onUpdate = (values) => {
    const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
    const dataToSave = getDataToSave(fieldsToUpdate, 'user');
    dispatch(authSlice.actions.updateMe({ id: user.id, input: dataToSave }));
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={initialValues}
        onSubmit={onUpdate}
        validate={validateEditTrainerProfileForm}
        render={({ handleSubmit, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={{ xs: 1, sm: 4 }} mb={{ xs: 2, lg: 4 }}>
              <Grid xs={12} sm="auto">
                <StyledProfilePictureContainer>
                  <Field
                    id="photo"
                    name="photo"
                    component={ProfilePictureFieldAdapter}
                    firstName={user.first_name}
                    lastName={user.last_name}
                  />
                </StyledProfilePictureContainer>
              </Grid>
              <Grid xs={12} sm>
                <StyledProfileUsername>{user.full_name}</StyledProfileUsername>
                {user.country && (
                  <StyledProfileHeaderText>
                    <span>{t('country', { ns: 'labels' })}</span>
                    <span>:</span> <span>{getCountryFromJson(user.country).label}</span>
                  </StyledProfileHeaderText>
                )}
                {user.specialties && (
                  <StyledProfileHeaderText>
                    <span>{t('specialties', { ns: 'labels' })}</span>
                    <span>:</span> <span>{getSpecialties(user.specialties)}</span>
                  </StyledProfileHeaderText>
                )}
                {!user.profile_complete && (
                  <StyledIncompleteTrainerProfileChip
                    label={t('profile.incomplete', { ns: 'errors' })}
                    color="error"
                  />
                )}
              </Grid>
            </Grid>
            <Tabs
              value={currentTab}
              onChange={onTabChange}
              textColor="inherit"
              indicatorColor="secondary"
              variant="fullWidth"
              centered>
              <StyledProfileTab
                icon={<AccountCircle />}
                label={t('personalInformation', { ns: 'labels' })}
                {...tabsA11yProps(0)}
              />
              <StyledProfileTab
                icon={<School />}
                label={t('professionalInformation', { ns: 'labels' })}
                {...tabsA11yProps(1)}
              />
              <StyledProfileTab
                icon={<Paid />}
                label={t('paymentInformation', { ns: 'labels' })}
                {...tabsA11yProps(2)}
              />
            </Tabs>
            <TabPanel current={currentTab} index={0}>
              <PersonalTabPanel countries={countries} geoStatus={geoStatus} />
            </TabPanel>
            <TabPanel current={currentTab} index={1}>
              <ProfessionalTabPanel
                sessionTypeOptions={sessionTypeOptions}
                sessionTypeStatus={sessionTypeStatus}
              />
            </TabPanel>
            <TabPanel current={currentTab} index={2}>
              <PaymentTabPanel />
            </TabPanel>
            <StyledProfileButtonContainer>
              <StyledButtonsContainer>
                <PrimaryLoadingButton
                  disabled={invalid || pristine || status === requestStatus.loading}
                  loading={status === requestStatus.loading}
                  label={t('save', { ns: 'buttons' })}
                  type="submit"
                />
              </StyledButtonsContainer>
            </StyledProfileButtonContainer>
          </form>
        )}
      />
    </>
  );
}

export default TrainerProfile;
