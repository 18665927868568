import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledNotificationMenuButton from 'common/theme/notification/StyledNotificationMenuButton';
import StyledNotificationMenuButtonsContainer from 'common/theme/notification/StyledNotificationMenuButtonsContainer';
import useNotifications from 'hooks/notifications/useNotifications';

function NotificationMenuButtons() {
  const { t } = useTranslation(['buttons']);
  const { deleteAllNotifications, markAllNotificationAsRead } = useNotifications();

  const handleDeleteAll = () => {
    deleteAllNotifications();
  };

  const handleMarkAllAsRead = () => {
    markAllNotificationAsRead();
  };

  return (
    <StyledNotificationMenuButtonsContainer>
      <StyledNotificationMenuButton
        variant="text"
        color="white"
        size="small"
        onClick={handleDeleteAll}>
        {t('deleteAll', { context: 'female', ns: 'buttons' })}
      </StyledNotificationMenuButton>
      <StyledNotificationMenuButton
        variant="contained"
        color="success"
        size="small"
        onClick={handleMarkAllAsRead}>
        {t('markAllAsRead', { context: 'female', ns: 'buttons' })}
      </StyledNotificationMenuButton>
    </StyledNotificationMenuButtonsContainer>
  );
}

export default NotificationMenuButtons;
