import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { lightFormat } from 'date-fns';
import { Box, ListItemIcon, MenuItem } from '@mui/material';
import { Delete, FilterAlt, MoreVert } from '@mui/icons-material';
import { hourFormat, hourFormatAmPm } from 'common/constants/date';
import StyledAvailability from 'common/theme/availability/StyledAvailability';
import StyledAvailabilityButton from 'common/theme/availability/StyledAvailabilityButton';
import StyledAvailabilityContainer from 'common/theme/availability/StyledAvailabilityContainer';
import StyledAvailabilityMenu from 'common/theme/availability/StyledAvailabilityMenu';
import StyledAvailabilityMenuText from 'common/theme/availability/StyledAvailabilityMenuText';
import StyledAvailabilityText from 'common/theme/availability/StyledAvailabilityText';
import { canDeleteAvailability, canFilterAvailabilitySessions } from 'common/utils/availability';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

function AvailabilityEvent({ event, onDelete, onFilter }) {
  const { t } = useTranslation(['buttons']);
  const [anchorEl, setAnchorEl] = useState(null);
  const startTime = lightFormat(event.start, hourFormat);
  const endTime = lightFormat(event.end, hourFormatAmPm);
  const stored = event.extendedProps?.stored || false;
  const open = Boolean(anchorEl);
  const isDelectable = canDeleteAvailability(event);
  const canFilterSessions = canFilterAvailabilitySessions(event);

  const handleClick = (ev) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(event);
    handleClose();
  };

  const handleFilter = () => {
    onFilter(event);
    handleClose();
  };

  return (
    <Box className="fc-event-main-frame">
      <StyledAvailability className="fc-event-time">
        <StyledAvailabilityContainer fullWidth>
          <StyledAvailabilityText className="fc-event-time-text">
            {startTime} - {endTime}
          </StyledAvailabilityText>
          {(canFilterSessions || isDelectable) && (
            <StyledAvailabilityButton
              color={stored ? 'white' : 'black'}
              onClick={handleClick}
              size="small">
              <MoreVert />
            </StyledAvailabilityButton>
          )}
        </StyledAvailabilityContainer>
        <StyledAvailabilityMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}>
          {canFilterSessions && (
            <MenuItem onClick={handleFilter}>
              <ListItemIcon>
                <FilterAlt fontSize="small" />
              </ListItemIcon>
              <StyledAvailabilityMenuText>
                {t('filterSessions', { ns: 'buttons' })}
              </StyledAvailabilityMenuText>
            </MenuItem>
          )}
          {isDelectable && (
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <StyledAvailabilityMenuText>
                {t('deleteAvailability', { ns: 'buttons' })}
              </StyledAvailabilityMenuText>
            </MenuItem>
          )}
        </StyledAvailabilityMenu>
      </StyledAvailability>
    </Box>
  );
}

AvailabilityEvent.defaultProps = {
  onDelete: null,
  onFilter: null
};

AvailabilityEvent.propTypes = {
  event: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onFilter: PropTypes.func
};

export default AvailabilityEvent;
