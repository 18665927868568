import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import AdminFilters from 'components/admin/AdminFilters';
import AppBarButtonLink from 'components/shared/buttons/AppBarButtonLink';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import routes from 'common/routes';
import { getAdminColumns } from 'common/table/columns';
import { getAdminData } from 'common/table/data';
import { defaultTableOptions, getSelectToolBar, tableLocalization } from 'common/table/utils';
import AppBarContext from 'context/AppBarContext';
import adminSlice from 'store/modules/admin';

function AdminList() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['buttons', 'labels', 'routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const pageTitle = t('administrator.list', { ns: 'routes' });
  const emptyMessage = t('empty', { type: pageTitle.toLowerCase(), ns: 'tables' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const filters = useSelector((state) => state.admin.filters);
  const pagination = useSelector((state) => state.admin.pagination);
  const paginatorInfo = useSelector((state) => state.admin.paginatorInfo);
  const refresh = useSelector((state) => state.admin.refresh);
  const status = useSelector((state) => state.admin.status.general);
  const admins = useSelector((state) => state.admin.users);

  const listAdmins = useCallback(() => {
    dispatch(adminSlice.actions.list({ filters, pagination }));
  }, [dispatch, filters, pagination]);

  useEffect(() => {
    listAdmins();
  }, [listAdmins, refresh]);

  useEffect(() => {
    setAppBarButton(
      <AppBarButtonLink label={t('add', { ns: 'buttons' })} path={routes.createAdmin.path} />
    );
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const tableData = getAdminData(admins, t, dateLocale);
  const columns = getAdminColumns({ data: tableData, translateFunc: t });

  const onChangePage = (page) => {
    dispatch(adminSlice.actions.updatePagination({ page: page + 1 }));
  };

  const onDelete = (ids) => {
    dispatch(adminSlice.actions.remove(ids));
  };

  const onRestore = (ids) => {
    dispatch(adminSlice.actions.restore(ids));
  };

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    customToolbarSelect: (selectedRows, displayData) =>
      getSelectToolBar({
        filters,
        rows: selectedRows,
        data: displayData,
        onDelete,
        onRestore
      }),
    onChangePage,
    page: pagination.page - 1,
    rowsPerPage: pagination.first,
    textLabels: tableLocalization(pageTitle.toLowerCase(), t)
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <AdminFilters />
      <Box
        sx={{
          position: 'relative'
        }}>
        <LoadingBlock
          emptyBlockProps={{ message: emptyMessage }}
          isValid={admins.length > 0}
          skeletonProps={{ rows: 5 }}
          status={status}
          type="table">
          <MUIDataTable
            className="user user--admin"
            columns={columns}
            data={tableData}
            options={options}
          />
        </LoadingBlock>
      </Box>
    </>
  );
}

export default AdminList;
