import styledComponent from 'styled-components';

const StyledProgressGalleryGridContainer = styledComponent.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  column-gap: 32px;
  row-gap: 32px;
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
`;

export default StyledProgressGalleryGridContainer;
