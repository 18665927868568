import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Unstable_Grid2 as Grid } from '@mui/material';
import CKEditorFieldAdapter from 'components/shared/field-adapters/CKEditorFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import ImageUploadAdapter from 'components/shared/field-adapters/ImageUploadAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import { getUiLanguageOptions } from 'common/constants/options';
import { isRequiredField } from 'common/utils/general';

function NewsFields({ requiredFields, uiLanguage }) {
  const { t } = useTranslation(['labels']);

  return (
    <Grid container spacing={4}>
      <Grid xs={12}>
        <Field
          id="title"
          name="title"
          component={TextFieldAdapter}
          label={t('title', { ns: 'labels' })}
          type="text"
          fullWidth
          required={isRequiredField('title', requiredFields)}
        />
      </Grid>
      <Grid xs={12} md={12} lg={6}>
        <Field
          id="body"
          name="body"
          component={CKEditorFieldAdapter}
          label={t('body', { ns: 'labels' })}
          language={uiLanguage}
          required={isRequiredField('body', requiredFields)}
        />
      </Grid>
      <Grid xs={12} md={6} lg={3}>
        <Field
          id="featured_image"
          name="featured_image"
          component={ImageUploadAdapter}
          label={t('featuredImage', { ns: 'labels' })}
          allowedFileTypes={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
          }}
        />
      </Grid>
      <Grid xs={12} md={6} lg={3}>
        <Field
          id="mobile_featured_image"
          name="mobile_featured_image"
          component={ImageUploadAdapter}
          label={t('mobileFeaturedImage', { ns: 'labels' })}
          allowedFileTypes={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg']
          }}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Field
          id="created_at"
          name="created_at"
          component={DatePickerFieldAdapter}
          label={t('createdAt', { ns: 'labels' })}
          renderInputProps={{
            fullWidth: true,
            required: isRequiredField('created_at', requiredFields)
          }}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Field
          id="language"
          name="language"
          component={SelectFieldAdapter}
          options={getUiLanguageOptions(t)}
          label={t('language', { count: 1, ns: 'labels' })}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

NewsFields.propTypes = {
  requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  uiLanguage: PropTypes.oneOf(['es', 'en']).isRequired
};

export default NewsFields;
