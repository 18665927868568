const paymentRoutes = {
  payments: {
    path: '/payments'
  },
  listClientPayments: {
    administrator: {
      path: '/payments/clients'
    },
    client: {
      path: '/payment-history'
    }
  },
  listTrainerPayments: {
    administrator: {
      path: '/payments/trainers'
    },
    trainer: {
      path: '/payments/trainer'
    }
  },
  createClientPayment: {
    path: '/payments/clients/create'
  },
  createTrainerPayment: {
    path: '/payments/trainers/create'
  },
  editClientPayment: {
    path: '/payments/clients/:id'
  },
  editTrainerPayment: {
    path: '/payments/trainers/:id'
  }
};

export default paymentRoutes;
