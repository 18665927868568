import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import AlertBlock from 'components/shared/blocks/AlertBlock';
import { shouldUpdateAvailability } from 'common/utils/availability';
import { isTrainer } from 'common/utils/user';

function GlobalBannerBlock() {
  const latestAvailability = useSelector((state) => state.availability.data.latest);
  const profileCompleted = useSelector((state) => state.auth.user.profile_complete);
  const role = useSelector((state) => state.auth.user.role);

  const showAlertBlocks = useMemo(
    () => isTrainer(role) && (!profileCompleted || shouldUpdateAvailability(latestAvailability)),
    [latestAvailability, profileCompleted, role]
  );

  if (!showAlertBlocks) {
    return null;
  }

  return (
    <Stack spacing={2}>
      {shouldUpdateAvailability(latestAvailability) && (
        <AlertBlock type="trainerAvailabilityOutdated" />
      )}
      {!profileCompleted && <AlertBlock type="trainerProfileIncomplete" />}
    </Stack>
  );
}

export default GlobalBannerBlock;
