import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, useMediaQuery } from '@mui/material';
import {
  ArrowCircleDown,
  NavigateBefore,
  NavigateNext,
  ZoomIn,
  ZoomOut
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import StyledPdfViewerControls from 'common/theme/pdf-viewer/StyledPdfViewerControls';

function PdfControls({ download, nextPage, numPages, pageNumber, previousPage, zoomIn, zoomOut }) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonSize = isExtraSmall ? 'small' : 'medium';

  return (
    <StyledPdfViewerControls>
      <div>
        <div>
          <IconButton size={buttonSize} onClick={previousPage} disabled={pageNumber <= 1}>
            <NavigateBefore />
          </IconButton>
          <IconButton size={buttonSize} onClick={nextPage} disabled={pageNumber >= numPages}>
            <NavigateNext />
          </IconButton>
        </div>
        <div>
          <IconButton size={buttonSize} onClick={zoomIn}>
            <ZoomIn />
          </IconButton>
          <IconButton size={buttonSize} onClick={zoomOut}>
            <ZoomOut />
          </IconButton>
        </div>
      </div>
      <IconButton size={buttonSize} onClick={download}>
        <ArrowCircleDown />
      </IconButton>
    </StyledPdfViewerControls>
  );
}

PdfControls.propTypes = {
  download: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  numPages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired
};

export default PdfControls;
