const mealPlanRoutes = {
  listMealPlan: {
    path: '/meal-plans'
  },
  clientMealPlan: {
    path: '/meal-plan'
  },
  createMealPlan: {
    path: '/meal-plans/create'
  },
  editMealPlan: {
    path: '/meal-plans/edit/:id'
  }
};

export default mealPlanRoutes;
