import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Assignment, Home, Payment, Person, Restaurant } from '@mui/icons-material';
import { List } from '@mui/material';
import { AccountBoxMultiple, CalendarCursor, ChartBoxOutline, TableSearch } from 'mdi-material-ui';
import MenuItem from 'components/shared/menus/MenuItem';
import routes from 'common/routes';

function ClientDesktopMenu() {
  const { t } = useTranslation(['routes']);

  const miniDrawer = useSelector((state) => state.settings.config.miniDrawer);

  return (
    <List>
      <MenuItem
        icon={<Home />}
        iconOnly={miniDrawer}
        itemKey="home"
        label={t('home', { ns: 'routes' })}
        path={routes.clientDashboard.path}
      />
      <MenuItem
        icon={<CalendarCursor />}
        iconOnly={miniDrawer}
        itemKey="calendar"
        label={t('session.calendar', { ns: 'routes' })}
        path={routes.calendar.path}
      />
      <MenuItem
        icon={<TableSearch />}
        iconOnly={miniDrawer}
        itemKey="sessions"
        label={t('session.list', { ns: 'routes' })}
        path={routes.clientSessions.path}
      />
      <MenuItem
        icon={<Restaurant />}
        iconOnly={miniDrawer}
        itemKey="nutrition"
        label={t('mealPlan.mealPlan', { ns: 'routes' })}
        path={routes.clientMealPlan.path}
      />
      <MenuItem
        icon={<ChartBoxOutline />}
        iconOnly={miniDrawer}
        itemKey="performance"
        label={t('performance.list', { ns: 'routes' })}
        path={routes.clientPerformances.path}
      />
      <MenuItem
        icon={<AccountBoxMultiple />}
        iconOnly={miniDrawer}
        itemKey="progress"
        label={t('progress.list', { ns: 'routes' })}
        path={routes.clientProgress.path}
      />
      <MenuItem
        icon={<Payment />}
        iconOnly={miniDrawer}
        itemKey="payment"
        label={t('payment.payments', { ns: 'routes' })}
        path={routes.listClientPayments.client.path}
      />
      <MenuItem
        icon={<Person />}
        iconOnly={miniDrawer}
        itemKey="profile"
        label={t('profile', { ns: 'routes' })}
        path={routes.clientProfile.path}
      />
      <MenuItem
        icon={<Assignment />}
        iconOnly={miniDrawer}
        itemKey="legal"
        label={t('legal.list', { ns: 'routes' })}
        path={routes.viewTC.path}
      />
    </List>
  );
}

export default ClientDesktopMenu;
