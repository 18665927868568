/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function DaysInArrearsColumn({ data }) {
  const { t } = useTranslation();
  const { membership_days_in_arrears: daysInArrears } = data;

  return daysInArrears >= 0 ? (
    <Typography>
      {daysInArrears > 1 || daysInArrears === 0
        ? `${daysInArrears} ${t('day', { count: 2, ns: 'common' }).toLowerCase()}`
        : `${daysInArrears} ${t('day', { count: 1, ns: 'common' }).toLowerCase()}`}
    </Typography>
  ) : (
    <Typography>-</Typography>
  );
}

DaysInArrearsColumn.propTypes = {
  data: PropTypes.object.isRequired
};

export default DaysInArrearsColumn;
