import { Box, styled } from '@mui/material';

const StyledImageUploadPreviewContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  height: 'calc(100% - 32px)',
  position: 'absolute',
  width: 'calc(100% - 32px)',
  zIndex: 1
}));

export default StyledImageUploadPreviewContainer;
