import React from 'react';
import { Link } from '@mui/material';
import DaysInArrearsColumn from 'components/shared/table-columns/DaysInArrears';
import DaysLeftColumn from 'components/shared/table-columns/DaysLeft';
import DownloadButton from 'components/shared/buttons/DownloadButton';
import MembershipStatusChip from 'components/shared/chips/MembershipStatusChip';
import MultipleItemsColumn from 'components/shared/table-columns/MultipleItems';
import PriceWithCurrencyColumn from 'components/shared/table-columns/PriceWithCurrency';
import { roles } from 'common/constants/options';
import routes from 'common/routes';
import { actions, dateRange, userProfile } from 'common/table/columns/customColumns';

const getRow = (tableData, rowIndex) => tableData.filter((data, index) => index === rowIndex);

const downloadableFile = ({ data, label, onDownload }) => ({
  label,
  name: 'file',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <DownloadButton onClick={onDownload} id={row.id} />;
    }
  }
});

const invoice = ({ data, label }) => ({
  label,
  name: 'invoice_url',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return row.invoice_url ? (
        <Link href={row.invoice_url} color="secondary" target="_blank" rel="noreferrer noopener">
          {label}
        </Link>
      ) : (
        ''
      );
    }
  }
});

const languages = ({ data, label }) => ({
  label,
  name: 'languages',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <MultipleItemsColumn items={row.languages} />;
    }
  }
});

const membershipDaysInArrears = ({ data, label }) => ({
  label,
  name: 'membership_days_in_arrears',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <DaysInArrearsColumn data={row} />;
    }
  }
});

const membershipDaysLeft = ({ data, label }) => ({
  label,
  name: 'membership_days_left',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <DaysLeftColumn data={row} />;
    }
  }
});

const membershipStatus = ({ data, label }) => ({
  label,
  name: 'membership_status',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      const { membership_status: status } = row;

      return <MembershipStatusChip status={status} />;
    }
  }
});

const priceWithCurrency = ({ data, label }) => ({
  label,
  name: 'price',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <PriceWithCurrencyColumn data={row} />;
    }
  }
});

export const getAdminColumns = ({ data, translateFunc }) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  userProfile({ data, label: translateFunc('administrator', { count: 1, ns: 'labels' }) }),
  { label: translateFunc('email', { ns: 'labels' }), name: 'email' },
  { label: translateFunc('gender', { ns: 'labels' }), name: 'gender' },
  { label: translateFunc('createdAt', { ns: 'labels' }), name: 'created_at' },
  actions({
    items: ['edit'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editAdmin.path }
  })
];

export const getArrearageClientsColumns = ({ data, translateFunc }) => [
  { label: translateFunc('client', { count: 1, ns: 'labels' }), name: 'user' },
  { label: translateFunc('dueDate', { ns: 'labels' }), name: 'due_date' },
  membershipDaysInArrears({ data, label: translateFunc('daysInArrears', { ns: 'labels' }) }),
  membershipStatus({ data, label: translateFunc('membership', { ns: 'labels' }) })
];

export const getClientColumns = ({ data, role, includeActions = true, translateFunc }) => {
  const columns = [
    { label: 'Id', name: 'id', options: { display: 'excluded' } },
    userProfile({ data, label: translateFunc('client', { count: 1, ns: 'labels' }) }),
    { label: translateFunc('country', { ns: 'labels' }), name: 'country' },
    { label: translateFunc('gender', { ns: 'labels' }), name: 'gender' }
  ];

  if (role === roles.administrator) {
    columns.push({ label: translateFunc('plan', { ns: 'labels' }), name: 'plan' });
    columns.push(membershipDaysLeft({ data, label: translateFunc('daysLeft', { ns: 'labels' }) }));
    columns.push(membershipStatus({ data, label: translateFunc('membership', { ns: 'labels' }) }));
  }

  if (includeActions) {
    columns.push(
      actions({
        items: role === roles.administrator ? ['view', 'edit'] : ['view'],
        data,
        label: translateFunc('actions', { ns: 'labels' }),
        actionRoutes: { edit: routes.editClient.path, view: routes.viewClient.path }
      })
    );
  }

  return columns;
};

export const getLegalColumns = ({ data, translateFunc }) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: translateFunc('title', { ns: 'labels' }), name: 'title' },
  { label: translateFunc('role', { count: 1, ns: 'labels' }), name: 'role' },
  { label: translateFunc('language', { count: 1, ns: 'labels' }), name: 'language' },
  { label: translateFunc('createdAt', { ns: 'labels' }), name: 'created_at' },
  actions({
    items: ['view', 'edit'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editLegal.path, view: routes.viewLegal.path }
  })
];

export const getMealPlanColumns = ({ data, translateFunc, onDownload }) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  userProfile({ data, label: translateFunc('client', { count: 1, ns: 'labels' }) }),
  { label: translateFunc('createdAt', { ns: 'labels' }), name: 'created_at' },
  downloadableFile({
    data,
    label: translateFunc('pdf', { ns: 'labels' }),
    onDownload
  }),
  actions({
    items: ['edit'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editMealPlan.path }
  })
];

export const getMyTrainerPaymentColumns = ({ data, translateFunc }) => [
  { label: translateFunc('voucherNumber', { ns: 'labels' }), name: 'id' },
  { label: translateFunc('amount', { ns: 'labels' }), name: 'price' },
  {
    label: translateFunc('sessionsCompleted', { ns: 'labels' }),
    name: 'sessions_completed'
  },
  dateRange({ data, label: translateFunc('invoicePeriod', { ns: 'labels' }) }),
  { label: translateFunc('paymentDate', { ns: 'labels' }), name: 'created_at' },
  invoice({ data, label: translateFunc('invoice', { ns: 'labels' }) })
];

export const getNewsColumns = ({ data, translateFunc }) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: translateFunc('title', { ns: 'labels' }), name: 'title' },
  { label: translateFunc('language', { count: 1, ns: 'labels' }), name: 'language' },
  { label: translateFunc('createdAt', { ns: 'labels' }), name: 'created_at' },
  actions({
    items: ['view', 'edit'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editNews.path, view: routes.viewNews.path }
  })
];

export const getPlanColumns = ({ data, translateFunc }) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: translateFunc('name', { ns: 'labels' }), name: 'name' },
  { label: translateFunc('price', { ns: 'labels' }), name: 'price' },
  { label: translateFunc('currency', { ns: 'labels' }), name: 'currency' },
  { label: translateFunc('client', { count: 2, ns: 'labels' }), name: 'clients' },
  actions({
    items: ['edit'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editPlan.path }
  })
];

export const getSessionTypeColumns = ({ data, translateFunc }) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  { label: translateFunc('name', { ns: 'labels' }), name: 'name' },
  priceWithCurrency({ data, label: translateFunc('price', { ns: 'labels' }) }),
  actions({
    items: ['edit'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editSessionType.path }
  })
];

export const getTrainerColumns = ({ data, translateFunc }) => [
  { label: 'Id', name: 'id', options: { display: 'excluded' } },
  userProfile({ data, label: translateFunc('trainer', { count: 1, ns: 'labels' }) }),
  { label: translateFunc('age', { ns: 'labels' }), name: 'age' },
  { label: translateFunc('gender', { ns: 'labels' }), name: 'gender' },
  languages({ data, label: translateFunc('language', { count: 2, ns: 'labels' }) }),
  { label: translateFunc('country', { ns: 'labels' }), name: 'country' },
  actions({
    items: ['edit', 'view'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editTrainer.path, view: routes.viewTrainer.path }
  })
];

export const getTrainerPaymentColumns = ({ data, translateFunc }) => [
  { label: translateFunc('voucherNumber', { ns: 'labels' }), name: 'id' },
  userProfile({ data, label: translateFunc('trainer', { count: 1, ns: 'labels' }) }),
  { label: translateFunc('amount', { ns: 'labels' }), name: 'price' },
  {
    label: translateFunc('sessionsCompleted', { ns: 'labels' }),
    name: 'sessions_completed'
  },
  dateRange({ data, label: translateFunc('invoicePeriod', { ns: 'labels' }) }),
  { label: translateFunc('paymentDate', { ns: 'labels' }), name: 'created_at' },
  actions({
    items: ['edit'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editTrainerPayment.path }
  })
];

export const getTrainerPaymentBasicColumns = ({ data, translateFunc }) => [
  { label: translateFunc('trainer', { count: 1, ns: 'labels' }), name: 'user' },
  { label: translateFunc('sessionsCompleted', { ns: 'labels' }), name: 'sessions_completed' },
  dateRange({ data, label: translateFunc('invoicePeriod', { ns: 'labels' }) }),
  { label: translateFunc('paymentDate', { ns: 'labels' }), name: 'created_at' },
  { label: translateFunc('amount', { ns: 'labels' }), name: 'price' }
];
