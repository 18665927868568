import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PerformanceFields from 'components/performance/PerformanceFields';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import defaultPerformanceFormValidations from 'common/validations/form/defaultPerformance';
import routes from 'common/routes';
import requestStatus from 'common/constants/status';
import { updatePerformanceInitialValues } from 'common/form/initial-values/update';
import getDataToSave from 'common/form/dataToSave';
import { getFieldsToUpdate } from 'common/form/utils';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import { performanceFormDecorators } from 'common/utils/performance';
import { handleClientIdChange } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import performanceSlice from 'store/modules/performance';

const calculator = performanceFormDecorators;
const requiredFields = ['user_id', 'created_at', 'start_date', 'end_date'];

function PerformanceEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('performance.edit', { ns: 'routes' });
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({});
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const clientOptions = useSelector((state) => state.client.data.search);
  const performance = useSelector((state) => state.performance.data.currentPerformance);
  const searchStatus = useSelector((state) => state.client.status.search);
  const status = useSelector((state) => state.performance.status.general);

  useEffect(() => {
    dispatch(performanceSlice.actions.find(id));
  }, []);

  useEffect(() => {
    if (performance && Object.keys(performance).length > 0 && performance.id === id) {
      const performanceData = updatePerformanceInitialValues(performance);
      setInitialValues(performanceData);
    }
  }, [performance]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onClientIdChange = handleClientIdChange(dispatch);

  const onEdit = useCallback(
    (values) => {
      const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
      const dataToSave = getDataToSave(fieldsToUpdate, 'performance');
      dispatch(performanceSlice.actions.update({ id, input: dataToSave }));
    },
    [dispatch, id, initialValues]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        decorators={[calculator]}
        initialValues={initialValues}
        onSubmit={onEdit}
        validate={defaultPerformanceFormValidations}
        render={({ handleSubmit, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <PerformanceFields
              clientOptions={clientOptions}
              onClientIdChange={onClientIdChange}
              requiredFields={requiredFields}
              searchStatus={searchStatus}
            />
            <StyledButtonsContainer>
              <SecondaryButtonLoading
                component={RouterLink}
                disabled={status === requestStatus.loading}
                style={{ marginRight: '32px' }}
                label={t('cancel', { ns: 'buttons' })}
                to={routes.listPerformance.path}
                type="button"
              />
              <PrimaryLoadingButton
                disabled={invalid || pristine || status === requestStatus.loading}
                loading={status === requestStatus.loading}
                label={t('performance.edit', { ns: 'buttons' })}
                type="submit"
              />
            </StyledButtonsContainer>
          </form>
        )}
      />
    </>
  );
}

export default PerformanceEdit;
