import { Typography, styled } from '@mui/material';

const StyledCounterSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(16)
  }
}));

export default StyledCounterSectionTitle;
