import { Alert, styled } from '@mui/material';
import { alertClasses } from '@mui/material/Alert';
import { buttonClasses } from '@mui/material/Button';

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: '10px',
  [theme.breakpoints.down('sm')]: {
    [`& .${alertClasses.icon}`]: {
      fontSize: theme.typography.pxToRem(18)
    },
    [`& .${alertClasses.message}`]: {
      fontSize: theme.typography.pxToRem(12)
    },
    [`& .${alertClasses.action}`]: {
      paddingTop: '2px',
      [`& .${buttonClasses.root}`]: {
        fontSize: theme.typography.pxToRem(12)
      }
    }
  }
}));

export default StyledAlert;
