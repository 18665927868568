import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import MoreProfileLogoutButton from 'components/shared/more-profile/MoreProfileLogoutButton';
import AdminMoreHub from 'components/admin/more/AdminMoreHub';
import StyledMoreProfileSubtitle from 'common/theme/more-profile/StyledMoreProfileSubtitle';
import { userMembershipFields } from 'common/constants/query-fields/user';
import AppBarContext from 'context/AppBarContext';
import LayoutContext from 'context/LayoutContext';
import authSlice from 'store/modules/auth';

function AdminMore() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons']);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const { setCompactLayout, setNoBgColorLayout } = useContext(LayoutContext);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(null);
  }, [setAppBarButton, setAppBarTitle]);

  useEffect(() => {
    setCompactLayout(true);
    setNoBgColorLayout(true);

    return () => {
      setCompactLayout(false);
      setNoBgColorLayout(false);
    };
  }, []);

  useEffect(() => {
    dispatch(authSlice.actions.me(userMembershipFields));
  }, [dispatch]);

  return (
    <Stack spacing={5}>
      <Stack spacing={1}>
        <StyledMoreProfileSubtitle>{t('hub', { ns: 'common' })}</StyledMoreProfileSubtitle>
        <AdminMoreHub />
      </Stack>
      <MoreProfileLogoutButton />
    </Stack>
  );
}

export default AdminMore;
