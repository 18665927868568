import DefaultLayout from 'components/layout/DefaultLayout';
import SessionCalendar from 'components/session/calendar/SessionCalendar';
import SessionEdit from 'components/session/SessionEdit';
import SessionList from 'components/session/SessionList';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const sessionRoutes = [
  // Shared routes

  {
    key: 'calendar',
    exact: true,
    path: routes.calendar.path,
    component: SessionCalendar,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.trainer, roles.client]
  },
  {
    key: 'session-edit',
    exact: true,
    path: routes.editSession.path,
    component: SessionEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'session-list',
    exact: true,
    path: routes.listSession.path,
    component: SessionList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.trainer]
  },

  // Client only routes

  {
    key: 'session-history',
    exact: true,
    path: routes.clientSessions.path,
    component: SessionList,
    layout: DefaultLayout,
    allowedRoles: [roles.client]
  }
];

export default sessionRoutes;
