import styledComponent from 'styled-components';
import palette from 'common/theme/palette';

const StyledPerformanceHistoryChartTotal = styledComponent.text`
  fill: ${palette.white.main};
  font-size: 0.625rem;
  @media (min-width: 1200px) {
    font-size: 0.75rem;
  }
`;

export default StyledPerformanceHistoryChartTotal;
