import React, { useCallback, useMemo } from 'react';
import PropType from 'prop-types';
import { lightFormat, parseISO } from 'date-fns';
import { ResponsiveBar } from '@nivo/bar';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PerformanceHistoryBar from 'components/shared/charts/performance/history/PerformanceHistoryBar';
import PerformanceHistoryChartHeader from 'components/shared/charts/performance/history/PerformanceHistoryChartHeader';
import StyledPerformanceHistoryChartContainer from 'common/theme/charts/performance-history/StyledPerformanceHistoryChartContainer';
import { getDefs, getFill } from 'common/utils/charts';

function getFontSize(isDesktop, isMediumScreen) {
  if (isDesktop) {
    return '14px';
  }

  if (isMediumScreen) {
    return '12px';
  }

  return '10px';
}

function PerformanceHistoryChart({ chartData, type }) {
  const gradient = { from: '#C08300', id: 'performance', to: '#F8B231' };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const fontSize = useMemo(() => getFontSize(isDesktop, isTablet), [isDesktop, isTablet]);
  const fill = useMemo(() => getFill({ id: gradient.id }), [gradient]);

  const defs = useMemo(
    () => getDefs({ id: gradient.id, from: gradient.from, to: gradient.to }),
    [gradient]
  );

  const chartTheme = useMemo(
    () => ({
      axis: {
        ticks: {
          text: {
            fill: '#FFFFFF',
            fontSize,
            fontWeight: 600
          }
        }
      }
    }),
    [fontSize]
  );

  const layers = useMemo(
    () => ['grid', 'axes', PerformanceHistoryBar, 'markers', 'legends', 'annotations'],
    [PerformanceHistoryBar]
  );

  const formatDate = useCallback((date) => {
    const parsedDate = parseISO(date);
    return lightFormat(parsedDate, 'MM/yy');
  }, []);

  const axisBottomConfig = useMemo(
    () => ({
      format: formatDate,
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: ''
    }),
    [formatDate]
  );

  return (
    <div>
      <PerformanceHistoryChartHeader testType={type} />
      <StyledPerformanceHistoryChartContainer>
        <ResponsiveBar
          animate
          axisBottom={axisBottomConfig}
          axisLeft={null}
          axisRight={null}
          axisTop={null}
          data={chartData}
          defs={defs}
          enableGridY={false}
          fill={fill}
          keys={['totalRaw']}
          indexBy="date"
          isInteractive={false}
          label={null}
          layers={layers}
          margin={{ top: isMedium ? 50 : 40, right: 0, bottom: 22, left: 0 }}
          motionDamping={15}
          motionStiffness={90}
          padding={0.1}
          theme={chartTheme}
        />
      </StyledPerformanceHistoryChartContainer>
    </div>
  );
}

PerformanceHistoryChart.propTypes = {
  chartData: PropType.arrayOf(
    PropType.shape({
      date: PropType.string.isRequired,
      place: PropType.number.isRequired,
      total: PropType.string.isRequired,
      totalRaw: PropType.number.isRequired
    })
  ).isRequired,
  type: PropType.string.isRequired
};

export default PerformanceHistoryChart;
