import { Typography, styled } from '@mui/material';

const StyledLargeSessionCardTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.spacing(0.5)
  }
}));

export default StyledLargeSessionCardTitle;
