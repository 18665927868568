import { Link, styled } from '@mui/material';

const StyledNewsCardViewMoreLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.typography.pxToRem(12)
  }
}));

export default StyledNewsCardViewMoreLink;
