import React from 'react';
import { Box, styled } from '@mui/material';

const StyledCounterContainer = styled(({ double, ...otherProps }) => <Box {...otherProps} />)(
  ({ double, theme }) => ({
    backgroundColor: theme.palette.gray2.main,
    borderRadius: '4px',
    height: '100%',
    overflow: 'hidden',
    padding: double
      ? `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)}`
      : theme.spacing(1),
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '5px'
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3)
    }
  })
);

export default StyledCounterContainer;
