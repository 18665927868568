import { Chip, styled } from '@mui/material';
import defaultChipStyles from 'common/theme/chips/defaultChipStyles';

const StyledProgressGalleryChip = styled(Chip)(({ theme }) => ({
  ...defaultChipStyles,
  bottom: theme.spacing(1),
  display: 'flex',
  height: theme.spacing(3),
  position: 'absolute',
  width: 'calc(100% - 8px)',
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(10)
  },
  [theme.breakpoints.up('md')]: {
    bottom: 'auto',
    display: 'inline-flex',
    height: theme.spacing(4),
    right: theme.spacing(1),
    top: theme.spacing(2),
    width: 'auto'
  },
  [theme.breakpoints.up('lg')]: {
    right: theme.spacing(2)
  }
}));

export default StyledProgressGalleryChip;
