import { takeLatest, put } from 'redux-saga/effects';
import adminSlice from 'store/modules/admin';
import uiSlice from 'store/modules/ui';
import user from 'api/graphql/user';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import i18n from 'i18n';

/**
 * Create admin saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'admin-create-notification-success',
      variant: 'success',
      message: i18n.t('administrator.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield user.create(payload);
    yield put(adminSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(adminSlice.actions.createError(message));
  }
}

/**
 * Find admin saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const { fields, id } = action.payload;
    const response = yield user.find({ fields, id });

    const {
      data: { user: currentAdmin }
    } = response;

    yield put(adminSlice.actions.findSuccess(currentAdmin));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(adminSlice.actions.findError(message));
  }
}

/**
 * List admins saga
 *
 * @param {*} action
 */
function* list(action) {
  try {
    const {
      payload: { filters, pagination }
    } = action;
    const response = yield user.admins({ filters, pagination });
    const {
      data: {
        users: { data, paginatorInfo }
      }
    } = response;

    yield put(adminSlice.actions.listSuccess(data));
    yield put(
      adminSlice.actions.updatePaginatorInfo({
        hasMorePages: paginatorInfo.hasMorePages,
        lastPage: paginatorInfo.lastPage,
        total: paginatorInfo.total
      })
    );
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(adminSlice.actions.listAdminsError(message));
  }
}

/**
 * Remove admins saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deleteUsers: removedAdmins }
    } = yield user.remove(action.payload);

    const snack = createSnack({
      key: 'remove-admins-notification-success',
      variant: 'success',
      message: i18n.t('administrator.delete', { count: removedAdmins.length, ns: 'notifications' })
    });

    yield put(adminSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(adminSlice.actions.removeError(message));
  }
}

/**
 * Restore admins saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restoreUsers: restoredAdmins }
    } = yield user.restore(action.payload);

    const snack = createSnack({
      key: 'restore-admins-notification-success',
      variant: 'success',
      message: i18n.t('administrator.restore', {
        count: restoredAdmins.length,
        ns: 'notifications'
      })
    });

    yield put(adminSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(adminSlice.actions.restoreError(message));
  }
}

/**
 * Search admins saga
 *
 * @param {*} action
 */
function* search(action) {
  try {
    const { input, fields } = action.payload;
    const response = yield user.search({ fields, input });

    const {
      data: { searchUser: admins }
    } = response;

    const options = admins.map((option) => ({ value: option.id, label: option.full_name }));

    yield put(adminSlice.actions.searchSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(adminSlice.actions.searchError(message));
  }
}

/**
 * Update admin saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-admin-notification-success',
      variant: 'success',
      message: i18n.t('administrator.update', { ns: 'notifications' })
    });

    yield user.update(action.payload);

    yield put(adminSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(adminSlice.actions.updateError(message));
  }
}

/**
 * Watch admin
 *
 * @export
 */
export default function* watchAdmin() {
  yield takeLatest(adminSlice.actions.create, create);
  yield takeLatest(adminSlice.actions.find, find);
  yield takeLatest(adminSlice.actions.list, list);
  yield takeLatest(adminSlice.actions.remove, remove);
  yield takeLatest(adminSlice.actions.restore, restore);
  yield takeLatest(adminSlice.actions.search, search);
  yield takeLatest(adminSlice.actions.update, update);
}
