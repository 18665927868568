import { useState } from 'react';

const usePdfControls = (initialPage = 1, initialScale = 1.5) => {
  const [pageNumber, setPageNumber] = useState(initialPage);
  const [scale, setScale] = useState(initialScale);

  const nextPage = () => setPageNumber((prev) => prev + 1);
  const prevPage = () => setPageNumber((prev) => prev - 1);
  const zoomIn = () => setScale((prev) => prev + 0.1);
  const zoomOut = () => setScale((prev) => prev - 0.1);

  return { nextPage, pageNumber, prevPage, scale, setPageNumber, setScale, zoomIn, zoomOut };
};

export default usePdfControls;
