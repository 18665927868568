import styledComponent from 'styled-components';

const StyledPerformanceHistoryChartTitle = styledComponent.p`
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 1.125rem;
  }
`;

export default StyledPerformanceHistoryChartTitle;
