import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, TableRow } from '@mui/material';
import StyledTableRowCell from 'common/theme/table-rows/StyledTableRowCell';
import StyledTableRowLabel from 'common/theme/table-rows/StyledTableRowLabel';
import StyledPaymentRowText from 'common/theme/table-rows/payment/StyledPaymentRowText';
import { cleanLabel } from 'common/utils/format';
import { isAdmin } from 'common/utils/user';

function PaymentExpandableRow({ data, meta, role, tableData }) {
  const { t } = useTranslation(['buttons', 'labels']);

  const colSpan = data.length + 1;
  const rowData = tableData[meta.rowIndex];
  const isAdminUser = isAdmin(role);

  return (
    <TableRow>
      <StyledTableRowCell colSpan={colSpan}>
        {rowData.sessions_paid && (
          <StyledPaymentRowText variant="body2">
            <StyledTableRowLabel component="span" variant="body2">
              {t('sessionsPaid', { ns: 'labels' })}:
            </StyledTableRowLabel>{' '}
            {rowData.sessions_paid}
          </StyledPaymentRowText>
        )}
        {rowData.currency && (
          <StyledPaymentRowText variant="body2">
            <StyledTableRowLabel component="span" variant="body2">
              {t('currency', { ns: 'labels' })}:
            </StyledTableRowLabel>{' '}
            {rowData.currency}
          </StyledPaymentRowText>
        )}
        {rowData.payment_method && (
          <StyledPaymentRowText variant="body2">
            <StyledTableRowLabel component="span" variant="body2">
              {t('paymentMethod', { ns: 'labels' })}:
            </StyledTableRowLabel>{' '}
            {cleanLabel(rowData.payment_method)}
          </StyledPaymentRowText>
        )}
        {rowData?.invoice_url && (
          <StyledPaymentRowText variant="body2">
            <StyledTableRowLabel component="span" variant="body2">
              {t('invoice', { ns: 'labels' })}:
            </StyledTableRowLabel>{' '}
            <Link
              href={rowData.invoice_url}
              target="_blank"
              rel="noopener noreferrer"
              color="secondary">
              {t('payment.seeInvoice', { ns: 'buttons' })}
            </Link>
          </StyledPaymentRowText>
        )}
        {isAdminUser && (
          <StyledPaymentRowText variant="body2">
            <StyledTableRowLabel component="span" variant="body2">
              {t('observations', { ns: 'labels' })}:
            </StyledTableRowLabel>{' '}
            {rowData?.observations || t('none', { context: 'female', ns: 'common' })}
          </StyledPaymentRowText>
        )}
      </StyledTableRowCell>
    </TableRow>
  );
}

PaymentExpandableRow.propTypes = {
  data: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  tableData: PropTypes.array.isRequired
};

export default PaymentExpandableRow;
