import { Link, styled } from '@mui/material';

const StyledNotificationStretchedLink = styled(Link)(() => ({
  '&::after': {
    bottom: 0,
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

export default StyledNotificationStretchedLink;
