import React from 'react';
import { LoadingButton } from '@mui/lab';
import { buttonClasses } from '@mui/material/Button';
import { styled } from '@mui/material';

const StyledSecondaryLoadingButton = styled(({ minWidth, ...otherProps }) => (
  <LoadingButton {...otherProps} />
))(({ minWidth, theme }) => ({
  background: 'transparent',
  borderColor: theme.palette.common.white,
  color: theme.palette.common.white,
  fontSize: theme.typography.pxToRem(14),
  height: '40px',
  minWidth: minWidth ? '170px' : 'auto',
  padding: '0 20px',
  textTransform: 'none',
  '&:hover': {
    background: 'transparent',
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main
  },
  [theme.breakpoints.up('lg')]: {
    height: '47px',
    minWidth: minWidth ? '191px' : 'auto',
    padding: '0 25px'
  },
  [`&.${buttonClasses.disabled}`]: {
    borderColor: theme.palette.grey[600]
  }
}));

export default StyledSecondaryLoadingButton;
