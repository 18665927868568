import styledComponent from 'styled-components';

const StyledNewsCardImage = styledComponent.img`
  border-radius: 4px;
  height: 50px;
  @media (min-width: 600px) {
    height: 100px;
  }
  @media (min-width: 1200px) {
    border-radius: 5px;
    height: 150px;
  }
`;

export default StyledNewsCardImage;
