import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useMatch } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import StyledMainMenuButton from 'common/theme/menu/StyledMainMenuButton';
import StyledMainMenuLink from 'common/theme/menu/StyledMainMenuLink';
import StyledMainMenuListItem from 'common/theme/menu/StyledMainMenuListItem';
import StyledMainMenuListItemIcon from 'common/theme/menu/StyledMainMenuListItemIcon';
import StyledMainMenuListItemText from 'common/theme/menu/StyledMainMenuListItemText';

function MenuItem({ icon, iconOnly, itemKey, label, onClick, path }) {
  const match = useMatch(`${path}/*`);
  const isActive = match !== null;

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <StyledMainMenuListItem key={itemKey} onClick={handleClick} disablePadding>
      <StyledMainMenuLink component={NavLink} to={path}>
        <StyledMainMenuButton selected={isActive}>
          {iconOnly ? (
            <Tooltip title={label} placement="right">
              <StyledMainMenuListItemIcon>{icon}</StyledMainMenuListItemIcon>
            </Tooltip>
          ) : (
            <>
              <StyledMainMenuListItemIcon>{icon}</StyledMainMenuListItemIcon>
              <StyledMainMenuListItemText primary={label} />
            </>
          )}
        </StyledMainMenuButton>
      </StyledMainMenuLink>
    </StyledMainMenuListItem>
  );
}

MenuItem.defaultProps = {
  iconOnly: false,
  onClick: () => {}
};

MenuItem.propTypes = {
  icon: PropTypes.object.isRequired,
  iconOnly: PropTypes.bool,
  itemKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string.isRequired
};

export default MenuItem;
