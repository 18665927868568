import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledInputLabel, StyledMenuItem, StyledSelect } from 'common/theme/fields/StyledSelect';

function SelectFieldAdapter({ input, id, label, options, ...rest }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onChange = (event) => {
    input.onChange(event.target.value);
  };

  return (
    <FormControl size={size} {...rest}>
      <StyledInputLabel id={id}>{label}</StyledInputLabel>
      <StyledSelect
        {...rest}
        label={label}
        labelId={id}
        id={id}
        value={input.value}
        onChange={onChange}>
        {options.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}

SelectFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default SelectFieldAdapter;
