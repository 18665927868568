import styledComponent from 'styled-components';

const StyledPdfViewerControls = styledComponent.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  @media (max-width: 1200px) {
    margin-bottom: 1rem;
  }

  & > div {
    display: flex;
    column-gap: 1rem;
  }
`;

export default StyledPdfViewerControls;
