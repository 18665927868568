import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Pagination, useMediaQuery } from '@mui/material';
import StyledProgressGalleryPagination from 'common/theme/galleries/progress/StyledProgressGalleryPagination';

function ProgressGalleryPagination({ onChange, page, totalPages }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <StyledProgressGalleryPagination>
      <Pagination
        color="secondary"
        count={totalPages}
        page={page}
        onChange={onChange}
        size={isMedium ? 'medium' : 'small'}
      />
    </StyledProgressGalleryPagination>
  );
}

ProgressGalleryPagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default ProgressGalleryPagination;
