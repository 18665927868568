import React from 'react';
import { Avatar, styled } from '@mui/material';

const StyledUserAvatarImage = styled(({ border, size, theme, ...otherProps }) => (
  <Avatar {...otherProps} />
))(({ border, size, theme }) => ({
  border: border ? `2px solid ${theme.palette.secondary.main}` : 'none',
  height: size,
  width: size
}));

export default StyledUserAvatarImage;
