import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import StyledSecondaryLoadingButton from 'common/theme/button/StyledSecondaryLoadingButton';

function SecondaryButtonLoading({ label, loading, minWidth, onClick, ...rest }) {
  return (
    <StyledSecondaryLoadingButton
      {...rest}
      size="small"
      onClick={onClick}
      loading={loading}
      loadingIndicator={<CircularProgress color="secondary" size={16} />}
      variant="outlined">
      {label}
    </StyledSecondaryLoadingButton>
  );
}

SecondaryButtonLoading.defaultProps = {
  loading: false,
  minWidth: true,
  onClick: () => {}
};

SecondaryButtonLoading.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  minWidth: PropTypes.bool,
  onClick: PropTypes.func
};

export default SecondaryButtonLoading;
