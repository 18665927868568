import React from 'react';
import { Box, styled } from '@mui/material';

const StyledDefaultLayoutMain = styled(({ drawerWidth, ...otherProps }) => <Box {...otherProps} />)(
  ({ drawerWidth, theme }) => ({
    backgroundColor: theme.palette.gray2.main,
    flexGrow: 1,
    maxWidth: '100%',
    minHeight: '100vh',
    paddingBottom: theme.spacing(7),
    transition: `width ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms`,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5),
      width: `calc(100% - ${drawerWidth}px)`
    }
  })
);

export default StyledDefaultLayoutMain;
