import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { homePathByRole } from 'common/constants/general';
import routes from 'common/routes';

function Gate({ allowedRoles, children }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!user) {
      navigate(routes.login.path);
    } else if (!allowedRoles.includes(user.role)) {
      const homePath = homePathByRole[user.role] || homePathByRole.default;
      navigate(homePath);
    }
  }, [user, navigate, allowedRoles]);

  return user && children;
}

Gate.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired
};

export default Gate;
