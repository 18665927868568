import React from 'react';
import { Box, styled } from '@mui/material';

const StyledDefaultLayoutSectionContainer = styled(({ compact, noBgColor, ...otherProps }) => (
  <Box {...otherProps} />
))(({ compact, noBgColor, theme }) => ({
  backgroundColor: noBgColor ? 'transparent' : theme.palette.gray0.main,
  borderRadius: '8px',
  padding: compact ? 0 : theme.spacing(1.5, 2),
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: compact ? 0 : `${theme.spacing(4)} ${theme.spacing(3)}`
  }
}));

export default StyledDefaultLayoutSectionContainer;
