import { Button, styled } from '@mui/material';
import { svgIconClasses } from '@mui/material/SvgIcon';

const StyledBackButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginBottom: '10px',
  textTransform: 'capitalize',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(18),
    marginBottom: '16px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(27),
    marginBottom: '30px'
  },
  [`& .${svgIconClasses.root}`]: {
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(20)
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(27)
    }
  }
}));

export default StyledBackButton;
