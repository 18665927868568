import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import TrainerSelectFieldAdapter from 'components/shared/field-adapters/TrainerSelectFieldAdapter';

function SessionFieldsTrainerSelection({ values }) {
  const { t } = useTranslation(['labels', 'sessions']);

  const trainers = useSelector((state) => state.trainer.data.trainers);
  const trainerStatus = useSelector((state) => state.trainer.status.general);

  return (
    <Field
      id="trainer_id"
      name="trainer_id"
      component={TrainerSelectFieldAdapter}
      label={t('trainer', { count: 1, ns: 'labels' })}
      status={trainerStatus}
      trainers={trainers}
      emptyMessage={t('trainerNotAvailable', { ns: 'sessions' })}
      disabled={!values.client_id || !values.session_type_id}
    />
  );
}

SessionFieldsTrainerSelection.propTypes = {
  values: PropTypes.object.isRequired
};

export default SessionFieldsTrainerSelection;
