import DefaultLayout from 'components/layout/DefaultLayout';
import ProgressCreate from 'components/progress/ProgressCreate';
import ProgressEdit from 'components/progress/ProgressEdit';
import ProgressHistory from 'components/progress/ProgressHistory';
import ProgressList from 'components/progress/ProgressList';
import ProgressView from 'components/progress/ProgressView';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const progressRoutes = [
  // Admin routes

  {
    key: 'progress-create',
    exact: true,
    path: routes.createProgress.path,
    component: ProgressCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'progress-edit',
    exact: true,
    path: routes.editProgress.path,
    component: ProgressEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'progress-list',
    exact: true,
    path: routes.listProgress.path,
    component: ProgressList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'progress-view',
    exact: true,
    path: routes.viewProgress.path,
    component: ProgressView,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },

  // Client routes

  {
    key: 'progress-history',
    exact: true,
    path: routes.clientProgress.path,
    component: ProgressHistory,
    layout: DefaultLayout,
    allowedRoles: [roles.client]
  }
];

export default progressRoutes;
