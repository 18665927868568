import styledComponent from 'styled-components';

const StyledProgressGalleryThumbnailImage = styledComponent.img`
  aspect-ratio: 1 / 1 auto;
  border-radius: 4px;
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  @media (min-width: 900px) {
    border-radius: 5px;
  }
`;

export default StyledProgressGalleryThumbnailImage;
