import { styled, Typography } from '@mui/material';

const StyledOverviewProfileDetailLabel = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  marginLeft: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    flexShrink: 0
  }
}));

export default StyledOverviewProfileDetailLabel;
