import user from 'api/graphql/user';

/**
 * Creates and retrieves a temporary URL for a specific user document.
 * This function sends a request to obtain a temporary URL for a user's document
 * (such as a bank account certificate or identification document) based on the provided user ID and document type.
 * The URL is valid for a limited time.
 *
 * @param  {number|string}  id            The unique identifier of the user.
 * @param  {string}         documentType  The type of the document for which the temporary URL is to be generated (e.g., 'bank_account_certificate', 'identification_document').
 *
 * @returns  {Promise<string>}  A promise that resolves to the temporary URL of the requested document.
 */
const createTemporaryUserDocumentUrl = async (id, documentType) => {
  const response = await user.createTemporaryUserDocumentUrl(id, documentType);
  const {
    data: { createTemporaryUserDocumentUrl: temporaryUrl }
  } = response;

  return temporaryUrl;
};

export default createTemporaryUserDocumentUrl;
