export const trainerCreatePaymentSearchFields = `
  id
  full_name
`;

export const trainerFindFields = `
  id
  birthdate
  country
  email
  first_name
  gender
  languages
  last_name
  observations
  phone
  settings {
    language
  }
  specialties {
    id
  }
`;

export const trainerListFields = `
  id
  age
  country
  first_name
  full_name
  gender
  languages
  last_name
  photo
  specialties {
    name
  }
`;

export const trainerViewFields = `
  id
  academic_formation
  age
  bank
  bank_account_certificate
  bank_account_number
  bank_account_type
  contact_name
  contact_phone
  country
  email
  eps
  experience
  first_name
  full_name
  gender
  identification_document
  identification_number
  languages
  last_name
  observations
  online_meeting_url
  phone
  photo
  profile_complete
  specialties {
    name
  }
`;
