import React from 'react';
import { Skeleton, Stack } from '@mui/material';

function ClientPlanSkeleton() {
  const skeletonCount = 4;

  return (
    <Stack sx={{ marginBottom: (theme) => theme.spacing(5) }}>
      {Array.from({ length: skeletonCount }, (_, index) => (
        <Skeleton key={index} height={56} width="180" />
      ))}
    </Stack>
  );
}

export default ClientPlanSkeleton;
