import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import StyledOverviewProfileDivider from 'common/theme/overview-profile/StyledOverviewProfileDivider';
import StyledOverviewProfileObservations from 'common/theme/overview-profile/StyledOverviewProfileObservations';
import StyledOverviewProfileSubtitle from 'common/theme/overview-profile/StyledOverviewProfileSubtitle';

function ClientObservations({ client, loading }) {
  const { t } = useTranslation(['labels']);

  return (
    <>
      <StyledOverviewProfileSubtitle component="h4">
        {t('observations', { ns: 'labels' })}
      </StyledOverviewProfileSubtitle>
      <StyledOverviewProfileDivider />
      {loading ? (
        <Skeleton height={145} />
      ) : (
        <StyledOverviewProfileObservations>
          {client?.observations}
        </StyledOverviewProfileObservations>
      )}
    </>
  );
}

ClientObservations.defaultProps = {
  client: null
};

ClientObservations.propTypes = {
  client: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default ClientObservations;
