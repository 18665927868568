import { Typography, styled } from '@mui/material';

const StyledUserProfileColumnName = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  marginLeft: '12px',
  textAlign: 'left',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(14)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(16)
  }
}));

export default StyledUserProfileColumnName;
