/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { register } from 'swiper/element/bundle';

register();

function SwiperCarousel({ autoplay, items, renderItem, slidesPerView }) {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;

    const params = {
      autoplay,
      loop: true,
      pagination: {
        clickable: true
      },
      slidesPerView,
      spaceBetween: 30,
      injectStyles: [
        `
          .swiper-pagination-bullets {
            margin-top: 16px;
            position: relative;
          }
          .swiper-pagination-bullet-active {
            background: #F9BD4E;
          }
        `
      ]
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, []);

  return (
    <swiper-container ref={swiperRef} init="false">
      {items.map((item) => (
        <swiper-slide key={item.id}>{renderItem(item)}</swiper-slide>
      ))}
    </swiper-container>
  );
}

SwiperCarousel.defaultProps = {
  autoplay: true,
  slidesPerView: 1
};

SwiperCarousel.propTypes = {
  autoplay: PropTypes.bool,
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  slidesPerView: PropTypes.number
};

export default SwiperCarousel;
