import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardBlock from 'components/shared/blocks/DashboardBlock';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import SessionsTable from 'components/shared/tables/SessionsTable';

function LatestSessionsTable({ emptyMessage, itemsPerPage }) {
  const { t } = useTranslation(['sessions']);

  const role = useSelector((state) => state.auth.user.role);
  const sessions = useSelector((state) => state.session.data.list);
  const sessionStatus = useSelector((state) => state.session.status.general);

  const isValid = sessions.length > 0;

  return (
    <DashboardBlock title={t('latestSessions', { ns: 'sessions' })}>
      <LoadingBlock
        emptyBlockProps={{
          message: emptyMessage
        }}
        isValid={isValid}
        skeletonProps={{
          rows: itemsPerPage
        }}
        status={sessionStatus}
        type="table">
        <SessionsTable sessions={sessions} role={role} rowsPerPage={itemsPerPage} />
      </LoadingBlock>
    </DashboardBlock>
  );
}

LatestSessionsTable.defaultProps = {
  itemsPerPage: 5
};

LatestSessionsTable.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number
};

export default LatestSessionsTable;
