import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import requestStatus from 'common/constants/status';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import StyledProfileButtonContainer from 'common/theme/profile/StyledProfileButtonContainer';

function ClientProfileButtons({ invalid, pristine, status }) {
  const { t } = useTranslation(['buttons']);

  return (
    <StyledProfileButtonContainer>
      <StyledButtonsContainer>
        <PrimaryLoadingButton
          disabled={invalid || pristine || status === requestStatus.loading}
          loading={status === requestStatus.loading}
          label={t('save', { ns: 'buttons' })}
          type="submit"
        />
      </StyledButtonsContainer>
    </StyledProfileButtonContainer>
  );
}

ClientProfileButtons.propTypes = {
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired
};

export default ClientProfileButtons;
