import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _camelCase from 'lodash/camelCase';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import TopRankedChartContainer from 'components/shared/charts/performance/top-ranked/TopRankedChartContainer';
import TopRankedControls from 'components/shared/charts/performance/top-ranked/TopRankedControls';
import TopRankedTitle from 'components/shared/charts/performance/top-ranked/TopRankedTitle';
import { gender, performanceTest } from 'common/constants/options';

function TopRankedPerformance({
  data,
  onPerformanceTestChange,
  onTimeRangeChange,
  selectedPerformanceTest,
  selectedTimeRange,
  status
}) {
  const { t } = useTranslation(['labels', 'performances']);
  const { male: maleData, female: femaleData } = data;
  const testName = t(`${_camelCase(selectedPerformanceTest)}.label`, { ns: 'performances' });
  const testUnit = t(`${_camelCase(selectedPerformanceTest)}.unit`, { ns: 'performances' });

  return (
    <Box>
      <TopRankedTitle onChange={onPerformanceTestChange}>
        <TopRankedControls
          onPerformanceTestChange={onPerformanceTestChange}
          onTimeRangeChange={onTimeRangeChange}
          selectedTimeRange={selectedTimeRange}
          selectedPerformanceTest={selectedPerformanceTest}
          status={status}
        />
      </TopRankedTitle>
      <Typography
        align="center"
        sx={{ fontSize: (theme) => theme.typography.pxToRem(18), fontWeight: 600, marginTop: 4 }}>
        {testName}
        {selectedPerformanceTest !== performanceTest.plank &&
          ` - ${t(`${_camelCase(selectedPerformanceTest)}.duration`, { ns: 'performances' })}`}
      </Typography>
      <Typography align="center" color="secondary" sx={{ fontWeight: 600 }}>
        {testUnit}
      </Typography>
      <Typography
        align="center"
        component="p"
        variant="subtitle2"
        sx={{ marginBottom: 4, marginTop: 1 }}>
        {t('clickOnBar', { ns: 'performances' })}
      </Typography>
      <Grid container spacing={4} sx={{ marginBottom: 0, marginTop: (theme) => theme.spacing(4) }}>
        <TopRankedChartContainer
          data={maleData}
          gender={gender.male}
          gradient={{ from: '#1B23BF', id: 'male', to: '#28439D' }}
          status={status}
          test={selectedPerformanceTest}
        />
        <TopRankedChartContainer
          data={femaleData}
          gender={gender.female}
          gradient={{ from: '#C08300', id: 'female', to: '#F8B231' }}
          status={status}
          test={selectedPerformanceTest}
        />
      </Grid>
    </Box>
  );
}

const chartPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    place: PropTypes.number.isRequired,
    total: PropTypes.string.isRequired,
    totalRaw: PropTypes.number.isRequired,
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      photo: PropTypes.string
    }).isRequired
  })
).isRequired;

TopRankedPerformance.propTypes = {
  data: PropTypes.shape({
    male: chartPropTypes,
    female: chartPropTypes
  }).isRequired,
  onPerformanceTestChange: PropTypes.func.isRequired,
  onTimeRangeChange: PropTypes.func.isRequired,
  selectedPerformanceTest: PropTypes.string.isRequired,
  selectedTimeRange: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default TopRankedPerformance;
