import styledComponent from 'styled-components';

const StyledLatestPerformanceCardContent = styledComponent.div`
  column-gap: 1rem;
  display: flex;
  & > *:nth-child(2) {
    flex-grow: 1;
  };
  & > *:nth-child(1), & > *:nth-child(3) {
    flex-shrink: 0;
  }
`;

export default StyledLatestPerformanceCardContent;
