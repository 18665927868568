import { Chip, styled } from '@mui/material';

const StyledTrainerSelectChip = styled(Chip)(({ theme }) => ({
  borderRadius: '28px',
  height: '44px',
  justifyContent: 'flex-start',
  padding: `0 ${theme.spacing(1)}`,
  width: '100%'
}));

export default StyledTrainerSelectChip;
