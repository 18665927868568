import { addHours, differenceInHours, isEqual, isPast } from 'date-fns';
import createDecorator from 'final-form-calculate';
import { roles, sessionStatus } from 'common/constants/options';
import { isClient, isTrainer } from 'common/utils/user';
import authSlice from 'store/modules/auth';
import sessionSlice from 'store/modules/session';

/**
 * Constructs an object to represent a session event.
 *
 * @param  {Object}   values      An object containing the values needed to build the session event.
 * @param  {Boolean}  fromServer  A flag indicating if the data is from the server.
 *
 * @returns  {Object}  An object representing the session event.
 */
export const buildSessionEvent = (values, fromServer = false) => ({
  id: Number(values.id),
  allDay: false,
  start: values.start_date,
  end: values.end_date,
  backgroundColor: fromServer ? '#12633266' : '#0a65ff66',
  classNames: ['fc-session'],
  extendedProps: {
    client: {
      first_name: values.client_id.first_name,
      last_name: values.client_id.last_name,
      full_name: values.client_id.full_name,
      photo: values.client_id.photo
    },
    language: values.language,
    observations: values.observations,
    session_type: values.session_type_id.name,
    status: values.status,
    stored: fromServer,
    trainer: {
      first_name: values.trainer_id.first_name,
      last_name: values.trainer_id.last_name,
      full_name: values.trainer_id.full_name,
      online_meeting_url: values.trainer_id.online_meeting_url,
      photo: values.trainer_id.photo
    }
  }
});

/**
 * Builds session filters based on the user's role.
 *
 * @param  {string}  role     The role of the user (e.g. "client", "trainer").
 * @param  {Number}  userId   The ID of the user.
 * @param  {Object}  filters  The current set of filters.
 *
 * @returns  {Object}  The updated set of filters.
 */
export const buildSessionFiltersPerRole = (role, userId, filters) => {
  const isClientRole = isClient(role);
  const isTrainerRole = isTrainer(role);

  if (isClientRole || isTrainerRole) {
    return {
      ...filters,
      [isClientRole ? 'client_id' : 'trainer_id']: userId
    };
  }

  return filters;
};

/**
 * Checks if a client user can create sessions based on their role and the number of available sessions.
 *
 * @param  {string}  role               The role of the user.
 * @param  {number}  availableSessions  The number of available sessions.
 *
 * @returns  {boolean}  True if the user is a client and has available sessions; otherwise, false.
 */
export const canClientCreateSessions = (role, availableSessions) =>
  isClient(role) && availableSessions > 0;

/**
 * Determines if a client can attend a session based on its end date and status.
 *
 * A client can go to a session if the session is scheduled and the end date is
 * not in the past. This function checks if the session's end date is either equal to
 * or after the current date and time (i.e., it's not in the past).
 *
 * @param  {Date|string}  endDate  The end date of the session. Can be a Date object or a string representation of a date.
 * @param  {string}       status   The current status of the session, e.g., 'scheduled'.
 * @returns  {boolean}  Returns true if the session is scheduled and the end date is not past the current date and time, otherwise false.
 */
export const canClientGoToSession = (endDate, status) => {
  const sessionEndDate = typeof endDate === 'object' ? endDate : new Date(endDate);
  return status === sessionStatus.scheduled && !isPast(sessionEndDate);
};

/**
 * Determines if a session can be deleted based on its start date and current status.
 *
 * A session can be deleted if it is scheduled and at least 8 hours away from the current time.
 *
 * @param  {Date|string}  startDate  The start date of the session. Can be a Date object or a date string.
 * @param  {string}       status     The current status of the session.
 *
 * @returns  {boolean}  Returns true if the session is scheduled and at least 8 hours away, otherwise false.
 */
export const canSessionBeDeleted = (startDate, status) => {
  const now = new Date();
  const sessionStartDate = typeof startDate === 'object' ? startDate : new Date(startDate);
  const hoursDifference = differenceInHours(sessionStartDate, now);

  return status === sessionStatus.scheduled && hoursDifference >= 8;
};

/**
 * Updates the value of the 'to' field in response to changes in the 'from' field in a form.
 *
 * @param  {Object}  params          An object specifying the field to listen for changes (field), and the updates to make in response (updates).
 * @param  {string}  params.field    The name of the field to watch for changes.
 * @param  {Object}  params.updates  An object where keys are the names of the fields to update, and values are update functions.
 *
 * @returns {Function} A decorated form with automatically updated 'to' field.
 */
export const formDecorator = createDecorator({
  field: 'from',
  updates: {
    to: (fromValue, allValues) => {
      const newToValue = addHours(fromValue, 1);
      const currentToValue = allValues.to;

      if (!isEqual(newToValue, currentToValue)) {
        return newToValue;
      }

      return currentToValue;
    }
  }
});

/**
 * Translates the session status into a human readable string and assigns a color based on the status.
 *
 * @param  {string}    status         The status of the session.
 * @param  {Function}  translateFunc  The function to translate status keys.
 *
 * @returns  {Object}  Returns an object with label (translated status) and color.
 */
export const getTranslatedSessionStatus = (status, translateFunc) => {
  const statusObject = {
    [sessionStatus.scheduled]: {
      label: translateFunc('sessionStatus.scheduled', { ns: 'options' }),
      color: 'scheduled'
    },
    [sessionStatus.finished]: {
      label: translateFunc('sessionStatus.finished', { ns: 'options' }),
      color: 'finished'
    },
    [sessionStatus.clientAbsent]: {
      label: translateFunc('sessionStatus.clientAbsent', { ns: 'options' }),
      color: 'absent'
    },
    [sessionStatus.trainerAbsent]: {
      label: translateFunc('sessionStatus.trainerAbsent', { ns: 'options' }),
      color: 'absent'
    }
  };

  return statusObject[status] || statusObject.default;
};

/**
 * Transforms an event object into a session card data object.
 *
 * @param  {Object}  event     The event object containing session details.
 * @param  {string}  userRole  The role of the user viewing the session card.
 *
 * @returns  {Object}  Returns an object containing the session id, data (session details), and options (view customization).
 */
export const mapEventToSessionCardData = (event, userRole) => ({
  id: event.id,
  data: {
    client_id: event.extendedProps.client,
    end_date: event.end,
    language: event.extendedProps.language,
    session_type_id: { name: event.extendedProps.session_type },
    start_date: event.start,
    status: event.extendedProps.status,
    trainer_id: event.extendedProps.trainer
  },
  options: {
    viewType: userRole
  }
});

/**
 * Triggers a refresh of session data if the user has a trainer role.
 *
 * @param  {string}    role      The role of the current user.
 * @param  {Function}  dispatch  The Redux dispatch function to dispatch actions.
 */
export const refreshSessionsAction = (role, dispatch) => {
  if (role === roles.trainer) {
    dispatch(sessionSlice.actions.refresh());
  }
};

/**
 * Dispatches an action to update the count of available sessions.
 *
 * @param  {number}    availableSessions  The new count of available sessions to update in the state.
 * @param  {Function}  dispatch           The Redux dispatch function used to send actions to the store.
 */
export const updateAvailableSessionsCounterAction = (availableSessions, dispatch) => {
  dispatch(authSlice.actions.updateAvailableSessionsCounter(availableSessions));
};
