import { Skeleton, styled } from '@mui/material';

const StyledProgressGalleryHighlightSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: '4px',
  height: '386px',
  [theme.breakpoints.up('md')]: {
    borderRadius: '5px'
  },
  [theme.breakpoints.up('lg')]: {
    height: '500px'
  }
}));

export default StyledProgressGalleryHighlightSkeleton;
