/* eslint-disable camelcase */
import { performanceStatus, trashed } from 'common/constants/options';

/**
 * Returns an array of IDs extracted from the given options.
 * If the options parameter is an array, it maps each option to its value property.
 * Otherwise, it returns the options as is.
 *
 * @param {Array|any} options - The options to extract IDs from.
 * @returns {Array|any} - The array of IDs or the options themselves.
 */
const getIdsFromOptions = (options) =>
  Array.isArray(options) ? options.map((option) => option.value) : options;

/**
 * Checks if a filter is valid based on the provided filter object and type.
 *
 * @param {object} filterObject - The filter object to check against.
 * @param {string} filter - The filter key to check.
 * @param {string} type - The type of filter ('range' or 'dateRange').
 * @returns {boolean} - Returns true if the filter is valid, otherwise false.
 */
const isValidFilter = (filterObject, filter, type) => {
  if (!(filter in filterObject)) {
    return false;
  }

  if (typeof filterObject[filter] === 'boolean') {
    return filterObject[filter] !== null;
  }

  if (type === 'range') {
    return (
      filterObject[filter] !== null &&
      'from' in filterObject[filter] &&
      filterObject[filter]?.from !== null &&
      'to' in filterObject[filter]
    );
  }

  if (type === 'dateRange') {
    return (
      filterObject[filter] !== null &&
      'start_date' in filterObject[filter] &&
      filterObject[filter]?.start_date !== null &&
      'end_date' in filterObject[filter]
    );
  }

  if (Number.isInteger(filterObject[filter])) {
    return typeof filterObject[filter] !== 'undefined' && filterObject[filter] !== null;
  }

  if (typeof filterObject[filter] === 'object') {
    return filterObject[filter] !== null && Object.keys(filterObject[filter]).length > 0;
  }

  return (
    typeof filterObject[filter] !== 'undefined' &&
    filterObject[filter] !== null &&
    filterObject[filter].length > 0
  );
};

/**
 * Returns the legal filters based on the provided filters object.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The legal filters object.
 */
export const getLegalFilters = (filters) => {
  const language = isValidFilter(filters, 'language')
    ? `language: ${JSON.stringify(filters.language)}`
    : '';
  const role = isValidFilter(filters, 'role') ? `role: ${filters.role}` : '';

  return {
    language,
    role
  };
};

/**
 * Retrieves the meal plan filters based on the provided filters object.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The meal plan filters.
 */
export const getMealPlanFilters = (filters) => {
  let created_at = '';

  if (isValidFilter(filters, 'created_at', 'dateRange')) {
    created_at = `created_at: { start_date: "${filters.created_at.start_date}", end_date: "${filters.created_at.end_date}" }`;
  }

  const soft_delete = isValidFilter(filters, 'soft_delete')
    ? `trashed: ${filters.soft_delete}`
    : `trashed: ${trashed.without}`;
  const user_id = isValidFilter(filters, 'user_id')
    ? `user_id: [${getIdsFromOptions(filters.user_id)}]`
    : '';

  return {
    created_at,
    soft_delete,
    user_id
  };
};

/**
 * Retrieves the news filters based on the provided filter object.
 *
 * @param {Object} filters - The filter object.
 * @returns {Object} - The news filters.
 */
export const getNewsFilters = (filters) => {
  let created_at = '';

  if (isValidFilter(filters, 'created_at', 'dateRange')) {
    created_at = `created_at: { start_date: "${filters.created_at.start_date}", end_date: "${filters.created_at.end_date}" }`;
  }

  const language = isValidFilter(filters, 'language')
    ? `language: ${JSON.stringify(filters.language)}`
    : '';
  const search = isValidFilter(filters, 'search') ? `search: ${filters.search}` : '';
  const soft_delete = isValidFilter(filters, 'soft_delete')
    ? `trashed: ${filters.soft_delete}`
    : `trashed: ${trashed.without}`;

  return {
    created_at,
    language,
    search,
    soft_delete
  };
};

/**
 * Returns the notification filters based on the provided filters object.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The notification filters object.
 */
export const getNotificationFilters = (filters) => {
  const status = isValidFilter(filters, 'status') ? `status: ${filters.status}` : '';
  const user_id = isValidFilter(filters, 'user_id') ? `user_id: ${filters.user_id}` : '';

  return {
    status,
    user_id
  };
};

/**
 * Returns an object containing payment filters based on the provided filters.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The payment filters object.
 */
export const getPaymentFilters = (filters) => {
  const currency = isValidFilter(filters, 'currency') ? `currency: ${filters.currency}` : '';
  const plan_id = isValidFilter(filters, 'plan_id') ? `plan_id: [${filters.plan_id}]` : '';
  const soft_delete = isValidFilter(filters, 'soft_delete')
    ? `trashed: ${filters.soft_delete}`
    : `trashed: ${trashed.without}`;
  const user_id = isValidFilter(filters, 'user_id')
    ? `user_id: [${getIdsFromOptions(filters.user_id)}]`
    : '';

  return {
    currency,
    plan_id,
    soft_delete,
    user_id
  };
};

/**
 * Retrieves performance filters based on the provided filters object.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The performance filters object.
 */
export const getPerformanceFilters = (filters) => {
  const incomplete =
    isValidFilter(filters, 'incomplete') && filters.incomplete !== performanceStatus.all
      ? `incomplete: ${filters.incomplete}`
      : '';
  const soft_delete = isValidFilter(filters, 'soft_delete')
    ? `trashed: ${filters.soft_delete}`
    : `trashed: ${trashed.without}`;
  const user_id = isValidFilter(filters, 'user_id')
    ? `user_id: [${getIdsFromOptions(filters.user_id)}]`
    : '';

  return {
    incomplete,
    soft_delete,
    user_id
  };
};

/**
 * Retrieves the performance history filters based on the provided filters.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The performance history filters object.
 */
export const getPerformanceHistoryFilters = (filters) => {
  let created_between = '';

  if (isValidFilter(filters, 'created_between', 'dateRange')) {
    created_between = `created_between: { start_date: "${filters.created_between.start_date}", end_date: "${filters.created_between.end_date}" }`;
  }

  const user_id = isValidFilter(filters, 'user_id') ? `user_id: ${filters.user_id}` : '';

  return {
    created_between,
    user_id
  };
};

/**
 * Retrieves plan filters based on the provided filters object.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The plan filters object.
 */
export const getPlanFilters = (filters) => {
  const currency = isValidFilter(filters, 'currency') ? `currency: ${filters.currency}` : '';
  const soft_delete = isValidFilter(filters, 'soft_delete')
    ? `trashed: ${filters.soft_delete}`
    : `trashed: ${trashed.without}`;

  return {
    currency,
    soft_delete
  };
};

/**
 * Retrieves progress filters based on the provided filters.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The progress filters object.
 */
export const getProgressFilters = (filters) => {
  const soft_delete = isValidFilter(filters, 'soft_delete')
    ? `trashed: ${filters.soft_delete}`
    : `trashed: ${trashed.without}`;
  const user_id = isValidFilter(filters, 'user_id')
    ? `user_id: [${getIdsFromOptions(filters.user_id)}]`
    : '';

  return {
    soft_delete,
    user_id
  };
};

/**
 * Returns an object containing filters for a session request.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The session filters object.
 */
export const getSessionFilters = (filters) => {
  let happen_between = '';
  let start_between = '';

  if (isValidFilter(filters, 'happen_between', 'dateRange')) {
    if (filters.happen_between.end_date !== null) {
      happen_between = `happen_between: { start_date: "${filters.happen_between.start_date}", end_date: "${filters.happen_between.end_date}" }`;
    } else {
      happen_between = `happen_between: { start_date: "${filters.happen_between.start_date}" }`;
    }
  }

  if (isValidFilter(filters, 'start_between', 'dateRange')) {
    if (filters.start_between.end_date !== null) {
      start_between = `start_between: { start_date: "${filters.start_between.start_date}", end_date: "${filters.start_between.end_date}" }`;
    } else {
      start_between = `start_between: { start_date: "${filters.start_between.start_date}" }`;
    }
  }

  const client_id = isValidFilter(filters, 'client_id')
    ? `client_id: [${getIdsFromOptions(filters.client_id)}]`
    : '';
  const pending_update = isValidFilter(filters, 'pending_update')
    ? `pending_update: ${filters.pending_update}`
    : '';
  const session_type_id = isValidFilter(filters, 'session_type_id')
    ? `session_type_id: [${filters.session_type_id}]`
    : '';
  const status = isValidFilter(filters, 'status') ? `status: ${filters.status}` : '';
  const trainer_id = isValidFilter(filters, 'trainer_id')
    ? `trainer_id: [${
        Array.isArray(filters.trainer_id)
          ? getIdsFromOptions(filters.trainer_id)
          : filters.trainer_id
      }]`
    : '';
  const user_id = isValidFilter(filters, 'user_id') ? `user_id: ${filters.user_id}` : '';

  return {
    client_id,
    happen_between,
    pending_update,
    session_type_id,
    start_between,
    status,
    trainer_id,
    user_id
  };
};

/**
 * Returns the session type filters based on the provided filters object.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The session type filters.
 */
export const getSessionTypeFilters = (filters) => {
  const currency = isValidFilter(filters, 'currency') ? `currency: ${filters.currency}` : '';
  const soft_delete = isValidFilter(filters, 'soft_delete')
    ? `trashed: ${filters.soft_delete}`
    : `trashed: ${trashed.without}`;

  return {
    currency,
    soft_delete
  };
};

/**
 * Retrieves the top ranked performance filters based on the provided filters.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The top ranked performance filters.
 */
export const getTopRankedPerformanceFilters = (filters) => {
  let created_at = '';

  if (isValidFilter(filters, 'created_at', 'dateRange')) {
    created_at = `created_at: { start_date: "${filters.created_at.start_date}", end_date: "${filters.created_at.end_date}" }`;
  }

  const performance_test = isValidFilter(filters, 'performance_test')
    ? `performance_test: "${filters.performance_test}"`
    : '';

  return {
    created_at,
    performance_test
  };
};

/**
 * Returns the filters for trainer payment information.
 *
 * @param {Object} filters - The filters object.
 * @returns {Object} - The filters for trainer payment information.
 */
export const getTrainerPaymentInfoFilters = (filters) => {
  let date_range = '';

  if (isValidFilter(filters, 'date_range', 'dateRange')) {
    date_range = `date_range: { start_date: "${filters.date_range.start_date}", end_date: "${filters.date_range.end_date}" }`;
  }

  const trainer_id = isValidFilter(filters, 'trainer_id')
    ? `trainer_id: ${filters.trainer_id}`
    : '';

  return {
    date_range,
    trainer_id
  };
};

/**
 * Retrieves user filters based on the provided filter object.
 *
 * @param {Object} filters - The filter object.
 * @returns {Object} - The user filters object.
 */
export const getUserFilters = (filters) => {
  let date_range = '';

  if (isValidFilter(filters, 'date_range', 'dateRange')) {
    if (filters.date_range.end_date !== null) {
      date_range = `date_range: { start_date: "${filters.date_range.start_date}", end_date: "${filters.date_range.end_date}" }`;
    } else {
      date_range = `date_range: { start_date: "${filters.date_range.start_date}" }`;
    }
  }

  const ids = isValidFilter(filters, 'ids') ? `ids: [${filters.ids}]` : '';
  const gender = isValidFilter(filters, 'gender') ? `gender: ${filters.gender}` : '';
  const languages = isValidFilter(filters, 'languages')
    ? `languages: ${JSON.stringify(filters.languages)}`
    : '';
  const membership_status = isValidFilter(filters, 'membership_status')
    ? `membership_status: [${filters.membership_status}]`
    : '';
  const plan_id = isValidFilter(filters, 'plan_id') ? `plan_id: [${filters.plan_id}]` : '';
  const role = isValidFilter(filters, 'role') ? `role: ${filters.role}` : '';
  const soft_delete = isValidFilter(filters, 'soft_delete')
    ? `trashed: ${filters.soft_delete}`
    : `trashed: ${trashed.without}`;
  const specialties = isValidFilter(filters, 'specialties')
    ? `specialties: ${JSON.stringify(filters.specialties)}`
    : '';
  const user_id = isValidFilter(filters, 'user_id')
    ? `user_id: [${getIdsFromOptions(filters.user_id)}]`
    : '';

  return {
    date_range,
    ids,
    gender,
    languages,
    membership_status,
    plan_id,
    role,
    soft_delete,
    specialties,
    user_id
  };
};
