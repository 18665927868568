import mealPlan from 'api/graphql/mealPlan';

const createMealPlanTemporaryUrl = async (id) => {
  const response = await mealPlan.createMealPlanTemporaryUrl(id);
  const {
    data: { createMealPlanTemporaryUrl: temporaryUrl }
  } = response;

  return temporaryUrl;
};

export default createMealPlanTemporaryUrl;
