import { styled } from '@mui/material';
import { appBarClasses } from '@mui/material/AppBar';
import { dialogContentClasses } from '@mui/material/DialogContent';
import { toolbarClasses } from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import sharedDialogStyles from 'common/theme/dialog/sharedDialogStyles';

const StyledSessionDialogCreate = styled(Dialog)(({ theme }) => ({
  ...sharedDialogStyles(theme),
  [`& .${appBarClasses.root}`]: {
    boxShadow: 'none'
  },
  [`& .${dialogContentClasses.root}`]: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(4)
    },
    [theme.breakpoints.down('sm')]: {
      padding: `20px ${theme.spacing(2)} ${theme.spacing(4)}}`
    }
  },
  [`& .${toolbarClasses.root}`]: {
    justifyContent: 'space-between'
  }
}));

export default StyledSessionDialogCreate;
