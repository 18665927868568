import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import PdfControls from 'components/shared/PdfControls';
import { mealPlanPdfFields } from 'common/constants/query-fields/mealPlan';
import { downloadFile } from 'common/utils/general';
import AppBarContext from 'context/AppBarContext';
import usePdfControls from 'hooks/usePdfControls';
import mealPlanSlice from 'store/modules/mealPlan';

import 'styles/pdf-viewer.css';

function MealPlan() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation(['mealPlans', 'routes']);
  const pageTitle = t('mealPlan.myMealPlan', { ns: 'routes' });
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultScale = useMemo(() => (isExtraSmall ? 0.6 : 1.5), [isExtraSmall]);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const [totalPages, setTotalPages] = useState(null);

  const mealPlans = useSelector((state) => state.mealPlan.data.mealPlans);
  const status = useSelector((state) => state.mealPlan.status.general);
  const userId = useSelector((state) => state.auth.user.id);

  const { nextPage, pageNumber, prevPage, scale, zoomIn, zoomOut } = usePdfControls(
    1,
    defaultScale
  );

  useEffect(() => {
    dispatch(
      mealPlanSlice.actions.list({
        fields: mealPlanPdfFields,
        filters: { user_id: userId },
        pagination: { first: 1, page: 1 }
      })
    );
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const handleDownload = () => {
    downloadFile(mealPlans[0]?.pdf_document, t('mealPlanFilename', { ns: 'mealPlans' }));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <LoadingBlock
        emptyBlockProps={{ message: t('empty', { ns: 'mealPlans' }) }}
        isValid={mealPlans.length > 0}
        status={status}>
        <>
          <PdfControls
            download={handleDownload}
            nextPage={nextPage}
            numPages={totalPages}
            pageNumber={pageNumber}
            previousPage={prevPage}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
          />
          <Document
            className="pdfViewer"
            file={mealPlans[0]?.pdf_document}
            onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              className="pdfViewerPage"
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              scale={scale}
            />
          </Document>
        </>
      </LoadingBlock>
    </>
  );
}

export default MealPlan;
