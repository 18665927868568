import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import SessionTypeFields from 'components/session-type/SessionTypeFields';
import defaultSessionTypeFormValidations from 'common/validations/form/defaultSessionType';
import routes from 'common/routes';
import requestStatus from 'common/constants/status';
import { createSessionTypeInitialValues } from 'common/form/initial-values/create';
import getDataToSave from 'common/form/dataToSave';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import AppBarContext from 'context/AppBarContext';
import sessionTypeSlice from 'store/modules/sessionType';

const requiredFields = ['name', 'price'];

function SessionTypeCreate() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('sessionType.create', { ns: 'routes' });
  const formRef = useRef(null);
  const status = useSelector((state) => state.sessionType.status);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  useEffect(() => {
    if (status === requestStatus.success) {
      formRef.current.restart();
    }
  }, [status]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onCreate = useCallback(
    (values) => {
      const dataToSave = getDataToSave(values, 'sessionType');
      dispatch(sessionTypeSlice.actions.create(dataToSave));
    },
    [dispatch]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={createSessionTypeInitialValues}
        onSubmit={onCreate}
        validate={defaultSessionTypeFormValidations}
        render={({ handleSubmit, form, invalid, pristine }) => {
          formRef.current = form;

          return (
            <form onSubmit={handleSubmit}>
              <SessionTypeFields requiredFields={requiredFields} />
              <StyledButtonsContainer>
                <SecondaryButtonLoading
                  component={RouterLink}
                  disabled={status === requestStatus.loading}
                  style={{ marginRight: '32px' }}
                  label={t('cancel', { ns: 'buttons' })}
                  to={routes.listSessionType.path}
                  type="button"
                />
                <PrimaryLoadingButton
                  disabled={invalid || pristine || status === requestStatus.loading}
                  loading={status === requestStatus.loading}
                  label={t('sessionType.create', { ns: 'buttons' })}
                  type="submit"
                />
              </StyledButtonsContainer>
            </form>
          );
        }}
      />
    </>
  );
}

export default SessionTypeCreate;
