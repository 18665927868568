import DefaultLayout from 'components/layout/DefaultLayout';
import MealPlan from 'components/meal-plan/MealPlan';
import MealPlanCreate from 'components/meal-plan/MealPlanCreate';
import MealPlanEdit from 'components/meal-plan/MealPlanEdit';
import MealPlanList from 'components/meal-plan/MealPlanList';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const planRoutes = [
  // Admin routes

  {
    key: 'meal-plan-create',
    exact: true,
    path: routes.createMealPlan.path,
    component: MealPlanCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'meal-plan-edit',
    exact: true,
    path: routes.editMealPlan.path,
    component: MealPlanEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'meal-plan-list',
    exact: true,
    path: routes.listMealPlan.path,
    component: MealPlanList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },

  // Client routes

  {
    key: 'meal-plan-client',
    exact: true,
    path: routes.clientMealPlan.path,
    component: MealPlan,
    layout: DefaultLayout,
    allowedRoles: [roles.client]
  }
];

export default planRoutes;
