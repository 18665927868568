/* eslint-disable react/jsx-no-duplicate-props */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Autocomplete, CircularProgress, MenuItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClientOption from 'components/shared/options/ClientOption';
import { membershipStatus } from 'common/constants/options';
import StyledTextField from 'common/theme/fields/StyledTextfield';

const getRenderedOption = (option, props, renderType) => {
  const options = {
    client: () => <ClientOption option={option} props={props} key={option.value} />,
    default: () => (
      <MenuItem key={option.value} {...props}>
        {option.label}
      </MenuItem>
    )
  };

  return options[renderType]();
};

const isOptionDisable = (option, disableType) => {
  const options = {
    client: option.membership_status !== membershipStatus.active || option.available_sessions <= 0,
    default: false
  };

  return options[disableType];
};

function TypeaheadFieldAdapter({
  disableType,
  input,
  isMultiple,
  loading,
  meta,
  onClose,
  renderInputProps,
  renderOptionAs,
  ...rest
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onBlur = (event) => {
    input.onBlur(event);
  };

  const onChange = (event, value) => {
    input.onChange(value);
  };

  const onFocus = (event) => {
    input.onFocus(event);
  };

  return (
    <Autocomplete
      {...rest}
      autoHighlight
      filterOptions={(options) => {
        let currentValues = [];

        if (Array.isArray(input.value)) {
          currentValues = input.value.map((item) => item.value);
        } else {
          currentValues = [input.value.value];
        }

        const filteredOptions = options.filter((option) => !currentValues.includes(option.value));
        return filteredOptions;
      }}
      getOptionDisabled={(option) => isOptionDisable(option, disableType)}
      getOptionLabel={(option) =>
        option && typeof option.label !== 'undefined' ? option.label : ''
      }
      isOptionEqualToValue={(option, value) => option.value === value.value || value === ''}
      multiple={isMultiple}
      noOptionsText={
        loading ? t('loadingOptions', { ns: 'common' }) : t('noOptions', { ns: 'common' })
      }
      onBlur={onBlur}
      onChange={onChange}
      onClose={onClose}
      onFocus={onFocus}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          {...renderInputProps}
          InputProps={{
            ...params.InputProps,
            endAdornment:
              loading && meta.active ? (
                <CircularProgress color="secondary" size={25} />
              ) : (
                params.InputProps.endAdornment
              )
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off'
          }}
        />
      )}
      renderOption={(props, option) => getRenderedOption(option, props, renderOptionAs)}
      size={size}
      value={input.value || null}
    />
  );
}

TypeaheadFieldAdapter.defaultProps = {
  disableType: 'default',
  loading: false,
  onClose: () => {},
  renderOptionAs: 'default'
};

TypeaheadFieldAdapter.propTypes = {
  disableType: PropTypes.string,
  input: PropTypes.object.isRequired,
  isMultiple: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  renderInputProps: PropTypes.object.isRequired,
  renderOptionAs: PropTypes.string
};

export default TypeaheadFieldAdapter;
