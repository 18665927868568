export const adminFindFields = `
  id
  birthdate
  email
  first_name
  gender
  last_name
  settings {
    language
  }
`;

export const adminListFields = `
  id
  created_at
  email
  first_name
  full_name
  gender
  last_name
  photo
`;
