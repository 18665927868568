import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

function FullScreenLoading() {
  return (
    <Backdrop
      open
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}>
      <CircularProgress size={34} thickness={5} color="secondary" />
    </Backdrop>
  );
}

export default FullScreenLoading;
