import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import StyledAppbarButtonLink from 'common/theme/appbar/StyledAppbarButtonLink';

function AppBarButtonLink({ label, path }) {
  return (
    <StyledAppbarButtonLink component={RouterLink} to={path} size="small" variant="contained">
      <span>{label}</span>
    </StyledAppbarButtonLink>
  );
}

AppBarButtonLink.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};

export default AppBarButtonLink;
