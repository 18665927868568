import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AdminDesktopMenu from 'components/shared/menus/desktop/AdminDesktopMenu';
import ClientDesktopMenu from 'components/shared/menus/desktop/ClientDesktopMenu';
import TrainerDesktopMenu from 'components/shared/menus/desktop/TrainerDesktopMenu';
import UserAvatar from 'components/shared/user/UserAvatar';
import { roles } from 'common/constants/options';
import DrawerCollapseButton from 'components/shared/drawers/DrawerCollapseButton';
import DrawerLogo from 'components/shared/drawers/DrawerLogo';
import DrawerLogoutButton from 'components/shared/drawers/DrawerLogoutButton';
import DrawerUserInfo from 'components/shared/drawers/DrawerUserInfo';
import StyledDrawerMenuContainer from 'common/theme/drawer-menu/StyledDrawerMenuContainer';
import StyledDrawerMenuUserContainer from 'common/theme/drawer-menu/StyledDrawerMenuUserContainer';
import BeamContext from 'context/BeamContext';
import settingsSlice from 'store/modules/settings';
import authSlice from 'store/modules/auth';

const getAvatarSize = (miniDrawer, isMedium) => {
  if (miniDrawer) {
    return 64;
  }

  return isMedium ? 50 : 90;
};

const getDesktopMenuByRole = (role) => {
  const roleType = {
    [roles.client]: () => <ClientDesktopMenu />,
    [roles.trainer]: () => <TrainerDesktopMenu />,
    default: () => <AdminDesktopMenu />
  };

  return (roleType[role] || roleType.default)();
};

function DrawerMenu() {
  const dispatch = useDispatch();
  const { beamsClient } = useContext(BeamContext);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));

  const miniDrawer = useSelector((state) => state.settings.config.miniDrawer);
  const user = useSelector((state) => state.auth.user);

  const avatarSize = useMemo(() => getAvatarSize(miniDrawer, isMedium), [miniDrawer, isMedium]);
  const avatarTextSize = useMemo(() => (isMedium ? 25 : 32), [isMedium]);

  const logout = () => {
    if (beamsClient) beamsClient.stop().catch(console.error);
    dispatch(authSlice.actions.logout());
  };

  const toggleMiniDrawer = () => {
    dispatch(
      settingsSlice.actions.update({
        id: user.settings.id,
        input: { mini_drawer: !miniDrawer }
      })
    );
  };

  return (
    <StyledDrawerMenuContainer>
      <Box>
        <DrawerLogo />
        <StyledDrawerMenuUserContainer miniDrawer={miniDrawer}>
          <UserAvatar border size={avatarSize} textSize={avatarTextSize} user={user} />
          {!miniDrawer && <DrawerUserInfo />}
        </StyledDrawerMenuUserContainer>
        {getDesktopMenuByRole(user.role)}
      </Box>
      <DrawerCollapseButton onClick={toggleMiniDrawer} />
      <DrawerLogoutButton onClick={logout} />
    </StyledDrawerMenuContainer>
  );
}

export default DrawerMenu;
