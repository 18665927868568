import { Stack, styled } from '@mui/material';

const StyledDefaultLayoutBannerContainer = styled(Stack)(({ theme }) => ({
  '& > *:first-of-type': {
    marginTop: theme.spacing(3)
  },
  [theme.breakpoints.up('lg')]: {
    '& > *:first-of-type': {
      marginTop: 0
    }
  }
}));

export default StyledDefaultLayoutBannerContainer;
