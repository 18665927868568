import { Box, styled } from '@mui/material';

const StyledImageUploadPreviewInnerContainer = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  maxHeight: '100%',
  overflow: 'hidden',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px'
  }
}));

export default StyledImageUploadPreviewInnerContainer;
