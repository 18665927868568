import i18n from 'i18n';

/**
 * Get cities method
 *
 * @return {Promise}
 */
const getCities = async (payload) => {
  const requestOptions = {
    body: JSON.stringify(payload),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    redirect: 'follow'
  };

  const response = await fetch(
    'https://countriesnow.space/api/v0.1/countries/state/cities',
    requestOptions
  );

  if (response.ok) {
    const jsonResponse = await response.json();
    return Promise.resolve(jsonResponse);
  }

  return Promise.reject(new Error(i18n.t('geo.cities', { ns: 'errors' })));
};

/**
 * Get countries method
 *
 * @return {Promise}
 */
const getCountries = async () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  const response = await fetch('https://countriesnow.space/api/v0.1/countries/iso', requestOptions);

  if (response.ok) {
    const jsonResponse = await response.json();
    return Promise.resolve(jsonResponse);
  }

  return Promise.reject(new Error(i18n.t('geo.countries', { ns: 'errors' })));
};

/**
 * Get states method
 *
 * @return {Promise}
 */
const getStates = async (country) => {
  const requestOptions = {
    body: JSON.stringify({ country }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    redirect: 'follow'
  };

  const response = await fetch(
    'https://countriesnow.space/api/v0.1/countries/states',
    requestOptions
  );

  if (response.ok) {
    const jsonResponse = await response.json();
    return Promise.resolve(jsonResponse);
  }

  return Promise.reject(new Error(i18n.t('geo.states', { ns: 'errors' })));
};

export default {
  getCities,
  getCountries,
  getStates
};
