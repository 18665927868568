import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import SessionFields from 'components/session/fields/SessionFields';
import SessionFieldsListeners from 'components/session/fields/SessionFieldsListeners';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import { userAvatarFields } from 'common/constants/query-fields/user';
import requestStatus from 'common/constants/status';
import getDataToSave from 'common/form/dataToSave';
import { updateSessionInitialValues } from 'common/form/initial-values/update';
import { getFieldsToUpdate } from 'common/form/utils';
import { formDecorator } from 'common/utils/session';
import routes from 'common/routes';
import defaultSessionFormValidations from 'common/validations/form/defaultSession';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import AppBarContext from 'context/AppBarContext';
import clientPaymentSlice from 'store/modules/clientPayment';
import sessionSlice from 'store/modules/session';
import trainerSlice from 'store/modules/trainer';

const calculator = formDecorator;

function SessionEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('session.edit', { ns: 'routes' });
  const { id } = useParams();
  const formRef = useRef(null);
  const [enableFormListeners, setEnableFormListeners] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const editStatus = useSelector((state) => state.session.status.edit);
  const session = useSelector((state) => state.session.data.currentSession);
  const status = useSelector((state) => state.session.status.general);

  const sessionLoaded =
    session &&
    Object.keys(session).length > 0 &&
    session.id === id &&
    status === requestStatus.success;

  useEffect(() => {
    dispatch(sessionSlice.actions.find(id));
  }, []);

  useEffect(() => {
    if (sessionLoaded) {
      const sessionData = updateSessionInitialValues(session);
      setInitialValues(sessionData);
    }
  }, [session, sessionLoaded]);

  useEffect(() => {
    if (sessionLoaded && initialValues?.trainer_id?.id) {
      dispatch(
        trainerSlice.actions.listAvailable({
          fields: userAvatarFields,
          filters: { ids: [Number(initialValues.trainer_id.id)] }
        })
      );
    }
  }, [dispatch, initialValues.trainer_id?.id, sessionLoaded]);

  useEffect(() => {
    if (sessionLoaded && Object.keys(initialValues).length > 0) {
      setEnableFormListeners(true);
    }
  }, [initialValues, sessionLoaded]);

  useEffect(() => {
    if (sessionLoaded && initialValues?.client_id?.value) {
      dispatch(clientPaymentSlice.actions.getLatest(Number(initialValues.client_id.value)));
    }
  }, [dispatch, initialValues.client_id?.value, sessionLoaded]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onEdit = useCallback(
    (values) => {
      const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
      const dataToSave = getDataToSave(fieldsToUpdate, 'session');
      dispatch(sessionSlice.actions.update({ id, input: dataToSave }));
    },
    [dispatch, id, initialValues]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        decorators={[calculator]}
        initialValues={initialValues}
        onSubmit={onEdit}
        validate={defaultSessionFormValidations}
        render={({ form, handleSubmit, invalid, pristine, values }) => {
          formRef.current = form;

          return (
            <form onSubmit={handleSubmit}>
              <SessionFields isEditing values={values} disableClientSelection />
              <StyledButtonsContainer>
                <SecondaryButtonLoading
                  component={RouterLink}
                  disabled={editStatus === requestStatus.loading}
                  style={{ marginRight: '32px' }}
                  label={t('cancel', { ns: 'buttons' })}
                  to={routes.listSession.path}
                  type="button"
                />
                <PrimaryLoadingButton
                  disabled={invalid || pristine || editStatus === requestStatus.loading}
                  loading={editStatus === requestStatus.loading}
                  label={t('session.edit', { ns: 'buttons' })}
                  type="submit"
                />
              </StyledButtonsContainer>
              {enableFormListeners && <SessionFieldsListeners form={formRef?.current} />}
            </form>
          );
        }}
      />
    </>
  );
}

export default SessionEdit;
