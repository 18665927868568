import React from 'react';
import PropTypes from 'prop-types';
import StyledNotificationMenuMenuItem from 'common/theme/notification/StyledNotificationMenuMenuItem';
import { getNotificationType } from 'common/utils/notification';

function NotificationMenuItem({ notification }) {
  return (
    <StyledNotificationMenuMenuItem disableGutters divider isRead={notification.read}>
      {getNotificationType(notification)}
    </StyledNotificationMenuMenuItem>
  );
}

NotificationMenuItem.propTypes = {
  notification: PropTypes.shape({
    read: PropTypes.bool.isRequired
  }).isRequired
};

export default NotificationMenuItem;
