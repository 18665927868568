import { Button, styled } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';
import {
  primaryButtonBaseStyles,
  primaryButtonHoverStyles,
  primaryButtonPseudoElementStyles
} from 'common/theme/button/sharedButtonStyles';

const StyledAvailabilityRepeatButton = styled(Button)(({ theme }) => ({
  ...primaryButtonBaseStyles(theme),
  position: 'absolute',
  right: 0,
  top: '39px',
  '&::before': {
    ...primaryButtonHoverStyles,
    ...primaryButtonPseudoElementStyles(theme)
  },
  '&:hover::before': {
    opacity: 1
  },
  [`&.${buttonClasses.disabled}`]: {
    background: theme.palette.grey[800]
  }
}));

export default StyledAvailabilityRepeatButton;
