import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardBlock from 'components/shared/blocks/DashboardBlock';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import NewsCard from 'components/shared/cards/NewsCard';
import PaginatedList from 'components/shared/lists/PaginatedList';

function LatestNews({ itemsPerPage }) {
  const { t } = useTranslation();

  const news = useSelector((state) => state.news.data.news);
  const newsStatus = useSelector((state) => state.news.status.general);

  const isValid = news.length > 0;

  return (
    <DashboardBlock title={t('latestNews', { ns: 'common' })}>
      <LoadingBlock
        isValid={isValid}
        skeletonProps={{
          height: 120,
          rows: itemsPerPage
        }}
        status={newsStatus}
        type="table">
        <PaginatedList
          items={news}
          itemsPerPage={itemsPerPage}
          renderItem={(item) => <NewsCard data={item} key={item.id} />}
        />
      </LoadingBlock>
    </DashboardBlock>
  );
}

LatestNews.defaultProps = {
  itemsPerPage: 5
};

LatestNews.propTypes = {
  itemsPerPage: PropTypes.number
};

export default LatestNews;
