import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledTextField from 'common/theme/fields/StyledTextfield';

function TextAreaFieldAdapter({ helperText, input, meta, ...rest }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onChange = (event) => {
    const {
      target: { type, value }
    } = event;
    const formattedValue = type === 'number' ? Number(value) : value;

    input.onChange(formattedValue);
  };

  return (
    <StyledTextField
      {...input}
      {...rest}
      onChange={onChange}
      error={meta.touched ? meta.invalid : false}
      helperText={meta.touched && meta.invalid ? meta.error : helperText}
      size={size}
    />
  );
}

TextAreaFieldAdapter.defaultProps = {
  helperText: ''
};

TextAreaFieldAdapter.propTypes = {
  helperText: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default TextAreaFieldAdapter;
