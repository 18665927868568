const sessionRoutes = {
  calendar: {
    path: '/calendar'
  },
  clientSessions: {
    path: '/session-history'
  },
  createSession: {
    path: '/sessions/create'
  },
  editSession: {
    path: '/sessions/edit/:id'
  },
  listSession: {
    path: '/sessions'
  }
};

export default sessionRoutes;
