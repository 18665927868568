import { Unstable_Grid2 as Grid, styled } from '@mui/material';

const StyledTrainerSelectGridContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.gray2.main,
  borderRadius: '8px',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  height: '196px',
  overflowY: 'auto',
  padding: theme.spacing(1),
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '136px'
  }
}));

export default StyledTrainerSelectGridContainer;
