import styledComponent from 'styled-components';

const StyledSingleCounterIconContainer = styledComponent.div`
  align-self: center;
  background-color: #57482B;
  border-radius: 10px;
  padding: 10px 6px;
  & svg {
    color: #F9BD4E;
    display: block;
    font-size: 2rem;
  }
  @media (min-width: 600px) {
    padding: 12px 8px;
  }
  @media (min-width: 900px) {
    padding: 15px 10px;
  }
`;

export default StyledSingleCounterIconContainer;
