import { takeLatest, put } from 'redux-saga/effects';
import planSlice from 'store/modules/plan';
import uiSlice from 'store/modules/ui';
import plan from 'api/graphql/plan';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import i18n from 'i18n';

/**
 * Create plan saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'plan-create-notification-success',
      variant: 'success',
      message: i18n.t('plan.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield plan.create(payload);
    yield put(planSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(planSlice.actions.createError(message));
  }
}

/**
 * Find plan saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield plan.find(action.payload);
    const {
      data: { plan: currentPlan }
    } = response;

    yield put(planSlice.actions.findSuccess(currentPlan));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(planSlice.actions.findError(message));
  }
}

/**
 * List plans saga
 *
 * @param {*} action
 */
function* list(action) {
  try {
    const {
      payload: { filters, pagination }
    } = action;
    const response = yield plan.list({ filters, pagination });
    const {
      data: {
        plans: { data, paginatorInfo }
      }
    } = response;

    yield put(planSlice.actions.listSuccess(data));
    yield put(
      planSlice.actions.updatePaginatorInfo({
        hasMorePages: paginatorInfo.hasMorePages,
        lastPage: paginatorInfo.lastPage,
        total: paginatorInfo.total
      })
    );
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(planSlice.actions.listError(message));
  }
}

/**
 * List all plan saga
 *
 * @param {*} action
 */
function* listAll() {
  try {
    const response = yield plan.listAll();
    const {
      data: { allPlans }
    } = response;

    const options = allPlans.map((option) => ({
      value: Number(option.id),
      label: option.name
    }));

    yield put(planSlice.actions.listAllSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(planSlice.actions.listAllError(message));
  }
}

/**
 * Remove plans saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deletePlans: removedPlans }
    } = yield plan.remove(action.payload);

    const snack = createSnack({
      key: 'remove-plans-notification-success',
      variant: 'success',
      message: i18n.t('plan.delete', { count: removedPlans.length, ns: 'notifications' })
    });

    yield put(planSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(planSlice.actions.removeError(message));
  }
}

/**
 * Restore plans saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restorePlans: restoredPlans }
    } = yield plan.restore(action.payload);

    const snack = createSnack({
      key: 'restore-plans-notification-success',
      variant: 'success',
      message: i18n.t('plan.restore', { count: restoredPlans.length, ns: 'notifications' })
    });

    yield put(planSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(planSlice.actions.restoreError(message));
  }
}

/**
 * Update plan saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-plan-notification-success',
      variant: 'success',
      message: i18n.t('plan.update', { ns: 'notifications' })
    });

    yield plan.update(action.payload);

    yield put(planSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(planSlice.actions.updateError(message));
  }
}

/**
 * Watch plan
 *
 * @export
 */
export default function* watchPlan() {
  yield takeLatest(planSlice.actions.create, create);
  yield takeLatest(planSlice.actions.find, find);
  yield takeLatest(planSlice.actions.list, list);
  yield takeLatest(planSlice.actions.listAll, listAll);
  yield takeLatest(planSlice.actions.remove, remove);
  yield takeLatest(planSlice.actions.restore, restore);
  yield takeLatest(planSlice.actions.update, update);
}
