import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import requestStatus from 'common/constants/status';
import { trashed } from 'common/constants/options';

const initialFiltersState = {
  currency: '',
  user_id: [],
  soft_delete: trashed.without
};

const initialState = {
  data: {
    currentPayment: null,
    latestPayments: [],
    payments: [],
    totalOutcome: []
  },
  filters: initialFiltersState,
  pagination: {
    first: 20,
    page: 1
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0
  },
  refresh: false,
  status: {
    general: requestStatus.idle,
    totalOutcome: requestStatus.idle
  }
};

const trainerPaymentSlice = createSlice({
  name: 'trainerPayment',
  initialState,
  reducers: {
    create(state) {
      state.status.general = requestStatus.loading;
    },
    createSuccess(state) {
      state.status.general = requestStatus.success;
    },
    createError(state) {
      state.status.general = requestStatus.error;
    },
    createInvoice(state) {
      state.status.general = requestStatus.loading;
    },
    createInvoiceSuccess(state) {
      state.status.general = requestStatus.success;
    },
    createInvoiceError(state) {
      state.status.general = requestStatus.error;
    },
    find(state) {
      state.status.general = requestStatus.loading;
    },
    findSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.currentPayment = action.payload;
    },
    findError(state) {
      state.status.general = requestStatus.error;
    },
    list(state) {
      state.status.general = requestStatus.loading;
      if (state.refresh) state.refresh = false;
    },
    listSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.payments = action.payload;
    },
    listError(state) {
      state.status.general = requestStatus.error;
    },
    listLatest(state) {
      state.status.general = requestStatus.loading;
    },
    listLatestSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.latestPayments = action.payload;
    },
    listLatestError(state) {
      state.status.general = requestStatus.error;
    },
    remove(state) {
      state.status.general = requestStatus.loading;
    },
    removeSuccess(state) {
      state.status.general = requestStatus.success;
      state.refresh = true;
    },
    removeError(state) {
      state.status.general = requestStatus.error;
    },
    restore(state) {
      state.status.general = requestStatus.loading;
    },
    restoreSuccess(state) {
      state.status.general = requestStatus.success;
      state.refresh = true;
    },
    restoreError(state) {
      state.status.general = requestStatus.error;
    },
    resetFilters(state) {
      state.filters = initialFiltersState;
    },
    sendInvoice(state) {
      state.status.general = requestStatus.loading;
    },
    sendInvoiceSuccess(state) {
      state.status.general = requestStatus.success;
    },
    sendInvoiceError(state) {
      state.status.general = requestStatus.error;
    },
    totalOutcomeByMonth(state) {
      state.status.totalOutcome = requestStatus.loading;
    },
    totalOutcomeByMonthSuccess(state, action) {
      state.data.totalOutcome = action.payload;
      state.status.totalOutcome = requestStatus.success;
    },
    totalOutcomeByMonthError(state) {
      state.status.totalOutcome = requestStatus.error;
    },
    update(state) {
      state.status.general = requestStatus.loading;
    },
    updateSuccess(state) {
      state.status.general = requestStatus.success;
    },
    updateError(state) {
      state.status.general = requestStatus.error;
    },
    updateFilters(state, action) {
      state.filters = action.payload;
    },
    updatePagination(state, action) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    updatePaginatorInfo(state, action) {
      state.paginatorInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default trainerPaymentSlice;
