import { email } from 'common/validations/field';
import i18n from 'i18n';

const validateForgotPasswordForm = (values) => {
  const errors = {};

  if (!email(values.email)) {
    errors.email = i18n.t('email', { ns: 'validations' });
  }

  return errors;
};

export default validateForgotPasswordForm;
