import { Button, styled } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';

const StyledLogoutButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-start',
  fontSize: theme.typography.pxToRem(12),
  paddingLeft: 2,
  textTransform: 'capitalize',
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(14)
  },
  [`& .${buttonClasses.startIcon}`]: {
    marginRight: 0
  }
}));

export default StyledLogoutButton;
