import { Box, styled } from '@mui/material';

const StyledSessionSideListHeader = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  width: '100%'
}));

export default StyledSessionSideListHeader;
