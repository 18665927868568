import { createContext } from 'react';

const AppBarContext = createContext({
  appBarButton: null,
  setAppBarButton: () => {},
  appBarTitle: '',
  setAppBarTitle: () => {}
});

export default AppBarContext;
