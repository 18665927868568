import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localforage from 'localforage';
import createSagaMiddleware from 'redux-saga';
import loggerMiddleware from 'store/middleware/logger';
import errorMiddleware from 'store/middleware/error';
import createRootReducer from 'store/modules';
import rootSaga from 'store/sagas';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'admin',
    'auth',
    'availability',
    'client',
    'clientPayment',
    'geo',
    'legal',
    'mealPlan',
    'news',
    'notification',
    'performance',
    'plan',
    'progress',
    'session',
    'sessionType',
    'settings',
    'trainer',
    'trainerPayment',
    'ui'
  ]
};

const rootReducer = createRootReducer();

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware =
      process.env.NODE_ENV !== 'production'
        ? getDefaultMiddleware({
            serializableCheck: {
              ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
              ignoreState: true
            }
          }).concat(loggerMiddleware)
        : getDefaultMiddleware();

    return middleware.concat(errorMiddleware).concat(sagaMiddleware);
  }
});

const persistor = persistStore(store);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./modules', () => store.replaceReducer(rootReducer));
}

sagaMiddleware.run(rootSaga);

export { store, persistor };
