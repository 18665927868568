import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import DashboardBlock from 'components/shared/blocks/DashboardBlock';
import IncompletePerformanceTable from 'components/shared/tables/IncompletePerformanceTable';
import LatestPerformanceTable from 'components/shared/tables/LatestPerformanceTable';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TopRankedPerformance from 'components/shared/charts/performance/top-ranked/TopRankedPerformance';
import { performanceTest, timeRange } from 'common/constants/options';
import { performanceListBasicFields } from 'common/constants/query-fields/performance';
import { getDateRange } from 'common/utils/date';
import AppBarContext from 'context/AppBarContext';
import performanceSlice from 'store/modules/performance';

const latestPerformanceFetchCount = 10;
const itemsDisplayedPerPage = 5;

function PerformanceDashboard() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['payments', 'performances', 'routes']);
  const pageTitle = t('performance.list', { ns: 'routes' });
  const [selectedPerformanceTest, setSelectedPerformanceTest] = useState(performanceTest.squat);
  const [selectedTimeRange, setSelectedTimeRange] = useState(timeRange.last3Months);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const incompletePerformances = useSelector(
    (state) => state.performance.data.incompletePerformances
  );
  const incompleteStatus = useSelector((state) => state.performance.status.incomplete);
  const pagination = useSelector((state) => state.performance.pagination);
  const paginatorInfo = useSelector((state) => state.performance.paginatorInfo);
  const performances = useSelector((state) => state.performance.data.performances);
  const performanceStatus = useSelector((state) => state.performance.status.general);
  const topRankedPerformances = useSelector(
    (state) => state.performance.data.topRankedPerformances
  );
  const topRankedStatus = useSelector((state) => state.performance.status.topRanked);

  useEffect(() => {
    dispatch(
      performanceSlice.actions.topRanked({
        created_at: getDateRange(selectedTimeRange),
        performance_test: selectedPerformanceTest
      })
    );
  }, [getDateRange, selectedPerformanceTest, selectedTimeRange]);

  useEffect(() => {
    dispatch(
      performanceSlice.actions.list({
        fields: performanceListBasicFields,
        pagination: { first: latestPerformanceFetchCount, page: 1 }
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      performanceSlice.actions.listIncomplete({
        fields: performanceListBasicFields,
        filters: { incomplete: true },
        pagination: { first: itemsDisplayedPerPage, page: pagination.page }
      })
    );
  }, [pagination.page]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const handlePerformanceTestChange = useCallback((test) => {
    setSelectedPerformanceTest(test);
  }, []);

  const handlePerformanceChangePage = useCallback(
    (page) => {
      dispatch(performanceSlice.actions.updatePagination({ page: page + 1 }));
    },
    [dispatch]
  );

  const handleTimeRangeChange = useCallback((range) => {
    setSelectedTimeRange(range);
  }, []);

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Stack spacing={4}>
        <DashboardBlock>
          <TopRankedPerformance
            data={topRankedPerformances}
            onPerformanceTestChange={handlePerformanceTestChange}
            onTimeRangeChange={handleTimeRangeChange}
            selectedPerformanceTest={selectedPerformanceTest}
            selectedTimeRange={selectedTimeRange}
            status={topRankedStatus}
          />
        </DashboardBlock>
        <DashboardBlock title={t('incompletePerformance', { ns: 'performances' })}>
          <LoadingBlock
            emptyBlockProps={{
              imageType: 'man'
            }}
            isValid={incompletePerformances.length > 0}
            skeletonProps={{
              rows: 5
            }}
            status={incompleteStatus}
            type="table">
            <IncompletePerformanceTable
              performances={incompletePerformances}
              onChangePage={handlePerformanceChangePage}
              pagination={pagination}
              paginatorInfo={paginatorInfo}
              rowsPerPage={itemsDisplayedPerPage}
            />
          </LoadingBlock>
        </DashboardBlock>
        <DashboardBlock
          title={t('latestNPerformance', { num: latestPerformanceFetchCount, ns: 'performances' })}>
          <LoadingBlock
            isValid={performances.length > 0}
            skeletonProps={{
              rows: itemsDisplayedPerPage
            }}
            status={performanceStatus}
            type="table">
            <LatestPerformanceTable
              performances={performances}
              rowsPerPage={latestPerformanceFetchCount}
            />
          </LoadingBlock>
        </DashboardBlock>
      </Stack>
    </>
  );
}

export default PerformanceDashboard;
