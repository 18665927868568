import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { enUS, es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import _capitalize from 'lodash/capitalize';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import SessionCard from 'components/shared/cards/session/SessionCard';
import SessionHint from 'components/session/calendar/SessionHint';
import { longFormatEN, longFormatES } from 'common/constants/date';
import StyledSessionDrawer from 'common/theme/session/drawer/StyledSessionDrawer';
import StyledSessionDrawerMessage from 'common/theme/session/drawer/StyledSessionDrawerMessage';
import StyledSessionDrawerSessionsContainer from 'common/theme/session/drawer/StyledSessionDrawerSessionsContainer';
import StyledSessionDrawerTitle from 'common/theme/session/drawer/StyledSessionDrawerTitle';
import { convertUtcToLocalTime } from 'common/utils/date';
import { getInteractionType } from 'common/utils/general';
import { isAdmin } from 'common/utils/user';

function SessionDrawer({ open, onClose, sessions }) {
  const { t, i18n } = useTranslation(['buttons', 'sessions']);
  const [date, setDate] = useState('');
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const dateFormat = i18n.language === 'es' ? longFormatES : longFormatEN;

  const firstName = useSelector((state) => state.auth.user.first_name);
  const role = useSelector((state) => state.auth.user.role);

  const isAdminUser = isAdmin(role);

  const [counters, setCounters] = useState({
    finished: 0,
    scheduled: 0
  });

  useEffect(() => {
    if (sessions.length) {
      const localDate = convertUtcToLocalTime(sessions[0].data.start_date, dateFormat, dateLocale);

      const sessionsCounters = sessions.reduce((acc, session) => {
        const statusKey = session.data.status.toLowerCase();
        acc[statusKey] = (acc[statusKey] || 0) + 1;

        return acc;
      }, {});

      setDate(localDate);
      setCounters(sessionsCounters);
    }
  }, [dateLocale, sessions]);

  return (
    <StyledSessionDrawer anchor="right" open={open} onClose={onClose}>
      <StyledSessionDrawerTitle variant="h2">
        <span>{_capitalize(date)}</span>
        <IconButton aria-label={t('close', { ns: 'buttons' })} onClick={onClose}>
          <Close />
        </IconButton>
      </StyledSessionDrawerTitle>
      {!isAdminUser && (
        <StyledSessionDrawerMessage>
          <p>{t('todaySessionsIntro', { name: firstName, date, ns: 'sessions' })}</p>
          <p>
            {counters.scheduled > 0 && (
              <span>
                {t('todaySessions.scheduled', {
                  num: counters.scheduled,
                  count: counters.scheduled,
                  ns: 'sessions'
                })}
              </span>
            )}
            {counters.finished > 0 && (
              <span>
                {t('todaySessions.finished', {
                  num: counters.finished,
                  count: counters.finished,
                  ns: 'sessions'
                })}
              </span>
            )}
          </p>
        </StyledSessionDrawerMessage>
      )}
      {!isAdminUser && (
        <SessionHint
          cookieName="sessionDrawerHint"
          message={_capitalize(t('drawerHint', { action: getInteractionType(), ns: 'sessions' }))}
        />
      )}
      <StyledSessionDrawerSessionsContainer>
        {sessions.map((session) => (
          <SessionCard data={session.data} options={session.options} key={session.id} />
        ))}
      </StyledSessionDrawerSessionsContainer>
    </StyledSessionDrawer>
  );
}

SessionDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SessionDrawer;
