import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import AppBarButtonLink from 'components/shared/buttons/AppBarButtonLink';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import MealPlanFilters from 'components/meal-plan/MealPlanFilters';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import { mealPlanListFields } from 'common/constants/query-fields/mealPlan';
import routes from 'common/routes';
import { getMealPlanColumns } from 'common/table/columns';
import { getMealPlanData } from 'common/table/data';
import { defaultTableOptions, getSelectToolBar, tableLocalization } from 'common/table/utils';
import { downloadFile } from 'common/utils/general';
import AppBarContext from 'context/AppBarContext';
import mealPlanSlice from 'store/modules/mealPlan';
import createMealPlanTemporaryUrl from 'http/mealPlan';

function MealPlanList() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['buttons', 'labels', 'mealPlans', 'routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const pageTitle = t('mealPlan.list', { ns: 'routes' });
  const emptyMessage = t('empty', { type: pageTitle.toLowerCase(), ns: 'tables' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const filters = useSelector((state) => state.mealPlan.filters);
  const mealPlans = useSelector((state) => state.mealPlan.data.mealPlans);
  const pagination = useSelector((state) => state.mealPlan.pagination);
  const paginatorInfo = useSelector((state) => state.mealPlan.paginatorInfo);
  const refresh = useSelector((state) => state.mealPlan.refresh);
  const status = useSelector((state) => state.mealPlan.status.general);

  const listMealPlans = useCallback(() => {
    dispatch(mealPlanSlice.actions.list({ fields: mealPlanListFields, filters, pagination }));
  }, [dispatch, filters, pagination]);

  useEffect(() => {
    listMealPlans();
  }, [listMealPlans, refresh]);

  useEffect(() => {
    setAppBarButton(
      <AppBarButtonLink label={t('add', { ns: 'buttons' })} path={routes.createMealPlan.path} />
    );
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onDownload = async (id) => {
    const signedUrl = await createMealPlanTemporaryUrl(id);
    downloadFile(signedUrl, t('mealPlanFilename', { ns: 'mealPlans' }));
  };

  const tableData = getMealPlanData(mealPlans, dateLocale);
  const columns = getMealPlanColumns({ data: tableData, translateFunc: t, onDownload });

  const onChangePage = (page) => {
    dispatch(mealPlanSlice.actions.updatePagination({ page: page + 1 }));
  };

  const onDelete = (ids) => {
    dispatch(mealPlanSlice.actions.remove(ids));
  };

  const onRestore = (ids) => {
    dispatch(mealPlanSlice.actions.restore(ids));
  };

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    customToolbarSelect: (selectedRows, displayData) =>
      getSelectToolBar({
        filters,
        rows: selectedRows,
        data: displayData,
        onDelete,
        onRestore
      }),
    onChangePage,
    page: pagination.page - 1,
    rowsPerPage: pagination.first,
    textLabels: tableLocalization(pageTitle.toLowerCase(), t)
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <MealPlanFilters />
      <Box
        sx={{
          position: 'relative'
        }}>
        <LoadingBlock
          emptyBlockProps={{ message: emptyMessage }}
          isValid={mealPlans.length > 0}
          skeletonProps={{ rows: 5 }}
          status={status}
          type="table">
          <MUIDataTable className="mealPlan" columns={columns} data={tableData} options={options} />
        </LoadingBlock>
      </Box>
    </>
  );
}

export default MealPlanList;
