import React from 'react';
import PropTypes from 'prop-types';
import OverviewProfileBasicDetailsSkeleton from 'components/shared/skeletons/OverviewProfileBasicDetailsSkeleton';
import UserAvatar from 'components/shared/user/UserAvatar';
import UserInfo from 'components/shared/user/UserInfo';
import StyledOverviewProfileDetailsContainer from 'common/theme/overview-profile/StyledOverviewProfileDetailsContainer';

function OverviewProfileBasicDetails({ user, loading }) {
  return (
    <StyledOverviewProfileDetailsContainer>
      {loading ? (
        <OverviewProfileBasicDetailsSkeleton />
      ) : (
        <>
          <UserAvatar size={200} user={user} />
          <UserInfo
            age={user.age}
            country={user.country}
            fullName={user.full_name}
            gender={user.gender}
            membershipStatus={user?.membership_status}
          />
        </>
      )}
    </StyledOverviewProfileDetailsContainer>
  );
}

OverviewProfileBasicDetails.defaultProps = {
  user: null
};

OverviewProfileBasicDetails.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default OverviewProfileBasicDetails;
