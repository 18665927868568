import trainerPayment from 'api/graphql/trainerPayment';

const getTrainerPaymentInfo = async ({ endDate, startDate, trainerId }) => {
  const response = await trainerPayment.trainerPaymentInfo({
    filters: {
      trainer_id: Number(trainerId),
      date_range: { start_date: startDate, end_date: endDate }
    }
  });

  const {
    data: { trainerPaymentInfo }
  } = response;

  return trainerPaymentInfo;
};

export default getTrainerPaymentInfo;
