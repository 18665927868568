import { styled } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';

const StyledProfilePictureIcon = styled(CameraAlt)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(30)
  }
}));

export default StyledProfilePictureIcon;
