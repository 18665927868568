import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import PerformanceHistoryChart from 'components/shared/charts/performance/history/PerformanceHistoryChart';
import Select from 'components/shared/inputs/Select';
import { getTimeRangeOptions, timeRange } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import StyledDashboardBlockTitle from 'common/theme/blocks/dashboard/StyledDashboardBlockTitle';
import StyledLatestPerformanceCardText from 'common/theme/cards/latest-performance/StyledLatestPerformanceCardText';
import StyledPerformanceHistoryChartGrid from 'common/theme/charts/performance-history/StyledPerformanceHistoryChartGrid';
import StyledPerformanceHistoryChartHeader from 'common/theme/charts/performance-history/StyledPerformanceHistoryChartHeader';
import { getDateRange } from 'common/utils/date';
import { hasNonEmptyPerformanceHistory } from 'common/utils/performance';
import performanceSlice from 'store/modules/performance';

function PerformanceHistoryCharts({ showTitle, userId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels', 'performances']);
  const [selectedTimeRange, setSelectedTimeRange] = useState(timeRange.last3Months);
  const timeRangeOptions = useMemo(() => getTimeRangeOptions(t), [t]);

  const history = useSelector((state) => state.performance.data.performanceHistory);
  const status = useSelector((state) => state.performance.status.history);

  const hasHistory = useMemo(() => history && hasNonEmptyPerformanceHistory(history), [history]);
  const isDisabled = status === requestStatus.loading;

  useEffect(() => {
    dispatch(
      performanceSlice.actions.getHistory({
        filters: {
          created_between: getDateRange(selectedTimeRange),
          user_id: userId
        }
      })
    );
  }, [selectedTimeRange, userId]);

  const handTimeRangeChange = useCallback((event) => {
    setSelectedTimeRange(event.target.value);
  }, []);

  return (
    <LoadingBlock
      emptyBlockProps={{ message: t('empty', { ns: 'performances' }) }}
      isValid={hasHistory}
      status={status}>
      <>
        <StyledPerformanceHistoryChartHeader showTitle={showTitle}>
          {showTitle && (
            <StyledDashboardBlockTitle>
              {t('performanceHistoric.title', { ns: 'performances' })}
            </StyledDashboardBlockTitle>
          )}
          <Select
            id="performance-time-range-select"
            isDisabled={isDisabled}
            label={t('timeRange', { ns: 'labels' })}
            onChange={handTimeRangeChange}
            options={timeRangeOptions}
            value={selectedTimeRange}
          />
        </StyledPerformanceHistoryChartHeader>
        <StyledLatestPerformanceCardText>
          {t('performanceHistoric.text', { ns: 'performances' })}
        </StyledLatestPerformanceCardText>
        <StyledPerformanceHistoryChartGrid>
          {Object.entries(history).map(([key, value]) => {
            if (value.length > 0) {
              return <PerformanceHistoryChart chartData={value} type={key} key={key} />;
            }
            return null;
          })}
        </StyledPerformanceHistoryChartGrid>
      </>
    </LoadingBlock>
  );
}

PerformanceHistoryCharts.defaultProps = {
  showTitle: true
};

PerformanceHistoryCharts.propTypes = {
  showTitle: PropTypes.bool,
  userId: PropTypes.number.isRequired
};

export default PerformanceHistoryCharts;
