import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import requestStatus from 'common/constants/status';
import StyledFilterButtonsContainer from 'common/theme/shared/StyledFilterButtonsContainer';

function FilterButtons({ resetForm, status }) {
  const { t } = useTranslation(['buttons']);

  return (
    <StyledFilterButtonsContainer>
      <SecondaryButtonLoading
        disabled={status === requestStatus.loading}
        minWidth={false}
        onClick={resetForm}
        label={t('reset', { ns: 'buttons' })}
        type="button"
      />
      <PrimaryLoadingButton
        disabled={status === requestStatus.loading}
        loading={false}
        minWidth={false}
        label={t('filter', { ns: 'buttons' })}
        type="submit"
      />
    </StyledFilterButtonsContainer>
  );
}

FilterButtons.propTypes = {
  resetForm: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default FilterButtons;
