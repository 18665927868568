import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DoubleCounter from 'components/shared/counters/double/DoubleCounter';
import Emoji from 'components/shared/Emoji';

function TodaySessionsCounters() {
  const { t } = useTranslation(['sessions']);

  const todayMorningSessionsCounter = useSelector((state) => state.session.counters.todayMorning);
  const todayAfternoonSessionsCounter = useSelector(
    (state) => state.session.counters.todayAfternoon
  );

  return (
    <DoubleCounter
      emoji={<Emoji label={t('sessionsToday', { ns: 'sessions' })} symbol="🕛" />}
      title={t('sessionsToday', { ns: 'sessions' })}
      counters={[
        {
          title: t('tomorrow', { ns: 'common' }),
          counter: todayMorningSessionsCounter
        },
        {
          title: t('afternoonNight', { ns: 'common' }),
          counter: todayAfternoonSessionsCounter
        }
      ]}
    />
  );
}

export default TodaySessionsCounters;
