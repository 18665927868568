import { ListItemText, styled } from '@mui/material';
import { typographyClasses } from '@mui/material/Typography';

const StyledMainMenuListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.menuItem.main,
  marginLeft: '20px',
  transition: 'color .3s ease-in-out',
  [`& .${typographyClasses.root}`]: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  }
}));

export default StyledMainMenuListItemText;
