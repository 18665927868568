import adminRoutes from 'common/routes/adminRoutes';
import authRoutes from 'common/routes/authRoutes';
import availabilityRoutes from 'common/routes/availabilityRoutes';
import clientRoutes from 'common/routes/clientRoutes';
import mealPlanRoutes from 'common/routes/mealPlanRoutes';
import newsRoutes from 'common/routes/newsRoutes';
import notificationRoutes from 'common/routes/notificationRoutes';
import paymentRoutes from 'common/routes/paymentRoutes';
import performanceRoutes from 'common/routes/performanceRoutes';
import planRoutes from 'common/routes/planRoutes';
import legalRoutes from 'common/routes/legalRoutes';
import progressRoutes from 'common/routes/progressRoutes';
import sessionRoutes from 'common/routes/sessionRoutes';
import sessionTypeRoutes from 'common/routes/sessionTypeRoutes';
import trainerRoutes from 'common/routes/trainerRoutes';
import userRoutes from 'common/routes/userRoutes';

const routes = {
  ...adminRoutes,
  ...authRoutes,
  ...availabilityRoutes,
  ...clientRoutes,
  ...legalRoutes,
  ...mealPlanRoutes,
  ...newsRoutes,
  ...notificationRoutes,
  ...paymentRoutes,
  ...performanceRoutes,
  ...planRoutes,
  ...progressRoutes,
  ...sessionRoutes,
  ...sessionTypeRoutes,
  ...trainerRoutes,
  ...userRoutes
};

export default routes;
