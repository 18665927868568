import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { InputAdornment, Unstable_Grid2 as Grid } from '@mui/material';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import { currencyOptions } from 'common/constants/options';
import { isRequiredField } from 'common/utils/general';

function SessionTypeFields({ requiredFields }) {
  const { t } = useTranslation(['labels']);

  return (
    <Grid container spacing={4}>
      <Grid xs={12} sm={4}>
        <Field
          id="name"
          name="name"
          component={TextFieldAdapter}
          label={t('name', { ns: 'labels' })}
          type="text"
          required={isRequiredField('name', requiredFields)}
          fullWidth
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <Field
          id="price"
          name="price"
          component={TextFieldAdapter}
          label={t('price', { ns: 'labels' })}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
          required={isRequiredField('price', requiredFields)}
          fullWidth
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <Field
          id="currency"
          name="currency"
          component={SelectFieldAdapter}
          options={currencyOptions}
          label={t('currency', { ns: 'labels' })}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

SessionTypeFields.propTypes = {
  requiredFields: PropTypes.array.isRequired
};

export default SessionTypeFields;
