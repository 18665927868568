import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import MembershipStatusChip from 'components/shared/chips/MembershipStatusChip';
import StyledClientOptionChip from 'common/theme/chips/StyledClientOptionChip';

function ClientOption({ option, props }) {
  const { t } = useTranslation(['sessions']);
  const {
    available_sessions: availableSessions,
    gender,
    label,
    membership_status: membershipStatus
  } = option;

  return (
    <Box component="li" {...props}>
      <Typography sx={{ marginRight: (theme) => theme.spacing(2) }}>{label}</Typography>
      <MembershipStatusChip context={gender.toLowerCase()} status={membershipStatus} />
      <StyledClientOptionChip
        label={`${availableSessions} ${t('availableSessions', { ns: 'sessions' })}`}
      />
    </Box>
  );
}

ClientOption.propTypes = {
  option: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired
};

export default ClientOption;
