import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AccountBoxMultiple, ChartBoxOutline } from 'mdi-material-ui';
import { Box, Tab, Tabs, Skeleton } from '@mui/material';
import PerformanceHistoryCharts from 'components/shared/charts/performance/history/PerformanceHistoryCharts';
import ProgressGallery from 'components/shared/galleries/progress/ProgressGallery';
import TabPanel from 'components/shared/tabs/TabPanel';
import { tabsA11yProps } from 'common/utils/general';

function ClientTabs({ client, loading }) {
  const { t } = useTranslation(['progress', 'routes']);
  const [currentTab, setCurrentTab] = useState(0);
  const clientId = Number(client?.id);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        textColor="inherit"
        indicatorColor="secondary"
        variant="fullWidth"
        centered>
        <Tab
          label={t('performance.list', { ns: 'routes' })}
          icon={<ChartBoxOutline fontSize="large" />}
          {...tabsA11yProps(0)}
        />
        <Tab
          label={t('progress.list', { ns: 'routes' })}
          icon={<AccountBoxMultiple fontSize="large" />}
          {...tabsA11yProps(1)}
        />
      </Tabs>
      <TabPanel current={currentTab} index={0}>
        {loading ? (
          <>
            <Skeleton height={200} />
            <Skeleton height={200} />
          </>
        ) : (
          <PerformanceHistoryCharts showTitle={false} userId={clientId} />
        )}
      </TabPanel>
      <TabPanel current={currentTab} index={1}>
        {loading ? (
          <>
            <Skeleton height={200} />
            <Skeleton height={200} />
          </>
        ) : (
          <ProgressGallery clientId={clientId} />
        )}
      </TabPanel>
    </Box>
  );
}

ClientTabs.defaultProps = {
  client: null
};

ClientTabs.propTypes = {
  client: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default ClientTabs;
