import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import MUIDataTable from 'mui-datatables';
import { roles } from 'common/constants/options';
import { getClientPaymentBasicColumns } from 'common/table/columns/clientPayment';
import { getTrainerPaymentBasicColumns } from 'common/table/columns';
import { getTrainerPaymentBasicData } from 'common/table/data';
import { getClientPaymentBasicData } from 'common/table/data/clientPayment';
import { defaultTableOptions, tableLocalization } from 'common/table/utils';

function PaymentsTable({ payments, role, rowsPerPage }) {
  const { t, i18n } = useTranslation(['routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;

  const tableData =
    role === roles.client
      ? getClientPaymentBasicData(payments, dateLocale)
      : getTrainerPaymentBasicData(payments, dateLocale);

  const columns =
    role === roles.client
      ? getClientPaymentBasicColumns({ data: tableData, translateFunc: t })
      : getTrainerPaymentBasicColumns({ data: tableData, translateFunc: t });

  const options = {
    ...defaultTableOptions,
    jumpToPage: false,
    rowsPerPage,
    selectableRows: 'none',
    serverSide: false,
    textLabels: tableLocalization(t('payment.payments', { ns: 'routes' }).toLowerCase(), t)
  };

  return (
    <MUIDataTable className="paymentsBasic" columns={columns} data={tableData} options={options} />
  );
}

PaymentsTable.propTypes = {
  payments: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default PaymentsTable;
