import { gql } from '@apollo/client';
import client from 'apollo/client';
import { getSessionTypeFilters } from 'common/filters/requestFilters';

/**
 * Create session type method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createSessionType($input: CreateSessionTypeInput!) {
        createSessionType(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Find session type method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query sessionType($id: ID!) {
        sessionType(id: $id) {
          id
          currency
          name
          price
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * List session types method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { pagination, filters } = payload;
  const sessionTypeFilters = getSessionTypeFilters(filters);

  return client.query({
    query: gql`
      query sessionTypes {
        sessionTypes(
          first: ${pagination.first}
          page: ${pagination.page}
          ${sessionTypeFilters.currency}
          ${sessionTypeFilters.soft_delete}
          orderBy: {
            column: NAME,
            order: ASC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            id
            currency
            name
            price
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * List all session types method
 * @returns {Promise}
 */
const listAll = () =>
  client.query({
    query: gql`
      query sessionTypesAll {
        sessionTypesAll {
          id
          name
        }
      }
    `,
    fetchPolicy: 'network-only'
  });

/**
 * Remove session types method
 * @param  {Array} ids
 * @return {Promise}
 */
const remove = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deleteSessionTypes($id: [ID!]!) {
        deleteSessionTypes(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Restore session types method
 * @param  {Array} ids
 * @return {Promise}
 */
const restore = (ids) =>
  client.mutate({
    mutation: gql`
      mutation restoreSessionTypes($id: [ID!]!) {
        restoreSessionTypes(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Update session type method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateSessionType($id: ID!, $input: UpdateSessionTypeInput!) {
        updateSessionType(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  create,
  find,
  list,
  listAll,
  remove,
  restore,
  update
};
