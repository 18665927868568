import { Box, styled } from '@mui/material';

const StyledDashboardBlockHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4)
  }
}));

export default StyledDashboardBlockHeader;
