import { Typography, styled } from '@mui/material';

const StyledPaymentRowText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '&:last-child': {
    marginBottom: 0
  }
}));

export default StyledPaymentRowText;
