import { Typography, styled } from '@mui/material';

const StyledCounterLine = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(11),
  fontWeight: 500,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(14)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(16)
  }
}));

export default StyledCounterLine;
