import React from 'react';
import _camelCase from 'lodash/camelCase';
import { Box, styled } from '@mui/material';

const StyledMonthlySessionEventIndicator = styled(({ status, ...otherProps }) => (
  <Box {...otherProps} />
))(({ status, theme }) => ({
  backgroundColor: theme.palette[_camelCase(status)].main,
  borderRadius: '50%',
  flexShrink: 0,
  height: '8px',
  marginRight: theme.spacing(1),
  width: '8px'
}));

export default StyledMonthlySessionEventIndicator;
