import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    notifications: []
  },
  reducers: {
    enqueueSnackbar(state, action) {
      state.notifications = [
        ...state.notifications,
        {
          key: action.payload.key,
          ...action.payload.notification
        }
      ];
    },
    closeSnackbar(state, action) {
      state.notifications = state.notifications.map((notification) =>
        action.payload.dismissAll || notification.key === action.payload.key
          ? { ...notification, dismissed: true }
          : notification
      );
    },
    removeSnackbar(state, action) {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== action.payload
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => ({
      notifications: []
    }));
  }
});

export default uiSlice;
