import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ProgressEditFields from 'components/progress/ProgressEditFields';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import defaultProgressFormValidations from 'common/validations/form/defaultProgress';
import routes from 'common/routes';
import requestStatus from 'common/constants/status';
import { updateProgressInitialValues } from 'common/form/initial-values/update';
import getDataToSave from 'common/form/dataToSave';
import { getFieldsToUpdate } from 'common/form/utils';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import { handleClientIdChange } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import progressSlice from 'store/modules/progress';

const requiredFields = [
  'back_picture',
  'created_at',
  'end_date',
  'front_picture',
  'height',
  'profile_picture',
  'start_date',
  'user_id',
  'weight'
];

function ProgressEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['routes']);
  const pageTitle = t('progress.edit', { ns: 'routes' });
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({});
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const clientOptions = useSelector((state) => state.client.data.search);
  const progress = useSelector((state) => state.progress.data.currentProgress);
  const searchStatus = useSelector((state) => state.client.status.search);
  const status = useSelector((state) => state.progress.status);

  useEffect(() => {
    dispatch(progressSlice.actions.find(id));
  }, []);

  useEffect(() => {
    if (progress && Object.keys(progress).length > 0 && progress.id === id) {
      const progressData = updateProgressInitialValues(progress);
      setInitialValues(progressData);
    }
  }, [progress]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onClientIdChange = handleClientIdChange(dispatch);

  const onEdit = useCallback(
    (values) => {
      const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
      const dataToSave = getDataToSave(fieldsToUpdate, 'progress');
      dispatch(progressSlice.actions.update({ id, input: dataToSave }));
    },
    [dispatch, id, initialValues]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={initialValues}
        onSubmit={onEdit}
        validate={defaultProgressFormValidations}
        render={({
          handleSubmit,
          dirtyFields,
          initialValues: formInitialValues,
          invalid,
          pristine
        }) => (
          <form onSubmit={handleSubmit}>
            <ProgressEditFields
              clientOptions={clientOptions}
              dirtyFields={dirtyFields}
              initialValues={formInitialValues}
              onClientIdChange={onClientIdChange}
              requiredFields={requiredFields}
              searchStatus={searchStatus}
            />
            <StyledButtonsContainer>
              <SecondaryButtonLoading
                component={RouterLink}
                disabled={status === requestStatus.loading}
                style={{ marginRight: '32px' }}
                label={t('cancel', { ns: 'buttons' })}
                to={routes.listProgress.path}
                type="button"
              />
              <PrimaryLoadingButton
                disabled={invalid || pristine || status === requestStatus.loading}
                loading={status === requestStatus.loading}
                label={t('progress.edit', { ns: 'buttons' })}
                type="submit"
              />
            </StyledButtonsContainer>
          </form>
        )}
      />
    </>
  );
}

export default ProgressEdit;
