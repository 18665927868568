import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ArrowCircleDown } from '@mui/icons-material';
import { Button } from '@mui/material';

function DownloadButton({ id, onClick }) {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick(id);
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<ArrowCircleDown />}
      onClick={handleClick}>
      {t('download', { ns: 'common' })}
    </Button>
  );
}

DownloadButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default DownloadButton;
