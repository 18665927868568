import { Skeleton, styled } from '@mui/material';

export const StyledNewsDateSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize
}));

export const StyledNewsImageSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: '8px',
  margin: `${theme.spacing(5)} 0`,
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px'
  }
}));

export const StyledNewsTitleSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(44)
}));
