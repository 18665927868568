import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import uiSlice from 'store/modules/ui';

function SnackCloseButton({ snackKey = new Date().getTime() + Math.random() }) {
  const dispatch = useDispatch();

  const onCloseSnack = () => {
    dispatch(uiSlice.actions.closeSnackbar({ key: snackKey }));
  };

  return (
    <IconButton
      onClick={onCloseSnack}
      sx={{
        color: 'common.white'
      }}>
      <Close />
    </IconButton>
  );
}

SnackCloseButton.propTypes = {
  snackKey: PropTypes.string.isRequired
};

export default SnackCloseButton;
