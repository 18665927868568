import styledComponent from 'styled-components';

const StyledProfilePicturePreview = styledComponent.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('${(props) => props.$url}');
  height: 100%;
  width: 100%;
`;

export default StyledProfilePicturePreview;
