/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function DaysLeftColumn({ data }) {
  const { t } = useTranslation();
  const { membership_days_left: daysLeft } = data;

  return daysLeft >= 0 ? (
    <Typography>
      {daysLeft > 1 || daysLeft === 0
        ? `${daysLeft} ${t('day', { count: 2, ns: 'common' }).toLowerCase()}`
        : `${daysLeft} ${t('day', { count: 1, ns: 'common' }).toLowerCase()}`}
    </Typography>
  ) : (
    <Typography>-</Typography>
  );
}

DaysLeftColumn.propTypes = {
  data: PropTypes.object.isRequired
};

export default DaysLeftColumn;
