import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StyledChip from 'common/theme/chips/StyledChip';

function PerformanceTestColumn({ data, name }) {
  const { t } = useTranslation();

  const getColumn = (performanceTest, columnName) =>
    performanceTest[columnName] ? (
      performanceTest[columnName]
    ) : (
      <StyledChip color="error" label={t('pending', { ns: 'common' })} />
    );

  return getColumn(data, name);
}

PerformanceTestColumn.propTypes = {
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default PerformanceTestColumn;
