import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import StyledPrimaryLoadingButton from 'common/theme/button/StyledPrimaryLoadingButton';

function PrimaryLoadingButton({ loading, minWidth, onClick, label, ...rest }) {
  return (
    <StyledPrimaryLoadingButton
      {...rest}
      size="small"
      onClick={onClick}
      loading={loading}
      loadingIndicator={<CircularProgress color="white" size={16} />}
      variant="contained">
      <span>{label}</span>
    </StyledPrimaryLoadingButton>
  );
}

PrimaryLoadingButton.defaultProps = {
  minWidth: true,
  onClick: () => {}
};

PrimaryLoadingButton.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  minWidth: PropTypes.bool,
  onClick: PropTypes.func
};

export default PrimaryLoadingButton;
