import { Box, styled } from '@mui/material';

const StyledSessionCardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray0.main,
  borderRadius: '4px',
  columnGap: theme.spacing(2),
  display: 'flex',
  padding: theme.spacing(1.5),
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    borderRadius: '5px'
  }
}));

export default StyledSessionCardContainer;
