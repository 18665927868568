import React from 'react';
import { Skeleton } from '@mui/material';
import { StyledNewsTitleSkeleton } from 'common/theme/skeleton';

const getBodySkeleton = () =>
  Array.from({ length: 20 }, (_, i) => <Skeleton key={i} variant="text" width="100%" />);

function LegalSkeleton() {
  return (
    <>
      <StyledNewsTitleSkeleton variant="text" width="50%" sx={{ margin: 'auto' }} />
      {getBodySkeleton()}
    </>
  );
}

export default LegalSkeleton;
