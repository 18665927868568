import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { Box, Divider, Skeleton, Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import GoBackButton from 'components/shared/buttons/GoBackButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import UserInfo from 'components/shared/user/UserInfo';
import UserAvatar from 'components/shared/user/UserAvatar';
import { dateFormat } from 'common/constants/date';
import requestStatus from 'common/constants/status';
import StyledInfoContainer from 'common/theme/containers/StyledInfoContainer';
import StyledProgressGalleryHighlightImage from 'common/theme/galleries/progress/StyledProgressGalleryHighlightImage';
import AppBarContext from 'context/AppBarContext';
import progressSlice from 'store/modules/progress';

function ProgressView() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['labels', 'routes']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const { id } = useParams();
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const pageTitle = t('progress.view', { ns: 'routes' });

  const progress = useSelector((state) => state.progress.data.currentProgress);
  const status = useSelector((state) => state.progress.status);

  useEffect(() => {
    dispatch(progressSlice.actions.find(id));
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle('');
  }, [setAppBarButton, setAppBarTitle]);

  const isProgressDataAvailable = status !== requestStatus.loading && progress !== null;

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <GoBackButton />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginBottom: '45px'
        }}>
        {isProgressDataAvailable ? (
          <>
            <UserAvatar size={233} user={progress.user_id} />
            <UserInfo
              age={progress.user_id.age}
              country={progress.user_id.country}
              fullName={progress.user_id.full_name}
              gender={progress.user_id.gender}
              height={progress.height}
              membershipStatus={progress.user_id.membership_status}
            />
          </>
        ) : (
          <>
            <Skeleton variant="circular" width={233} height={233} />
            <Box sx={{ marginLeft: '36px' }}>
              <Skeleton width={203} height={56} sx={{ marginBottom: '10px' }} />
              <Stack sx={{ marginBottom: '15px' }}>
                <Skeleton width={203} height={30} />
                <Skeleton width={203} height={30} />
                <Skeleton width={203} height={30} />
                <Skeleton width={203} height={30} />
              </Stack>
              <Skeleton width={32} height={32} variant="circular" />
            </Box>
          </>
        )}
      </Box>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          fontWeight: 500
        }}>
        {t('progressDetails', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      {isProgressDataAvailable ? (
        <Grid container spacing={2} sx={{ marginBottom: '45px' }}>
          <Grid xs={6} md={4}>
            <StyledInfoContainer>
              <Typography sx={{ fontWeight: 500 }}>
                {t('weight', { ns: 'labels' })}:{' '}
                <Typography component="span">{progress.weight} kg</Typography>
              </Typography>
            </StyledInfoContainer>
          </Grid>
          <Grid xs={6} md={4}>
            <StyledInfoContainer>
              <Typography sx={{ fontWeight: 500 }}>
                {t('height', { ns: 'labels' })}:{' '}
                <Typography component="span">{progress.height} mts</Typography>
              </Typography>
            </StyledInfoContainer>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={6} sx={{ marginBottom: '45px' }}>
          <Grid xs={6} md={3}>
            <Skeleton height={56} />
          </Grid>
          <Grid xs={6} md={3}>
            <Skeleton height={56} />
          </Grid>
        </Grid>
      )}
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          fontWeight: 500
        }}>
        {t('progressPhotos', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      {isProgressDataAvailable ? (
        <Grid container spacing={4} sx={{ marginBottom: '45px' }}>
          <Grid xs={12} sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: (theme) => theme.typography.pxToRem(20), fontWeight: 600 }}>
              {t('periodRange', {
                from: format(parseISO(progress.start_date), dateFormat, { locale: dateLocale }),
                to: format(parseISO(progress.end_date), dateFormat, { locale: dateLocale }),
                ns: 'labels'
              })}
            </Typography>
          </Grid>
          <Grid xs={6} md={4} sx={{ textAlign: 'center' }}>
            <StyledProgressGalleryHighlightImage
              src={`${progress.profile_picture}?w=248&fit=crop&auto=format`}
              srcSet={`${progress.profile_picture}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={t('profile', { ns: 'labels' })}
              loading="lazy"
            />
          </Grid>
          <Grid xs={6} md={4} sx={{ textAlign: 'center' }}>
            <StyledProgressGalleryHighlightImage
              src={`${progress.front_picture}?w=248&fit=crop&auto=format`}
              srcSet={`${progress.front_picture}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={t('front', { ns: 'labels' })}
              loading="lazy"
            />
          </Grid>
          <Grid xs={6} md={4} sx={{ textAlign: 'center' }}>
            <StyledProgressGalleryHighlightImage
              src={`${progress.back_picture}?w=248&fit=crop&auto=format`}
              srcSet={`${progress.back_picture}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={t('back', { ns: 'labels' })}
              loading="lazy"
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={4} sx={{ marginBottom: '45px' }}>
          <Grid xs={12} sx={{ textAlign: 'center' }}>
            <Skeleton width={315} height={30} />
          </Grid>
          <Grid xs={6} md={4}>
            <Skeleton height={600} />
          </Grid>
          <Grid xs={6} md={4}>
            <Skeleton height={600} />
          </Grid>
          <Grid xs={6} md={4}>
            <Skeleton height={600} />
          </Grid>
        </Grid>
      )}
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          fontWeight: 500
        }}>
        {t('observations', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      {isProgressDataAvailable ? (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.gray0.main,
            borderRadius: '10px',
            minHeight: '105px',
            padding: '20px 12px'
          }}>
          {progress.observations}
        </Box>
      ) : (
        <Skeleton height={145} />
      )}
    </>
  );
}

export default ProgressView;
