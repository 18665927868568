import { Typography, styled } from '@mui/material';

const StyledSingleCounterTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(18)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(20)
  }
}));

export default StyledSingleCounterTitle;
