import {
  hasValue,
  isText,
  isValidDate,
  positiveFloat,
  positiveInt,
  positiveIntZero
} from 'common/validations/field';
import i18n from 'i18n';

const defaultPerformanceFormValidations = (values) => {
  const errors = {};

  if (hasValue(values.burpees_repetitions) && !positiveInt(values.burpees_repetitions)) {
    errors.burpees_repetitions = i18n.t('positiveInteger', { ns: 'validations' });
  }

  if (!isValidDate(values.created_at)) {
    errors.created_at = i18n.t('date', { ns: 'validations' });
  }

  if (hasValue(values.observations) && !isText(values.observations)) {
    errors.observations = i18n.t('text', { ns: 'validations' });
  }

  if (hasValue(values.plank_time_minutes) && !positiveIntZero(values.plank_time_minutes)) {
    errors.plank_time_minutes = i18n.t('positiveIntegerZero', { ns: 'validations' });
  }

  if (!hasValue(values.plank_time_minutes) && hasValue(values.plank_time_seconds)) {
    errors.plank_time_minutes = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.plank_time_minutes) && !hasValue(values.plank_time_seconds)) {
    errors.plank_time_seconds = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.plank_time_seconds) && !positiveIntZero(values.plank_time_seconds)) {
    errors.plank_time_seconds = i18n.t('positiveIntegerZero', { ns: 'validations' });
  }

  if (hasValue(values.pull_ups_repetitions) && !positiveInt(values.pull_ups_repetitions)) {
    errors.pull_ups_repetitions = i18n.t('positiveInteger', { ns: 'validations' });
  }

  if (hasValue(values.push_up_repetitions) && !positiveInt(values.push_up_repetitions)) {
    errors.push_up_repetitions = i18n.t('positiveInteger', { ns: 'validations' });
  }

  if (hasValue(values.running_distance) && !positiveFloat(values.running_distance)) {
    errors.running_distance = i18n.t('positiveFloat', { ns: 'validations' });
  }

  if (hasValue(values.squats_repetitions) && !positiveInt(values.squats_repetitions)) {
    errors.squats_repetitions = i18n.t('positiveInteger', { ns: 'validations' });
  }

  if (!isValidDate(values.start_date)) {
    errors.start_date = i18n.t('date', { ns: 'validations' });
  }

  if (!hasValue(values.user_id)) {
    errors.user_id = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!isValidDate(values.end_date)) {
    errors.end_date = i18n.t('date', { ns: 'validations' });
  }

  return errors;
};

export default defaultPerformanceFormValidations;
