export const progressListFields = `
  id
  created_at
  end_date
  start_date
  user_id {
    full_name
    first_name
    last_name
    photo
  }
`;

export const progressListGalleryFields = `
  id
  back_picture
  created_at
  front_picture
  profile_picture
`;
