import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Divider, Unstable_Grid2 as Grid } from '@mui/material';
import MultipleSelectFieldAdapter from 'components/shared/field-adapters/MultipleSelectFieldAdapter';
import TextAreaFieldAdapter from 'components/shared/field-adapters/TextAreaFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import { getLanguageOptions } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import StyledProfileTabPanelTitle from 'common/theme/profile/StyledProfileTabPanelTitle';

function ProfessionalTabPanel({ sessionTypeOptions, sessionTypeStatus }) {
  const { t } = useTranslation(['labels']);

  return (
    <>
      <StyledProfileTabPanelTitle component="h4">
        {t('professionalInformation', { ns: 'labels' })}
      </StyledProfileTabPanelTitle>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={6}>
          <Field
            id="specialties"
            name="specialties"
            component={MultipleSelectFieldAdapter}
            options={sessionTypeOptions}
            label={t('specialties', { ns: 'labels' })}
            fullWidth
            required
            disabled={sessionTypeStatus === requestStatus.loading}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="languages"
            name="languages"
            component={MultipleSelectFieldAdapter}
            options={getLanguageOptions(t)}
            label={t('languagesLong', { ns: 'labels' })}
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12}>
          <Field
            id="online_meeting_url"
            name="online_meeting_url"
            component={TextFieldAdapter}
            label={t('onlineMeetingUrl', { ns: 'labels' })}
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12}>
          <Field
            id="academic_formation"
            name="academic_formation"
            component={TextAreaFieldAdapter}
            label={t('academicFormation', { ns: 'labels' })}
            helperText={t('text', { ns: 'validations' })}
            multiline
            minRows={5}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12}>
          <Field
            id="experience"
            name="experience"
            component={TextAreaFieldAdapter}
            label={t('workExperience', { ns: 'labels' })}
            helperText={t('text', { ns: 'validations' })}
            multiline
            minRows={5}
            type="text"
            fullWidth
            required
          />
        </Grid>
      </Grid>
    </>
  );
}

ProfessionalTabPanel.propTypes = {
  sessionTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  sessionTypeStatus: PropTypes.string.isRequired
};

export default ProfessionalTabPanel;
