import { takeLatest, put } from 'redux-saga/effects';
import legalSlice from 'store/modules/legal';
import uiSlice from 'store/modules/ui';
import legal from 'api/graphql/legal';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import i18n from 'i18n';

/**
 * Create legal saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'legal-create-notification-success',
      variant: 'success',
      message: i18n.t('legal.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield legal.create(payload);
    yield put(legalSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(legalSlice.actions.createError(message));
  }
}

/**
 * Find legal saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield legal.find(action.payload);
    const {
      data: { legal: currentLegal }
    } = response;

    yield put(legalSlice.actions.findSuccess(currentLegal));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(legalSlice.actions.findError(message));
  }
}

/**
 * Force delete legal saga
 *
 * @param {*} action
 */
function* forceDelete(action) {
  try {
    const snack = createSnack({
      key: 'force-delete-legal-notification-success',
      variant: 'success',
      message: i18n.t('legal.delete', { ns: 'notifications' })
    });

    yield legal.forceDelete(action.payload);
    yield put(legalSlice.actions.forceDeleteSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(legalSlice.actions.forceDeleteError(message));
  }
}

/**
 * Get latest legal by role saga
 *
 * @param {*} action
 */
function* getLatestByRole(action) {
  try {
    const response = yield legal.getLatestByRole(action.payload);
    const {
      data: { latestLegalByRole: currentLegal }
    } = response;

    yield put(legalSlice.actions.getLatestByRoleSuccess(currentLegal));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(legalSlice.actions.getLatestByRoleError(message));
  }
}

/**
 * List legal saga
 *
 * @param {*} action
 */
function* list(action) {
  try {
    const { fields, filters = {}, pagination } = action.payload;
    const response = yield legal.list({ fields, filters, pagination });

    const {
      data: {
        legals: { data, paginatorInfo }
      }
    } = response;

    yield put(legalSlice.actions.listSuccess(data));
    yield put(
      legalSlice.actions.updatePaginatorInfo({
        hasMorePages: paginatorInfo.hasMorePages,
        lastPage: paginatorInfo.lastPage,
        total: paginatorInfo.total
      })
    );
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(legalSlice.actions.listError(message));
  }
}

/**
 * Update legal saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-legal-notification-success',
      variant: 'success',
      message: i18n.t('legal.update', { ns: 'notifications' })
    });

    yield legal.update(action.payload);

    yield put(legalSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(legalSlice.actions.updateError(message));
  }
}

/**
 * Watch legal
 *
 * @export
 */
export default function* watchLegal() {
  yield takeLatest(legalSlice.actions.create, create);
  yield takeLatest(legalSlice.actions.find, find);
  yield takeLatest(legalSlice.actions.forceDelete, forceDelete);
  yield takeLatest(legalSlice.actions.getLatestByRole, getLatestByRole);
  yield takeLatest(legalSlice.actions.list, list);
  yield takeLatest(legalSlice.actions.update, update);
}
