import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import routerSlice from 'store/modules/router';

function RouteSync() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPage = useSelector((state) => state.router.current);

  useEffect(() => {
    if (location.pathname !== currentPage) {
      dispatch(routerSlice.actions.updateUrl(location.pathname));
    }
  }, [dispatch, location]);
}

export default RouteSync;
