import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Unstable_Grid2 as Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import UserAvatar from 'components/shared/user/UserAvatar';
import requestStatus from 'common/constants/status';
import StyledTrainerSelectButton from 'common/theme/fields/trainer-select/StyledTrainerSelectButton';
import StyledTrainerSelectChip from 'common/theme/fields/trainer-select/StyledTrainerSelectChip';
import StyledTrainerSelectGridContainer from 'common/theme/fields/trainer-select/StyledTrainerSelectGridContainer';

function TrainerSelectFieldAdapter({ disabled, emptyMessage, input, status, trainers }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const { value } = input;

  const hasInitialValue =
    (value &&
      typeof value === 'object' &&
      !Array.isArray(value) &&
      Object.keys(value).length > 0) ||
    false;

  const isValid = trainers.length > 0 || hasInitialValue;
  const trainerStatus = status === requestStatus.idle ? requestStatus.success : status;

  useEffect(() => {
    if (hasInitialValue) {
      input.onChange(value.id);
    }
  }, [value]);

  const onTrainerSelect = (event) => {
    const {
      currentTarget: {
        dataset: { trainerId }
      }
    } = event;

    if (value !== trainerId) {
      input.onChange(trainerId);
    }
  };

  return (
    <LoadingBlock
      emptyBlockProps={{ imageType: 'text', message: emptyMessage }}
      isValid={isValid}
      status={trainerStatus}
      type="trainerSelect">
      <StyledTrainerSelectGridContainer container spacing={2}>
        {hasInitialValue && trainers.length === 0 ? (
          <Grid xs={12} md={3} key={value.id}>
            <StyledTrainerSelectButton selected>
              <StyledTrainerSelectChip
                avatar={
                  <UserAvatar
                    size={isMedium ? 30 : 24}
                    textSize={isMedium ? 15 : 12}
                    user={value}
                  />
                }
                label={value.full_name}
                onClick={onTrainerSelect}
                data-trainer-id={value.id}
              />
            </StyledTrainerSelectButton>
          </Grid>
        ) : (
          trainers.map((trainer) => (
            <Grid xs={12} md={3} key={trainer.id}>
              <StyledTrainerSelectButton selected={trainer.id === input.value}>
                <StyledTrainerSelectChip
                  avatar={
                    <UserAvatar
                      size={isMedium ? 30 : 24}
                      textSize={isMedium ? 15 : 12}
                      user={trainer}
                    />
                  }
                  label={trainer.full_name}
                  onClick={onTrainerSelect}
                  data-trainer-id={trainer.id}
                  disabled={disabled}
                />
              </StyledTrainerSelectButton>
            </Grid>
          ))
        )}
      </StyledTrainerSelectGridContainer>
    </LoadingBlock>
  );
}

TrainerSelectFieldAdapter.defaultProps = {
  disabled: false
};

TrainerSelectFieldAdapter.propTypes = {
  disabled: PropTypes.bool,
  emptyMessage: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  trainers: PropTypes.array.isRequired
};

export default TrainerSelectFieldAdapter;
