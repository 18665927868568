import createDecorator from 'final-form-calculate';

/**
 * Creates decorators for a performance form. These decorators are used to update related fields
 * in response to changes in other fields.
 *
 * @param {Object} decorators - An object defining the decorators.
 * @returns {Function} A function that applies the specified decorators.
 */
export const performanceFormDecorators = createDecorator(
  {
    field: 'plank_time_minutes',
    updates: {
      plank_time_seconds: (plankMinutesValue, allValues) => {
        const seconds = allValues?.plank_time_seconds ?? null;
        return seconds === null ? '0' : seconds;
      }
    }
  },
  {
    field: 'plank_time_seconds',
    updates: {
      plank_time_minutes: (plankSecondsValue, allValues) => {
        const minutes = allValues?.plank_time_minutes ?? null;
        return minutes === null ? '0' : minutes;
      }
    }
  }
);

/**
 * Checks if the performance history object has any non-empty entries.
 *
 * @param {Object} history - The performance history object to check.
 * @returns {boolean} True if there is at least one non-empty entry, false otherwise.
 */
export const hasNonEmptyPerformanceHistory = (history) => {
  const historyKeys = Object.keys(history);
  return historyKeys.some((key) => history[key].length > 0);
};
