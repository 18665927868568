import styledComponent from 'styled-components';
import palette from 'common/theme/palette';

const StyledImageUploadButton = styledComponent.button`
  align-items: center;
  background-color: ${palette.gray2.main};
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 0;
  height: 100%;
  justify-content: center;
  min-height: 250px;
  padding: 12px;
  transition: border-color 150ms ease-in-out;
  width: 100%;

  &:hover {
    border-color: ${palette.secondary.main};

    & svg {
      transform: scale(1.1);
    }
  }

  &:disabled {
    cursor: not-allowed;
    &:hover {
      border-color: transparent;
    }
  }
`;

export default StyledImageUploadButton;
