import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TrainerFields from 'components/trainer/TrainerFields';
import validateCreateTrainerForm from 'common/validations/form/createTrainer';
import { createTrainerInitialValues } from 'common/form/initial-values/create';
import { generatePassword } from 'common/form/utils';
import getDataToSave from 'common/form/dataToSave';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import AppBarContext from 'context/AppBarContext';
import geoSlice from 'store/modules/geo';
import trainerSlice from 'store/modules/trainer';

const requiredFields = [
  'first_name',
  'last_name',
  'email',
  'birthdate',
  'phone',
  'country',
  'ui_language',
  'specialties',
  'languages',
  'password'
];

function TrainerCreate() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('trainer.create', { ns: 'routes' });
  const formRef = useRef(null);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const countries = useSelector((state) => state.geo.countries);
  const geoStatus = useSelector((state) => state.geo.status);
  const sessionTypeOptions = useSelector((state) => state.sessionType.sessionTypeOptions);
  const sessionTypeStatus = useSelector((state) => state.sessionType.status);
  const status = useSelector((state) => state.trainer.status.general);

  useEffect(() => {
    if (geoStatus !== requestStatus.loading && countries.length <= 0) {
      dispatch(geoSlice.actions.getCountries());
    }
  }, []);

  useEffect(() => {
    if (status === requestStatus.success) {
      formRef.current.restart();
    }
  }, [status]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onCreate = useCallback(
    (values) => {
      const dataToSave = getDataToSave(values, 'user');
      dispatch(trainerSlice.actions.create(dataToSave));
    },
    [dispatch]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={createTrainerInitialValues}
        mutators={{
          generatePassword
        }}
        onSubmit={onCreate}
        validate={validateCreateTrainerForm}
        render={({ form, handleSubmit, invalid, pristine }) => {
          formRef.current = form;

          return (
            <form onSubmit={handleSubmit}>
              <TrainerFields
                countries={countries}
                form={form}
                geoStatus={geoStatus}
                requestStatus={requestStatus}
                requiredFields={requiredFields}
                sessionTypeOptions={sessionTypeOptions}
                sessionTypeStatus={sessionTypeStatus}
              />
              <StyledButtonsContainer>
                <SecondaryButtonLoading
                  component={RouterLink}
                  disabled={status === requestStatus.loading}
                  style={{ marginRight: '32px' }}
                  label={t('cancel', { ns: 'buttons' })}
                  to={routes.listTrainer.path}
                  type="button"
                />
                <PrimaryLoadingButton
                  disabled={invalid || pristine || status === requestStatus.loading}
                  loading={status === requestStatus.loading}
                  label={t('trainer.create', { ns: 'buttons' })}
                  type="submit"
                />
              </StyledButtonsContainer>
            </form>
          );
        }}
      />
    </>
  );
}

export default TrainerCreate;
