import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { dateFormat } from 'common/constants/date';
import StyledProgressGalleryHeaderIndicator from 'common/theme/galleries/progress/StyledProgressGalleryHeaderIndicator';
import StyledProgressGalleryHeaderPaper from 'common/theme/galleries/progress/StyledProgressGalleryHeaderPaper';

function ProgressGalleryHeader({ currentImageIndex, images, onClose }) {
  const { i18n } = useTranslation();
  const dateLocale = i18n.language === 'es' ? es : enUS;

  return (
    <StyledProgressGalleryHeaderPaper elevation={0}>
      <StyledProgressGalleryHeaderIndicator>
        {currentImageIndex + 1} / {images.length}
      </StyledProgressGalleryHeaderIndicator>
      <Box>
        {format(parseISO(images[currentImageIndex].date), dateFormat, { locale: dateLocale })}
      </Box>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </StyledProgressGalleryHeaderPaper>
  );
}

ProgressGalleryHeader.propTypes = {
  currentImageIndex: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ProgressGalleryHeader;
