import styledComponent from 'styled-components';

const StyledPerformanceHistoryChartEmoji = styledComponent.text`
  font-size: 0.875rem;
  @media (min-width: 600px) {
    font-size: 1rem;
  }
  @media (min-width: 900px) {
    font-size: 1.25rem;
  }
`;

export default StyledPerformanceHistoryChartEmoji;
