import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import AppBarButtonLink from 'components/shared/buttons/AppBarButtonLink';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import PlanFilters from 'components/plan/PlanFilters';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import routes from 'common/routes';
import { getPlanColumns } from 'common/table/columns';
import { getPlanData } from 'common/table/data';
import { defaultTableOptions, getSelectToolBar, tableLocalization } from 'common/table/utils';
import AppBarContext from 'context/AppBarContext';
import planSlice from 'store/modules/plan';

function PlanList() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'labels', 'routes', 'tables']);
  const pageTitle = t('plan.list', { ns: 'routes' });
  const emptyMessage = t('empty', { type: pageTitle.toLowerCase(), ns: 'tables' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const filters = useSelector((state) => state.plan.filters);
  const pagination = useSelector((state) => state.plan.pagination);
  const paginatorInfo = useSelector((state) => state.plan.paginatorInfo);
  const plans = useSelector((state) => state.plan.plans);
  const refresh = useSelector((state) => state.plan.refresh);
  const status = useSelector((state) => state.plan.status);

  const listPlans = useCallback(() => {
    dispatch(planSlice.actions.list({ pagination, filters }));
  }, [dispatch, filters, pagination]);

  useEffect(() => {
    listPlans();
  }, [listPlans, refresh]);

  useEffect(() => {
    setAppBarButton(
      <AppBarButtonLink label={t('add', { ns: 'buttons' })} path={routes.createPlan.path} />
    );
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const tableData = getPlanData(plans);
  const columns = getPlanColumns({ data: tableData, translateFunc: t });

  const onChangePage = (page) => {
    dispatch(planSlice.actions.updatePagination({ page: page + 1 }));
  };

  const onDelete = (ids) => {
    dispatch(planSlice.actions.remove(ids));
  };

  const onRestore = (ids) => {
    dispatch(planSlice.actions.restore(ids));
  };

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    customToolbarSelect: (selectedRows, displayData) =>
      getSelectToolBar({
        filters,
        rows: selectedRows,
        data: displayData,
        onDelete,
        onRestore
      }),
    onChangePage,
    page: pagination.page - 1,
    rowsPerPage: pagination.first,
    textLabels: tableLocalization(pageTitle.toLowerCase(), t)
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <PlanFilters />
      <Box
        sx={{
          position: 'relative'
        }}>
        <LoadingBlock
          emptyBlockProps={{ message: emptyMessage }}
          isValid={plans.length > 0}
          skeletonProps={{ rows: 5 }}
          status={status}
          type="table">
          <MUIDataTable columns={columns} data={tableData} options={options} />
        </LoadingBlock>
      </Box>
    </>
  );
}

export default PlanList;
