import { Typography, styled } from '@mui/material';

const StyledLatestPerformanceCardText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(4)
  }
}));

export default StyledLatestPerformanceCardText;
