/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import { pushNotificationStatus } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import { camelCaseKeys } from 'common/utils/general';

const initialState = {
  config: {
    language: 'es',
    miniDrawer: false,
    pushNotifications: pushNotificationStatus.default
  },
  status: requestStatus.idle
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    create(state) {
      state.status = requestStatus.loading;
    },
    createSuccess(state) {
      state.status = requestStatus.success;
    },
    createError(state) {
      state.status = requestStatus.error;
    },
    find(state) {
      state.status = requestStatus.success;
    },
    findSuccess(state, action) {
      state.config = camelCaseKeys(action.payload);
      state.status = requestStatus.success;
    },
    findError(state) {
      state.status = requestStatus.error;
    },
    update(state) {
      state.status = requestStatus.loading;
    },
    updateSuccess(state, action) {
      state.config = camelCaseKeys(action.payload);
      state.status = requestStatus.success;
    },
    updateError(state) {
      state.status = requestStatus.error;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default settingsSlice;
