import React from 'react';
import PropTypes from 'prop-types';
import StyledTabPanel from 'common/theme/tab-panels/StyledTabPanel';

function TabPanel({ children, current, index, ...rest }) {
  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={current !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}>
      {current === index && children}
    </StyledTabPanel>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  current: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

export default TabPanel;
