import { Box, styled } from '@mui/material';

const StyledAppbarTitleContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    marginTop: 0,
    width: 'auto'
  }
}));

export default StyledAppbarTitleContainer;
