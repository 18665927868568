import { Box, styled } from '@mui/material';

const StyledLoginLayoutOverlay = styled(Box)(() => ({
  background: 'linear-gradient(180deg, rgba(31, 35, 39, 0.78) 0%, rgba(41, 46, 51, 0.28) 100%)',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%'
}));

export default StyledLoginLayoutOverlay;
