import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import StyledProgressGalleryChip from 'common/theme/galleries/progress/StyledProgressGalleryChip';
import StyledProgressGalleryHighlightImage from 'common/theme/galleries/progress/StyledProgressGalleryHighlightImage';

function ProgressGalleryImage({ alt, src, date }) {
  return (
    <Box sx={{ position: 'relative ' }}>
      <StyledProgressGalleryChip label={date} color="secondary" />
      <StyledProgressGalleryHighlightImage alt={alt} src={src} loading="lazy" />
    </Box>
  );
}

ProgressGalleryImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
};

export default ProgressGalleryImage;
