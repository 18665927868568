import React from 'react';
import { Avatar, styled } from '@mui/material';

const StyledUserAvatarInitials = styled(({ size, textSize, theme, ...otherProps }) => (
  <Avatar {...otherProps} />
))(({ size, textSize, theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.gray3.main,
  fontSize: theme.typography.pxToRem(textSize),
  fontWeight: 500,
  lineHeight: 1.5,
  height: size,
  width: size
}));

export default StyledUserAvatarInitials;
