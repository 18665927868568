import { ListItemButton, styled } from '@mui/material';

const StyledTrainerSelectButton = styled(ListItemButton)(() => ({
  borderRadius: '28px',
  display: 'inline-block',
  padding: 0,
  width: '100%'
}));

export default StyledTrainerSelectButton;
