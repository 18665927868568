/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import StyledDrawerMenuLogoContainer from 'common/theme/drawer-menu/StyledDrawerMenuLogoContainer';
import StyledDrawerMenuLogotypeContainer from 'common/theme/drawer-menu/StyledDrawerMenuLogotypeContainer';
import { ReactComponent as Logotype } from 'images/logotype.svg';
import { ReactComponent as Logo } from 'images/logo.svg';

function DrawerLogo() {
  const miniDrawer = useSelector((state) => state.settings.config.miniDrawer);

  return miniDrawer ? (
    <StyledDrawerMenuLogoContainer>
      <Logo />
    </StyledDrawerMenuLogoContainer>
  ) : (
    <StyledDrawerMenuLogotypeContainer>
      <Logotype />
    </StyledDrawerMenuLogotypeContainer>
  );
}

export default DrawerLogo;
