import styledComponent from 'styled-components';

const StyledProgressGalleryGridItem = styledComponent.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 16px;
  @media (min-width: 600px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export default StyledProgressGalleryGridItem;
