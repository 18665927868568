import { differenceInCalendarWeeks, differenceInHours, isAfter, isBefore, isEqual } from 'date-fns';
import palette from 'common/theme/palette';
import { convertLocalToUtcTime } from 'common/utils/date';

/**
 * Constructs a FullCalendar Event object from a given set of values.
 *
 * @param  {Object}   values      Object containing the properties needed to build a FullCalendar event.
 * @param  {Boolean}  fromServer  Flag indicating if the event data comes from the server.
 *
 * @returns  {Object}  event  A FullCalendar event object with properties:
 *  id               (number),
 *  allDay           (boolean),
 *  start            (string),
 *  end              (string),
 *  backgroundColor  (string),
 *  classNames       (array of strings),
 *  display          (string),
 *  extendedProps    (object with sessions array and stored boolean)
 */
export const constructAvailabilityCalendarEvent = (values, fromServer = false) => ({
  id: Number(values.id),
  allDay: false,
  start: values.start_date,
  end: values.end_date,
  backgroundColor: fromServer ? palette.scheduled.main : palette.absent.main,
  classNames: [
    'fc-availability',
    fromServer ? 'fc-availability-scheduled' : 'fc-availability-temporary'
  ],
  display: 'list-item',
  extendedProps: {
    sessions: values?.sessions || [],
    stored: fromServer
  }
});

/**
 * Determines whether a given availability object can be deleted.
 *
 * @param  {Object}  availability  The availability object to check.
 *
 * @returns  {Boolean}  Returns true if the availability can be deleted, false otherwise.
 */
export const canDeleteAvailability = (availability) => {
  const { start } = availability;
  const { stored } = availability.extendedProps;

  if (stored) {
    return differenceInHours(start, new Date()) > 23;
  }

  return true;
};

/**
 * Checks if the 'availability' object has any sessions that can be filtered.
 *
 * @param  {Object}  availability  An object containing the availability information.
 *
 * @returns  {Boolean}  Returns true if the 'availability' object has one or more sessions, false otherwise.
 */
export const canFilterAvailabilitySessions = (availability) => {
  const { sessions = [] } = availability.extendedProps;

  return sessions.length > 0;
};

/**
 * Deletes 'mirror' events from a calendar.
 * A mirror event is defined as any event which does not have a `stored` property set to true.
 *
 * @param  {Object}  calendar  An instance of a FullCalendar React component.
 */
export const deleteMirrorEvents = (calendar) => {
  const events = calendar.getEvents();

  events.forEach((event) => {
    const { stored } = event.extendedProps;

    if (!stored) {
      event.remove();
    }
  });
};

/**
 * filters events from calendar within a specified date range.
 *
 * @param  {Object}  dateRange  The date range within which to filter events.
 * @param  {Object}  calendar   The calendar from which to filter events.
 *
 * @returns  {Array}  Returns an array of filtered events.
 */
export const filterEventsByDateRange = (dateRange, calendar) =>
  calendar
    .getEvents()
    .filter((event) => event.start >= dateRange.startDate && event.end <= dateRange.endDate);

/**
 * Filters an array of sessions to return only those that fall within a specified availability period.
 *
 * @param  {Array}   sessions      An array of session objects.
 * @param  {Object}  availability  An object representing an availability period.
 *
 * @returns  {Array}  An array of session objects.
 */
export const filterSessionsByAvailability = (sessions, availability) => {
  const { start: availabilityStartDate, end: availabilityEndDate } = availability;

  const filteredSessions = sessions.filter((session) => {
    const sessionStartDate = new Date(session.start_date);
    const sessionEndDate = new Date(session.end_date);

    return (
      (isAfter(sessionStartDate, availabilityStartDate) ||
        isEqual(sessionStartDate, availabilityStartDate)) &&
      (isBefore(sessionEndDate, availabilityEndDate) ||
        isEqual(sessionEndDate, availabilityEndDate))
    );
  });

  return filteredSessions;
};

/**
 * Generate a pseudo-random identifier based on the difference between the start
 * and end times of an event, and a random integer.
 *
 * @param  {Object}  info  The event information.
 * @returns  {Number}  The pseudo-random identifier.
 */
export const generateAvailabilityIdentifier = (info) => {
  const randomInt = Math.round(Math.random() * (1000 - 1) + 1);
  return info.end.getTime() - info.start.getTime() + randomInt;
};

/**
 * Extracts the start and end dates from an event object and formats them as strings.
 *
 * @param  {Object}  info  The event object.
 *
 * @returns  {Object}  An object containing `startDate` and `endDate` properties. Both properties
 * are strings representing dates formatted according to `dateTimeFormat`.
 */
export const getStartEndDatesFromEvent = (info) => {
  const startDate = convertLocalToUtcTime(info.start);
  const endDate = convertLocalToUtcTime(info.end);

  return { startDate, endDate };
};

/**
 * Extracts the start and end dates from the current view of a FullCalendar instance.
 *
 * @param  {Object}  calendar  An instance of a FullCalendar React component.
 *
 * @returns  {Object}  An object with `startDate` and `endDate` properties, which are string
 * representations of the start and end dates of the calendar's current view.
 */
export const getStartEndDatesFromView = (calendar) => {
  const { view } = calendar;
  return { startDate: view.currentStart, endDate: view.currentEnd };
};

/**
 * Removes unused fields from the availability objects if not stored.
 *
 * @param  {Array<Object>}  availabilities  An array of availability objects.
 *
 * @returns  {Array<Object>}  A new array of availability objects, with `id` and `stored` properties removed
 * where `stored` is falsy.
 */
export const sanitizeAvailabilities = (availabilities) =>
  availabilities.map((availabilityData) => {
    if (!availabilityData.stored) {
      const { id, stored, ...rest } = availabilityData;
      return { ...rest };
    }

    return availabilityData;
  });

/**
 * Determines whether the availability should be updated based on the latest availability data.
 *
 * This function checks if the latest availability exists and if it does, it calculates the
 * difference in calendar weeks between the current date and the start date of the latest
 * availability. The availability should be updated if this difference is less than 4 weeks.
 *
 * @param  {Object|null}  latestAvailability  The most recent availability data, or null if not available.
 *
 * @returns  {boolean}  True if the availability should be updated, either because the latest
 * availability is not set or the difference in weeks is less than 4. False otherwise.
 */
export const shouldUpdateAvailability = (latestAvailability) => {
  if (!latestAvailability) return true;

  const { start_date: startDate } = latestAvailability;
  const now = new Date();

  return differenceInCalendarWeeks(new Date(startDate), now, { weekStartsOn: 1 }) < 4;
};
