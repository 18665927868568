import { addMonths, set } from 'date-fns';
import {
  currency,
  gender,
  language,
  paymentMethod,
  roles,
  sessionStatus,
  uiLanguage
} from 'common/constants/options';
import { getAdjustedTimeForRange, getNextHourIfTimePassed } from 'common/utils/date';

/**
 * Initial values for creating an admin.
 * @type {Object}
 * @property {string} email - The email of the admin.
 * @property {string} password - The password of the admin.
 * @property {string} first_name - The first name of the admin.
 * @property {string} last_name - The last name of the admin.
 * @property {string} gender - The gender of the admin.
 * @property {Date} birthdate - The birthdate of the admin.
 * @property {string} ui_language - The UI language of the admin.
 * @property {string} role - The role of the admin.
 */
export const createAdminInitialValues = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  gender: gender.female,
  birthdate: new Date(),
  ui_language: 'es',
  role: roles.administrator
};

/**
 * Initial values for creating a client.
 * @type {Object}
 * @property {string} email - The email of the client.
 * @property {string} password - The password of the client.
 * @property {string} first_name - The first name of the client.
 * @property {string} last_name - The last name of the client.
 * @property {string} gender - The gender of the client.
 * @property {Date} birthdate - The birthdate of the client.
 * @property {string} phone - The phone number of the client.
 * @property {string} country - The country of the client.
 * @property {string} state - The state of the client.
 * @property {string} city - The city of the client.
 * @property {string} plan_id - The plan ID of the client.
 * @property {string} ui_language - The UI language of the client.
 * @property {string} role - The role of the client.
 */
export const createClientInitialValues = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  gender: gender.female,
  birthdate: new Date(),
  phone: '',
  country: null,
  state: null,
  city: null,
  plan_id: '',
  ui_language: 'es',
  role: roles.client
};

/**
 * Initial values for creating a client payment.
 * @type {Object}
 * @property {Date} created_at - The date and time when the payment is created.
 * @property {string} currency - The currency of the payment.
 * @property {Date} end_date - The end date of the payment.
 * @property {string} payment_method - The payment method used.
 * @property {number} price - The price of the payment.
 * @property {string} plan_id - The ID of the payment plan.
 * @property {number} sessions_paid - The number of sessions paid.
 * @property {Date} start_date - The start date of the payment.
 * @property {string} user_id - The ID of the user making the payment.
 */
export const createClientPaymentInitialValues = {
  created_at: new Date(),
  currency: currency.cop,
  end_date: addMonths(new Date(), 1),
  payment_method: paymentMethod.wire,
  price: 1,
  plan_id: '',
  sessions_paid: 0,
  start_date: new Date(),
  user_id: ''
};

/**
 * Initial values for creating a legal entity.
 * @typedef {Object}
 * @property {string} body - The body of the legal entity.
 * @property {Date} created_at - The creation date of the legal entity.
 * @property {string} language - The language of the legal entity.
 * @property {string} role - The role of the legal entity.
 * @property {string} title - The title of the legal entity.
 */
export const createLegalInitialValues = {
  body: '',
  created_at: new Date(),
  language: uiLanguage.es.value,
  role: roles.client,
  title: ''
};

/**
 * Initial values for creating a meal plan.
 * @type {Object}
 * @property {Date} created_at - The date and time when the meal plan is created.
 * @property {string} pdf_document - The path or URL of the PDF document associated with the meal plan.
 * @property {string} user_id - The ID of the user associated with the meal plan.
 */
export const createMealPlanInitialValues = {
  created_at: new Date(),
  pdf_document: '',
  user_id: ''
};

/**
 * Initial values for creating news.
 * @type {Object}
 * @property {string} body - The body of the news.
 * @property {Date} created_at - The creation date of the news.
 * @property {string} language - The language of the news.
 * @property {string} title - The title of the news.
 */
export const createNewsInitialValues = {
  body: '',
  created_at: new Date(),
  language: uiLanguage.es.value,
  title: ''
};

/**
 * Initial values for creating performance.
 * @type {Object}
 * @property {Date} created_at - The date when the performance is created.
 * @property {Date} end_date - The end date of the performance.
 * @property {string} user_id - The ID of the user associated with the performance.
 * @property {Date} start_date - The start date of the performance.
 */
export const createPerformanceInitialValues = {
  created_at: new Date(),
  end_date: addMonths(new Date(), 1),
  user_id: '',
  start_date: new Date()
};

/**
 * Initial values for creating a plan.
 * @typedef {Object}
 * @property {string} currency - The currency of the plan.
 * @property {string} name - The name of the plan.
 * @property {number} price - The price of the plan.
 * @property {number} sessions - The number of sessions included in the plan.
 */
export const createPlanInitialValues = {
  currency: currency.cop,
  name: '',
  price: 1,
  sessions: 1
};

/**
 * Initial values for creating progress.
 * @type {Object}
 * @property {Date} created_at - The date when the progress is created.
 * @property {Date} end_date - The end date of the progress.
 * @property {number} height - The height value for the progress.
 * @property {Date} start_date - The start date of the progress.
 * @property {string} user_id - The user ID associated with the progress.
 * @property {number} weight - The weight value for the progress.
 */
export const createProgressInitialValues = {
  created_at: new Date(),
  end_date: addMonths(new Date(), 1),
  height: 0.0,
  start_date: new Date(),
  user_id: '',
  weight: 0.0
};

/**
 * Initial values for creating session.
 *
 * @returns {Object} An object containing initial values for a new session.
 * @property {string} client_id - The client ID for the session, initially empty.
 * @property {Date} date - The base date for the session, adjusted for the time range.
 * @property {Date} from - The starting time for the session, set to the next hour if the current time has passed.
 * @property {string} language - The language for the session, defaulted to Spanish.
 * @property {string} observations - Any initial observations for the session, initially empty.
 * @property {string} session_type_id - The session type ID, initially empty.
 * @property {string} status - The status of the session, defaulted to 'SCHEDULED'.
 * @property {Date|null} to - The ending time for the session, set 5 hours ahead of the 'from' time, or null if 'from' is not set.
 * @property {string} trainer_id - The trainer ID for the session, initially empty.
 */
export const createSessionInitialValues = () => {
  const baseDate = getAdjustedTimeForRange();
  const fromDate = getNextHourIfTimePassed(baseDate);

  return {
    client_id: '',
    date: baseDate,
    from: fromDate,
    language: language.spanish,
    observations: '',
    session_type_id: '',
    status: sessionStatus.scheduled,
    to: fromDate ? set(fromDate, { hours: 5, minutes: 0, seconds: 0, milliseconds: 0 }) : null,
    trainer_id: ''
  };
};

/**
 * Initial values for creating a session type.
 * @type {Object}
 * @property {string} currency - The currency for the session type.
 * @property {string} name - The name of the session type.
 * @property {number} price - The price of the session type.
 */
export const createSessionTypeInitialValues = {
  currency: currency.cop,
  name: '',
  price: 1
};

/**
 * Initial values for creating a trainer.
 * @type {Object}
 * @property {string} email - The email of the trainer.
 * @property {string} password - The password of the trainer.
 * @property {string} first_name - The first name of the trainer.
 * @property {string} last_name - The last name of the trainer.
 * @property {string} gender - The gender of the trainer.
 * @property {Date} birthdate - The birthdate of the trainer.
 * @property {string} phone - The phone number of the trainer.
 * @property {string} country - The country of the trainer.
 * @property {string} ui_language - The UI language of the trainer.
 * @property {string} role - The role of the trainer.
 * @property {Array} languages - The languages spoken by the trainer.
 * @property {Array} specialties - The specialties of the trainer.
 */
export const createTrainerInitialValues = {
  email: '',
  password: '',
  first_name: '',
  last_name: '',
  gender: gender.female,
  birthdate: new Date(),
  phone: '',
  country: null,
  ui_language: 'es',
  role: roles.trainer,
  languages: [],
  specialties: []
};

/**
 * Initial values for creating a trainer payment.
 * @type {Object}
 * @property {Date} created_at - The date and time when the payment is created.
 * @property {string} currency - The currency of the payment.
 * @property {Date} end_date - The end date of the payment.
 * @property {number} price - The price of the payment.
 * @property {number} sessions_completed - The number of sessions completed for the payment.
 * @property {Date} start_date - The start date of the payment.
 * @property {string} user_id - The ID of the user associated with the payment.
 */
export const createTrainerPaymentInitialValues = {
  created_at: new Date(),
  currency: currency.cop,
  end_date: addMonths(new Date(), 1),
  price: 1,
  sessions_completed: 0,
  start_date: new Date(),
  user_id: ''
};
