import Drawer, { drawerClasses } from '@mui/material/Drawer';
import { styled } from '@mui/material';

const StyledSessionDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${drawerClasses.paper}`]: {
    background: theme.palette.gray3.main,
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '400px'
    }
  }
}));

export default StyledSessionDrawer;
