import React from 'react';
import { Box, styled } from '@mui/material';

const StyledNewsCardContainer = styled(({ hasThumbnail, ...otherProps }) => (
  <Box {...otherProps} />
))(({ hasThumbnail, theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: theme.palette.gray2.main,
  borderRadius: '4px',
  columnGap: theme.spacing(2),
  display: hasThumbnail ? 'flex' : 'block',
  padding: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.gray0.main,
    borderRadius: '5px'
  }
}));

export default StyledNewsCardContainer;
