import { styled, Typography } from '@mui/material';

const StyledSessionDialogAppBarTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 600,
  letterSpacing: theme.typography.pxToRem(0.2),
  lineHeight: 1,
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(26)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: 700
  }
}));

export default StyledSessionDialogAppBarTitle;
