import { Paper, styled } from '@mui/material';

const StyledProgressGalleryHeaderPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2)
}));

export default StyledProgressGalleryHeaderPaper;
