import styledComponent from 'styled-components';

const StyledLatestPerformanceCardItem = styledComponent.div`
  align-items: center;
  display: flex;
  height: 22px;
  & p {
    font-size: 0.75rem;
  }
  @media (min-width: 1200px) {
    height: 26px;
    & p {
      font-size: 0.875rem;
    }
  }
`;

export default StyledLatestPerformanceCardItem;
