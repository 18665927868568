import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function CheckboxFieldAdapter({ input, label }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onChange = (event) => {
    input.onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={input.value} onChange={onChange} size={size} />}
      label={label}
    />
  );
}

CheckboxFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export default CheckboxFieldAdapter;
