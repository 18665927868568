import styledComponent from 'styled-components';

const StyledSessionCardHiddenLink = styledComponent.a`
  position: absolute;
  height: 100%;
  left: 0;
  opacity: 0;
  top: 0;
  width: 100%;
`;

export default StyledSessionCardHiddenLink;
