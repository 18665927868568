import React from 'react';
import ActionsColumn from 'components/shared/table-columns/Actions';
import RangeColumn from 'components/shared/table-columns/Range';
import SessionStatusChip from 'components/shared/chips/SessionStatusChip';
import SessionStatusSelectColumn from 'components/shared/table-columns/SessionStatusSelect';
import UserProfileColumn from 'components/shared/table-columns/UserProfile';

export const getRow = (tableData, dataIndex) =>
  tableData.filter((data, index) => index === dataIndex);

export const actions = ({ data, label, items, actionRoutes }) => ({
  label,
  name: 'actions',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <ActionsColumn actions={items} data={row} routes={actionRoutes} />;
    }
  }
});

export const dateRange = ({ data, label }) => ({
  label,
  name: 'dateRange',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <RangeColumn start={row.start_date} end={row.end_date} multiline />;
    }
  }
});

export const sessionStatus = ({ data, label }) => ({
  label,
  name: 'session_status',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <SessionStatusChip data={row} />;
    }
  }
});

export const sessionStatusUpdate = ({ data, label, onSelectChange }) => ({
  label,
  name: 'session_status_update',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <SessionStatusSelectColumn data={row} onSelectChange={onSelectChange} />;
    }
  }
});

export const timeRange = ({ data, label }) => ({
  label,
  name: 'dateRange',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      return <RangeColumn start={row.start_time} end={row.end_time} multiline={false} />;
    }
  }
});

export const userProfile = ({ data, key = 'user', label, options }) => ({
  label,
  name: 'user',
  options: {
    customBodyRenderLite: (dataIndex) => {
      const row = getRow(data, dataIndex)[0];
      const user = key in row ? row[key] : row;

      return <UserProfileColumn data={user} options={options} />;
    }
  }
});
