import { alphaNumericSpace, hasValue, lengthBetween, numberOnly } from 'common/validations/field';
import defaultClientFormValidations from 'common/validations/form/defaultClient';
import i18n from 'i18n';

const validateEditClientProfileForm = (values) => {
  const errors = defaultClientFormValidations(values);

  if (!hasValue(values.photo)) {
    errors.photo = i18n.t('profilePicture', { ns: 'validations' });
  }

  if (!numberOnly(values.identification_number)) {
    errors.identification_number = i18n.t('onlyNumber', { ns: 'validations' });
  }

  if (!lengthBetween(values.identification_number, 6, 20)) {
    errors.identification_number = i18n.t('lengthBetweenNumber', {
      min: '6',
      max: '20',
      ns: 'validations'
    });
  }

  if (!hasValue(values.phone)) {
    errors.phone = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!hasValue(values.eps)) {
    errors.eps = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.eps) && !alphaNumericSpace(values.eps)) {
    errors.eps = i18n.t('alphaNumericSpace', { ns: 'validations' });
  }

  if (!hasValue(values.contact_name)) {
    errors.contact_name = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.contact_name) && !alphaNumericSpace(values.contact_name)) {
    errors.contact_name = i18n.t('alphaNumericSpace', { ns: 'validations' });
  }

  if (!hasValue(values.contact_phone)) {
    errors.contact_phone = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.contact_phone) && !lengthBetween(values.contact_phone, 7, 10)) {
    errors.contact_phone = i18n.t('lengthBetweenNumber', {
      min: '7',
      max: '10',
      ns: 'validations'
    });
  }

  if (hasValue(values.password) && values.password !== values.confirm_password) {
    errors.confirm_password = i18n.t('passwordMatch', { ns: 'validations' });
  }

  return errors;
};

export default validateEditClientProfileForm;
