export const primaryButtonBaseStyles = (theme) => ({
  background:
    'linear-gradient(90deg, rgba(27, 35, 191, 0.9) 0%, #273BD7 85.18%, #273BD7 99.99%, rgba(255, 255, 255, 0) 100%)',
  fontSize: theme.typography.pxToRem(14),
  position: 'relative',
  textTransform: 'none',
  '& span': {
    zIndex: 1
  }
});

export const primaryButtonHoverStyles = {
  background:
    'linear-gradient(90deg, rgba(20, 25, 170, 0.9) 0%, #202fC7 85.18%, #202fC7 99.99%, rgba(255, 255, 255, 0) 100%)',
  borderRadius: '4px'
};

export const primaryButtonPseudoElementStyles = (theme) => ({
  content: '""',
  display: 'block',
  height: '100%',
  left: 0,
  opacity: 0,
  position: 'absolute',
  top: 0,
  transition: `opacity ${theme.transitions.easing.easeInOut} ${theme.transitions.duration.short}ms`,
  width: '100%'
});
