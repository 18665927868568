import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GoBackButton from 'components/shared/buttons/GoBackButton';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import { dateFormat } from 'common/constants/date';
import StyledNewsBodyContainer from 'common/theme/news-view/StyledNewsBodyContainer';
import StyledNewsDate from 'common/theme/news-view/StyledNewsDate';
import StyledNewsImageContainer from 'common/theme/news-view/StyledNewsImageContainer';
import StyledNewsImage from 'common/theme/news-view/StyledNewsImage';
import StyledH2Title from 'common/theme/shared/StyledH2Title';
import AppBarContext from 'context/AppBarContext';
import newsSlice from 'store/modules/news';

function NewsView() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t, i18n } = useTranslation(['news', 'routes']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const pageTitle = t('news.view', { ns: 'routes' });
  const { id } = useParams();
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const news = useSelector((state) => state.news.data.currentNews);
  const status = useSelector((state) => state.news.status.general);

  const newsImage = useMemo(() => {
    if (!news || (!news.featured_image && !news.mobile_featured_image)) {
      return null;
    }

    return isSmall && news.mobile_featured_image
      ? news.mobile_featured_image
      : news?.featured_image;
  }, [news, isSmall]);

  useEffect(() => {
    dispatch(newsSlice.actions.find(id));
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle('');
  }, [setAppBarButton, setAppBarTitle]);

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <GoBackButton />
      <LoadingBlock
        emptyBlockProps={{ message: t('empty', { ns: 'news' }) }}
        isValid={news !== null}
        status={status}
        type="news">
        {news && (
          <>
            <StyledH2Title component="h2">{news.title}</StyledH2Title>
            <StyledNewsDate color="secondary" variant="body1">
              {format(parseISO(news.created_at), dateFormat, { locale: dateLocale })}
            </StyledNewsDate>
            {(news.featured_image || news.mobile_featured_image) && (
              <StyledNewsImageContainer>
                <StyledNewsImage src={newsImage} alt={news.title} />
              </StyledNewsImageContainer>
            )}
            <StyledNewsBodyContainer dangerouslySetInnerHTML={{ __html: news.body }} />
          </>
        )}
      </LoadingBlock>
    </>
  );
}

export default NewsView;
