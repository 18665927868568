import styledComponent from 'styled-components';

const StyledOverviewProfileDetailContainer = styledComponent.div`
  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export default StyledOverviewProfileDetailContainer;
