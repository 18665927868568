import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import ProgressGalleryHighlight from 'components/shared/galleries/progress/ProgressGalleryHighlight';
import ProgressGalleryLightBox from 'components/shared/galleries/progress/lightbox/ProgressGalleryLightbox';
import ProgressGalleryPagination from 'components/shared/galleries/progress/ProgressGalleryPagination';
import { progressListGalleryFields } from 'common/constants/query-fields/progress';
import progressSlice from 'store/modules/progress';

const progressPerPage = 6;

function ProgressGallery({ clientId }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation(['progress']);

  const progress = useSelector((state) => state.progress.data.progressGallery);
  const pagination = useSelector((state) => state.progress.pagination);
  const paginatorInfo = useSelector((state) => state.progress.paginatorInfo);
  const status = useSelector((state) => state.progress.status);

  const hasProgress = progress.length > 0;
  const totalPages = useMemo(
    () => Math.ceil(paginatorInfo.total / progressPerPage),
    [paginatorInfo.total]
  );

  useEffect(() => {
    if (clientId) {
      const galleryFilters = { user_id: Number(clientId) };
      const galleryPagination = { first: progressPerPage, page: pagination.page };

      dispatch(
        progressSlice.actions.listGallery({
          fields: progressListGalleryFields,
          filters: galleryFilters,
          pagination: galleryPagination
        })
      );
    }
  }, [clientId, dispatch, pagination.page]);

  const handlePaginationChange = (event, page) => {
    dispatch(progressSlice.actions.updatePagination({ page }));
  };

  return (
    <LoadingBlock
      emptyBlockProps={{ message: t('empty', { ns: 'progress' }) }}
      isValid={hasProgress}
      status={status}
      type="progressGallery">
      <>
        {isMedium && <ProgressGalleryHighlight progress={progress} />}
        <ProgressGalleryLightBox progress={progress} />
        <ProgressGalleryPagination
          page={pagination.page}
          totalPages={totalPages}
          onChange={handlePaginationChange}
        />
      </>
    </LoadingBlock>
  );
}

ProgressGallery.propTypes = {
  clientId: PropTypes.number.isRequired
};

export default ProgressGallery;
