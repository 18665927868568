import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import TextAreaFieldAdapter from 'components/shared/field-adapters/TextAreaFieldAdapter';

function SessionFieldsObservations({ values }) {
  const { t } = useTranslation(['labels']);

  return (
    <Grid container>
      <Grid xs={12}>
        <Field
          id="observations"
          name="observations"
          component={TextAreaFieldAdapter}
          label={t('observations', { ns: 'labels' })}
          helperText={t('text', { ns: 'validations' })}
          multiline
          minRows={5}
          type="text"
          fullWidth
          disabled={!values.client_id || !values.session_type_id}
        />
      </Grid>
    </Grid>
  );
}

SessionFieldsObservations.propTypes = {
  values: PropTypes.object.isRequired
};

export default SessionFieldsObservations;
