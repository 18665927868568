import { ListItemIcon, styled } from '@mui/material';

const StyledMainMenuListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.menuItem.main,
  margin: '0 auto',
  minWidth: 'auto',
  transition: 'color .3s ease-in-out'
}));

export default StyledMainMenuListItemIcon;
