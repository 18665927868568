import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import { Box, Typography } from '@mui/material';

import 'styles/ck-editor.css';

const toolbarItems = [
  'undo',
  'redo',
  '|',
  'heading',
  '|',
  'bold',
  'italic',
  '|',
  'link',
  'insertTable',
  'blockQuote',
  'mediaEmbed',
  '|',
  'bulletedList',
  'numberedList',
  'outdent',
  'indent'
];

const getConfig = (language) => ({
  language,
  removePlugins: ['ImageUpload', 'EasyImage'],
  toolbar: toolbarItems
});

function CKEditorFieldAdapter({ input, language, meta, required = false, ...rest }) {
  const { t } = useTranslation(['labels']);

  const onChange = (event, editor) => {
    input.onChange(editor.getData());
  };

  return (
    <Box className={meta.touched && meta.invalid ? 'ck-error' : ''}>
      <Typography
        variant="body2"
        sx={{
          color: meta.touched && meta.invalid ? 'muiError.main' : 'text.primary',
          marginBottom: 1,
          transition: 'color 150ms ease-in-out'
        }}>
        {t('body', { ns: 'labels' })}
        {required && ' *'}
      </Typography>
      <CKEditor
        config={getConfig(language)}
        editor={ClassicEditor}
        data={input.value}
        error={meta.touched ? meta.invalid : false}
        helperText={meta.touched && meta.invalid ? meta.error : ''}
        onBlur={input.onBlur}
        onChange={onChange}
        onFocus={input.onFocus}
        {...rest}
      />
      {meta.touched && meta.invalid && (
        <Typography
          variant="caption"
          sx={{ color: 'muiError.main', transition: 'color 150ms ease-in-out' }}>
          {meta.error}
        </Typography>
      )}
    </Box>
  );
}

CKEditorFieldAdapter.defaultProps = {
  required: false
};

CKEditorFieldAdapter.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
  }).isRequired,
  language: PropTypes.oneOf(['es', 'en']).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    invalid: PropTypes.bool.isRequired,
    touched: PropTypes.bool.isRequired
  }).isRequired,
  required: PropTypes.bool
};

export default CKEditorFieldAdapter;
