import DefaultLayout from 'components/layout/DefaultLayout';
import NewsCreate from 'components/news/NewsCreate';
import NewsEdit from 'components/news/NewsEdit';
import NewsList from 'components/news/NewsList';
import NewsView from 'components/news/NewsView';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const newsRoutes = [
  {
    key: 'news-create',
    exact: true,
    path: routes.createNews.path,
    component: NewsCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'news-edit',
    exact: true,
    path: routes.editNews.path,
    component: NewsEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'news-list',
    exact: true,
    path: routes.listNews.path,
    component: NewsList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'news-view',
    exact: true,
    path: routes.viewNews.path,
    component: NewsView,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.client, roles.trainer]
  }
];

export default newsRoutes;
