import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Divider, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import PasswordFieldAdapter from 'components/shared/field-adapters/PasswordFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import { getGenderOptions, getUiLanguageOptions } from 'common/constants/options';
import { isRequiredField } from 'common/utils/general';

function AdminFields({ form, requiredFields }) {
  const { t } = useTranslation(['labels']);

  return (
    <>
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="first_name"
            name="first_name"
            component={TextFieldAdapter}
            label={t('firstname', { count: 2, ns: 'labels' })}
            type="text"
            fullWidth
            required={isRequiredField('first_name', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="last_name"
            name="last_name"
            component={TextFieldAdapter}
            label={t('lastname', { count: 2, ns: 'labels' })}
            type="text"
            fullWidth
            required={isRequiredField('last_name', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="gender"
            name="gender"
            component={SelectFieldAdapter}
            options={getGenderOptions(t)}
            label={t('gender', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="email"
            name="email"
            component={TextFieldAdapter}
            label={t('email', { ns: 'labels' })}
            type="text"
            autoComplete="email"
            fullWidth
            required={isRequiredField('email', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="birthdate"
            name="birthdate"
            component={DatePickerFieldAdapter}
            label={t('birthdate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('birthdate', requiredFields)
            }}
            disableFuture
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="ui_language"
            name="ui_language"
            component={SelectFieldAdapter}
            options={getUiLanguageOptions(t)}
            label={t('uiLanguage', { ns: 'labels' })}
            fullWidth
            required={isRequiredField('ui_language', requiredFields)}
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('password', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="password"
            name="password"
            component={PasswordFieldAdapter}
            label={t('password', { ns: 'labels' })}
            autoComplete="current-password"
            onShuffle={form.mutators.generatePassword}
            fullWidth
            required={isRequiredField('password', requiredFields)}
            shuffle
          />
        </Grid>
      </Grid>
    </>
  );
}

AdminFields.propTypes = {
  form: PropTypes.object.isRequired,
  requiredFields: PropTypes.array.isRequired
};

export default AdminFields;
