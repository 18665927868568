import styledComponent from 'styled-components';

const StyledFiltersForm = styledComponent.form`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  @media (min-width: 1200px) {
    display: block;
  }
`;

export default StyledFiltersForm;
