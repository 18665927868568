import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import MoreProfileUser from 'components/shared/more-profile/MoreProfileUser';
import MoreProfileLogoutButton from 'components/shared/more-profile/MoreProfileLogoutButton';
import ClientMoreHub from 'components/client/more/ClientMoreHub';
import ClientMoreStatus from 'components/client/more/ClientMoreStatus';
import StyledMoreProfileSubtitle from 'common/theme/more-profile/StyledMoreProfileSubtitle';
import { userMembershipFields } from 'common/constants/query-fields/user';
import routes from 'common/routes';
import AppBarContext from 'context/AppBarContext';
import LayoutContext from 'context/LayoutContext';
import authSlice from 'store/modules/auth';

function ClientMore() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons']);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const { setCompactLayout, setNoBgColorLayout } = useContext(LayoutContext);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(null);
  }, [setAppBarButton, setAppBarTitle]);

  useEffect(() => {
    setCompactLayout(true);
    setNoBgColorLayout(true);

    return () => {
      setCompactLayout(false);
      setNoBgColorLayout(false);
    };
  }, []);

  useEffect(() => {
    dispatch(authSlice.actions.me(userMembershipFields));
  }, [dispatch]);

  return (
    <Stack spacing={5}>
      <Stack spacing={1}>
        <MoreProfileUser profilePath={routes.clientProfile.path} />
        <ClientMoreStatus />
      </Stack>
      <Stack spacing={2}>
        <StyledMoreProfileSubtitle>{t('hub', { ns: 'common' })}</StyledMoreProfileSubtitle>
        <ClientMoreHub />
      </Stack>
      <MoreProfileLogoutButton />
    </Stack>
  );
}

export default ClientMore;
