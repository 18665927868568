import {
  addDays,
  addHours,
  endOfDay,
  endOfMonth,
  isWithinInterval,
  lightFormat,
  set,
  startOfDay,
  startOfHour,
  startOfMonth,
  subMonths
} from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  dateTimeFormat,
  isoFormat,
  sessionEndMaximumHour,
  sessionStartMinimumHour
} from 'common/constants/date';

/**
 * Ensures the date is set to the start or end of the day if it's a Date object.
 *
 * @param  {Date|String}  date    A date, either as a Date object or a string in a valid date format.
 * @param  {Boolean}      isEnd   A flag to determine whether the date should be set to the end of the day (true) or the start of the day (false).
 *
 * @returns  {Date|String}  A Date object set to the start or end of the day if the input is a Date object, or the original input if it's a string.
 */
const normalizeDateBoundary = (date, isEnd = false) => {
  if (date instanceof Date) {
    return isEnd ? endOfDay(date) : startOfDay(date);
  }

  return date;
};

/**
 * Creates an array of time options between the specified start and end hours on a given date.
 * Each option is an object containing a `value` (Date object) and a `label` (formatted time string).
 *
 * @param  {number}  startHour  The start hour (0-23) for the time options.
 * @param  {number}  endHour    The end hour (0-23) for the time options.
 * @param  {Date}    baseDate   The date to use for generating time options (year, month, day are used).
 *
 * @returns  {Array<{value: Date, label: string}>}  An array of time option objects.
 */
export const createTimeOptions = (startHour, endHour, baseDate) => {
  const timeOptions = [];
  const currentDate = new Date(baseDate.getTime());
  currentDate.setHours(startHour, 0, 0, 0);

  while (currentDate.getHours() <= endHour) {
    const hours = currentDate.getHours();
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const period = hours < 12 ? 'AM' : 'PM';
    const label = `${formattedHours} ${period}`;

    timeOptions.push({ value: new Date(currentDate), label });
    currentDate.setHours(currentDate.getHours() + 1);
  }

  return timeOptions;
};

/**
 * Converts a local date to a UTC date.
 *
 * @param  {Date|String}  date  The local date to be converted, can be a Date object or a string in a valid date format.
 *
 * @returns  {string}  The date converted to UTC in ISO format.
 */
export const convertLocalToUtcTime = (date) => {
  if (date instanceof Date) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const parsedDate = new Date(date);
    const formattedDate = format(parsedDate, isoFormat);

    return zonedTimeToUtc(formattedDate, timeZone).toISOString();
  }

  return date;
};

/**
 * Converts a date value into a Date object.
 *
 * @param  {Date|String}  date  A date, either as a Date object or a string in a valid date format.
 *
 * @returns  {Date|null}   A Date object if the input is valid, or null if it's not or if no date is provided.
 */
export const convertToDate = (date) => {
  if (!date) {
    return null;
  }

  if (typeof date === 'object') {
    return date;
  }

  return new Date(date);
};

/**
 * Converts a UTC date to local time in a specified format.
 *
 * @param  {Date|String}  date        The date to be converted, can be a Date object or a string in a valid date format.
 * @param  {string}       dateFormat  The format in which to return the converted date.
 * @param  {String|null}  locale      The locale to use for formatting the date.
 *
 * @returns  {string}  The date converted to local time, in the specified format.
 */
export const convertUtcToLocalTime = (date, dateFormat = dateTimeFormat, locale = null) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const localDate = utcToZonedTime(date, timeZone);
  const formattedDate = format(localDate, dateFormat, { timeZone, locale });

  return formattedDate;
};

/**
 * Formats the date range for a specific field in a values object.
 *
 * @param  {Object}   values     The object containing values to format.
 * @param  {string}   dateField  The field in the values object to format.
 * @param  {Boolean}  toUtc      A flag to determine whether the date should be converted to UTC.
 *
 * @returns  {Object}  The values object with the specified date range formatted.
 */
export const formatDateRangeField = (values, dateField, toUtc = false) => {
  if (Object.prototype.hasOwnProperty.call(values, dateField)) {
    const { start_date: startDate, end_date: endDate } = values[dateField] || {};

    if (!startDate) {
      return values;
    }

    const formattedDateRange = {
      start_date: normalizeDateBoundary(startDate),
      end_date: normalizeDateBoundary(endDate, true)
    };

    if (toUtc) {
      formattedDateRange.start_date = convertLocalToUtcTime(formattedDateRange.start_date);
      formattedDateRange.end_date = convertLocalToUtcTime(formattedDateRange.end_date);
    }

    return { ...values, [dateField]: formattedDateRange };
  }

  return values;
};

/**
 * Checks if the current time is between 4 AM and 8 PM. If it is, returns the current time with
 * minutes, seconds, and milliseconds set to 0. If not, returns the next day at 4 AM with minutes,
 * seconds, and milliseconds set to 0.
 *
 * @returns  {Date}  The adjusted date and time.
 */
export const getAdjustedTimeForRange = () => {
  const now = new Date();
  const minDate = set(now, {
    hours: sessionStartMinimumHour,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });
  const maxDate = set(now, {
    hours: sessionEndMaximumHour,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });

  if (!isWithinInterval(now, { start: minDate, end: maxDate })) {
    return set(startOfDay(addDays(now, 1)), { hours: sessionStartMinimumHour });
  }

  return startOfHour(now);
};

/**
 * Calculate a date range based on the provided time range string.
 *
 * @param  {string}  timeRange  The time range identifier (e.g., 'last3Months', 'last6Months').
 *
 * @returns  {Object}  An object containing the start_date and end_date of the calculated range.
 */
export const getDateRange = (timeRange) => {
  const currentDate = new Date();

  const timeRangeLengths = {
    last3Months: 3,
    last6Months: 6,
    last9Months: 9
  };

  const endDate = subMonths(endOfMonth(currentDate), 1);
  const startDate = startOfMonth(subMonths(endDate, timeRangeLengths[timeRange] - 1));

  return {
    start_date: lightFormat(startDate, dateTimeFormat),
    end_date: lightFormat(endDate, dateTimeFormat)
  };
};

/**
 * Gets the next hour with zeroed minutes, seconds, and milliseconds if the current time is
 * within a specific range and if either minutes, seconds, or milliseconds are greater than zero.
 * If the current time is outside the 4 AM to 8 PM range, it returns null. If the current time is
 * exactly on the hour mark (with zeroed minutes, seconds, and milliseconds), it returns the current time.
 *
 * @param  {Date}  referenceDate  The reference date to use for calculating the next hour.
 *
 * @returns  {Date|null}  The next hour with minutes, seconds, and milliseconds set to 0 if
 * the current time is within the 4 AM to 8 PM range and not exactly on the hour mark.
 * Returns the current time if it's exactly on the hour mark. Returns null if the current time
 * is outside the 4 AM to 8 PM range.
 */
export const getNextHourIfTimePassed = (referenceDate = null) => {
  const now = referenceDate || new Date();
  const minDate = set(now, {
    hours: sessionStartMinimumHour,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });
  const maxDate = set(now, {
    hours: sessionEndMaximumHour,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });

  if (!isWithinInterval(now, { start: minDate, end: maxDate })) {
    return null;
  }

  if (now.getMinutes() > 0 || now.getSeconds() > 0 || now.getMilliseconds() > 0) {
    return startOfHour(addHours(now, 1));
  }

  return now;
};

/**
 * Resets the time of a given Date object to start of the hour.
 *
 * @param  {Date}  date  The date object whose time is to be reset.
 *
 * @returns  {Date}  The date object with the time reset to the start of the hour.
 */
export const resetTimeInDate = (date) => {
  if (date instanceof Date) {
    const resetDate = new Date(date);
    resetDate.setMinutes(0);
    resetDate.setSeconds(0);

    return resetDate;
  }

  return date;
};
