import React from 'react';
import { useDispatch } from 'react-redux';
import { DropzoneArea } from 'mui-file-dropzone';
import { Box, Typography } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { keyFormat } from 'common/utils/format';
import { createSnack } from 'common/utils/general';
import uiSlice from 'store/modules/ui';
import 'styles/dropzone.css';

function DropzoneFieldAdapter({ dropzoneOptions, input, label }) {
  const dispatch = useDispatch();

  const getDropRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) => {
    const { name, size } = rejectedFile;
    const message =
      size > maxFileSize
        ? `El archivo ${name} sobrepasa el límite permitido de ${maxFileSize / maxFileSize} MB`
        : `Archivo ${name} rechazado`;

    const snack = createSnack({
      key: `add-file-${keyFormat(name)}-dropzone-notification-error`,
      variant: 'error',
      message
    });

    dispatch(uiSlice.actions.enqueueSnackbar(snack));
  };

  const onChange = (files) => {
    input.onChange(files);
  };

  return (
    <Box>
      <Typography sx={{ marginBottom: (theme) => theme.spacing(1) }}>{label}</Typography>
      <DropzoneArea
        dropzoneClass="MuiDropzone"
        dropzoneParagraphClass="MuiDropzone-Paragraph"
        getDropRejectMessage={getDropRejectMessage}
        Icon={UploadFile}
        maxFileSize={10000000}
        onChange={onChange}
        previewGridClasses={{ container: 'MuiDropzone-Preview' }}
        previewText="Vista previa"
        showAlerts={false}
        {...dropzoneOptions}
      />
    </Box>
  );
}

DropzoneFieldAdapter.propTypes = {
  dropzoneOptions: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export default DropzoneFieldAdapter;
