import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledInputLabel, StyledMenuItem, StyledSelect } from 'common/theme/fields/StyledSelect';

function Select({ id, isDisabled, label, onChange, options, value }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = isDesktop ? 'medium' : 'small';

  return (
    <FormControl disabled={isDisabled} size={size}>
      <StyledInputLabel id={`${id}-label`}>{label}</StyledInputLabel>
      <StyledSelect label={label} labelId={`${id}-label`} id={id} value={value} onChange={onChange}>
        {options.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}

Select.defaultProps = {
  isDisabled: false
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Select;
