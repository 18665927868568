import { getLocalStorage, saveAuthInfo, removeAuthInfo } from 'common/utils/general';
import routes from 'common/routes';
import auth from 'api/graphql/auth';
import { persistor } from 'store';
import i18n from 'i18n';

const { REACT_APP_BASE_URL } = process.env;

const MAX_RETRY_COUNT = 3;
let retryCount = 0;
let isRefreshing = false;
let pendingRequests = [];

const getErrors = (data) => {
  let errors = [];

  if (Object.keys(data).length > 0) {
    Object.keys(data).forEach((key) => {
      const message = data[key];

      if (Array.isArray(message)) {
        errors = [...errors, message];
      } else {
        errors.push(message);
      }
    });

    return errors;
  }

  return ['Internal Error'];
};

const redirectToLogin = async () => {
  window.localStorage.clear();
  await removeAuthInfo();
  persistor.purge();
  window.location.replace(`${REACT_APP_BASE_URL}${routes.login.path}`);
};

const getNewToken = async () => {
  const refreshToken = await getLocalStorage('otx_r');

  if (!refreshToken) {
    redirectToLogin();
  }

  return auth.refreshToken(refreshToken).then((response) => {
    const { data } = response;

    return data;
  });
};

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorHandler = ({ response, operation, graphQLErrors, networkError, forward }) => {
  if (response && 'errors' in response && response.errors.length > 0) {
    response.errors.forEach(async (err, index) => {
      if ('extensions' in err && 'validation' in err.extensions) {
        const errors = getErrors(err.extensions.validation);
        response.errors[index].message = errors.join('. ');
      }

      if (err.message === 'Unauthenticated.' || err.message === 'Not Authenticated') {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const { refreshToken } = await getNewToken();
            saveAuthInfo(refreshToken);
            resolvePendingRequests();
            return forward(operation);
          } catch (error) {
            retryCount += 1;
            if (retryCount >= MAX_RETRY_COUNT) {
              redirectToLogin();
            }
          } finally {
            isRefreshing = false;
          }
        } else {
          return new Promise((resolve) => {
            pendingRequests.push(() => resolve());
          }).then(() => forward(operation));
        }
      }

      if (operation && operation.operationName === 'login') {
        response.errors[index].message = i18n.t('auth.wrongCredentials', { ns: 'errors' });
      }

      return null;
    });
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      console.log(`[GraphQL error]: ${err.message}`); // eslint-disable-line
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`); // eslint-disable-line
  }

  return null;
};

export default errorHandler;
