import { endOfDay, lightFormat, startOfDay } from 'date-fns';
import { dateTimeFormat } from 'common/constants/date';

const legalFields = {
  created_at: (value) => lightFormat(startOfDay(value), dateTimeFormat),
  default: (value) => value
};

const mealPlanFields = {
  created_at: (value) => lightFormat(startOfDay(value), dateTimeFormat),
  default: (value) => value,
  user_id: (value) => Number(value.value)
};

const newsFields = {
  created_at: (value) => lightFormat(startOfDay(value), dateTimeFormat),
  default: (value) => value
};

const paymentFields = {
  created_at: (value) => lightFormat(startOfDay(value), dateTimeFormat),
  default: (value) => value,
  end_date: (value) => lightFormat(endOfDay(value), dateTimeFormat),
  plan_id: (value) => Number(value),
  price: (value) => Number(value),
  start_date: (value) => lightFormat(startOfDay(value), dateTimeFormat),
  sessions_completed: (value) => Number(value),
  sessions_paid: (value) => Number(value),
  user_id: (value) => Number(value.value)
};

const performanceFields = {
  burpees_repetitions: (value) => Number(value),
  created_at: (value) => lightFormat(startOfDay(value), dateTimeFormat),
  default: (value) => value,
  end_date: (value) => lightFormat(endOfDay(value), dateTimeFormat),
  plank_time_minutes: (value) => Number(value),
  plank_time_seconds: (value) => Number(value),
  pull_ups_repetitions: (value) => Number(value),
  running_distance: (value) => Number(value),
  squats_repetitions: (value) => Number(value),
  start_date: (value) => lightFormat(startOfDay(value), dateTimeFormat),
  push_up_repetitions: (value) => Number(value),
  user_id: (value) => Number(value.value)
};

const planFields = {
  default: (value) => value,
  price: (value) => Number(value),
  sessions: (value) => Number(value)
};

const progressFields = {
  back_picture: (value) => value[0],
  created_at: (value) => lightFormat(startOfDay(value), dateTimeFormat),
  default: (value) => value,
  end_date: (value) => lightFormat(endOfDay(value), dateTimeFormat),
  front_picture: (value) => value[0],
  height: (value) => Number(value),
  profile_picture: (value) => value[0],
  start_date: (value) => lightFormat(startOfDay(value), dateTimeFormat),
  user_id: (value) => Number(value.value),
  weight: (value) => Number(value)
};

const sessionFields = {
  client_id: (value) => Number(value),
  default: (value) => value,
  session_type_id: (value) => Number(value),
  trainer_id: (value) => Number(value)
};

const sessionTypeFields = {
  default: (value) => value,
  price: (value) => Number(value)
};

const userFields = {
  birthdate: (value) => lightFormat(value, dateTimeFormat),
  city: (value) => value.value,
  country: (value) => JSON.stringify(value),
  default: (value) => value,
  languages: (value) => JSON.stringify(value),
  plan_id: (value) => (value !== null ? Number(value) : value),
  specialties: (value) => JSON.stringify(value),
  state: (value) => JSON.stringify(value)
};

const entity = {
  legal: legalFields,
  mealPlan: mealPlanFields,
  news: newsFields,
  payment: paymentFields,
  performance: performanceFields,
  plan: planFields,
  progress: progressFields,
  session: sessionFields,
  sessionType: sessionTypeFields,
  user: userFields
};

/**
 * Retrieves the data to be saved based on the provided data and type.
 *
 * @param {Object} data - The data object to extract the values from.
 * @param {string} type - The type of entity to determine the fields to save.
 * @returns {Object} - The extracted data to be saved.
 */
const getDataToSave = (data, type) => {
  const fields = entity[type];
  const toSave = {};

  Object.keys(data).forEach((key) => {
    if (Object.hasOwn(data, key)) {
      toSave[key] = (fields[key] || fields.default)(data[key]);
    }
  });

  return toSave;
};

export default getDataToSave;
