import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import GoBackButton from 'components/shared/buttons/GoBackButton';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import StyledNewsBodyContainer from 'common/theme/news-view/StyledNewsBodyContainer';
import StyledH2Title from 'common/theme/shared/StyledH2Title';
import { isAdmin } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import legalSlice from 'store/modules/legal';

function LegalView() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['legal', 'routes']);
  const pageTitle = t('legal.list', { ns: 'routes' });
  const { id } = useParams();
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const legal = useSelector((state) => state.legal.data.currentLegal);
  const role = useSelector((state) => state.auth.user.role);
  const status = useSelector((state) => state.legal.status);
  const uiLanguage = useSelector((state) => state.settings.config.language);

  const isAdminUser = isAdmin(role);

  useEffect(() => {
    if (role && isAdminUser) {
      dispatch(legalSlice.actions.find(id));
    } else {
      dispatch(legalSlice.actions.getLatestByRole({ language: uiLanguage, role }));
    }
  }, [dispatch, id, isAdminUser, role, uiLanguage]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle('');
  }, [setAppBarButton, setAppBarTitle]);

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      {isAdminUser && <GoBackButton />}
      <LoadingBlock
        emptyBlockProps={{ message: t('empty', { ns: 'legal' }) }}
        isValid={legal !== null}
        status={status}
        type="legal">
        {legal && (
          <>
            <StyledH2Title component="h2" align="center">
              {legal.title}
            </StyledH2Title>
            <StyledNewsBodyContainer dangerouslySetInnerHTML={{ __html: legal.body }} />
          </>
        )}
      </LoadingBlock>
    </>
  );
}

export default LegalView;
