import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Divider, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import AutocompleteFieldAdapter from 'components/shared/field-adapters/AutocompleteFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import MultipleSelectFieldAdapter from 'components/shared/field-adapters/MultipleSelectFieldAdapter';
import PasswordFieldAdapter from 'components/shared/field-adapters/PasswordFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import TextAreaFieldAdapter from 'components/shared/field-adapters/TextAreaFieldAdapter';
import {
  getGenderOptions,
  getLanguageOptions,
  getUiLanguageOptions
} from 'common/constants/options';
import { isRequiredField } from 'common/utils/general';

function TrainerFields({
  countries,
  form,
  geoStatus,
  requestStatus,
  requiredFields,
  sessionTypeOptions,
  sessionTypeStatus
}) {
  const { t } = useTranslation(['labels']);

  return (
    <>
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="first_name"
            name="first_name"
            component={TextFieldAdapter}
            label={t('firstname', { count: 2, ns: 'labels' })}
            type="text"
            fullWidth
            required={isRequiredField('first_name', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="last_name"
            name="last_name"
            component={TextFieldAdapter}
            label={t('lastname', { count: 2, ns: 'labels' })}
            type="text"
            fullWidth
            required={isRequiredField('last_name', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="gender"
            name="gender"
            component={SelectFieldAdapter}
            options={getGenderOptions(t)}
            label={t('gender', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="email"
            name="email"
            component={TextFieldAdapter}
            label={t('email', { ns: 'labels' })}
            type="text"
            fullWidth
            required={isRequiredField('email', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="birthdate"
            name="birthdate"
            component={DatePickerFieldAdapter}
            label={t('birthdate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('birthdate', requiredFields)
            }}
            disableFuture
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="phone"
            name="phone"
            component={TextFieldAdapter}
            label={t('phone', { ns: 'labels' })}
            type="text"
            fullWidth
            required={isRequiredField('phone', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="country"
            name="country"
            component={AutocompleteFieldAdapter}
            options={countries}
            renderInputProps={{
              label: t('country', { ns: 'labels' }),
              required: isRequiredField('country', requiredFields)
            }}
            loading={geoStatus === requestStatus.loading}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="ui_language"
            name="ui_language"
            component={SelectFieldAdapter}
            options={getUiLanguageOptions(t)}
            label={t('uiLanguage', { ns: 'labels' })}
            fullWidth
            required={isRequiredField('ui_language', requiredFields)}
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('profile', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="specialties"
            name="specialties"
            component={MultipleSelectFieldAdapter}
            options={sessionTypeOptions}
            label={t('specialties', { ns: 'labels' })}
            fullWidth
            required={isRequiredField('specialties', requiredFields)}
            disabled={sessionTypeStatus === requestStatus.loading}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="languages"
            name="languages"
            component={MultipleSelectFieldAdapter}
            options={getLanguageOptions(t)}
            label={t('language', { count: 2, ns: 'labels' })}
            fullWidth
            required={isRequiredField('languages', requiredFields)}
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('password', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="password"
            name="password"
            component={PasswordFieldAdapter}
            label={t('password', { ns: 'labels' })}
            autoComplete="current-password"
            onShuffle={form.mutators.generatePassword}
            fullWidth
            required={isRequiredField('password', requiredFields)}
            shuffle
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('observations', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container>
        <Grid xs={12}>
          <Field
            id="observations"
            name="observations"
            component={TextAreaFieldAdapter}
            label={t('observations', { ns: 'labels' })}
            helperText={t('text', { ns: 'validations' })}
            multiline
            minRows={5}
            type="text"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

TrainerFields.propTypes = {
  countries: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  geoStatus: PropTypes.string.isRequired,
  requestStatus: PropTypes.object.isRequired,
  requiredFields: PropTypes.array.isRequired,
  sessionTypeOptions: PropTypes.array.isRequired,
  sessionTypeStatus: PropTypes.string.isRequired
};

export default TrainerFields;
