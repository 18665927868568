import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import Point from 'components/shared/charts/shared/Point';
import palette from 'common/theme/palette';
import { getTotalOutcomeByMonthData } from 'common/utils/charts';

const areaColors = {
  COP: palette.secondary.main
};

const customChartTheme = {
  textColor: palette.white.main,
  axis: {
    ticks: {
      text: {
        fontSize: 13,
        fontWeight: 600
      }
    }
  }
};

function TotalOutcomeByMonthChart({ data }) {
  const formattedData = getTotalOutcomeByMonthData(data);

  return (
    <Stack spacing={2}>
      <Box sx={{ height: '277px' }}>
        <ResponsiveLine
          animate
          axisBottom={{
            orient: 'bottom',
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
            legend: ''
          }}
          axisLeft={null}
          axisRight={null}
          axisTop={null}
          colors={(d) => areaColors[d.id]}
          curve="natural"
          data={formattedData}
          enableArea
          enableCrosshair={false}
          enableGridX={false}
          enableGridY={false}
          enablePointLabel
          isInteractive={false}
          margin={{ top: 25, right: 25, bottom: 50, left: 25 }}
          motionConfig="molasses"
          layers={['grid', 'axes', 'areas', 'lines', Point]}
          lineWidth={4}
          legends={[]}
          pointBorderColor={{ from: 'serieColor', modifiers: [] }}
          pointBorderWidth={0}
          pointColor={{ from: 'color', modifiers: [] }}
          pointLabelYOffset={-12}
          pointSize={14}
          theme={customChartTheme}
          useMesh
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 1,
            max: 'auto',
            stacked: false,
            reverse: false
          }}
        />
      </Box>
    </Stack>
  );
}

TotalOutcomeByMonthChart.propTypes = {
  data: PropTypes.array.isRequired
};

export default TotalOutcomeByMonthChart;
