import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardBlock from 'components/shared/blocks/DashboardBlock';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import ClientsTable from 'components/shared/tables/ClientsTable';

function LatestClientsTable({ emptyMessage, itemsPerPage }) {
  const { t } = useTranslation(['clients']);

  const clients = useSelector((state) => state.client.data.clients);
  const clientStatus = useSelector((state) => state.client.status.general);
  const role = useSelector((state) => state.auth.user.role);

  const isValid = clients.length > 0;

  return (
    <DashboardBlock title={t('latestRegisteredClients', { ns: 'clients' })}>
      <LoadingBlock
        emptyBlockProps={{
          message: emptyMessage
        }}
        isValid={isValid}
        skeletonProps={{
          rows: itemsPerPage
        }}
        status={clientStatus}
        type="table">
        <ClientsTable clients={clients} rowsPerPage={itemsPerPage} role={role} />
      </LoadingBlock>
    </DashboardBlock>
  );
}

LatestClientsTable.defaultProps = {
  itemsPerPage: 5
};

LatestClientsTable.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number
};

export default LatestClientsTable;
