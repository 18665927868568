import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NotificationMenu from 'components/notifications/menu/NotificationMenu';
import LanguageSelector from 'components/shared/LanguageSelector';
import UserAvatar from 'components/shared/user/UserAvatar';
import { fullDrawerWidth, miniDrawerWidth } from 'common/constants/general';
import StyledAppbar from 'common/theme/appbar/StyledAppbar';
import StyledAppbarCountry from 'common/theme/appbar/StyledAppbarCountry';
import StyledAppbarLogoContainer from 'common/theme/appbar/StyledAppbarLogoContainer';
import StyledAppbarMainContainer from 'common/theme/appbar/StyledAppbarMainContainer';
import StyledAppbarTitle from 'common/theme/appbar/StyledAppbarTitle';
import StyledAppbarTitleContainer from 'common/theme/appbar/StyledAppbarTitleContainer';
import StyledAppbarToolbar from 'common/theme/appbar/StyledAppbarToolbar';
import StyledAppbarUserContainer from 'common/theme/appbar/StyledAppbarUserContainer';
import StyledAppbarUsername from 'common/theme/appbar/StyledAppbarUsername';
import AppBarContext from 'context/AppBarContext';
import EchoContextProvider from 'context/EchoContextProvider';
import settingsSlice from 'store/modules/settings';

import { ReactComponent as Logo } from 'images/logotype.svg';

function AppBar() {
  const dispatch = useDispatch();
  const appBarRef = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const { appBarButton, appBarTitle } = useContext(AppBarContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const language = useSelector((state) => state.settings.config.language);
  const miniDrawer = useSelector((state) => state.settings.config.miniDrawer);
  const user = useSelector((state) => state.auth.user);

  const drawerWidth = miniDrawer ? miniDrawerWidth : fullDrawerWidth;

  const handleScroll = useCallback(() => {
    const verticalScroll = window.scrollY;

    if (verticalScroll > 0 && !hasScrolled) {
      setHasScrolled(true);
    } else if (verticalScroll === 0 && hasScrolled) {
      setHasScrolled(false);
    }
  }, [hasScrolled, setHasScrolled]);

  const onLanguageChange = useCallback(
    (newLanguage) => {
      if (language !== newLanguage) {
        dispatch(
          settingsSlice.actions.update({ id: user.settings.id, input: { language: newLanguage } })
        );
      }
    },
    [dispatch, language, user.settings.id]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolled]);

  return (
    <StyledAppbar
      drawerWidth={drawerWidth}
      hasScrolled={hasScrolled}
      position="fixed"
      ref={appBarRef}>
      <StyledAppbarToolbar>
        {isDesktop && (
          <StyledAppbarTitleContainer>
            {appBarTitle && (
              <StyledAppbarTitle noWrap component="h1">
                {appBarTitle}
              </StyledAppbarTitle>
            )}
            {appBarButton}
          </StyledAppbarTitleContainer>
        )}
        <StyledAppbarMainContainer>
          <StyledAppbarLogoContainer>
            <Logo />
          </StyledAppbarLogoContainer>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: { xs: 1, md: 2 } }}>
            <EchoContextProvider>
              <NotificationMenu anchorElement={appBarRef.current} />
            </EchoContextProvider>
            <StyledAppbarUserContainer>
              <StyledAppbarUsername>{user.full_name}</StyledAppbarUsername>
              <StyledAppbarCountry>{user.country?.label}</StyledAppbarCountry>
            </StyledAppbarUserContainer>
            <UserAvatar border size={isSmall ? 30 : 40} textSize={isSmall ? 15 : 20} user={user} />
            <LanguageSelector selectedLanguage={language} onSelect={onLanguageChange} />
          </Box>
        </StyledAppbarMainContainer>
      </StyledAppbarToolbar>
    </StyledAppbar>
  );
}

export default AppBar;
