import { Typography, styled } from '@mui/material';

const StyledProfilePictureInitials = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontSize: theme.typography.pxToRem(56),
  fontWeight: '500',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(66)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(96)
  }
}));

export default StyledProfilePictureInitials;
