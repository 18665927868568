import {
  hasValue,
  isText,
  isUrl,
  isValidDate,
  isEmptyNumber,
  positiveInt
} from 'common/validations/field';
import i18n from 'i18n';

const defaultPaymentFormValidations = (values) => {
  const errors = {};

  if (!isValidDate(values.created_at)) {
    errors.created_at = i18n.t('date', { ns: 'validations' });
  }

  if (!isValidDate(values.end_date)) {
    errors.end_date = i18n.t('date', { ns: 'validations' });
  }

  if (hasValue(values.invoice_url) && !isUrl(values.invoice_url)) {
    errors.invoice_url = i18n.t('url', { ns: 'validations' });
  }

  if (hasValue(values.observations) && !isText(values.observations)) {
    errors.observations = i18n.t('text', { ns: 'validations' });
  }

  if (isEmptyNumber(values.price)) {
    errors.price = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!positiveInt(values.price)) {
    errors.price = i18n.t('positiveInteger', { ns: 'validations' });
  }

  if ('sessions_completed' in values && isEmptyNumber(values.sessions_completed)) {
    errors.sessions_completed = i18n.t('emptyField', { ns: 'validations' });
  }

  if ('sessions_completed' in values && !positiveInt(values.sessions_completed)) {
    errors.sessions_completed = i18n.t('positiveInteger', { ns: 'validations' });
  }

  if ('sessions_paid' in values && isEmptyNumber(values.sessions_paid)) {
    errors.sessions_paid = i18n.t('emptyField', { ns: 'validations' });
  }

  if ('sessions_paid' in values && !positiveInt(values.sessions_paid)) {
    errors.sessions_paid = i18n.t('positiveInteger', { ns: 'validations' });
  }

  if (!isValidDate(values.start_date)) {
    errors.start_date = i18n.t('date', { ns: 'validations' });
  }

  if (!hasValue(values.user_id)) {
    errors.user_id = i18n.t('emptyField', { ns: 'validations' });
  }

  return errors;
};

export default defaultPaymentFormValidations;
