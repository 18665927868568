import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, FormSpy } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import ClientFields from 'components/client/ClientFields';
import validateCreateClientForm from 'common/validations/form/createClient';
import { createClientInitialValues } from 'common/form/initial-values/create';
import { generatePassword } from 'common/form/utils';
import getDataToSave from 'common/form/dataToSave';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import AppBarContext from 'context/AppBarContext';
import geoSlice from 'store/modules/geo';
import clientSlice from 'store/modules/client';

const requiredFields = [
  'first_name',
  'last_name',
  'email',
  'birthdate',
  'phone',
  'country',
  'state',
  'city',
  'ui_language',
  'plan_id',
  'password'
];

function ClientCreate() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('client.create', { ns: 'routes' });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const formRef = useRef(null);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const cities = useSelector((state) => state.geo.cities);
  const countries = useSelector((state) => state.geo.countries);
  const geoStatus = useSelector((state) => state.geo.status);
  const planOptions = useSelector((state) => state.plan.planOptions);
  const planStatus = useSelector((state) => state.plan.status);
  const states = useSelector((state) => state.geo.states);
  const status = useSelector((state) => state.client.status.general);

  useEffect(() => {
    if (geoStatus !== requestStatus.loading && countries.length <= 0) {
      dispatch(geoSlice.actions.getCountries());
    }
  }, []);

  useEffect(() => {
    if (status === requestStatus.success) {
      formRef.current.restart();
    }
  }, [status]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onCreate = useCallback(
    (values) => {
      const dataToSave = getDataToSave(values, 'user');
      dispatch(clientSlice.actions.create(dataToSave));
    },
    [dispatch]
  );

  const onFormChange = (formState) => {
    const { values } = formState;

    // On country change, get the list of all country's states and reset the states and city fields
    if (values.country && values.country.label !== selectedCountry) {
      dispatch(geoSlice.actions.getStates(values.country.label));
      setSelectedCountry(values.country.label);
      setSelectedState(null);

      if (values.state) formRef.current.change('state', null);
      if (values.city) formRef.current.change('city', null);
    }

    // On state change, get the list of all state's cities and reset the city field
    if (values.state && values.state.label !== selectedState) {
      dispatch(
        geoSlice.actions.getCities({ country: values.country.label, state: values.state.label })
      );
      setSelectedState(values.state.label);

      if (values.city) formRef.current.change('city', null);
    }
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={createClientInitialValues}
        mutators={{
          generatePassword
        }}
        onSubmit={onCreate}
        validate={validateCreateClientForm}
        render={({ form, handleSubmit, invalid, pristine, values }) => {
          formRef.current = form;

          return (
            <form onSubmit={handleSubmit}>
              <ClientFields
                cities={cities}
                countries={countries}
                form={form}
                geoStatus={geoStatus}
                planOptions={planOptions}
                planStatus={planStatus}
                requiredFields={requiredFields}
                states={states}
                values={values}
              />
              <StyledButtonsContainer>
                <SecondaryButtonLoading
                  component={RouterLink}
                  disabled={status === requestStatus.loading}
                  style={{ marginRight: '32px' }}
                  label={t('cancel', { ns: 'buttons' })}
                  to={routes.listClient.path}
                  type="button"
                />
                <PrimaryLoadingButton
                  disabled={invalid || pristine || status === requestStatus.loading}
                  loading={status === requestStatus.loading}
                  label={t('client.create', { ns: 'buttons' })}
                  type="submit"
                />
              </StyledButtonsContainer>
              <FormSpy onChange={onFormChange} subscription={{ values: true }} />
            </form>
          );
        }}
      />
    </>
  );
}

export default ClientCreate;
