import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Skeleton } from '@mui/material';
import Emoji from 'components/shared/Emoji';
import requestStatus from 'common/constants/status';
import StyledNotificationEmptyMessage from 'common/theme/notification/StyledNotificationEmptyMessage';

function getSkeleton() {
  return (
    <>
      {Array.from({ length: 5 }, (_, i) => (
        <MenuItem key={i}>
          <Skeleton variant="rounded" />
        </MenuItem>
      ))}
    </>
  );
}

function NotificationMenuList({ emptyMessage, isValid, renderMenuItemsFunc, status }) {
  return (
    <>
      {(status === requestStatus.idle || status === requestStatus.loading) && getSkeleton()}
      {(status === requestStatus.error || (status === requestStatus.success && !isValid)) && (
        <StyledNotificationEmptyMessage disableGutters>
          {emptyMessage} <Emoji label={emptyMessage} symbol="😁" />
        </StyledNotificationEmptyMessage>
      )}
      {status === requestStatus.success && isValid && renderMenuItemsFunc()}
    </>
  );
}

NotificationMenuList.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  renderMenuItemsFunc: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired
};

export default NotificationMenuList;
