import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import StyledOverviewProfileDetailContainer from 'common/theme/overview-profile/StyledOverviewProfileDetailContainer';
import StyledOverviewProfileDetailLabel from 'common/theme/overview-profile/StyledOverviewProfileDetailLabel';
import StyledOverviewProfileDetailValue from 'common/theme/overview-profile/StyledOverviewProfileDetailValue';

function OverviewProfileDetail({ label, loading, value }) {
  return (
    <StyledOverviewProfileDetailContainer>
      <StyledOverviewProfileDetailLabel variant="subtitle2">
        {loading ? <Skeleton width={80} /> : label}
      </StyledOverviewProfileDetailLabel>
      {loading ? (
        <Skeleton variant="rounded" height={40} />
      ) : (
        <StyledOverviewProfileDetailValue>{value}</StyledOverviewProfileDetailValue>
      )}
    </StyledOverviewProfileDetailContainer>
  );
}

OverviewProfileDetail.defaultProps = {
  loading: false,
  value: null
};

OverviewProfileDetail.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.oneOf([null])])
};

export default OverviewProfileDetail;
