import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import UserAvatar from 'components/shared/user/UserAvatar';
import StyledUserProfileColumn from 'common/theme/columns/user-profile/StyledUserProfileColumn';
import StyledUserProfileColumnName from 'common/theme/columns/user-profile/StyledUserProfileColumnName';

function UserProfileColumn({ data, options }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <StyledUserProfileColumn options={options}>
      <UserAvatar size={isDesktop ? 37 : 27} textSize={isDesktop ? 17 : 14} user={data} />
      <StyledUserProfileColumnName>{data.full_name}</StyledUserProfileColumnName>
    </StyledUserProfileColumn>
  );
}

UserProfileColumn.defaultProps = {
  options: {}
};

UserProfileColumn.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object
};

export default UserProfileColumn;
