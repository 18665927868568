import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _camelCase from 'lodash/camelCase';
import { Box, Typography } from '@mui/material';
import { PodiumGold, TimerCheck } from 'mdi-material-ui';
import UserAvatar from 'components/shared/user/UserAvatar';

function TopRankedPerformer({ customStyles, data, test }) {
  if (!data) {
    return null;
  }

  const { t } = useTranslation(['performances']);
  const { label, place, user } = data;
  const testResult = t(`${_camelCase(test)}.result`, { num: label, ns: 'performances' });

  return (
    <Box
      sx={{
        alignItems: 'center',
        columnGap: 2,
        display: 'flex',
        justifyContent: 'center',
        ...customStyles
      }}>
      <UserAvatar size={70} textSize={30} user={user} />
      <div>
        <Typography variant="body1">
          {user.first_name} {user.last_name}
        </Typography>
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 0.5
          }}>
          <Box
            sx={{
              alignItems: 'flex-end',
              display: 'flex'
            }}>
            <PodiumGold
              sx={{ color: 'secondary.main', fontSize: (theme) => theme.typography.pxToRem(18) }}
            />
            <Typography variant="body2" sx={{ lineHeight: 1, marginLeft: 0.5 }}>
              {t('place', { ns: 'performances' })}: {place}
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'flex-end',
              display: 'flex',
              marginTop: 1
            }}>
            <TimerCheck
              sx={{ color: 'secondary.main', fontSize: (theme) => theme.typography.pxToRem(18) }}
            />
            <Typography variant="body2" sx={{ lineHeight: 1, marginLeft: 0.5 }}>
              {t('result', { num: label, ns: 'performances' })}: {testResult}
            </Typography>
          </Box>
        </Box>
      </div>
    </Box>
  );
}

TopRankedPerformer.defaultProps = {
  customStyles: {},
  data: null
};

TopRankedPerformer.propTypes = {
  customStyles: PropTypes.object,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    place: PropTypes.number.isRequired,
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      photo: PropTypes.string
    }).isRequired
  }),
  test: PropTypes.string.isRequired
};

export default TopRankedPerformer;
