import { Chip, styled } from '@mui/material';
import { chipClasses } from '@mui/material/Chip';
import defaultChipStyles from 'common/theme/chips/defaultChipStyles';

const StyledChip = styled(Chip)(({ theme }) => ({
  ...defaultChipStyles,
  [theme.breakpoints.down('lg')]: {
    height: '26px',
    fontSize: theme.typography.pxToRem(11)
  },
  [`& .${chipClasses.label}`]: {
    [theme.breakpoints.down('lg')]: {
      padding: '0 8px'
    }
  }
}));

export default StyledChip;
