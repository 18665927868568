import React from 'react';
import localforage from 'localforage';
import _camelCase from 'lodash/camelCase';
import SnackCloseButton from 'components/shared/buttons/SnackCloseButton';

export const camelCaseKeys = (object) =>
  Object.entries(object).reduce((current, [key, value]) => {
    current[_camelCase(key)] = value;
    return current;
  }, {});

export const cleanApiDataResponse = (data) => {
  if (Array.isArray(data)) {
    return data.map(cleanApiDataResponse);
  }
  if (typeof data === 'object' && data !== null) {
    const newData = {};

    Object.keys(data).forEach((key) => {
      if (key !== '__typename') {
        newData[key] = cleanApiDataResponse(data[key]);
      }
    });

    return newData;
  }

  return data;
};

export const createSnack = (options) => {
  const { key = new Date().getTime() + Math.random(), variant = 'error', message = '' } = options;
  const randomString = new Date().getTime() + Math.random();

  return {
    notification: {
      message,
      options: {
        key: `${key}-${randomString}`,
        variant,
        action: (snackKey) => <SnackCloseButton snackKey={snackKey} />
      }
    },
    key: `${key}-${randomString}`
  };
};

export const downloadFile = (url, filename) => {
  if (url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = filename;
    link.click();
    link.remove();
  }
};

export const getInteractionType = () => {
  const isTouchDevice =
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  return isTouchDevice ? 'tap' : 'click';
};

export const getLocalStorage = async (key) => {
  try {
    const value = await localforage.getItem(key);
    return value;
  } catch (error) {
    return error;
  }
};

export const getUiLanguage = (browserLanguage) => {
  if (browserLanguage.length > 2) {
    return `${browserLanguage.slice(0, 2).toLowerCase()}`;
  }

  return browserLanguage.toLowerCase();
};

export const getUserFriendlyError = (ex) => {
  if ('message' in ex) {
    return ex.message.replace(/Error:/i, '');
  }

  return ex.replace(/Error:/i, '');
};

export const isRequiredField = (field, fields) => fields.includes(field);

export const generateRandomKey = (length = 32) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result + Date.now();
};

export const removeLocalStorage = async (key) => {
  try {
    const value = await localforage.removeItem(key);
    return value;
  } catch (error) {
    return error;
  }
};

export const removeAuthInfo = async () => {
  await Promise.all([removeLocalStorage('otx_a'), removeLocalStorage('otx_r')]);
};

export const saveLocalStorage = async (key, value) => {
  try {
    const savedValue = await localforage.setItem(key, value);
    return savedValue;
  } catch (error) {
    return error;
  }
};

export const saveAuthInfo = async (data) => {
  await Promise.all([
    saveLocalStorage('otx_a', data.access_token),
    saveLocalStorage('otx_r', data.refresh_token)
  ]);
};

export const tabsA11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`
});
