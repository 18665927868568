export const clientPaymentCountersFields = `
  currentMonthIncomeInCop
  currentMonthIncomeInUsd
`;

export const clientPaymentFindFields = `
  id
  created_at
  currency
  end_date
  invoice_url
  observations
  payment_method
  plan_id {
    id
    name
    price
    sessions
  }
  price
  sessions_paid
  start_date
  user_id {
    id
    full_name
  }
`;

export const clientPaymentHistoryFields = `
  id
  created_at
  currency
  end_date
  invoice_url
  payment_method
  price
  plan_id {
    id
    name
  }
  sessions_paid
  start_date
`;

export const clientPaymentListFields = `
  id
  created_at
  currency
  end_date
  invoice_url
  observations
  payment_method
  price
  plan_id {
    id
    name
  }
  sessions_paid
  start_date
  user_id {
    full_name
    first_name
    last_name
    membership_status
    photo
  }
`;

export const clientPaymentListBasicFields = `
  created_at
  currency
  end_date
  price
  sessions_paid
  start_date
  user_id {
    full_name
  }
`;

export const totalIncomeByMonthFields = `
  COP {
    date
    total
  }
  USD {
    date
    total
  }
`;
