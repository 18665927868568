import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import AdminFields from 'components/admin/AdminFields';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import validateEditAdminForm from 'common/validations/form/editAdmin';
import { updateAdminInitialValues } from 'common/form/initial-values/update';
import getDataToSave from 'common/form/dataToSave';
import { generatePassword, getFieldsToUpdate } from 'common/form/utils';
import { adminFindFields } from 'common/constants/query-fields/admin';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import AppBarContext from 'context/AppBarContext';
import adminSlice from 'store/modules/admin';

const requiredFields = ['first_name', 'last_name', 'email', 'birthdate', 'ui_language'];

function AdminEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const { id } = useParams();
  const pageTitle = t('administrator.edit', { ns: 'routes' });
  const [initialValues, setInitialValues] = useState({});
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const admin = useSelector((state) => state.admin.currentAdmin);
  const status = useSelector((state) => state.admin.status.general);

  useEffect(() => {
    dispatch(adminSlice.actions.find({ fields: adminFindFields, id }));
  }, []);

  useEffect(() => {
    if (admin && Object.keys(admin).length > 0 && admin.id === id) {
      const userData = updateAdminInitialValues(admin);
      setInitialValues(userData);
    }
  }, [admin]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onEdit = useCallback(
    (values) => {
      const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
      const dataToSave = getDataToSave(fieldsToUpdate, 'user');
      dispatch(adminSlice.actions.update({ id, input: dataToSave }));
    },
    [dispatch, id, initialValues]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={initialValues}
        mutators={{
          generatePassword
        }}
        onSubmit={onEdit}
        validate={validateEditAdminForm}
        render={({ form, handleSubmit, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <AdminFields form={form} requiredFields={requiredFields} />
            <Box sx={{ marginTop: '60px', textAlign: 'right' }}>
              <SecondaryButtonLoading
                component={RouterLink}
                disabled={status === requestStatus.loading}
                style={{ marginRight: '32px' }}
                label={t('cancel', { ns: 'buttons' })}
                to={routes.listAdmin.path}
                type="button"
              />
              <PrimaryLoadingButton
                disabled={invalid || pristine || status === requestStatus.loading}
                loading={status === requestStatus.loading}
                label={t('administrator.edit', { ns: 'buttons' })}
                type="submit"
              />
            </Box>
          </form>
        )}
      />
    </>
  );
}

export default AdminEdit;
