import { takeLatest, put } from 'redux-saga/effects';
import performanceSlice from 'store/modules/performance';
import uiSlice from 'store/modules/ui';
import performance from 'api/graphql/performance';
import { cleanApiDataResponse, createSnack, getUserFriendlyError } from 'common/utils/general';
import createPerformanceList from 'store/sagas/hof/performance';
import i18n from 'i18n';

/**
 * Create performance saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'performance-create-notification-success',
      variant: 'success',
      message: i18n.t('performance.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield performance.create(payload);
    yield put(performanceSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(performanceSlice.actions.createError(message));
  }
}

/**
 * Find performance saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield performance.find(action.payload);
    const {
      data: { performance: currentPerformance }
    } = response;

    yield put(performanceSlice.actions.findSuccess(currentPerformance));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(performanceSlice.actions.findError(message));
  }
}

/**
 * Get performance history saga
 *
 * @param {*} action
 */
function* getHistory(action) {
  try {
    const response = yield performance.history(action.payload);

    const {
      data: { performanceHistory }
    } = response;

    yield put(performanceSlice.actions.getHistorySuccess(cleanApiDataResponse(performanceHistory)));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(performanceSlice.actions.getHistoryError(message));
  }
}

/**
 * Get latest performance saga
 *
 * @param {*} action
 */
function* getLatest(action) {
  try {
    const response = yield performance.getLatest(Number(action.payload));

    const {
      data: { latestPerformance }
    } = response;

    yield put(performanceSlice.actions.getLatestSuccess(latestPerformance));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(performanceSlice.actions.getLatestError(message));
  }
}

/**
 * Remove performance saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deletePerformances: removedPerformance }
    } = yield performance.remove(action.payload);

    const snack = createSnack({
      key: 'remove-performance-notification-success',
      variant: 'success',
      message: i18n.t('performance.delete', {
        count: removedPerformance.length,
        ns: 'notifications'
      })
    });

    yield put(performanceSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(performanceSlice.actions.removeError(message));
  }
}

/**
 * Restore performance saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restorePerformances: restoredPerformance }
    } = yield performance.restore(action.payload);

    const snack = createSnack({
      key: 'restore-performance-notification-success',
      variant: 'success',
      message: i18n.t('performance.restore', {
        count: restoredPerformance.length,
        ns: 'notifications'
      })
    });

    yield put(performanceSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(performanceSlice.actions.restoreError(message));
  }
}

/**
 * Top ranked performance saga
 *
 * @param {*} action
 */
function* topRanked(action) {
  try {
    const response = yield performance.topRanked(action.payload);

    const {
      data: { topRankedPerformances: topRankedObj }
    } = response;

    yield put(performanceSlice.actions.topRankedSuccess(topRankedObj));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(performanceSlice.actions.topRankedError(message));
  }
}

/**
 * Update performance saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-performance-notification-success',
      variant: 'success',
      message: i18n.t('performance.update', { ns: 'notifications' })
    });

    yield performance.update(action.payload);

    yield put(performanceSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(performanceSlice.actions.updateError(message));
  }
}

/**
 * Watch performance
 *
 * @export
 */
export default function* watchPerformance() {
  yield takeLatest(performanceSlice.actions.create, create);
  yield takeLatest(performanceSlice.actions.find, find);
  yield takeLatest(performanceSlice.actions.getHistory, getHistory);
  yield takeLatest(performanceSlice.actions.getLatest, getLatest);
  yield takeLatest(
    performanceSlice.actions.list,
    createPerformanceList(performanceSlice.actions.listSuccess, performanceSlice.actions.listError)
  );
  yield takeLatest(
    performanceSlice.actions.listIncomplete,
    createPerformanceList(
      performanceSlice.actions.listIncompleteSuccess,
      performanceSlice.actions.listIncompleteError
    )
  );
  yield takeLatest(performanceSlice.actions.remove, remove);
  yield takeLatest(performanceSlice.actions.restore, restore);
  yield takeLatest(performanceSlice.actions.topRanked, topRanked);
  yield takeLatest(performanceSlice.actions.update, update);
}
