import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DoubleCounter from 'components/shared/counters/double/DoubleCounter';
import Emoji from 'components/shared/Emoji';

function SessionReminderCounters() {
  const { t } = useTranslation(['sessions']);

  const tomorrowSessionsCounter = useSelector((state) => state.session.counters.tomorrow);
  const pendingToUpdateSessionsCounter = useSelector(
    (state) => state.session.counters.pendingToUpdate
  );

  return (
    <DoubleCounter
      emoji={<Emoji label={t('reminders', { ns: 'common' })} symbol="☝️" />}
      title={t('reminders', { ns: 'common' })}
      counters={[
        {
          title: t('sessionsTomorrow', { ns: 'sessions' }),
          counter: tomorrowSessionsCounter
        },
        {
          title: t('sessionsToReport', { ns: 'sessions' }),
          counter: pendingToUpdateSessionsCounter
        }
      ]}
    />
  );
}

export default SessionReminderCounters;
