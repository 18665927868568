import styledComponent from 'styled-components';

const getGradient = (type) => {
  switch (type) {
    case 'repetition':
      return `linear-gradient(90deg, rgba(27, 35, 191, 0.90) 0%, #273BD7 85.18%, #273BD7 100%);`;
    default:
      return `linear-gradient(180deg, #C08300 33.62%, #F8B231 99.74%);`;
  }
};

const StyledLatestPerformanceCardLegendColor = styledComponent.span`
  background: ${(props) => getGradient(props.type)};
  border-radius: 4px;
  display: inline-block;
  height: 15px;
  width: 30px;
`;

export default StyledLatestPerformanceCardLegendColor;
