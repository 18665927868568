import React from 'react';
import { Box, styled } from '@mui/material';
import { isAdmin } from 'common/utils/user';

const StyledSessionCardParticipants = styled(({ viewType, ...otherProps }) => (
  <Box {...otherProps} />
))(({ viewType }) => ({
  alignItems: isAdmin(viewType) ? 'flex-start' : 'center',
  display: 'flex',
  flexDirection: 'column'
}));

export default StyledSessionCardParticipants;
