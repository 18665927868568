import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledEmptyResultBlockContainer from 'common/theme/empty-result-block/StyledEmptyResultBlockContainer';
import StyledEmptyResultBlockText from 'common/theme/empty-result-block/StyledEmptyResultBlockText';

import { ReactComponent as CalendarImg } from 'images/calendar.svg';
import { ReactComponent as EmptyResultManImg } from 'images/empty_result_man.svg';
import { ReactComponent as EmptyResultWomanImg } from 'images/empty_result_woman.svg';

const getEmptyResultImage = (height, type, width) => {
  const imageTag = {
    calendar: () => <CalendarImg style={{ height, maxWidth: '100%', width }} />,
    man: () => <EmptyResultManImg style={{ height, maxWidth: '100%', width }} />,
    text: () => null,
    woman: () => <EmptyResultWomanImg style={{ height, maxWidth: '100%', width }} />
  };

  return imageTag[type]();
};

function EmptyResultBlock({ imageType, imageWidth, imageHeight, message }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const height = isDesktop ? imageHeight : 'auto';
  const width = isDesktop ? imageWidth : '150px';

  return (
    <StyledEmptyResultBlockContainer>
      {getEmptyResultImage(height, imageType, width)}
      <StyledEmptyResultBlockText>{message}</StyledEmptyResultBlockText>
    </StyledEmptyResultBlockContainer>
  );
}

EmptyResultBlock.defaultProps = {
  imageHeight: 'auto',
  imageWidth: 'auto',
  imageType: 'woman'
};

EmptyResultBlock.propTypes = {
  message: PropTypes.string.isRequired,
  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string,
  imageType: PropTypes.oneOf(['calendar', 'man', 'text', 'woman'])
};

export default EmptyResultBlock;
