import { backdropClasses } from '@mui/material/Backdrop';
import { Dialog, styled } from '@mui/material';
import { dialogClasses } from '@mui/material/Dialog';

const StyledSessionDetailsDialog = styled(Dialog)(({ theme }) => ({
  [`& .${backdropClasses.root}`]: {
    backgroundColor: 'rgba(31,35,39, 0.5)'
  },
  [`& .${dialogClasses.paper}`]: {
    backgroundColor: theme.palette.gray3.main,
    backgroundImage: 'none',
    borderRadius: '8px',
    boxShadow: '0 0 5px 4px rgba(0, 0, 0, 0.1)',
    padding: `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
    [theme.breakpoints.up('md')]: {
      borderRadius: '10px'
    }
  }
}));

export default StyledSessionDetailsDialog;
