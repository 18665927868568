const newsRoutes = {
  listNews: {
    path: '/news'
  },
  createNews: {
    path: '/news/create'
  },
  editNews: {
    path: '/news/edit/:id'
  },
  viewNews: {
    path: '/news/:id'
  }
};

export default newsRoutes;
