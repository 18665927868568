import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import StyledMainMenuCollapseButton from 'common/theme/menu/StyledMainMenuCollapseButton';
import StyledCollapsibleMenuIcon from 'common/theme/icon/StyledCollapsibleMenuIcon';
import StyledCollapsibleMenuItemIcon from 'common/theme/icon/StyledCollapsibleMenuItemIcon';
import StyledCollapsibleMenuLisItemButtom from 'common/theme/button/StyledCollapsibleMenuLisItemButtom';
import StyledMenuItemLink from 'common/theme/links/StyledMenuItemLink';
import StyledMenuItemText from 'common/theme/typography/text/StyledMenuItemText';

function CollapsibleMenuItemToggle({ icon, isActive, label, noLink, onClick, open, path }) {
  return (
    <StyledCollapsibleMenuLisItemButtom noLink={noLink} selected={isActive}>
      {noLink ? (
        <StyledMainMenuCollapseButton
          variant="text"
          endIcon={open ? <ExpandLess /> : <ExpandMore />}
          onClick={onClick}
          startIcon={icon}>
          {label}
        </StyledMainMenuCollapseButton>
      ) : (
        <>
          <StyledMenuItemLink component={NavLink} to={path}>
            <StyledCollapsibleMenuItemIcon>{icon}</StyledCollapsibleMenuItemIcon>
            <StyledMenuItemText primary={label} />
          </StyledMenuItemLink>
          <StyledCollapsibleMenuIcon aria-label="collapse" onClick={onClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </StyledCollapsibleMenuIcon>
        </>
      )}
    </StyledCollapsibleMenuLisItemButtom>
  );
}

CollapsibleMenuItemToggle.defaultProps = {
  isActive: false,
  noLink: false
};

CollapsibleMenuItemToggle.propTypes = {
  icon: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  noLink: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
};

export default CollapsibleMenuItemToggle;
