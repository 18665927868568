import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLottie } from 'lottie-react';
import SingleCounter from 'components/shared/counters/single/SingleCounter';
import { defaultLottieOptions } from 'common/constants/general';

import trainingIcon from 'lottie/training_icon.json';

function SessionFinishedTodayCounter() {
  const { t } = useTranslation(['sessions']);

  const todayFinishedSessionsCounter = useSelector((state) => state.session.counters.finishedToday);
  const todaySessionsCounter = useSelector((state) => state.session.counters.today);

  const unfinishedSessions = todaySessionsCounter - todayFinishedSessionsCounter;

  const getCounterLines = () => {
    if (todaySessionsCounter === 0) {
      return [t('clearSchedule'), ''];
    }
    return [
      unfinishedSessions === 0
        ? t('training')
        : `${unfinishedSessions} ${t('session', { count: unfinishedSessions })}`,
      unfinishedSessions === 0
        ? t('completed', { ns: 'common' })
        : t('toComplete', { ns: 'common' })
    ];
  };

  const [line1, line2] = getCounterLines();

  const lottieOptions = {
    ...defaultLottieOptions,
    animationData: trainingIcon
  };

  const { View } = useLottie(lottieOptions);

  return (
    <SingleCounter lottieIcon={View} title={t('today', { ns: 'common' })} lines={[line1, line2]} />
  );
}

export default SessionFinishedTodayCounter;
