import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { Unstable_Grid2 as Grid } from '@mui/material';
import StyledCounterSectionTitle from 'common/theme/counter/StyledCounterSectionTitle';
import StyledCounterSectionNumber from 'common/theme/counter/StyledCounterSectionNumber';
import { formatWithNumberSuffix } from 'common/utils/format';

function CounterSection({ title, counter, type }) {
  const currencyFormat = (value) => formatWithNumberSuffix(value, '$', 2);

  return (
    <Grid xs={6} sx={{ padding: '0 !important' }}>
      <StyledCounterSectionTitle align="center" color="secondary">
        {title}
      </StyledCounterSectionTitle>
      <StyledCounterSectionNumber align="center">
        {type === 'number' ? (
          <CountUp start={-50} duration={0.5} end={counter} />
        ) : (
          <CountUp start={-50} duration={0.5} end={counter} formattingFn={currencyFormat} />
        )}
      </StyledCounterSectionNumber>
    </Grid>
  );
}

CounterSection.defaultProps = {
  counter: 0,
  type: 'number'
};

CounterSection.propTypes = {
  counter: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['number', 'currency'])
};

export default CounterSection;
