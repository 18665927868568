import {
  alphaNumericSpace,
  hasValue,
  isText,
  isUrl,
  lengthBetween,
  numberOnly
} from 'common/validations/field';
import defaultTrainerFormValidations from 'common/validations/form/defaultTrainer';
import i18n from 'i18n';

const validateEditTrainerProfileForm = (values) => {
  const errors = defaultTrainerFormValidations(values);

  if (!hasValue(values.photo)) {
    errors.photo = i18n.t('profilePicture', { ns: 'validations' });
  }

  if (!numberOnly(values.identification_number)) {
    errors.identification_number = i18n.t('onlyNumber', { ns: 'validations' });
  }

  if (!lengthBetween(values.identification_number, 6, 20)) {
    errors.identification_number = i18n.t('lengthBetweenNumber', {
      min: '6',
      max: '20',
      ns: 'validations'
    });
  }

  if (!hasValue(values.phone)) {
    errors.phone = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!hasValue(values.eps)) {
    errors.eps = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.eps) && !alphaNumericSpace(values.eps)) {
    errors.eps = i18n.t('alphaNumericSpace', { ns: 'validations' });
  }

  if (!hasValue(values.contact_name)) {
    errors.contact_name = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.contact_name) && !alphaNumericSpace(values.contact_name)) {
    errors.contact_name = i18n.t('alphaNumericSpace', { ns: 'validations' });
  }

  if (!hasValue(values.contact_phone)) {
    errors.contact_phone = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.contact_phone) && !lengthBetween(values.contact_phone, 7, 10)) {
    errors.contact_phone = i18n.t('lengthBetweenNumber', {
      min: '7',
      max: '10',
      ns: 'validations'
    });
  }

  if (hasValue(values.password) && values.password !== values.confirm_password) {
    errors.confirm_password = i18n.t('passwordMatch', { ns: 'validations' });
  }

  if (!hasValue(values.online_meeting_url)) {
    errors.online_meeting_url = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.online_meeting_url) && !isUrl(values.online_meeting_url)) {
    errors.online_meeting_url = i18n.t('url', { ns: 'validations' });
  }

  if (!hasValue(values.academic_formation)) {
    errors.academic_formation = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.academic_formation) && !isText(values.academic_formation)) {
    errors.academic_formation = i18n.t('text', { ns: 'validations' });
  }

  if (!hasValue(values.experience)) {
    errors.experience = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.experience) && !isText(values.experience)) {
    errors.experience = i18n.t('text', { ns: 'validations' });
  }

  if (!hasValue(values.bank)) {
    errors.bank = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.bank) && !alphaNumericSpace(values.bank)) {
    errors.bank = i18n.t('alphaNumericSpace', { ns: 'validations' });
  }

  if (!hasValue(values.bank_account_type)) {
    errors.bank_account_type = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!numberOnly(values.bank_account_number)) {
    errors.bank_account_number = i18n.t('onlyNumber', { ns: 'validations' });
  }

  if (!lengthBetween(values.bank_account_number, 6, 20)) {
    errors.bank_account_number = i18n.t('lengthBetweenNumber', {
      min: '6',
      max: '20',
      ns: 'validations'
    });
  }

  if (!hasValue(values.bank_account_certificate)) {
    errors.bank_account_certificate = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!hasValue(values.identification_document)) {
    errors.identification_document = i18n.t('emptyField', { ns: 'validations' });
  }

  return errors;
};

export default validateEditTrainerProfileForm;
