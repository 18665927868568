import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { enUS, es } from 'date-fns/locale';
import MUIDataTable from 'mui-datatables';
import { getFinishedSessionColumns } from 'common/table/columns/session';
import { getFinishedSessionData } from 'common/table/data/session';
import { defaultTableOptions, tableLocalization } from 'common/table/utils';

function FinishedSessionsTable({ role, rowsPerPage, sessions }) {
  const { t, i18n } = useTranslation(['routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const tableData = getFinishedSessionData(sessions, role, dateLocale);
  const columns = getFinishedSessionColumns({ data: tableData, role, translateFunc: t });

  const options = {
    ...defaultTableOptions,
    jumpToPage: false,
    rowsPerPage,
    selectableRows: 'none',
    serverSide: false,
    textLabels: tableLocalization(t('session.list', { ns: 'routes' }).toLowerCase(), t)
  };

  return (
    <MUIDataTable
      className="finishedSessions"
      columns={columns}
      data={tableData}
      options={options}
    />
  );
}

FinishedSessionsTable.defaultProps = {
  rowsPerPage: 7
};

FinishedSessionsTable.propTypes = {
  role: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number,
  sessions: PropTypes.array.isRequired
};

export default FinishedSessionsTable;
