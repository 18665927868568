import { hasValue, isEmptyNumber, isText } from 'common/validations/field';
import i18n from 'i18n';

const defaultSessionFormValidations = (values) => {
  const errors = {};

  if (!hasValue(values.client_id)) {
    errors.client_id = i18n.t('emptyField', { ns: 'validations' });
  }

  if (isEmptyNumber(values.session_type_id)) {
    errors.session_type_id = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!hasValue(values.trainer_id)) {
    errors.trainer_id = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.observations) && !isText(values.observations)) {
    errors.observations = i18n.t('text', { ns: 'validations' });
  }

  return errors;
};

export default defaultSessionFormValidations;
