import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import AlertBlock from 'components/shared/blocks/AlertBlock';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import { createClientSessionSearchFields } from 'common/constants/query-fields/client';
import requestStatus from 'common/constants/status';
import { handleClientIdChange, isAdmin } from 'common/utils/user';
import useForms from 'hooks/useForms';

function SessionFieldsClientSelection({ isDisabled, values }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels']);
  const { clearClientSearchResults } = useForms();

  const clientOptions = useSelector((state) => state.client.data.search);
  const role = useSelector((state) => state.auth.user.role);
  const searchStatus = useSelector((state) => state.client.status.search);
  const sessionsInRange = useSelector((state) => state.session.sessionsInRange);

  const isAdminUser = isAdmin(role);

  const onClientIdChange = handleClientIdChange(dispatch, createClientSessionSearchFields);

  return (
    <Grid container spacing={4}>
      {isAdminUser && (
        <Grid xs={12}>
          <Field
            id="client_id"
            name="client_id"
            component={TypeaheadFieldAdapter}
            fullWidth
            loading={searchStatus === requestStatus.loading}
            isMultiple={false}
            onClose={clearClientSearchResults}
            onInputChange={onClientIdChange}
            options={clientOptions}
            renderInputProps={{
              label: t('client', { count: 1, ns: 'labels' }),
              required: true
            }}
            renderOptionAs="client"
            disableType="client"
            disabled={isDisabled}
          />
        </Grid>
      )}
      {sessionsInRange.exists && values.client_id && (
        <Grid xs={12}>
          <AlertBlock type="clientTimeRangeFilled" />
        </Grid>
      )}
    </Grid>
  );
}

SessionFieldsClientSelection.defaultProps = {
  isDisabled: false
};

SessionFieldsClientSelection.propTypes = {
  isDisabled: PropTypes.bool,
  values: PropTypes.object.isRequired
};

export default SessionFieldsClientSelection;
