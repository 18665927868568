import DefaultLayout from 'components/layout/DefaultLayout';
import PerformanceCreate from 'components/performance/PerformanceCreate';
import PerformanceDashboard from 'components/dashboard/PerformanceDashboard';
import PerformanceEdit from 'components/performance/PerformanceEdit';
import PerformanceHistory from 'components/performance/PerformanceHistory';
import PerformanceList from 'components/performance/PerformanceList';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const performanceRoutes = [
  // Admin and trainer routes

  {
    key: 'performances',
    exact: true,
    path: routes.performanceDashboard.path,
    component: PerformanceDashboard,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'performance-create',
    exact: true,
    path: routes.createPerformance.path,
    component: PerformanceCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.trainer]
  },
  {
    key: 'performance-edit',
    exact: true,
    path: routes.editPerformance.path,
    component: PerformanceEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.trainer]
  },
  {
    key: 'performance-list',
    exact: true,
    path: routes.listPerformance.path,
    component: PerformanceList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.trainer]
  },

  // Client routes

  {
    key: 'performance-history',
    exact: true,
    path: routes.clientPerformances.path,
    component: PerformanceHistory,
    layout: DefaultLayout,
    allowedRoles: [roles.client]
  }
];

export default performanceRoutes;
