import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { enUS, es } from 'date-fns/locale';
import MUIDataTable from 'mui-datatables';
import {
  getClientPerformanceColumns,
  getPerformanceColumns
} from 'common/table/columns/performance';
import { getClientPerformanceData, getPerformanceData } from 'common/table/data/performance';
import { defaultTableOptions, tableLocalization } from 'common/table/utils';
import { roles } from 'common/constants/options';

function LatestPerformanceTable({ performances, rowsPerPage, view }) {
  const { t, i18n } = useTranslation(['routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const tableData =
    view === roles.administrator
      ? getPerformanceData(performances, dateLocale)
      : getClientPerformanceData(performances, dateLocale);
  const columns =
    view === roles.administrator
      ? getPerformanceColumns({ data: tableData, translateFunc: t, showActions: false })
      : getClientPerformanceColumns({ data: tableData, translateFunc: t, showActions: false });

  const options = {
    ...defaultTableOptions,
    jumpToPage: false,
    pagination: false,
    rowsPerPage,
    selectableRows: 'none',
    serverSide: false,
    textLabels: tableLocalization(t('performance.list', { ns: 'routes' }).toLowerCase(), t)
  };

  return (
    <MUIDataTable
      className={`latestPerformance ${view === roles.client ? 'latestPerformanceClient' : ''}`}
      columns={columns}
      data={tableData}
      options={options}
    />
  );
}

LatestPerformanceTable.defaultProps = {
  view: roles.administrator
};

LatestPerformanceTable.propTypes = {
  performances: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  view: PropTypes.string
};

export default LatestPerformanceTable;
