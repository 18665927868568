import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import FilterButtons from 'components/shared/buttons/FilterButtons';
import { currencyOptions, getTrashedOptions } from 'common/constants/options';
import StyledFiltersContainer from 'common/theme/filters/StyledFiltersContainer';
import StyledFiltersForm from 'common/theme/filters/StyledFiltersForm';
import sessionTypeSlice from 'store/modules/sessionType';

function SessionTypeFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels']);

  const initialValues = useSelector((state) => state.sessionType.filters);
  const status = useSelector((state) => state.sessionType.status);

  const onFilter = useCallback(
    (values) => {
      dispatch(sessionTypeSlice.actions.updateFilters(values));
    },
    [dispatch]
  );

  const resetForm = useCallback(() => {
    dispatch(sessionTypeSlice.actions.resetFilters());
  }, [dispatch]);

  return (
    <StyledFiltersContainer>
      <Form
        initialValues={initialValues}
        onSubmit={onFilter}
        render={({ handleSubmit, pristine }) => (
          <StyledFiltersForm onSubmit={handleSubmit}>
            <Grid container spacing={{ xs: 2, lg: 4 }}>
              <Grid xs={12} sm={6}>
                <Field
                  id="currency"
                  name="currency"
                  component={SelectFieldAdapter}
                  options={currencyOptions}
                  label={t('currency', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Field
                  id="soft_delete"
                  name="soft_delete"
                  component={SelectFieldAdapter}
                  options={getTrashedOptions(t)}
                  label={t('deleted', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
            <FilterButtons pristine={pristine} resetForm={resetForm} status={status} />
          </StyledFiltersForm>
        )}
      />
    </StyledFiltersContainer>
  );
}

export default SessionTypeFilters;
