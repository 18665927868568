import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _camelCase from 'lodash/camelCase';
import { language, sessionStatus } from 'common/constants/options';
import { userAvatarFields } from 'common/constants/query-fields/user';
import getDataToSave from 'common/form/dataToSave';
import { convertLocalToUtcTime } from 'common/utils/date';
import { isAdmin } from 'common/utils/user';
import clientPaymentSlice from 'store/modules/clientPayment';
import sessionSlice from 'store/modules/session';
import trainerSlice from 'store/modules/trainer';

const useCreateSession = () => {
  const dispatch = useDispatch();

  /**
   * Extracts and returns the session language and specialty from a given form.
   *
   * @param  {Object}  form  The form object from which to extract the session details.
   * @callback getSessionDetails
   *
   * @returns  {Object}  An object containing the 'specialty' and 'sessionLanguage' extracted from the form.
   */
  const getSessionDetails = useCallback(
    (form) => {
      const sessionLanguage = language[_camelCase(form.getFieldState('language').value)] || '';
      const specialty = form.getFieldState('session_type_id').value || [];

      return { specialty, sessionLanguage };
    },
    [language]
  );

  /**
   * Constructs the start and end dates for an event in UTC, based on a base date and specific start and end times.
   *
   * @callback getSessionUtcStartEndDates
   * @param  {Date}  baseDate   The base date for the event.
   * @param  {Date}  startTime  The specific start time for the event.
   * @param  {Date}  endTime    The specific end time for the event.
   *
   * @returns  {Object}  Returns an object containing the `startDate` and `endDate` for the event in UTC.
   */
  const getSessionUtcStartEndDates = useCallback(
    (baseDate, startTime, endTime) => {
      const baseYear = baseDate.getFullYear();
      const baseMonth = baseDate.getMonth();
      const baseDay = baseDate.getDate();

      const startDate = convertLocalToUtcTime(
        new Date(baseYear, baseMonth, baseDay, startTime.getHours(), startTime.getMinutes())
      );

      const endDate = convertLocalToUtcTime(
        new Date(baseYear, baseMonth, baseDay, endTime.getHours(), endTime.getMinutes())
      );

      return { startDate, endDate };
    },
    [convertLocalToUtcTime]
  );

  /**
   * Retrieves the 'date', 'from', and 'to' field values, constructs a date range from these values,
   * and then converts the start and end dates of the range to UTC time.
   *
   * @callback getUTCDateRangeFromForm
   * @param  {Object}  form  The form object containing the date range fields.
   *
   * @returns  {Object}  An object containing the start and end dates in UTC time.
   */
  const getUTCDateRangeFromForm = useCallback((form) => {
    const [date, from, to] = ['date', 'from', 'to'].map((f) => form.getFieldState(f).value);
    const { startDate, endDate } = getSessionUtcStartEndDates(date, from, to);

    return { start_date: startDate, end_date: endDate };
  }, []);

  /**
   * Checks if the user's input value has changed.
   *
   * @callback hasUserInputChanged
   * @param  {Object}  value          The current value of the input.
   * @param  {Object}  previousValue  The previous value of the input.
   *
   * @returns  {Boolean}  Returns true if the value has changed, otherwise false.
   */
  const hasUserInputChanged = useCallback(
    (value, previousValue) =>
      (!previousValue && 'value' in value) ||
      (previousValue && previousValue.length === 0 && 'value' in value) ||
      (value && previousValue && value.value !== previousValue.value),
    []
  );

  /**
   * Fetches session data if certain conditions are met.
   *
   * @param  {string}  field          The name of the field that has changed.
   * @param  {Object}  form           The form object from which to extract the session details.
   * @param  {Any}     value          The new value of the field.
   * @param  {Any}     previousValue  The previous value of the field.
   * @param  {Object}  dateRange      The date range for which to fetch sessions.
   */
  const fetchIfRequired = useCallback(
    (field, form, value, previousValue, dateRange) => {
      if (field === 'client_id' && hasUserInputChanged(value, previousValue)) {
        const clientId = Number(value.value);
        dispatch(sessionSlice.actions.getSessionsInRange({ dateRange, userId: clientId }));
        dispatch(clientPaymentSlice.actions.getLatest(clientId));
      }

      if (['date', 'from', 'to'].includes(field)) {
        const clientId = form.getFieldState('client_id')?.value?.value;
        dispatch(sessionSlice.actions.getSessionsInRange({ dateRange, userId: clientId }));
      }
    },
    [hasUserInputChanged]
  );

  /**
   * Creates a new session.
   *
   * @callback createSession
   * @param  {Object}  values  Form values needed to create a new session.
   * @param  {Object}  user    Current user object.
   */
  const createSession = useCallback(
    (values, user) => {
      const { startDate, endDate } = getSessionUtcStartEndDates(
        values.date,
        values.from,
        values.to
      );

      const sessionData = {
        client_id: isAdmin(user.role) ? values.client_id.value : user.id,
        end_date: endDate,
        language: values.language,
        observations: values.observations,
        session_type_id: values.session_type_id,
        start_date: startDate,
        status: sessionStatus.scheduled,
        trainer_id: values.trainer_id
      };

      const dataToSave = getDataToSave(sessionData, 'session');
      dispatch(sessionSlice.actions.create(dataToSave));
    },
    [dispatch]
  );

  /**
   * Updates the trainer selection based on specified criteria.
   * Resets the 'trainer_id' field and dispatches an action to list available trainers
   * according to the provided date range, specialty, and session language.
   *
   * @callback updateTrainerSelection
   * @param  {Object}  params                  The parameters for updating trainer selection.
   * @param  {string}  params.dateRange        The selected date range for filtering trainers.
   * @param  {FormApi} params.form             The form instance to update the 'trainer_id' field.
   * @param  {string}  params.specialty        The selected specialty for filtering trainers.
   * @param  {string}  params.sessionLanguage  The selected session language for filtering trainers.
   */
  const updateTrainerSelection = useCallback(
    ({ dateRange, form, specialty, sessionLanguage }) => {
      form.change('trainer_id', '');

      dispatch(
        trainerSlice.actions.listAvailable({
          fields: userAvatarFields,
          filters: { date_range: dateRange, specialties: specialty, languages: sessionLanguage }
        })
      );
    },
    [dispatch]
  );

  /**
   * `handleSessionFormChange` is called whenever there's a change in the form.
   * It dispatches appropriate actions based on the field that was changed.
   *
   * @callback handleSessionFormChange
   * @param  {string}  field          The field in the form that was changed.
   * @param  {Object}  form           The current state of the form.
   * @param  {Object}  value          The current value of the field that was changed.
   * @param  {Object}  previousValue  The previous value of the field that was changed.
   */
  const handleSessionFormChange = useCallback(
    (field, form, value, previousValue) => {
      if (!form) return;

      const dateRange = getUTCDateRangeFromForm(form);
      const { specialty, sessionLanguage } = getSessionDetails(form);

      fetchIfRequired(field, form, value, previousValue, dateRange);
      updateTrainerSelection({ dateRange, form, specialty, sessionLanguage });
    },
    [fetchIfRequired, updateTrainerSelection]
  );

  return {
    createSession,
    handleSessionFormChange
  };
};

export default useCreateSession;
