import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Emoji from 'components/shared/Emoji';
import UserAvatar from 'components/shared/user/UserAvatar';
import { placeToEmoji } from 'common/constants/general';

const emojiStyles = {
  display: 'inline-block',
  fontSize: '1.25rem',
  marginTop: '5px',
  textAlign: 'center'
};

const getPlaceEmoji = (place, t) => {
  const { label, symbol } = placeToEmoji[place] || placeToEmoji.default;

  return (
    <Emoji label={t(label, { ns: 'performances' })} symbol={symbol} customStyles={emojiStyles} />
  );
};

function Podium({ label, place, user, width, x, y }) {
  const { t } = useTranslation(['performances']);

  return (
    <foreignObject key={place} height="95" width="30" x={x + width / 2 - 15} y={y + 15}>
      <UserAvatar size={30} textSize={15} user={user} />
      <Typography align="center" variant="subtitle2" sx={{ marginTop: '5px' }}>
        {label}
      </Typography>
      {getPlaceEmoji(place, t)}
    </foreignObject>
  );
}

Podium.propTypes = {
  label: PropTypes.string.isRequired,
  place: PropTypes.number.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    photo: PropTypes.string
  }).isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
};

export default Podium;
