import { Upload } from '@mui/icons-material';
import { styled } from '@mui/material';

const StyledImageUploadResetIcon = styled(Upload)(({ theme }) => ({
  color: theme.palette.white.main,
  fontSize: theme.typography.pxToRem(50),
  transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`
}));

export default StyledImageUploadResetIcon;
