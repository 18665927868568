import { Box, styled } from '@mui/material';

const StyledNotificationMenuButtonsContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
  columnGap: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  padding: `0 ${theme.spacing(2)}  ${theme.spacing(2)}`
}));

export default StyledNotificationMenuButtonsContainer;
