import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import adminSlice from 'store/modules/admin';
import clientSlice from 'store/modules/client';
import newsSlice from 'store/modules/news';
import trainerSlice from 'store/modules/trainer';

const useForms = () => {
  const dispatch = useDispatch();

  /**
   * Clears the admin search results.
   *
   * This function dispatches an action to the Redux store
   * to clear the search results from the admin section.
   */
  const clearAdminSearchResults = useCallback(() => {
    dispatch(adminSlice.actions.clearSearchResults());
  }, [dispatch]);

  /**
   * Clears the client search results.
   *
   * This function dispatches an action to the Redux store
   * to clear the search results from the client section.
   */
  const clearClientSearchResults = useCallback(() => {
    dispatch(clientSlice.actions.clearSearchResults());
  }, [dispatch]);

  /**
   * Clears the news search results.
   *
   * This function dispatches an action to the Redux store
   * to clear the search results from the news section.
   */
  const clearNewsSearchResults = useCallback(() => {
    dispatch(newsSlice.actions.clearSearchResults());
  }, [dispatch]);

  /**
   * Clears the trainer search results.
   *
   * This function dispatches an action to the Redux store
   * to clear the search results from the trainer section.
   */
  const clearTrainerSearchResults = useCallback(() => {
    dispatch(trainerSlice.actions.clearSearchResults());
  }, [dispatch]);

  return {
    clearAdminSearchResults,
    clearClientSearchResults,
    clearNewsSearchResults,
    clearTrainerSearchResults
  };
};

export default useForms;
