import { Typography, styled } from '@mui/material';

const StyledProfileUsername = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: theme.typography.pxToRem(0.2),
  lineHeight: theme.typography.pxToRem(24.75),
  marginBottom: theme.spacing(1),
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(33),
    textAlign: 'left'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(44),
    marginBottom: theme.spacing(2)
  }
}));

export default StyledProfileUsername;
