import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Unstable_Grid2 as Grid } from '@mui/material';
import OverviewProfileDetail from 'components/shared/overview-profile/OverviewProfileDetail';
import StyledOverviewProfileDivider from 'common/theme/overview-profile/StyledOverviewProfileDivider';
import StyledOverviewProfileSubtitle from 'common/theme/overview-profile/StyledOverviewProfileSubtitle';

function TrainerObservations({ trainer, loading }) {
  const { t } = useTranslation(['labels']);

  return (
    <div>
      <StyledOverviewProfileSubtitle component="h4">
        {t('observations', { ns: 'labels' })}
      </StyledOverviewProfileSubtitle>
      <StyledOverviewProfileDivider />
      <Grid container spacing={{ xs: 2, md: 4 }}>
        <Grid xs={12}>
          <OverviewProfileDetail
            label={t('observations', { ns: 'labels' })}
            value={trainer?.observations}
            loading={loading}
          />
        </Grid>
      </Grid>
    </div>
  );
}

TrainerObservations.defaultProps = {
  trainer: null
};

TrainerObservations.propTypes = {
  trainer: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default TrainerObservations;
