import styledComponent from 'styled-components';

const StyledWeeklySessionEventContent = styledComponent.div`
  padding: 6px;
  @media (min-width: 600px) {
    padding: 8px;
  }
`;

export default StyledWeeklySessionEventContent;
