import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { ChevronLeftCircle, ChevronRightCircle } from 'mdi-material-ui';

function ProgressGalleryButton({ disabled, onClick, position }) {
  return (
    <IconButton color="secondary" disabled={disabled} onClick={onClick} sx={{ zIndex: 1 }}>
      {position === 'right' ? (
        <ChevronRightCircle sx={{ fontSize: (theme) => theme.typography.pxToRem(35) }} />
      ) : (
        <ChevronLeftCircle sx={{ fontSize: (theme) => theme.typography.pxToRem(35) }} />
      )}
    </IconButton>
  );
}

ProgressGalleryButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired
};

export default ProgressGalleryButton;
