import { gql } from '@apollo/client';
import client from 'apollo/client';
import { getLegalFilters } from 'common/filters/requestFilters';

/**
 * Create legal method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createLegal($input: CreateLegalInput!) {
        createLegal(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Find legal method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query legal($id: ID!) {
        legal(id: $id) {
          id
          body
          created_at
          language
          role
          title
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * Force delete legals method
 * @param  {string} ids
 * @return {Promise}
 */
const forceDelete = (ids) =>
  client.mutate({
    mutation: gql`
      mutation forceDeleteLegals($id: [ID!]!) {
        forceDeleteLegals(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Get latest legal by role method
 * @param {Object} payload
 * @return {Promise}
 */
const getLatestByRole = (payload) => {
  const { language, role } = payload;

  return client.query({
    query: gql`
      query latestLegalByRole($language: String!, $role: UserRole!) {
        latestLegalByRole(language: $language, role: $role) {
          body
          title
        }
      }
    `,
    variables: { language, role },
    fetchPolicy: 'network-only'
  });
};

/**
 * List legals method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { fields, filters, pagination } = payload;
  const legalFilters = getLegalFilters(filters);

  return client.query({
    query: gql`
      query legals {
        legals(
          first: ${pagination.first}
          page: ${pagination.page}
          ${legalFilters.language}
          ${legalFilters.role}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${fields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Update legal method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateLegal($id: ID!, $input: UpdateLegalInput!) {
        updateLegal(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  create,
  find,
  forceDelete,
  getLatestByRole,
  list,
  update
};
