import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import AppBarButtonLink from 'components/shared/buttons/AppBarButtonLink';
import ClientFilters from 'components/client/ClientFilters';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import { clientListBasicFields } from 'common/constants/query-fields/client';
import routes from 'common/routes';
import { getClientColumns } from 'common/table/columns';
import { getClientData } from 'common/table/data';
import { defaultTableOptions, getSelectToolBar, tableLocalization } from 'common/table/utils';
import { isAdmin } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import clientSlice from 'store/modules/client';

function ClientList() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'labels', 'routes', 'tables']);
  const pageTitle = t('client.list', { ns: 'routes' });
  const emptyMessage = t('empty', { type: pageTitle.toLowerCase(), ns: 'tables' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const clients = useSelector((state) => state.client.data.clients);
  const filters = useSelector((state) => state.client.filters);
  const pagination = useSelector((state) => state.client.pagination);
  const paginatorInfo = useSelector((state) => state.client.paginatorInfo);
  const refresh = useSelector((state) => state.client.refresh);
  const role = useSelector((state) => state.auth.user.role);
  const status = useSelector((state) => state.client.status.general);

  const isAdminUser = isAdmin(role);

  const appBarButton = useMemo(
    () =>
      isAdminUser ? (
        <AppBarButtonLink label={t('add', { ns: 'buttons' })} path={routes.createClient.path} />
      ) : null,
    [isAdminUser]
  );

  const listClients = useCallback(() => {
    dispatch(
      clientSlice.actions.list({
        fields: clientListBasicFields,
        filters,
        pagination
      })
    );
  }, [clientListBasicFields, dispatch, filters, pagination]);

  useEffect(() => {
    listClients();
  }, [listClients, refresh]);

  useEffect(() => {
    setAppBarButton(appBarButton);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const tableData = getClientData(clients, t);
  const columns = getClientColumns({ data: tableData, role, translateFunc: t });

  const onChangePage = (page) => {
    dispatch(clientSlice.actions.updatePagination({ page: page + 1 }));
  };

  const onDelete = (ids) => {
    dispatch(clientSlice.actions.remove(ids));
  };

  const onRestore = (ids) => {
    dispatch(clientSlice.actions.restore(ids));
  };

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    customToolbarSelect: (selectedRows, displayData) =>
      getSelectToolBar({
        filters,
        rows: selectedRows,
        data: displayData,
        onDelete,
        onRestore
      }),
    onChangePage,
    page: pagination.page - 1,
    rowsPerPage: pagination.first,
    selectableRows: isAdminUser ? 'multiple' : 'none',
    selectableRowsHeader: isAdminUser,
    textLabels: tableLocalization(pageTitle.toLowerCase(), t)
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <ClientFilters />
      <Box
        sx={{
          position: 'relative'
        }}>
        <LoadingBlock
          emptyBlockProps={{ message: emptyMessage }}
          isValid={clients.length > 0}
          skeletonProps={{ rows: 5 }}
          status={status}
          type="table">
          <MUIDataTable
            className="user user--client"
            columns={columns}
            data={tableData}
            options={options}
          />
        </LoadingBlock>
      </Box>
    </>
  );
}

export default ClientList;
