import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { Stack, Typography } from '@mui/material';
import AvailableSessionsChip from 'components/shared/chips/AvailableSessionsChip';
import MembershipStatusChip from 'components/shared/chips/MembershipStatusChip';
import { dateFormat } from 'common/constants/date';

function ClientMoreStatus() {
  const { t, i18n } = useTranslation(['labels']);

  const availableSessions = useSelector((state) => state.auth.user.available_sessions);
  const gender = useSelector((state) => state.auth.user.gender);
  const joined = useSelector((state) => state.auth.user.created_at);
  const membershipStatus = useSelector((state) => state.auth.user.membership_status);

  const dateLocale = i18n.language === 'es' ? es : enUS;

  return (
    <Stack spacing={1}>
      <Typography variant="body2">
        {t('titanSince', { ns: 'labels' })}:{' '}
        {format(parseISO(joined), dateFormat, { locale: dateLocale })}
      </Typography>
      <Stack direction="row" spacing={1}>
        <MembershipStatusChip context={gender.toLowerCase()} status={membershipStatus} />
        <AvailableSessionsChip availableSessions={availableSessions} />
      </Stack>
    </Stack>
  );
}

export default ClientMoreStatus;
