import _isEmpty from 'lodash/isEmpty';
import _isDate from 'lodash/isDate';
import { addDecimalZeroes } from 'common/utils/format';

const isValid = (value) => typeof value !== 'undefined' && value !== null && value !== '';

export const alphaNumericSpace = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^[A-Za-záÁéÉíÍóÓúÚÑñ]+[A-Za-záÁéÉíÍóÓúÚÑñ0-9\s]*$/i.test(value);
};

export const alphaSpace = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^[A-Za-záÁéÉíÍóÓúÚÑñ]+[A-Za-záÁéÉíÍóÓúÚÑñ\s]*$/i.test(value);
};

export const email = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
};

export const hasValue = (value) => !_isEmpty(value);

export const isEmptyNumber = (value) => value && value.length <= 0;

export const isText = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^[A-Za-záÁéÉíÍóÓúÚÑñ0-9]+[A-Za-záÁéÉíÍóÓúÚÑñ0-9,.\s]*$/i.test(value);
};

export const isUrl = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/gi.test(
    value
  );
};

export const isValidDate = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return _isDate(value);
};

export const lengthBetween = (value, min, max) => {
  if (!isValid(value)) {
    return false;
  }

  return value.toString().length >= min && value.toString().length <= max;
};

export const minLength = (value, min) => {
  if (!isValid(value)) {
    return false;
  }

  return value.toString().length >= min;
};

export const numberOnly = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^\d+$/.test(value);
};

export const password = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^(?=.*[0-9]+.*)(?=(.*[A-Z]){1,})(?=(.*[a-z]){1,})[0-9a-zA-Z]{8,}$/.test(value);
};

export const positiveFloat = (value) => {
  if (!isValid(value)) {
    return false;
  }

  const convertedValue = Number(value);

  if (typeof convertedValue === 'number') {
    const formattedValue = addDecimalZeroes(convertedValue, 2);
    return formattedValue >= 0;
  }

  return false;
};

export const positiveInt = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^[1-9][0-9]*$/.test(String(value));
};

export const positiveIntZero = (value) => {
  if (!isValid(value)) {
    return false;
  }

  return /^(0|[1-9][0-9]*)$/.test(String(value));
};
