import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { ApolloLink } from '@apollo/client/link/core/ApolloLink';
import localforage from 'localforage';
import errorHandler from 'apollo/errorHandler';
import { getUiLanguage } from 'common/utils/general';

const { REACT_APP_GRAPHQL_SERVER_BASE_URL } = process.env;

const getAuthToken = () =>
  localforage
    .getItem('otx_a')
    .then((value) => (value ? `Bearer ${value}` : value))
    .catch((error) => console.log(error));

const authLink = setContext(async (operation, { headers }) => {
  const token = await getAuthToken();
  const language =
    (await localforage.getItem('otx_lang')) || getUiLanguage(window.navigator.language);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const updatedHeaders = {
    ...headers,
    'Accept-Language': language,
    Timezone: timeZone
  };

  if (token) {
    return {
      headers: {
        ...updatedHeaders,
        Authorization: token
      }
    };
  }

  return {
    headers: updatedHeaders
  };
});

const uploadLink = createUploadLink({
  uri: `${REACT_APP_GRAPHQL_SERVER_BASE_URL}/graphql` || ''
});
const errorLink = onError(errorHandler);

const link = ApolloLink.from([authLink, errorLink, uploadLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

export default client;
