import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';
import Cookies from 'js-cookie';
import StyledSessionHintCollapse from 'common/theme/session/hints/StyledSessionHintCollapse';

function SessionHint({ cookieName, expireIn, message }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    Cookies.set(cookieName, '0', { expires: expireIn });
    setOpen(false);
  };

  useEffect(() => {
    const showHint = Cookies.get(cookieName) || '1';
    setOpen(showHint !== '0');
  }, []);

  return (
    <StyledSessionHintCollapse collapse={!open} in={open}>
      <Alert variant="filled" severity="info" onClose={handleClose}>
        {message}
      </Alert>
    </StyledSessionHintCollapse>
  );
}

SessionHint.defaultProps = {
  expireIn: 3650
};

SessionHint.propTypes = {
  cookieName: PropTypes.string.isRequired,
  expireIn: PropTypes.number,
  message: PropTypes.string.isRequired
};

export default SessionHint;
