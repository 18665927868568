import { Typography, styled } from '@mui/material';

const StyledDrawerMenuUsername = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 500,
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(24)
  }
}));

export default StyledDrawerMenuUsername;
