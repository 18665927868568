import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, ListItemText, OutlinedInput, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  StyledInputLabel,
  StyledMultipleMenuItem,
  StyledSelect
} from 'common/theme/fields/StyledSelect';

function MultipleSelectFieldAdapter({ input, id, label, options, ...rest }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onChange = (event) => {
    input.onChange(event.target.value);
  };

  return (
    <FormControl size={size} {...rest}>
      <StyledInputLabel id={id}>{label}</StyledInputLabel>
      <StyledSelect
        {...rest}
        labelId={id}
        id={id}
        input={<OutlinedInput label={label} />}
        value={input.value}
        onChange={onChange}
        renderValue={(selected) => {
          const labels = options.reduce((acc, curr) => {
            if (selected.indexOf(curr.value) >= 0) {
              acc.push(curr.label);
            }

            return acc;
          }, []);

          return labels.join(', ');
        }}
        multiple>
        {options.map((option) => (
          <StyledMultipleMenuItem key={`checkbox-${option.value}`} value={option.value}>
            <Checkbox checked={input.value.indexOf(option.value) >= 0} />
            <ListItemText primary={option.label} />
          </StyledMultipleMenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}

MultipleSelectFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default MultipleSelectFieldAdapter;
