import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import DateRangePickerFieldAdapter from 'components/shared/field-adapters/DateRangePickerFieldAdapter';
import MultipleSelectFieldAdapter from 'components/shared/field-adapters/MultipleSelectFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import FilterButtons from 'components/shared/buttons/FilterButtons';
import { getSessionStatusOptions } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import StyledFiltersContainer from 'common/theme/filters/StyledFiltersContainer';
import StyledFiltersForm from 'common/theme/filters/StyledFiltersForm';
import { formatDateRangeField } from 'common/utils/date';
import { handleClientIdChange, handleTrainerIdChange } from 'common/utils/user';
import useForms from 'hooks/useForms';
import sessionSlice from 'store/modules/session';
import sessionTypeSlice from 'store/modules/sessionType';

function AdminSessionFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels']);
  const { clearClientSearchResults, clearTrainerSearchResults } = useForms();

  const clientOptions = useSelector((state) => state.client.data.search);
  const clientSearchStatus = useSelector((state) => state.client.status.search);
  const initialValues = useSelector((state) => state.session.filters);
  const sessionTypeOptions = useSelector((state) => state.sessionType.sessionTypeOptions);
  const sessionTypeStatus = useSelector((state) => state.sessionType.status);
  const status = useSelector((state) => state.session.status.general);
  const trainerOptions = useSelector((state) => state.trainer.data.search);
  const trainerSearchStatus = useSelector((state) => state.trainer.status.search);

  useEffect(() => {
    dispatch(sessionTypeSlice.actions.listAll());
  }, [dispatch]);

  const onClientIdChange = handleClientIdChange(dispatch);

  const onFilter = useCallback(
    (values) => {
      const filters = formatDateRangeField(values, 'start_between', true);
      dispatch(sessionSlice.actions.updateFilters(filters));
    },
    [dispatch]
  );

  const onTrainerIdChange = handleTrainerIdChange(dispatch);

  const resetForm = useCallback(() => {
    dispatch(sessionSlice.actions.resetFilters());
  }, [dispatch]);

  return (
    <StyledFiltersContainer>
      <Form
        initialValues={initialValues}
        onSubmit={onFilter}
        render={({ handleSubmit, pristine }) => (
          <StyledFiltersForm onSubmit={handleSubmit}>
            <Grid container spacing={{ xs: 2, lg: 4 }}>
              <Grid xs={12} sm={6}>
                <Field
                  id="client_id"
                  name="client_id"
                  component={TypeaheadFieldAdapter}
                  fullWidth
                  loading={clientSearchStatus === requestStatus.loading}
                  isMultiple
                  onClose={clearClientSearchResults}
                  onInputChange={onClientIdChange}
                  options={clientOptions}
                  renderInputProps={{
                    label: t('client', { count: 2, ns: 'labels' })
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Field
                  id="trainer_id"
                  name="trainer_id"
                  component={TypeaheadFieldAdapter}
                  fullWidth
                  loading={trainerSearchStatus === requestStatus.loading}
                  isMultiple
                  onClose={clearTrainerSearchResults}
                  onInputChange={onTrainerIdChange}
                  options={trainerOptions}
                  renderInputProps={{
                    label: t('trainer', { count: 2, ns: 'labels' })
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Field
                  id="start_between"
                  name="start_between"
                  label={t('date', { ns: 'labels' })}
                  component={DateRangePickerFieldAdapter}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Field
                  id="session_type_id"
                  name="session_type_id"
                  component={MultipleSelectFieldAdapter}
                  options={sessionTypeOptions}
                  label={t('category', { ns: 'labels' })}
                  fullWidth
                  disabled={sessionTypeStatus === requestStatus.loading}
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Field
                  id="status"
                  name="status"
                  component={SelectFieldAdapter}
                  options={getSessionStatusOptions(t)}
                  label={t('status', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
            <FilterButtons pristine={pristine} resetForm={resetForm} status={status} />
          </StyledFiltersForm>
        )}
      />
    </StyledFiltersContainer>
  );
}

export default AdminSessionFilters;
