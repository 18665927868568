import ClientPaymentCreate from 'components/client-payment/ClientPaymentCreate';
import ClientPaymentEdit from 'components/client-payment/ClientPaymentEdit';
import ClientPaymentList from 'components/client-payment/ClientPaymentList';
import PaymentDashboard from 'components/dashboard/PaymentDashboard';
import TrainerPaymentCreate from 'components/trainer-payment/TrainerPaymentCreate';
import TrainerPaymentEdit from 'components/trainer-payment/TrainerPaymentEdit';
import TrainerPaymentList from 'components/trainer-payment/TrainerPaymentList';
import DefaultLayout from 'components/layout/DefaultLayout';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const paymentRoutes = [
  // Admin routes

  {
    key: 'client-payment-create',
    exact: true,
    path: routes.createClientPayment.path,
    component: ClientPaymentCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'client-payment-edit',
    exact: true,
    path: routes.editClientPayment.path,
    component: ClientPaymentEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'client-payment-list',
    exact: true,
    path: routes.listClientPayments.administrator.path,
    component: ClientPaymentList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'payments',
    exact: true,
    path: routes.payments.path,
    component: PaymentDashboard,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'trainer-payment-create',
    exact: true,
    path: routes.createTrainerPayment.path,
    component: TrainerPaymentCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'trainer-payment-edit',
    exact: true,
    path: routes.editTrainerPayment.path,
    component: TrainerPaymentEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'trainer-payment-list',
    exact: true,
    path: routes.listTrainerPayments.administrator.path,
    component: TrainerPaymentList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },

  // Trainer routes

  {
    key: 'trainer-payments',
    exact: true,
    path: routes.listTrainerPayments.trainer.path,
    component: TrainerPaymentList,
    layout: DefaultLayout,
    allowedRoles: [roles.trainer]
  },

  // Client routes

  {
    key: 'client-payments',
    exact: true,
    path: routes.listClientPayments.client.path,
    component: ClientPaymentList,
    layout: DefaultLayout,
    allowedRoles: [roles.client]
  }
];

export default paymentRoutes;
