const legalRoutes = {
  listLegal: {
    path: '/legal'
  },
  createLegal: {
    path: '/legal/create'
  },
  editLegal: {
    path: '/legal/edit/:id'
  },
  viewLegal: {
    path: '/legal/:id'
  },
  viewTC: {
    path: '/terms-and-conditions'
  }
};

export default legalRoutes;
