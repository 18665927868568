import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Divider, Unstable_Grid2 as Grid } from '@mui/material';
import AutocompleteFieldAdapter from 'components/shared/field-adapters/AutocompleteFieldAdapter';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import { getGenderOptions } from 'common/constants/options';
import StyledProfileTabPanelTitle from 'common/theme/profile/StyledProfileTabPanelTitle';
import requestStatus from 'common/constants/status';

function PersonalTabPanel({ countries, geoStatus }) {
  const { t } = useTranslation(['labels']);

  return (
    <>
      <StyledProfileTabPanelTitle component="h4">
        {t('personalInformation', { ns: 'labels' })}
      </StyledProfileTabPanelTitle>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={6}>
          <Field
            id="first_name"
            name="first_name"
            component={TextFieldAdapter}
            label={t('firstname_other', { count: 2, ns: 'labels' })}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="last_name"
            name="last_name"
            component={TextFieldAdapter}
            label={t('lastname_other', { count: 2, ns: 'labels' })}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="birthdate"
            name="birthdate"
            component={DatePickerFieldAdapter}
            label={t('birthdate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: true
            }}
            disableFuture
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="gender"
            name="gender"
            component={SelectFieldAdapter}
            options={getGenderOptions(t)}
            label={t('gender', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="identification_number"
            name="identification_number"
            component={TextFieldAdapter}
            label={t('identificationNumber', { ns: 'labels' })}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="email"
            name="email"
            component={TextFieldAdapter}
            label={t('email', { ns: 'labels' })}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="country"
            name="country"
            component={AutocompleteFieldAdapter}
            options={countries}
            renderInputProps={{
              label: t('country', { ns: 'labels' }),
              required: true
            }}
            loading={geoStatus === requestStatus.loading}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="phone"
            name="phone"
            component={TextFieldAdapter}
            label={t('phone', { ns: 'labels' })}
            type="text"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <StyledProfileTabPanelTitle component="h4">
        {t('medicalInformation', { ns: 'labels' })}
      </StyledProfileTabPanelTitle>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={6}>
          <Field
            id="eps"
            name="eps"
            component={TextFieldAdapter}
            label={t('medicalInsuranceCompany', { ns: 'labels' })}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="contact_name"
            name="contact_name"
            component={TextFieldAdapter}
            label={t('emergencyContactName', { ns: 'labels' })}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="contact_phone"
            name="contact_phone"
            component={TextFieldAdapter}
            label={t('emergencyContactPhone', { ns: 'labels' })}
            type="text"
            fullWidth
            required
          />
        </Grid>
      </Grid>
    </>
  );
}

PersonalTabPanel.propTypes = {
  countries: PropTypes.array.isRequired,
  geoStatus: PropTypes.string.isRequired
};

export default PersonalTabPanel;
