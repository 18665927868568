import { Box, styled } from '@mui/material';

const StyledNotificationHeader = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  columnGap: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1)
}));

export default StyledNotificationHeader;
