import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _camelCase from 'lodash/camelCase';
import StyledPerformanceHistoryChartSubtitle from 'common/theme/charts/performance-history/StyledPerformanceHistoryChartSubtitle';
import StyledPerformanceHistoryChartTitle from 'common/theme/charts/performance-history/StyledPerformanceHistoryChartTitle';

function PerformanceHistoryChartHeader({ testType }) {
  const { t } = useTranslation(['performances']);
  const performanceTest = _camelCase(testType);
  const omitDuration = ['plankTime'];

  return (
    <>
      <StyledPerformanceHistoryChartTitle>
        {t(`${performanceTest}.label`, { ns: 'performances' })}
        {!omitDuration.includes(performanceTest) && (
          <>
            {' - '}
            {t(`${performanceTest}.duration`, { ns: 'performances' })}
          </>
        )}
      </StyledPerformanceHistoryChartTitle>
      <StyledPerformanceHistoryChartSubtitle>
        {t(`${performanceTest}.unit`, { ns: 'performances' })}
      </StyledPerformanceHistoryChartSubtitle>
    </>
  );
}

PerformanceHistoryChartHeader.propTypes = {
  testType: PropTypes.string.isRequired
};

export default PerformanceHistoryChartHeader;
