import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import MUIDataTable from 'mui-datatables';
import { Box } from '@mui/material';
import AppBarButtonLink from 'components/shared/buttons/AppBarButtonLink';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import PaymentExpandableRow from 'components/shared/table-rows/PaymentExpandableRow';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TrainerPaymentFilters from 'components/trainer-payment/TrainerPaymentFilters';
import {
  myTrainerPaymentListFields,
  trainerPaymentListFields
} from 'common/constants/query-fields/trainerPayment';
import routes from 'common/routes';
import { getMyTrainerPaymentColumns, getTrainerPaymentColumns } from 'common/table/columns';
import { getMyTrainerPaymentData, getTrainerPaymentData } from 'common/table/data';
import { defaultTableOptions, getSelectToolBar, tableLocalization } from 'common/table/utils';
import { isAdmin } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import trainerPaymentSlice from 'store/modules/trainerPayment';

function TrainerPaymentList() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['buttons', 'labels', 'routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const filters = useSelector((state) => state.trainerPayment.filters);
  const pagination = useSelector((state) => state.trainerPayment.pagination);
  const paginatorInfo = useSelector((state) => state.trainerPayment.paginatorInfo);
  const refresh = useSelector((state) => state.trainerPayment.refresh);
  const role = useSelector((state) => state.auth.user.role);
  const status = useSelector((state) => state.trainerPayment.status.general);
  const payments = useSelector((state) => state.trainerPayment.data.payments);
  const userId = useSelector((state) => state.auth.user.id);

  const isAdminUser = isAdmin(role);
  const titlei18nKey = isAdminUser ? 'payment.trainer.list' : 'payment.myPayments';
  const pageTitle = t(titlei18nKey, { ns: 'routes' });
  const emptyMessage = t('empty', { type: pageTitle.toLowerCase(), ns: 'tables' });

  const appBarButton = useMemo(
    () =>
      isAdminUser ? (
        <AppBarButtonLink
          label={t('add', { ns: 'buttons' })}
          path={routes.createTrainerPayment.path}
        />
      ) : null,
    [isAdminUser]
  );

  useEffect(() => {
    const updatedFilters = { ...filters };
    let updatedFields = trainerPaymentListFields;

    if (!isAdminUser) {
      updatedFields = myTrainerPaymentListFields;
      updatedFilters.user_id = userId;
    }

    dispatch(
      trainerPaymentSlice.actions.list({
        fields: updatedFields,
        filters: updatedFilters,
        pagination
      })
    );
  }, [filters, pagination, refresh]);

  useEffect(() => {
    setAppBarButton(appBarButton);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const tableData = isAdminUser
    ? getTrainerPaymentData(payments, dateLocale)
    : getMyTrainerPaymentData(payments, dateLocale);

  const columns = isAdminUser
    ? getTrainerPaymentColumns({ data: tableData, translateFunc: t })
    : getMyTrainerPaymentColumns({ data: tableData, translateFunc: t });

  const onChangePage = (page) => {
    dispatch(trainerPaymentSlice.actions.updatePagination({ page: page + 1 }));
  };

  const onDelete = (ids) => {
    dispatch(trainerPaymentSlice.actions.remove(ids));
  };

  const onRestore = (ids) => {
    dispatch(trainerPaymentSlice.actions.restore(ids));
  };

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    customToolbarSelect: (selectedRows, displayData) =>
      getSelectToolBar({
        filters,
        rows: selectedRows,
        data: displayData,
        onDelete,
        onRestore
      }),
    expandableRows: isAdminUser,
    expandableRowsHeader: false,
    expandableRowsOnClick: isAdminUser,
    onChangePage,
    page: pagination.page - 1,
    renderExpandableRow: (rowData, rowMeta) => (
      <PaymentExpandableRow data={rowData} meta={rowMeta} role={role} tableData={tableData} />
    ),
    rowsPerPage: pagination.first,
    selectableRows: isAdminUser ? 'multiple' : 'none',
    textLabels: tableLocalization(t('payment.payments', { ns: 'routes' }).toLowerCase(), t)
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      {isAdminUser && <TrainerPaymentFilters />}
      <Box
        sx={{
          position: 'relative'
        }}>
        <LoadingBlock
          emptyBlockProps={{ message: emptyMessage }}
          isValid={payments.length > 0}
          skeletonProps={{ rows: 5 }}
          status={status}
          type="table">
          <MUIDataTable
            className="trainerPayment"
            columns={columns}
            data={tableData}
            options={options}
          />
        </LoadingBlock>
      </Box>
    </>
  );
}

export default TrainerPaymentList;
