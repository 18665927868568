import React from 'react';
import PropTypes from 'prop-types';
import DoubleCounterContainer from 'components/shared/counters/double/DoubleCounterContainer';
import CounterSection from 'components/shared/counters/CounterSection';

function DoubleCounter({ counters, emoji, title, type }) {
  return (
    <DoubleCounterContainer title={title} emoji={emoji}>
      <CounterSection counter={counters[0].counter} title={counters[0].title} type={type} />
      <CounterSection counter={counters[1].counter} title={counters[1].title} type={type} />
    </DoubleCounterContainer>
  );
}

DoubleCounter.defaultProps = {
  type: 'number'
};

DoubleCounter.propTypes = {
  counters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      counter: PropTypes.number.isRequired
    })
  ).isRequired,
  emoji: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['number', 'currency'])
};

export default DoubleCounter;
