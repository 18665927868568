import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function MultipleItemsColumn({ items }) {
  return items.map((item) => (
    <Typography variant="body2" key={item}>
      {item}
    </Typography>
  ));
}

MultipleItemsColumn.propTypes = {
  items: PropTypes.array.isRequired
};

export default MultipleItemsColumn;
