import React from 'react';
import PropTypes from 'prop-types';
import _camelCase from 'lodash/camelCase';
import { Stack } from '@mui/material';
import CounterLine from 'components/shared/counters/CounterLine';
import SingleCounterContainer from 'components/shared/counters/single/SingleCounterContainer';
import StyledSingleCounterIconContainer from 'common/theme/counter/StyledSingleCounterIconContainer';
import StyledSingleCounterLottieIconContainer from 'common/theme/counter/StyledSingleCounterLottieIconContainer';
import StyledSingleCounterTitle from 'common/theme/counter/StyledSingleCounterTitle';

function SingleCounter({ icon, lines, lottieIcon, title }) {
  return (
    <SingleCounterContainer>
      {lottieIcon && (
        <StyledSingleCounterLottieIconContainer>
          {lottieIcon}
        </StyledSingleCounterLottieIconContainer>
      )}
      {icon && <StyledSingleCounterIconContainer>{icon}</StyledSingleCounterIconContainer>}
      <StyledSingleCounterTitle>{title}</StyledSingleCounterTitle>
      <Stack spacing={0}>
        {lines.map(
          (line, index) =>
            line && <CounterLine key={_camelCase(line)} secondary={index !== 0} text={line} />
        )}
      </Stack>
    </SingleCounterContainer>
  );
}

SingleCounter.defaultProps = {
  icon: null,
  lottieIcon: null
};

SingleCounter.propTypes = {
  icon: PropTypes.node,
  lines: PropTypes.arrayOf(PropTypes.string).isRequired,
  lottieIcon: PropTypes.node,
  title: PropTypes.string.isRequired
};

export default SingleCounter;
