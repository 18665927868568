import styledComponent from 'styled-components';

const StyledHourPickerGrid = styledComponent.div`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 1rem;
`;

export default StyledHourPickerGrid;
