import { Box, styled } from '@mui/material';

const StyledNotificationContainer = styled(Box)(({ theme }) => ({
  columnGap: theme.spacing(2),
  display: 'flex',
  position: 'relative',
  width: '100%'
}));

export default StyledNotificationContainer;
