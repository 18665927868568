import React, { useMemo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { enUS, es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import * as locales from '@mui/material/locale';
import { pdfjs } from 'react-pdf';
import Router from 'router';
import FullScreenLoading from 'components/shared/loading/FullScreenLoading';
import { uiLanguage } from 'common/constants/options';
import components from 'common/theme/components';
import palette from 'common/theme/palette';
import i18n from 'i18n';

import 'styles/base.css';

function App() {
  const currentI18nLanguage = i18n.language;

  const language = useSelector((state) => state.settings.config.language);

  const adapterLocale = useMemo(() => (language === uiLanguage.es.value ? es : enUS), [language]);
  const themeLocale = useMemo(
    () => (language === uiLanguage.es.value ? 'esES' : 'enUS'),
    [language]
  );

  if (currentI18nLanguage !== language) {
    i18n.changeLanguage(language);
  }

  const customTheme = useMemo(
    () =>
      createTheme(
        {
          components,
          palette
        },
        locales[themeLocale] || 'esES'
      ),
    [language, palette]
  );

  if (process.env.REACT_APP_ENV === 'production') {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;
  } else {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      'pdfjs-dist/build/pdf.worker.min.js',
      import.meta.url
    ).toString();
  }

  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
        <CssBaseline />
        <Suspense fallback={<FullScreenLoading />}>
          <Router />
        </Suspense>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
