import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { filterSessionsByAvailability } from 'common/utils/availability';

const useAvailabilitySidebar = () => {
  const [disableReset, setDisableReset] = useState(true);
  const [sessionsToShow, setSessionsToShow] = useState([]);

  const sessions = useSelector((state) => state.session.data.list);

  /**
   * Resets the list of sessions to show to the full set of sessions and disables the reset button.
   *
   * @callback handleResetSessionList
   */
  const handleResetSessionList = useCallback(() => {
    setSessionsToShow(sessions);
    setDisableReset(true);
  }, [sessions, setDisableReset, setSessionsToShow]);

  /**
   * Filters the list of sessions based on the event data and enables the reset button.
   *
   * @callback handleSessionsFilter
   * @param  {Object}  event  The event data used to filter the sessions.
   */
  const handleSessionsFilter = useCallback(
    (event) => {
      const filteredSessions = filterSessionsByAvailability(sessions, event);
      setSessionsToShow(filteredSessions);
      setDisableReset(false);
    },
    [sessions, setDisableReset, setSessionsToShow]
  );

  /**
   * This useEffect hook is utilized to set the sessions to show when the `sessions` prop changes.
   */
  useEffect(() => {
    setSessionsToShow(sessions);
  }, [sessions]);

  return {
    disableReset,
    handleResetSessionList,
    handleSessionsFilter,
    sessionsToShow
  };
};

export default useAvailabilitySidebar;
