import ClientCreate from 'components/client/ClientCreate';
import ClientDashboard from 'components/dashboard/client/ClientDashboard';
import ClientEdit from 'components/client/ClientEdit';
import ClientList from 'components/client/ClientList';
import ClientMore from 'components/client/more/ClientMore';
import ClientOverviewProfile from 'components/client/overview-profile/ClientOverviewProfile';
import ClientProfile from 'components/client/profile/ClientProfile';
import DefaultLayout from 'components/layout/DefaultLayout';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const clientRoutes = [
  // Admin and trainer routes

  {
    key: 'client-create',
    exact: true,
    path: routes.createClient.path,
    component: ClientCreate,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'client-edit',
    exact: true,
    path: routes.editClient.path,
    component: ClientEdit,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator]
  },
  {
    key: 'client-list',
    exact: true,
    path: routes.listClient.path,
    component: ClientList,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.trainer]
  },
  {
    key: 'client-overview-profile',
    exact: true,
    path: routes.viewClient.path,
    component: ClientOverviewProfile,
    layout: DefaultLayout,
    allowedRoles: [roles.administrator, roles.trainer]
  },

  // Client routes

  {
    key: 'client-dashboard',
    exact: true,
    path: routes.clientDashboard.path,
    component: ClientDashboard,
    layout: DefaultLayout,
    allowedRoles: [roles.client]
  },
  {
    key: 'client-more',
    exact: true,
    path: routes.clientMore.path,
    component: ClientMore,
    layout: DefaultLayout,
    allowedRoles: [roles.client]
  },
  {
    key: 'client-profile',
    exact: true,
    path: routes.clientProfile.path,
    component: ClientProfile,
    layout: DefaultLayout,
    allowedRoles: [roles.client]
  }
];

export default clientRoutes;
