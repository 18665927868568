import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import AppBarContext from 'context/AppBarContext';

function AppbarContextProvider({ children }) {
  const [button, setButton] = useState(null);
  const [title, setTitle] = useState('');

  const updateButton = useCallback((newButton) => {
    setButton(newButton);
  }, []);

  const updateTitle = useCallback((newTitle) => {
    setTitle(newTitle);
  }, []);

  const appBarContextValue = useMemo(
    () => ({
      appBarButton: button,
      setAppBarButton: updateButton,
      appBarTitle: title,
      setAppBarTitle: updateTitle
    }),
    [button, title, updateButton, updateTitle]
  );

  return <AppBarContext.Provider value={appBarContextValue}>{children}</AppBarContext.Provider>;
}

AppbarContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppbarContextProvider;
