import { takeLatest, put } from 'redux-saga/effects';
import user from 'api/graphql/user';
import { getClientOptionList } from 'common/form/utils';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import { createClientList, createClientFind } from 'store/sagas/hof/client';
import clientSlice from 'store/modules/client';
import uiSlice from 'store/modules/ui';
import i18n from 'i18n';

/**
 * Create client saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'client-create-notification-success',
      variant: 'success',
      message: i18n.t('client.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield user.create(payload);
    yield put(clientSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientSlice.actions.createError(message));
  }
}

/**
 * Get membership status counters saga
 *
 * @param {*} action
 */
function* membershipStatusCounters() {
  try {
    const response = yield user.getMembershipStatusCounters();

    const {
      data: { membershipStatusCounters: counters }
    } = response;

    yield put(clientSlice.actions.membershipStatusCountersSuccess(counters));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientSlice.actions.membershipStatusCountersError(message));
  }
}

/**
 * Remove clients saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deleteUsers: removedClients }
    } = yield user.remove(action.payload);

    const snack = createSnack({
      key: 'remove-clients-notification-success',
      variant: 'success',
      message: i18n.t('client.delete', { count: removedClients.length, ns: 'notifications' })
    });

    yield put(clientSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientSlice.actions.removeError(message));
  }
}

/**
 * Restore clients saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restoreUsers: restoredClients }
    } = yield user.restore(action.payload);

    const snack = createSnack({
      key: 'restore-clients-notification-success',
      variant: 'success',
      message: i18n.t('client.restore', { count: restoredClients.length, ns: 'notifications' })
    });

    yield put(clientSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientSlice.actions.restoreError(message));
  }
}

/**
 * Search clients saga
 *
 * @param {*} action
 */
function* search(action) {
  try {
    const { input, fields } = action.payload;
    const response = yield user.search({ fields, input });

    const {
      data: { searchUser: clients }
    } = response;

    const options = getClientOptionList(clients);

    yield put(clientSlice.actions.searchSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientSlice.actions.searchError(message));
  }
}

/**
 * Update client saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-client-notification-success',
      variant: 'success',
      message: i18n.t('client.update', { ns: 'notifications' })
    });

    yield user.update(action.payload);

    yield put(clientSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientSlice.actions.updateError(message));
  }
}

/**
 * Watch client
 *
 * @export
 */
export default function* watchClient() {
  yield takeLatest(clientSlice.actions.create, create);
  yield takeLatest(
    clientSlice.actions.find,
    createClientFind(clientSlice.actions.findSuccess, clientSlice.actions.findError)
  );
  yield takeLatest(
    clientSlice.actions.list,
    createClientList(clientSlice.actions.listSuccess, clientSlice.actions.listError)
  );
  yield takeLatest(
    clientSlice.actions.listArrearage,
    createClientList(
      clientSlice.actions.listArrearageSuccess,
      clientSlice.actions.listArrearageError
    )
  );
  yield takeLatest(clientSlice.actions.membershipStatusCounters, membershipStatusCounters);
  yield takeLatest(clientSlice.actions.remove, remove);
  yield takeLatest(clientSlice.actions.restore, restore);
  yield takeLatest(clientSlice.actions.search, search);
  yield takeLatest(clientSlice.actions.update, update);
  yield takeLatest(
    clientSlice.actions.view,
    createClientFind(clientSlice.actions.viewSuccess, clientSlice.actions.viewError)
  );
}
