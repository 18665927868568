import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import MultipleSelectFieldAdapter from 'components/shared/field-adapters/MultipleSelectFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import FilterButtons from 'components/shared/buttons/FilterButtons';
import { currencyOptions, getTrashedOptions } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import StyledFiltersContainer from 'common/theme/filters/StyledFiltersContainer';
import StyledFiltersForm from 'common/theme/filters/StyledFiltersForm';
import { handleClientIdChange } from 'common/utils/user';
import useForms from 'hooks/useForms';
import planSlice from 'store/modules/plan';
import clientPaymentSlice from 'store/modules/clientPayment';

function ClientPaymentFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels']);
  const { clearClientSearchResults } = useForms();

  const clientOptions = useSelector((state) => state.client.data.search);
  const initialValues = useSelector((state) => state.clientPayment.filters);
  const planOptions = useSelector((state) => state.plan.planOptions);
  const planStatus = useSelector((state) => state.plan.status);
  const searchStatus = useSelector((state) => state.client.status.search);
  const status = useSelector((state) => state.clientPayment.status.general);

  useEffect(() => {
    dispatch(planSlice.actions.listAll());
  }, []);

  const onClientIdChange = handleClientIdChange(dispatch);

  const onFilter = useCallback(
    (values) => {
      dispatch(clientPaymentSlice.actions.updateFilters(values));
    },
    [dispatch]
  );

  const resetForm = useCallback(() => {
    dispatch(clientPaymentSlice.actions.resetFilters());
  }, [dispatch]);

  return (
    <StyledFiltersContainer>
      <Form
        initialValues={initialValues}
        onSubmit={onFilter}
        render={({ handleSubmit, pristine }) => (
          <StyledFiltersForm onSubmit={handleSubmit}>
            <Grid container spacing={{ xs: 2, lg: 4 }}>
              <Grid xs={12}>
                <Field
                  id="user_id"
                  name="user_id"
                  component={TypeaheadFieldAdapter}
                  fullWidth
                  loading={searchStatus === requestStatus.loading}
                  isMultiple
                  onClose={clearClientSearchResults}
                  onInputChange={onClientIdChange}
                  options={clientOptions}
                  renderInputProps={{
                    label: t('client', { count: 2, ns: 'labels' })
                  }}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Field
                  id="plan_id"
                  name="plan_id"
                  component={MultipleSelectFieldAdapter}
                  options={planOptions}
                  disabled={planStatus === requestStatus.loading}
                  label={t('plan', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Field
                  id="currency"
                  name="currency"
                  component={SelectFieldAdapter}
                  options={currencyOptions}
                  label={t('currency', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Field
                  id="soft_delete"
                  name="soft_delete"
                  component={SelectFieldAdapter}
                  options={getTrashedOptions(t)}
                  label={t('deleted', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
            <FilterButtons pristine={pristine} resetForm={resetForm} status={status} />
          </StyledFiltersForm>
        )}
      />
    </StyledFiltersContainer>
  );
}

export default ClientPaymentFilters;
