import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StyledMoreProfileLogout from 'common/theme/more-profile/StyledMoreProfileLogout';
import BeamContext from 'context/BeamContext';
import authSlice from 'store/modules/auth';

function MoreProfileLogoutButton() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons']);
  const { beamsClient } = useContext(BeamContext);

  const logout = () => {
    if (beamsClient) beamsClient.stop().catch(console.error);
    dispatch(authSlice.actions.logout());
  };

  return (
    <StyledMoreProfileLogout
      color="inherit"
      disableElevation
      size="small"
      variant="text"
      onClick={logout}>
      {t('auth.logout', { ns: 'buttons' })}
    </StyledMoreProfileLogout>
  );
}

export default MoreProfileLogoutButton;
