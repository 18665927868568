import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemText } from '@mui/material';
import StyledAvailabilityStatusContainer from 'common/theme/availability/StyledAvailabilityStatusContainer';
import StyledAvailabilityStatusList from 'common/theme/availability/StyledAvailabilityStatusList';
import StyledAvailabilityStatusListIcon from 'common/theme/availability/StyledAvailabilityStatusListIcon';
import StyledAvailabilityStatusListItem from 'common/theme/availability/StyledAvailabilityStatusListItem';
import StyledAvailabilityStatusSquare from 'common/theme/availability/StyledAvailabilityStatusSquare';
import StyledAvailabilityStatusTitle from 'common/theme/availability/StyledAvailabilityStatusTitle';
import { sessionStatus } from 'common/constants/options';

function AvailabilityStatus() {
  const { t } = useTranslation(['availabilities']);

  return (
    <div>
      <StyledAvailabilityStatusTitle>
        {t('statusLabel', { ns: 'availabilities' })}
      </StyledAvailabilityStatusTitle>
      <StyledAvailabilityStatusContainer>
        <StyledAvailabilityStatusList>
          <StyledAvailabilityStatusListItem disablePadding>
            <StyledAvailabilityStatusListIcon>
              <StyledAvailabilityStatusSquare status={sessionStatus.clientAbsent} />
            </StyledAvailabilityStatusListIcon>
            <ListItemText primary={t('status.unsaved', { ns: 'availabilities' })} />
          </StyledAvailabilityStatusListItem>
          <StyledAvailabilityStatusListItem disablePadding>
            <StyledAvailabilityStatusListIcon>
              <StyledAvailabilityStatusSquare status={sessionStatus.scheduled} />
            </StyledAvailabilityStatusListIcon>
            <ListItemText primary={t('status.saved', { ns: 'availabilities' })} />
          </StyledAvailabilityStatusListItem>
        </StyledAvailabilityStatusList>
      </StyledAvailabilityStatusContainer>
    </div>
  );
}

export default AvailabilityStatus;
