import { Typography, styled } from '@mui/material';

const StyledSessionDrawerTitle = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 500,
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(22)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(24)
  }
}));

export default StyledSessionDrawerTitle;
