import { format, parseISO } from 'date-fns';
import { dateFormat } from 'common/constants/date';

const formatDate = (dateString, dateLocale) =>
  format(parseISO(dateString), dateFormat, { locale: dateLocale });

const mapBasicPerformanceData = (performance, dateLocale) => {
  let plankTime = '';

  if (performance.plank_time_minutes || performance.plank_time_seconds) {
    plankTime = `${performance.plank_time_minutes}min ${performance.plank_time_seconds}s`;
  }

  return {
    id: performance.id,
    burpees_repetitions: performance.burpees_repetitions
      ? Number(performance.burpees_repetitions)
      : null,
    end_date: formatDate(performance.end_date, dateLocale),
    push_up_repetitions: performance.push_up_repetitions
      ? Number(performance.push_up_repetitions)
      : null,
    plank_time: plankTime,
    pull_ups_repetitions: performance.pull_ups_repetitions
      ? Number(performance.pull_ups_repetitions)
      : null,
    running_distance: performance?.running_distance
      ? `${Number(performance.running_distance)} km`
      : null,
    squats_repetitions: performance.squats_repetitions
      ? Number(performance.squats_repetitions)
      : null,
    start_date: formatDate(performance.start_date, dateLocale)
  };
};

export const getClientPerformanceData = (data, dateLocale) =>
  data.map((performance) => mapBasicPerformanceData(performance, dateLocale));

export const getPerformanceData = (data, dateLocale) =>
  data.map((performance) => ({
    ...mapBasicPerformanceData(performance, dateLocale),
    observations: performance?.observations,
    user: performance.user_id
  }));
