import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

function TopRankedTitle({ children }) {
  const { t } = useTranslation(['performances']);

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
      <Typography
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(20),
          fontWeight: 600
        }}>
        {t('topRankedPerTest', { ns: 'performances' })} -{' '}
        {t('topN', { num: 10, ns: 'performances' })}
      </Typography>
      {children}
    </Box>
  );
}

TopRankedTitle.propTypes = {
  children: PropTypes.node.isRequired
};

export default TopRankedTitle;
