import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import requestStatus from 'common/constants/status';

const initialState = {
  data: {
    availabilities: [],
    latest: null,
    toDelete: [],
    toUpsert: []
  },
  didRepeat: false,
  refresh: false,
  status: {
    general: requestStatus.idle,
    latest: requestStatus.idle,
    repeat: requestStatus.idle
  }
};

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    addToDelete(state, action) {
      state.data.toDelete.push(action.payload);
    },
    addToUpsert(state, action) {
      const index = state.data.toUpsert.findIndex((item) => item.id === action.payload.id);

      if (index !== -1) {
        state.data.toUpsert[index] = action.payload;
      } else {
        state.data.toUpsert.push(action.payload);
      }
    },
    forceDelete(state) {
      state.status.general = requestStatus.loading;
    },
    forceDeleteSuccess(state) {
      state.status.general = requestStatus.success;
      state.data.toDelete = [];
    },
    forceDeleteError(state) {
      state.status.general = requestStatus.error;
    },
    getLatest(state) {
      state.status.latest = requestStatus.loading;
    },
    getLatestSuccess(state, action) {
      state.data.latest = action.payload;
      state.status.latest = requestStatus.success;
    },
    getLatestError(state) {
      state.status.latest = requestStatus.error;
    },
    list(state) {
      state.status.general = requestStatus.loading;
      if (state.refresh) state.refresh = false;
    },
    listSuccess(state, action) {
      state.status.general = requestStatus.success;

      state.data.availabilities = action.payload.filter(
        (availability) => state.data.toDelete.indexOf(availability.id) === -1
      );
    },
    listError(state) {
      state.status.general = requestStatus.error;
    },
    removeToDelete(state, action) {
      const { toDelete } = state.data;
      const indexToRemove = toDelete.findIndex((item) => item.id === action.payload);

      if (indexToRemove !== -1) {
        toDelete.splice(indexToRemove, 1);
      }
    },
    removeToUpsert(state, action) {
      const { toUpsert } = state.data;
      const indexToRemove = toUpsert.findIndex((item) => item.id === action.payload);

      if (indexToRemove !== -1) {
        toUpsert.splice(indexToRemove, 1);
      }
    },
    repeat(state) {
      state.status.repeat = requestStatus.loading;
      if (state.didRepeat) state.didRepeat = false;
    },
    repeatSuccess(state, action) {
      state.didRepeat = true;
      state.data.toUpsert.push(...action.payload);
      state.status.repeat = requestStatus.success;
    },
    reset(state) {
      state.data.toDelete = [];
      state.data.toUpsert = [];
      state.refresh = true;
      if (state.didRepeat) state.didRepeat = false;
    },
    upsert(state) {
      state.status.general = requestStatus.loading;
    },
    upsertSuccess(state) {
      state.status.general = requestStatus.success;
    },
    upsertError(state) {
      state.status.general = requestStatus.error;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default availabilitySlice;
