import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import UserAvatar from 'components/shared/user/UserAvatar';

function SessionCardDefaultView({ roleToDisplay, user }) {
  const { t } = useTranslation([]);

  return (
    <>
      <UserAvatar user={user} size={50} textSize={25} />
      <Typography variant="body2" sx={{ fontWeight: 500, marginTop: 1 }}>
        {t(`role.${roleToDisplay}`, { ns: 'options' })}
      </Typography>
      <Typography variant="body2" align="center">
        {user.full_name}
      </Typography>
    </>
  );
}

SessionCardDefaultView.propTypes = {
  roleToDisplay: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default SessionCardDefaultView;
