import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Divider, List, ListItem, ListItemAvatar, ListItemText, Stack } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import UserAvatar from 'components/shared/user/UserAvatar';
import StyledAppbarTitle from 'common/theme/appbar/StyledAppbarTitle';

function MoreProfileUser({ profilePath }) {
  const { t } = useTranslation(['routes']);

  const firstName = useSelector((state) => state.auth.user.first_name);
  const fullName = useSelector((state) => state.auth.user.full_name);
  const lastName = useSelector((state) => state.auth.user.last_name);
  const photo = useSelector((state) => state.auth.user.photo);

  return (
    <Stack spacing={2}>
      <StyledAppbarTitle noWrap component="h1">
        {t('profile', { ns: 'routes' })}
      </StyledAppbarTitle>
      <div>
        <List dense>
          <ListItem
            button
            component={Link}
            to={profilePath}
            secondaryAction={<ChevronRight />}
            sx={{ pl: 0 }}>
            <ListItemAvatar>
              <UserAvatar
                size={40}
                textSize={15}
                user={{
                  first_name: firstName,
                  full_name: fullName,
                  last_name: lastName,
                  photo
                }}
              />
            </ListItemAvatar>
            <ListItemText primary={fullName} secondary={t('viewProfile', { ns: 'common' })} />
          </ListItem>
        </List>
        <Divider />
      </div>
    </Stack>
  );
}

MoreProfileUser.propTypes = {
  profilePath: PropTypes.string.isRequired
};

export default MoreProfileUser;
