import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import requestStatus from 'common/constants/status';
import { trashed } from 'common/constants/options';

const initialFiltersState = {
  incomplete: 'all',
  user_id: [],
  soft_delete: trashed.without
};

const initialState = {
  data: {
    currentPerformance: null,
    incompletePerformances: [],
    performanceHistory: {},
    performances: [],
    topRankedPerformances: {
      female: [],
      male: []
    }
  },
  filters: initialFiltersState,
  pagination: {
    first: 20,
    page: 1
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0
  },
  refresh: false,
  status: {
    general: requestStatus.idle,
    history: requestStatus.idle,
    incomplete: requestStatus.idle,
    latest: requestStatus.idle,
    topRanked: requestStatus.idle
  }
};

const performanceSlice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    create(state) {
      state.status.general = requestStatus.loading;
    },
    createSuccess(state) {
      state.status.general = requestStatus.success;
    },
    createError(state) {
      state.status.general = requestStatus.error;
    },
    find(state) {
      state.status.general = requestStatus.loading;
    },
    findSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.currentPerformance = action.payload;
    },
    findError(state) {
      state.status.general = requestStatus.error;
    },
    getHistory(state) {
      state.status.history = requestStatus.loading;
    },
    getHistorySuccess(state, action) {
      state.status.history = requestStatus.success;
      state.data.performanceHistory = action.payload;
    },
    getHistoryError(state) {
      state.status.history = requestStatus.error;
    },
    getLatest(state) {
      state.status.latest = requestStatus.loading;
    },
    getLatestSuccess(state, action) {
      state.data.currentPerformance = action.payload;
      state.status.latest = requestStatus.success;
    },
    getLatestError(state) {
      state.status.latest = requestStatus.error;
    },
    list(state) {
      state.status.general = requestStatus.loading;
      if (state.refresh) state.refresh = false;
    },
    listSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.performances = action.payload;
    },
    listError(state) {
      state.status.general = requestStatus.error;
    },
    listAll(state) {
      state.status.general = requestStatus.loading;
    },
    listAllSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.performances = action.payload;
    },
    listAllError(state) {
      state.status.general = requestStatus.error;
    },
    listIncomplete(state) {
      state.status.incomplete = requestStatus.loading;
    },
    listIncompleteSuccess(state, action) {
      state.status.incomplete = requestStatus.success;
      state.data.incompletePerformances = action.payload;
    },
    listIncompleteError(state) {
      state.status.incomplete = requestStatus.error;
    },
    remove(state) {
      state.status.general = requestStatus.loading;
    },
    removeSuccess(state) {
      state.status.general = requestStatus.success;
      state.refresh = true;
    },
    removeError(state) {
      state.status.general = requestStatus.error;
    },
    restore(state) {
      state.status.general = requestStatus.loading;
    },
    restoreSuccess(state) {
      state.status.general = requestStatus.success;
      state.refresh = true;
    },
    restoreError(state) {
      state.status.general = requestStatus.error;
    },
    resetFilters(state) {
      state.filters = initialFiltersState;
    },
    topRanked(state) {
      state.status.topRanked = requestStatus.loading;
    },
    topRankedSuccess(state, action) {
      state.status.topRanked = requestStatus.success;
      state.data.topRankedPerformances = action.payload;
    },
    topRankedError(state) {
      state.status.topRanked = requestStatus.error;
    },
    update(state) {
      state.status.general = requestStatus.loading;
    },
    updateSuccess(state) {
      state.status.general = requestStatus.success;
    },
    updateError(state) {
      state.status.general = requestStatus.error;
    },
    updateFilters(state, action) {
      state.filters = action.payload;
    },
    updatePagination(state, action) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    updatePaginatorInfo(state, action) {
      state.paginatorInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default performanceSlice;
