import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import { Box } from '@mui/material';
import TopRankedBar from 'components/shared/charts/performance/top-ranked/TopRankedBar';
import { getDefs, getFill } from 'common/utils/charts';

const formattedData = (data) =>
  data.map(({ place, total, totalRaw, user }) => ({
    label: total,
    place,
    total: totalRaw,
    user
  }));

function ResponsiveBarWithCustomLayers({ onClick, ...props }) {
  const layers = [
    'grid',
    'axes',
    (p) => <TopRankedBar {...p} onClick={onClick} />,
    'markers',
    'legends',
    'annotations'
  ];

  return <ResponsiveBar {...props} layers={layers} />;
}

ResponsiveBarWithCustomLayers.propTypes = {
  onClick: PropTypes.func.isRequired
};

function TopRankedChart({ data, gradient, onClick }) {
  const chartData = useMemo(() => formattedData(data), [data]);
  const defs = useMemo(
    () => getDefs({ id: gradient.id, from: gradient.from, to: gradient.to }),
    [gradient]
  );
  const fill = useMemo(() => getFill({ id: gradient.id }), [gradient]);

  return (
    <Box sx={{ height: '400px' }}>
      <ResponsiveBarWithCustomLayers
        animate
        axisBottom={null}
        axisLeft={null}
        axisRight={null}
        axisTop={null}
        data={chartData}
        defs={defs}
        enableGridY={false}
        fill={fill}
        indexBy="place"
        keys={['total']}
        label={null}
        margin={{ top: 50, right: 130, bottom: 0, left: 60 }}
        motionDamping={15}
        motionStiffness={90}
        onClick={onClick}
        padding={0.3}
      />
    </Box>
  );
}

TopRankedChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      place: PropTypes.number.isRequired,
      total: PropTypes.string.isRequired,
      totalRaw: PropTypes.number.isRequired,
      user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        photo: PropTypes.string
      }).isRequired
    })
  ).isRequired,
  gradient: PropTypes.shape({
    from: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
};

export default TopRankedChart;
