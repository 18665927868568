import routes from 'common/routes';
import { actions, dateRange, userProfile } from 'common/table/columns/customColumns';

/**
 * Generates columns configuration for client payment table.
 *
 * @param  {Object}    params                Parameters for configuring columns.
 * @param  {Array}     params.data           Data used in the table.
 * @param  {Function}  params.translateFunc  Function to translate labels.
 *
 * @returns  {Array}  Array of column configurations.
 */
export const getClientPaymentColumns = ({ data, translateFunc }) => [
  { label: translateFunc('voucherNumber', { ns: 'labels' }), name: 'id' },
  userProfile({ data, label: translateFunc('client', { count: 1, ns: 'labels' }) }),
  { label: translateFunc('amount', { ns: 'labels' }), name: 'price' },
  { label: translateFunc('plan', { ns: 'labels' }), name: 'plan' },
  dateRange({ data, label: translateFunc('invoicePeriod', { ns: 'labels' }) }),
  { label: translateFunc('paymentDate', { ns: 'labels' }), name: 'created_at' },
  actions({
    items: ['edit'],
    data,
    label: translateFunc('actions', { ns: 'labels' }),
    actionRoutes: { edit: routes.editClientPayment.path }
  })
];

/**
 * Generates basic columns configuration for client payment table.
 *
 * @param  {Object}    params                Parameters for configuring columns.
 * @param  {Array}     params.data           Data used in the table.
 * @param  {Function}  params.translateFunc  Function to translate labels.
 *
 * @returns  {Array}  Array of basic column configurations.
 */
export const getClientPaymentBasicColumns = ({ data, translateFunc }) => [
  { label: translateFunc('client', { count: 1, ns: 'labels' }), name: 'user' },
  { label: translateFunc('sessionsPaid', { ns: 'labels' }), name: 'sessions_paid' },
  dateRange({ data, label: translateFunc('invoicePeriod', { ns: 'labels' }) }),
  { label: translateFunc('paymentDate', { ns: 'labels' }), name: 'created_at' },
  { label: translateFunc('amount', { ns: 'labels' }), name: 'price' },
  { label: translateFunc('currency', { ns: 'labels' }), name: 'currency' }
];

/**
 * Generates columns configuration for my client payment table.
 *
 * @param  {Object}    params                Parameters for configuring columns.
 * @param  {Array}     params.data           Data used in the table.
 * @param  {Function}  params.translateFunc  Function to translate labels.
 *
 * @returns  {Array}  Array of column configurations for personal use.
 */
export const getMyClientPaymentColumns = ({ data, translateFunc }) => [
  { label: translateFunc('voucherNumber', { ns: 'labels' }), name: 'id' },
  { label: translateFunc('amount', { ns: 'labels' }), name: 'price' },
  { label: translateFunc('plan', { ns: 'labels' }), name: 'plan' },
  dateRange({ data, label: translateFunc('invoicePeriod', { ns: 'labels' }) }),
  { label: translateFunc('paymentDate', { ns: 'labels' }), name: 'created_at' }
];
