import styledComponent from 'styled-components';

const StyledProfilePictureDropzone = styledComponent.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

export default StyledProfilePictureDropzone;
