import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';
import { Box, Typography } from '@mui/material';
import MembershipStatusChip from 'components/shared/chips/MembershipStatusChip';
import StyledH2Title from 'common/theme/shared/StyledH2Title';
import { getCountryFromJson } from 'common/utils/geo';

function UserInfo({ age, country, fullName, gender, height, membershipStatus }) {
  const { t } = useTranslation(['labels']);
  const lowerCasedGender = gender.toLowerCase();
  const genderDisplay = _capitalize(t(`gender.${lowerCasedGender}`, { ns: 'options' }));

  return (
    <Box sx={{ marginLeft: '36px' }}>
      <StyledH2Title component="h2">{fullName}</StyledH2Title>
      <Typography
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(20),
          fontWeight: 500
        }}>
        {t('age', { ns: 'labels' })}:{' '}
        <Typography
          component="span"
          sx={{ fontSize: (theme) => theme.typography.pxToRem(20), fontWeight: 400 }}>
          {age} {t('year', { count: 2, ns: 'common' })}
        </Typography>
      </Typography>
      {height && (
        <Typography
          sx={{
            fontSize: (theme) => theme.typography.pxToRem(20),
            fontWeight: 500
          }}>
          {t('height', { ns: 'labels' })}:{' '}
          <Typography
            component="span"
            sx={{ fontSize: (theme) => theme.typography.pxToRem(20), fontWeight: 400 }}>
            {height} mts
          </Typography>
        </Typography>
      )}
      <Typography
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(20),
          fontWeight: 500
        }}>
        {t('gender', { ns: 'labels' })}:{' '}
        <Typography
          component="span"
          sx={{ fontSize: (theme) => theme.typography.pxToRem(20), fontWeight: 400 }}>
          {genderDisplay}
        </Typography>
      </Typography>
      <Typography
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(20),
          fontWeight: 500,
          marginBottom: '15px'
        }}>
        {t('country', { ns: 'labels' })}:{' '}
        <Typography
          component="span"
          sx={{ fontSize: (theme) => theme.typography.pxToRem(20), fontWeight: 400 }}>
          {getCountryFromJson(country).label}
        </Typography>
      </Typography>
      {membershipStatus && (
        <MembershipStatusChip context={lowerCasedGender} status={membershipStatus} />
      )}
    </Box>
  );
}

UserInfo.defaultProps = {
  height: null,
  membershipStatus: null
};

UserInfo.propTypes = {
  age: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  height: PropTypes.number,
  membershipStatus: PropTypes.string
};

export default UserInfo;
