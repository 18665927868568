import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, IconButton, InputAdornment, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ShuffleVariant } from 'mdi-material-ui';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  StyledFormHelperText,
  StyledInputLabel,
  StyledOutlineInput
} from 'common/theme/fields/StyledPassword';

function PasswordFieldAdapter({ id, input, label, meta, onShuffle, shuffle, ...rest }) {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onChange = (event) => {
    input.onChange(event.target.value);
  };

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onClickShuffle = () => {
    onShuffle();
  };

  const onKeyDownPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const onKeyDownShuffle = (event) => {
    event.preventDefault();
    onShuffle();
  };

  return (
    <FormControl
      {...rest}
      error={meta.touched ? meta.invalid : false}
      size={size}
      variant="outlined">
      <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
      <StyledOutlineInput
        {...input}
        {...rest}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle-password-visibility"
              edge="end"
              onClick={onClickShowPassword}
              onKeyDown={onKeyDownPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            {shuffle && (
              <IconButton
                arial-label="shuffle"
                onClick={onClickShuffle}
                onKeyDown={onKeyDownShuffle}>
                <ShuffleVariant />
              </IconButton>
            )}
          </InputAdornment>
        }
        id={id}
        label={label}
        margin="dense"
        onChange={onChange}
        type={showPassword ? 'text' : 'password'}
      />
      {meta.touched && meta.invalid && (
        <StyledFormHelperText>{meta.invalid ? meta.error : ''}</StyledFormHelperText>
      )}
    </FormControl>
  );
}

PasswordFieldAdapter.defaultProps = {
  shuffle: false,
  onShuffle: () => {}
};

PasswordFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  shuffle: PropTypes.bool,
  onShuffle: PropTypes.func
};

export default PasswordFieldAdapter;
