import React from 'react';
import { Unstable_Grid2 as Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledProgressGalleryGridContainer from 'common/theme/galleries/progress/StyledProgressGalleryGridContainer';
import StyledProgressGalleryGridItem from 'common/theme/galleries/progress/StyledProgressGalleryGridItem';
import StyledProgressGalleryHighlightSkeleton from 'common/theme/galleries/progress/StyledProgressGalleryHighlightSkeleton';
import StyledProgressGalleryThumbnailSkeleton from 'common/theme/galleries/progress/StyledProgressGalleryThumbnailSkeleton';
import { generateRandomKey } from 'common/utils/general';

function ProgressGallerySkeleton() {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const numberOfThumbnails = isExtraSmall ? 2 : 3;

  const largeSkeletons = [...Array(3)].map(() => (
    <Grid key={generateRandomKey(12)} xs={12} sm={4}>
      <StyledProgressGalleryHighlightSkeleton variant="rectangular" width="100%" />
    </Grid>
  ));

  return (
    <>
      {isMedium && (
        <Grid container spacing={4} sx={{ marginBottom: theme.spacing(5) }}>
          {largeSkeletons}
        </Grid>
      )}
      <StyledProgressGalleryGridContainer>
        {[...Array(6)].map(() => (
          <StyledProgressGalleryGridItem key={generateRandomKey(12)}>
            {[...Array(numberOfThumbnails)].map(() => (
              <StyledProgressGalleryThumbnailSkeleton
                variant="rectangular"
                width="100%"
                key={generateRandomKey(12)}
              />
            ))}
          </StyledProgressGalleryGridItem>
        ))}
      </StyledProgressGalleryGridContainer>
    </>
  );
}

export default ProgressGallerySkeleton;
