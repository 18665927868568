import { parseISO } from 'date-fns';

/**
 * Creates initial values for updating admin data.
 *
 * @param {Object} data - The admin data.
 * @param {string} data.birthdate - The admin's birthdate.
 * @param {string} data.email - The admin's email.
 * @param {string} data.first_name - The admin's first name.
 * @param {string} data.gender - The admin's gender.
 * @param {string} data.last_name - The admin's last name.
 * @param {string} data.settings.language - The admin's preferred UI language.
 * @returns {Object} - The initial values object.
 */
export const updateAdminInitialValues = (data) => ({
  birthdate: parseISO(data.birthdate),
  email: data.email,
  first_name: data.first_name,
  gender: data.gender,
  last_name: data.last_name,
  password: null,
  ui_language: data.settings.language
});

/**
 * Creates initial values for updating client data.
 *
 * @param {Object} data - The client data.
 * @param {string} data.birthdate - The client's birthdate in ISO format.
 * @param {string} data.city - The client's city.
 * @param {string} data.country - The client's country in JSON string format.
 * @param {string} data.email - The client's email.
 * @param {string} data.first_name - The client's first name.
 * @param {string} data.gender - The client's gender.
 * @param {string} data.last_name - The client's last name.
 * @param {string} [data.observations] - Observations about the client.
 * @param {null} data.password - A placeholder for the client's password.
 * @param {string} data.phone - The client's phone number.
 * @param {number} data.plan_id - The ID of the client's plan.
 * @param {string} data.state - The client's state in JSON string format.
 * @param {string} data.settings.language - The client's UI language setting.
 * @returns {Object} - The initial values object.
 */
export const updateClientInitialValues = (data) => ({
  birthdate: parseISO(data.birthdate),
  city: { value: data.city, label: data.city },
  country: JSON.parse(data.country),
  email: data.email,
  first_name: data.first_name,
  gender: data.gender,
  last_name: data.last_name,
  observations: data?.observations,
  password: null,
  phone: data.phone,
  plan_id: Number(data.plan_id.id),
  state: JSON.parse(data.state),
  ui_language: data.settings.language
});

/**
 * Creates initial values for updating client's profile data.
 *
 * @param {Object} data - The client profile data.
 * @param {string} data.birthdate - The client's birthdate in ISO format.
 * @param {string} data.city - The client's city.
 * @param {string} data.contact_name - The contact name in the client's profile.
 * @param {string} data.contact_phone - The contact phone in the client's profile.
 * @param {string} data.country - The client's country in JSON string format.
 * @param {string} data.email - The client's email.
 * @param {string} data.eps - The client's health insurance provider.
 * @param {string} data.first_name - The client's first name.
 * @param {string} data.gender - The client's gender.
 * @param {string} data.identification_number - The client's identification number.
 * @param {string} data.last_name - The client's last name.
 * @param {string} [data.observations] - Observations about the client.
 * @param {null} data.password - A placeholder for the client's password.
 * @param {string} data.phone - The client's phone number.
 * @param {string} data.photo - The client's profile photo URL.
 * @param {string} data.state - The client's state in JSON string format.
 * @returns {Object} - The initial values object.
 */
export const updateClientProfileInitialValues = (data) => ({
  birthdate: parseISO(data.birthdate),
  city: data.city,
  contact_name: data.contact_name,
  contact_phone: data.contact_phone,
  country: JSON.parse(data.country),
  email: data.email,
  eps: data.eps,
  first_name: data.first_name,
  gender: data.gender,
  identification_number: data.identification_number,
  last_name: data.last_name,
  observations: data?.observations,
  password: null,
  phone: data.phone,
  photo: data.photo,
  state: JSON.parse(data.state)
});

/**
 * Creates initial values for updating client's payment data.
 *
 * @param {Object} data - The client payment data.
 * @param {string} data.created_at - The creation date of the payment in ISO format.
 * @param {string} data.currency - The currency used for the payment.
 * @param {string} data.end_date - The end date of the payment period in ISO format.
 * @param {string} data.invoice_url - The URL of the payment invoice.
 * @param {string} data.observations - Observations about the payment.
 * @param {string} data.payment_method - The payment method used.
 * @param {number} data.plan_id - The ID of the plan associated with the payment.
 * @param {number} data.price - The amount paid.
 * @param {number} data.sessions_paid - The number of sessions paid for.
 * @param {string} data.start_date - The start date of the payment period in ISO format.
 * @param {Object} data.user_id - The user associated with the payment.
 * @returns {Object} - The initial values object.
 */
export const updateClientPaymentInitialValues = (data) => ({
  created_at: parseISO(data.created_at),
  currency: data.currency,
  end_date: parseISO(data.end_date),
  invoice_url: data.invoice_url,
  observations: data.observations,
  payment_method: data.payment_method,
  plan_id: Number(data.plan_id.id),
  price: Number(data.price),
  sessions_paid: Number(data.sessions_paid),
  start_date: parseISO(data.start_date),
  user_id: {
    label: data.user_id.full_name,
    value: data.user_id.id,
    plan_id: {
      id: data.plan_id.id,
      name: data.plan_id.name,
      price: Number(data.price),
      sessions: Number(data.sessions_paid)
    }
  }
});

/**
 * Creates initial values for updating legal information.
 *
 * @param {Object} data - The legal data.
 * @param {string} data.created_at - The creation date of the legal document in ISO format.
 * @param {string} data.body - The content body of the legal document.
 * @param {string} data.language - The language of the legal document.
 * @param {string} data.role - The role associated with the legal document.
 * @param {string} data.title - The title of the legal document.
 * @returns {Object} - The initial values object.
 */
export const updateLegalInitialValues = (data) => ({
  created_at: parseISO(data.created_at),
  body: data.body,
  language: data.language,
  role: data.role,
  title: data.title
});

/**
 * Creates initial values for updating meal plan information.
 *
 * @param {Object} data - The meal plan data.
 * @param {string} data.created_at - The creation date of the meal plan in ISO format.
 * @param {string} data.pdf_document - The URL of the meal plan PDF document.
 * @param {Object} data.user_id - The user associated with the meal plan.
 * @returns {Object} - The initial values object.
 */
export const updateMealPlanInitialValues = (data) => ({
  created_at: parseISO(data.created_at),
  pdf_document: data.pdf_document,
  user_id: {
    label: data.user_id.full_name,
    value: data.user_id.id
  }
});

/**
 * Creates initial values for updating news information.
 *
 * @param {Object} data - The news data.
 * @param {string} data.created_at - The creation date of the news item in ISO format.
 * @param {string} data.body - The content body of the news item.
 * @param {string} data.featured_image - The URL of the news item's featured image.
 * @param {string} data.language - The language of the news item.
 * @param {string} data.mobile_featured_image - The URL of the mobile-optimized featured image.
 * @param {string} data.title - The title of the news item.
 * @returns {Object} - The initial values object.
 */
export const updateNewsInitialValues = (data) => ({
  created_at: parseISO(data.created_at),
  body: data.body,
  featured_image: data.featured_image,
  language: data.language,
  mobile_featured_image: data.mobile_featured_image,
  title: data.title
});

/**
 * Creates initial values for updating performance data.
 *
 * @param {Object} data - The performance data.
 * @param {number} data.burpees_repetitions - Number of repetitions for burpees.
 * @param {string} data.created_at - The creation date of the performance record in ISO format.
 * @param {string} data.end_date - The end date of the performance record in ISO format.
 * @param {number} data.plank_time_minutes - Time in minutes for plank.
 * @param {number} data.plank_time_seconds - Time in seconds for plank.
 * @param {number} data.pull_ups_repetitions - Number of repetitions for pull-ups.
 * @param {number} data.push_up_repetitions - Number of repetitions for push-ups.
 * @param {number} data.running_distance - Distance covered in running.
 * @param {number} data.squats_repetitions - Number of repetitions for squats.
 * @param {string} data.start_date - The start date of the performance record in ISO format.
 * @param {Object} data.user_id - The user associated with the performance record.
 * @returns {Object} - The initial values object.
 */
export const updatePerformanceInitialValues = (data) => ({
  burpees_repetitions: Number(data.burpees_repetitions),
  created_at: parseISO(data.created_at),
  end_date: parseISO(data.end_date),
  observations: data.observations,
  plank_time_minutes: Number(data.plank_time_minutes),
  plank_time_seconds: Number(data.plank_time_seconds),
  pull_ups_repetitions: Number(data.pull_ups_repetitions),
  push_up_repetitions: Number(data.push_up_repetitions),
  running_distance: Number(data.running_distance),
  squats_repetitions: Number(data.squats_repetitions),
  start_date: parseISO(data.start_date),
  user_id: {
    label: data.user_id.full_name,
    value: data.user_id.id
  }
});

/**
 * Creates initial values for updating plan information.
 *
 * @param {Object} data - The plan data.
 * @param {string} data.currency - The currency used in the plan.
 * @param {string} data.name - The name of the plan.
 * @param {number} data.price - The price of the plan.
 * @param {number} data.sessions - The number of sessions included in the plan.
 * @returns {Object} - The initial values object.
 */
export const updatePlanInitialValues = (data) => ({
  currency: data.currency,
  name: data.name,
  price: data.price,
  sessions: data.sessions
});

/**
 * Creates initial values for updating progress data.
 *
 * @param {Object} data - The progress data.
 * @param {string} data.back_picture - The URL of the back picture.
 * @param {string} data.created_at - The creation date of the progress record in ISO format.
 * @param {string} data.end_date - The end date of the progress record in ISO format.
 * @param {string} data.front_picture - The URL of the front picture.
 * @param {number} data.height - The height of the user.
 * @param {string} data.observations - Observations about the progress.
 * @param {string} data.profile_picture - The URL of the profile picture.
 * @param {string} data.start_date - The start date of the progress record in ISO format.
 * @param {Object} data.user_id - The user associated with the progress record.
 * @param {number} data.weight - The weight of the user.
 * @returns {Object} - The initial values object.
 */
export const updateProgressInitialValues = (data) => ({
  back_picture: data.back_picture,
  created_at: parseISO(data.created_at),
  end_date: parseISO(data.end_date),
  front_picture: data.front_picture,
  height: Number(data.height),
  observations: data.observations,
  profile_picture: data.profile_picture,
  start_date: parseISO(data.start_date),
  user_id: {
    label: data.user_id.full_name,
    value: data.user_id.id
  },
  weight: Number(data.weight)
});

/**
 * Creates initial values for updating session data.
 *
 * @param {Object} data - The session data.
 * @param {Object} data.client_id - The client associated with the session.
 * @param {Date} data.date - The date of the session.
 * @param {Date} data.from - The start time of the session.
 * @param {string} data.language - The language used in the session.
 * @param {string} data.observations - Observations about the session.
 * @param {number} data.session_type_id - The ID of the session type.
 * @param {Date} data.to - The end time of the session.
 * @param {string} data.trainer_id - The trainer ID associated with the session.
 * @returns {Object} - The initial values object.
 */
export const updateSessionInitialValues = (data) => ({
  client_id: {
    label: data.client_id.full_name,
    value: data.client_id.id
  },
  date: new Date(data.start_date),
  from: new Date(data.start_date),
  language: data.language,
  observations: data.observations,
  session_type_id: Number(data.session_type_id.id),
  status: data.status,
  to: new Date(data.end_date),
  trainer_id: data.trainer_id
});

/**
 * Creates initial values for updating session type information.
 *
 * @param {Object} data - The session type data.
 * @param {string} data.currency - The currency used for the session type.
 * @param {string} data.name - The name of the session type.
 * @param {number} data.price - The price of the session type.
 * @returns {Object} - The initial values object.
 */
export const updateSessionTypeInitialValues = (data) => ({
  currency: data.currency,
  name: data.name,
  price: data.price
});

/**
 * Creates initial values for updating trainer data.
 *
 * @param {Object} data - The trainer data.
 * @param {string} data.birthdate - The trainer's birthdate in ISO format.
 * @param {string} data.country - The trainer's country in JSON string format.
 * @param {string} data.email - The trainer's email.
 * @param {string} data.first_name - The trainer's first name.
 * @param {string} data.gender - The trainer's gender.
 * @param {Array} data.languages - The languages spoken by the trainer.
 * @param {string} data.last_name - The trainer's last name.
 * @param {string} [data.observations] - Observations about the trainer.
 * @param {null} data.password - A placeholder for the trainer's password.
 * @param {string} data.phone - The trainer's phone number.
 * @param {Array} data.specialties - The specialties of the trainer.
 * @param {string} data.settings.language - The trainer's UI language setting.
 * @returns {Object} - The initial values object.
 */
export const updateTrainerInitialValues = (data) => ({
  birthdate: parseISO(data.birthdate),
  country: JSON.parse(data.country),
  email: data.email,
  first_name: data.first_name,
  gender: data.gender,
  languages: data.languages.map((trainerLanguage) => trainerLanguage),
  last_name: data.last_name,
  observations: data?.observations,
  password: null,
  phone: data.phone,
  specialties: data.specialties.map((specialty) => Number(specialty.id)),
  ui_language: data.settings.language
});

/**
 * Creates initial values for updating trainer's payment data.
 *
 * @param {Object} data - The trainer payment data.
 * @param {string} data.created_at - The creation date of the payment record in ISO format.
 * @param {string} data.currency - The currency used for the payment.
 * @param {string} data.end_date - The end date of the payment period in ISO format.
 * @param {string} data.invoice_url - The URL of the payment invoice.
 * @param {string} data.observations - Observations about the payment.
 * @param {number} data.price - The amount paid.
 * @param {number} data.sessions_completed - The number of sessions completed.
 * @param {string} data.start_date - The start date of the payment period in ISO format.
 * @param {Object} data.user_id - The user associated with the payment.
 * @returns {Object} - The initial values object.
 */
export const updateTrainerPaymentInitialValues = (data) => ({
  created_at: parseISO(data.created_at),
  currency: data.currency,
  end_date: parseISO(data.end_date),
  invoice_url: data.invoice_url,
  observations: data.observations,
  price: Number(data.price),
  sessions_completed: Number(data.sessions_completed),
  start_date: parseISO(data.start_date),
  user_id: {
    label: data.user_id.full_name,
    value: data.user_id.id
  }
});

/**
 * Creates initial values for updating a trainer's profile.
 *
 * @param {Object} data - The trainer profile data.
 * @param {string} data.academic_formation - The trainer's academic background.
 * @param {string} data.bank - The trainer's affiliated bank.
 * @param {string} data.bank_account_certificate - Certificate or document associated with the trainer's bank account.
 * @param {number} data.bank_account_number - The trainer's bank account number.
 * @param {string} data.bank_account_type - The type of the trainer's bank account.
 * @param {string} data.birthdate - The trainer's birthdate in ISO format.
 * @param {string} data.city - The trainer's city.
 * @param {string} data.contact_name - The name of the trainer's emergency contact.
 * @param {string} data.contact_phone - The phone number of the trainer's emergency contact.
 * @param {string} data.country - The trainer's country in JSON string format.
 * @param {string} data.email - The trainer's email address.
 * @param {string} data.eps - The trainer's health insurance information.
 * @param {string} data.experience - The trainer's work experience.
 * @param {string} data.first_name - The trainer's first name.
 * @param {string} data.gender - The trainer's gender.
 * @param {string} data.identification_document - The trainer's identification document.
 * @param {string} data.identification_number - The trainer's identification number.
 * @param {Array} data.languages - The languages spoken by the trainer.
 * @param {string} data.last_name - The trainer's last name.
 * @param {string} [data.observations] - Observations or notes about the trainer.
 * @param {string} data.online_meeting_url - The URL for the trainer's online meetings.
 * @param {null} data.password - A placeholder for the trainer's password.
 * @param {string} data.phone - The trainer's phone number.
 * @param {string} data.photo - The URL of the trainer's photo.
 * @param {Array} data.specialties - The IDs of the trainer's specialties.
 * @param {string} data.state - The trainer's state in JSON string format.
 * @returns {Object} - The initial values object.
 */
export const updateTrainerProfileInitialValues = (data) => ({
  academic_formation: data.academic_formation,
  bank: data.bank,
  bank_account_certificate: data.bank_account_certificate,
  bank_account_number: Number(data.bank_account_number),
  bank_account_type: data.bank_account_type,
  birthdate: parseISO(data.birthdate),
  city: data.city,
  contact_name: data.contact_name,
  contact_phone: data.contact_phone,
  country: JSON.parse(data.country),
  email: data.email,
  eps: data.eps,
  experience: data.experience,
  first_name: data.first_name,
  gender: data.gender,
  identification_document: data.identification_document,
  identification_number: data.identification_number,
  languages: data.languages,
  last_name: data.last_name,
  observations: data?.observations,
  online_meeting_url: data.online_meeting_url,
  password: null,
  phone: data.phone,
  photo: data.photo,
  specialties: data.specialties.map((specialty) => Number(specialty.id)),
  state: JSON.parse(data.state)
});
