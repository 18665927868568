import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ClientPaymentFields from 'components/client-payment/ClientPaymentFields';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import defaultPaymentFormValidations from 'common/validations/form/defaultPayment';
import { updateClientPaymentInitialValues } from 'common/form/initial-values/update';
import { createClientPaymentSearchFields } from 'common/constants/query-fields/client';
import getDataToSave from 'common/form/dataToSave';
import { getFieldsToUpdate } from 'common/form/utils';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import { clientPaymentFormDecorators } from 'common/utils/payment';
import { handleClientIdChange } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import clientPaymentSlice from 'store/modules/clientPayment';

const requiredFields = [
  'created_at',
  'end_date',
  'plan_name',
  'price',
  'sessions_paid',
  'start_date',
  'user_id'
];

const calculator = clientPaymentFormDecorators;

function ClientPaymentEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('payment.client.edit', { ns: 'routes' });
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({});
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const clientOptions = useSelector((state) => state.client.data.search);
  const clientPayment = useSelector((state) => state.clientPayment.data.currentPayment);
  const searchStatus = useSelector((state) => state.client.status.search);
  const status = useSelector((state) => state.clientPayment.status.general);

  useEffect(() => {
    dispatch(clientPaymentSlice.actions.find(id));
  }, []);

  useEffect(() => {
    if (clientPayment && Object.keys(clientPayment).length > 0 && clientPayment.id === id) {
      const clientPaymentData = updateClientPaymentInitialValues(clientPayment);
      setInitialValues(clientPaymentData);
    }
  }, [clientPayment]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onClientIdChange = handleClientIdChange(dispatch, createClientPaymentSearchFields);

  const onEdit = useCallback(
    (values) => {
      // eslint-disable-next-line camelcase
      const { plan_name, ...cleanValues } = values;
      const fieldsToUpdate = getFieldsToUpdate(cleanValues, initialValues);
      const dataToSave = getDataToSave(fieldsToUpdate, 'payment');
      dispatch(clientPaymentSlice.actions.update({ id, input: dataToSave }));
    },
    [dispatch, id, initialValues]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        decorators={[calculator]}
        initialValues={initialValues}
        onSubmit={onEdit}
        validate={defaultPaymentFormValidations}
        render={({ handleSubmit, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <ClientPaymentFields
              clientOptions={clientOptions}
              onClientIdChange={onClientIdChange}
              requiredFields={requiredFields}
              searchStatus={searchStatus}
            />
            <StyledButtonsContainer>
              <SecondaryButtonLoading
                component={RouterLink}
                disabled={status === requestStatus.loading}
                style={{ marginRight: '32px' }}
                label={t('cancel', { ns: 'buttons' })}
                to={routes.listClientPayments.administrator.path}
                type="button"
              />
              <PrimaryLoadingButton
                disabled={invalid || pristine || status === requestStatus.loading}
                loading={status === requestStatus.loading}
                label={t('payment.client.edit', { ns: 'buttons' })}
                type="submit"
              />
            </StyledButtonsContainer>
          </form>
        )}
      />
    </>
  );
}

export default ClientPaymentEdit;
