import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Unstable_Grid2 as Grid } from '@mui/material';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import FileUploadAdapter from 'components/shared/field-adapters/FileUploadAdapter';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import requestStatus from 'common/constants/status';
import { isRequiredField } from 'common/utils/general';
import useForms from 'hooks/useForms';

function MealPlanFields({ clientOptions, onClientIdChange, requiredFields, searchStatus }) {
  const { t } = useTranslation(['labels']);
  const { clearClientSearchResults } = useForms;

  return (
    <Grid container spacing={4}>
      <Grid xs={12}>
        <Field
          id="user_id"
          name="user_id"
          component={TypeaheadFieldAdapter}
          fullWidth
          loading={searchStatus === requestStatus.loading}
          isMultiple={false}
          onClose={clearClientSearchResults}
          onInputChange={onClientIdChange}
          options={clientOptions}
          renderInputProps={{
            label: t('client', { count: 1, ns: 'labels' }),
            required: isRequiredField('user_id', requiredFields)
          }}
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Field
          id="pdf_document"
          name="pdf_document"
          component={FileUploadAdapter}
          label={t('mealPlan', { ns: 'labels' })}
          allowedFileTypes={{
            'application/pdf': ['.pdf']
          }}
          required
        />
      </Grid>
      <Grid xs={12} sm={6}>
        <Field
          id="created_at"
          name="created_at"
          component={DatePickerFieldAdapter}
          label={t('createdAt', { ns: 'labels' })}
          renderInputProps={{
            fullWidth: true,
            required: isRequiredField('created_at', requiredFields)
          }}
        />
      </Grid>
    </Grid>
  );
}

MealPlanFields.propTypes = {
  clientOptions: PropTypes.array.isRequired,
  onClientIdChange: PropTypes.func.isRequired,
  requiredFields: PropTypes.array.isRequired,
  searchStatus: PropTypes.string.isRequired
};

export default MealPlanFields;
