import { createContext } from 'react';

const LayoutContext = createContext({
  compactLayout: false,
  setCompactLayout: () => {},
  noBgColorLayout: false,
  setNoBgColorLayout: () => {}
});

export default LayoutContext;
