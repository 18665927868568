import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Unstable_Grid2 as Grid } from '@mui/material';
import DashboardBlock from 'components/shared/blocks/DashboardBlock';
import LatestPerformanceCard from 'components/shared/cards/latest-performance/LatestPerformanceCard';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import PerformanceHistoryCharts from 'components/shared/charts/performance/history/PerformanceHistoryCharts';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import LatestPerformanceTable from 'components/shared/tables/LatestPerformanceTable';
import { roles } from 'common/constants/options';
import { clientPerformanceListFields } from 'common/constants/query-fields/performance';
import AppBarContext from 'context/AppBarContext';
import performanceSlice from 'store/modules/performance';

const itemsDisplayedPerPage = 3;

function PerformanceHistory() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['routes', 'performances']);
  const pageTitle = t('performance.myPerformance', { ns: 'routes' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const currentPerformance = useSelector((state) => state.performance.data.currentPerformance);
  const latestStatus = useSelector((state) => state.performance.status.latest);
  const performances = useSelector((state) => state.performance.data.performances);
  const performanceStatus = useSelector((state) => state.performance.status.general);
  const userId = useSelector((state) => state.auth.user.id);

  useEffect(() => {
    dispatch(performanceSlice.actions.getLatest(userId));
  }, [userId]);

  useEffect(() => {
    dispatch(
      performanceSlice.actions.list({
        fields: clientPerformanceListFields,
        filters: { user_id: userId },
        pagination: { first: itemsDisplayedPerPage, page: 1 }
      })
    );
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <DashboardBlock title={t('latestPerformanceCard.title', { ns: 'performances' })}>
            <LoadingBlock
              isValid={currentPerformance && Object.keys(currentPerformance).length > 0}
              status={latestStatus}>
              <LatestPerformanceCard performance={currentPerformance} />
            </LoadingBlock>
          </DashboardBlock>
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardBlock
            title={t('performanceOverview', { ns: 'performances' })}
            titleNote={t('latestNPerformance', { num: 3, ns: 'performances' })}>
            <LoadingBlock
              emptyBlockProps={{
                imageType: 'man'
              }}
              isValid={performances.length > 0}
              status={performanceStatus}>
              <LatestPerformanceTable
                performances={performances}
                rowsPerPage={3}
                view={roles.client}
              />
            </LoadingBlock>
          </DashboardBlock>
        </Grid>
        <Grid item xs={12}>
          <DashboardBlock>
            <PerformanceHistoryCharts userId={Number(userId)} />
          </DashboardBlock>
        </Grid>
      </Grid>
    </>
  );
}

export default PerformanceHistory;
