import { Box, styled } from '@mui/material';

const StyledNotificationDotContainer = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexGrow: 1,
  textAlign: 'center'
}));

export default StyledNotificationDotContainer;
