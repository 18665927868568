import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import StyledUserAvatarImage from 'common/theme/user-avatar/StyledUserAvatarImage';
import StyledUserAvatarInitials from 'common/theme/user-avatar/StyledUserAvatarInitials';

function UserAvatar({ border, size, textSize, user }) {
  return (
    <Box>
      {user.photo ? (
        <StyledUserAvatarImage
          alt={user.full_name || `${user.first_name} ${user.last_name}`}
          border={border}
          size={size}
          src={user.photo}
        />
      ) : (
        <StyledUserAvatarInitials size={size} textSize={textSize}>
          {user.first_name.charAt(0)}
          {user.last_name.charAt(0)}
        </StyledUserAvatarInitials>
      )}
    </Box>
  );
}

UserAvatar.defaultProps = {
  border: false,
  textSize: 32
};

UserAvatar.propTypes = {
  border: PropTypes.bool,
  size: PropTypes.number.isRequired,
  textSize: PropTypes.number,
  user: PropTypes.object.isRequired
};

export default UserAvatar;
