import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import AdminSessionFilters from 'components/session/filters/AdminSessionFilters';
import AlertBlock from 'components/shared/blocks/AlertBlock';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import SessionFilters from 'components/session/filters/SessionFilters';
import { sessionStatus } from 'common/constants/options';
import { getAdminSessionColumns, getSessionColumns } from 'common/table/columns/session';
import { getAdminSessionData, getSessionData } from 'common/table/data/session';
import {
  defaultTableOptions,
  getSelectToolBar,
  isSessionSelectable,
  tableLocalization
} from 'common/table/utils';
import { isAdmin, isClient, isTrainer } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import sessionSlice from 'store/modules/session';

function SessionList() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['labels', 'routes', 'sessions', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const filters = useSelector((state) => state.session.filters);
  const pagination = useSelector((state) => state.session.pagination);
  const paginatorInfo = useSelector((state) => state.session.paginatorInfo);
  const refresh = useSelector((state) => state.session.refresh);
  const role = useSelector((state) => state.auth.user.role);
  const sessions = useSelector((state) => state.session.data.list);
  const status = useSelector((state) => state.session.status.general);
  const userId = useSelector((state) => state.auth.user.id);

  const isAdminUser = isAdmin(role);
  const isClientUser = isClient(role);
  const isTrainerUser = isTrainer(role);
  const titlei18nKey = isAdminUser ? 'session.list' : 'session.mySessions';
  const pageTitle = t(titlei18nKey, { ns: 'routes' });
  const entityType = t('session.list', { ns: 'routes' }).toLowerCase();
  const emptyMessage = t('empty', { type: entityType, ns: 'tables' });

  useEffect(() => {
    const updatedFilters = {
      ...filters,
      ...(isClientUser && { client_id: userId }),
      ...(isTrainerUser && {
        trainer_id: userId,
        status: filters?.status?.length > 0 ? filters.status : sessionStatus.scheduled
      })
    };

    const orderBy = isTrainerUser ? { column: 'START_DATE', direction: 'ASC' } : null;

    dispatch(
      sessionSlice.actions.list({
        pagination,
        filters: updatedFilters,
        orderBy
      })
    );
  }, [dispatch, filters, pagination, refresh, role, userId]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onStatusChange = (sessionId, newSessionStatus) => {
    dispatch(sessionSlice.actions.update({ id: sessionId, input: { status: newSessionStatus } }));
  };

  const tableData = isAdminUser
    ? getAdminSessionData(sessions, dateLocale)
    : getSessionData(sessions, role, dateLocale);

  const columns = isAdminUser
    ? getAdminSessionColumns({ data: tableData, translateFunc: t })
    : getSessionColumns({ data: tableData, onStatusChange, role, translateFunc: t });

  const onChangePage = (page) => {
    dispatch(sessionSlice.actions.updatePagination({ page: page + 1 }));
  };

  const onDelete = (ids) => {
    dispatch(sessionSlice.actions.forceDelete(ids));
  };

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    customToolbarSelect: (selectedRows, displayData) =>
      getSelectToolBar({
        filters,
        rows: selectedRows,
        data: displayData,
        onDelete
      }),
    isRowSelectable: (dataIndex) => isSessionSelectable(tableData, dataIndex, role),
    onChangePage,
    page: pagination.page - 1,
    rowsPerPage: pagination.first,
    selectableRows: isAdminUser || isClientUser ? 'multiple' : 'none',
    selectableRowsHeader: isAdminUser || isClientUser,
    textLabels: tableLocalization(entityType, t)
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      {isAdminUser ? <AdminSessionFilters /> : <SessionFilters />}
      <Box
        sx={{
          position: 'relative'
        }}>
        <LoadingBlock
          emptyBlockProps={{ message: emptyMessage }}
          isValid={sessions.length > 0}
          skeletonProps={{ rows: 5 }}
          status={status}
          type="table">
          <>
            {isTrainerUser && <AlertBlock type="sessionUpdate" />}
            {isAdminUser && <AlertBlock type="sessionDelete" />}
            <MUIDataTable
              className={`session session--${role.toLowerCase()}`}
              columns={columns}
              data={tableData}
              options={options}
            />
          </>
        </LoadingBlock>
      </Box>
    </>
  );
}

export default SessionList;
