import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import requestStatus from 'common/constants/status';

const initialState = {
  status: requestStatus.idle,
  countries: [],
  states: [],
  cities: []
};

const geoSlice = createSlice({
  name: 'geo',
  initialState,
  reducers: {
    getCountries(state) {
      state.status = requestStatus.loading;
    },
    getCountriesSuccess(state, action) {
      state.countries = action.payload;
      state.status = requestStatus.success;
    },
    getCountriesError(state) {
      state.status = requestStatus.error;
    },
    getStates(state) {
      state.status = requestStatus.loading;
    },
    getStatesSuccess(state, action) {
      state.states = action.payload;
      state.status = requestStatus.success;
    },
    getStatesError(state) {
      state.status = requestStatus.error;
    },
    getCities(state) {
      state.status = requestStatus.loading;
    },
    getCitiesSuccess(state, action) {
      state.cities = action.payload;
      state.status = requestStatus.success;
    },
    getCitiesError(state) {
      state.status = requestStatus.error;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default geoSlice;
