import { lightFormat, parseISO } from 'date-fns';
import { monthYearFormat } from 'common/constants/date';
import palette from 'common/theme/palette';

export const getDefs = (gradient) => [
  {
    id: gradient.id,
    type: 'linearGradient',
    colors: [
      { offset: 0, color: gradient.from },
      { offset: 100, color: gradient.to }
    ]
  }
];

export const getFill = (gradient) => [{ match: '*', id: gradient.id }];

export const getTotalIncomeByMonthData = (data) => {
  const { COP = [], USD = [] } = data;
  const formattedData = [];

  const fallbackArray = [
    { id: 'COP', color: palette.primary.main, data: [] },
    { id: 'USD', color: palette.finished.main, data: [] }
  ];

  if (COP && COP.length) {
    formattedData.push({
      id: 'COP',
      color: palette.primary.main,
      data: data.COP.map((item) => ({
        x: lightFormat(parseISO(item.date), monthYearFormat),
        y: item.total
      }))
    });
  }

  if (USD && USD.length) {
    formattedData.push({
      id: 'USD',
      color: palette.finished.main,
      data: data.USD.map((item) => ({
        x: lightFormat(parseISO(item.date), monthYearFormat),
        y: item.total
      }))
    });
  }

  return formattedData.length ? formattedData : fallbackArray;
};

export const getTotalOutcomeByMonthData = (data) => [
  {
    id: 'COP',
    color: palette.primary.secondary,
    data: data.map((item) => ({
      x: lightFormat(parseISO(item.date), monthYearFormat),
      y: item.total
    }))
  }
];
