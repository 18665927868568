import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'common/routes/index';
import Gate from 'components/auth/Gate';

function Authenticated({ allowedRoles, children }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!user || Object.keys(user).length === 0) {
      navigate(routes.login.path);
    }
  }, [navigate, user]);

  return <Gate allowedRoles={allowedRoles}>{children}</Gate>;
}

Authenticated.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired
};

export default Authenticated;
