import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Unstable_Grid2 as Grid } from '@mui/material';
import OverviewProfileDetail from 'components/shared/overview-profile/OverviewProfileDetail';
import StyledOverviewProfileDivider from 'common/theme/overview-profile/StyledOverviewProfileDivider';
import StyledOverviewProfileSubtitle from 'common/theme/overview-profile/StyledOverviewProfileSubtitle';
import { getLanguages, getSpecialties } from 'common/utils/user';

function TrainerQualifications({ trainer, loading }) {
  const { t } = useTranslation(['labels']);
  const specialties = useMemo(
    () => (trainer?.specialties ? getSpecialties(trainer.specialties) : ''),
    [trainer]
  );
  const languages = useMemo(
    () => (trainer?.languages ? getLanguages(trainer.languages, t) : ''),
    [trainer]
  );

  return (
    <div>
      <StyledOverviewProfileSubtitle component="h4">
        {t('qualifications', { ns: 'labels' })}
      </StyledOverviewProfileSubtitle>
      <StyledOverviewProfileDivider />
      <Grid container spacing={{ xs: 2, md: 4 }}>
        <Grid xs={12}>
          <OverviewProfileDetail
            label={t('academicFormation', { ns: 'labels' })}
            value={trainer?.academic_formation}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <OverviewProfileDetail
            label={t('specialties', { ns: 'labels' })}
            value={specialties}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <OverviewProfileDetail
            label={t('language', { count: 2, ns: 'labels' })}
            value={languages}
            loading={loading}
          />
        </Grid>
      </Grid>
    </div>
  );
}

TrainerQualifications.defaultProps = {
  trainer: null
};

TrainerQualifications.propTypes = {
  trainer: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default TrainerQualifications;
