import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppBarPrimaryButton from 'components/shared/buttons/AppBarPrimaryButton';
import AppBarSecondaryButton from 'components/shared/buttons/AppBarSecondaryButton';
import requestStatus from 'common/constants/status';
import StyledAppBarButtonsContainer from 'common/theme/appbar/StyledAppBarButtonsContainer';
import useAvailabilityAppBar from 'hooks/availability/useAvailabilityAppBar';

function AvailabilityAppBarButtons() {
  const { t } = useTranslation(['buttons', 'routes']);

  const availabilityStatus = useSelector((state) => state.availability.status.general);
  const repeatStatus = useSelector((state) => state.availability.status.repeat);

  const { handleReset, handleSave, isSync } = useAvailabilityAppBar();

  return (
    <StyledAppBarButtonsContainer>
      <AppBarPrimaryButton
        disabled={
          availabilityStatus === requestStatus.loading ||
          isSync ||
          repeatStatus === requestStatus.loading
        }
        label={t('save', { ns: 'buttons' })}
        loading={availabilityStatus === requestStatus.loading}
        onClick={handleSave}
      />
      <AppBarSecondaryButton
        disabled={
          availabilityStatus === requestStatus.loading ||
          isSync ||
          repeatStatus === requestStatus.loading
        }
        label={t('reset', { ns: 'buttons' })}
        loading={availabilityStatus === requestStatus.loading}
        onClick={handleReset}
      />
    </StyledAppBarButtonsContainer>
  );
}

export default AvailabilityAppBarButtons;
