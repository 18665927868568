import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import DateRangePickerFieldAdapter from 'components/shared/field-adapters/DateRangePickerFieldAdapter';
import MultipleSelectFieldAdapter from 'components/shared/field-adapters/MultipleSelectFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import FilterButtons from 'components/shared/buttons/FilterButtons';
import { getTrashedOptions, getUiLanguageOptions } from 'common/constants/options';
import StyledFiltersContainer from 'common/theme/filters/StyledFiltersContainer';
import StyledFiltersForm from 'common/theme/filters/StyledFiltersForm';
import { formatDateRangeField } from 'common/utils/date';
import newsSlice from 'store/modules/news';

function NewsFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels']);

  const initialValues = useSelector((state) => state.news.filters);
  const status = useSelector((state) => state.news.status.general);

  const onFilter = useCallback(
    (values) => {
      const filters = formatDateRangeField(values, 'created_at');
      dispatch(newsSlice.actions.updateFilters(filters));
    },
    [dispatch]
  );

  const resetForm = useCallback(() => {
    dispatch(newsSlice.actions.resetFilters());
  }, [dispatch]);

  return (
    <StyledFiltersContainer>
      <Form
        initialValues={initialValues}
        onSubmit={onFilter}
        render={({ handleSubmit, pristine }) => (
          <StyledFiltersForm onSubmit={handleSubmit}>
            <Grid container spacing={{ xs: 2, lg: 4 }}>
              <Grid xs={12} sm={6}>
                <Field
                  id="created_at"
                  name="created_at"
                  label={t('createdAt', { ns: 'labels' })}
                  component={DateRangePickerFieldAdapter}
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <Field
                  id="language"
                  name="language"
                  component={MultipleSelectFieldAdapter}
                  options={getUiLanguageOptions(t)}
                  label={t('language', { count: 1, ns: 'labels' })}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={3}>
                <Field
                  id="soft_delete"
                  name="soft_delete"
                  component={SelectFieldAdapter}
                  options={getTrashedOptions(t)}
                  label={t('deleted', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
            <FilterButtons pristine={pristine} resetForm={resetForm} status={status} />
          </StyledFiltersForm>
        )}
      />
    </StyledFiltersContainer>
  );
}

export default NewsFilters;
