import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DoubleCounter from 'components/shared/counters/double/DoubleCounter';
import Emoji from 'components/shared/Emoji';

function MembershipStatusCounters() {
  const { t } = useTranslation(['labels']);

  const activeClientsCounter = useSelector((state) => state.client.counters.active);
  const inactiveClientsCounter = useSelector((state) => state.client.counters.inactive);

  return (
    <DoubleCounter
      emoji={<Emoji label={t('membershipStatus', { ns: 'labels' })} symbol="🎫" />}
      title={t('membershipStatus', { ns: 'labels' })}
      counters={[
        {
          title: `${t('membershipStatus.active', { context: 'female', ns: 'options' })}s`,
          counter: activeClientsCounter
        },
        {
          title: `${t('membershipStatus.inactive', { context: 'female', ns: 'options' })}s`,
          counter: inactiveClientsCounter
        }
      ]}
    />
  );
}

export default MembershipStatusCounters;
