import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { endOfDay, lightFormat, subMonths } from 'date-fns';
import { Stack, Unstable_Grid2 as Grid } from '@mui/material';
import ArrearageClientsTable from 'components/shared/tables/ArrearageClientsTable';
import DashboardBlock from 'components/shared/blocks/DashboardBlock';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import PaymentsTable from 'components/shared/tables/PaymentsTable';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TotalIncomeByMonthChart from 'components/shared/charts/payment/TotalIncomeByMonthChart';
import TotalOutcomeByMonthChart from 'components/shared/charts/payment/TotalOutcomeByMonthChart';
import { dateTimeFormat } from 'common/constants/date';
import { membershipStatus, roles } from 'common/constants/options';
import { clientListArrearageFields } from 'common/constants/query-fields/client';
import { clientPaymentListBasicFields } from 'common/constants/query-fields/clientPayment';
import { trainerPaymentListBasicFields } from 'common/constants/query-fields/trainerPayment';
import AppBarContext from 'context/AppBarContext';
import clientSlice from 'store/modules/client';
import clientPaymentSlice from 'store/modules/clientPayment';
import trainerPaymentSlice from 'store/modules/trainerPayment';

function PaymentDashboard() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['payments', 'routes']);
  const pageTitle = t('payment.payments', { ns: 'routes' });

  const arrearageTableRowsPerPage = 4;
  const today = new Date();
  const nowDate = lightFormat(endOfDay(today), dateTimeFormat);
  const sixMonthsAgoDate = lightFormat(subMonths(today, 6), dateTimeFormat);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const arrearageClients = useSelector((state) => state.client.data.arrearageClients);
  const clientPaymentsStatus = useSelector((state) => state.clientPayment.status.general);
  const clientStatus = useSelector((state) => state.client.status.general);
  const latestClientPayments = useSelector((state) => state.clientPayment.data.latestPayments);
  const latestTrainerPayments = useSelector((state) => state.trainerPayment.data.latestPayments);
  const pagination = useSelector((state) => state.client.pagination);
  const paginatorInfo = useSelector((state) => state.client.paginatorInfo);
  const totalIncome = useSelector((state) => state.clientPayment.data.totalIncome);
  const totalIncomeStatus = useSelector((state) => state.clientPayment.status.totalIncome);
  const totalOutcome = useSelector((state) => state.trainerPayment.data.totalOutcome);
  const totalOutcomeStatus = useSelector((state) => state.trainerPayment.status.totalOutcome);
  const trainerPaymentsStatus = useSelector((state) => state.trainerPayment.status.general);

  useEffect(() => {
    dispatch(
      clientPaymentSlice.actions.listLatest({
        fields: clientPaymentListBasicFields,
        pagination: { first: 20, page: 1 }
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      trainerPaymentSlice.actions.listLatest({
        fields: trainerPaymentListBasicFields,
        pagination: { first: 20, page: 1 }
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      clientPaymentSlice.actions.totalIncomeByMonth({
        createdAt: { start_date: sixMonthsAgoDate, end_date: nowDate }
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      trainerPaymentSlice.actions.totalOutcomeByMonth({
        createdAt: { start_date: sixMonthsAgoDate, end_date: nowDate }
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      clientSlice.actions.listArrearage({
        fields: clientListArrearageFields,
        filters: {
          membership_status: [membershipStatus.inactive, membershipStatus.inArrears],
          role: roles.client
        },
        pagination: { first: arrearageTableRowsPerPage, page: pagination.page }
      })
    );
  }, [pagination]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onClientChangePage = (page) => {
    dispatch(clientSlice.actions.updatePagination({ page: page + 1 }));
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={6}>
          <DashboardBlock
            showLogo
            title={t('incomeFromTheLastNMonths', { num: 6, ns: 'payments' })}>
            <LoadingBlock isValid={Object.keys(totalIncome).length > 0} status={totalIncomeStatus}>
              <TotalIncomeByMonthChart data={totalIncome} />
            </LoadingBlock>
          </DashboardBlock>
        </Grid>
        <Grid xs={12} sm={6}>
          <DashboardBlock
            showLogo
            title={t('outcomeFromTheLastNMonths', { num: 6, ns: 'payments' })}>
            <LoadingBlock
              emptyBlockProps={{
                imageType: 'man'
              }}
              isValid={totalOutcome.length > 0}
              status={totalOutcomeStatus}>
              <TotalOutcomeByMonthChart data={totalOutcome} />
            </LoadingBlock>
          </DashboardBlock>
        </Grid>
        <Grid xs={12} sm={6}>
          <DashboardBlock title={t('lastNPaymentsReceived', { num: 20, ns: 'payments' })}>
            <LoadingBlock
              emptyBlockProps={{ imageType: 'man' }}
              isValid={latestClientPayments.length > 0}
              skeletonProps={{
                rows: 9
              }}
              status={clientPaymentsStatus}
              type="table">
              <PaymentsTable payments={latestClientPayments} role={roles.client} rowsPerPage={9} />
            </LoadingBlock>
          </DashboardBlock>
        </Grid>
        <Grid xs={12} sm={6}>
          <Stack spacing={4}>
            <DashboardBlock title={t('lastNOtrixTeamPayments', { num: 20, ns: 'payments' })}>
              <LoadingBlock
                isValid={latestTrainerPayments.length > 0}
                skeletonProps={{
                  rows: 5
                }}
                status={trainerPaymentsStatus}
                type="table">
                <PaymentsTable
                  payments={latestTrainerPayments}
                  role={roles.trainer}
                  rowsPerPage={5}
                />
              </LoadingBlock>
            </DashboardBlock>
            <DashboardBlock title={t('inactiveAndInArrearsClients', { ns: 'payments' })}>
              <LoadingBlock
                emptyBlockProps={{ imageType: 'man' }}
                isValid={arrearageClients.length > 0}
                skeletonProps={{
                  rows: arrearageTableRowsPerPage
                }}
                status={clientStatus}
                type="table">
                <ArrearageClientsTable
                  clients={arrearageClients}
                  onChangePage={onClientChangePage}
                  pagination={pagination}
                  paginatorInfo={paginatorInfo}
                  rowsPerPage={arrearageTableRowsPerPage}
                />
              </LoadingBlock>
            </DashboardBlock>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default PaymentDashboard;
