import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardBlock from 'components/shared/blocks/DashboardBlock';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import LargeSessionCard from 'components/shared/cards/session/LargeSessionCard';
import PaginatedList from 'components/shared/lists/PaginatedList';

function NextSessionsList({ emptyMessage, itemsPerPage }) {
  const { t } = useTranslation(['sessions']);

  const nextSessions = useSelector((state) => state.session.data.next);
  const role = useSelector((state) => state.auth.user.role);
  const sessionStatus = useSelector((state) => state.session.status.dashboard);

  const isValid = nextSessions.length > 0;

  return (
    <DashboardBlock
      title={t('nextTrainingSessions', { ns: 'sessions' })}
      titleNote={t('nextNWeeks', { num: 2, ns: 'common' })}>
      <LoadingBlock
        emptyBlockProps={{
          imageType: 'man',
          message: emptyMessage
        }}
        isValid={isValid}
        skeletonProps={{
          height: 100,
          rows: itemsPerPage
        }}
        status={sessionStatus}
        type="table">
        <PaginatedList
          items={nextSessions}
          renderItem={(item) => <LargeSessionCard data={item} userRole={role} key={item.id} />}
        />
      </LoadingBlock>
    </DashboardBlock>
  );
}

NextSessionsList.defaultProps = {
  itemsPerPage: 5
};

NextSessionsList.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number
};

export default NextSessionsList;
