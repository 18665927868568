export const mealPlanListFields = `
  id
  created_at
  pdf_document
  user_id {
    first_name
    full_name
    last_name
    photo
  }
`;

export const mealPlanPdfFields = `
  pdf_document
`;
