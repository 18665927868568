import { gql } from '@apollo/client';
import client from 'apollo/client';
import {
  clientPerformanceListFields,
  performanceFindFields,
  performanceHistoryFields,
  performanceTopRankedFields
} from 'common/constants/query-fields/performance';
import {
  getPerformanceFilters,
  getPerformanceHistoryFilters,
  getTopRankedPerformanceFilters
} from 'common/filters/requestFilters';

/**
 * Create performance method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createPerformance($input: CreatePerformanceInput!) {
        createPerformance(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Find performance method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query performance($id: ID!) {
        performance(id: $id) {
          ${performanceFindFields}
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * Get latest performance method
 * @param {Integer} userId
 * @returns {Promise}
 */
const getLatest = (userId) =>
  client.query({
    query: gql`
      query latestPerformance($user_id: Int!) {
        latestPerformance(user_id: $user_id) {
          ${clientPerformanceListFields}
        }
      }
    `,
    variables: { user_id: userId },
    fetchPolicy: 'network-only'
  });

/**
 * Get history method
 * @param {Integer} userId
 * @returns {Promise}
 */
const history = (payload) => {
  const { filters } = payload;
  const performanceFilters = getPerformanceHistoryFilters(filters);

  return client.query({
    query: gql`
      query performanceHistory {
        performanceHistory(
          ${performanceFilters.created_between}
          ${performanceFilters.user_id}
        ) {
          ${performanceHistoryFields}
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * List performance method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { fields, filters, pagination } = payload;
  const performanceFilters = getPerformanceFilters(filters);

  return client.query({
    query: gql`
      query performances {
        performances(
          first: ${pagination.first}
          page: ${pagination.page}
          ${performanceFilters.user_id}
          ${performanceFilters.incomplete}
          ${performanceFilters.soft_delete}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${fields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Remove performance method
 * @param  {Array} ids
 * @return {Promise}
 */
const remove = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deletePerformances($id: [ID!]!) {
        deletePerformances(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Restore performance method
 * @param  {Array} ids
 * @return {Promise}
 */
const restore = (ids) =>
  client.mutate({
    mutation: gql`
      mutation restorePerformances($id: [ID!]!) {
        restorePerformances(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Top ranked performance method
 *
 * @param {object} filters
 * @returns {Promise}
 */
const topRanked = (filters) => {
  const performanceFilters = getTopRankedPerformanceFilters(filters);

  return client.query({
    query: gql`
      query topRankedPerformances {
        topRankedPerformances(
          ${performanceFilters.created_at}
          ${performanceFilters.performance_test}
        ) {
          ${performanceTopRankedFields}
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Update performance method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updatePerformance($id: ID!, $input: UpdatePerformanceInput!) {
        updatePerformance(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  create,
  find,
  getLatest,
  history,
  list,
  remove,
  restore,
  topRanked,
  update
};
