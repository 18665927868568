import { Select, styled } from '@mui/material';
import { checkboxClasses } from '@mui/material/Checkbox';
import { iconButtonClasses } from '@mui/material/IconButton';
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { typographyClasses } from '@mui/material/Typography';

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  [`&.${inputLabelClasses.root}`]: {
    color: '#E3E7EB',
    [`&.${inputLabelClasses.focused}`]: {
      color: theme.palette.common.white
    },
    [`&.${inputLabelClasses.error}`]: {
      color: '#F6B1B1'
    },
    [`&.${inputLabelClasses.disabled}`]: {
      color: theme.palette.action.disabled,
      '.MuiInputLabel-asterisk': {
        color: theme.palette.action.disabled
      }
    }
  },
  [`&.${inputLabelClasses.asterisk}`]: {
    color: '#E3E7EB',
    '&.Mui-error': {
      color: '#F6B1B1'
    }
  }
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  [`&.${outlinedInputClasses.root}`]: {
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      height: '47px'
    },
    '& fieldset': {
      border: '1px solid #E3E7EB'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.main
    },
    [`&.${outlinedInputClasses.focused}`]: {
      '& fieldset': {
        borderColor: theme.palette.secondary.main
      },
      [`& .${iconButtonClasses.root}`]: {
        color: theme.palette.common.white
      }
    },
    [`&.${outlinedInputClasses.disabled}`]: {
      '& fieldset': {
        borderColor: theme.palette.action.disabled
      },
      [`& .${iconButtonClasses.root}`]: {
        color: theme.palette.action.disabled
      }
    }
  },
  [`&.${outlinedInputClasses.input}`]: {
    color: theme.palette.common.white
  },
  [`& .${iconButtonClasses.root}`]: {
    color: '#E3E7EB'
  }
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.gray2.main,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.gray2.main
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.gray2.main
    }
  }
}));

export const StyledMultipleMenuItem = styled(MenuItem)(({ theme }) => ({
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.gray2.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.gray2.main
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.gray2.main
    },
    [`& .${typographyClasses.root}`]: {
      fontWeight: 600
    },
    [`& .${checkboxClasses.root}.${checkboxClasses.checked}`]: {
      color: theme.palette.gray2.main
    }
  }
}));
