import { Box, styled } from '@mui/material';

const StyledNewsImageContainer = styled(Box)(({ theme }) => ({
  marginTop: '15px',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    height: 300,
    marginTop: '25px'
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '40px'
  }
}));

export default StyledNewsImageContainer;
