import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import BeamContext from 'context/BeamContext';

function BeamContextProvider({ children }) {
  const { REACT_APP_PUSHER_BEAM_INSTANCE_ID, REACT_APP_PUSHER_BEAM_TOKEN_PROVIDER_URL } =
    process.env;

  const [beamsClient, setBeamsClient] = useState(null);
  const accessToken = useSelector((state) => state.auth.accessToken);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          setBeamsClient(
            new PusherPushNotifications.Client({
              instanceId: REACT_APP_PUSHER_BEAM_INSTANCE_ID,
              serviceWorkerRegistration: registration
            })
          );
        })
        .catch((err) => {
          console.log('Service Worker registration failed:', err);
        });
    }
  }, [REACT_APP_PUSHER_BEAM_INSTANCE_ID]);

  const beamsTokenProvider = useMemo(
    () =>
      new PusherPushNotifications.TokenProvider({
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        url: REACT_APP_PUSHER_BEAM_TOKEN_PROVIDER_URL
      }),
    [accessToken, REACT_APP_PUSHER_BEAM_TOKEN_PROVIDER_URL]
  );

  const beamsContextValue = useMemo(
    () => ({
      beamsClient,
      beamsTokenProvider
    }),
    [beamsClient, beamsTokenProvider]
  );

  return <BeamContext.Provider value={beamsContextValue}>{children}</BeamContext.Provider>;
}

BeamContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BeamContextProvider;
