import { ListItemIcon, styled } from '@mui/material';

const StyledMainMenuNestedListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.menuItem.main,
  marginRight: '20px',
  minWidth: 'auto',
  transition: 'color .3s ease-in-out',
  '& svg': {
    width: '10px'
  }
}));

export default StyledMainMenuNestedListItemIcon;
