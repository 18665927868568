import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { lightFormat, parseISO } from 'date-fns';
import { monthYearFormat } from 'common/constants/date';
import StyledProgressGalleryChip from 'common/theme/galleries/progress/StyledProgressGalleryChip';
import StyledProgressGalleryGridItem from 'common/theme/galleries/progress/StyledProgressGalleryGridItem';
import StyledProgressGalleryThumbnail from 'common/theme/galleries/progress/StyledProgressGalleryThumbnail';
import StyledProgressGalleryThumbnailImage from 'common/theme/galleries/progress/StyledProgressGalleryThumbnailImage';

function ProgressGalleryLightboxItem({ onImageClick, progress }) {
  const { t } = useTranslation(['labels']);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const date = lightFormat(parseISO(progress.created_at), monthYearFormat);

  const handleImageClick = (event) => {
    const { imageIndex } = event.target.dataset;
    onImageClick({ imageIndex, currentProgress: progress });
  };

  return (
    <StyledProgressGalleryGridItem>
      <StyledProgressGalleryThumbnail>
        <StyledProgressGalleryThumbnailImage
          src={progress.profile_picture}
          alt={t('profile', { ns: 'labels' })}
          loading="lazy"
          data-image-index={0}
          onClick={handleImageClick}
        />
        <StyledProgressGalleryChip color="secondary" label={date} />
      </StyledProgressGalleryThumbnail>
      <StyledProgressGalleryThumbnail>
        <StyledProgressGalleryThumbnailImage
          src={progress.front_picture}
          alt={t('back', { ns: 'labels' })}
          loading="lazy"
          data-image-index={1}
          onClick={handleImageClick}
        />
        <StyledProgressGalleryChip color="secondary" label={date} />
      </StyledProgressGalleryThumbnail>
      {isSmall && (
        <StyledProgressGalleryThumbnail>
          <StyledProgressGalleryThumbnailImage
            src={progress.back_picture}
            alt={t('back', { ns: 'labels' })}
            loading="lazy"
            data-image-index={2}
            onClick={handleImageClick}
          />
          <StyledProgressGalleryChip color="secondary" label={date} />
        </StyledProgressGalleryThumbnail>
      )}
    </StyledProgressGalleryGridItem>
  );
}

ProgressGalleryLightboxItem.propTypes = {
  onImageClick: PropTypes.func.isRequired,
  progress: PropTypes.shape({
    back_picture: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    front_picture: PropTypes.string.isRequired,
    profile_picture: PropTypes.string.isRequired
  }).isRequired
};

export default ProgressGalleryLightboxItem;
