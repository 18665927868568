/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import StyledDrawerMenuCollapseButton from 'common/theme/drawer-menu/StyledDrawerMenuCollapseButton';
import { isAdmin } from 'common/utils/user';

function DrawerCollapseButton({ onClick }) {
  const { t } = useTranslation(['buttons']);

  const miniDrawer = useSelector((state) => state.settings.config.miniDrawer);
  const role = useSelector((state) => state.auth.user.role);

  const isAdminUser = isAdmin(role);

  return (
    !isAdminUser && (
      <Tooltip
        title={miniDrawer ? t('expand', { ns: 'buttons' }) : t('collapse', { ns: 'buttons' })}>
        <StyledDrawerMenuCollapseButton
          aria-label={
            miniDrawer ? t('expand', { ns: 'buttons' }) : t('collapse', { ns: 'buttons' })
          }
          onClick={onClick}>
          {miniDrawer ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </StyledDrawerMenuCollapseButton>
      </Tooltip>
    )
  );
}

DrawerCollapseButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default DrawerCollapseButton;
