/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import EchoContext from 'context/EchoContext';

function EchoContextProvider({ children }) {
  const {
    REACT_APP_GRAPHQL_SERVER_BASE_URL,
    REACT_APP_PUSHER_APP_CLUSTER,
    REACT_APP_PUSHER_APP_KEY
  } = process.env;

  const accessToken = useSelector((state) => state.auth.accessToken);

  const echoOptions = useMemo(
    () => ({
      broadcaster: 'pusher',
      cluster: REACT_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
      forceTLS: true,
      key: REACT_APP_PUSHER_APP_KEY,
      authEndpoint: `${REACT_APP_GRAPHQL_SERVER_BASE_URL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json'
        }
      }
    }),
    [
      accessToken,
      REACT_APP_GRAPHQL_SERVER_BASE_URL,
      REACT_APP_PUSHER_APP_CLUSTER,
      REACT_APP_PUSHER_APP_KEY
    ]
  );

  const echo = useMemo(() => new Echo(echoOptions), [echoOptions]);
  const echoContextValue = useMemo(() => ({ echo }), [echo]);

  return <EchoContext.Provider value={echoContextValue}>{children}</EchoContext.Provider>;
}

EchoContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default EchoContextProvider;
