import { Box, styled } from '@mui/material';

const StyledAppbarMainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    borderBottom: 0,
    padding: 0,
    width: 'auto'
  }
}));

export default StyledAppbarMainContainer;
