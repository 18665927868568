import React from 'react';
import { Box, styled } from '@mui/material';

const StyledPerformanceHistoryChartHeader = styled(({ showTitle, ...otherProps }) => (
  <Box {...otherProps} />
))(({ showTitle, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  rowGap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    columnGap: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: showTitle ? 'space-between' : 'flex-end',
    marginBottom: theme.spacing(4)
  }
}));

export default StyledPerformanceHistoryChartHeader;
