import { Typography, styled } from '@mui/material';

const StyledDrawerMenuMembershipDate = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: 0
  }
}));

export default StyledDrawerMenuMembershipDate;
