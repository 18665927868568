import React from 'react';
import { Skeleton } from '@mui/material';
import {
  StyledNewsDateSkeleton,
  StyledNewsImageSkeleton,
  StyledNewsTitleSkeleton
} from 'common/theme/skeleton';

function NewsSkeleton() {
  return (
    <>
      <StyledNewsTitleSkeleton variant="text" width="100%" />
      <StyledNewsDateSkeleton variant="text" width="50%" />
      <StyledNewsImageSkeleton variant="rounded" width="100%" height={300} />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="100%" />
    </>
  );
}

export default NewsSkeleton;
