import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Box, Divider, InputAdornment, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import DropzoneFieldAdapter from 'components/shared/field-adapters/DropzoneFieldAdapter';
import TextAreaFieldAdapter from 'components/shared/field-adapters/TextAreaFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import requestStatus from 'common/constants/status';
import { isRequiredField } from 'common/utils/general';
import useForms from 'hooks/useForms';

function ProgressEditFields({
  clientOptions,
  dirtyFields,
  initialValues,
  onClientIdChange,
  requiredFields,
  searchStatus
}) {
  const { t } = useTranslation(['labels']);
  const { clearClientSearchResults } = useForms;

  return (
    <>
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="user_id"
            name="user_id"
            component={TypeaheadFieldAdapter}
            fullWidth
            loading={searchStatus === requestStatus.loading}
            isMultiple={false}
            onClose={clearClientSearchResults}
            onInputChange={onClientIdChange}
            options={clientOptions}
            renderInputProps={{
              label: t('client', { count: 1, ns: 'labels' }),
              required: isRequiredField('user_id', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="start_date"
            name="start_date"
            component={DatePickerFieldAdapter}
            label={t('startDate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('start_date', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="end_date"
            name="end_date"
            component={DatePickerFieldAdapter}
            label={t('endDate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('end_date', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="created_at"
            name="created_at"
            component={DatePickerFieldAdapter}
            label={t('createdAt', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('created_at', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="height"
            name="height"
            component={TextFieldAdapter}
            label={t('height', { ns: 'labels' })}
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">mts</InputAdornment>
            }}
            required={isRequiredField('height', requiredFields)}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="weight"
            name="weight"
            component={TextFieldAdapter}
            label={t('weight', { ns: 'labels' })}
            placeholder="0.00"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>
            }}
            required={isRequiredField('weight', requiredFields)}
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('photos', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={4}>
          <Field
            id="profile_picture"
            name="profile_picture"
            component={DropzoneFieldAdapter}
            label={t('profile', { ns: 'labels' })}
            dropzoneOptions={{
              acceptedFiles: ['.png', '.jpg', '.jpeg'],
              dropzoneText: t('dragOrClick', { ns: 'common' }),
              filesLimit: 1
            }}
          />
          {!('profile_picture' in dirtyFields) && (
            <Box sx={{ marginTop: (theme) => theme.spacing(2), textAlign: 'center' }}>
              <Typography sx={{ marginBottom: (theme) => theme.spacing(1) }}>
                {t('currentPhoto', { ns: 'common' })}
              </Typography>
              <img
                style={{ borderRadius: '5px', maxWidth: '100px' }}
                src={initialValues.profile_picture}
                alt={t('profile', { ns: 'labels' })}
              />
            </Box>
          )}
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="front_picture"
            name="front_picture"
            component={DropzoneFieldAdapter}
            label={t('front', { ns: 'labels' })}
            dropzoneOptions={{
              acceptedFiles: ['.png', '.jpg', '.jpeg'],
              dropzoneText: t('dragOrClick', { ns: 'common' }),
              filesLimit: 1
            }}
          />
          {!('front_picture' in dirtyFields) && (
            <Box sx={{ marginTop: (theme) => theme.spacing(2), textAlign: 'center' }}>
              <Typography sx={{ marginBottom: (theme) => theme.spacing(1) }}>
                {t('currentPhoto', { ns: 'common' })}
              </Typography>
              <img
                style={{ borderRadius: '5px', maxWidth: '100px' }}
                src={initialValues.front_picture}
                alt={t('front', { ns: 'labels' })}
              />
            </Box>
          )}
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="back_picture"
            name="back_picture"
            component={DropzoneFieldAdapter}
            label={t('back', { ns: 'labels' })}
            dropzoneOptions={{
              acceptedFiles: ['.png', '.jpg', '.jpeg'],
              dropzoneText: t('dragOrClick', { ns: 'common' }),
              filesLimit: 1
            }}
          />
          {!('back_picture' in dirtyFields) && (
            <Box sx={{ marginTop: (theme) => theme.spacing(2), textAlign: 'center' }}>
              <Typography sx={{ marginBottom: (theme) => theme.spacing(1) }}>
                {t('currentPhoto', { ns: 'common' })}
              </Typography>
              <img
                style={{ borderRadius: '5px', maxWidth: '100px' }}
                src={initialValues.back_picture}
                alt={t('back', { ns: 'labels' })}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('observations', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container>
        <Grid xs={12}>
          <Field
            id="observations"
            name="observations"
            component={TextAreaFieldAdapter}
            label={t('observations', { ns: 'labels' })}
            helperText={t('text', { ns: 'validations' })}
            multiline
            minRows={5}
            type="text"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

ProgressEditFields.propTypes = {
  clientOptions: PropTypes.array.isRequired,
  dirtyFields: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClientIdChange: PropTypes.func.isRequired,
  requiredFields: PropTypes.array.isRequired,
  searchStatus: PropTypes.string.isRequired
};

export default ProgressEditFields;
