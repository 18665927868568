import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import StyledTextField from 'common/theme/fields/StyledTextfield';
import { convertToDate, resetTimeInDate } from 'common/utils/date';

function TimeFieldAdapter({ dateFormat, input, renderInputProps, ...rest }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  useEffect(() => {
    if (input.value && input.value instanceof Date) {
      const date = resetTimeInDate(input.value);
      input.onChange(date);
    }
  }, []);

  const onChange = (value) => {
    input.onChange(value);
  };

  return (
    <TimeField
      {...input}
      {...rest}
      format={dateFormat}
      value={convertToDate(input.value)}
      onChange={onChange}
      slots={{
        textField: StyledTextField
      }}
      slotProps={{
        textField: { ...renderInputProps, size }
      }}
    />
  );
}

TimeFieldAdapter.defaultProps = {
  dateFormat: 'hh:mm aaa'
};

TimeFieldAdapter.propTypes = {
  dateFormat: PropTypes.string,
  input: PropTypes.object.isRequired,
  renderInputProps: PropTypes.object.isRequired
};

export default TimeFieldAdapter;
