import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StyledChip from 'common/theme/chips/StyledChip';

function AvailableSessionsChip({ availableSessions }) {
  const { t } = useTranslation(['sessions']);

  const getChipColor = () => {
    if (availableSessions === 0) return 'error';
    if (availableSessions < 5) return 'secondary';

    return 'primary';
  };

  const label = `${availableSessions} ${t('availableSessions', { ns: 'sessions' })}`;

  return <StyledChip label={label} color={getChipColor()} />;
}

AvailableSessionsChip.propTypes = {
  availableSessions: PropTypes.number.isRequired
};

export default AvailableSessionsChip;
