import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import requestStatus from 'common/constants/status';

const initialFiltersState = {
  client_id: [],
  pending_update: false,
  session_type_id: [],
  start_between: {
    start_date: null,
    end_date: null
  },
  status: '',
  trainer_id: []
};

const initialState = {
  counters: {
    absentThisMonth: 0,
    createdThisMonth: 0,
    finishedThisMonth: 0,
    finishedToday: 0,
    pendingToUpdate: 0,
    toBeScheduled: 0,
    todayAftermoon: 0,
    todayMorning: 0,
    tomorrow: 0
  },
  data: {
    currentSession: null,
    events: [],
    finished: [],
    list: [],
    next: []
  },
  filters: initialFiltersState,
  pagination: {
    first: 20,
    page: 1
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0
  },
  refresh: false,
  sessionsInRange: {
    exists: false,
    startDate: null,
    endDate: null
  },
  status: {
    dashboard: requestStatus.idle,
    edit: requestStatus.idle,
    event: requestStatus.idle,
    general: requestStatus.idle
  }
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    counters(state) {
      state.status.general = requestStatus.loading;
    },
    countersSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.counters = action.payload;
    },
    countersError(state) {
      state.status.general = requestStatus.error;
    },
    create(state) {
      state.status.general = requestStatus.loading;
    },
    createSuccess(state) {
      state.status.general = requestStatus.success;
      state.refresh = true;
    },
    createError(state) {
      state.status.general = requestStatus.error;
    },
    dashboard(state) {
      state.status.dashboard = requestStatus.loading;
    },
    dashboardSuccess(state, action) {
      state.status.dashboard = requestStatus.success;
      state.counters = action.payload.counters;
      state.data.finished = action.payload.finishedSessions;
      state.data.next = action.payload.nextSessions;
    },
    dashboardError(state) {
      state.status.dashboard = requestStatus.error;
    },
    find(state) {
      state.status.general = requestStatus.loading;
    },
    findSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.currentSession = action.payload;
    },
    findError(state) {
      state.status.general = requestStatus.error;
    },
    forceDelete(state) {
      state.status.general = requestStatus.loading;
    },
    forceDeleteSuccess(state) {
      state.status.general = requestStatus.success;
      state.refresh = true;
    },
    forceDeleteError(state) {
      state.status.general = requestStatus.error;
    },
    getSessionsInRange(state) {
      state.status.general = requestStatus.loading;
    },
    getSessionsInRangeSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.sessionsInRange = action.payload;
    },
    getSessionsInRangeError(state) {
      state.status.general = requestStatus.error;
    },
    list(state) {
      state.status.general = requestStatus.loading;
      if (state.refresh) state.refresh = false;
    },
    listSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.list = action.payload;
    },
    listError(state) {
      state.status.general = requestStatus.error;
    },
    listAll(state) {
      state.status.general = requestStatus.loading;
    },
    listAllSuccess(state, action) {
      state.status.general = requestStatus.success;
      state.data.list = action.payload;
    },
    listAllError(state) {
      state.status.general = requestStatus.error;
    },
    listEvent(state) {
      state.status.event = requestStatus.loading;
      if (state.refresh) state.refresh = false;
    },
    listEventSuccess(state, action) {
      state.status.event = requestStatus.success;
      state.data.events = action.payload;
    },
    listEventError(state) {
      state.status.event = requestStatus.error;
    },
    refresh(state) {
      state.refresh = true;
    },
    resetFilters(state) {
      state.filters = initialFiltersState;
    },
    update(state) {
      state.status.edit = requestStatus.loading;
    },
    updateSuccess(state) {
      state.status.edit = requestStatus.success;
      state.refresh = true;
    },
    updateError(state) {
      state.status.edit = requestStatus.error;
    },
    updateFilters(state, action) {
      state.filters = action.payload;
    },
    updatePagination(state, action) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    updatePaginatorInfo(state, action) {
      state.paginatorInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default sessionSlice;
