import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { InputAdornment, Stack } from '@mui/material';
import { Email } from '@mui/icons-material';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import PasswordFieldAdapter from 'components/shared/field-adapters/PasswordFieldAdapter';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import { homePathByRole } from 'common/constants/general';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import StyledNavigationLink from 'common/theme/links/StyledNavigationLink';
import StyledLoginContainer from 'common/theme/login/StyledLoginContainer';
import StyledLoginLogoContainer from 'common/theme/login/StyledLoginLogoContainer';
import validateLoginForm from 'common/validations/form/login';
import authSlice from 'store/modules/auth';

import { ReactComponent as Logo } from 'images/logotype.svg';

const loginInitialValues = {
  username: '',
  password: ''
};

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['buttons', 'labels', 'routes']);
  const pageTitle = t('login', { ns: 'routes' });

  const passwordReset = useSelector((state) => state.auth.passwordReset);
  const status = useSelector((state) => state.auth.status);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user) {
      const loginPath = homePathByRole[user?.role] || homePathByRole.default;
      navigate(loginPath);
    }
  }, [navigate, user]);

  useEffect(() => {
    if (passwordReset) {
      dispatch(authSlice.actions.resetPasswordReset());
    }
  }, [passwordReset]);

  const onLogin = (values) => {
    dispatch(authSlice.actions.login(values));
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <StyledLoginContainer elevation={0}>
        <Stack spacing={{ xs: 4, lg: 6 }} useFlexGap sx={{ width: '100%' }}>
          <StyledLoginLogoContainer>
            <Logo />
          </StyledLoginLogoContainer>
          <Form
            initialValues={loginInitialValues}
            validate={validateLoginForm}
            onSubmit={onLogin}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Stack spacing={{ xs: 3, lg: 4 }}>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                      <Field
                        id="email"
                        name="username"
                        component={TextFieldAdapter}
                        autoComplete="email"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Email />
                            </InputAdornment>
                          )
                        }}
                        label={t('email', { ns: 'labels' })}
                        type="text"
                        fullWidth
                        required
                      />
                      <Field
                        id="password"
                        name="password"
                        component={PasswordFieldAdapter}
                        label={t('password', { ns: 'labels' })}
                        autoComplete="current-password"
                        fullWidth
                        required
                      />
                    </Stack>
                    <PrimaryLoadingButton
                      disabled={status === requestStatus.loading}
                      loading={status === requestStatus.loading}
                      label={t('auth.login', { ns: 'buttons' })}
                      type="submit"
                    />
                  </Stack>
                  <StyledNavigationLink
                    component={RouterLink}
                    to={routes.forgotPassword.path}
                    variant="subtitle2"
                    underline="always">
                    {t('auth.forgotPassword', { ns: 'buttons' })}
                  </StyledNavigationLink>
                </Stack>
              </form>
            )}
          />
        </Stack>
      </StyledLoginContainer>
    </>
  );
}

export default Login;
