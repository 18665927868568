import { format, parseISO } from 'date-fns';
import { dateFormat } from 'common/constants/date';

const formatDate = (dateString, dateLocale) =>
  format(parseISO(dateString), dateFormat, { locale: dateLocale });

const getProgressData = (data, dateLocale) =>
  data.map((progress) => ({
    id: progress.id,
    created_at: formatDate(progress.created_at, dateLocale),
    end_date: formatDate(progress.end_date, dateLocale),
    start_date: formatDate(progress.start_date, dateLocale),
    user: progress.user_id
  }));

export default getProgressData;
