import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@mui/material';
import { AccountCircle, LockRounded } from '@mui/icons-material';
import StyledProfileTab from 'common/theme/profile/StyledProfileTab';
import { tabsA11yProps } from 'common/utils/general';

function ClientProfileTabs({ currentTab, onTabChange }) {
  const { t } = useTranslation(['labels']);

  return (
    <Tabs
      value={currentTab}
      onChange={onTabChange}
      textColor="inherit"
      indicatorColor="secondary"
      variant="fullWidth"
      centered>
      <StyledProfileTab
        icon={<AccountCircle />}
        label={t('personalInformation', { ns: 'labels' })}
        {...tabsA11yProps(0)}
      />
      <StyledProfileTab
        icon={<LockRounded />}
        label={t('securityInformation', { ns: 'labels' })}
        {...tabsA11yProps(1)}
      />
    </Tabs>
  );
}

ClientProfileTabs.propTypes = {
  currentTab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired
};

export default ClientProfileTabs;
