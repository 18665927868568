import React from 'react';
import { Box, styled } from '@mui/material';

const StyledDrawerMenuUserInfoContainer = styled(({ miniDrawer, ...otherProps }) => (
  <Box {...otherProps} />
))(({ miniDrawer, theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(1),
  flexWrap: 'wrap',
  margin: '40px 0 20px',
  padding: miniDrawer ? '0' : `0 ${theme.spacing(2)}`,
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    margin: '30px 0 20px'
  },
  [theme.breakpoints.up('xl')]: {
    margin: `46px 0 ${theme.spacing(4)}`
  }
}));

export default StyledDrawerMenuUserInfoContainer;
