export const sessionAdminCountersFields = `
  createdThisMonth
  finishedThisMonth
`;

export const sessionDashboardClientFields = `
  counters {
    finishedToday
    toBeScheduled
    today
  }
  finishedSessions {
    id
    trainer_id {
      full_name
    }
    end_date
    session_type_id {
      name
    }
    start_date
    status
  }
  nextSessions {
    id
    end_date
    language
    session_type_id {
      name
    }
    start_date
    status
    trainer_id {
      first_name
      last_name
      full_name
      photo
    }
  }
`;

export const sessionDashboardTrainerFields = `
  counters {
    absentThisMonth
    finishedThisMonth
    pendingToUpdate
    todayAfternoon
    todayMorning
    tomorrow
  }
  finishedSessions {
    id
    client_id {
      full_name
    }
    end_date
    session_type_id {
      name
    }
    start_date
    status
  }
  nextSessions {
    id
    client_id {
      first_name
      last_name
      full_name
      photo
    }
    end_date
    language
    session_type_id {
      name
    }
    start_date
    status
  }
`;

export const sessionFindFields = `
  id
  client_id {
    full_name
    id
  }
  end_date
  language
  observations
  session_type_id {
    id
  }
  start_date
  status
  trainer_id {
    first_name
    full_name
    id
    last_name
    photo
  }
`;

export const sessionListFields = `
  id
  client_id {
    first_name
    last_name
    full_name
    photo
  }
  end_date
  observations
  session_type_id {
    name
  }
  start_date
  status
  stored
  trainer_id {
    first_name
    last_name
    full_name
    photo
  }
`;

export const sessionListAllFields = `
  id
  client_id {
    first_name
    full_name
    last_name
    photo
  }
  end_date
  language
  session_type_id {
    name
  }
  start_date
  status
  trainer_id {
    first_name
    full_name
    last_name
    photo
  }
`;

export const sessionListCalendarEventFields = `
  id
  client_id {
    first_name
    full_name
    last_name
    photo
  }
  end_date
  language
  observations
  session_type_id {
    name
  }
  start_date
  status
  trainer_id {
    first_name
    full_name
    last_name
    online_meeting_url
    photo
  }
`;

export const sessionListIdOnlyFields = 'id';
