import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledPerformanceHistoryChartEmoji from 'common/theme/charts/performance-history/StyledPerformanceHistoryChartEmoji';
import StyledPerformanceHistoryChartTotal from 'common/theme/charts/performance-history/StyledPerformanceHistoryChartTotal';
import { placeToEmoji } from 'common/constants/general';

function PerformanceHistoryBarLabel({ bar }) {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const totalRef = useRef({});
  const emojiRef = useRef({});

  const { data: barData, key, width, x, y } = bar;
  const {
    data: { place, total }
  } = barData;
  const isOnPodium = place <= 3;

  const alignEmojiTextOnBar = useCallback(
    (emojiElement) => {
      const textWidth = emojiElement.getComputedTextLength();
      const centerX = x + width / 2 - textWidth / 2;
      emojiElement.setAttribute('transform', `translate(${centerX}, ${y - (isMedium ? 28 : 25)})`);
    },
    [bar, isMedium]
  );

  /**
   * Aligns the total text element on a bar chart.
   * This function calculates the center position of the total text element based on the bar's dimensions and applies a transform to center the text horizontally.
   * The vertical position is adjusted slightly above the bar based on the screen size (medium or not).
   *
   * @param  {SVGTextElement}  totalElement  The SVG text element representing the total value.
   */
  const alignTotalTextOnBar = useCallback(
    (totalElement) => {
      const textWidth = totalElement.getComputedTextLength();
      const centerX = x + width / 2 - textWidth / 2;
      totalElement.setAttribute('transform', `translate(${centerX}, ${y - 10})`);
    },
    [bar, isMedium]
  );

  useEffect(() => {
    const emojiElement = emojiRef.current[key];
    const totalElement = totalRef.current[key];

    if (emojiElement) {
      alignEmojiTextOnBar(emojiElement);
    }

    if (totalElement) {
      alignTotalTextOnBar(totalElement);
    }
  }, [key, alignEmojiTextOnBar, alignTotalTextOnBar]);

  return (
    <g key={place}>
      {isOnPodium && (
        <StyledPerformanceHistoryChartEmoji
          ref={(el) => {
            emojiRef.current[key] = el;
          }}>
          {placeToEmoji[place].symbol || placeToEmoji.default.symbol}
        </StyledPerformanceHistoryChartEmoji>
      )}
      <StyledPerformanceHistoryChartTotal
        ref={(el) => {
          totalRef.current[key] = el;
        }}>
        {total}
      </StyledPerformanceHistoryChartTotal>
    </g>
  );
}

PerformanceHistoryBarLabel.propTypes = {
  bar: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.shape({
        place: PropTypes.number.isRequired,
        total: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    key: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired
};

export default PerformanceHistoryBarLabel;
