import { Menu, styled } from '@mui/material';
import { menuClasses } from '@mui/material/Menu';

const StyledNotificationMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    backgroundColor: theme.palette.gray3.main,
    backgroundImage: 'none',
    borderRadius: '8px',
    padding: `${theme.spacing(2)} 0 0 0`,
    width: `calc(100% - ${theme.spacing(3)})`,
    [theme.breakpoints.up('sm')]: {
      width: '400px'
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '10px'
    }
  },
  [`& .${menuClasses.list}`]: {
    padding: 0
  }
}));

export default StyledNotificationMenu;
