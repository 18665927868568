import React from 'react';
import { Collapse, styled } from '@mui/material';

const StyledSessionHintCollapse = styled(({ collapse, ...otherProps }) => (
  <Collapse {...otherProps} />
))(({ collapse, theme }) => ({
  marginBottom: collapse ? 0 : theme.spacing(3)
}));

export default StyledSessionHintCollapse;
