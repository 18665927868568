const planRoutes = {
  listPlan: {
    path: '/plans'
  },
  createPlan: {
    path: '/plans/create'
  },
  editPlan: {
    path: '/plans/edit/:id'
  }
};

export default planRoutes;
