import { Box, styled } from '@mui/material';

const StyledDateRangePickerContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gridColumnGap: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    gridColumnGap: theme.spacing(4)
  }
}));

export default StyledDateRangePickerContainer;
