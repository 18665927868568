import { gql } from '@apollo/client';
import client from 'apollo/client';
import { getPaymentFilters, getTrainerPaymentInfoFilters } from 'common/filters/requestFilters';

/**
 * Create trainer payment method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createTrainerPayment($input: CreateTrainerPaymentInput!) {
        createTrainerPayment(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Create trainer payment invoice method
 * @param  {string} id
 * @return {Promise}
 */
const createInvoice = (id) =>
  client.mutate({
    mutation: gql`
    mutation {
      createTrainerInvoice(id: ${id}) {
        created
        id
      }
    }
  `
  });

/**
 * Find trainer payment method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query trainerPayment($id: ID!) {
        trainerPayment(id: $id) {
          id
          created_at
          currency
          end_date
          invoice_url
          observations
          price
          sessions_completed
          start_date
          user_id {
            id
            full_name
          }
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * List trainer payments method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { fields, filters, pagination } = payload;
  const paymentFilters = getPaymentFilters(filters);

  return client.query({
    query: gql`
      query trainerPayments {
        trainerPayments(
          first: ${pagination.first}
          page: ${pagination.page}
          ${paymentFilters.user_id}
          ${paymentFilters.currency}
          ${paymentFilters.soft_delete}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${fields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Remove trainer payments method
 * @param  {Array} ids
 * @return {Promise}
 */
const remove = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deleteTrainerPayments($id: [ID!]!) {
        deleteTrainerPayments(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Restore trainer payments method
 * @param  {Array} ids
 * @return {Promise}
 */
const restore = (ids) =>
  client.mutate({
    mutation: gql`
      mutation restoreTrainerPayments($id: [ID!]!) {
        restoreTrainerPayments(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Send trainer payment invoice method
 * @param  {string} id
 * @return {Promise}
 */
const sendInvoice = (id) =>
  client.mutate({
    mutation: gql`
    mutation {
      sendTrainerInvoice(id: ${id}) {
        sent
        error
      }
    }
  `
  });

/**
 * Get total outcome by month method
 * @param  {Object} payload
 * @return {Promise}
 */
const totalOutcomeByMonth = (payload) =>
  client.query({
    query: gql`
      query totalOutcomeByMonth {
        totalOutcomeByMonth(
          created_at: {
            start_date: "${payload.start_date}"
            end_date: "${payload.end_date}"
          }
        ) {
          date
          total
        }
      }
    `,
    fetchPolicy: 'network-only'
  });

/**
 * Trainer payment info method
 * @param {Object} payload
 * @returns {Promise}
 */
const trainerPaymentInfo = (payload) => {
  const { filters } = payload;
  const paymentInfoFilters = getTrainerPaymentInfoFilters(filters);

  return client.query({
    query: gql`
      query trainerPaymentInfo {
        trainerPaymentInfo(
          ${paymentInfoFilters.trainer_id}
          ${paymentInfoFilters.date_range}
        ) {
          sessionsCompleted
          totalAmount
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Update trainer payment method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateTrainerPayment($id: ID!, $input: UpdateTrainerPaymentInput!) {
        updateTrainerPayment(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  create,
  createInvoice,
  find,
  list,
  remove,
  restore,
  sendInvoice,
  totalOutcomeByMonth,
  trainerPaymentInfo,
  update
};
