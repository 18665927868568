import { lightFormat } from 'date-fns';
import randomize from 'randomatic';
import _omit from 'lodash/omit';
import { isValidDate } from 'common/validations/field';
import { iso8601Format } from 'common/constants/date';

/**
 * Generates a random password and updates the 'password' field in the state.
 *
 * @param {any} args - The arguments for the function.
 * @param {object} state - The current state object.
 * @param {object} tools - The tools object.
 */
export const generatePassword = (args, state, tools) => {
  tools.changeValue(state, 'password', () => randomize('Aa0', 16));
};

/**
 * Returns an array of client options.
 *
 * @param {Array} clients - The array of clients.
 * @returns {Array} - The array of client options.
 */
export const getClientOptionList = (clients) =>
  clients.map((client) => ({
    label: client.full_name,
    value: client.id,
    ..._omit(client, ['full_name', 'id'])
  }));

/**
 * Returns an object containing the fields that have been updated compared to their initial values.
 *
 * @param {object} values - The updated field values.
 * @param {object} initialValues - The initial field values.
 * @returns {object} - An object containing the fields that have been updated.
 */
export const getFieldsToUpdate = (values, initialValues) => {
  const fieldsToUpdate = {};

  Object.keys(initialValues).forEach((key) => {
    if (Object.hasOwn(values, key)) {
      let value = values[key];
      let initialValue = initialValues[key];

      if (isValidDate(values[key])) {
        value = lightFormat(value, iso8601Format);
        initialValue = lightFormat(initialValue, iso8601Format);
      }

      if (value !== initialValue) {
        fieldsToUpdate[key] = values[key];
      }
    } else {
      fieldsToUpdate[key] = null;
    }
  });

  return fieldsToUpdate;
};
