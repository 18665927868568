import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { DialogContent } from '@mui/material';
import SessionCreateDialogActions from 'components/session/dialog/create/SessionCreateDialogActions';
import SessionFieldsListeners from 'components/session/fields/SessionFieldsListeners';
import SessionFields from 'components/session/fields/SessionFields';
import { createSessionInitialValues } from 'common/form/initial-values/create';
import StyledSessionDialogCreateForm from 'common/theme/dialog/create-session/StyledSessionDialogCreateForm';
import defaultSessionFormValidations from 'common/validations/form/defaultSession';
import { formDecorator } from 'common/utils/session';
import { isClient } from 'common/utils/user';
import useCreateSession from 'hooks/session/useCreateSession';

const calculator = formDecorator;

function SessionCreateDialogContent({ onDialogClose }) {
  const formRef = useRef(null);
  const { createSession } = useCreateSession();

  const user = useSelector((state) => state.auth.user);

  const initialValues = useMemo(
    () =>
      isClient(user.role)
        ? { ...createSessionInitialValues(), client_id: { label: user.full_name, value: user.id } }
        : createSessionInitialValues(),
    [user.role]
  );

  const onCreate = (values) => {
    createSession(values, user);
    onDialogClose();
  };

  return (
    <DialogContent>
      <Form
        decorators={[calculator]}
        initialValues={initialValues}
        onSubmit={onCreate}
        validate={defaultSessionFormValidations}
        render={({ handleSubmit, form, invalid, pristine, values }) => {
          formRef.current = form;

          return (
            <StyledSessionDialogCreateForm onSubmit={handleSubmit}>
              <div>
                <SessionFields values={values} />
              </div>
              <SessionCreateDialogActions
                invalid={invalid}
                onDialogClose={onDialogClose}
                pristine={pristine}
              />
              <SessionFieldsListeners form={formRef?.current} />
            </StyledSessionDialogCreateForm>
          );
        }}
      />
    </DialogContent>
  );
}

SessionCreateDialogContent.propTypes = {
  onDialogClose: PropTypes.func.isRequired
};

export default SessionCreateDialogContent;
