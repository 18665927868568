import { put } from 'redux-saga/effects';
import notification from 'api/graphql/notification';
import { getUserFriendlyError } from 'common/utils/general';
import { normalizeDatabaseNotification } from 'common/utils/notification';

/**
 * A higher-order function that creates a saga for handling notification listing.
 *
 * @param {function} successAction - The action to be dispatched upon a successful API call.
 * @param {function} errorAction - The action to be dispatched if the API call fails.
 *
 * @returns {function} A generator function (saga) that makes an API call to list notifications.
 */
const createNotificationList = (successAction, errorAction, updatePaginatorAction) =>
  function* list(action) {
    try {
      const { filters = {}, pagination } = action.payload;
      const response = yield notification.list({ filters, pagination });

      const {
        data: {
          notifications: { data, paginatorInfo }
        }
      } = response;

      const normalizedData = data.map((currentNotification) =>
        normalizeDatabaseNotification(currentNotification)
      );

      yield put(successAction(normalizedData));
      yield put(
        updatePaginatorAction({
          hasMorePages: paginatorInfo.hasMorePages,
          lastPage: paginatorInfo.lastPage,
          total: paginatorInfo.total
        })
      );
    } catch (ex) {
      const message = getUserFriendlyError(ex);
      yield put(errorAction(message));
    }
  };

export default createNotificationList;
