const authRoutes = {
  home: {
    path: '/'
  },
  login: {
    path: '/login'
  },
  forgotPassword: {
    path: '/forgot-password'
  },
  resetPassword: {
    path: '/reset-password'
  }
};

export default authRoutes;
