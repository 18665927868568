import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Delete, Restore } from '@mui/icons-material';
import { trashed } from 'common/constants/options';
import { getSelectedIds } from 'common/table/utils';

function ToolbarSelect({ displayData, filters, onDelete, onRestore, selectedRows }) {
  const { t } = useTranslation(['buttons']);
  const softDelete = filters.soft_delete === trashed.only;

  const onClick = () => {
    const ids = getSelectedIds(selectedRows, displayData);

    if (softDelete && onRestore) {
      onRestore(ids);
    } else {
      onDelete(ids);
    }
  };

  return (
    <Box sx={{ marginRight: '16px' }}>
      {softDelete ? (
        <Tooltip title={t('restore', { ns: 'buttons' })}>
          <IconButton onClick={onClick} aria-label="restore">
            <Restore />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('delete', { ns: 'buttons' })}>
          <IconButton onClick={onClick} aria-label="delete">
            <Delete />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

ToolbarSelect.defaultProps = {
  onRestore: null
};

ToolbarSelect.propTypes = {
  displayData: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRestore: PropTypes.func,
  selectedRows: PropTypes.object.isRequired
};

export default ToolbarSelect;
