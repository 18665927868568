import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import AppBarPrimaryButton from 'components/shared/buttons/AppBarPrimaryButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import SessionCreateDialog from 'components/session/dialog/create/SessionCreateDialog';
import SessionDetailsDialog from 'components/session/dialog/details/SessionDetailsDialog';
import SessionDrawer from 'components/session/calendar/SessionDrawer';
import SessionHint from 'components/session/calendar/SessionHint';
import MonthlySessionEvent from 'components/shared/calendar-events/MonthlySessionEvent';
import WeeklySessionEvent from 'components/shared/calendar-events/WeeklySessionEvent';
import { userAvailableSessionsFields } from 'common/constants/query-fields/user';
import {
  eventTimeFormat,
  headerToolbar,
  slotLabelFormat,
  titleFormat
} from 'common/constants/session';
import requestStatus from 'common/constants/status';
import { getInteractionType } from 'common/utils/general';
import { canClientCreateSessions } from 'common/utils/session';
import { isAdmin, isClient } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import LayoutContext from 'context/LayoutContext';
import useSessionCalendar from 'hooks/session/useSessionCalendar';
import authSlice from 'store/modules/auth';
import clientPaymentSlice from 'store/modules/clientPayment';

function SessionCalendar() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t, i18n } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('session.calendar', { ns: 'routes' });
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const { setCompactLayout, setNoBgColorLayout } = useContext(LayoutContext);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const availableSessions = useSelector((state) => state.auth.user?.available_sessions) ?? 0;
  const eventStatus = useSelector((state) => state.session.status.event);
  const role = useSelector((state) => state.auth.user.role);
  const sessions = useSelector((state) => state.session.data.events);
  const sessionStatus = useSelector((state) => state.session.status.general);
  const userId = Number(useSelector((state) => state.auth.user.id) ?? 0);

  const isAdminUser = isAdmin(role);
  const isClientUser = isClient(role);

  const {
    calendarRef,
    handleDateClick,
    handleDateSet,
    isDialogOpen,
    moreLinkContent,
    openDrawer,
    todaySessions,
    toggleDrawer,
    toggleSessionCreateDialog
  } = useSessionCalendar();

  /**
   * Memoizes the AppBarPrimaryButton component.
   * The button is rendered if the user is an admin or if the client can create sessions.
   * It is disabled and shown as loading during specific event or session status conditions.
   */
  const appBarButton = useMemo(() => {
    const isDisabledOrLoading =
      eventStatus === requestStatus.loading || sessionStatus === requestStatus.loading;

    if (isAdminUser || canClientCreateSessions(role, availableSessions)) {
      return (
        <AppBarPrimaryButton
          disabled={isDisabledOrLoading}
          label={t('session.create', { ns: 'buttons' })}
          loading={isDisabledOrLoading}
          onClick={toggleSessionCreateDialog}
        />
      );
    }

    return null;
  }, [availableSessions, eventStatus, sessionStatus, t, toggleSessionCreateDialog]);

  useEffect(() => {
    setAppBarButton(appBarButton);
    setAppBarTitle(pageTitle);
  }, [appBarButton, pageTitle, setAppBarButton, setAppBarTitle]);

  useEffect(() => {
    setCompactLayout(true);
    setNoBgColorLayout(true);

    return () => {
      setCompactLayout(false);
      setNoBgColorLayout(false);
    };
  }, []);

  useEffect(() => {
    if (isClientUser) {
      dispatch(authSlice.actions.me(userAvailableSessionsFields));
      dispatch(clientPaymentSlice.actions.getLatest(userId));
    }
  }, [dispatch, isClientUser, userId]);

  const handleCloseDetailsDialog = useCallback(() => {
    setSelectedEvent(null);
  }, []);

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
  };

  const renderMonthEventContent = (info) => (
    <MonthlySessionEvent event={info.event} viewType={role} />
  );

  const renderWeekEventContent = (info) => (
    <WeeklySessionEvent event={info.event} viewType={role} />
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <SessionHint
        cookieName="sessionCalendarHint"
        message={t('calendarHint', { action: getInteractionType(), ns: 'sessions' })}
      />
      <div className={`fc-session-calendar fc-${role.toLowerCase()}-view`}>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          datesSet={handleDateSet}
          events={sessions}
          eventClick={handleEventClick}
          eventTimeFormat={eventTimeFormat}
          eventOverlap={isAdmin(role)}
          expandRows
          allDaySlot={false}
          selectMirror
          height="auto"
          moreLinkClick="week"
          moreLinkContent={moreLinkContent}
          slotDuration="01:00:00"
          selectable={false}
          slotLabelFormat={slotLabelFormat}
          stickyHeaderDates={false}
          headerToolbar={headerToolbar}
          titleFormat={titleFormat}
          buttonText={{
            today: t('today', { ns: 'common' }),
            month: t('month', { ns: 'common' }),
            week: t('week', { ns: 'common' }),
            day: t('day', { count: 1, ns: 'common' }),
            list: t('list', { ns: 'common' })
          }}
          locale={i18n.language}
          dateClick={handleDateClick}
          selectLongPressDelay={1}
          timeZone="local"
          views={{
            dayGridMonth: {
              dayMaxEvents: isExtraSmall ? 1 : 2,
              eventContent: renderMonthEventContent
            },
            timeGridWeek: {
              eventContent: renderWeekEventContent,
              eventMaxStack: 2,
              slotMinTime: '04:00:00',
              slotMaxTime: '21:00:00'
            }
          }}
        />
      </div>
      <SessionDrawer open={openDrawer} onClose={toggleDrawer} sessions={todaySessions} />
      <SessionCreateDialog open={isDialogOpen} onClose={toggleSessionCreateDialog} />
      <SessionDetailsDialog
        open={selectedEvent !== null}
        onClose={handleCloseDetailsDialog}
        event={selectedEvent}
        viewType={role}
      />
    </>
  );
}

export default SessionCalendar;
