import { gql } from '@apollo/client';
import client from 'apollo/client';
import { getProgressFilters } from 'common/filters/requestFilters';

/**
 * Create progress method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createProgress($input: CreateProgressInput!) {
        createProgress(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Find progress method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query progress($id: ID!) {
        progress(id: $id) {
          id
          back_picture
          created_at
          end_date
          front_picture
          height
          observations
          profile_picture
          start_date
          user_id {
            id
            age
            country
            full_name
            first_name
            gender
            last_name
            membership_status
            photo
          }
          weight
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * List progress method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { fields, filters, pagination } = payload;
  const progressFilters = getProgressFilters(filters);

  return client.query({
    query: gql`
      query progresses {
        progresses(
          first: ${pagination.first}
          page: ${pagination.page}
          ${progressFilters.user_id}
          ${progressFilters.soft_delete}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${fields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Remove progress method
 * @param  {Array} ids
 * @return {Promise}
 */
const remove = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deleteProgress($id: [ID!]!) {
        deleteProgress(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Restore progress method
 * @param  {Array} ids
 * @return {Promise}
 */
const restore = (ids) =>
  client.mutate({
    mutation: gql`
      mutation restoreProgress($id: [ID!]!) {
        restoreProgress(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Update progress method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateProgress($id: ID!, $input: UpdateProgressInput!) {
        updateProgress(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  create,
  find,
  list,
  remove,
  restore,
  update
};
