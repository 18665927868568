import styledComponent from 'styled-components';
import palette from 'common/theme/palette';

const StyledOverviewProfileDetailValue = styledComponent.div`
  background-color: ${palette.gray1.main};
  border-radius: 4px;
  padding: 8px 16px;
  @media (min-width: 900px) {
    flex-grow: 1;
  }
  @media (min-width: 1200px) {
    border-radius: 5px;
  }
`;

export default StyledOverviewProfileDetailValue;
