import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormControl, useMediaQuery } from '@mui/material';
import { sessionStatus } from 'common/constants/options';
import { StyledMenuItem, StyledSelect } from 'common/theme/fields/StyledSelect';

const finalStatus = [
  sessionStatus.finished,
  sessionStatus.clientAbsent,
  sessionStatus.trainerAbsent
];

const options = (t) => [
  <StyledMenuItem key="scheduled" value={sessionStatus.scheduled} disabled>
    {t('selectAnOption', { ns: 'common' })}
  </StyledMenuItem>,
  <StyledMenuItem key="finished" value={sessionStatus.finished}>
    {t('sessionStatus.finished', { ns: 'options' })}
  </StyledMenuItem>,
  <StyledMenuItem key="client-absent" value={sessionStatus.clientAbsent}>
    {t('sessionStatus.clientAbsent', { ns: 'options' })}
  </StyledMenuItem>,
  <StyledMenuItem key="trainer-absent" value={sessionStatus.trainerAbsent}>
    {t('notAttend', { ns: 'sessions' })}
  </StyledMenuItem>
];

function SessionStatusSelectColumn({ data, onSelectChange }) {
  const { t } = useTranslation(['labels', 'sessions']);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);
  const { id, status: trainerStatus } = data;
  const [status, setStatus] = useState(trainerStatus);
  const isDisabled = finalStatus.includes(trainerStatus);

  const onChange = (event) => {
    onSelectChange(id, event.target.value);
    setStatus(event.target.value);
  };

  return (
    <FormControl size={size} disabled={isDisabled} sx={{ width: 190 }}>
      <StyledSelect
        labelId="session-status-select-label"
        id="session-status-select"
        value={status}
        onChange={onChange}>
        {options(t)}
      </StyledSelect>
    </FormControl>
  );
}

SessionStatusSelectColumn.propTypes = {
  data: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired
};

export default SessionStatusSelectColumn;
