import { put, take, takeLatest } from 'redux-saga/effects';
import clientPaymentSlice from 'store/modules/clientPayment';
import uiSlice from 'store/modules/ui';
import clientPayment from 'api/graphql/clientPayment';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import { createClientPaymentList } from 'store/sagas/hof/payment';
import i18n from 'i18n';

/**
 * Counters sessions saga
 *
 * @param {*} action
 */
function* counters() {
  try {
    const response = yield clientPayment.counters();
    const {
      data: { clientPaymentCounters }
    } = response;

    yield put(clientPaymentSlice.actions.countersSuccess(clientPaymentCounters));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.countersError(message));
  }
}

/**
 * Create client payment saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'client-payment-create-notification-success',
      variant: 'success',
      message: i18n.t('payment.create', { ns: 'notifications' })
    });

    const { payload } = action;
    const {
      data: {
        createClientPayment: { id }
      }
    } = yield clientPayment.create(payload);

    yield put(clientPaymentSlice.actions.createInvoice(id));

    yield take(clientPaymentSlice.actions.createInvoiceSuccess);
    yield put(clientPaymentSlice.actions.sendInvoice(id));

    yield take(clientPaymentSlice.actions.sendInvoiceSuccess);
    yield put(clientPaymentSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.createError(message));
  }
}

/**
 * Create client payment invoice saga
 *
 * @param {*} action
 */
function* createInvoice(action) {
  try {
    const { payload: id } = action;
    yield clientPayment.createInvoice(id);
    yield put(clientPaymentSlice.actions.createInvoiceSuccess());
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.createInvoiceError(message));
  }
}

/**
 * Find client payment saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield clientPayment.find(action.payload);
    const {
      data: { clientPayment: currentPayment }
    } = response;

    yield put(clientPaymentSlice.actions.findSuccess(currentPayment));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.findError(message));
  }
}

/**
 * Get latest client payment saga
 *
 * @param {*} action
 */
function* getLatest(action) {
  try {
    const response = yield clientPayment.getLatest(action.payload);
    const {
      data: { latestClientPayment }
    } = response;

    yield put(clientPaymentSlice.actions.getLatestSuccess(latestClientPayment));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.getLatestError(message));
  }
}

/**
 * Remove client payments saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deleteClientPayments: removedPayments }
    } = yield clientPayment.remove(action.payload);

    const snack = createSnack({
      key: 'remove-client-payments-notification-success',
      variant: 'success',
      message: i18n.t('payment.delete', { count: removedPayments.length, ns: 'notifications' })
    });

    yield put(clientPaymentSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.removeError(message));
  }
}

/**
 * Restore client payments saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restoreClientPayments: restoredPayments }
    } = yield clientPayment.restore(action.payload);

    const snack = createSnack({
      key: 'restore-client-payments-notification-success',
      variant: 'success',
      message: i18n.t('payment.restore', { count: restoredPayments.length, ns: 'notifications' })
    });

    yield put(clientPaymentSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.restoreError(message));
  }
}

/**
 * Send client payment invoice saga
 *
 * @param {*} action
 */
function* sendInvoice(action) {
  try {
    const { payload: id } = action;
    const {
      data: {
        sendClientInvoice: { sent, error }
      }
    } = yield clientPayment.sendInvoice(id);

    if (!sent) {
      yield put(clientPaymentSlice.actions.sendInvoiceError(error));
    } else {
      yield put(clientPaymentSlice.actions.sendInvoiceSuccess());
    }
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.sendInvoiceError(message));
  }
}

/**
 * Get total income by month saga
 *
 * @param {*} action
 */
function* totalIncomeByMonth(action) {
  try {
    const { createdAt } = action.payload;
    const response = yield clientPayment.totalIncomeByMonth(createdAt);

    const {
      data: { totalIncomeByMonth: totalIncomeByMonthData }
    } = response;

    yield put(clientPaymentSlice.actions.totalIncomeByMonthSuccess(totalIncomeByMonthData));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.totalIncomeByMonthError(message));
  }
}

/**
 * Update client payment saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-client-payment-notification-success',
      variant: 'success',
      message: i18n.t('payment.update', { ns: 'notifications' })
    });

    yield clientPayment.update(action.payload);

    yield put(clientPaymentSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(clientPaymentSlice.actions.updateError(message));
  }
}

/**
 * Watch client payment
 *
 * @export
 */
export default function* watchClientPayment() {
  yield takeLatest(clientPaymentSlice.actions.counters, counters);
  yield takeLatest(clientPaymentSlice.actions.create, create);
  yield takeLatest(clientPaymentSlice.actions.createInvoice, createInvoice);
  yield takeLatest(clientPaymentSlice.actions.find, find);
  yield takeLatest(clientPaymentSlice.actions.getLatest, getLatest);
  yield takeLatest(
    clientPaymentSlice.actions.list,
    createClientPaymentList(
      clientPaymentSlice.actions.listSuccess,
      clientPaymentSlice.actions.listError
    )
  );
  yield takeLatest(
    clientPaymentSlice.actions.listLatest,
    createClientPaymentList(
      clientPaymentSlice.actions.listLatestSuccess,
      clientPaymentSlice.actions.listLatestError
    )
  );
  yield takeLatest(clientPaymentSlice.actions.remove, remove);
  yield takeLatest(clientPaymentSlice.actions.restore, restore);
  yield takeLatest(clientPaymentSlice.actions.sendInvoice, sendInvoice);
  yield takeLatest(clientPaymentSlice.actions.totalIncomeByMonth, totalIncomeByMonth);
  yield takeLatest(clientPaymentSlice.actions.update, update);
}
