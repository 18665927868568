import { Box, styled } from '@mui/material';

const StyledNewsBodyContainer = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginTop: '15px',
  '& > *:first-of-type': {
    marginTop: 0
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(16),
    marginTop: '25px'
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '40px'
  }
}));

export default StyledNewsBodyContainer;
