import React, { useMemo } from 'react';
import { Autocomplete, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import StyledTextField from 'common/theme/fields/StyledTextfield';

function AutocompleteFieldAdapter({ input, renderInputProps, ...rest }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onChange = (event, value) => {
    input.onChange(value);
  };

  return (
    <Autocomplete
      {...rest}
      autoHighlight
      getOptionLabel={(option) =>
        option && typeof option.label !== 'undefined' ? option.label : ''
      }
      isOptionEqualToValue={(option, value) => option.value === value.value || value === ''}
      onChange={onChange}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          {...renderInputProps}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
          size={size}
        />
      )}
      value={input.value || null}
    />
  );
}

AutocompleteFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  renderInputProps: PropTypes.object.isRequired
};

export default AutocompleteFieldAdapter;
