import React from 'react';
import { Box, styled } from '@mui/material';

const getGradient = (type, theme) => {
  switch (type) {
    case 'repetition':
      return `linear-gradient(90deg, rgba(27, 35, 191, 0.90) 0%, #273BD7 85.18%, #273BD7 100%);`;
    case 'incomplete':
      return `linear-gradient(90deg, ${theme.palette.error.main} 0%, ${theme.palette.error.dark} 100%)`;
    default:
      return `linear-gradient(180deg, #C08300 33.62%, #F8B231 99.74%);`;
  }
};

const StyledLatestPerformanceProgressBar = styled(({ type, ...otherProps }) => (
  <Box {...otherProps} />
))(({ type, theme }) => ({
  background: getGradient(type, theme),
  borderRadius: '9999px',
  height: '8px',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    height: '10px'
  }
}));

export default StyledLatestPerformanceProgressBar;
