import styledComponent from 'styled-components';

const StyledProfileButtonContainer = styledComponent.div`
  padding: 0;
  @media (min-width: 600px) {
    padding: 0 8px;
  }
  @media (min-width: 900px) {
    padding: 0 24px;
  }
`;

export default StyledProfileButtonContainer;
