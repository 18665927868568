import React from 'react';
import { Box, styled } from '@mui/material';

const StyledLoginLayoutBackground = styled(({ bgImage, ...otherProps }) => <Box {...otherProps} />)(
  ({ bgImage }) => ({
    alignItems: 'center',
    background: `url(${bgImage}) no-repeat center center / cover`,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    width: '100%'
  })
);

export default StyledLoginLayoutBackground;
