import { gql } from '@apollo/client';
import client from 'apollo/client';
import { adminListFields } from 'common/constants/query-fields/admin';
import { trainerListFields } from 'common/constants/query-fields/trainer';
import { userUpdateFields } from 'common/constants/query-fields/user';
import { getUserFilters } from 'common/filters/requestFilters';

/**
 * List admins method
 * @param {Object} payload
 * @returns {Promise}
 */
const admins = (payload) => {
  const { pagination, filters } = payload;
  const adminFilters = getUserFilters(filters);

  return client.query({
    query: gql`
      query users {
        users(
          first: ${pagination.first}
          page: ${pagination.page}
          ${adminFilters.user_id}
          ${adminFilters.gender}
          ${adminFilters.soft_delete}
          ${adminFilters.role}
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${adminListFields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * List available trainers method
 * @param {Object} payload
 * @returns {Promise}
 */
const availableTrainers = (payload) => {
  const { fields, filters } = payload;
  const trainerFilters = getUserFilters(filters);

  return client.query({
    query: gql`
      query availableTrainers {
        availableTrainers(
          ${trainerFilters.date_range}
          ${trainerFilters.ids}
          ${trainerFilters.specialties}
          ${trainerFilters.languages}
        ) {
          ${fields}
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * List clients method
 * @param {Object} payload
 * @returns {Promise}
 */
const clients = (payload) => {
  const { fields, filters, orderBy, pagination } = payload;
  const clientFilters = getUserFilters(filters);
  const orderByDir = orderBy || `{ column: FIRST_NAME, order: ASC }`;

  return client.query({
    query: gql`
      query users {
        users(
          first: ${pagination.first}
          page: ${pagination.page}
          ${clientFilters.user_id}
          ${clientFilters.membership_status}
          ${clientFilters.gender}
          ${clientFilters.plan_id}
          ${clientFilters.soft_delete}
          ${clientFilters.role}
          orderBy: ${orderByDir}
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${fields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Create user method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createUser($input: CreateUserInput!) {
        createUser(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Create temporary user document URL method
 * @param  {string} id
 * @param  {string} documentType
 * @return {Promise}
 */
const createTemporaryUserDocumentUrl = (id, documentType) =>
  client.query({
    query: gql`
      query createTemporaryUserDocumentUrl($id: ID!, $document_type: String!) {
        createTemporaryUserDocumentUrl(id: $id, document_type: $document_type)
      }
    `,
    variables: { id, document_type: documentType },
    fetchPolicy: 'network-only'
  });

/**
 * Find user method
 * @param {Object} payload
 * @return {Promise}
 */
const find = (payload) => {
  const { fields, id } = payload;

  return client.query({
    query: gql`
      query user($id: ID!) {
        user(id: $id) {
          ${fields}
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });
};

/**
 * Get membership status counters method
 * @return {Promise}
 */
const getMembershipStatusCounters = () =>
  client.query({
    query: gql`
      query membershipStatusCounters {
        membershipStatusCounters {
          active
          inactive
          inArrears
        }
      }
    `,
    fetchPolicy: 'network-only'
  });

/**
 * Me method
 * @param {string} fields
 * @return {Promise}
 */
const me = (fields) =>
  client.query({
    query: gql`
      query me {
        me {
          ${fields}
        }
      }
    `,
    fetchPolicy: 'network-only'
  });

/**
 * Remove users method
 * @param  {string} ids
 * @return {Promise}
 */
const remove = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deleteUsers($id: [ID!]!) {
        deleteUsers(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Restore users method
 * @param  {string} ids
 * @return {Promise}
 */
const restore = (ids) =>
  client.mutate({
    mutation: gql`
      mutation restoreUsers($id: [ID!]!) {
        restoreUsers(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Search users method
 * @param {Object} payload
 * @returns {Promise}
 */
const search = (payload) => {
  const { input, fields } = payload;

  return client.query({
    query: gql`
        query searchUser($input: SearchUserInput!) {
          searchUser(input: $input) {
            ${fields}
          }
        }
      `,
    variables: { input },
    fetchPolicy: 'network-only'
  });
};

/**
 * List trainers method
 * @param {Object} payload
 * @returns {Promise}
 */
const trainers = (payload) => {
  const { pagination, filters } = payload;
  const trainerFilters = getUserFilters(filters);

  return client.query({
    query: gql`
      query users {
        users(
          first: ${pagination.first}
          page: ${pagination.page}
          ${trainerFilters.user_id}
          ${trainerFilters.gender}
          ${trainerFilters.languages}
          ${trainerFilters.soft_delete}
          ${trainerFilters.specialties}
          ${trainerFilters.role}
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${trainerListFields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Update user method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateUser($id: ID!, $input: UpdateUserInput!) {
        updateUser(id: $id, input: $input) {
          ${userUpdateFields}
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  admins,
  availableTrainers,
  clients,
  create,
  createTemporaryUserDocumentUrl,
  find,
  getMembershipStatusCounters,
  me,
  remove,
  restore,
  search,
  trainers,
  update
};
