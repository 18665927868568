import { gql } from '@apollo/client';
import client from 'apollo/client';
import {
  clientPaymentCountersFields,
  clientPaymentFindFields,
  totalIncomeByMonthFields
} from 'common/constants/query-fields/clientPayment';
import { getPaymentFilters } from 'common/filters/requestFilters';

/**
 * Counters client payment method
 * @returns {Promise}
 */
const counters = () =>
  client.query({
    query: gql`
      query clientPaymentCounters {
        clientPaymentCounters{
          ${clientPaymentCountersFields}
        }
      }
    `,
    fetchPolicy: 'network-only'
  });

/**
 * Create client payment method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createClientPayment($input: CreateClientPaymentInput!) {
        createClientPayment(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Create client payment invoice method
 * @param  {string} id
 * @return {Promise}
 */
const createInvoice = (id) =>
  client.mutate({
    mutation: gql`
    mutation {
      createClientInvoice(id: ${id}) {
        created
        id
      }
    }
  `
  });

/**
 * Find client payment method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query clientPayment($id: ID!) {
        clientPayment(id: $id) {
          ${clientPaymentFindFields}
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * Get latest client payment method
 * @param  {string} userId
 * @return {Promise}
 */
const getLatest = (userId) =>
  client.query({
    query: gql`
      query latestClientPayment($user_id: Int!) {
        latestClientPayment(user_id: $user_id) {
          id
          created_at
          end_date
          start_date
        }
      }
    `,
    variables: { user_id: userId },
    fetchPolicy: 'network-only'
  });

/**
 * List client payments method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { fields, filters, pagination } = payload;
  const paymentFilters = getPaymentFilters(filters);

  return client.query({
    query: gql`
      query clientPayments {
        clientPayments(
          first: ${pagination.first}
          page: ${pagination.page}
          ${paymentFilters.user_id}
          ${paymentFilters.currency}
          ${paymentFilters.plan_id}
          ${paymentFilters.soft_delete}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${fields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Remove client payments method
 * @param  {Array} ids
 * @return {Promise}
 */
const remove = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deleteClientPayments($id: [ID!]!) {
        deleteClientPayments(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Restore client payments method
 * @param  {Array} ids
 * @return {Promise}
 */
const restore = (ids) =>
  client.mutate({
    mutation: gql`
      mutation restoreClientPayments($id: [ID!]!) {
        restoreClientPayments(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Send client payment invoice method
 * @param  {string} id
 * @return {Promise}
 */
const sendInvoice = (id) =>
  client.mutate({
    mutation: gql`
    mutation {
      sendClientInvoice(id: ${id}) {
        sent
        error
      }
    }
  `
  });

/**
 * Get total income by month method
 * @param  {Object} payload
 * @return {Promise}
 */
const totalIncomeByMonth = (payload) =>
  client.query({
    query: gql`
      query totalIncomeByMonth {
        totalIncomeByMonth(
          created_at: {
            start_date: "${payload.start_date}"
            end_date: "${payload.end_date}"
          }
        ) {
          ${totalIncomeByMonthFields}
        }
      }
    `,
    fetchPolicy: 'network-only'
  });

/**
 * Update client payment method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateClientPayment($id: ID!, $input: UpdateClientPaymentInput!) {
        updateClientPayment(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  counters,
  create,
  createInvoice,
  find,
  getLatest,
  list,
  remove,
  restore,
  sendInvoice,
  totalIncomeByMonth,
  update
};
