import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { ReactComponent as Logo } from 'images/logo.svg';
import StyledDashboardBlockContainer from 'common/theme/blocks/dashboard/StyledDashboardBlockContainer';
import StyledDashboardBlockHeader from 'common/theme/blocks/dashboard/StyledDashboardBlockHeader';
import StyledDashboardBlockTitle from 'common/theme/blocks/dashboard/StyledDashboardBlockTitle';

const logoStyles = {
  height: '100%',
  position: 'absolute',
  right: 0,
  opacity: 0.04,
  top: 0,
  transform: 'translateX(50%)'
};

function DashboardBlock({ children, showLogo, sxStyles, title, titleNote }) {
  return (
    <StyledDashboardBlockContainer showLogo={showLogo} sxStyles={sxStyles}>
      {showLogo && <Logo style={logoStyles} />}
      {(title || titleNote) && (
        <StyledDashboardBlockHeader>
          {title && <StyledDashboardBlockTitle>{title}</StyledDashboardBlockTitle>}
          {titleNote && <Typography variant="subtitle2">{titleNote}</Typography>}
        </StyledDashboardBlockHeader>
      )}
      {children}
    </StyledDashboardBlockContainer>
  );
}

DashboardBlock.defaultProps = {
  showLogo: false,
  sxStyles: {},
  title: null,
  titleNote: ''
};

DashboardBlock.propTypes = {
  children: PropTypes.object.isRequired,
  showLogo: PropTypes.bool,
  sxStyles: PropTypes.object,
  title: PropTypes.string,
  titleNote: PropTypes.string
};

export default DashboardBlock;
