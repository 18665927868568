import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { enUS, es } from 'date-fns/locale';
import MUIDataTable from 'mui-datatables';
import { getAdminSessionColumns, getSessionColumns } from 'common/table/columns/session';
import { getAdminSessionData, getSessionData } from 'common/table/data/session';
import { defaultTableOptions, tableLocalization } from 'common/table/utils';
import { isAdmin } from 'common/utils/user';

function SessionsTable({ role, rowsPerPage, sessions }) {
  const { t, i18n } = useTranslation(['labels', 'routes']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const tableData = isAdmin(role)
    ? getAdminSessionData(sessions, dateLocale)
    : getSessionData(sessions, role, dateLocale);
  const columns = isAdmin
    ? getAdminSessionColumns({ data: tableData, includeActions: false, translateFunc: t })
    : getSessionColumns({ data: tableData, includeActions: false, role, translateFunc: t });

  const options = {
    ...defaultTableOptions,
    jumpToPage: false,
    rowsPerPage,
    selectableRows: 'none',
    serverSide: false,
    textLabels: tableLocalization(t('session.list', { ns: 'routes' }).toLowerCase(), t)
  };

  return <MUIDataTable className="sessions" columns={columns} data={tableData} options={options} />;
}

SessionsTable.defaultProps = {
  rowsPerPage: 5
};

SessionsTable.propTypes = {
  role: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number,
  sessions: PropTypes.array.isRequired
};

export default SessionsTable;
