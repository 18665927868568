import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import { Box, Typography } from '@mui/material';
import SessionCardAdminView from 'components/shared/cards/session/SessionCardAdminView';
import SessionCardDefaultView from 'components/shared/cards/session/SessionCardDefaultView';
import { dateFormat, hourMinuteFormat, timeFormat } from 'common/constants/date';
import { roles } from 'common/constants/options';
import StyledSessionCardChip from 'common/theme/cards/session/basic/StyledSessionCardChip';
import StyledSessionCardContainer from 'common/theme/cards/session/basic/StyledSessionCardContainer';
import StyledSessionCardDate from 'common/theme/cards/session/basic/StyledSessionCardDate';
import StyledSessionCardHiddenLink from 'common/theme/cards/session/basic/StyledSessionCardHiddenLink';
import StyledSessionCardParticipants from 'common/theme/cards/session/basic/StyledSessionCardParticipants';
import { convertUtcToLocalTime } from 'common/utils/date';
import { canClientGoToSession, getTranslatedSessionStatus } from 'common/utils/session';

const getLeftSection = ({ client, trainer, viewType }) => {
  const markup = {
    [roles.administrator]: () => <SessionCardAdminView client={client} trainer={trainer} />,
    [roles.trainer]: () => <SessionCardDefaultView roleToDisplay="client" user={client} />,
    default: () => <SessionCardDefaultView roleToDisplay="trainer" user={trainer} />
  };

  return (markup[viewType] || markup.default)();
};

function SessionCard({ data, options }) {
  const { t, i18n } = useTranslation(['labels']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const { start_date: startDate, end_date: endDate } = data;
  const { viewType } = options;

  const {
    client_id: client,
    session_type_id: { name: sessionType },
    trainer_id: trainer,
    status
  } = data;

  const { online_meeting_url: sessionLink = null } = trainer;
  const statusChip = getTranslatedSessionStatus(status, t);

  return (
    <StyledSessionCardContainer>
      <Box sx={{ flexGrow: 1 }}>
        <StyledSessionCardDate variant="h6">
          {convertUtcToLocalTime(startDate, dateFormat, dateLocale)}
        </StyledSessionCardDate>
        <Typography variant="body2">
          {t('category', { ns: 'labels' })}: {sessionType}
        </Typography>
        <Typography variant="body2">
          {t('time', { ns: 'labels' })}: {convertUtcToLocalTime(startDate, hourMinuteFormat)} -{' '}
          {convertUtcToLocalTime(endDate, timeFormat)}
        </Typography>
        <Typography variant="body2">
          {t('language', { count: 1, ns: 'labels' })}:{' '}
          {t(`language.${data.language.toLowerCase()}`, { ns: 'options' })}
        </Typography>
        <StyledSessionCardChip label={statusChip.label} color={statusChip.color} />
      </Box>
      <StyledSessionCardParticipants viewType={viewType}>
        {getLeftSection({
          client,
          trainer,
          viewType
        })}
      </StyledSessionCardParticipants>
      {sessionLink && canClientGoToSession(endDate, status) && (
        <StyledSessionCardHiddenLink href={sessionLink} target="_blank" rel="noreferrer">
          Session Link
        </StyledSessionCardHiddenLink>
      )}
    </StyledSessionCardContainer>
  );
}

SessionCard.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

export default SessionCard;
