import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { InputAdornment, Stack, Typography } from '@mui/material';
import { Email } from '@mui/icons-material';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import StyledLoginContainer from 'common/theme/login/StyledLoginContainer';
import validateForgotPasswordForm from 'common/validations/form/forgotPassword';
import authSlice from 'store/modules/auth';

const forgotPasswordInitialValues = {
  email: ''
};

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submittedEmail, setSubmittedEmail] = useState(forgotPasswordInitialValues.email);
  const { t } = useTranslation(['auth', 'buttons', 'labels', 'routes']);
  const pageTitle = t('auth.forgotPassword', { ns: 'routes' });
  const urlParams = [['email', submittedEmail]];

  const tokenSent = useSelector((state) => state.auth.tokenSent);
  const status = useSelector((state) => state.auth.status);

  useEffect(() => {
    dispatch(authSlice.actions.resetTokenSent());

    if (tokenSent) {
      navigate({
        pathname: routes.resetPassword.path,
        search: `?${createSearchParams(urlParams)}`
      });
    }
  }, [navigate, tokenSent]);

  const onTokenSend = (values) => {
    const { email } = values;
    setSubmittedEmail(email);
    dispatch(authSlice.actions.generateResetToken(email));
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <StyledLoginContainer elevation={0}>
        <Form
          initialValues={forgotPasswordInitialValues}
          validate={validateForgotPasswordForm}
          onSubmit={onTokenSend}
          render={({ handleSubmit, invalid, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={4} sx={{ width: '100%' }}>
                <Stack spacing={2}>
                  <div>
                    <Typography variant="h5" align="center" component="h1" sx={{ fontWeight: 500 }}>
                      {t('forgotPassword.title', { ns: 'auth' })}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      component="h1"
                      sx={{ fontWeight: 500 }}>
                      {t('forgotPassword.subtitle', { ns: 'auth' })}
                    </Typography>
                  </div>
                  <Typography variant="body2" align="center" component="p">
                    {t('forgotPassword.text', { ns: 'auth' })}
                  </Typography>
                </Stack>
                <Field
                  id="email"
                  name="email"
                  component={TextFieldAdapter}
                  autoComplete="email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    )
                  }}
                  label={t('email', { ns: 'labels' })}
                  type="text"
                  fullWidth
                  required
                />
                <PrimaryLoadingButton
                  disabled={invalid || pristine || status === requestStatus.loading}
                  loading={status === requestStatus.loading}
                  label={t('auth.sendResetCode', { ns: 'buttons' })}
                  type="submit"
                />
              </Stack>
            </form>
          )}
        />
      </StyledLoginContainer>
    </>
  );
}

export default ForgotPassword;
