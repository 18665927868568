import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';
import requestStatus from 'common/constants/status';
import { trashed } from 'common/constants/options';

const initialFiltersState = {
  user_id: [],
  soft_delete: trashed.without
};

const initialState = {
  data: {
    currentProgress: null,
    progress: [],
    progressGallery: []
  },
  filters: initialFiltersState,
  pagination: {
    first: 20,
    page: 1
  },
  paginatorInfo: {
    hasMorePages: false,
    lastPage: 0,
    total: 0
  },
  refresh: false,
  status: requestStatus.idle
};

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    create(state) {
      state.status = requestStatus.loading;
    },
    createSuccess(state) {
      state.status = requestStatus.success;
    },
    createError(state) {
      state.status = requestStatus.error;
    },
    find(state) {
      state.status = requestStatus.loading;
    },
    findSuccess(state, action) {
      state.status = requestStatus.success;
      state.data.currentProgress = action.payload;
    },
    findError(state) {
      state.status = requestStatus.error;
    },
    list(state) {
      state.status = requestStatus.loading;
      if (state.refresh) state.refresh = false;
    },
    listSuccess(state, action) {
      state.status = requestStatus.success;
      state.data.progress = action.payload;
    },
    listError(state) {
      state.status = requestStatus.error;
    },
    listGallery(state) {
      state.status = requestStatus.loading;
    },
    listGallerySuccess(state, action) {
      state.status = requestStatus.success;
      state.data.progressGallery = action.payload;
    },
    listGalleryError(state) {
      state.status = requestStatus.error;
    },
    remove(state) {
      state.status = requestStatus.loading;
    },
    removeSuccess(state) {
      state.status = requestStatus.success;
      state.refresh = true;
    },
    removeError(state) {
      state.status = requestStatus.error;
    },
    restore(state) {
      state.status = requestStatus.loading;
    },
    restoreSuccess(state) {
      state.status = requestStatus.success;
      state.refresh = true;
    },
    restoreError(state) {
      state.status = requestStatus.error;
    },
    resetFilters(state) {
      state.filters = initialFiltersState;
    },
    update(state) {
      state.status = requestStatus.loading;
    },
    updateSuccess(state) {
      state.status = requestStatus.success;
    },
    updateError(state) {
      state.status = requestStatus.error;
    },
    updateFilters(state, action) {
      state.filters = action.payload;
    },
    updatePagination(state, action) {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    updatePaginatorInfo(state, action) {
      state.paginatorInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default progressSlice;
