const clientRoutes = {
  clientDashboard: {
    path: '/dashboard'
  },
  clientMore: {
    path: '/more'
  },
  clientProfile: {
    path: '/profile'
  },
  listClient: {
    path: '/users/clients'
  },
  createClient: {
    path: '/users/clients/create'
  },
  editClient: {
    path: '/users/clients/edit/:id'
  },
  viewClient: {
    path: '/users/clients/:id'
  }
};

export default clientRoutes;
