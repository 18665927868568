import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import ClientPlanSkeleton from 'components/shared/skeletons/ClientPlanSkeleton';
import { dateFormat } from 'common/constants/date';
import StyledOverviewProfileDivider from 'common/theme/overview-profile/StyledOverviewProfileDivider';
import StyledOverviewProfileSubtitle from 'common/theme/overview-profile/StyledOverviewProfileSubtitle';
import StyledInfoContainer from 'common/theme/containers/StyledInfoContainer';
import { formatCurrency } from 'common/utils/format';

function ClientPlan({ client, loading }) {
  const { t, i18n } = useTranslation(['labels']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const dueDate = useMemo(
    () =>
      client?.last_client_payment?.end_date
        ? format(parseISO(client.last_client_payment.end_date), dateFormat, {
            locale: dateLocale
          })
        : '',
    [client]
  );
  const planPrice = useMemo(
    () => (client?.plan_id?.price ? formatCurrency(client.plan_id.price) : ''),
    [client]
  );

  return (
    <>
      <StyledOverviewProfileSubtitle component="h4">
        {t('planDetails', { ns: 'labels' })}
      </StyledOverviewProfileSubtitle>
      <StyledOverviewProfileDivider />
      {loading ? (
        <ClientPlanSkeleton />
      ) : (
        <Stack direction="row" sx={{ marginBottom: (theme) => theme.spacing(3) }}>
          <StyledInfoContainer>
            <Typography sx={{ fontWeight: 500 }}>
              {t('name', { ns: 'labels' })}:{' '}
              <Typography component="span">{client?.plan_id?.name}</Typography>
            </Typography>
          </StyledInfoContainer>
          <StyledInfoContainer>
            <Typography sx={{ fontWeight: 500 }}>
              {t('price', { ns: 'labels' })}: <Typography component="span">{planPrice}</Typography>
            </Typography>
          </StyledInfoContainer>
          <StyledInfoContainer>
            <Typography sx={{ fontWeight: 500 }}>
              {t('sessionsPaid', { ns: 'labels' })}:{' '}
              <Typography component="span">{client?.plan_id?.sessions}</Typography>
            </Typography>
          </StyledInfoContainer>
          <StyledInfoContainer>
            <Typography sx={{ fontWeight: 500 }}>
              {t('sessionsAvailable', { ns: 'labels' })}:{' '}
              <Typography component="span">{client?.available_sessions}</Typography>
            </Typography>
          </StyledInfoContainer>
          <StyledInfoContainer>
            <Typography sx={{ fontWeight: 500 }}>
              {t('dueDate', { ns: 'labels' })}: <Typography component="span">{dueDate}</Typography>
            </Typography>
          </StyledInfoContainer>
        </Stack>
      )}
    </>
  );
}

ClientPlan.defaultProps = {
  client: null
};

ClientPlan.propTypes = {
  client: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default ClientPlan;
