const adminRoutes = {
  adminDashboard: {
    path: '/dashboard/admin'
  },
  adminMore: {
    path: '/more/admin'
  },
  listAdmin: {
    path: '/users/admins'
  },
  createAdmin: {
    path: '/users/admins/create'
  },
  editAdmin: {
    path: '/users/admins/edit/:id'
  }
};

export default adminRoutes;
