import { takeLatest, put } from 'redux-saga/effects';
import mealPlanSlice from 'store/modules/mealPlan';
import uiSlice from 'store/modules/ui';
import mealPlan from 'api/graphql/mealPlan';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import i18n from 'i18n';

/**
 * Create meal plan saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'meal-plan-create-notification-success',
      variant: 'success',
      message: i18n.t('mealPlan.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield mealPlan.create(payload);
    yield put(mealPlanSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(mealPlanSlice.actions.createError(message));
  }
}

/**
 * Find meal plan saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield mealPlan.find(action.payload);
    const {
      data: { mealPlan: currentMealPlan }
    } = response;

    yield put(mealPlanSlice.actions.findSuccess(currentMealPlan));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(mealPlanSlice.actions.findError(message));
  }
}

/**
 * List meal plan saga
 *
 * @param {*} action
 */
function* list(action) {
  try {
    const {
      payload: { fields, filters, pagination }
    } = action;
    const response = yield mealPlan.list({ fields, filters, pagination });
    const {
      data: {
        mealPlans: { data, paginatorInfo }
      }
    } = response;

    yield put(mealPlanSlice.actions.listSuccess(data));
    yield put(
      mealPlanSlice.actions.updatePaginatorInfo({
        hasMorePages: paginatorInfo.hasMorePages,
        lastPage: paginatorInfo.lastPage,
        total: paginatorInfo.total
      })
    );
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(mealPlanSlice.actions.listError(message));
  }
}

/**
 * Remove meal plan saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deleteMealPlans: removedMealPlan }
    } = yield mealPlan.remove(action.payload);

    const snack = createSnack({
      key: 'remove-meal-plan-notification-success',
      variant: 'success',
      message: i18n.t('mealPlan.delete', { count: removedMealPlan.length, ns: 'notifications' })
    });

    yield put(mealPlanSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(mealPlanSlice.actions.removeError(message));
  }
}

/**
 * Restore meal plan saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restoreMealPlans: restoredMealPlan }
    } = yield mealPlan.restore(action.payload);

    const snack = createSnack({
      key: 'restore-meal-plan-notification-success',
      variant: 'success',
      message: i18n.t('mealPlan.restore', { count: restoredMealPlan.length, ns: 'notifications' })
    });

    yield put(mealPlanSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(mealPlanSlice.actions.restoreError(message));
  }
}

/**
 * Update meal plan saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-meal-plan-notification-success',
      variant: 'success',
      message: i18n.t('mealPlan.update', { ns: 'notifications' })
    });

    yield mealPlan.update(action.payload);

    yield put(mealPlanSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(mealPlanSlice.actions.updateError(message));
  }
}

/**
 * Watch meal plan
 *
 * @export
 */
export default function* watchMealPlan() {
  yield takeLatest(mealPlanSlice.actions.create, create);
  yield takeLatest(mealPlanSlice.actions.find, find);
  yield takeLatest(mealPlanSlice.actions.list, list);
  yield takeLatest(mealPlanSlice.actions.remove, remove);
  yield takeLatest(mealPlanSlice.actions.restore, restore);
  yield takeLatest(mealPlanSlice.actions.update, update);
}
