import { dateFormat, timeFormat } from 'common/constants/date';
import { convertUtcToLocalTime } from 'common/utils/date';
import { isTrainer } from 'common/utils/user';

/**
 * Formats the date and time of a session field from UTC to local time.
 *
 * @param  {Object}  session     The session object containing the date/time field.
 * @param  {string}  field       The key name of the date/time field in the session object to format.
 * @param  {string}  format      The desired format for the date/time string.
 * @param  {string}  dateLocale  The locale to be used for formatting the date/time.
 *
 * @returns  {string}  The formatted date/time string in local time based on the given locale.
 */
const formatSessionDateTime = (session, field, format, dateLocale) =>
  convertUtcToLocalTime(session[field], format, dateLocale);

/**
 * Transforms an array of session data for administrative purposes by formatting date and time fields.
 *
 * @param  {Object[]}  data        The array of session objects to be transformed.
 * @param  {string}    dateLocale  The locale to be used for formatting the date/time.
 *
 * @returns  {Object[]}  An array of session objects with formatted date and time fields suitable for admin display.
 */
export const getAdminSessionData = (data, dateLocale) =>
  data.map((session) => {
    const {
      id,
      client_id: client,
      observations,
      session_type_id: sessionType,
      status,
      trainer_id: trainer
    } = session;

    return {
      id,
      client,
      end_date: formatSessionDateTime(session, 'end_date', dateFormat, dateLocale),
      end_date_raw: session.end_date,
      end_time: formatSessionDateTime(session, 'end_date', timeFormat),
      observations,
      session_type: sessionType.name,
      start_date: formatSessionDateTime(session, 'start_date', dateFormat, dateLocale),
      start_date_raw: session.start_date,
      start_time: formatSessionDateTime(session, 'start_date', timeFormat),
      status,
      trainer
    };
  });

/**
 * Transforms an array of session data objects into a format suitable for displaying finished sessions.
 *
 * @param  {Object[]}  data        The array of session objects to be transformed.
 * @param  {string}    role        The role of the user (e.g., 'admin', 'trainer', 'client').
 * @param  {string}    dateLocale  The locale to be used for formatting the date/time.
 *
 * @returns  {Object[]}  An array of objects each representing a finished session with formatted date and time.
 */
export const getFinishedSessionData = (data, role, dateLocale) =>
  data.map((session) => {
    const { session_type_id: sessionType, start_date: startDate, status } = session;
    const user = isTrainer(role) ? session.client_id.full_name : session.trainer_id.full_name;

    return {
      category: sessionType.name,
      user,
      date: formatSessionDateTime({ startDate }, 'startDate', dateFormat, dateLocale),
      status,
      time: formatSessionDateTime({ startDate }, 'startDate', timeFormat)
    };
  });

/**
 * Transforms an array of session data objects for a specific role by formatting date and time fields.
 *
 * @param  {Object[]}  data        The array of session objects to be transformed.
 * @param  {string}    role        The role of the user (e.g., 'admin', 'trainer', 'client').
 * @param  {string}    dateLocale  The locale to be used for formatting the date/time.
 *
 * @returns  {Object[]}  An array of session objects with formatted date and time fields and additional role-specific information.
 */
export const getSessionData = (data, role, dateLocale) =>
  data.map((session) => {
    const {
      id,
      observations,
      session_type_id: sessionType,
      status,
      client_id: client,
      trainer_id: trainer
    } = session;

    return {
      id,
      end_date: formatSessionDateTime(session, 'end_date', dateFormat, dateLocale),
      end_date_raw: session.end_date,
      end_time: formatSessionDateTime(session, 'end_date', timeFormat),
      observations,
      session_type: sessionType.name,
      start_date: formatSessionDateTime(session, 'start_date', dateFormat, dateLocale),
      start_date_raw: session.start_date,
      start_time: formatSessionDateTime(session, 'start_date', timeFormat),
      status,
      user: isTrainer(role) ? client : trainer
    };
  });
