import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import MultipleSelectFieldAdapter from 'components/shared/field-adapters/MultipleSelectFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import FilterButtons from 'components/shared/buttons/FilterButtons';
import { getLegalRoleOptions, getUiLanguageOptions } from 'common/constants/options';
import StyledFiltersContainer from 'common/theme/filters/StyledFiltersContainer';
import StyledFiltersForm from 'common/theme/filters/StyledFiltersForm';
import legalSlice from 'store/modules/legal';

function LegalFilters() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels']);

  const initialValues = useSelector((state) => state.legal.filters);
  const status = useSelector((state) => state.legal.status);

  const onFilter = useCallback(
    (values) => {
      dispatch(legalSlice.actions.updateFilters(values));
    },
    [dispatch]
  );

  const resetForm = useCallback(() => {
    dispatch(legalSlice.actions.resetFilters());
  }, [dispatch]);

  return (
    <StyledFiltersContainer>
      <Form
        initialValues={initialValues}
        onSubmit={onFilter}
        render={({ handleSubmit, pristine }) => (
          <StyledFiltersForm onSubmit={handleSubmit}>
            <Grid container spacing={{ xs: 2, lg: 4 }}>
              <Grid xs={12} sm={6}>
                <Field
                  id="language"
                  name="language"
                  component={MultipleSelectFieldAdapter}
                  options={getUiLanguageOptions(t)}
                  label={t('language', { count: 1, ns: 'labels' })}
                  fullWidth
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <Field
                  id="role"
                  name="role"
                  component={SelectFieldAdapter}
                  options={getLegalRoleOptions(t)}
                  label={t('user', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
            <FilterButtons pristine={pristine} resetForm={resetForm} status={status} />
          </StyledFiltersForm>
        )}
      />
    </StyledFiltersContainer>
  );
}

export default LegalFilters;
