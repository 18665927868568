import { Box, styled } from '@mui/material';

const StyledSessionDrawerMessage = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '8px',
  color: theme.palette.gray3.main,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`
  },
  '& p': {
    margin: 0,
    '&:first-of-type': {
      marginBottom: theme.spacing(1)
    },
    '&:last-of-type span': {
      '&::after': {
        content: '", "'
      },
      '&:last-of-type::after': {
        display: 'none'
      }
    }
  }
}));

export default StyledSessionDrawerMessage;
