import { takeLatest, put } from 'redux-saga/effects';
import sessionTypeSlice from 'store/modules/sessionType';
import uiSlice from 'store/modules/ui';
import sessionType from 'api/graphql/sessionType';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import i18n from 'i18n';

/**
 * Create session type saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'session-type-create-notification-success',
      variant: 'success',
      message: i18n.t('sessionType.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield sessionType.create(payload);
    yield put(sessionTypeSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionTypeSlice.actions.createError(message));
  }
}

/**
 * Find session type saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield sessionType.find(action.payload);
    const {
      data: { sessionType: currentSessionType }
    } = response;

    yield put(sessionTypeSlice.actions.findSuccess(currentSessionType));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionTypeSlice.actions.findError(message));
  }
}

/**
 * List session types saga
 *
 * @param {*} action
 */
function* list(action) {
  try {
    const {
      payload: { filters, pagination }
    } = action;
    const response = yield sessionType.list({ filters, pagination });
    const {
      data: {
        sessionTypes: { data, paginatorInfo }
      }
    } = response;

    yield put(sessionTypeSlice.actions.listSuccess(data));
    yield put(
      sessionTypeSlice.actions.updatePaginatorInfo({
        hasMorePages: paginatorInfo.hasMorePages,
        lastPage: paginatorInfo.lastPage,
        total: paginatorInfo.total
      })
    );
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionTypeSlice.actions.listError(message));
  }
}

/**
 * List all session types saga
 *
 * @param {*} action
 */
function* listAll() {
  try {
    const response = yield sessionType.listAll();
    const {
      data: { sessionTypesAll }
    } = response;

    const options = sessionTypesAll.map((option) => ({
      value: Number(option.id),
      label: option.name
    }));

    yield put(sessionTypeSlice.actions.listAllSuccess(options));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionTypeSlice.actions.listAllError(message));
  }
}

/**
 * Remove session types saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deleteSessionTypes: removedSessionTypes }
    } = yield sessionType.remove(action.payload);

    const snack = createSnack({
      key: 'remove-session-types-notification-success',
      variant: 'success',
      message: i18n.t('sessionType.delete', {
        count: removedSessionTypes.length,
        ns: 'notifications'
      })
    });

    yield put(sessionTypeSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionTypeSlice.actions.removeError(message));
  }
}

/**
 * Restore session types saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restoreSessionTypes: restoredSessionTypes }
    } = yield sessionType.restore(action.payload);

    const snack = createSnack({
      key: 'restore-session-types-notification-success',
      variant: 'success',
      message: i18n.t('sessionType.restore', {
        count: restoredSessionTypes.length,
        ns: 'notifications'
      })
    });

    yield put(sessionTypeSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionTypeSlice.actions.restoreError(message));
  }
}

/**
 * Update session type saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-session-type-notification-success',
      variant: 'success',
      message: i18n.t('sessionType.update', { ns: 'notifications' })
    });

    yield sessionType.update(action.payload);

    yield put(sessionTypeSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(sessionTypeSlice.actions.updateError(message));
  }
}

/**
 * Watch session type
 *
 * @export
 */
export default function* watchSessionType() {
  yield takeLatest(sessionTypeSlice.actions.create, create);
  yield takeLatest(sessionTypeSlice.actions.find, find);
  yield takeLatest(sessionTypeSlice.actions.list, list);
  yield takeLatest(sessionTypeSlice.actions.listAll, listAll);
  yield takeLatest(sessionTypeSlice.actions.remove, remove);
  yield takeLatest(sessionTypeSlice.actions.restore, restore);
  yield takeLatest(sessionTypeSlice.actions.update, update);
}
