import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMatch } from 'react-router-dom';
import { Collapse, List } from '@mui/material';
import CollapsibleMenuItemToggle from 'components/shared/menus/CollapsibleMenuItemToggle';

function CollapsibleMenuItem({ children, icon, label, noLink, path }) {
  const [open, setOpen] = useState(false);
  const match = useMatch(`${path}/*`);
  const isActive = match !== null;

  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <>
      <CollapsibleMenuItemToggle
        icon={icon}
        isActive={isActive}
        label={label}
        noLink={noLink}
        onClick={toggleCollapse}
        open={open}
        path={path}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
}

CollapsibleMenuItem.defaultProps = {
  noLink: false,
  path: '/'
};

CollapsibleMenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  noLink: PropTypes.bool,
  path: PropTypes.string
};

export default CollapsibleMenuItem;
