import { Menu, styled } from '@mui/material';
import { menuClasses } from '@mui/material/Menu';

const StyledAvailabilityMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    backgroundColor: theme.palette.gray2.main,
    backgroundImage: 'none',
    borderRadius: '10px',
    [`& .${menuClasses.list}`]: {
      padding: 0
    }
  }
}));

export default StyledAvailabilityMenu;
