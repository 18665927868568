import React from 'react';
import { useTranslation } from 'react-i18next';
import { Assignment, Group, Home, Payment, Restaurant } from '@mui/icons-material';
import { List } from '@mui/material';
import {
  AccountBoxMultiple,
  CalendarCursor,
  ChartBoxOutline,
  ClipboardList,
  NewspaperVariantOutline,
  TableSearch,
  WeightLifter
} from 'mdi-material-ui';
import CollapsibleMenuItem from 'components/shared/menus/CollapsibleMenuItem';
import MenuItem from 'components/shared/menus/MenuItem';
import NestedMenuItem from 'components/shared/menus/NestedMenuItem';
import routes from 'common/routes';

function AdminDesktopMenu() {
  const { t } = useTranslation(['routes']);

  return (
    <List>
      <MenuItem
        icon={<Home />}
        itemKey="home"
        label={t('home', { ns: 'routes' })}
        path={routes.adminDashboard.path}
      />
      <MenuItem
        icon={<NewspaperVariantOutline />}
        itemKey="news"
        label={t('news.list', { ns: 'routes' })}
        path={routes.listNews.path}
      />
      <MenuItem
        icon={<CalendarCursor />}
        itemKey="calendar"
        label={t('session.calendar', { ns: 'routes' })}
        path={routes.calendar.path}
      />
      <MenuItem
        icon={<TableSearch />}
        itemKey="sessions"
        label={t('session.list', { ns: 'routes' })}
        path={routes.listSession.path}
      />
      <MenuItem
        icon={<WeightLifter />}
        itemKey="sessionTypes"
        label={t('sessionType.list', { ns: 'routes' })}
        path={routes.listSessionType.path}
      />
      <MenuItem
        icon={<ClipboardList />}
        itemKey="plans"
        label={t('plan.list', { ns: 'routes' })}
        path={routes.listPlan.path}
      />
      <MenuItem
        icon={<Restaurant />}
        itemKey="nutrition"
        label={t('mealPlan.list', { ns: 'routes' })}
        path={routes.listMealPlan.path}
      />
      <CollapsibleMenuItem
        icon={<ChartBoxOutline />}
        label={t('performance.list', { ns: 'routes' })}
        path={routes.performanceDashboard.path}>
        <NestedMenuItem
          label={t('performance.reports', { ns: 'routes' })}
          path={routes.listPerformance.path}
        />
      </CollapsibleMenuItem>
      <MenuItem
        icon={<AccountBoxMultiple />}
        itemKey="progress"
        label={t('progress.list', { ns: 'routes' })}
        path={routes.listProgress.path}
      />
      <CollapsibleMenuItem
        icon={<Payment />}
        label={t('payment.payments', { ns: 'routes' })}
        path={routes.payments.path}>
        <NestedMenuItem
          label={t('client.list', { ns: 'routes' })}
          path={routes.listClientPayments.administrator.path}
        />
        <NestedMenuItem
          label={t('trainer.list', { ns: 'routes' })}
          path={routes.listTrainerPayments.administrator.path}
        />
      </CollapsibleMenuItem>
      <CollapsibleMenuItem
        icon={<Group />}
        label={t('users', { ns: 'routes' })}
        noLink
        path={routes.users.path}>
        <NestedMenuItem
          label={t('administrator.list', { ns: 'routes' })}
          path={routes.listAdmin.path}
        />
        <NestedMenuItem label={t('client.list', { ns: 'routes' })} path={routes.listClient.path} />
        <NestedMenuItem
          label={t('trainer.list', { ns: 'routes' })}
          path={routes.listTrainer.path}
        />
      </CollapsibleMenuItem>
      <MenuItem
        icon={<Assignment />}
        itemKey="legal"
        label={t('legal.list', { ns: 'routes' })}
        path={routes.listLegal.path}
      />
    </List>
  );
}

export default AdminDesktopMenu;
