import { IconButton, styled } from '@mui/material';

const StyledProfilePictureIconButton = styled(IconButton)(({ theme }) => ({
  backgroundImage: `linear-gradient(90deg, ${theme.palette.common.white} 0%, ${theme.palette.common.white} 100%);`,
  bottom: 0,
  borderRadius: '50%',
  position: 'absolute',
  right: 0
}));

export default StyledProfilePictureIconButton;
