import { lightFormat } from 'date-fns';
import createDecorator from 'final-form-calculate';
import { dateTimeFormat } from 'common/constants/date';
import getTrainerPaymentInfo from 'http/trainerPayment';

export const clientPaymentFormDecorators = createDecorator({
  field: 'user_id',
  updates: {
    currency: (userIdValue) => userIdValue?.plan_id?.currency,
    plan_id: (userIdValue) => userIdValue?.plan_id?.id,
    plan_name: (userIdValue) => userIdValue?.plan_id?.name,
    price: (userIdValue) => userIdValue?.plan_id?.price,
    sessions_paid: (userIdValue) => userIdValue?.plan_id?.sessions
  }
});

export const onTrainerPaymentFormChange = async (field, disableForm, formRef, isFormDisabled) => {
  const { current: form } = formRef;
  const trainer = form.getFieldState('user_id').value;
  const startDate = lightFormat(form.getFieldState('start_date').value, dateTimeFormat);
  const endDate = lightFormat(form.getFieldState('end_date').value, dateTimeFormat);

  if (trainer && 'value' in trainer && startDate && endDate) {
    try {
      disableForm(true);

      const { sessionsCompleted, totalAmount } = await getTrainerPaymentInfo({
        endDate,
        startDate,
        trainerId: Number(trainer.value)
      });

      form.change('price', totalAmount);
      form.change('sessions_completed', sessionsCompleted);

      disableForm(false);
    } catch {
      if (isFormDisabled) {
        disableForm(!isFormDisabled);
      }
    }
  }
};
