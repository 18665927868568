import styledComponent from 'styled-components';

const StyledPerformanceHistoryChartGrid = styledComponent.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 2rem;
  @media (min-width: 900px) {
    column-gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 1rem;
  }
  @media (min-width: 1200px) {
    column-gap: 2rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 2rem;
  }
`;

export default StyledPerformanceHistoryChartGrid;
