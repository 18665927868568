import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-spring-lightbox';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ProgressGalleryButton from 'components/shared/galleries/progress/lightbox/ProgressGalleryButton';
import ProgressGalleryHeader from 'components/shared/galleries/progress/lightbox/ProgressGalleryHeader';
import ProgressGalleryLightboxItem from 'components/shared/galleries/progress/lightbox/ProgressGalleryLightboxItem';
import StyledProgressGalleryGridContainer from 'common/theme/galleries/progress/StyledProgressGalleryGridContainer';
import 'styles/lightbox.css';

function ProgressGalleryLightbox({ progress }) {
  const { t } = useTranslation(['labels']);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  const [isOpen, setIsOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const progressItems = isMedium ? progress.slice(1) : progress;

  const toggleLightbox = () => {
    setIsOpen(!isOpen);
  };

  const gotoPrevious = () => currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < lightboxImages.length && setCurrentImageIndex(currentImageIndex + 1);

  const handleImageClick = useCallback(({ imageIndex, currentProgress }) => {
    const images = [
      {
        loading: 'lazy',
        src: currentProgress.profile_picture,
        alt: t('profile', { ns: 'labels' }),
        date: currentProgress.created_at
      },
      {
        loading: 'lazy',
        src: currentProgress.front_picture,
        alt: t('back', { ns: 'labels' }),
        date: currentProgress.created_at
      },
      {
        loading: 'lazy',
        src: currentProgress.back_picture,
        alt: t('back', { ns: 'labels' }),
        date: currentProgress.created_at
      }
    ];

    setCurrentImageIndex(Number(imageIndex));
    setLightboxImages(images);
    toggleLightbox();
  }, []);

  const renderHeader = useCallback(
    () => (
      <ProgressGalleryHeader
        currentImageIndex={currentImageIndex}
        images={lightboxImages}
        onClose={toggleLightbox}
      />
    ),
    [currentImageIndex, lightboxImages, toggleLightbox]
  );

  const renderPrevButton = useCallback(
    ({ canPrev }) => (
      <ProgressGalleryButton position="left" onClick={gotoPrevious} disabled={!canPrev} />
    ),
    [gotoPrevious]
  );

  const renderNextButton = useCallback(
    ({ canNext }) => (
      <ProgressGalleryButton position="right" onClick={gotoNext} disabled={!canNext} />
    ),
    [gotoNext]
  );

  return (
    <>
      <StyledProgressGalleryGridContainer>
        {progressItems.map((item) => (
          <ProgressGalleryLightboxItem
            key={item.id}
            onImageClick={handleImageClick}
            progress={item}
          />
        ))}
      </StyledProgressGalleryGridContainer>
      <Lightbox
        isOpen={isOpen}
        onClose={toggleLightbox}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={lightboxImages}
        currentIndex={currentImageIndex}
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
        renderHeader={renderHeader}
      />
    </>
  );
}

ProgressGalleryLightbox.propTypes = {
  progress: PropTypes.arrayOf(
    PropTypes.shape({
      back_picture: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      front_picture: PropTypes.string.isRequired,
      profile_picture: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default ProgressGalleryLightbox;
