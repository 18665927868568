import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledTextField from 'common/theme/fields/StyledTextfield';

function TextFieldAdapter({ input, meta, ...rest }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onChange = (event) => {
    input.onChange(event.target.value);
  };

  return (
    <StyledTextField
      {...input}
      {...rest}
      onChange={onChange}
      error={meta.touched ? meta.invalid : false}
      helperText={meta.touched && meta.invalid ? meta.error : ''}
      size={size}
    />
  );
}

TextFieldAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default TextFieldAdapter;
