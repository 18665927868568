import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Typography } from '@mui/material';
import StyledImageUploadButton from 'common/theme/fields/image-upload/StyledImageUploadButton';
import StyledImageUploadContainer from 'common/theme/fields/image-upload/StyledImageUploadContainer';
import StyledImageUploadInnerContainer from 'common/theme/fields/image-upload/StyledImageUploadInnerContainer';
import StyledImageUploadPreview from 'common/theme/fields/image-upload/StyledImageUploadPreview';
import StyledImageUploadPreviewContainer from 'common/theme/fields/image-upload/StyledImageUploadPreviewContainer';
import StyledImageUploadPreviewInnerContainer from 'common/theme/fields/image-upload/StyledImageUploadPreviewInnerContainer';
import StyledImageUploadResetButton from 'common/theme/fields/image-upload/StyledImageUploadResetButton';
import StyledImageUploadResetIcon from 'common/theme/fields/image-upload/StyledImageUploadResetIcon';
import StyledImageUploadText from 'common/theme/fields/image-upload/StyledImageUploadText';
import { createSnack } from 'common/utils/general';
import uiSlice from 'store/modules/ui';

function ImageUploadAdapter({ allowedFileTypes, input, label, meta, ...rest }) {
  const { t } = useTranslation(['buttons', 'errors', 'labels']);
  const dropzoneRef = useRef();
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(null);
  const isDisabled = preview !== null;

  useEffect(() => {
    if (input.value && input.value.length > 0) {
      setPreview(input.value);
    }
  }, [input.value]);

  useEffect(() => () => URL.revokeObjectURL(preview), []);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setPreview(URL.createObjectURL(acceptedFiles[0]));
      input.onChange(acceptedFiles[0]);
    }
  };

  const onDropRejected = () => {
    const snack = createSnack({
      key: `upload-file-notification-error`,
      variant: 'error',
      message: t('upload.size.file', { ns: 'errors' })
    });

    dispatch(uiSlice.actions.enqueueSnackbar(snack));
  };

  const onReset = () => {
    setPreview(null);
    input.onChange(null);

    if (dropzoneRef.current) {
      dropzoneRef.current.value = null;
    }
  };

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <Dropzone
      accept={allowedFileTypes}
      disabled={isDisabled}
      ref={dropzoneRef}
      noClick
      noKeyboard
      maxFiles={1}
      maxSize={10000000}
      multiple={false}
      onDrop={onDrop}
      onDropRejected={onDropRejected}
      {...rest}>
      {({ getRootProps, getInputProps }) => (
        <StyledImageUploadContainer {...getRootProps()}>
          <input style={{ display: 'none' }} {...getInputProps()} />
          <Typography variant="body2" sx={{ marginBottom: 1 }}>
            {label}
          </Typography>
          <StyledImageUploadInnerContainer>
            {preview && (
              <StyledImageUploadPreviewContainer>
                <StyledImageUploadPreviewInnerContainer>
                  <StyledImageUploadPreview
                    src={preview}
                    alt={t('preview', { ns: 'common' })}
                    onLoad={() => URL.revokeObjectURL(preview)}
                  />
                  <StyledImageUploadResetButton
                    color="secondary"
                    onClick={onReset}
                    size="small"
                    variant="contained">
                    {t('delete', { ns: 'common' })}
                  </StyledImageUploadResetButton>
                </StyledImageUploadPreviewInnerContainer>
              </StyledImageUploadPreviewContainer>
            )}
            <StyledImageUploadButton disabled={isDisabled} onClick={openDialog} type="button">
              <StyledImageUploadResetIcon />
              <StyledImageUploadText component="span" variant="body1">
                {t('clickToUploadImage', { ns: 'labels' })}
              </StyledImageUploadText>
              {t('open', { ns: 'common' })}
            </StyledImageUploadButton>
          </StyledImageUploadInnerContainer>
        </StyledImageUploadContainer>
      )}
    </Dropzone>
  );
}

ImageUploadAdapter.propTypes = {
  allowedFileTypes: PropTypes.object.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
  }).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  }).isRequired
};

export default ImageUploadAdapter;
