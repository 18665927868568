import { Box, styled } from '@mui/material';

const StyledInfoContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray0.main,
  borderRadius: '8px',
  padding: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px'
  }
}));

export default StyledInfoContainer;
