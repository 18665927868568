import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import requestStatus from 'common/constants/status';
import StyledAvailabilityRepeatButton from 'common/theme/availability/StyledAvailabilityRepeatButton';
import { getStartEndDatesFromView } from 'common/utils/availability';
import useAvailabilityRepeat from 'hooks/availability/useAvailabilityRepeat';

function AvailabilityRepeatButton({ calendar }) {
  const { t } = useTranslation(['buttons']);

  const didRepeat = useSelector((state) => state.availability.didRepeat);
  const repeatStatus = useSelector((state) => state.availability.status.repeat);

  const { canRepeat, handleRepeat } = useAvailabilityRepeat();

  if (!calendar) return null;

  const dateRange = getStartEndDatesFromView(calendar);
  const isDisabled = !canRepeat(dateRange) || didRepeat || repeatStatus === requestStatus.loading;

  const handleClick = () => {
    handleRepeat(dateRange, calendar);
  };

  return (
    <StyledAvailabilityRepeatButton
      color="primary"
      onClick={handleClick}
      variant="contained"
      disabled={isDisabled}>
      <span>{t('repeatAvailability', { ns: 'buttons' })}</span>
    </StyledAvailabilityRepeatButton>
  );
}

AvailabilityRepeatButton.defaultProps = {
  calendar: null
};

AvailabilityRepeatButton.propTypes = {
  calendar: PropTypes.object
};

export default AvailabilityRepeatButton;
