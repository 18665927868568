import { all } from 'redux-saga/effects';
import watchAdmin from 'store/sagas/adminSaga';
import watchAuth from 'store/sagas/authSaga';
import watchAvailability from 'store/sagas/availabilitySaga';
import watchClient from 'store/sagas/clientSaga';
import watchClientPayment from 'store/sagas/clientPaymentSaga';
import watchGeo from 'store/sagas/geoSaga';
import watchLegal from 'store/sagas/legalSaga';
import watchMealPlan from 'store/sagas/mealPlanSaga';
import watchNews from 'store/sagas/newsSaga';
import watchNotification from 'store/sagas/notificationSaga';
import watchPerformance from 'store/sagas/performanceSaga';
import watchPlan from 'store/sagas/planSaga';
import watchProgress from 'store/sagas/progressSaga';
import watchSession from 'store/sagas/sessionSaga';
import watchSessionType from 'store/sagas/sessionTypeSaga';
import watchSettings from 'store/sagas/settingsSaga';
import watchTrainer from 'store/sagas/trainerSaga';
import watchTrainerPayment from 'store/sagas/trainerPaymentSaga';

/**
 * Root saga
 *
 * @export
 */
export default function* rootSaga() {
  yield all([
    watchAdmin(),
    watchAuth(),
    watchAvailability(),
    watchClient(),
    watchClientPayment(),
    watchGeo(),
    watchLegal(),
    watchMealPlan(),
    watchNews(),
    watchNotification(),
    watchPerformance(),
    watchPlan(),
    watchProgress(),
    watchSession(),
    watchSessionType(),
    watchSettings(),
    watchTrainer(),
    watchTrainerPayment()
  ]);
}
