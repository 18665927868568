import { put } from 'redux-saga/effects';
import progress from 'api/graphql/progress';
import progressSlice from 'store/modules/progress';
import { getUserFriendlyError } from 'common/utils/general';

/**
 * A higher-order function that creates a saga for handling progress listing.
 *
 * @param {function} successAction - The action to be dispatched upon a successful API call.
 * @param {function} errorAction - The action to be dispatched if the API call fails.
 *
 * @returns {function} A generator function (saga) that makes an API call to list progress.
 */
const createProgressList = (successAction, errorAction) =>
  function* list(action) {
    try {
      const { fields, filters = {}, pagination } = action.payload;
      const response = yield progress.list({ fields, filters, pagination });

      const {
        data: {
          progresses: { data, paginatorInfo }
        }
      } = response;

      yield put(successAction(data));
      yield put(
        progressSlice.actions.updatePaginatorInfo({
          hasMorePages: paginatorInfo.hasMorePages,
          lastPage: paginatorInfo.lastPage,
          total: paginatorInfo.total
        })
      );
    } catch (ex) {
      const message = getUserFriendlyError(ex);
      yield put(errorAction(message));
    }
  };

export default createProgressList;
