const trainerRoutes = {
  trainerDashboard: {
    path: '/dashboard/trainer'
  },
  trainerMore: {
    path: '/more/trainer'
  },
  trainerProfile: {
    path: '/trainer-profile'
  },
  listTrainer: {
    path: '/users/trainers'
  },
  createTrainer: {
    path: '/users/trainers/create'
  },
  editTrainer: {
    path: '/users/trainers/edit/:id'
  },
  viewTrainer: {
    path: '/users/trainers/:id'
  }
};

export default trainerRoutes;
