import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationStatus } from 'common/constants/options';
import notificationSlice from 'store/modules/notification';

const useMenuNotifications = () => {
  const dispatch = useDispatch();

  const notifications = useSelector((state) => state.notification.data.menuNotifications);
  const paginatorInfo = useSelector((state) => state.notification.menuPaginatorInfo);
  const status = useSelector((state) => state.notification.status.menu);
  const refresh = useSelector((state) => state.notification.refresh.menu);
  const userId = useSelector((state) => state.auth.user.id);

  const hasNotifications = notifications.length > 0;

  const listNotifications = useCallback(() => {
    dispatch(
      notificationSlice.actions.listMenuNotifications({
        filters: { status: notificationStatus.all, user_id: userId },
        pagination: { first: 5, page: 1 }
      })
    );
  }, [dispatch, userId]);

  return {
    hasNotifications,
    listNotifications,
    notifications,
    paginatorInfo,
    refresh,
    status
  };
};

export default useMenuNotifications;
