export const availabilityEventTimeFormat = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true
};

export const availabilityHeaderToolbar = {
  start: 'prev,next,today',
  center: 'title',
  end: ''
};

export const availabilitySlotDuration = '01:00:00';

export const availabilitySlotLabelFormat = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
};
