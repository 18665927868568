import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import availabilitySlice from 'store/modules/availability';

const useAvailabilityAppBar = () => {
  const dispatch = useDispatch();

  const toDelete = useSelector((state) => state.availability.data.toDelete);
  const toUpsert = useSelector((state) => state.availability.data.toUpsert);
  const trainerId = useSelector((state) => state.auth.user.id);

  /**
   * Dispatches a `reset` action to the `availabilitySlice` reducer.
   *
   * @callback handleReset
   */
  const handleReset = useCallback(() => {
    dispatch(availabilitySlice.actions.reset());
  }, [dispatch]);

  /**
   * Dispatches actions to the `availabilitySlice` reducer based on the current state of the application.
   * It performs either a force delete or an upsert action depending on the data present in `toDelete` and `toUpsert` arrays.
   *
   * @callback handleSave
   */
  const handleSave = useCallback(() => {
    if (toDelete.length > 0) {
      dispatch(availabilitySlice.actions.forceDelete({ toDelete, userId: trainerId }));
    }

    if (toUpsert.length > 0) {
      dispatch(availabilitySlice.actions.upsert({ toUpsert, userId: trainerId }));
    }
  }, [dispatch, toDelete, toUpsert, trainerId]);

  /**
   * Indicates whether there are any pending deletions or insert.
   *
   * @returns {Boolean}  `true` if there are no pending deletions or insert, `false` otherwise.
   */
  const isSync = useMemo(() => toDelete.length <= 0 && toUpsert.length <= 0, [toDelete, toUpsert]);

  return {
    handleReset,
    handleSave,
    isSync
  };
};

export default useAvailabilityAppBar;
