import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _camelCase from 'lodash/camelCase';
import StyledChip from 'common/theme/chips/StyledChip';
import { sessionStatus } from 'common/constants/options';

function SessionStatusChip({ data }) {
  const { t } = useTranslation();
  const { status } = data;
  const visualStatus = t(`sessionStatus.${_camelCase(status)}`, { ns: 'options' });

  const statusChip = {
    [sessionStatus.finished]: () => <StyledChip label={visualStatus} color="finished" />,
    [sessionStatus.clientAbsent]: () => <StyledChip label={visualStatus} color="absent" />,
    [sessionStatus.trainerAbsent]: () => <StyledChip label={visualStatus} color="absent" />,
    default: () => <StyledChip label={visualStatus} color="scheduled" />
  };

  return (statusChip[status] || statusChip.default)();
}

SessionStatusChip.propTypes = {
  data: PropTypes.object.isRequired
};

export default SessionStatusChip;
