import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ProgressGallery from 'components/shared/galleries/progress/ProgressGallery';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import AppBarContext from 'context/AppBarContext';

function ProgressHistory() {
  const { t } = useTranslation(['routes']);
  const pageTitle = t('progress.myProgress', { ns: 'routes' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);
  const userId = useSelector((state) => state.auth.user.id);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <ProgressGallery clientId={Number(userId)} />
    </>
  );
}

export default ProgressHistory;
