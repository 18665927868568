import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import MUIDataTable from 'mui-datatables';
import { getArrearageClientsColumns } from 'common/table/columns';
import { getArrearageClientsData } from 'common/table/data';
import { defaultTableOptions, tableLocalization } from 'common/table/utils';

function ArrearageClientsTable({ clients, onChangePage, pagination, paginatorInfo, rowsPerPage }) {
  const { t, i18n } = useTranslation(['routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const tableData = getArrearageClientsData(clients, dateLocale);
  const columns = getArrearageClientsColumns({ data: clients, translateFunc: t });

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    jumpToPage: false,
    onChangePage,
    page: pagination.page - 1,
    rowsPerPage,
    selectableRows: 'none',
    textLabels: tableLocalization(t('client.list', { ns: 'routes' }).toLowerCase(), t)
  };

  return (
    <MUIDataTable
      className="arrearageClients"
      columns={columns}
      data={tableData}
      options={options}
    />
  );
}

ArrearageClientsTable.propTypes = {
  clients: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  paginatorInfo: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

export default ArrearageClientsTable;
