import { MenuItem, styled } from '@mui/material';

const StyledNotificationEmptyMessage = styled(MenuItem)(({ theme }) => ({
  display: 'block',
  padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
  textAlign: 'center',
  whiteSpace: 'normal',
  '&:hover, &:focus': {
    backgroundColor: 'transparent'
  }
}));

export default StyledNotificationEmptyMessage;
