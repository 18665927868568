import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography } from '@mui/material';
import StyledTableRowLabel from 'common/theme/table-rows/StyledTableRowLabel';

function PerformanceExpandableRow({ data, meta, tableData }) {
  const { t } = useTranslation(['labels']);
  const colSpan = data.length + 1;
  const rowData = tableData[meta.rowIndex];

  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Typography variant="body2">
          <StyledTableRowLabel component="span" variant="body2">
            {t('observations', { ns: 'labels' })}:
          </StyledTableRowLabel>{' '}
          {rowData?.observations || t('none', { context: 'female', ns: 'common' })}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

PerformanceExpandableRow.propTypes = {
  data: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  tableData: PropTypes.array.isRequired
};

export default PerformanceExpandableRow;
