import { gql } from '@apollo/client';
import client from 'apollo/client';
import { userLoginFields } from 'common/constants/query-fields/user';

/**
 * Generate reset token method
 *
 * @param  {string} email
 * @return {Promise}
 */
const generateResetToken = (email) =>
  client.mutate({
    mutation: gql`
      mutation generatePasswordResetToken($input: ForgotPasswordInput!) {
        generatePasswordResetToken(input: $input) {
          created
          message
        }
      }
    `,
    variables: { input: { email } }
  });

/**
 * Login method
 *
 * @param  {string} username
 * @param  {Password} password
 * @return {Promise}
 */
const login = ({ username, password }) =>
  client.mutate({
    mutation: gql`
      mutation login($input: LoginInput!) {
        login(input: $input) {
          access_token
          refresh_token
          expires_in
          token_type
          user {
            ${userLoginFields}
          }
        }
      }
    `,
    variables: { input: { username, password } }
  });

/**
 * Logout method
 *
 * @return {Promise}
 */
const logout = () =>
  client.mutate({
    mutation: gql`
      mutation logout {
        logout {
          status
          message
        }
      }
    `
  });

/**
 * Refresh token method
 *
 * @param  {string} token
 * @return {Promise}
 */
const refreshToken = (token) =>
  client.mutate({
    mutation: gql`
      mutation refreshToken($input: RefreshTokenInput) {
        refreshToken(input: $input) {
          access_token
          refresh_token
          expires_in
          token_type
        }
      }
    `,
    variables: { input: { refresh_token: token } }
  });

/**
 * Reset password method
 *
 * @param  {Object} input
 * @return {Promise}
 */
const resetPassword = (input) =>
  client.mutate({
    mutation: gql`
      mutation updatePasswordWithToken($input: NewPasswordWithCodeInput!) {
        updatePasswordWithToken(input: $input) {
          updated
          message
        }
      }
    `,
    variables: { input }
  });

/**
 * Send reset token method
 *
 * @param  {string} email
 * @return {Promise}
 */
const sendResetToken = (email) =>
  client.mutate({
    mutation: gql`
      mutation sendPasswordResetToken($input: ForgotPasswordInput!) {
        sendPasswordResetToken(input: $input) {
          sent
          message
        }
      }
    `,
    variables: { input: { email } }
  });

export default {
  generateResetToken,
  login,
  logout,
  refreshToken,
  resetPassword,
  sendResetToken
};
