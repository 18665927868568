import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { endOfDay, isBefore, startOfDay } from 'date-fns';
import { sessionListCalendarEventFields } from 'common/constants/query-fields/session';
import { convertLocalToUtcTime } from 'common/utils/date';
import { buildSessionFiltersPerRole, mapEventToSessionCardData } from 'common/utils/session';
import sessionSlice from 'store/modules/session';

const useSessionCalendar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation([]);
  const calendarRef = useRef();

  const [calendarApi, setCalendarApi] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [todaySessions, setTodaySessions] = useState([]);

  const refresh = useSelector((state) => state.session.refresh);
  const role = useSelector((state) => state.auth.user.role);
  const sessions = useSelector((state) => state.session.data.events);
  const userId = useSelector((state) => state.auth.user.id);

  /**
   * Filters sessions where the session's start date is within this range (inclusive of `startDate` and exclusive of `endDate`).
   *
   * @callback filterSessionsInDateRange
   * @param  {Date}  startDate  The start date of the range to filter sessions.
   * @param  {Date}  endDate    The end date of the range to filter sessions.
   *
   * @returns  {Array}  A new array containing all sessions that fall within the given date range.
   */
  const filterSessionsInDateRange = useCallback(
    (startDate, endDate) =>
      sessions.filter((session) => {
        const sessionStartDate = new Date(session.start);
        return !isBefore(sessionStartDate, startDate) && isBefore(sessionStartDate, endDate);
      }),
    [sessions]
  );

  /**
   * Fetch the sessions within a specified date range.
   *
   * @callback fetchSessionsWithinDateRange
   * @param  {string}  startDate  The start date of the range in local time.
   * @param  {string}  endDate    The end date of the range in local time.
   */
  const fetchSessionsWithinDateRange = useCallback(
    (startDate, endDate) => {
      const UtcStartDate = convertLocalToUtcTime(startDate);
      const UtcEndDate = convertLocalToUtcTime(endDate);

      dispatch(
        sessionSlice.actions.listEvent({
          fields: sessionListCalendarEventFields,
          filters: buildSessionFiltersPerRole(role, userId, {
            start_between: { start_date: UtcStartDate, end_date: UtcEndDate }
          })
        })
      );
    },
    [convertLocalToUtcTime, buildSessionFiltersPerRole, dispatch, userId, role]
  );

  /**
   * Toggles the state of the drawer (open/closed).
   *
   * @callback toggleDrawer
   */
  const toggleDrawer = useCallback(() => setOpenDrawer(!openDrawer), [openDrawer, setOpenDrawer]);

  /**
   * Filters the sessions within the selected date, then transforms the filtered sessions data for
   * display and updates the state to trigger a drawer containing the session data to open.
   *
   * @param  {Object}  info  An object containing the date clicked by the user.
   *
   * @callback handleDateSelect
   */
  const handleDateClick = useCallback(
    (info) => {
      const { date } = info;
      const start = startOfDay(date);
      const end = endOfDay(date);
      const filteredSessions = filterSessionsInDateRange(start, end);

      if (filteredSessions.length > 0) {
        const sessionCardData = filteredSessions.map((session) =>
          mapEventToSessionCardData(session, role)
        );

        setTodaySessions(sessionCardData);
        toggleDrawer();
      }
    },
    [filterSessionsInDateRange, mapEventToSessionCardData, setTodaySessions, toggleDrawer, role]
  );

  /**
   * Handles the action of setting a date range.
   *
   * @callback handleDateSet
   * @param  {Object}  info        The date range info object.
   * @param  {string}  info.start  The start date of the range.
   * @param  {string}  info.end    The end date of the range.
   */
  const handleDateSet = useCallback(
    (info) => {
      fetchSessionsWithinDateRange(info.start, info.end);
    },
    [fetchSessionsWithinDateRange]
  );

  /**
   * Handles the creation of a string that indicates more items in a list than are currently being displayed.
   *
   * @callback moreLinkContent
   * @param  {Object}  arg      The argument object.
   * @param  {Number}  arg.num  The number of additional items.
   */
  const moreLinkContent = useCallback((arg) => `+${arg.num} ${t('more', { ns: 'common' })}`, [t]);

  /**
   * Refresh the calendar view.
   *
   * @callback refreshCalendar
   */
  const refreshCalendar = useCallback(() => {
    if (!calendarApi) return;

    const { view } = calendarApi;
    fetchSessionsWithinDateRange(view.currentStart, view.currentEnd);
  }, [calendarApi, fetchSessionsWithinDateRange]);

  /**
   * Toggles the state of the dialog (open/closed).
   *
   * @callback toggleSessionCreateDialog
   */
  const toggleSessionCreateDialog = useCallback(() => {
    setIsDialogOpen(!isDialogOpen);
  }, [isDialogOpen, setIsDialogOpen]);

  /**
   * This useEffect hook is utilized to set the calendar API only once when the component is mounted.
   */
  useEffect(() => {
    if (calendarRef?.current && !calendarApi) {
      setCalendarApi(calendarRef.current.getApi());
    }
  }, []);

  /**
   * This useEffect hook is used to refresh the calendar when the `refresh` prop changes.
   */
  useEffect(() => {
    if (refresh && calendarApi) {
      refreshCalendar();
    }
  }, [refresh]);

  return {
    calendarApi,
    calendarRef,
    handleDateClick,
    handleDateSet,
    isDialogOpen,
    moreLinkContent,
    openDrawer,
    todaySessions,
    toggleDrawer,
    toggleSessionCreateDialog
  };
};

export default useSessionCalendar;
