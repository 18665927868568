import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import StyledSessionDialogAppBarTitle from 'common/theme/session/dialog/StyledSessionDialogAppBarTitle';

function SessionCreateDialogAppBar({ onDialogClose }) {
  const { t } = useTranslation(['buttons']);

  return (
    <AppBar sx={{ position: 'relative' }} color="transparent">
      <Toolbar>
        <StyledSessionDialogAppBarTitle variant="h2">
          {t('session.create', { ns: 'buttons' })}
        </StyledSessionDialogAppBarTitle>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onDialogClose}
          aria-label={t('close', { ns: 'buttons' })}>
          <Close />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

SessionCreateDialogAppBar.propTypes = {
  onDialogClose: PropTypes.func.isRequired
};

export default SessionCreateDialogAppBar;
