/* eslint-disable import/no-extraneous-dependencies */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const { REACT_APP_I18NEXT_DEBUG } = process.env;

const getUiLanguage = (browserLanguage) => {
  if (browserLanguage.length > 2) {
    return `${browserLanguage.slice(0, 2).toLowerCase()}`;
  }

  return browserLanguage.toLowerCase();
};

const browserLanguage = getUiLanguage(window.navigator.language);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: REACT_APP_I18NEXT_DEBUG === 'true',
    fallbackLng: 'es',
    lng: browserLanguage,
    load: 'languageOnly',
    ns: ['common', 'errors', 'notifications', 'options', 'validations'],
    supportedLngs: ['es', 'en']
  });

export default i18n;
