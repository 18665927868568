import React from 'react';
import { MenuItem, styled } from '@mui/material';

const StyledNotificationMenuMenuItem = styled(({ isRead, ...otherProps }) => (
  <MenuItem {...otherProps} />
))(({ isRead, theme }) => ({
  backgroundColor: isRead ? 'transparent' : 'rgba(86, 62, 11, 0.18)',
  padding: theme.spacing(2),
  whiteSpace: 'normal',
  '&:hover, &:focus': {
    backgroundColor: 'rgba(86, 62, 11, 0.38)'
  },
  '&:last-of-type': {
    borderBottom: 0
  }
}));

export default StyledNotificationMenuMenuItem;
