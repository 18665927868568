import { Typography, styled } from '@mui/material';

const StyledMonthlySessionEventText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

export default StyledMonthlySessionEventText;
