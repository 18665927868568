import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { Link as RouterLink } from 'react-router-dom';
import { Badge, IconButton, /* Link, */ useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Notifications } from '@mui/icons-material';
import NotificationMenuButtons from 'components/notifications/menu/NotificationMenuButtons';
import NotificationMenuItem from 'components/notifications/menu/NotificationMenuItem';
import NotificationMenuList from 'components/notifications/menu/NotificationMenuList';
import StyledNotificationMenu from 'common/theme/notification/StyledNotificationMenu';
// import StyledNotificationViewMore from 'common/theme/notification/StyledNotificationViewMore';
// import routes from 'common/routes';
import EchoContext from 'context/EchoContext';
import useNotifications from 'hooks/notifications/useNotifications';
import useMenuNotifications from 'hooks/notifications/useMenuNotifications';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

function NotificationMenu({ anchorElement }) {
  const { t } = useTranslation(['buttons', 'notifications']);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const userId = useSelector((state) => state.auth.user.id);

  const [anchorEl, setAnchorEl] = useState(null);
  const { echo } = useContext(EchoContext);
  const open = Boolean(anchorEl);

  const {
    hasNotifications,
    listNotifications,
    notifications,
    // paginatorInfo,
    refresh,
    status
  } = useMenuNotifications();

  const {
    countUnreadNotifications,
    getFormattedNotification,
    handleNotificationSideEffects,
    unreadNotificationsCount
  } = useNotifications();
  // const { hasMorePages } = paginatorInfo;

  useEffect(() => {
    echo.private(`users.${userId}`).notification((notification) => {
      const normalizedNotification = getFormattedNotification(notification);
      handleNotificationSideEffects(notification, normalizedNotification);
    });

    return () => {
      echo.leave(`users.${userId}`);
    };
  }, []);

  useEffect(() => {
    if (userId) {
      listNotifications();
    }
  }, [listNotifications, refresh, userId]);

  useEffect(() => {
    if (userId) {
      countUnreadNotifications(userId);
    }
  }, [countUnreadNotifications, refresh, userId]);

  const onClick = () => {
    setAnchorEl(anchorElement);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const getNotifications = useCallback(
    () =>
      notifications.map((notification) => (
        <NotificationMenuItem key={notification.id} notification={notification} />
      )),
    [notifications]
  );

  return (
    <div>
      <IconButton
        id="notification-menu-button"
        aria-label="show notifications"
        edge="start"
        aria-controls={open ? 'notification-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onClick}
        size={isSmall ? 'small' : 'medium'}>
        <Badge badgeContent={unreadNotificationsCount} color="secondary" overlap="circular">
          <Notifications />
        </Badge>
      </IconButton>
      <StyledNotificationMenu
        id="notification-menu"
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'notification-menu-button'
        }}>
        {hasNotifications && <NotificationMenuButtons />}
        <NotificationMenuList
          emptyMessage={t('empty', { ns: 'notifications' })}
          isValid={hasNotifications}
          renderMenuItemsFunc={getNotifications}
          status={status}
        />
        {/* {hasMorePages && (
          <StyledNotificationViewMore>
            <Link
              color="secondary"
              component={RouterLink}
              to={routes.notifications.path}
              variant="body2">
              {t('seeAll', { context: 'female', ns: 'buttons' })}
            </Link>
          </StyledNotificationViewMore>
        )} */}
      </StyledNotificationMenu>
    </div>
  );
}

NotificationMenu.defaultProps = {
  anchorElement: null
};

NotificationMenu.propTypes = {
  anchorElement: PropTypes.instanceOf(Element)
};

export default NotificationMenu;
