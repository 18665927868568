import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import LegalFields from 'components/legal/LegalFields';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import defaultLegalFormValidations from 'common/validations/form/defaultLegal';
import routes from 'common/routes';
import requestStatus from 'common/constants/status';
import { updateLegalInitialValues } from 'common/form/initial-values/update';
import getDataToSave from 'common/form/dataToSave';
import { getFieldsToUpdate } from 'common/form/utils';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import AppBarContext from 'context/AppBarContext';
import legalSlice from 'store/modules/legal';

const requiredFields = ['created_at', 'body', 'title'];

function LegalEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['routes']);
  const { id } = useParams();
  const pageTitle = t('legal.edit', { ns: 'routes' });
  const [initialValues, setInitialValues] = useState({});
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const legal = useSelector((state) => state.legal.data.currentLegal);
  const status = useSelector((state) => state.legal.status);
  const uiLanguage = useSelector((state) => state.settings.config.language);

  useEffect(() => {
    dispatch(legalSlice.actions.find(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (legal && Object.keys(legal).length > 0 && legal.id === id) {
      const legalData = updateLegalInitialValues(legal);
      setInitialValues(legalData);
    }
  }, [legal]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onEdit = useCallback(
    (values) => {
      const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
      const dataToSave = getDataToSave(fieldsToUpdate, 'legal');
      dispatch(legalSlice.actions.update({ id, input: dataToSave }));
    },
    [dispatch, id, initialValues]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={initialValues}
        onSubmit={onEdit}
        validate={defaultLegalFormValidations}
        render={({ handleSubmit, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <LegalFields requiredFields={requiredFields} uiLanguage={uiLanguage} />
            <StyledButtonsContainer>
              <SecondaryButtonLoading
                component={RouterLink}
                disabled={status === requestStatus.loading}
                style={{ marginRight: '32px' }}
                label={t('cancel', { ns: 'buttons' })}
                to={routes.listLegal.path}
                type="button"
              />
              <PrimaryLoadingButton
                disabled={invalid || pristine || status === requestStatus.loading}
                loading={status === requestStatus.loading}
                label={t('legal.edit', { ns: 'buttons' })}
                type="submit"
              />
            </StyledButtonsContainer>
          </form>
        )}
      />
    </>
  );
}

export default LegalEdit;
