import AvailabilityCalendar from 'components/availability/AvailabilityCalendar';
import DefaultLayout from 'components/layout/DefaultLayout';
import routes from 'common/routes';
import { roles } from 'common/constants/options';

const availabilityRoutes = [
  {
    key: 'availability',
    exact: true,
    path: routes.availability.path,
    component: AvailabilityCalendar,
    layout: DefaultLayout,
    allowedRoles: [roles.trainer]
  }
];

export default availabilityRoutes;
