import { Box, styled } from '@mui/material';

const StyledFiltersContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray2.main,
  borderRadius: '8px',
  marginBottom: '30px',
  padding: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`
  }
}));

export default StyledFiltersContainer;
