import { Box, styled } from '@mui/material';

const StyledLargeSessionCardDecoration = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(180deg, #C08300 33.62%, #F8B231 99.74%)',
  borderBottomLeftRadius: '4px',
  borderTopLeftRadius: '4px',
  width: '10px',
  [theme.breakpoints.up('sm')]: {
    width: '18px'
  },
  [theme.breakpoints.up('lg')]: {
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px'
  }
}));

export default StyledLargeSessionCardDecoration;
