import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardBlock from 'components/shared/blocks/DashboardBlock';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import FinishedSessionsTable from 'components/shared/tables/FinishedSessionsTable';

function SessionsCompletedTable({ emptyMessage, itemsPerPage }) {
  const { t } = useTranslation(['sessions']);

  const finishedSessions = useSelector((state) => state.session.data.finished);
  const role = useSelector((state) => state.auth.user.role);
  const sessionStatus = useSelector((state) => state.session.status.dashboard);

  const isValid = finishedSessions.length > 0;

  return (
    <DashboardBlock
      title={t('sessionsCompleted', { ns: 'sessions' })}
      titleNote={t('lastNWeeks', { num: 2, ns: 'common' })}>
      <LoadingBlock
        emptyBlockProps={{
          message: emptyMessage
        }}
        isValid={isValid}
        skeletonProps={{
          rows: itemsPerPage
        }}
        status={sessionStatus}
        type="table">
        <FinishedSessionsTable sessions={finishedSessions} role={role} />
      </LoadingBlock>
    </DashboardBlock>
  );
}

SessionsCompletedTable.defaultProps = {
  itemsPerPage: 5
};

SessionsCompletedTable.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  itemsPerPage: PropTypes.number
};

export default SessionsCompletedTable;
