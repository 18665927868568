import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LayoutContext from 'context/LayoutContext';

function LayoutContextProvider({ children }) {
  const [compact, setCompact] = useState(false);
  const [noBgColor, setNoBgColor] = useState(false);

  const updateCompactLayout = useCallback((value) => {
    setCompact(value);
  }, []);

  const updateNoBgColor = useCallback((value) => {
    setNoBgColor(value);
  }, []);

  const layoutContextValue = useMemo(
    () => ({
      compactLayout: compact,
      setCompactLayout: updateCompactLayout,
      noBgColorLayout: noBgColor,
      setNoBgColorLayout: updateNoBgColor
    }),
    [compact, updateCompactLayout]
  );

  return <LayoutContext.Provider value={layoutContextValue}>{children}</LayoutContext.Provider>;
}

LayoutContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutContextProvider;
