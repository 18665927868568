import { styled, Typography } from '@mui/material';

const StyledAppbarTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 600,
  letterSpacing: theme.typography.pxToRem(0.2),
  lineHeight: 1,
  overflow: 'initial',
  whiteSpace: 'initial',
  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(26)
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.typography.pxToRem(42),
    fontWeight: 700,
    marginRight: '50px'
  }
}));

export default StyledAppbarTitle;
