import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List } from '@mui/material';
import { Assignment, EventAvailable, Group, Home, Payment, Person } from '@mui/icons-material';
import { CalendarCursor, ChartBoxOutline, TableSearch } from 'mdi-material-ui';
import MenuItem from 'components/shared/menus/MenuItem';
import routes from 'common/routes';

function TrainerDesktopMenu() {
  const { t } = useTranslation(['routes']);

  const miniDrawer = useSelector((state) => state.settings.config.miniDrawer);

  return (
    <List>
      <MenuItem
        icon={<Home />}
        iconOnly={miniDrawer}
        itemKey="home"
        label={t('home', { ns: 'routes' })}
        path={routes.trainerDashboard.path}
      />
      <MenuItem
        icon={<CalendarCursor />}
        iconOnly={miniDrawer}
        itemKey="calendar"
        label={t('session.calendar', { ns: 'routes' })}
        path={routes.calendar.path}
      />
      <MenuItem
        icon={<EventAvailable />}
        iconOnly={miniDrawer}
        itemKey="availability"
        label={t('availability.trainer', { ns: 'routes' })}
        path={routes.availability.path}
      />
      <MenuItem
        icon={<TableSearch />}
        iconOnly={miniDrawer}
        itemKey="sessions"
        label={t('session.list', { ns: 'routes' })}
        path={routes.listSession.path}
      />
      <MenuItem
        icon={<Group />}
        iconOnly={miniDrawer}
        itemKey="client"
        label={t('client.list', { ns: 'routes' })}
        path={routes.listClient.path}
      />
      <MenuItem
        icon={<ChartBoxOutline />}
        itemKey="performance"
        label={t('performance.list', { ns: 'routes' })}
        path={routes.listPerformance.path}
      />
      <MenuItem
        icon={<Payment />}
        iconOnly={miniDrawer}
        itemKey="payment"
        label={t('payment.payments', { ns: 'routes' })}
        path={routes.listTrainerPayments.trainer.path}
      />
      <MenuItem
        icon={<Person />}
        iconOnly={miniDrawer}
        itemKey="profile"
        label={t('profile', { ns: 'routes' })}
        path={routes.trainerProfile.path}
      />
      <MenuItem
        icon={<Assignment />}
        iconOnly={miniDrawer}
        itemKey="legal"
        label={t('legal.list', { ns: 'routes' })}
        path={routes.viewTC.path}
      />
    </List>
  );
}

export default TrainerDesktopMenu;
