import { hasValue, isValidDate } from 'common/validations/field';
import i18n from 'i18n';

const defaultNewsFormValidations = (values) => {
  const errors = {};

  if (!hasValue(values.body)) {
    errors.body = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!isValidDate(values.created_at)) {
    errors.created_at = i18n.t('date', { ns: 'validations' });
  }

  if (!hasValue(values.title)) {
    errors.title = i18n.t('emptyField', { ns: 'validations' });
  }

  return errors;
};

export default defaultNewsFormValidations;
