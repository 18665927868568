import { ListItemText, styled } from '@mui/material';
import { listItemTextClasses } from '@mui/material/ListItemText';

const StyledAvailabilityMenuText = styled(ListItemText)(({ theme }) => ({
  [`& .${listItemTextClasses.primary}`]: {
    fontSize: theme.typography.pxToRem(14)
  }
}));

export default StyledAvailabilityMenuText;
