import styledComponent from 'styled-components';

const StyledSessionDialogCreateForm = styledComponent.form`
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
`;

export default StyledSessionDialogCreateForm;
