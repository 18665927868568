import { Typography, styled } from '@mui/material';

const StyledAvailabilityText = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 0,
  fontSize: theme.typography.pxToRem(13),
  fontWeight: 500,
  textAlign: 'center'
}));

export default StyledAvailabilityText;
