import React from 'react';
import { Box, styled } from '@mui/material';

const StyledUserProfileColumn = styled(({ options, ...otherProps }) => <Box {...otherProps} />)(
  ({ options }) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: options?.horizontallyCentered ? 'center' : 'flex-start'
  })
);

export default StyledUserProfileColumn;
