import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import ClientProfileButtons from 'components/client/profile/ClientProfileButtons';
import ClientProfileHeader from 'components/client/profile/ClientProfileHeader';
import ClientProfileTabs from 'components/client/profile/ClientProfileTabs';
import PersonalTabPanel from 'components/client/profile/PersonalTabPanel';
import SecurityTabPanel from 'components/client/profile/SecurityTabPanel';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TabPanel from 'components/shared/tabs/TabPanel';
import requestStatus from 'common/constants/status';
import getDataToSave from 'common/form/dataToSave';
import { updateClientProfileInitialValues } from 'common/form/initial-values/update';
import { getFieldsToUpdate } from 'common/form/utils';
import validateEditClientProfileForm from 'common/validations/form/editClientProfile';
import AppBarContext from 'context/AppBarContext';
import authSlice from 'store/modules/auth';
import geoSlice from 'store/modules/geo';

function ClientProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['routes']);
  const pageTitle = t('profile', { ns: 'routes' });
  const [initialValues, setInitialValues] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const countries = useSelector((state) => state.geo.countries);
  const geoStatus = useSelector((state) => state.geo.status);
  const status = useSelector((state) => state.auth.status);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      const userData = updateClientProfileInitialValues(user);
      setInitialValues(userData);
    }
  }, [user]);

  useEffect(() => {
    if (geoStatus !== requestStatus.loading && countries.length <= 0) {
      dispatch(geoSlice.actions.getCountries());
    }
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(null);
  }, [setAppBarButton, setAppBarTitle]);

  const onTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const onUpdate = (values) => {
    const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
    const dataToSave = getDataToSave(fieldsToUpdate, 'user');
    dispatch(authSlice.actions.updateMe({ id: user.id, input: dataToSave }));
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={initialValues}
        onSubmit={onUpdate}
        validate={validateEditClientProfileForm}
        render={({ handleSubmit, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <ClientProfileHeader />
            <ClientProfileTabs currentTab={currentTab} onTabChange={onTabChange} />
            <TabPanel current={currentTab} index={0}>
              <PersonalTabPanel countries={countries} geoStatus={geoStatus} />
            </TabPanel>
            <TabPanel current={currentTab} index={1}>
              <SecurityTabPanel />
            </TabPanel>
            <ClientProfileButtons invalid={invalid} pristine={pristine} status={status} />
          </form>
        )}
      />
    </>
  );
}

export default ClientProfile;
