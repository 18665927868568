import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import requestStatus from 'common/constants/status';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import { isClient } from 'common/utils/user';

function SessionCreateDialogActions({ invalid, onDialogClose, pristine }) {
  const { t } = useTranslation(['buttons']);

  const availableSessions = useSelector((state) => state.auth.user?.available_sessions || 0);
  const role = useSelector((state) => state.auth.user.role);
  const sessionsInRange = useSelector((state) => state.session.sessionsInRange);
  const status = useSelector((state) => state.session.status.general);
  const userStatus = useSelector((state) => state.auth.status);

  const isClientUser = useMemo(() => isClient(role), [role]);

  const isDisabled = useMemo(
    () =>
      (isClientUser && availableSessions <= 0) ||
      invalid ||
      pristine ||
      status === requestStatus.loading ||
      userStatus === requestStatus.loading ||
      sessionsInRange.exists,
    [availableSessions, isClientUser, invalid, pristine, status, userStatus, sessionsInRange.exists]
  );

  return (
    <StyledButtonsContainer>
      <SecondaryButtonLoading
        disabled={status === requestStatus.loading}
        style={{ marginRight: '32px' }}
        label={t('cancel', { ns: 'buttons' })}
        type="button"
        onClick={onDialogClose}
      />
      <PrimaryLoadingButton
        disabled={isDisabled}
        loading={status === requestStatus.loading}
        label={t('session.create', { ns: 'buttons' })}
        type="submit"
      />
    </StyledButtonsContainer>
  );
}

SessionCreateDialogActions.propTypes = {
  invalid: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

export default SessionCreateDialogActions;
