import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLottie } from 'lottie-react';
import SingleCounter from 'components/shared/counters/single/SingleCounter';
import { defaultLottieOptions } from 'common/constants/general';

import calendarIcon from 'lottie/calendar_icon.json';

function SessionFinishedTodayCounter() {
  const { t } = useTranslation(['routes', 'sessions']);

  const toScheduleSessionsCounter = useSelector((state) => state.session.counters.toBeScheduled);

  const line1 = `${toScheduleSessionsCounter} ${t('session', {
    count: toScheduleSessionsCounter,
    ns: 'sessions'
  })}`;
  const line2 = t('toSchedule', { ns: 'sessions' });

  const lottieOptions = {
    ...defaultLottieOptions,
    animationData: calendarIcon
  };

  const { View } = useLottie(lottieOptions);

  return (
    <SingleCounter
      lottieIcon={View}
      title={t('session.calendar', { ns: 'routes' })}
      lines={[line1, line2]}
    />
  );
}

export default SessionFinishedTodayCounter;
