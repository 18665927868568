import { gql } from '@apollo/client';
import client from 'apollo/client';
import { sessionFindFields, sessionListFields } from 'common/constants/query-fields/session';
import { getSessionFilters } from 'common/filters/requestFilters';

/**
 * Counters sessions method
 * @param {Object} payload
 * @returns {Promise}
 */
const counters = (payload) => {
  const { fields, filters } = payload;
  const sessionFilters = getSessionFilters(filters);

  return client.query({
    query: gql`
      query sessionCounters {
        sessionCounters(
          ${sessionFilters.user_id}
          ${sessionFilters.start_between}
        ) {
          ${fields}
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Create session method
 * @param {Array} sessions
 * @returns {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createSession($input: CreateSessionInput!) {
        createSession(input: $input) {
          client_id {
            available_sessions
          }
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Find session method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query session($id: ID!) {
        session(id: $id) {
          ${sessionFindFields}
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * Force delete sessions method
 * @param  {string} ids
 * @return {Promise}
 */
const forceDelete = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deleteSessions($id: [ID!]!) {
        deleteSessions(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Get dashboard data method
 * @param  {string} ids
 * @return {Promise}
 */
const getDashboardData = (payload) => {
  const { fields, userId } = payload;

  return client.query({
    query: gql`
        query sessionDashboard($userId: Int!) {
          sessionDashboard(user_id: $userId) {
            ${fields}
          }
        }
      `,
    variables: { userId },
    fetchPolicy: 'network-only'
  });
};

/**
 * List sessions method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { pagination, filters, orderBy } = payload;
  const sessionFilters = getSessionFilters(filters);

  return client.query({
    query: gql`
      query sessions {
        sessions(
          ${sessionFilters.client_id}
          ${sessionFilters.happen_between}
          ${sessionFilters.pending_update}
          ${sessionFilters.session_type_id}
          ${sessionFilters.start_between}
          ${sessionFilters.status}
          ${sessionFilters.trainer_id}
          first: ${pagination.first}
          page: ${pagination.page}
          orderBy: {
            column: ${orderBy ? orderBy.column : 'START_DATE'},
            order: ${orderBy ? orderBy.direction : 'DESC'}
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${sessionListFields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * List all sessions method
 * @param {Object} payload
 * @returns {Promise}
 */
const listAll = (payload) => {
  const { fields, filters } = payload;
  const sessionFilters = getSessionFilters(filters);

  return client.query({
    query: gql`
      query sessionsAll {
        sessionsAll(
          ${sessionFilters.client_id}
          ${sessionFilters.happen_between}
          ${sessionFilters.pending_update}
          ${sessionFilters.session_type_id}
          ${sessionFilters.start_between}
          ${sessionFilters.status}
          ${sessionFilters.trainer_id}
          orderBy: {
            column: START_DATE,
            order: ASC
          }
        ) {
          ${fields}
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Update session method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateSession($id: ID!, $input: UpdateSessionInput!) {
        updateSession(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  counters,
  create,
  find,
  forceDelete,
  getDashboardData,
  list,
  listAll,
  update
};
