import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, FormSpy } from 'react-final-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ClientFields from 'components/client/ClientFields';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import validateEditClientForm from 'common/validations/form/editClient';
import { updateClientInitialValues } from 'common/form/initial-values/update';
import getDataToSave from 'common/form/dataToSave';
import { generatePassword, getFieldsToUpdate } from 'common/form/utils';
import { clientFindFields } from 'common/constants/query-fields/client';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import AppBarContext from 'context/AppBarContext';
import geoSlice from 'store/modules/geo';
import clientSlice from 'store/modules/client';

const requiredFields = [
  'first_name',
  'last_name',
  'email',
  'birthdate',
  'phone',
  'country',
  'state',
  'city',
  'ui_language',
  'plan_id'
];

function ClientEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const { id } = useParams();
  const pageTitle = t('client.edit', { ns: 'routes' });
  const [initialValues, setInitialValues] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const formRef = useRef(null);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const cities = useSelector((state) => state.geo.cities);
  const countries = useSelector((state) => state.geo.countries);
  const client = useSelector((state) => state.client.data.findClient);
  const geoStatus = useSelector((state) => state.geo.status);
  const planOptions = useSelector((state) => state.plan.planOptions);
  const planStatus = useSelector((state) => state.plan.status);
  const states = useSelector((state) => state.geo.states);
  const status = useSelector((state) => state.client.status.find);

  useEffect(() => {
    dispatch(clientSlice.actions.find({ fields: clientFindFields, id }));
  }, []);

  useEffect(() => {
    if (client && Object.keys(client).length > 0 && client.id === id) {
      const userData = updateClientInitialValues(client);
      setInitialValues(userData);
    }
  }, [client]);

  useEffect(() => {
    if (geoStatus !== requestStatus.loading && countries.length <= 0) {
      dispatch(geoSlice.actions.getCountries());
    }
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onEdit = useCallback(
    (values) => {
      const fieldsToUpdate = getFieldsToUpdate(values, initialValues);
      const dataToSave = getDataToSave(fieldsToUpdate, 'user');
      dispatch(clientSlice.actions.update({ id, input: dataToSave }));
    },
    [dispatch, id, initialValues]
  );

  const onFormChange = (formState) => {
    const { dirtyFields, values } = formState;
    const dirtyKeys = Object.keys(dirtyFields);

    // On country change, get the list of all country's states and reset the states and city fields
    if (values.country && values.country.label && values.country.label !== selectedCountry) {
      setSelectedCountry(values.country.label);
      dispatch(geoSlice.actions.getStates(values.country.label));

      if ('country' in dirtyKeys) {
        setSelectedState(null);

        if (values.state) formRef.current.change('state', null);
        if (values.city) formRef.current.change('city', null);
      }
    }

    // On state change, get the list of all state's cities and reset the city field
    if (values.state && values.state.label && values.state.label !== selectedState) {
      dispatch(
        geoSlice.actions.getCities({ country: values.country.label, state: values.state.label })
      );
      setSelectedState(values.state.label);

      if ('state' in dirtyKeys && values.city) formRef.current.change('city', null);
    }
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        initialValues={initialValues}
        mutators={{
          generatePassword
        }}
        onSubmit={onEdit}
        validate={validateEditClientForm}
        render={({ form, handleSubmit, invalid, pristine, values }) => {
          formRef.current = form;

          return (
            <form onSubmit={handleSubmit}>
              <ClientFields
                cities={cities}
                countries={countries}
                form={form}
                geoStatus={geoStatus}
                planOptions={planOptions}
                planStatus={planStatus}
                requiredFields={requiredFields}
                states={states}
                values={values}
              />
              <StyledButtonsContainer>
                <SecondaryButtonLoading
                  component={RouterLink}
                  disabled={status === requestStatus.loading}
                  style={{ marginRight: '32px' }}
                  label={t('cancel', { ns: 'buttons' })}
                  to={routes.listClient.path}
                  type="button"
                />
                <PrimaryLoadingButton
                  disabled={invalid || pristine || status === requestStatus.loading}
                  loading={status === requestStatus.loading}
                  label={t('client.edit', { ns: 'buttons' })}
                  type="submit"
                />
              </StyledButtonsContainer>
              <FormSpy onChange={onFormChange} subscription={{ dirtyFields: true, values: true }} />
            </form>
          );
        }}
      />
    </>
  );
}

export default ClientEdit;
