import { gql } from '@apollo/client';
import client from 'apollo/client';
import { getNewsFilters } from 'common/filters/requestFilters';

/**
 * Create news method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createNews($input: CreateNewsInput!) {
        createNews(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Find news method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query findNews($id: ID!) {
        findNews(id: $id) {
          id
          body
          created_at
          featured_image
          language
          mobile_featured_image
          title
          thumbnail
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * Force delete news method
 * @param  {string} ids
 * @return {Promise}
 */
const forceDelete = (ids) =>
  client.mutate({
    mutation: gql`
      mutation forceDeleteNews($id: [ID!]!) {
        forceDeleteNews(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * List news method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { fields, filters, pagination } = payload;
  const newsFilters = getNewsFilters(filters);

  return client.query({
    query: gql`
      query news {
        news(
          first: ${pagination.first}
          page: ${pagination.page}
          ${newsFilters.language}
          ${newsFilters.search}
          ${newsFilters.created_at}
          ${newsFilters.soft_delete}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${fields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Remove news method
 * @param  {Array} ids
 * @return {Promise}
 */
const remove = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deleteNews($id: [ID!]!) {
        deleteNews(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Restore news method
 * @param  {Array} ids
 * @return {Promise}
 */
const restore = (ids) =>
  client.mutate({
    mutation: gql`
      mutation restoreNews($id: [ID!]!) {
        restoreNews(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Update news method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateNews($id: ID!, $input: UpdateNewsInput!) {
        updateNews(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  create,
  find,
  forceDelete,
  list,
  remove,
  restore,
  update
};
