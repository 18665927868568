import _capitalize from 'lodash/capitalize';

/**
 * Adds trailing zeros to a number's decimal part up to the specified number of decimal places.
 * If the number already has decimals, it ensures at least the specified number of decimal places,
 * but it won't truncate if the number has more decimals than requested.
 *
 * @param  {number|string}  num    The number to be formatted.
 * @param  {number}         zeros  The minimum number of decimal places to ensure.
 *
 * @returns  {string}  The number as a string with the specified minimum number of decimal places.
 *
 * @example
 * // returns '123.00'
 * addDecimalZeroes(123, 2);
 *
 * @example
 * // returns '123.456'
 * addDecimalZeroes(123.456, 2);
 *
 * @example
 * // returns '123.40'
 * addDecimalZeroes(123.4, 2);
 */
export const addDecimalZeroes = (num, zeros) => {
  const decimalPlaces = num.toString().split('.')[1]?.length || 0;
  return Number(num).toFixed(Math.max(decimalPlaces, zeros));
};

/**
 * Converts a label with underscores to a human-readable format and capitalizes the first letter.
 * This function replaces all underscores in the given label with spaces and capitalizes the first letter of the resulting string.
 *
 * @param  {string}  label  The label string to be cleaned and formatted.
 *
 * @returns  {string}  The cleaned label with spaces instead of underscores and the first letter capitalized.
 *
 * @example
 * // returns 'Example label'
 * cleanLabel('example_label');
 *
 * @example
 * // returns 'My new label'
 * cleanLabel('my_new_label');
 */
export const cleanLabel = (label) => _capitalize(label.replace(/_/gi, ' '));

/**
 * Formats a numeric value into a currency string in US Dollar format.
 * Trims trailing zeros after the decimal point, effectively removing ".00" from whole numbers.
 *
 * @param  {number}  value  The numeric value to be formatted as currency.
 *
 * @returns  {string}  The value formatted in US Dollar currency format, without trailing zeros.
 *
 * @example
 * // returns '$1,234'
 * formatCurrency(1234);
 *
 * @example
 * // returns '$1,234.56'
 * formatCurrency(1234.56);
 *
 * @example
 * // returns '$1,234.50'
 * formatCurrency(1234.50);
 */
export const formatCurrency = (value) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
    .format(value)
    .replace(/(\.|,)00$/g, '');

/**
 * Formats a number with a suffix representing its magnitude (thousands, millions, billions).
 *
 * @param  {number}  num            The number to be formatted.
 * @param  {string}  [prefix='']    A prefix to be added before the number (optional).
 * @param  {number}  [precision=1]  The number of decimal places to include in the formatted number (optional).
 *
 * @returns  {string}  The formatted number with the appropriate suffix.
 * For numbers less than a thousand, no suffix is added.
 * Removes trailing zeros after the decimal point up to the specified precision.
 *
 * @example
 * // returns '3.5k'
 * formatWithNumberSuffix(3500);
 *
 * @example
 * // returns '$3.5k'
 * formatWithNumberSuffix(3500, '$');
 *
 * @example
 * // returns '1.235m'
 * formatWithNumberSuffix(1234567, '', 3);
 */
export const formatWithNumberSuffix = (num, prefix = '', precision = 1) => {
  const absNum = Math.abs(num);
  const thousand = 1.0e3;
  const million = 1.0e6;
  const billion = 1.0e9;
  let divisor = 1;
  let suffix = '';

  if (absNum >= billion) {
    divisor = billion;
    suffix = 'b';
  } else if (absNum >= million) {
    divisor = million;
    suffix = 'm';
  } else if (absNum >= thousand) {
    divisor = thousand;
    suffix = 'k';
  }

  return `${prefix}${(absNum / divisor)
    .toFixed(precision)
    .replace(new RegExp(`\\.0{1,${precision}}$`), '')}${suffix}`;
};

/**
 * Formats a given text string into a lowercase string with spaces replaced by underscores.
 * This is often useful for creating key-like strings from human-readable text.
 *
 * @param  {string}  text  The text to be formatted.
 *
 * @returns  {string}  The formatted string in lowercase with underscores instead of spaces.
 *
 * @example
 * // returns 'hello_world'
 * keyFormat('Hello World');
 *
 * @example
 * // returns 'example_text'
 * keyFormat('Example Text');
 */
export const keyFormat = (text) => text.toLowerCase().replace(/\s/gi, '_');
