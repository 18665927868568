import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enUS, es } from 'date-fns/locale';
import { Box } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import AppBarButtonLink from 'components/shared/buttons/AppBarButtonLink';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import PerformanceExpandableRow from 'components/shared/table-rows/PerformanceExpandableRow';
import PerformanceFilters from 'components/performance/PerformanceFilters';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import { performanceListFields } from 'common/constants/query-fields/performance';
import routes from 'common/routes';
import { getPerformanceColumns } from 'common/table/columns/performance';
import { getPerformanceData } from 'common/table/data/performance';
import { defaultTableOptions, getSelectToolBar, tableLocalization } from 'common/table/utils';
import { isAdmin } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import performanceSlice from 'store/modules/performance';

function PerformanceList() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['buttons', 'labels', 'routes', 'tables']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const pageTitle = t('performance.list', { ns: 'routes' });
  const emptyMessage = t('empty', { type: pageTitle.toLowerCase(), ns: 'tables' });
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const filters = useSelector((state) => state.performance.filters);
  const pagination = useSelector((state) => state.performance.pagination);
  const paginatorInfo = useSelector((state) => state.performance.paginatorInfo);
  const performances = useSelector((state) => state.performance.data.performances);
  const refresh = useSelector((state) => state.performance.refresh);
  const role = useSelector((state) => state.auth.user.role);
  const status = useSelector((state) => state.performance.status.general);

  const isAdminUser = isAdmin(role);

  const listPerformances = useCallback(() => {
    dispatch(
      performanceSlice.actions.list({
        fields: performanceListFields,
        filters,
        pagination
      })
    );
  }, [dispatch, filters, performanceListFields, pagination]);

  useEffect(() => {
    listPerformances();
  }, [listPerformances, refresh]);

  useEffect(() => {
    setAppBarButton(
      <AppBarButtonLink label={t('add', { ns: 'buttons' })} path={routes.createPerformance.path} />
    );
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const tableData = getPerformanceData(performances, dateLocale);
  const columns = getPerformanceColumns({ data: tableData, translateFunc: t });

  const onChangePage = (page) => {
    dispatch(performanceSlice.actions.updatePagination({ page: page + 1 }));
  };

  const onDelete = (ids) => {
    dispatch(performanceSlice.actions.remove(ids));
  };

  const onRestore = (ids) => {
    dispatch(performanceSlice.actions.restore(ids));
  };

  const options = {
    ...defaultTableOptions,
    count: paginatorInfo.total,
    customToolbarSelect: (selectedRows, displayData) =>
      getSelectToolBar({
        filters,
        rows: selectedRows,
        data: displayData,
        onDelete,
        onRestore
      }),
    expandableRows: isAdminUser,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    onChangePage,
    page: pagination.page - 1,
    renderExpandableRow: (rowData, rowMeta) => (
      <PerformanceExpandableRow data={rowData} meta={rowMeta} tableData={tableData} />
    ),
    rowsPerPage: pagination.first,
    selectableRows: 'multiple',
    selectableRowsHeader: true,
    textLabels: tableLocalization(pageTitle.toLowerCase(), t)
  };

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <PerformanceFilters />
      <Box
        sx={{
          position: 'relative'
        }}>
        <LoadingBlock
          emptyBlockProps={{ message: emptyMessage }}
          isValid={performances.length > 0}
          skeletonProps={{ rows: 5 }}
          status={status}
          type="table">
          <MUIDataTable
            className="performance"
            columns={columns}
            data={tableData}
            options={options}
          />
        </LoadingBlock>
      </Box>
    </>
  );
}

export default PerformanceList;
