import { Typography, styled } from '@mui/material';

const StyledRangeColumn = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  margin: 0,
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(14)
  }
}));

export default StyledRangeColumn;
