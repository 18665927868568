import React from 'react';
import { Drawer, styled } from '@mui/material';
import { drawerClasses } from '@mui/material/Drawer';

const StyledDesktopDrawer = styled(({ drawerWidth, ...otherProps }) => <Drawer {...otherProps} />)(
  ({ drawerWidth, theme }) => ({
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    },
    [`& .${drawerClasses.paper}`]: {
      backgroundColor: theme.palette.gray3.main,
      backgroundImage: 'initial',
      borderRight: 0,
      boxSizing: 'border-box',
      transition: `width ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms`,
      width: drawerWidth
    }
  })
);

export default StyledDesktopDrawer;
