import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Notifier from 'components/shared/Notifier';
import StyledLoginLayoutBackground from 'common/theme/layouts/login/StyledLoginLayoutBackground';
import StyledLoginLayoutOverlay from 'common/theme/layouts/login/StyledLoginLayoutOverlay';

import bgImage from 'images/login.webp';
import bgImageMobile from 'images/login_mobile.webp';

function LoginLayout({ children }) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledLoginLayoutBackground bgImage={isSmall ? bgImageMobile : bgImage}>
      <StyledLoginLayoutOverlay />
      {children}
      <Notifier />
    </StyledLoginLayoutBackground>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default LoginLayout;
