import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import OverviewProfileBasicDetails from 'components/shared/overview-profile/OverviewProfileBasicDetails';
import GoBackButton from 'components/shared/buttons/GoBackButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import TrainerBankingDetails from 'components/trainer/overview-profile/TrainerBankingDetails';
import TrainerMedicalInfo from 'components/trainer/overview-profile/TrainerMedicalInfo';
import TrainerObservations from 'components/trainer/overview-profile/TrainerObservations';
import TrainerPersonalInfo from 'components/trainer/overview-profile/TrainerPersonalInfo';
import TrainerQualifications from 'components/trainer/overview-profile/TrainerQualifications';
import TrainerWorkExperience from 'components/trainer/overview-profile/TrainerWorkExperience';
import { trainerViewFields } from 'common/constants/query-fields/trainer';
import requestStatus from 'common/constants/status';
import { isAdmin } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import trainerSlice from 'store/modules/trainer';

function TrainerOverviewProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['labels', 'routes']);
  const pageTitle = t('trainer.view', { ns: 'routes' });
  const { id } = useParams();
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const role = useSelector((state) => state.auth.user.role);
  const status = useSelector((state) => state.trainer.status.view);
  const trainer = useSelector((state) => state.trainer.data.viewTrainer);

  const isAdminUser = isAdmin(role);
  const isLoading = status === requestStatus.loading || status === requestStatus.idle || !trainer;

  useEffect(() => {
    dispatch(trainerSlice.actions.view({ fields: trainerViewFields, id }));
  }, []);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(null);
  }, [setAppBarButton, setAppBarTitle]);

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <GoBackButton />
      <OverviewProfileBasicDetails user={trainer} loading={isLoading} />
      <Stack spacing={4}>
        <TrainerPersonalInfo trainer={trainer} loading={isLoading} />
        {isAdminUser && <TrainerBankingDetails trainer={trainer} loading={isLoading} />}
        <TrainerQualifications trainer={trainer} loading={isLoading} />
        <TrainerWorkExperience trainer={trainer} loading={isLoading} />
        {isAdminUser && <TrainerMedicalInfo trainer={trainer} loading={isLoading} />}
        {isAdminUser && trainer && trainer.observations && (
          <TrainerObservations trainer={trainer} loading={isLoading} />
        )}
      </Stack>
    </>
  );
}

export default TrainerOverviewProfile;
