import {
  alphaSpace,
  email,
  hasValue,
  isText,
  isValidDate,
  lengthBetween,
  minLength,
  password
} from 'common/validations/field';
import i18n from 'i18n';

const defaultClientFormValidations = (values) => {
  const errors = {};

  if (!hasValue(values.first_name)) {
    errors.first_name = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!alphaSpace(values.first_name)) {
    errors.first_name = i18n.t('letterSpace', { ns: 'validations' });
  }

  if (!minLength(values.first_name, 2)) {
    errors.first_name = i18n.t('minLength', { length: '2', ns: 'validations' });
  }

  if (!hasValue(values.last_name)) {
    errors.last_name = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!alphaSpace(values.last_name)) {
    errors.last_name = i18n.t('letterSpace', { ns: 'validations' });
  }

  if (!minLength(values.last_name, 2)) {
    errors.last_name = i18n.t('minLength', { length: '2', ns: 'validations' });
  }

  if (!email(values.email)) {
    errors.email = i18n.t('email', { ns: 'validations' });
  }

  if (!isValidDate(values.birthdate)) {
    errors.birthdate = i18n.t('date', { ns: 'validations' });
  }

  if (hasValue(values.phone) && !lengthBetween(values.phone, 7, 10)) {
    errors.phone = i18n.t('lengthBetweenNumber', { min: '7', max: '10', ns: 'validations' });
  }

  if (!hasValue(values.country)) {
    errors.country = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!hasValue(values.state)) {
    errors.state = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!hasValue(values.city)) {
    errors.city = i18n.t('emptyField', { ns: 'validations' });
  }

  if (hasValue(values.password) && !password(values.password)) {
    errors.password = i18n.t('password', { ns: 'validations' });
  }

  if (hasValue(values.observations) && !isText(values.observations)) {
    errors.observations = i18n.t('text', { ns: 'validations' });
  }

  return errors;
};

export default defaultClientFormValidations;
