import { Button, styled } from '@mui/material';

const StyledMoreProfileLogout = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-start',
  fontSize: theme.typography.pxToRem(14),
  minWidth: 'auto',
  padding: 0,
  textDecoration: 'underline',
  textTransform: 'capitalize'
}));

export default StyledMoreProfileLogout;
