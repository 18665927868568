import { Typography, styled } from '@mui/material';

const StyledEmptyResultBlockText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(16)
  }
}));

export default StyledEmptyResultBlockText;
