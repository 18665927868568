import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import ClientPaymentFields from 'components/client-payment/ClientPaymentFields';
import PrimaryLoadingButton from 'components/shared/buttons/PrimaryLoadingButton';
import SecondaryButtonLoading from 'components/shared/buttons/SecondaryLoadingButton';
import SEOPageTitle from 'components/shared/SEOPageTitle';
import defaultPaymentFormValidations from 'common/validations/form/defaultPayment';
import { createClientPaymentInitialValues } from 'common/form/initial-values/create';
import { createClientPaymentSearchFields } from 'common/constants/query-fields/client';
import getDataToSave from 'common/form/dataToSave';
import requestStatus from 'common/constants/status';
import routes from 'common/routes';
import StyledButtonsContainer from 'common/theme/containers/StyledButtonsContainer';
import { clientPaymentFormDecorators } from 'common/utils/payment';
import { handleClientIdChange } from 'common/utils/user';
import AppBarContext from 'context/AppBarContext';
import clientPaymentSlice from 'store/modules/clientPayment';

const requiredFields = [
  'created_at',
  'end_date',
  'plan_name',
  'price',
  'sessions_paid',
  'start_date',
  'user_id'
];

const calculator = clientPaymentFormDecorators;

function ClientPaymentCreate() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['buttons', 'routes']);
  const pageTitle = t('payment.client.create', { ns: 'routes' });
  const formRef = useRef(null);
  const { setAppBarButton, setAppBarTitle } = useContext(AppBarContext);

  const clientOptions = useSelector((state) => state.client.data.search);
  const searchStatus = useSelector((state) => state.client.status.search);
  const status = useSelector((state) => state.clientPayment.status.general);

  useEffect(() => {
    if (status === requestStatus.success) {
      formRef.current.restart();
    }
  }, [status]);

  useEffect(() => {
    setAppBarButton(null);
    setAppBarTitle(pageTitle);
  }, [pageTitle, setAppBarButton, setAppBarTitle]);

  const onClientIdChange = handleClientIdChange(dispatch, createClientPaymentSearchFields);

  const onCreate = useCallback(
    (values) => {
      // eslint-disable-next-line camelcase
      const { plan_name, ...cleanValues } = values;
      const dataToSave = getDataToSave(cleanValues, 'payment');
      dispatch(clientPaymentSlice.actions.create(dataToSave));
    },
    [dispatch]
  );

  return (
    <>
      <SEOPageTitle title={pageTitle} />
      <Form
        decorators={[calculator]}
        initialValues={createClientPaymentInitialValues}
        onSubmit={onCreate}
        validate={defaultPaymentFormValidations}
        render={({ form, handleSubmit, invalid, pristine }) => {
          formRef.current = form;

          return (
            <form onSubmit={handleSubmit}>
              <ClientPaymentFields
                clientOptions={clientOptions}
                onClientIdChange={onClientIdChange}
                requiredFields={requiredFields}
                searchStatus={searchStatus}
              />
              <StyledButtonsContainer>
                <SecondaryButtonLoading
                  component={RouterLink}
                  disabled={status === requestStatus.loading}
                  style={{ marginRight: '32px' }}
                  label={t('cancel', { ns: 'buttons' })}
                  to={routes.listClientPayments.administrator.path}
                  type="button"
                />
                <PrimaryLoadingButton
                  disabled={invalid || pristine || status === requestStatus.loading}
                  loading={status === requestStatus.loading}
                  label={t('payment.client.create', { ns: 'buttons' })}
                  type="submit"
                />
              </StyledButtonsContainer>
            </form>
          );
        }}
      />
    </>
  );
}

export default ClientPaymentCreate;
