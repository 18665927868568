import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';
import LoadingBlock from 'components/shared/blocks/LoadingBlock';
import TopRankedChart from 'components/shared/charts/performance/top-ranked/TopRankedChart';
import TopRankedPerformer from 'components/shared/charts/performance/top-ranked/TopRankedPerformer';
import { gender as genderConst } from 'common/constants/options';

function TopRankedChartContainer({ data, gender, gradient, status, test }) {
  const { t } = useTranslation(['performances']);
  const [performer, setPerformer] = useState(null);
  const showChart = data.length > 0;

  useEffect(() => {
    if (data.length > 0) {
      setPerformer({
        label: data[0].total,
        place: data[0].place,
        user: data[0].user
      });
    } else {
      setPerformer(null);
    }
  }, [data]);

  const onBarClick = useCallback((barData) => {
    setPerformer(barData);
  }, []);

  return (
    <Grid
      xs={12}
      sm={6}
      sx={{
        borderRight:
          gender === genderConst.male ? (theme) => `2px solid ${theme.palette.gray0.main}` : 0
      }}>
      <LoadingBlock
        emptyBlockProps={{
          imageType: gender === genderConst.male ? 'man' : 'woman'
        }}
        isValid={showChart}
        skeletonProps={{ height: 400 }}
        status={status}>
        <>
          <TopRankedChart data={data} gradient={gradient} onClick={onBarClick} />
          <TopRankedPerformer customStyles={{ marginTop: 4 }} data={performer} test={test} />
        </>
      </LoadingBlock>
      <Typography align="center" sx={{ marginTop: 4 }}>
        {t('topNGender', {
          num: 10,
          gender: t(`gender.${gender.toLowerCase()}`, { count: 2, ns: 'options' }),
          ns: 'performances'
        })}
      </Typography>
    </Grid>
  );
}

const chartPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    place: PropTypes.number.isRequired,
    total: PropTypes.string.isRequired,
    totalRaw: PropTypes.number.isRequired,
    user: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      photo: PropTypes.string
    }).isRequired
  })
);

TopRankedChartContainer.propTypes = {
  data: chartPropTypes.isRequired,
  gender: PropTypes.string.isRequired,
  gradient: PropTypes.shape({
    from: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }).isRequired,
  status: PropTypes.string.isRequired,
  test: PropTypes.string.isRequired
};

export default TopRankedChartContainer;
