import { buttonClasses } from '@mui/material/Button';
import { dialogActionsClasses } from '@mui/material/DialogActions';
import { pickersDayClasses } from '@mui/x-date-pickers/PickersDay';

const datePickerCalendarStyles = (theme) => ({
  [`& .${pickersDayClasses.root}`]: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.black.main,
      fontWeight: 800
    },
    [`&.${pickersDayClasses.selected}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.black.main,
      fontWeight: 800,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  '& .MuiPickersYear-yearButton': {
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.black.main,
      fontWeight: 800,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '&.Mui-disabled': {
      color: theme.palette.text.disabled
    }
  },
  [`& .${dialogActionsClasses.root}`]: {
    [`& .${buttonClasses.text}`]: {
      color: theme.palette.secondary.main
    }
  }
});

export default datePickerCalendarStyles;
