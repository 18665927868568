import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Divider, Unstable_Grid2 as Grid } from '@mui/material';
import FileUploadAdapter from 'components/shared/field-adapters/FileUploadAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import { bankOptions, getBankAccountTypeOptions } from 'common/constants/options';
import StyledProfileTabPanelTitle from 'common/theme/profile/StyledProfileTabPanelTitle';

function PaymentTabPanel() {
  const { t } = useTranslation(['labels']);

  return (
    <>
      <StyledProfileTabPanelTitle component="h4">
        {t('paymentInformation', { ns: 'labels' })}
      </StyledProfileTabPanelTitle>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} sm={6}>
          <Field
            id="bank"
            name="bank"
            component={SelectFieldAdapter}
            options={bankOptions}
            label={t('bank', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="bank_account_type"
            name="bank_account_type"
            component={SelectFieldAdapter}
            options={getBankAccountTypeOptions(t)}
            label={t('bankAccountType', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="bank_account_number"
            name="bank_account_number"
            component={TextFieldAdapter}
            label={t('bankAccountNumber', { ns: 'labels' })}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="bank_account_certificate"
            name="bank_account_certificate"
            component={FileUploadAdapter}
            label={t('bankAccountCertificate', { ns: 'labels' })}
            allowedFileTypes={{
              'application/pdf': ['.pdf']
            }}
            required
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <Field
            id="identification_document"
            name="identification_document"
            component={FileUploadAdapter}
            label={t('identificationDocument', { ns: 'labels' })}
            allowedFileTypes={{
              'application/pdf': ['.pdf']
            }}
            required
          />
        </Grid>
      </Grid>
    </>
  );
}

export default PaymentTabPanel;
