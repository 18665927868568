import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Divider, InputAdornment, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TextAreaFieldAdapter from 'components/shared/field-adapters/TextAreaFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import { currencyOptions } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import { isRequiredField } from 'common/utils/general';
import useForms from 'hooks/useForms';

function TrainerPaymentFields({
  isFormDisabled,
  onTrainerIdChange,
  requiredFields,
  searchStatus,
  trainerOptions
}) {
  const { t } = useTranslation(['labels']);
  const { clearTrainerSearchResults } = useForms();

  return (
    <>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <Field
            id="user_id"
            name="user_id"
            component={TypeaheadFieldAdapter}
            fullWidth
            loading={searchStatus === requestStatus.loading}
            isMultiple={false}
            onClose={clearTrainerSearchResults}
            onInputChange={onTrainerIdChange}
            options={trainerOptions}
            renderInputProps={{
              label: t('trainer', { count: 1, ns: 'labels' }),
              required: isRequiredField('user_id', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="start_date"
            name="start_date"
            component={DatePickerFieldAdapter}
            label={t('startDate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('start_date', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="end_date"
            name="end_date"
            component={DatePickerFieldAdapter}
            label={t('endDate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('end_date', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="created_at"
            name="created_at"
            component={DatePickerFieldAdapter}
            label={t('createdAt', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('created_at', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="currency"
            name="currency"
            component={SelectFieldAdapter}
            options={currencyOptions}
            label={t('currency', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="price"
            name="price"
            component={TextFieldAdapter}
            label={t('price', { ns: 'labels' })}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            fullWidth
            disabled={isFormDisabled}
            required={isRequiredField('price', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <Field
            id="sessions_completed"
            name="sessions_completed"
            component={TextFieldAdapter}
            label={t('sessionsCompleted', { ns: 'labels' })}
            fullWidth
            disabled={isFormDisabled}
            required={isRequiredField('sessions_completed', requiredFields)}
          />
        </Grid>
        <Grid xs={12} sm={12}>
          <Field
            id="invoice_url"
            name="invoice_url"
            component={TextFieldAdapter}
            label={t('invoice', { ns: 'labels' })}
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('observations', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container>
        <Grid xs={12}>
          <Field
            id="observations"
            name="observations"
            component={TextAreaFieldAdapter}
            label={t('observations', { ns: 'labels' })}
            helperText={t('text', { ns: 'validations' })}
            multiline
            minRows={5}
            type="text"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

TrainerPaymentFields.defaultProps = {
  isFormDisabled: false
};

TrainerPaymentFields.propTypes = {
  isFormDisabled: PropTypes.bool,
  onTrainerIdChange: PropTypes.func.isRequired,
  requiredFields: PropTypes.array.isRequired,
  searchStatus: PropTypes.string.isRequired,
  trainerOptions: PropTypes.array.isRequired
};

export default TrainerPaymentFields;
