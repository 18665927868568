import { ListItemButton, styled } from '@mui/material';
import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { listItemTextClasses } from '@mui/material/ListItemText';

const StyledMainMenuNestedButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: '10px',
  paddingLeft: theme.spacing(4),
  position: 'relative',
  '&::before': {
    background:
      'linear-gradient(90deg, rgba(27, 35, 191, 0.9) 0%, #273BD7 85.18%, #273BD7 99.99%, rgba(255, 255, 255, 0) 100%)',
    borderRadius: '10px',
    content: '""',
    display: 'block',
    height: '100%',
    left: 0,
    opacity: 0,
    position: 'absolute',
    top: 0,
    transition: `opacity ${theme.transitions.easing.easeInOut} ${theme.transitions.duration.short}ms`,
    width: '100%'
  },
  [`& .${listItemTextClasses.root}`]: {
    zIndex: 1
  },
  [`& .${listItemIconClasses.root}`]: {
    zIndex: 1
  },
  [`&:hover, &.${listItemButtonClasses.selected}`]: {
    backgroundColor: 'transparent',
    '&::before': {
      opacity: 1
    },
    [`& .${listItemTextClasses.root}`]: {
      color: theme.palette.common.white
    },
    [`& .${listItemIconClasses.root}`]: {
      color: theme.palette.common.white
    }
  }
}));

export default StyledMainMenuNestedButton;
