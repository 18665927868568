import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Unstable_Grid2 as Grid } from '@mui/material';
import OverviewProfileDetail from 'components/shared/overview-profile/OverviewProfileDetail';
import StyledOverviewProfileDivider from 'common/theme/overview-profile/StyledOverviewProfileDivider';
import StyledOverviewProfileSubtitle from 'common/theme/overview-profile/StyledOverviewProfileSubtitle';
import { downloadFile } from 'common/utils/general';
import createTemporaryUserDocumentUrl from 'http/user';

function TrainerBankingDetails({ trainer, loading }) {
  const { t } = useTranslation(['labels']);

  const handleDownload = async (id, documentType, filename) => {
    const signedUrl = await createTemporaryUserDocumentUrl(id, documentType);
    downloadFile(signedUrl, filename);
  };

  const downloadBankAccountCertificate = () => {
    handleDownload(
      trainer.id,
      'bank_account_certificate',
      t('bankAccountCertificate', { ns: 'labels' })
    );
  };

  const downloadIdentificationDocument = () => {
    handleDownload(
      trainer.id,
      'identification_document',
      t('identificationDocument', { ns: 'labels' })
    );
  };

  return (
    <div>
      <StyledOverviewProfileSubtitle component="h4">
        {t('paymentInformation', { ns: 'labels' })}
      </StyledOverviewProfileSubtitle>
      <StyledOverviewProfileDivider />
      <Grid container spacing={{ xs: 2, md: 4 }}>
        <Grid xs={12} md={6} lg={4}>
          <OverviewProfileDetail
            label={t('identificationNumber', { ns: 'labels' })}
            value={trainer?.identification_number}
            loading={loading}
          />
        </Grid>
        {trainer?.identification_document && (
          <Grid xs={12} md={6} lg={4}>
            <OverviewProfileDetail
              label={t('identificationDocument', { ns: 'labels' })}
              value={
                <Button
                  variant="text"
                  color="secondary"
                  onClick={downloadIdentificationDocument}
                  size="small">
                  {t('download', { ns: 'common' })}
                </Button>
              }
              loading={loading}
            />
          </Grid>
        )}
        <Grid xs={12} md={6} lg={4}>
          <OverviewProfileDetail label="bank" value={trainer?.bank} loading={loading} />
        </Grid>
        <Grid xs={12} md={6} lg={4}>
          <OverviewProfileDetail
            label={t('bankAccountType', { ns: 'labels' })}
            value={t(`bankAccountType.${trainer?.bank_account_type?.toLowerCase()}`, {
              ns: 'options'
            })}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} md={6} lg={4}>
          <OverviewProfileDetail
            label={t('bankAccountNumber', { ns: 'labels' })}
            value={trainer?.bank_account_number}
            loading={loading}
          />
        </Grid>
        {trainer?.bank_account_certificate && (
          <Grid xs={12} md={6} lg={4}>
            <OverviewProfileDetail
              label={t('bankAccountCertificate', { ns: 'labels' })}
              value={
                <Button
                  variant="text"
                  color="secondary"
                  onClick={downloadBankAccountCertificate}
                  size="small">
                  {t('download', { ns: 'common' })}
                </Button>
              }
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

TrainerBankingDetails.defaultProps = {
  trainer: null
};

TrainerBankingDetails.propTypes = {
  trainer: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default TrainerBankingDetails;
