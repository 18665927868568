/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import { AppBar as MuiAppBar, styled } from '@mui/material';

const StyledAppbar = styled(
  forwardRef(({ drawerWidth, hasScrolled, ...other }, ref) => <MuiAppBar ref={ref} {...other} />)
)(({ drawerWidth, hasScrolled, theme }) => ({
  backgroundColor: theme.palette.gray2.main,
  backgroundImage: 'initial',
  boxShadow: 'none',
  padding: 0,
  top: 0,
  transition: `margin-left ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut} 0ms`,
  '&::after': {
    [theme.breakpoints.up('lg')]: {
      bottom: 0,
      backgroundColor: theme.palette.secondary.main,
      content: '""',
      height: '1px',
      left: 0,
      opacity: hasScrolled ? 1 : 0,
      position: 'absolute',
      transition: `opacity ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
      width: '100%'
    }
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: `${drawerWidth}px`,
    paddingTop: '30px',
    paddingBottom: theme.spacing(3),
    width: `calc(100% - ${drawerWidth}px)`
  }
}));

export default StyledAppbar;
