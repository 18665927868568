import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledPaginatedList from 'common/theme/lists/StyledPaginatedList';

function PaginatedList({ items, itemsPerPage, renderItem }) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const size = isSmall ? 'medium' : 'small';

  const [page, setPage] = useState(1);
  const count = Math.ceil(items.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;
  const itemsToShow = items.slice(startIndex, endIndex);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <StyledPaginatedList>
      <Stack spacing={2}>{itemsToShow.map(renderItem)}</Stack>
      <Pagination count={count} page={page} onChange={handleChange} size={size} />
    </StyledPaginatedList>
  );
}

PaginatedList.defaultProps = {
  itemsPerPage: 10
};

PaginatedList.propTypes = {
  items: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number,
  renderItem: PropTypes.func.isRequired
};

export default PaginatedList;
