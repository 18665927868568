import { TableCell, styled } from '@mui/material';

const StyledTableRowCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1.5),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2)
  }
}));

export default StyledTableRowCell;
