import { ListItemText, styled } from '@mui/material';
import { typographyClasses } from '@mui/material/Typography';

const StyledMenuListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.menuItem.main,
  transition: 'color .3s ease-in-out',
  [`& .${typographyClasses.root}`]: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700
  }
}));

export default StyledMenuListItemText;
