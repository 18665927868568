import { takeLatest, put } from 'redux-saga/effects';
import progressSlice from 'store/modules/progress';
import uiSlice from 'store/modules/ui';
import progress from 'api/graphql/progress';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import createProgressList from 'store/sagas/hof/progress';
import i18n from 'i18n';

/**
 * Create progress saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'progress-create-notification-success',
      variant: 'success',
      message: i18n.t('progress.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield progress.create(payload);
    yield put(progressSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(progressSlice.actions.createError(message));
  }
}

/**
 * Find progress saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield progress.find(action.payload);
    const {
      data: { progress: currentProgress }
    } = response;

    yield put(progressSlice.actions.findSuccess(currentProgress));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(progressSlice.actions.findError(message));
  }
}

/**
 * Remove progress saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deleteProgress: removedProgress }
    } = yield progress.remove(action.payload);

    const snack = createSnack({
      key: 'remove-progress-notification-success',
      variant: 'success',
      message: i18n.t('progress.delete', { count: removedProgress.length, ns: 'notifications' })
    });

    yield put(progressSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(progressSlice.actions.removeError(message));
  }
}

/**
 * Restore progress saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restoreProgress: restoredProgress }
    } = yield progress.restore(action.payload);

    const snack = createSnack({
      key: 'restore-progress-notification-success',
      variant: 'success',
      message: i18n.t('progress.restore', { count: restoredProgress.length, ns: 'notifications' })
    });

    yield put(progressSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(progressSlice.actions.restoreError(message));
  }
}

/**
 * Update progress saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-progress-notification-success',
      variant: 'success',
      message: i18n.t('progress.update', { ns: 'notifications' })
    });

    yield progress.update(action.payload);

    yield put(progressSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(progressSlice.actions.updateError(message));
  }
}

/**
 * Watch progress
 *
 * @export
 */
export default function* watchProgress() {
  yield takeLatest(progressSlice.actions.create, create);
  yield takeLatest(progressSlice.actions.find, find);
  yield takeLatest(
    progressSlice.actions.list,
    createProgressList(progressSlice.actions.listSuccess, progressSlice.actions.listError)
  );
  yield takeLatest(
    progressSlice.actions.listGallery,
    createProgressList(
      progressSlice.actions.listGallerySuccess,
      progressSlice.actions.listGalleryError
    )
  );
  yield takeLatest(progressSlice.actions.remove, remove);
  yield takeLatest(progressSlice.actions.restore, restore);
  yield takeLatest(progressSlice.actions.update, update);
}
