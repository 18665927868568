import {
  hasValue,
  isEmptyNumber,
  isText,
  isValidDate,
  positiveFloat
} from 'common/validations/field';
import i18n from 'i18n';

const defaultProgressFormValidations = (values) => {
  const errors = {};

  if (!hasValue(values.back_picture)) {
    errors.back_picture = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!isValidDate(values.created_at)) {
    errors.created_at = i18n.t('date', { ns: 'validations' });
  }

  if (!isValidDate(values.end_date)) {
    errors.end_date = i18n.t('date', { ns: 'validations' });
  }

  if (!hasValue(values.front_picture)) {
    errors.front_picture = i18n.t('emptyField', { ns: 'validations' });
  }

  if (isEmptyNumber(values.height)) {
    errors.height = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!positiveFloat(values.height)) {
    errors.height = i18n.t('positiveFloat', { ns: 'validations' });
  }

  if (hasValue(values.observations) && !isText(values.observations)) {
    errors.observations = i18n.t('text', { ns: 'validations' });
  }

  if (!hasValue(values.profile_picture)) {
    errors.profile_picture = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!isValidDate(values.start_date)) {
    errors.start_date = i18n.t('date', { ns: 'validations' });
  }

  if (!hasValue(values.user_id)) {
    errors.user_id = i18n.t('emptyField', { ns: 'validations' });
  }

  if (isEmptyNumber(values.weight)) {
    errors.weight = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!positiveFloat(values.weight)) {
    errors.weight = i18n.t('positiveFloat', { ns: 'validations' });
  }

  return errors;
};

export default defaultProgressFormValidations;
