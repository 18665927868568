import { gql } from '@apollo/client';
import client from 'apollo/client';
import { getMealPlanFilters } from 'common/filters/requestFilters';
import { mealPlanListFields } from 'common/constants/query-fields/mealPlan';

/**
 * Create meal plan method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createMealPlan($input: CreateMealPlanInput!) {
        createMealPlan(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Create temporary URL meal plan method
 * @param  {string} id
 * @return {Promise}
 */
const createMealPlanTemporaryUrl = (id) =>
  client.query({
    query: gql`
      query createMealPlanTemporaryUrl($id: ID!) {
        createMealPlanTemporaryUrl(id: $id)
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * Find meal plan method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query mealPlan($id: ID!) {
        mealPlan(id: $id) {
          ${mealPlanListFields}
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * List meal plan method
 * @param {Object} payload
 * @returns {Promise}
 */
const list = (payload) => {
  const { fields, filters, pagination } = payload;
  const mealPlanFilters = getMealPlanFilters(filters);

  return client.query({
    query: gql`
      query mealPlans {
        mealPlans(
          first: ${pagination.first}
          page: ${pagination.page}
          ${mealPlanFilters.user_id}
          ${mealPlanFilters.created_at}
          ${mealPlanFilters.soft_delete}
          orderBy: {
            column: CREATED_AT,
            order: DESC
          }
        ) {
          paginatorInfo {
            currentPage
            hasMorePages
            total
          }
          data {
            ${fields}
          }
        }
      }
    `,
    fetchPolicy: 'network-only'
  });
};

/**
 * Remove meal plan method
 * @param  {Array} ids
 * @return {Promise}
 */
const remove = (ids) =>
  client.mutate({
    mutation: gql`
      mutation deleteMealPlans($id: [ID!]!) {
        deleteMealPlans(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Restore meal plan method
 * @param  {Array} ids
 * @return {Promise}
 */
const restore = (ids) =>
  client.mutate({
    mutation: gql`
      mutation restoreMealPlans($id: [ID!]!) {
        restoreMealPlans(id: $id) {
          id
        }
      }
    `,
    variables: { id: ids }
  });

/**
 * Update meal plan method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateMealPlan($id: ID!, $input: UpdateMealPlanInput!) {
        updateMealPlan(id: $id, input: $input) {
          id
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  create,
  createMealPlanTemporaryUrl,
  find,
  list,
  remove,
  restore,
  update
};
