import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import StyledBackButton from 'common/theme/shared/StyledBackButton';

function GoBackButton() {
  const { t } = useTranslation(['buttons']);
  const previousPage = useSelector((state) => state.router.previous);

  return (
    <StyledBackButton
      color="white"
      component={RouterLink}
      to={previousPage}
      startIcon={<ArrowBack />}>
      {t('goBack', { ns: 'buttons' })}
    </StyledBackButton>
  );
}

export default GoBackButton;
