import { gql } from '@apollo/client';
import client from 'apollo/client';

/**
 * Create settings method
 * @param  {Object} payload
 * @return {Promise}
 */
const create = (payload) =>
  client.mutate({
    mutation: gql`
      mutation createSettings($input: CreateSettingsInput!) {
        createSettings(input: $input) {
          id
        }
      }
    `,
    variables: { input: payload }
  });

/**
 * Find settings method
 * @param  {string} id
 * @return {Promise}
 */
const find = (id) =>
  client.query({
    query: gql`
      query settings($id: ID!) {
        settings(id: $id) {
          id
          language
          mini_drawer
          push_notifications
        }
      }
    `,
    variables: { id },
    fetchPolicy: 'network-only'
  });

/**
 * Update settings method
 * @param  {Object} payload
 * @return {Promise}
 */
const update = (payload) =>
  client.mutate({
    mutation: gql`
      mutation updateSettings($id: ID!, $input: UpdateSettingsInput!) {
        updateSettings(id: $id, input: $input) {
          language
          mini_drawer
          push_notifications
        }
      }
    `,
    variables: {
      id: payload.id,
      input: payload.input
    }
  });

export default {
  create,
  find,
  update
};
