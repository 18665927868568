const palette = {
  mode: 'dark',
  primary: {
    dark: '#11178C',
    main: '#171FB9'
  },
  secondary: {
    dark: '#F8B131',
    main: '#F9BD4E'
  },
  success: {
    dark: '#126332',
    main: '#1B934B'
  },
  info: {
    dark: '#0A65FF',
    main: '#2D7AFF'
  },
  warning: {
    dark: '#E7B140',
    main: '#EBBD5C'
  },
  error: {
    dark: '#841010',
    main: '#B21616'
  },
  menuItem: {
    main: '#BBC2C9',
    contrastText: '#333333'
  },
  muiError: {
    main: '#F6B1B1',
    contrastText: '#333333'
  },
  scheduled: {
    main: '#003BA1',
    contrastText: '#FFFFFF'
  },
  finished: {
    main: '#21854A',
    contrastText: '#FFFFFF'
  },
  absent: {
    main: '#C4C4C4',
    contrastText: '#333333'
  },
  clientAbsent: {
    main: '#C4C4C4',
    contrastText: '#333333'
  },
  trainerAbsent: {
    main: '#C4C4C4',
    contrastText: '#333333'
  },
  white: {
    main: '#FFFFFF',
    contrastText: '#333333'
  },
  black: {
    main: '#333333',
    contrastText: '#FFFFFF'
  },
  gray0: {
    main: '#292E33',
    contrastText: '#FFFFFF'
  },
  gray1: {
    main: '#212529',
    contrastText: '#FFFFFF'
  },
  gray2: {
    main: '#1F2327',
    contrastText: '#FFFFFF'
  },
  gray3: {
    main: '#16191C',
    contrastText: '#FFFFFF'
  },
  gray4: {
    main: '#181819',
    contrastText: '#FFFFFF'
  }
};

export default palette;
