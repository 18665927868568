import { Box, styled } from '@mui/material';

const StyledTrainerSelectSkeletonItem = styled(Box)(({ theme }) => ({
  borderRadius: '28px',
  backgroundColor: theme.palette.gray3.main,
  columnGap: theme.spacing(1),
  display: 'flex',
  padding: theme.spacing(1),
  width: '100%'
}));

export default StyledTrainerSelectSkeletonItem;
