import { takeLatest, put } from 'redux-saga/effects';
import newsSlice from 'store/modules/news';
import uiSlice from 'store/modules/ui';
import news from 'api/graphql/news';
import { createSnack, getUserFriendlyError } from 'common/utils/general';
import i18n from 'i18n';

/**
 * Create news saga
 *
 * @param {*} action
 */
function* create(action) {
  try {
    const snack = createSnack({
      key: 'news-create-notification-success',
      variant: 'success',
      message: i18n.t('news.create', { ns: 'notifications' })
    });

    const { payload } = action;
    yield news.create(payload);
    yield put(newsSlice.actions.createSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(newsSlice.actions.createError(message));
  }
}

/**
 * Find news saga
 *
 * @param {*} action
 */
function* find(action) {
  try {
    const response = yield news.find(action.payload);
    const {
      data: { findNews: currentNews }
    } = response;

    yield put(newsSlice.actions.findSuccess(currentNews));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(newsSlice.actions.findError(message));
  }
}

/**
 * Force delete news saga
 *
 * @param {*} action
 */
function* forceDelete(action) {
  try {
    const {
      data: { forceDeleteNews: deletedNews }
    } = yield news.forceDelete(action.payload);

    const snack = createSnack({
      key: 'force-delete-news-notification-success',
      variant: 'success',
      message: i18n.t('news.delete', { count: deletedNews.length, ns: 'notifications' })
    });

    yield put(newsSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(newsSlice.actions.removeError(message));
  }
}

/**
 * List news saga
 *
 * @param {*} action
 */
function* list(action) {
  try {
    const { fields, filters = {}, pagination } = action.payload;
    const response = yield news.list({ fields, filters, pagination });

    const {
      data: {
        news: { data, paginatorInfo }
      }
    } = response;

    yield put(newsSlice.actions.listSuccess(data));
    yield put(
      newsSlice.actions.updatePaginatorInfo({
        hasMorePages: paginatorInfo.hasMorePages,
        lastPage: paginatorInfo.lastPage,
        total: paginatorInfo.total
      })
    );
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(newsSlice.actions.listError(message));
  }
}

/**
 * Remove news saga
 *
 * @param {*} action
 */
function* remove(action) {
  try {
    const {
      data: { deleteNews: removedNews }
    } = yield news.remove(action.payload);

    const snack = createSnack({
      key: 'remove-news-notification-success',
      variant: 'success',
      message: i18n.t('news.delete', { count: removedNews.length, ns: 'notifications' })
    });

    yield put(newsSlice.actions.removeSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(newsSlice.actions.removeError(message));
  }
}

/**
 * Restore news saga
 *
 * @param {*} action
 */
function* restore(action) {
  try {
    const {
      data: { restoreNews: restoredNews }
    } = yield news.restore(action.payload);

    const snack = createSnack({
      key: 'restore-news-notification-success',
      variant: 'success',
      message: i18n.t('news.restore', { count: restoredNews.length, ns: 'notifications' })
    });

    yield put(newsSlice.actions.restoreSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(newsSlice.actions.restoreError(message));
  }
}

/**
 * Update news saga
 *
 * @param {*} action
 */
function* update(action) {
  try {
    const snack = createSnack({
      key: 'update-news-notification-success',
      variant: 'success',
      message: i18n.t('news.update', { ns: 'notifications' })
    });

    yield news.update(action.payload);

    yield put(newsSlice.actions.updateSuccess());
    yield put(uiSlice.actions.enqueueSnackbar(snack));
  } catch (ex) {
    const message = getUserFriendlyError(ex);
    yield put(newsSlice.actions.updateError(message));
  }
}

/**
 * Watch news
 *
 * @export
 */
export default function* watchNews() {
  yield takeLatest(newsSlice.actions.create, create);
  yield takeLatest(newsSlice.actions.find, find);
  yield takeLatest(newsSlice.actions.forceDelete, forceDelete);
  yield takeLatest(newsSlice.actions.list, list);
  yield takeLatest(newsSlice.actions.remove, remove);
  yield takeLatest(newsSlice.actions.restore, restore);
  yield takeLatest(newsSlice.actions.update, update);
}
