export const clientPerformanceListFields = `
  id
  burpees_repetitions
  end_date
  plank_time_minutes
  plank_time_seconds
  pull_ups_repetitions
  push_up_repetitions
  running_distance
  squats_repetitions
  start_date
`;

export const performanceFindFields = `
  id
  burpees_repetitions
  created_at
  end_date
  observations
  plank_time_minutes
  plank_time_seconds
  pull_ups_repetitions
  push_up_repetitions
  running_distance
  squats_repetitions
  start_date
  user_id {
    id
    full_name
  }
`;

export const performanceHistoryFields = `
  push_up_repetitions {
    date
    place
    total
    totalRaw
  }
  squats_repetitions {
    date
    place
    total
    totalRaw
  }
  burpees_repetitions {
    date
    place
    total
    totalRaw
  }
  running_distance {
    date
    place
    total
    totalRaw
  }
  pull_ups_repetitions {
    date
    place
    total
    totalRaw
  }
  plank_time {
    date
    place
    total
    totalRaw
  }
`;

export const performanceListFields = `
  id
  burpees_repetitions
  end_date
  observations
  plank_time_minutes
  plank_time_seconds
  pull_ups_repetitions
  push_up_repetitions
  running_distance
  squats_repetitions
  start_date
  user_id {
    full_name
    first_name
    last_name
    photo
  }
`;

export const performanceListBasicFields = `
  id
  burpees_repetitions
  end_date
  plank_time_minutes
  plank_time_seconds
  pull_ups_repetitions
  push_up_repetitions
  running_distance
  squats_repetitions
  start_date
  user_id {
    full_name
    first_name
    last_name
    photo
  }
`;

export const performanceTopRankedFields = `
  female {
    place
    total
    totalRaw
    user {
      first_name
      last_name
      photo
    }
  }
  male {
    place
    total
    totalRaw
    user {
      first_name
      last_name
      photo
    }
  }
`;
