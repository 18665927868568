const progressRoutes = {
  listProgress: {
    path: '/progress'
  },
  clientProgress: {
    path: '/progress-history'
  },
  createProgress: {
    path: '/progress/create'
  },
  editProgress: {
    path: '/progress/edit/:id'
  },
  viewProgress: {
    path: '/progress/:id'
  }
};

export default progressRoutes;
