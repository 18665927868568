import { put } from 'redux-saga/effects';
import user from 'api/graphql/user';
import clientSlice from 'store/modules/client';
import { getUserFriendlyError } from 'common/utils/general';

/**
 * A higher-order function that creates a saga for handling client listing.
 *
 * @param {function} successAction - The action to be dispatched upon a successful API call.
 * @param {function} errorAction - The action to be dispatched if the API call fails.
 *
 * @returns {function} A generator function (saga) that makes an API call to list clients.
 */
export const createClientList = (successAction, errorAction) =>
  function* list(action) {
    try {
      const { fields, filters = {}, orderBy = null, pagination } = action.payload;
      const response = yield user.clients({ fields, filters, orderBy, pagination });

      const {
        data: {
          users: { data, paginatorInfo }
        }
      } = response;

      yield put(successAction(data));
      yield put(
        clientSlice.actions.updatePaginatorInfo({
          hasMorePages: paginatorInfo.hasMorePages,
          lastPage: paginatorInfo.lastPage,
          total: paginatorInfo.total
        })
      );
    } catch (ex) {
      const message = getUserFriendlyError(ex);
      yield put(errorAction(message));
    }
  };

/**
 * A higher-order function that creates a saga for handling client finding.
 *
 * @param {function} successAction - The action to be dispatched upon a successful API call.
 * @param {function} errorAction - The action to be dispatched if the API call fails.
 *
 * @returns {function} A generator function (saga) that makes an API call to find clients.
 */
export const createClientFind = (successAction, errorAction) =>
  function* find(action) {
    try {
      const { fields, id } = action.payload;
      const response = yield user.find({ fields, id });

      const {
        data: { user: currentClient }
      } = response;

      yield put(successAction(currentClient));
    } catch (ex) {
      const message = getUserFriendlyError(ex);
      yield put(errorAction(message));
    }
  };
