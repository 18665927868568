import { hasValue, isEmptyNumber, isText, minLength, positiveInt } from 'common/validations/field';
import i18n from 'i18n';

const defaultPlanFormValidations = (values) => {
  const errors = {};

  if (!hasValue(values.name)) {
    errors.name = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!minLength(values.name, 2)) {
    errors.name = i18n.t('minLength', { length: '2', ns: 'validations' });
  }

  if (isEmptyNumber(values.price)) {
    errors.price = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!positiveInt(values.price)) {
    errors.price = i18n.t('positiveInteger', { ns: 'validations' });
  }

  if (isEmptyNumber(values.sessions)) {
    errors.sessions = i18n.t('emptyField', { ns: 'validations' });
  }

  if (!positiveInt(values.sessions)) {
    errors.sessions = i18n.t('positiveInteger', { ns: 'validations' });
  }

  if (hasValue(values.description) && !isText(values.description)) {
    errors.description = i18n.t('text', { ns: 'validations' });
  }

  return errors;
};

export default defaultPlanFormValidations;
