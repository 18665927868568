import React from 'react';
import PerformanceTestColumn from 'components/shared/table-columns/PerformanceTest';
import routes from 'common/routes';
import { multiLineHeaderLabel } from 'common/table/utils';
import { actions, dateRange, getRow, userProfile } from 'common/table/columns/customColumns';

const performanceTest = ({ data, label, name }) => ({
  label,
  name,
  options: {
    customHeadLabelRender: multiLineHeaderLabel,
    customBodyRenderLite: (dataIndex, rowIndex) => {
      const row = getRow(data, rowIndex)[0];
      return <PerformanceTestColumn data={row} name={name} />;
    }
  }
});

const commonColumns = (data, translateFunc) => [
  performanceTest({
    data,
    label: translateFunc('pushUp.column', { ns: 'labels' }),
    name: 'push_up_repetitions'
  }),
  performanceTest({
    data,
    label: translateFunc('squat.column', { ns: 'labels' }),
    name: 'squats_repetitions'
  }),
  performanceTest({
    data,
    label: translateFunc('plank.column', { ns: 'labels' }),
    name: 'plank_time'
  }),
  performanceTest({
    data,
    label: translateFunc('burpees.column', { ns: 'labels' }),
    name: 'burpees_repetitions'
  }),
  performanceTest({
    data,
    label: translateFunc('running.column', { ns: 'labels' }),
    name: 'running_distance'
  }),
  performanceTest({
    data,
    label: translateFunc('pullUp.column', { ns: 'labels' }),
    name: 'pull_ups_repetitions'
  })
];

const idColumn = {
  label: 'Id',
  name: 'id',
  options: { display: 'excluded' }
};

const dateRangeColumn = (data, translateFunc) => ({
  ...dateRange({ data, label: translateFunc('period', { ns: 'labels' }) })
});

export const getClientPerformanceColumns = ({ data, translateFunc }) => [
  idColumn,
  dateRangeColumn(data, translateFunc),
  ...commonColumns(data, translateFunc)
];

export const getPerformanceColumns = ({ data, translateFunc, showActions = true }) => {
  const columns = [
    idColumn,
    userProfile({ data, label: translateFunc('client', { count: 1, ns: 'labels' }) }),
    dateRangeColumn(data, translateFunc),
    ...commonColumns(data, translateFunc)
  ];

  if (showActions) {
    columns.push(
      actions({
        items: ['edit'],
        data,
        label: translateFunc('actions', { ns: 'labels' }),
        actionRoutes: { edit: routes.editPerformance.path }
      })
    );
  }

  return columns;
};
