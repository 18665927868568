import { put } from 'redux-saga/effects';
import user from 'api/graphql/user';
import { getUserFriendlyError } from 'common/utils/general';

/**
 * A higher-order function that creates a saga for handling trainer finding.
 *
 * @param {function} successAction - The action to be dispatched upon a successful API call.
 * @param {function} errorAction - The action to be dispatched if the API call fails.
 *
 * @returns {function} A generator function (saga) that makes an API call to find clients.
 */
const createTrainerFind = (successAction, errorAction) =>
  function* find(action) {
    try {
      const { fields, id } = action.payload;
      const response = yield user.find({ fields, id });

      const {
        data: { user: currentTrainer }
      } = response;

      yield put(successAction(currentTrainer));
    } catch (ex) {
      const message = getUserFriendlyError(ex);
      yield put(errorAction(message));
    }
  };

export default createTrainerFind;
