import { Typography, styled } from '@mui/material';

const StyledProfileHeaderText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  textAlign: 'center',
  marginBottom: theme.spacing(1),
  '&:last-of-type': {
    marginBottom: 0
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
    textAlign: 'left'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(16)
  }
}));

export default StyledProfileHeaderText;
