export const createClientPaymentSearchFields = `
  id
  full_name
  plan_id {
    id
    currency
    name
    price
    sessions
  }
`;

export const clientListBasicFields = `
  id
  country
  first_name
  full_name
  gender
  last_name
  membership_days_left
  membership_status
  photo
  plan_id {
    id
    name
  }
`;

export const clientListArrearageFields = `
  full_name
  last_client_payment {
    end_date
  }
  membership_days_in_arrears
  membership_status
`;

export const createClientSessionSearchFields = `
  id
  available_sessions
  full_name
  gender
  membership_status
`;

export const clientFindFields = `
  id
  birthdate
  city
  country
  email
  first_name
  gender
  last_name
  observations
  phone
  plan_id {
    id
  }
  settings {
    language
  }
  state
`;

export const clientViewFields = `
  id
  age
  available_sessions
  country
  first_name
  full_name
  gender
  last_client_payment {
    end_date
  }
  last_name
  membership_status
  observations
  phone
  photo
  plan_id {
    name
    price
    sessions
  }
`;
