import { roles } from 'common/constants/options';
import routes from 'common/routes';
import {
  actions,
  sessionStatus,
  sessionStatusUpdate,
  timeRange,
  userProfile
} from 'common/table/columns/customColumns';
import { isTrainer } from 'common/utils/user';

const defaultOptions = { horizontallyCentered: true };

/**
 * Constructs an array of column definitions for displaying admin session data in a table.
 *
 * @param  {object}    params                 The parameters object.
 * @param  {boolean}   params.includeActions  Whether or not to include an actions column.
 * @param  {object}    params.data            The session data to be used in column definitions.
 * @param  {function}  params.translateFunc   The translation function, used for internationalizing labels.
 *
 * @returns  {object[]}  An array of column definition objects. Each object includes properties like `label`, `name`, and `options`.
 *   - `label` is a string or a translated string for the column header.
 *   - `name` is a string representing the data field name in the dataset.
 *   - `options` is an object providing additional configuration for the column (e.g., display settings).
 *   - Some columns utilize helper functions to generate more complex structures (e.g., `userProfile` for client and trainer columns).
 */
export const getAdminSessionColumns = ({ data, includeActions = true, translateFunc }) => {
  const columns = [
    { label: 'Id', name: 'id', options: { display: 'excluded' } },
    { label: translateFunc('date', { ns: 'labels' }), name: 'start_date' },
    timeRange({ data, label: translateFunc('time', { ns: 'labels' }) }),
    userProfile({
      data,
      key: roles.client.toLowerCase(),
      label: translateFunc('client', { count: 1, ns: 'labels' }),
      options: defaultOptions
    }),
    userProfile({
      data,
      key: roles.trainer.toLowerCase(),
      label: translateFunc('trainer', { count: 1, ns: 'labels' }),
      options: defaultOptions
    }),
    sessionStatus({
      data,
      label: translateFunc('status', { ns: 'labels' })
    }),
    { label: translateFunc('category', { ns: 'labels' }), name: 'session_type' }
  ];

  if (includeActions) {
    columns.push(
      actions({
        items: ['edit'],
        data,
        label: translateFunc('actions', { ns: 'labels' }),
        actionRoutes: { edit: routes.editSession.path }
      })
    );
  }

  return columns;
};

/**
 * Creates an array of column definitions for displaying finished session data in a table.
 *
 * @param  {object}    params                The parameters object for the function.
 * @param  {object}    params.data           The finished session data used in column definitions.
 * @param  {string}    params.role           The role of the user (e.g., 'trainer' or 'client'), used to tailor the column definitions.
 * @param  {function}  params.translateFunc  A translation function for internationalizing column labels.
 *
 * @returns  {object[]}  An array of objects, each representing a column definition for finished sessions. These include:
 *   - `label`: The label for the column, either a string or a translated string using `translateFunc`.
 *   - `name`: A string representing the data field name in the dataset.
 *   - The columns typically include user (client/trainer), date, time, category, and session status.
 *   - The session status column is generated using the `sessionStatus` helper function, tailored to the user's role.
 */
export const getFinishedSessionColumns = ({ data, role, translateFunc }) => {
  const userColumnLabel = isTrainer(role) ? 'client' : 'trainer';

  return [
    { label: translateFunc(userColumnLabel, { count: 1, ns: 'labels' }), name: 'user' },
    { label: translateFunc('date', { ns: 'labels' }), name: 'date' },
    { label: translateFunc('time', { ns: 'labels' }), name: 'time' },
    { label: translateFunc('category', { ns: 'labels' }), name: 'category' },
    sessionStatus({
      data,
      label: translateFunc('status', { ns: 'labels' }),
      userRole: role
    })
  ];
};

/**
 * Generates an array of column definitions for session data, tailored based on the user's role.
 *
 * @param  {object}    params                 The parameters object for the function.
 * @param  {boolean}   params.includeActions  Whether or not to include an actions column.
 * @param  {object}    params.data            The session data used in the column definitions.
 * @param  {function}  params.onStatusChange  A callback function that is triggered on session status change.
 * @param  {string}    params.role            The role of the user, used to determine the structure of columns (e.g., 'trainer' or 'client').
 * @param  {function}  params.translateFunc  The translation function for internationalizing labels.
 *
 * @returns  {object[]}  An array of objects, each representing a column definition. These include:
 *   - `label`: The label for the column header, either a string or a translated string.
 *   - `name`: A string representing the data field name in the dataset.
 *   - `options`: An object with additional settings for the column (e.g., display preferences).
 *   - Columns are created using helper functions like `timeRange`, `userProfile`, and `sessionStatus`.
 *   - Additional column for session status updates is included for trainers.
 */
export const getSessionColumns = ({
  data,
  includeActions = true,
  onStatusChange,
  role,
  translateFunc
}) => {
  const userColumnLabel = isTrainer(role) ? 'client' : 'trainer';

  const columns = [
    { label: 'Id', name: 'id', options: { display: 'excluded' } },
    { label: translateFunc('date', { ns: 'labels' }), name: 'start_date' },
    timeRange({ data, label: translateFunc('time', { ns: 'labels' }) }),
    userProfile({
      data,
      label: translateFunc(userColumnLabel, { count: 1, ns: 'labels' }),
      options: defaultOptions
    }),
    sessionStatus({
      data,
      label: translateFunc('status', { ns: 'labels' })
    }),
    { label: translateFunc('category', { ns: 'labels' }), name: 'session_type' }
  ];

  if (role === roles.trainer && includeActions) {
    columns.push(
      sessionStatusUpdate({
        data,
        label: translateFunc('markAs', { ns: 'labels' }),
        onSelectChange: onStatusChange
      })
    );
  }

  return columns;
};
