export const myTrainerPaymentListFields = `
  id
  created_at
  end_date
  invoice_url
  price
  sessions_completed
  start_date
`;

export const trainerPaymentListFields = `
  id
  created_at
  currency
  end_date
  invoice_url
  observations
  price
  sessions_completed
  start_date
  user_id {
    full_name
    first_name
    last_name
    photo
  }
`;

export const trainerPaymentListBasicFields = `
  created_at
  end_date
  price
  sessions_completed
  start_date
  user_id {
    full_name
  }
`;
