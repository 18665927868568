import { Typography, styled } from '@mui/material';

const StyledCounterSectionNumber = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 800,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(50)
  }
}));

export default StyledCounterSectionNumber;
