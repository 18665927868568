/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { Logout } from '@mui/icons-material';
import StyledDrawerMenuLogoutContainer from 'common/theme/drawer-menu/StyledDrawerMenuLogoutContainer';
import StyledLogoutButton from 'common/theme/button/StyledLogoutButton';

function DrawerLogoutButton({ onClick }) {
  const { t } = useTranslation(['buttons']);
  const miniDrawer = useSelector((state) => state.settings.config.miniDrawer);

  return miniDrawer ? (
    <Tooltip title={t('auth.logout', { ns: 'buttons' })}>
      <IconButton
        aria-label="logout"
        color="menuItem"
        onClick={onClick}
        sx={{ alignSelf: 'center' }}>
        <Logout />
      </IconButton>
    </Tooltip>
  ) : (
    <StyledDrawerMenuLogoutContainer>
      <StyledLogoutButton
        color="menuItem"
        onClick={onClick}
        startIcon={<Logout sx={{ marginRight: '20px' }} />}>
        {t('auth.logout', { ns: 'buttons' })}
      </StyledLogoutButton>
    </StyledDrawerMenuLogoutContainer>
  );
}

DrawerLogoutButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default DrawerLogoutButton;
