import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormControlLabel, FormLabel, RadioGroup } from '@mui/material';
import StyledRadio from 'common/theme/radio';

function RadioLegends({ legends, onClick, selected }) {
  const { t } = useTranslation(['labels']);

  const handleChange = (event) => {
    const { value } = event.target;
    onClick(value);
  };

  return (
    <FormControl>
      <FormLabel
        id="radio-legends-group-label"
        sx={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          padding: '0',
          margin: '-1px',
          overflow: 'hidden',
          clip: 'rect(0,0,0,0)',
          whiteSpace: 'nowrap',
          border: '0'
        }}>
        {t('currency', { ns: 'labels' })}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="radio-legends-group-label"
        name="radio-legends-buttons-group"
        sx={{ justifyContent: 'center' }}>
        {legends.map((legend) => (
          <Box key={legend.label.toLowerCase()} sx={{ alignItems: 'center', display: 'flex' }}>
            <FormControlLabel
              value={legend.label}
              control={<StyledRadio />}
              label={legend.label}
              checked={selected === legend.label}
              onChange={handleChange}
            />
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioLegends.propTypes = {
  legends: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired
};

export default RadioLegends;
