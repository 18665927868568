import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Unstable_Grid2 as Grid } from '@mui/material';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import HourSelectFieldAdapter from 'components/shared/field-adapters/hour-picker-field-adapter/HourPickerFieldAdapter';
import SelectFieldAdapter from 'components/shared/field-adapters/SelectFieldAdapter';
import TimeFieldAdapter from 'components/shared/field-adapters/TimeFieldAdapter';
import { sessionEndMaximumHour, sessionStartMinimumHour } from 'common/constants/date';
import { getLanguageOptions, getSessionStatusOptions } from 'common/constants/options';
import requestStatus from 'common/constants/status';
import { createTimeOptions } from 'common/utils/date';
import { isAdmin } from 'common/utils/user';

function SessionFieldsSessionDetails({ isEditing, values }) {
  const { t } = useTranslation(['labels', 'sessions']);

  const latestClientPayment =
    useSelector((state) => state.clientPayment.data?.currentPayment) ?? null;
  const role = useSelector((state) => state.auth.user.role);
  const sessionTypeOptions = useSelector((state) => state.sessionType.sessionTypeOptions);
  const sessionTypeStatus = useSelector((state) => state.sessionType.status);

  const isAdminUser = isAdmin(role);

  const timeOptions = useMemo(
    () =>
      values && values.date
        ? createTimeOptions(sessionStartMinimumHour, sessionEndMaximumHour, values.date)
        : createTimeOptions(sessionStartMinimumHour, sessionEndMaximumHour, new Date()),
    [values.date]
  );

  return (
    <Grid container spacing={4}>
      <Grid xs={12} md={isAdminUser ? 4 : 6}>
        <Field
          id="session_type_id"
          name="session_type_id"
          component={SelectFieldAdapter}
          options={sessionTypeOptions}
          label={t('category', { ns: 'labels' })}
          fullWidth
          required
          disabled={!values.client_id || sessionTypeStatus === requestStatus.loading}
        />
      </Grid>
      <Grid xs={12} md={isAdminUser ? 4 : 6}>
        <Field
          id="language"
          name="language"
          component={SelectFieldAdapter}
          options={getLanguageOptions(t)}
          label={t('sessionLanguage', { ns: 'labels' })}
          fullWidth
          required
          disabled={!values.client_id || !values.session_type_id}
        />
      </Grid>
      {isAdminUser && (
        <Grid xs={12} md={4}>
          <Field
            id="status"
            name="status"
            component={SelectFieldAdapter}
            options={getSessionStatusOptions(t)}
            label={t('status', { ns: 'labels' })}
            fullWidth
            required
            disabled={!values.client_id || !values.session_type_id}
          />
        </Grid>
      )}
      <Grid xs={12} md={4}>
        <Field
          id="date"
          name="date"
          component={DatePickerFieldAdapter}
          label={t('date', { ns: 'labels' })}
          disabled={!values.client_id || !values.session_type_id}
          disablePast={!isEditing}
          maxDate={latestClientPayment ? new Date(latestClientPayment.end_date) : null}
          minDate={latestClientPayment ? new Date(latestClientPayment.start_date) : null}
          renderInputProps={{
            fullWidth: true,
            required: true
          }}
        />
      </Grid>
      <Grid xs={6} md={4}>
        <Field
          id="from"
          name="from"
          component={HourSelectFieldAdapter}
          label={t('from', { ns: 'common' })}
          dateFormat="hh aaa"
          disablePast
          options={timeOptions}
          disabled={!values.client_id || !values.session_type_id}
          referenceDate={values.date}
          renderInputProps={{
            fullWidth: true,
            required: true
          }}
        />
      </Grid>
      <Grid xs={6} md={4}>
        <Field
          id="to"
          name="to"
          component={TimeFieldAdapter}
          label={t('to', { ns: 'common' })}
          dateFormat="hh aaa"
          referenceDate={values.date}
          renderInputProps={{
            fullWidth: true,
            required: true,
            disabled: true
          }}
        />
      </Grid>
    </Grid>
  );
}

SessionFieldsSessionDetails.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired
};

export default SessionFieldsSessionDetails;
