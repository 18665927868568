import { Typography, styled } from '@mui/material';

const StyledDoubleCounterTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(20)
  }
}));

export default StyledDoubleCounterTitle;
