import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { Divider, InputAdornment, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import DatePickerFieldAdapter from 'components/shared/field-adapters/DatePickerFieldAdapter';
import TextAreaFieldAdapter from 'components/shared/field-adapters/TextAreaFieldAdapter';
import TextFieldAdapter from 'components/shared/field-adapters/TextFieldAdapter';
import TypeaheadFieldAdapter from 'components/shared/field-adapters/TypeaheadFieldAdapter';
import requestStatus from 'common/constants/status';
import { isRequiredField } from 'common/utils/general';
import useForms from 'hooks/useForms';

function PerformanceFields({ clientOptions, onClientIdChange, requiredFields, searchStatus }) {
  const { t } = useTranslation(['labels']);
  const { clearClientSearchResults } = useForms();

  return (
    <>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <Field
            id="user_id"
            name="user_id"
            component={TypeaheadFieldAdapter}
            fullWidth
            loading={searchStatus === requestStatus.loading}
            isMultiple={false}
            onClose={clearClientSearchResults}
            onInputChange={onClientIdChange}
            options={clientOptions}
            renderInputProps={{
              label: t('client', { count: 1, ns: 'labels' }),
              required: isRequiredField('user_id', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <Field
            id="start_date"
            name="start_date"
            component={DatePickerFieldAdapter}
            label={t('startDate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('start_date', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <Field
            id="end_date"
            name="end_date"
            component={DatePickerFieldAdapter}
            label={t('endDate', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('end_date', requiredFields)
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <Field
            id="created_at"
            name="created_at"
            component={DatePickerFieldAdapter}
            label={t('createdAt', { ns: 'labels' })}
            renderInputProps={{
              fullWidth: true,
              required: isRequiredField('created_at', requiredFields)
            }}
          />
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('testResults', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container spacing={4}>
        <Grid xs={12} md={6}>
          <div>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t('pushUp.label', { ns: 'labels' })}
                </Typography>
                <Typography variant="body2">
                  {t('testDuration', { duration: 2, ns: 'labels' })}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Field
                  id="push_up_repetitions"
                  name="push_up_repetitions"
                  component={TextFieldAdapter}
                  label={t('repetitions', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t('burpees.label', { ns: 'labels' })}
                </Typography>
                <Typography variant="body2">
                  {t('testDuration', { duration: 2, ns: 'labels' })}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Field
                  id="burpees_repetitions"
                  name="burpees_repetitions"
                  component={TextFieldAdapter}
                  label={t('repetitions', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t('squat.label', { ns: 'labels' })}
                </Typography>
                <Typography variant="body2">
                  {t('testDuration', { duration: 3, ns: 'labels' })}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Field
                  id="squats_repetitions"
                  name="squats_repetitions"
                  component={TextFieldAdapter}
                  label={t('repetitions', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t('running.label', { ns: 'labels' })}
                </Typography>
                <Typography variant="body2">
                  {t('testDuration', { duration: 12, ns: 'labels' })}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Field
                  id="running_distance"
                  name="running_distance"
                  component={TextFieldAdapter}
                  label="Distancia"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">km</InputAdornment>
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t('plank.label', { ns: 'labels' })}
                </Typography>
                <Typography variant="body2">
                  {t('testDuration', { duration: 4, ns: 'labels' })}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <div>
                  <Grid container spacing={1}>
                    <Grid xs={6}>
                      <Field
                        id="plank_time_minutes"
                        name="plank_time_minutes"
                        component={TextFieldAdapter}
                        label={t('minutes', { ns: 'labels' })}
                        fullWidth
                      />
                    </Grid>
                    <Grid xs={6}>
                      <Field
                        id="plank_time_seconds"
                        name="plank_time_seconds"
                        component={TextFieldAdapter}
                        label={t('seconds', { ns: 'labels' })}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t('pullUp.label', { ns: 'labels' })}
                </Typography>
                <Typography variant="body2">
                  {t('testDuration', { duration: 2, ns: 'labels' })}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Field
                  id="pull_ups_repetitions"
                  name="pull_ups_repetitions"
                  component={TextFieldAdapter}
                  label={t('repetitions', { ns: 'labels' })}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Typography
        component="h4"
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(26),
          marginBottom: (theme) => theme.spacing(1),
          marginTop: '35px'
        }}>
        {t('observations', { ns: 'labels' })}
      </Typography>
      <Divider sx={{ marginBottom: '22px' }} />
      <Grid container>
        <Grid xs={12}>
          <Field
            id="observations"
            name="observations"
            component={TextAreaFieldAdapter}
            label={t('observations', { ns: 'labels' })}
            helperText={t('text', { ns: 'validations' })}
            multiline
            minRows={5}
            type="text"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

PerformanceFields.propTypes = {
  clientOptions: PropTypes.array.isRequired,
  onClientIdChange: PropTypes.func.isRequired,
  requiredFields: PropTypes.array.isRequired,
  searchStatus: PropTypes.string.isRequired
};

export default PerformanceFields;
