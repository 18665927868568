import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import StyledTextField from 'common/theme/fields/StyledTextfield';
import StyledDateRangePickerContainer from 'common/theme/fields/date-range-picker/StyledDateRangePickerContainer';
import datePickerCalendarStyles from 'common/theme/fields/date-picker/datePickerCalendarStyles';
import { convertToDate, resetTimeInDate } from 'common/utils/date';

function DateRangePickerFieldAdapter({ disableFuture, input, label }) {
  const { t } = useTranslation(['buttons']);
  const theme = useTheme();
  const { value } = input;
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const size = useMemo(() => (isDesktop ? 'medium' : 'small'), [isDesktop]);

  const onStartDateChange = (date) => {
    const resetDate = resetTimeInDate(date);

    input.onChange({
      start_date: resetDate,
      end_date: null
    });
  };

  const onEndDateChange = (date) => {
    const resetDate = resetTimeInDate(date);

    input.onChange({
      ...value,
      end_date: resetDate
    });
  };

  return (
    <StyledDateRangePickerContainer>
      <Box sx={{ flexGrow: 1 }}>
        <DatePicker
          closeOnSelect
          value={convertToDate(value.start_date)}
          onChange={onStartDateChange}
          disableFuture={disableFuture}
          slots={{
            textField: StyledTextField
          }}
          slotProps={{
            actionBar: {
              actions: []
            },
            mobilePaper: {
              sx: datePickerCalendarStyles(theme)
            },
            popper: {
              sx: datePickerCalendarStyles(theme)
            },
            textField: {
              id: `${input.name}-start_date`,
              name: `${input.name}.start_date`,
              label: `${label} - ${t('from', { ns: 'common' })}`,
              fullWidth: true,
              size
            },
            toolbar: {
              hidden: true
            }
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <DatePicker
          closeOnSelect
          value={convertToDate(value.end_date)}
          onChange={onEndDateChange}
          disableFuture={disableFuture}
          disabled={!value.start_date}
          minDate={convertToDate(value.start_date) || new Date()}
          slots={{
            textField: StyledTextField
          }}
          slotProps={{
            actionBar: {
              actions: []
            },
            mobilePaper: {
              sx: datePickerCalendarStyles(theme)
            },
            popper: {
              sx: datePickerCalendarStyles(theme)
            },
            textField: {
              id: `${input.name}-end_date`,
              name: `${input.name}.end_date`,
              label: `${label} - ${t('to', { ns: 'common' })}`,
              fullWidth: true,
              size
            },
            toolbar: {
              hidden: true
            }
          }}
        />
      </Box>
    </StyledDateRangePickerContainer>
  );
}

DateRangePickerFieldAdapter.defaultProps = {
  disableFuture: false
};

DateRangePickerFieldAdapter.propTypes = {
  disableFuture: PropTypes.bool,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export default DateRangePickerFieldAdapter;
