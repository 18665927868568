import React from 'react';
import { Box, styled } from '@mui/material';

const StyledSessionSideListContainer = styled(({ hasSessions, ...otherProps }) => (
  <Box {...otherProps} />
))(({ hasSessions }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  ...(!hasSessions && {
    alignItems: 'center',
    justifyContent: 'center'
  })
}));

export default StyledSessionSideListContainer;
