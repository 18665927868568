import { Paper, styled } from '@mui/material';

const StyledLoginContainer = styled(Paper)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.gray2.main,
  borderRadius: '8px',
  boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '32px',
  width: '310px',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px'
  },
  [theme.breakpoints.up('lg')]: {
    borderRadius: '35px',
    padding: '37px 32px 42px',
    width: '400px'
  }
}));

export default StyledLoginContainer;
