import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { dateFormat } from 'common/constants/date';
import routes from 'common/routes';
import StyledNewsCardContainer from 'common/theme/cards/news/StyledNewsCardContainer';
import StyledNewsCardDate from 'common/theme/cards/news/StyledNewsCardDate';
import StyledNewsCardImage from 'common/theme/cards/news/StyledNewsCardImage';
import StyledNewsCardMobileHeader from 'common/theme/cards/news/StyledNewsCardMobileHeader';
import StyledNewsCardText from 'common/theme/cards/news/StyledNewsCardText';
import StyledNewsCardTitle from 'common/theme/cards/news/StyledNewsCardTitle';
import StyledNewsCardViewMoreLink from 'common/theme/cards/news/StyledNewsCardViewMoreLink';

function NewsCard({ data: { created_at: createdAt, id, summary, title, thumbnail } }) {
  const { t, i18n } = useTranslation(['buttons']);
  const theme = useTheme();
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const hasThumbnail = thumbnail !== null && thumbnail !== '';

  return (
    <StyledNewsCardContainer hasThumbnail={hasThumbnail}>
      {thumbnail && !isExtraSmall && (
        <StyledNewsCardImage
          alt={title}
          src={thumbnail}
          srcSet={`${thumbnail}?w=248&fit=crop&auto=format&dpr=2 2x`}
          loading="lazy"
        />
      )}
      <div>
        <StyledNewsCardMobileHeader>
          {thumbnail && isExtraSmall && (
            <StyledNewsCardImage
              alt={title}
              src={thumbnail}
              srcSet={`${thumbnail}?w=248&fit=crop&auto=format&dpr=2 2x`}
              loading="lazy"
            />
          )}
          <div>
            <StyledNewsCardTitle component="h3" variant="h6">
              {title}
            </StyledNewsCardTitle>
            <StyledNewsCardDate variant="subtitle2" sx={{ marginBottom: 1 }}>
              {format(parseISO(createdAt), dateFormat, { locale: dateLocale })}
            </StyledNewsCardDate>
          </div>
        </StyledNewsCardMobileHeader>
        <StyledNewsCardText>{summary}</StyledNewsCardText>
        <StyledNewsCardViewMoreLink
          component={RouterLink}
          color="secondary"
          to={routes.viewNews.path.replace(':id', id)}
          variant="body2">
          {t('viewMore', { ns: 'buttons' })}
        </StyledNewsCardViewMoreLink>
      </div>
    </StyledNewsCardContainer>
  );
}

NewsCard.propTypes = {
  data: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.string
  }).isRequired
};

export default NewsCard;
