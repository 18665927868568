import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Toolbar } from '@mui/material';
import DefaultLayoutBody from 'components/layout/DefaultLayoutBody';
import AppBar from 'components/shared/AppBar';
import AlertBlock from 'components/shared/blocks/AlertBlock';
import GlobalBannerBlock from 'components/shared/blocks/GlobalBannerBlock';
import DesktopDrawer from 'components/shared/drawers/DesktopDrawer';
import MobileNavigation from 'components/shared/MobileNavigation';
import Notifier from 'components/shared/Notifier';
import { fullDrawerWidth, miniDrawerWidth } from 'common/constants/general';
import { pushNotificationStatus } from 'common/constants/options';
import StyledDefaultLayoutBannerContainer from 'common/theme/layouts/default/StyledDefaultLayoutBannerContainer';
import StyledDefaultLayoutContainer from 'common/theme/layouts/default/StyledDefaultLayoutContainer';
import StyledDefaultLayoutContent from 'common/theme/layouts/default/StyledDefaultLayoutContent';
import StyledDefaultLayoutMain from 'common/theme/layouts/default/StyledDefaultLayoutMain';
import StyledDefaultLayoutSidebar from 'common/theme/layouts/default/StyledDefaultLayoutSidebar';
import { arePushNotificationsSupported } from 'common/utils/notification';
import AppbarContextProvider from 'context/AppbarContextProvider';
import useNotifications from 'hooks/notifications/useNotifications';

function DefaultLayout({ children }) {
  const { askForNotificationPermission, pushNotifications } = useNotifications();

  const allowBlockActions = { allow: askForNotificationPermission };
  const miniDrawer = useSelector((state) => state.settings.config.miniDrawer);
  const drawerWidth = miniDrawer ? miniDrawerWidth : fullDrawerWidth;

  return (
    <StyledDefaultLayoutContainer>
      <StyledDefaultLayoutSidebar component="nav" drawerWidth={drawerWidth}>
        <DesktopDrawer drawerWidth={drawerWidth} />
      </StyledDefaultLayoutSidebar>
      <StyledDefaultLayoutMain component="main" drawerWidth={drawerWidth}>
        <AppbarContextProvider>
          <AppBar />
          <div>
            <Toolbar />
            <StyledDefaultLayoutContent spacing={{ xs: 3, md: 4 }}>
              <StyledDefaultLayoutBannerContainer spacing={2}>
                <GlobalBannerBlock />
                {arePushNotificationsSupported &&
                  pushNotifications === pushNotificationStatus.default && (
                    <AlertBlock
                      actions={allowBlockActions}
                      type="allowPushNotifications"
                      onActionClick={askForNotificationPermission}
                    />
                  )}
                {arePushNotificationsSupported &&
                  pushNotifications === pushNotificationStatus.denied && (
                    <AlertBlock type="blockedPushNotifications" />
                  )}
              </StyledDefaultLayoutBannerContainer>
              <DefaultLayoutBody>{children}</DefaultLayoutBody>
            </StyledDefaultLayoutContent>
          </div>
        </AppbarContextProvider>
        <Notifier />
      </StyledDefaultLayoutMain>
      <MobileNavigation />
    </StyledDefaultLayoutContainer>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default DefaultLayout;
