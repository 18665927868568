import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import StyledLatestPerformanceCardContent from 'common/theme/cards/latest-performance/StyledLatestPerformanceCardContent';
import StyledLatestPerformanceCardItem from 'common/theme/cards/latest-performance/StyledLatestPerformanceCardItem';
import StyledLatestPerformanceCardLegend from 'common/theme/cards/latest-performance/StyledLatestPerformanceCardLegend';
import StyledLatestPerformanceCardLegendColor from 'common/theme/cards/latest-performance/StyledLatestPerformanceCardLegendColor';
import StyledLatestPerformanceCardLegends from 'common/theme/cards/latest-performance/StyledLatestPerformanceCardLegends';
import StyledLatestPerformanceCardText from 'common/theme/cards/latest-performance/StyledLatestPerformanceCardText';
import StyledLatestPerformanceProgressBar from 'common/theme/cards/latest-performance/StyledLatestPerformanceProgressBar';

function LatestPerformanceCard({ performance }) {
  const { t } = useTranslation(['labels', 'performances']);

  if (!performance) {
    return null;
  }

  const tests = [
    { key: 'push_up_repetitions', labelKey: 'pushUp.label', type: 'repetition' },
    { key: 'squats_repetitions', labelKey: 'squat.label', type: 'repetition' },
    { key: 'burpees_repetitions', labelKey: 'burpees.label', type: 'repetition' },
    { key: 'pull_ups_repetitions', labelKey: 'pullUp.label', type: 'repetition' },
    { key: 'running_distance', labelKey: 'running.label', type: 'distance' },
    {
      key: 'plank_time',
      labelKey: 'plank.label',
      type: 'time',
      minutesKey: 'plank_time_minutes',
      secondsKey: 'plank_time_seconds'
    }
  ];

  const getPerformanceText = (test) => {
    if (test.type === 'repetition')
      return performance[test.key] ? `${performance[test.key]} rep` : '-';
    if (test.type === 'distance')
      return performance[test.key] ? `${performance[test.key]} km` : '-';
    if (test.type === 'time') {
      return `${performance[test.minutesKey] ? `${performance[test.minutesKey]}m` : '-'} ${
        performance[test.secondsKey] ? `${performance[test.secondsKey]}s` : '-'
      }`;
    }

    return '-';
  };

  const getProgressBarType = (test) => {
    if (test.type === 'time') {
      return performance[test.minutesKey] && performance[test.secondsKey]
        ? test.type
        : 'incomplete';
    }

    return performance[test.key] ? test.type : 'incomplete';
  };

  return (
    <div>
      <StyledLatestPerformanceCardText>
        {t('latestPerformanceCard.text', { ns: 'performances' })}
      </StyledLatestPerformanceCardText>
      <StyledLatestPerformanceCardContent>
        <Stack spacing={{ xs: 1, lg: 2 }} direction="column">
          {tests.map((test) => (
            <StyledLatestPerformanceCardItem key={test.key}>
              <Typography>{t(test.labelKey, { ns: 'labels' })}</Typography>
            </StyledLatestPerformanceCardItem>
          ))}
        </Stack>
        <Stack spacing={{ xs: 1, lg: 2 }} direction="column">
          {tests.map((test) => (
            <StyledLatestPerformanceCardItem key={test.key}>
              <StyledLatestPerformanceProgressBar type={getProgressBarType(test)} />
            </StyledLatestPerformanceCardItem>
          ))}
        </Stack>
        <Stack spacing={{ xs: 1, lg: 2 }} direction="column">
          {tests.map((test) => (
            <StyledLatestPerformanceCardItem key={test.key}>
              <Typography>{getPerformanceText(test)}</Typography>
            </StyledLatestPerformanceCardItem>
          ))}
        </Stack>
      </StyledLatestPerformanceCardContent>
      <StyledLatestPerformanceCardLegends>
        <StyledLatestPerformanceCardLegend>
          <StyledLatestPerformanceCardLegendColor type="repetition" />
          <Typography variant="body2">{t('repetitions', { ns: 'labels' })}</Typography>
        </StyledLatestPerformanceCardLegend>
        <StyledLatestPerformanceCardLegend>
          <StyledLatestPerformanceCardLegendColor type="time" />
          <Typography variant="body2">{t('timeDuration', { ns: 'labels' })}</Typography>
        </StyledLatestPerformanceCardLegend>
      </StyledLatestPerformanceCardLegends>
    </div>
  );
}

LatestPerformanceCard.propTypes = {
  performance: PropTypes.shape({
    burpees_repetitions: PropTypes.number,
    plank_time_minutes: PropTypes.number,
    plank_time_seconds: PropTypes.number,
    pull_ups_repetitions: PropTypes.number,
    push_up_repetitions: PropTypes.number,
    running_distance: PropTypes.number,
    squats_repetitions: PropTypes.number
  }).isRequired
};

export default LatestPerformanceCard;
