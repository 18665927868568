import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Stack } from '@mui/material';
import ProfilePictureFieldAdapter from 'components/shared/field-adapters/ProfilePictureFieldAdapter';
import StyledClientProfileHeader from 'common/theme/profile/client/StyledClientProfileHeader';
import StyledClientProfileHeaderDetails from 'common/theme/profile/client/StyledClientProfileHeaderDetails';
import StyledClientProfileHeaderDivider from 'common/theme/profile/client/StyledClientProfileHeaderDivider';
import StyledProfileHeaderText from 'common/theme/profile/StyledProfileHeaderText';
import StyledProfilePictureContainer from 'common/theme/profile/StyledProfilePictureContainer';
import StyledProfileUsername from 'common/theme/profile/StyledProfileUsername';
import { formatCurrency } from 'common/utils/format';

function ClientProfileHeader() {
  const { t } = useTranslation(['labels']);

  const firstName = useSelector((state) => state.auth.user.first_name);
  const fullName = useSelector((state) => state.auth.user.full_name);
  const lastName = useSelector((state) => state.auth.user.last_name);
  const planName = useSelector((state) => state.auth.user.plan_id.name);
  const planPrice = useSelector((state) => state.auth.user.plan_id.price);
  const planSessions = useSelector((state) => state.auth.user.plan_id.sessions);

  return (
    <StyledClientProfileHeader spacing={{ xs: 1, sm: 2, lg: 4 }}>
      <StyledProfilePictureContainer>
        <Field
          id="photo"
          name="photo"
          component={ProfilePictureFieldAdapter}
          firstName={firstName}
          lastName={lastName}
        />
      </StyledProfilePictureContainer>
      <StyledClientProfileHeaderDetails spacing={{ xs: 1 }}>
        <StyledProfileUsername>{fullName}</StyledProfileUsername>
        <Stack
          spacing={{ xs: 0, sm: 2 }}
          direction={{ xs: 'column', sm: 'row' }}
          divider={<StyledClientProfileHeaderDivider orientation="vertical" flexItem />}
          align="center">
          <StyledProfileHeaderText>
            <span>{t('plan', { ns: 'labels' })}</span>
            <span>:</span> <span>{planName}</span>
          </StyledProfileHeaderText>
          <StyledProfileHeaderText>
            <span>{t('planPrice', { ns: 'labels' })}</span>
            <span>:</span> <span>{formatCurrency(planPrice)}</span>
          </StyledProfileHeaderText>
          <StyledProfileHeaderText>
            <span>{t('planSessions', { ns: 'labels' })}</span>
            <span>:</span> <span>{planSessions}</span>
          </StyledProfileHeaderText>
        </Stack>
      </StyledClientProfileHeaderDetails>
    </StyledClientProfileHeader>
  );
}

export default ClientProfileHeader;
