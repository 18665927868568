import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist/src/constants';

const initialState = {
  current: null,
  previous: null
};

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    updateUrl(state, action) {
      state.previous = state.current;
      state.current = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

export default routerSlice;
