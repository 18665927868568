import styledComponent from 'styled-components';

const StyledPaginatedList = styledComponent.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  & > div {
    width: 100%;
  }
  @media (min-width: 600px) {
    align-items: flex-end;
    row-gap: 24px;
  }
`;

export default StyledPaginatedList;
