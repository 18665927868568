import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLottie } from 'lottie-react';
import { differenceInCalendarDays } from 'date-fns';
import SingleCounter from 'components/shared/counters/single/SingleCounter';
import { defaultLottieOptions } from 'common/constants/general';

import paymentIcon from 'lottie/payment_icon.json';

function LastPaymentCounter() {
  const { t } = useTranslation(['payments']);
  const latestPayment = useSelector((state) => state.clientPayment.data.currentPayment);

  const today = new Date();
  const lastPaymentEndDate = new Date(latestPayment?.end_date);
  const diffInDays = differenceInCalendarDays(lastPaymentEndDate, today);

  const line1 = diffInDays > 0 ? t('dueIn', { ns: 'payments' }) : t('overdue', { ns: 'payments' });
  const line2 =
    diffInDays > 0 ? `${diffInDays} ${t('day', { count: diffInDays, ns: 'common' })}` : '';

  const lottieOptions = {
    ...defaultLottieOptions,
    animationData: paymentIcon
  };

  const { View } = useLottie(lottieOptions);

  return (
    <SingleCounter
      lottieIcon={View}
      title={t('lastPayment', { ns: 'payments' })}
      lines={[line1, line2]}
    />
  );
}

export default LastPaymentCounter;
