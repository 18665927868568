import React from 'react';
import PropTypes from 'prop-types';
import DrawerMenu from 'components/shared/drawers/DrawerMenu';
import StyledDesktopDrawer from 'common/theme/drawers/StyledDesktopDrawer';

function DesktopDrawer({ drawerWidth }) {
  return (
    <StyledDesktopDrawer variant="permanent" drawerWidth={drawerWidth} open>
      <DrawerMenu />
    </StyledDesktopDrawer>
  );
}

DesktopDrawer.propTypes = {
  drawerWidth: PropTypes.number.isRequired
};

export default DesktopDrawer;
