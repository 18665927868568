import { Box, styled } from '@mui/material';

const StyledOverviewProfileObservations = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray0.main,
  borderRadius: '8px',
  padding: '16px 12px 20px 12px',
  [theme.breakpoints.up('md')]: {
    borderRadius: '10px'
  }
}));

export default StyledOverviewProfileObservations;
