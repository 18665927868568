import React from 'react';
import PropTypes from 'prop-types';
import { lightFormat } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { DoneAll } from '@mui/icons-material';
import UserAvatar from 'components/shared/user/UserAvatar';
import { dateFormat, timeFormat } from 'common/constants/date';
import routes from 'common/routes';
import StyledNotificationAvatarContainer from 'common/theme/notification/StyledNotificationAvatarContainer';
import StyledNotificationBody from 'common/theme/notification/StyledNotificationBody';
import StyledNotificationButton from 'common/theme/notification/StyledNotificationButton';
import StyledNotificationContainer from 'common/theme/notification/StyledNotificationContainer';
import StyledNotificationCTA from 'common/theme/notification/StyledNotificationCTA';
import StyledNotificationDate from 'common/theme/notification/StyledNotificationDate';
import StyledNotificationDot from 'common/theme/notification/StyledNotificationDot';
import StyledNotificationDotContainer from 'common/theme/notification/StyledNotificationDotContainer';
import StyledNotificationHeader from 'common/theme/notification/StyledNotificationHeader';
import StyledNotificationStretchedLink from 'common/theme/notification/StyledNotificationStretchedLink';
import { convertUtcToLocalTime } from 'common/utils/date';
import useNotifications from 'hooks/notifications/useNotifications';

function SessionCreatedNotification({ notification }) {
  const { t, i18n } = useTranslation(['buttons', 'notifications']);
  const dateLocale = i18n.language === 'es' ? es : enUS;
  const { markNotificationAsRead } = useNotifications();

  const {
    created_at: createdAt,
    data: {
      session: { start_date: startDate, user }
    }
  } = notification;

  const startDateObj = new Date(startDate);
  const date = convertUtcToLocalTime(startDateObj, dateFormat, dateLocale);
  const time = lightFormat(startDateObj, timeFormat);

  const handleMarkAsRead = () => {
    markNotificationAsRead(notification.id);
  };

  return (
    <StyledNotificationContainer>
      <StyledNotificationAvatarContainer>
        <UserAvatar size={40} textSize={20} user={user} />
        {!notification.read && (
          <StyledNotificationDotContainer>
            <StyledNotificationDot />
          </StyledNotificationDotContainer>
        )}
      </StyledNotificationAvatarContainer>
      <Box sx={{ width: '100%' }}>
        <StyledNotificationHeader>
          <Typography variant="body2">{user.full_name}</Typography>
          {!notification.read && (
            <StyledNotificationButton
              color="secondary"
              onClick={handleMarkAsRead}
              startIcon={<DoneAll />}>
              {t('markAsRead', { context: 'female', ns: 'buttons' })}
            </StyledNotificationButton>
          )}
        </StyledNotificationHeader>
        <StyledNotificationBody variant="body2">
          {t('session.created', {
            user: user.full_name,
            date,
            time,
            ns: 'notifications'
          })}
        </StyledNotificationBody>
        <StyledNotificationCTA variant="body2" sx={{ marginTop: 1 }}>
          {t('checkYourCalendar', { ns: 'notifications' })}{' '}
          <StyledNotificationStretchedLink
            color="secondary"
            component={RouterLink}
            to={routes.calendar.path}>
            {t('here', { ns: 'common' })}
          </StyledNotificationStretchedLink>
        </StyledNotificationCTA>
        <StyledNotificationDate color="secondary" variant="caption" component="p">
          {createdAt}
        </StyledNotificationDate>
      </Box>
    </StyledNotificationContainer>
  );
}

SessionCreatedNotification.propTypes = {
  notification: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    data: PropTypes.shape({
      session: PropTypes.shape({
        id: PropTypes.number.isRequired,
        start_date: PropTypes.string.isRequired,
        user: PropTypes.shape({
          first_name: PropTypes.string.isRequired,
          full_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
          photo: PropTypes.string
        }).isRequired
      }).isRequired
    }).isRequired,
    id: PropTypes.string.isRequired,
    read: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export default SessionCreatedNotification;
