import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { enUS, es } from 'date-fns/locale';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SessionStatusChip from 'components/shared/chips/SessionStatusChip';
import UserAvatar from 'components/shared/user/UserAvatar';
import { dateFormat, hourMinuteFormat, timeFormat } from 'common/constants/date';
import StyledLargeSessionCardContainer from 'common/theme/cards/session/large/StyledLargeSessionCardContainer';
import StyledLargeSessionCardContent from 'common/theme/cards/session/large/StyledLargeSessionCardContent';
import StyledLargeSessionCardDecoration from 'common/theme/cards/session/large/StyledLargeSessionCardDecoration';
import StyledLargeSessionCardInnerContainer from 'common/theme/cards/session/large/StyledLargeSessionCardInnerContainer';
import StyledLargeSessionCardText from 'common/theme/cards/session/large/StyledLargeSessionCardText';
import StyledLargeSessionCardTextContainer from 'common/theme/cards/session/large/StyledLargeSessionCardTextContainer';
import StyledLargeSessionCardTitle from 'common/theme/cards/session/large/StyledLargeSessionCardTitle';
import { convertUtcToLocalTime } from 'common/utils/date';
import { isTrainer } from 'common/utils/user';

function LargeSessionCard({ data, userRole }) {
  const { t, i18n } = useTranslation(['labels']);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const dateLocale = i18n.language === 'es' ? es : enUS;
  const { start_date: startDate, end_date: endDate } = data;
  const userToShow = isTrainer(userRole) ? data.client_id : data.trainer_id;

  return (
    <StyledLargeSessionCardContainer>
      <StyledLargeSessionCardDecoration />
      <StyledLargeSessionCardInnerContainer>
        <StyledLargeSessionCardContent>
          {isSmall && <UserAvatar size={60} textSize={20} user={userToShow} />}
          <StyledLargeSessionCardTextContainer>
            <StyledLargeSessionCardTitle variant="body1" color="secondary">
              {userToShow.full_name}
            </StyledLargeSessionCardTitle>
            <StyledLargeSessionCardText variant="body2">
              {t('date', { ns: 'labels' })}:{' '}
              {convertUtcToLocalTime(startDate, dateFormat, dateLocale)}
            </StyledLargeSessionCardText>
            <StyledLargeSessionCardText variant="body2">
              {t('time', { ns: 'labels' })}: {convertUtcToLocalTime(startDate, hourMinuteFormat)} -{' '}
              {convertUtcToLocalTime(endDate, timeFormat)}
            </StyledLargeSessionCardText>
            <StyledLargeSessionCardText variant="body2">
              {t('category', { ns: 'labels' })}: {data.session_type_id.name}
            </StyledLargeSessionCardText>
            <StyledLargeSessionCardText variant="body2">
              {t('language', { count: 1, ns: 'labels' })}:{' '}
              {t(`language.${data.language.toLowerCase()}`, { ns: 'options' })}
            </StyledLargeSessionCardText>
          </StyledLargeSessionCardTextContainer>
        </StyledLargeSessionCardContent>
        <SessionStatusChip data={data} userRole={userRole} />
      </StyledLargeSessionCardInnerContainer>
    </StyledLargeSessionCardContainer>
  );
}

LargeSessionCard.propTypes = {
  data: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired
};

export default LargeSessionCard;
