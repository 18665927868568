const sessionTypeRoutes = {
  listSessionType: {
    path: '/session-types'
  },
  createSessionType: {
    path: '/session-types/create'
  },
  editSessionType: {
    path: '/session-types/edit/:id'
  }
};

export default sessionTypeRoutes;
