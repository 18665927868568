import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

function SessionCardAdminView({ client, trainer }) {
  const { t } = useTranslation([]);

  return (
    <Box sx={{ textAlign: 'right' }}>
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {t('role.client', { ns: 'options' })}
      </Typography>
      <Typography variant="body2">{client.full_name}</Typography>
      <Typography variant="body2" sx={{ fontWeight: 500, marginTop: 1 }}>
        {t('role.trainer', { ns: 'options' })}
      </Typography>
      <Typography variant="body2">{trainer.full_name}</Typography>
    </Box>
  );
}

SessionCardAdminView.propTypes = {
  client: PropTypes.object.isRequired,
  trainer: PropTypes.object.isRequired
};

export default SessionCardAdminView;
